import React, { useReducer, createContext } from "react";
import { authenticationService } from "services/authServices";

let initialState = {
  status: "idle",
  user: null,
  privileges: null,
  error: null,
  language: "en",
  logout: async (userName, token) => {
    try {
      localStorage.clear();
      await authenticationService.logout(userName, token);
      // yield put({
      //   type: "RESET_APP"
      // });
    } catch (e) {
      console.error("error in logging out");
    }
  }
};

const authContext = createContext();
const { Provider } = authContext;
const AuthProvider = ({ children }) => {
  const [authState, authDispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "SET_USER":
        return { ...state, user: action.user };
      case "LOGGING_IN":
        return { ...state, status: "loggingIn" };
      case "LOGIN_SUCCESS":
        return { ...state, status: "loggedIn", error: null };
      case "LOGIN_FAILED":
        return { ...state, status: "idle", error: action.error };
      case "LOGGED_OUT":
        return { ...state, status: "idle", error: null };
      case "LOGGING_OUT":
        return { ...state, status: "loggingOut" };
      case "CHANGE_LANGUAGE":
        return { ...state, language: action.language };
      default:
        return state;
    }
  }, initialState);
  return <Provider value={{ authState, authDispatch }}>{children}</Provider>;
};

export { authContext, AuthProvider };
