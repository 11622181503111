import React, { useState, useEffect } from "react";
import { FieldArray, Field, getIn } from "formik";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { TextField } from "formik-material-ui";
import { provisioningService } from "services/provisioningServices";
import { metadataService } from "services/metadataServices";
import DeviceParam from "./DeviceParam";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default function DeviceProfile({
  parentIndex,
  name,
  values,
  errors,
  setFieldError,
  setFieldTouched,
  setFieldValue,
  metaProps,
  edit,
  devices
}) {
  // const [deviceAttributes, setDeviceAttributes] = useState([]);
  // const [applicationAttributes, setApplicationAttributes] = useState([]);
  // const [unitValueTypes, setUnitValueTypes] = useState([]);
  const { t } =useTranslation();
  useEffect(() => {
    if (!!edit && Array.isArray(values) && values.length > 0) {
      values.map(async (a, b) => {
        if (a.deviceType !== "") {
          Promise.all([
            metadataService.getDeviceAttributes(a.deviceType),
            metadataService.getApplicationAttributes(a.deviceType),
            metadataService.getUnitValueTypes(a.deviceType),
            (a.deviceType = a.deviceType.replace("indsensABC", "indsens"))
          ])
            .then(data => {
              setFieldValue(`${name}.${b}.deviceAttributes`, data[0], false);
              setFieldValue(
                `${name}.${b}.applicationAttributes`,
                data[1],
                false
              );
              setFieldValue(`${name}.${b}.unitValueTypes`, data[2], false);
            })
            .catch(e => {
              setFieldValue(`${name}.${b}.deviceAttributes`, [], false);
              setFieldValue(`${name}.${b}.applicationAttributes`, [], false);
              setFieldValue(`${name}.${b}.unitValueTypes`, [], false);
            });
        }
      });
    }
  }, [devices]);

  // useEffect(() => {
  //   let deviceType = getIn(values, `${0}.deviceType`);
  //   metadataService
  //     .getApplicationAttributes(deviceType)
  //     .then(data => setApplicationAttributes(data));
  // }, []);
  // useEffect(() => {
  //   let deviceType = getIn(values, `${0}.deviceType`);
  //   metadataService
  //     .getUnitValueTypes(deviceType)
  //     .then(data => setUnitValueTypes(data));
  // }, []);

  async function addDeviceProfile(childIndex, push) {
    let serialNumber = getIn(values, `${childIndex}.serialNumber`);
    let repeat = values.filter(device => device.serialNumber === serialNumber);
    const repeatDevice = devices.filter(
      device => device.serialNumber === serialNumber
    );
    try {
      if (serialNumber === "") {
        setFieldTouched(`${name}.${childIndex}.serialNumber`, true, false);
        throw new Error("Enter a serial Number");
      }
      if (repeat.length > 1) {
        throw new Error("Device is already added");
      } else if (repeatDevice.length > 0) {
        throw new Error("Device already added at the site level");
      }

      let deviceValues = {
        serialNumber: getIn(values, `${childIndex}.serialNumber`),
        gatewaySerialNumber: getIn(values, `${childIndex}.gatewaySerialNumber`),
        l0Config: [
          {
            deviceAttribute: "",
            applicationAttribute: "",
            unit: ""
          }
        ],
        deviceType: getIn(values, `${childIndex}.deviceType`)
      };

      if (deviceValues.gatewaySerialNumber === "") {
        setFieldTouched(
          `${name}.${childIndex}.gatewaySerialNumber`,
          true,
          false
        );
        setFieldError(
          `${name}.${childIndex}.gatewaySerialNumber`,
          "Select a serial number"
        );
      } else if (!getIn(errors, `${childIndex}.serialNumber`)) {
        push({
          // deviceUsedFor: "",
          serialNumber: "",
          deviceType: "",
          l0Config: [
            {
              deviceAttribute: "",
              applicationAttribute: "",
              unit: ""
            }
          ],
          gatewaySerialNumber: ""
        });
      }
    } catch (e) {
      setFieldError(`${name}.${childIndex}.serialNumber`, e.message);
    }
  }
  // function setL0DropdownValues(childIndex) {
  //   let deviceType = getIn(values, `${childIndex}.deviceType`);
  //   metadataService
  //     .getDeviceAttributes(deviceType)
  //     .then(data => setDeviceAttributes(data));
  //   metadataService
  //     .getApplicationAttributes(deviceType)
  //     .then(data => setApplicationAttributes(data));
  //   metadataService
  //     .getUnitValueTypes(deviceType)
  //     .then(data => setUnitValueTypes(data));
  // }
  return (
    <FieldArray name={name}>
      {({ remove, push, form }) => {
        let { values } = form;
        return (
          <>
            <Grid item xs={12}>
              <h3 style={{ color: "black" }}>{t("chilling_Unit_Form_Device_Profile")}</h3>
              {getIn(values, name).length === 0 && (
                <button
                  type="button"
                  onClick={() => {
                    push({
                      serialNumber: "",
                      deviceType: "",
                      gatewaySerialNumber: "",
                      l0Config: [
                        {
                          deviceAttribute: "",
                          applicationAttribute: "",
                          unit: ""
                        }
                      ]
                    });
                  }}
                  className="add-icon"
                >
                  <i className="fa fa-plus-circle" aria-hidden="true"></i>
                </button>
              )}
            </Grid>

            {getIn(values, name).length &&
              getIn(values, name).map((l1DeviceInfo, childIndex) => (
                <>
                  <Grid item xs={12}>
                    <h3 style={{ color: "black" }}>
                      {l1DeviceInfo.deviceType
                        ? l1DeviceInfo.deviceType
                        : t("chilling_Unit_Form_New_Device")}
                    </h3>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    lg={11}
                    className="device-profile"
                    key={childIndex}
                    justify="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <>
                      <Grid item xs={11} md={6} lg={3}>
                        <Field
                          type="text"
                          name={`${name}.${childIndex}.serialNumber`}
                          label="Serial Number"
                          component={TextField}
                          margin="dense"
                          fullWidth={true}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          {...metaProps}
                        />
                      </Grid>
                      <Grid item xs={11} md={6} lg={3}>
                        <Field
                          type="text"
                          disabled="true"
                          name={`${name}.${childIndex}.deviceType`}
                          label={t("chilling_Unit_Form_Device_Type")}
                          component={TextField}
                          margin="dense"
                          fullWidth={true}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={11} md={6} lg={3}>
                        <Field
                          select
                          name={`${name}.${childIndex}.gatewaySerialNumber`}
                          label="Gateway Serial Number"
                          className="placeholder-inner"
                          component={TextField}
                          margin="dense"
                          fullWidth={true}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        // onClick={() => {
                        //   // setFieldTouched(
                        //   //   `${name}.${childIndex}.serialNumber`,
                        //   //   false,
                        //   //   false
                        //   // );
                        //   setL0DropdownValues(childIndex);
                        // }}
                        >
                          <MenuItem disabled value="none">
                            Gateway Serial Number
                          </MenuItem>
                          {devices.length > 0 &&
                            devices
                              .filter(device => device.deviceType === "L3")
                              .map(device => (
                                <MenuItem
                                  key={device.serialNumber}
                                  value={device.serialNumber}
                                >
                                  {device.serialNumber}
                                </MenuItem>
                              ))}
                        </Field>
                      </Grid>

                      {!!getIn(
                        values,
                        `${name}.${childIndex}.deviceAttributes`
                      ) &&
                        !!getIn(
                          values,
                          `${name}.${childIndex}.applicationAttributes`
                        ) &&
                        !!getIn(
                          values,
                          `${name}.${childIndex}.unitValueTypes`
                        ) && (
                          <Grid container xs={12} spacing={2} className="mb-6">
                            <DeviceParam
                              name={`${name}.${childIndex}.l0Config`}
                              values={
                                getIn(values, `${name}.${childIndex}`) || []
                              }
                              errors={
                                getIn(errors, `${name}.${childIndex}`) || []
                              }
                              //handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              setFieldError={setFieldError}
                              setFieldTouched={setFieldTouched}
                              // metaProps={metaProps}
                              devices={getIn(values, `devices`)}
                              // edit={edit}
                              parentIndex={childIndex}
                            />
                          </Grid>
                        )}
                    </>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    xs={1}
                    justify="center"
                    alignItems="center"
                  >
                    {getIn(values, name).length > 0 &&
                      childIndex === getIn(values, name).length - 1 && (
                        <Grid item>
                          <button
                            type="button"
                            onClick={() => {
                              addDeviceProfile(childIndex, push);
                            }}
                            className="add-icon"
                          >
                            <i
                              className="fa fa-plus-circle"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </Grid>
                      )}
                    {getIn(values, name).length > 1 && (
                      <Grid item>
                        <button
                          type="button"
                          className="add-icon delete-icon"
                          onClick={e => {
                            getIn(values, name).length > 0 &&
                              remove(childIndex);
                          }}
                        >
                          <i class="fa fa-minus-circle" aria-hidden="true"></i>
                        </button>
                      </Grid>
                    )}
                  </Grid>
                </>
              ))}
          </>
        );
      }}
    </FieldArray>
  );
}
