import React, { useState, useEffect } from "react";
import { FieldArray, Field, getIn } from "formik";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { TextField } from "formik-material-ui";
import { useTranslation } from "react-i18next";
import ImageComponent from "components/ImageComponent";

import BrowseButton from "components/BrowseButton";

export default function BannerLanguageList({
  name,
  languageValues,
  setFieldValue,
  values,
  renderFields,
  elemProp }) {

  function handleTypeChange() {

  }
  const [imageUrlLang, setimageUrlLang] = useState("");
  const [urlError, setUrlError] = useState("");
  const [markTouched, setMarkTouched] = useState(false);
  // const [imageUrlKan,setImageUrlKan]= useState("");
  // const [imageUrlHin,setImageUrlHin]= useState(""); 
  const [respnseFilename, setRespnseFilename] = useState("");
  //const [urllabel, setUrlLabel] = useState("");

  // function handleUrl(url) {
  //   // set the hook here like
  //   //setUrl(url)
  //   }

  const handleClick = (resImgPath) => {
    console.log("Parent Handle click event fired", resImgPath)
  }

  const getPictureEng = value => {
    console.log("grt picture----------------------",value);
    setimageUrlLang(value);
    if (values.languageList[0].language === 'ENGLISH')
      values.en = value;
    else if (values.languageList[1].language === 'KANNADA')
      values.kn = value;
    else if (values.languageList[2].language === 'HINDI')
      values.hi = value;
    else if (values.languageList[3].language === 'MARATI')
      values.mr = value;
    else if (values.languageList[4].language === 'TELUGU')
      values.te = value;
    else if (values.languageList[5].language === 'TAMIL')
      values.ta = value;

    setFieldValue(values);
  };


  const { t } = useTranslation();


  return (
    <FieldArray name={name}>
      {({ insert, remove, push, form }) => {
        let {
          value,
          errors,
          touched,
          setFieldTouched,
          setFieldValue,
          handleChange,
          isSubmitting
        } = form;

        return (
          <>
            {getIn(values, name).length > 0 &&
              getIn(values, name).map((languageList, childIndex) => {
                const isTouched = getIn(touched, `${name}.${childIndex}.url`);
                if (isSubmitting && !isTouched) {
                  setFieldTouched(`${name}.${childIndex}.url`, true);
                  setFieldTouched(`${name}.${childIndex}.language`, true);
                }
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    md={12}
                    className="mb-0 d-flex flex-direction-column device-wrapper"
                    key={childIndex}
                  >
                    <Grid
                      item
                      container
                      xs={12}
                      md={12}
                      spacing={2}
                      alignContent="flex-start"
                      className="site-inner"
                    >
                      <Grid item xs={12} md={3}>
                        <Field
                          select
                          name={`${name}.${childIndex}.language`}
                          label={t("BannerLanguageList_Form_language_Label")}
                          component={TextField}
                          margin="dense"
                          fullWidth={true}
                          variant="outlined"
                          inputProps={{
                            onChange: e => {
                              handleChange(e);
                              handleTypeChange(
                                e.target.value,
                                childIndex,
                                setFieldValue,
                                setFieldTouched,
                                values
                              );
                            }
                          }}
                        >
                          <MenuItem disabled value="none">
                            {"Choose"}
                          </MenuItem>
                          {languageValues.map(value => (
                            <MenuItem key={value} value={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>

                      <Grid item xs={12} md={4}>

                        <Field
                          name={`${name}.${childIndex}.url`}
                          label={t("BannerLanguageList_Form_Url_Label")}
                          variant="outlined"
                          placeholder={t("BannerLanguageList_Form_Url_Label")}
                          component={TextField}
                          value={`${name}.${childIndex}.url`}
                          margin="dense"
                          fullWidth={true}
                          size="medium"
                          multiline="true"
                          minRows="5"
                          InputLabelProps={{ shrink: true }}
                          // helperText={errors[`${name}`] && errors[`${name}`][`${childIndex}`].url}
                          inputProps={{
                            onChange: e => {
                              getPictureEng(e.target.value);
                            }
                          }}
                        />

                      </Grid>

                      {getIn(values, `${name}.${childIndex}.url`) &&
                        <Grid item md={2}>
                          <ImageComponent
                            name="imageEng"
                            values={getIn(values, `${name}.${childIndex}.url`)}
                            errors={errors}
                            rendertypeFields={renderFields}
                          //elemProp="English Image"
                          />
                        </Grid>
                      }

                      <Grid item md={1}>
                        <BrowseButton
                          handleMyClick={(imagePath) => {
                            setFieldValue(`${name}.${childIndex}.url`, imagePath);
                            setUrlError("");
                          }}
                        />
                      </Grid>

                      <Grid item container spacing={1} xs={6} md={2} >
                        {getIn(values, name).length > 0 &&
                          childIndex === getIn(values, name).length - 1 && (
                            <Grid item style={{marginTop:"10px"}}>
                            <button
                              type="button"
                              onClick={() => {
                                setFieldTouched(`${name}.${childIndex}.url`, true);
                                setFieldTouched(`${name}.${childIndex}.language`, true);
                                if (
                                  !getIn(errors, `${name}.${childIndex}`) &&
                                  getIn(touched, `${name}.${childIndex}`)
                                ) {
                                  push({
                                    url:"",
                                    language:""                                  
                                  });
                                } else {
                                  Object.keys(
                                    getIn(values, `${name}.${childIndex}`)
                                  ).forEach(item => {
                                    item !== "subTaskValue" &&
                                      setFieldTouched(
                                        `${name}.${childIndex}.${item}`,
                                        true,
                                        true
                                      );
                                    if (
                                      getIn(
                                        errors,
                                        `${name}.${childIndex}.${item}`
                                      )
                                    ) {
                                      item !== "subTaskValue" &&
                                        setFieldTouched(
                                          `${name}.${childIndex}.${item}`,
                                          true,
                                          true
                                        );
                                    }
                                  });
                                }
                              }}
                              className="add-icon"
                              style={{marginLeft:'20px'}}
                            >
                              <i
                                className="fa fa-plus-circle"
                                aria-hidden="true"
                              ></i>

                            </button>
                            </Grid>
                          )}

                        {getIn(values, name).length > 1 && (
                          <Grid item style={{marginTop:"10px"}}>
                          <button
                            type="button"
                            className="add-icon delete-icon"
                            style={{marginLeft:'20px'}}    // marginLeft:'20px' - Its margin between addicon and deleteicon,marginTop:'1px'  - It delete icon marginTop
                            onClick={e => {
                              getIn(values, name).length > 0 &&
                                remove(childIndex);
                            }}
                          >
                            <i className="fa fa-minus-circle" aria-hidden="true"></i>
                          </button>
                          </Grid>
                        )}

                      </Grid>

                    </Grid>

                  </Grid>

                )
              })

            }

            {urlError && (

              <div style={{ color: "#f44336", textAlign: "center" }}>{urlError}</div>

            )}

          </>
        );
      }}
    </FieldArray>
  );
}
