import React, { useEffect } from "react";
import image from "../../assets/ErrorImages/500.svg";
import { makeStyles } from "@material-ui/core/styles";
//import { fontSize } from "@material-ui/system";
import { useDispatch } from "react-redux";

function Error500() {
  const useStyles = makeStyles(theme => ({
    error500PageConatiner: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "90vh"
    },
    errorContainer: {
      minWidth: "240px",
      maxWidth: "500px",
      maxHeight: "300px",
      display: "block",
      flexDirection: "column",
      alignSelf: "center"
    },
    errorMsg: {
      paddingLeft: theme.spacing(15),
      paddingRight: theme.spacing(15),
      [theme.breakpoints.down("md")]: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0)
      },
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    errorCode: {
      display: "block",
      marginBottom: theme.spacing(0)
    },
    errorSubMsg: {
      display: "flex",
      color: "#b0b4b9",
      fontSize: "2em",
      "& h2": {
        marginBottom: "0px"
      }
    }
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "RESET_ERROR"
    });
  }, []);
  return (
    <div className={classes.error500PageConatiner}>
      <div className={classes.errorContainer}>
        <div className={classes.errorMsg}>
          <h1 className={classes.errorCode}>500</h1>
          <div className={classes.errorSubMsg}>
            <h2>Internal Server Error </h2>
          </div>
        </div>
        <object type={"image/svg+xml"} data={image}>
          Your Browser doesn't support SVG
        </object>
      </div>
    </div>
  );
}
export default Error500;
