import React, { useEffect, useContext, useState, useMemo } from "react";

import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
//import Table from "components/common/table/ControlledPaginationTable";
import Table from "components/common/table/DynamicPaginationTable2";
//import Table from "components/common/table/DynamicPaginationTable";
import PageHeader from "../../../components/layout/PageHeader.js";

import CircularProgress from "@material-ui/core/CircularProgress";
import TemplateForTables from "../../../components/layout/innerPagesTemplate";
import { Grid, Paper } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { notificationService } from "services/notificationServices";
import { provisioningService } from "../../../services/provisioningServices.js";

import { errorContext } from "context/errorContext";
import addDays from "date-fns/addDays";

import "../../../components/Table.scss";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import CommonForm from "components/common/form/common-form";
import { func } from "prop-types";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
import FailureFeedback from "components/common/elements/FailureFeedback";
import { productService } from "services/productServices.js";

const dropitems = [
  // {
  //   item: <Link to="/configuration/createproduct">Add Product</Link>
  // }
];

const ProductListComp = props => {

  const { dispatch: errorDispatch } = useContext(errorContext);
  const [product, setProduct] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [fetchTagValues, setFetchTagValues] = useState([]);
  const [status, setStatus] = useState("loading");

  const [startDate] = React.useState(addDays(new Date(), -2));
  const [endDate] = React.useState(new Date());
  const [pbpData,setPbpData] = React.useState([]);
  const [fetchTagOnPbp, setFetchTagOnPbp] = React.useState([]);

  const [fetchTagStatus, setFetchTagStatus] = useState("loading");

  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();
  const createError = useState({});
  const history = useHistory();

  const user = JSON.parse(localStorage.getItem("currentUser"));

  const loadFetchTagData = value => {
    const territory = pbpData.find(pbpData =>pbpData.id === value);
    if(territory != undefined){      
      setFetchTagValues(territory.territories)
    }

    console.log(territory);

    // productService.getFetchTagBasedOnPbpId(value).then(data => {        
    //   if(data){
    //     setFetchTagOnPbp(data);
    //     console.log("getFetchTagBasedOnPbpId", data );
    //   }
    // });
  }

  const dropitems = [
    {
      item: (<Link to="/configuration/createproduct">Add Product</Link>)
    }
  ];

  let viewDropDown = false;

  // if(user.uiPrivileges.includes("CREATE_PRODUCT_TYPE")) {
  //   viewDropDown = true;
  // } else{
  //   viewDropDown = false;
  // }

  let query = useMemo(() => new URLSearchParams(props.location.search), [
    props.location
  ]);

  function handleSubmit(values, resetForm, setSubmitting) {
    props.history.push({
      pathname: props.location.pathname,
      search: `?page=3000&size=0`
    });
    getProductList(values);
    setSubmitting(false);
  }

  const { t } = useTranslation();

  function getProductList(values){  
    let pageSize = query.get("size");
    let pageIndex = query.get("page");
    
    if (!!query.get("page") && !!query.get("size")) {
      productService.productList(values.fetchTag,pageSize,pageIndex).then((data) => {
    // productService.getProdListBasedOnPbpFetchTag(values.pbpId,values.fetchTag).then((data) => {
        setProduct(data.content);
        setPageInfo(data.pageInfo);
        setStatus("success");
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
        setStatus("failed");
      });
    } else {
      props.history.push({
        pathname: props.location.pathname,
        search: `?page=3000&size=0`
      });
    }
  }

  function getFetchTag(){
    productService.getFetchTag().then((data) => {
      setFetchTagValues(data);
      setFetchTagStatus("success");
      setStatus("success");
    })
    .catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
      setStatus("failed");
    }); 
  }

  function getPbpList(){
    productService.getPbpList().then(data =>{
      if(data){
        setPbpData(data);
        setFetchTagStatus("success");
      }
    });
  }
 
  useEffect(() => {
    provisioningService.verifyOtpNew('1313131313').then((res) => {
      const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
      const organizationId = JSON.parse(localStorage.getItem("orgId"));
      //getPbpList();
      getFetchTag();
    })
    .catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });   
  }, []);  

  const handleDialogClose = () => {
  };

  const fields = [
    {
      type: "singleSelectSearch",
      name: "fetchTag",
      label: t("product_Form_fetchTag_Label"),
      placeholder: t("product_Form_fetchTag_Label"),
      option:
      fetchTagValues.length > 0
          ? fetchTagValues.map(fetchTagValues => ({
              value: fetchTagValues,
              label: fetchTagValues
            }))
          : [],
      grid: {
        xs: 12,
        sm: 2,
        md: 4,
        className: ""
      }
    }
    // {  
    //   type: "select",
    //   name: "pbpId",
    //   label: t("product_Form_pbpId_Label"),
    //   placeholder: t("product_Form_pbpId_Label"),
    //   option:
    //   pbpData.length > 0
    //     ? pbpData.map(pbpData => ({
    //         value: pbpData.id,
    //         label: pbpData.name
    //       }))
    //     : [],
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   },
    //   customOnChange:loadFetchTagData
    // },
    // {  
    //   type: "select",
    //   name: "fetchTag",
    //   label: t("product_Form_fetchTag_Label"),
    //   placeholder: t("product_Form_fetchTag_Label"),
    //   option:
    //     fetchTagValues.length > 0
    //       ? fetchTagValues.map(fetchTagValues => ({
    //           value: fetchTagValues.name,
    //           label: fetchTagValues.name
    //       }))
    //     : [],
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // }
  ]; 

  let values = {
    pbpId:"",
    fetchTag: ""
  };

  const formikForm = React.useRef(null);

  const nextPage = () => {
    if (
      status !== "loading" &&
      pageInfo.hasNextPage &&
      Number(query.get("page")) < pageInfo.totalPages
    ) {
      query.set("page", Number(query.get("page")) + 1);
      props.history.push({
        pathname: props.location.pathname,
        search: query.toString()
      });
    }
  };
  const previousPage = () => {
    props.history.goBack();
  };
  const handlePageSize = size => {
    query.set("size", size);
    query.set("page", 0);
    props.history.push({
      pathname: props.location.pathname,
      search: query.toString()
    });
  };

  let validations = {
    fetchTag: Yup.string().required(
        t("product_Form_fetchTag_Label")
    )
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Product Image",
        Cell: ({ row }) => {
          return (
            <>
              <img src={row.original.imageURL}  width="50" height="50"/>
            </>
          )
        }
      },
      {
        Header: "Product Name",
        accessor: "productName"
      },
      {
        Header: "Product Code",
        accessor: "productCode"
      },
      {
        Header:"Product Type",
        accessor:"productType"
      },
      {
        Header: "Selling Price",
        accessor: "price.sellingPrice"
      },
      {
        Header: "Category Name",
        accessor: "category.categoryDisplayName"
      },
      {
        Header: "Rating",
        accessor: "rating"
      },
      {
        Header: "View",
        Cell: ({ row }) => {
         // let link = `/configuration/editproduct/${row.original.id}`;
          let viewLink = `/configuration/viewproduct/${row.original.id}`;
          return (
            // user.uiPrivileges.includes("MODIFY_PRODUCT_TYPE") ?
            // <>
            //   <Link to={link}>
            //     <IconButton>
            //       <i className="fa fa-edit" aria-hidden="true"></i>
            //     </IconButton>
            //   </Link>
            // </>
            // : 
            <>
              <Link to={viewLink}>
                <IconButton>
                  <i className="fa fa-eye" aria-hidden="true"></i>
                </IconButton>
              </Link>            
            </>
          )
        }
      },      
    ],
    []
  );

  const data = React.useMemo(() => [...product]);
  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title="Product List"
          breadcrumbs={[
            { label: "Dashboard", link: "/dashboard" },
            { label: "Configuration" },
            { label: "Product List" }
          ]}
        />
      </Grid>
      <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%"
        }}
      >

    {fetchTagStatus === "loading" ? (
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ height: "50vh" }}
      >
      <CircularProgress />
      </Grid>
      ):(
      <CommonForm
        fields={fields}
        submitLabel={t("product_Form_Submit_Label")}
        submittingLabel={t("product_Form_Submitting_Label")}
        initialValues={values}
        validationSchema={Yup.object().shape({ ...validations })}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={(
          values,
          { setSubmitting, setFieldError, resetForm, errors }
        ) => {
          if (!!errors) {
            setSubmitting(false);
          }
          handleSubmit(values, resetForm, setSubmitting);
        }}
        formikRef={formikForm}
        buttonSize="2"
        buttonPosition="right"
        inlineButton={true}
      />
      )}  
      
      {/* <TemplateForTables
        dropdownitems={dropitems}
        needDropdown={true}
        importIcons
      > */}
        {status === "success" ? (
          // <Grid
          //   container
          //   justify="center"
          //   alignItems="center"
          //   style={{ height: "50vh" }}
          // >
          //  <CircularProgress />
          // </Grid>
          // <Table
          // columns={columns}
          // data={data}
          // needDropdown={viewDropDown}
          // dropItems={dropitems}
          // fileName={"productList.xls"}
          // />

          <Table
            columns={columns}
            data={data}
            paginationInfo={{
              pageIndex: Number(query.get("page")),
              nextPage,
              previousPage,
              pageSize: Number(query.get("size")),
              setPageSize: handlePageSize,
              pageInfo
            }}
          />
        ) : null}
      {/* </TemplateForTables> */}
      </Paper>

      {/* <SuccessFeedback
        open={ }
        onClose={handleDialogClose}
        successMessage={t("notification_list_Approve_Success_Message")}
      />
      <FailureFeedback open={failApproveNotification} onClose={handleDialogClose} /> */}
      
     
    </Grid>    
  );
};

export default ProductListComp;
