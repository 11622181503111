import React, { useState, useContext } from "react";
import PageHeader from "components/layout/PageHeader";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CommonForm from "../../../components/common/form/common-form";
import * as Yup from "yup";
import { provisioningService } from "../../../services/provisioningServices";
import Popups from "../../../components/common/popups/Popups";
import { authenticationService } from "../../../services/authServices";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { authContext } from "context/authContext";
const ChangePassword = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(false);
  const { authState, authDispatch } = useContext(authContext);
  const { t } = useTranslation();
  const fields = [
    {
      type: "password",
      name: "oldPassword",
      label: "Current Password",
      placeholder: "Current Password",
      grid: {
        xs: 12,
        sm: 4,
        className: ""
      }
    },
    {
      type: "password",
      name: "newPassword",
      label: t("user_Form_Edit_New_Password_Label"),
      placeholder: t("user_Form_Edit_New_Password_Label"),
      grid: {
        xs: 12,
        sm: 4,
        className: ""
      }
    },
    {
      type: "password",
      name: "confirmPassword",
      label: t("user_Form_Confirm_Password_Label"),
      placeholder: t("user_Form_Confirm_Password_Label"),
      grid: {
        xs: 12,
        sm: 4,
        className: ""
      }
    },
    {
      type: "hr"
    }
  ];
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  };
  const validations = {
    oldPassword: Yup.string().required("You Must Enter Old Password"),
    newPassword: Yup.string().required("You Must Enter New Password"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword")], "Both password need to be the same")
      .required("You Must Enter New Password")
  };
  const handleSubmit = e => {
    provisioningService
      .changePassword(e.oldPassword, e.newPassword)
      .then(res => {
        if (res.status) {
          const user = JSON.parse(localStorage.getItem("currentUser"));
          const token = user && user.jwtAccessToken;
          const userName = user && user.userName;
          setTimeout(async () => {
            authDispatch({
              type: "LOGGING_OUT"
            });
            const user = JSON.parse(localStorage.getItem("currentUser"));
            const token = user && user.jwtAccessToken;
            const userName = user && user.userName;
            authState.logout(userName, token).then(() => history.push("/login"));
            authDispatch({
              type: "LOGGED_OUT"
            });
          }, 1000);
        }
        setStatus(res.status);
        setOpen(true);
        setMessage(res.message);
      });
  };
  const check = cb => {
    cb();
  };
  return (
    <>
      <Grid container xs={12}>
        <Grid item xs={12}>
          <PageHeader
            title="Change Profile Password"
            breadcrumbs={[
              {
                label: t("checklist_Breadcrum_Dashboard_Label"),
                link: "/dashboard"
              },
              { label: t("user_Form_Edit_Change_Password_Label") }
            ]}
          />
        </Grid>
        <Grid container xs={12} md={9}>
          <Paper elevation={2} style={{ padding: "2em", width: "100%" }}>
            <Grid item>
              <h2>{t("user_Form_Edit_Change_Password_Label")}</h2>
            </Grid>
            <Grid item>
              <CommonForm
                fields={fields}
                submitLabel={t("user_Form_Edit_Change_Password_Label")}
                submittingLabel={t("user_Form_Edit_Change_Password_Label")}
                validationSchema={Yup.object().shape({ ...validations })}
                validateOnBlur={true}
                initialValues={initialValues}
                validateOnChange={true}
                onSubmit={(values, { setSubmitting, setFieldError }) => {
                  check(setSubmitting);
                  check(setFieldError);
                  handleSubmit(values);
                }}
                buttonSize="3"
                buttonPosition="right"
              />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {open && (
        <Popups
          open={open}
          setOpen={setOpen}
          message={message}
          status={status}
        />
      )}
    </>
  );
};
export default ChangePassword;
