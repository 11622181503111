import React, { useEffect, useContext, useState, useMemo } from "react";

import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
// import Table from "components/common/table/ControlledPaginationTable";

import Table from "components/common/table/DynamicPaginationTable2";

import PageHeader from "../../../components/layout/PageHeader.js";

import CircularProgress from "@material-ui/core/CircularProgress";
import TemplateForTables from "../../../components/layout/innerPagesTemplate";
import { Grid, Paper } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { notificationService } from "services/notificationServices";
import { provisioningService } from "../../../services/provisioningServices.js";

import { errorContext } from "context/errorContext";
import addDays from "date-fns/addDays";

import "../../../components/Table.scss";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import CommonForm from "components/common/form/common-form";
import { func } from "prop-types";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
import FailureFeedback from "components/common/elements/FailureFeedback";

const TotalNotification = props => {

  const user = JSON.parse(localStorage.getItem("currentUser"));

  const { dispatch: errorDispatch } = useContext(errorContext);
  const [notification, setNotification] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [status, setStatus] = useState("loading");
  const [notificationStatus, setNotificationStatus] = useState("loading");
  const [approveNotificationStatus,setApproveNotificationStatus] = useState("loading");

  const [startDate,setStartDate] = React.useState(addDays(new Date(), -2));
  const [endDate,setEndDate] = React.useState(new Date());
  const [productCode, setProductCode] = React.useState([]);
  const [prodCode, setProdCode] = React.useState("");

  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();
  const createError = useState({});
  const history = useHistory();

  const [successApproveNotification, setSuccessApproveNotification] = useState(false);
  const [failApproveNotification, setFailApproveNotification] = useState(false);

  const [sendNotificationStatus,setSendNotificationStatus] = useState(false);
  const [failSentNotification, setFailSentNotification] = useState(false);

  const [dateAlertMessage, setDateAlertMessage] = useState(false);
  const [validDateRangeMessage, setValidDateRangeMessage] = useState(false);

  const [disableApproveNotification,setDisableApproveNotification] = useState(false);

  let viewDropDown = true;

  let query = useMemo(() => new URLSearchParams(props.location.search), [
    props.location
  ]);

  const dropitems = [
    {
      item: (<Link to="/configuration/createnotification">Add Notification</Link>)
    }
  ];

  if(user.uiPrivileges.includes("CREATE_NOTIFICATION")) {
    viewDropDown = true;
  } else{
    viewDropDown = false;
  }

  function handleSubmit(values, resetForm, setSubmitting) {
    setStartDate(new Date(values.startDate));
    var startDatetime = new Date(values.startDate);
    let sDate = startDatetime
      .toISOString()
      .replace("Z", " ")
      .replace("T", " ")
      .trim();

    setEndDate(new Date(values.endDate));
    var endDatetime = new Date(values.endDate);
    let eDate = endDatetime
        .toISOString()
        .replace("Z", " ")
        .replace("T", " ")
        .trim();

    const diffInMs = Math.floor(endDatetime - startDatetime);
    const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    console.log(days);

    if(days < 0){
      setSubmitting(false);
      setValidDateRangeMessage(true);
      return;
    }

    if(days > 8){
      setSubmitting(false);
      setDateAlertMessage(true);
      return ;
    }

    getNotificationListAPI(values.productCode,sDate,eDate);
    setSubmitting(false);
  }

  const { t } = useTranslation();

  function getNotificationList(){
    notificationService.getNotificationUIOptions('PRODUCTS').then(data => {
      if (data) {
        setProductCode(data);
        setNotificationStatus("success");
        setProdCode(data[0]);
      }
    });
  }

  useEffect(() => {
    provisioningService.verifyOtpNew('1313131313').then((res) => {
      const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
      const organizationId = JSON.parse(localStorage.getItem("orgId"));
      getNotificationList();
    })
    .catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });
  }, []);

  function invokeDefaultNotificationList(){
    var datetime = new Date(startDate);
    let sDate = datetime
      .toISOString()
      .replace("Z", " ")
      .replace("T", " ")
      .trim();
  
    var datetime = new Date(endDate);
    let eDate = datetime
        .toISOString()
        .replace("Z", " ")
        .replace("T", " ")
        .trim();
     
   // let pCode = productCode[0];
    getNotificationListAPI(values.productCode,sDate,eDate);
  }

  const handleDialogClose = () => {
    invokeDefaultNotificationList();
    
   // getNotificationList();
    setSuccessApproveNotification(false);
    setFailApproveNotification(false);
  };

  const handleSendDialogClose = () => {
    invokeDefaultNotificationList();
    //getNotificationList();
    setSendNotificationStatus(false);
    setFailSentNotification(false);
  };

  const handleDateClose = () => {
    setDateAlertMessage(false);
  };

  const handleValidDateAlertClose = () => {
    setValidDateRangeMessage(false);
  }

  let values = {
    productCode: productCode[0],
    startDate: addDays(new Date(), -2),
    endDate: new Date()
  };

  const formikForm = React.useRef(null);

  const fields = [
    {
      type: "select",
      name: "productCode",
      label: t("notification_Form_productCode_Label"),
      placeholder: t("notification_Form_productCode_Label"),
      option:
        productCode.length > 0
          ? productCode.map(productCode => ({
              value: productCode,
              label: productCode
            }))
          : [],
      grid: {
        xs: 12,
        sm: 2,
        md: 3,
        className: ""
      }
    },
    {
      type: "datePicker",
      name: "startDate",
      label: t("listNotification_Form_startDate_Label"),
      placeholder: t("listNotification_Form_startDate_Label"),
      value: { startDate },
      grid: {
        xs: 12,
        sm: 2,
        md: 3,
        className: ""
      }
    },
    {
      type: "datePicker",
      name: "endDate",
      value: { endDate },
      label: t("listNotification_Form_endDate_Label"),
      grid: {
        xs: 12,
        sm: 2,
        md: 3,
        className: ""
      }
    }
  ]; 

  let validations = {
    startDate: Yup.string().required(
        t("listNotification_Form_Validation_startDate")
    ),
    endDate: Yup.string().required(
        t("listNotification_Form_Validation_endDate")
    )
  };

  const nextPage = () => {
    if (
      status !== "loading" &&
      pageInfo.hasNextPage &&
      Number(query.get("page")) < pageInfo.totalPages
    ) {
      query.set("page", Number(query.get("page")) + 1);
      props.history.push({
        pathname: props.location.pathname,
        search: query.toString()
      });
    }
  };
  const previousPage = () => {
    props.history.goBack();
  };
  const handlePageSize = size => {
    query.set("size", size);
    query.set("page", 0);
    props.history.push({
      pathname: props.location.pathname,
      search: query.toString()
    });
  };

  function getNotificationListAPI(pCode,startDate,endDate){

    let sDate =  new Date(startDate);
    startDate = sDate
        .toISOString()
        .substring(0,10);

    let eDate =  new Date(endDate);
    endDate = eDate
        .toISOString()
        .substring(0,10);


    notificationService.getNotificationList(pCode,startDate,endDate).then(data => {
      setNotification(data);
      setStatus("success");
    }).catch(error => {
       console.error(error);
         errorDispatch({
         type: "ERROR",
         error
       });
       setStatus("failed");
    });
  }

  function approveNotification(data){
    let value = {
      configurationId:data.configurationId
    };
    notificationService.invokeApproveNotification(value).then(data => {
      if(data.status === 200){
        setSuccessApproveNotification(true);
       
      }
    }).catch(error => {
      setDisableApproveNotification(false);
       console.error(error);
         errorDispatch({
         type: "ERROR",
         error
       });
       setSuccessApproveNotification(false);
    });
  }

  function sendNotification(data){
    let value = {
      configurationId:data.configurationId
    };

    setDisableApproveNotification(true);
    notificationService.sendNotification(value).then(data => {
      setSendNotificationStatus(true);
      setDisableApproveNotification(false);
    }).catch(error => {
       console.error(error);
         errorDispatch({
         type: "ERROR",
         error
       });
      setFailSentNotification(false);
    });
  }

  useEffect(() => {
    if(productCode.length > 0){
      var datetime = new Date(values.startDate);
      let sDate = datetime
        .toISOString()
        .replace("Z", " ")
        .replace("T", " ")
        .trim();
  
      var datetime = new Date(values.endDate);
      let eDate = datetime
          .toISOString()
          .replace("Z", " ")
          .replace("T", " ")
          .trim();
     
      let pCode = productCode[0];

      getNotificationListAPI(pCode,sDate,eDate);
    }
  }, [productCode]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Notification ID",
        accessor: "id"
      },
      {
        Header: "Language",
        accessor: "actualMessage.language"
      },
      {
        Header: "Title",
        accessor: "actualMessage.title"
      },
      {
        Header: "Mime Type",
        accessor: "actualMessage.mimeType"
      },
      {
        Header: "Target Users",
        accessor: "actualMessage.targetUsers"
      },
      {
        Header: "Publish Channel",
        accessor: "actualMessage.publishChannel"
      },
      {
        Header: "Valid From",
        accessor: "actualMessage.validFrom"
      },
      {
        Header: "Valid UpTo",
        accessor: "actualMessage.validUpTo"
      },
      {
        Header: "Type",
        accessor: "notificationCreateType"
      },
      {
        Header: "Edit",
        Cell: ({ row }) => {
          let link = `/configuration/editnotification/${row.original.configurationId}`;
          return (
            user.uiPrivileges.includes("EDIT_NOTIFICATION") ?
            <>
              {row.original.status === 'NEW' && (
                <Link to={link}>
                  <IconButton>
                    <i className="fa fa-edit" aria-hidden="true"></i>
                  </IconButton>
                </Link>
                
              )} 
            </>: null
          )
        }
      },
      {
        Header: "Approve",
        Cell: ({ row }) => {
          return (
            user.uiPrivileges.includes("APPROVE_NOTIFICATION") 
            ||  user.uiPrivileges.includes("PUBLISH_NOTIFICATION") ?
            <>
              {row.original.status === 'NEW' && (
                <IconButton onClick={() => approveNotification(row.original)}>
                  <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>
                </IconButton>
              )}              
            </>
            : null
          )
        }
      },
      {
        Header: "Send",
        Cell: ({ row }) => {
          return (
            user.uiPrivileges.includes("APPROVE_NOTIFICATION") 
            ||  user.uiPrivileges.includes("PUBLISH_NOTIFICATION") ?
            <>
              {row.original.status === 'APPROVED'  && (
                <IconButton disabled={disableApproveNotification} onClick={() => sendNotification(row.original)}>
                  <i class="fa fa-share-square-o" aria-hidden="true"></i>
                </IconButton>
              )}
            </>
            : null
          )
        }
      }
    ],
    []
  );

  const data = React.useMemo(() => [...notification]);
  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title="Total Notification"
          breadcrumbs={[
            { label: "Dashboard", link: "/dashboard" },
            { label: "Configuration" },
            { label: "Total Notification" }
          ]}
        />
      </Grid>
      <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%"
        }}
      >
      {notificationStatus === "loading" ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ):(
      <CommonForm
        fields={fields}
        submitLabel={t("site_Form_Submit_Label")}
        submittingLabel={t("site_Form_Submitting_Label")}
        initialValues={values}
        validationSchema={Yup.object().shape({ ...validations })}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={(
          values,
          { setSubmitting, setFieldError, resetForm, errors }
        ) => {
          if (!!errors) {
            setSubmitting(false);
          }
          handleSubmit(values, resetForm, setSubmitting);
        }}
        formikRef={formikForm}
        buttonSize="2"
        buttonPosition="right"
        inlineButton={true}
      />
      )}
      {/* <TemplateForTables
        dropdownitems={dropitems}
        needDropdown={true}
        importIcons
      > */}
        {status === "loading" ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "50vh" }}
          >
           <CircularProgress />
          </Grid>
        ) : (

          <Table  
            columns={columns}
            data={data}
            needDropdown={viewDropDown}
            dropItems={dropitems}
            fileName={"notificationList.xls"}
          />
        )}
      {/* </TemplateForTables> */}
      </Paper>

      <SuccessFeedback
        open={successApproveNotification}
        onClose={handleDialogClose}
        successMessage={t("notification_list_Approve_Success_Message")}
      />
      <FailureFeedback open={failApproveNotification} onClose={handleDialogClose} />
      
      <SuccessFeedback
        open={sendNotificationStatus}
        onClose={handleSendDialogClose}
        successMessage={t("notification_list_Send_Success_Message")}
      />
      <FailureFeedback open={failSentNotification} onClose={handleSendDialogClose} />

      <FailureFeedback
        open={dateAlertMessage}
        onClose={handleDateClose}
        status={"Failed"}
        message={t("notification_list_Date_Range_Message")}
        showButtons={true}
      /> 

      <FailureFeedback
        open={validDateRangeMessage}
        onClose={handleValidDateAlertClose}
        status={"Failed"}
        message={t("notification_list_Valid_Date_Range_Message")}
        showButtons={true}
      />       
    </Grid>    
  );
};

export default TotalNotification;
