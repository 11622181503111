import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5"
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles(theme => ({
  root: {
    fontSize: "14px",
    minHeight: "30px",
    "& a": {
      color: "black"
    },
    "&:hover": {
      backgroundColor: "rgba(63,81,181,0.08)"
    }
  }
}))(MenuItem);

export default function PopupMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = e => {
    if (typeof e.target !== "undefined") {
      e.target.value === 0 && !!props.onclick && props.onclick();
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        onClick={handleClick}
        classes={{ root: props.rootStyle ? props.rootStyle : "" }}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.droplist.map((val, idx, arr) => {
          return (
            <StyledMenuItem onClick={handleClose} key={idx}>
              {val.item}
            </StyledMenuItem>
          );
        })}
      </StyledMenu>
    </div>
  );
}
