import React, { forwardRef, useContext } from "react";

import { Link, NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ReactEcharts from "echarts-for-react";

import chilled from "../../../assets/chilled_color.png";
import chilling from "../../../assets/chilling_color.png";
import under_chilled from "../../../assets/under_chilled_color.png";
import no_data from "../../../assets/no_data_color.png";
import others from "../../../assets/others.png";
import liveData from "../../../assets/live-data.gif";

import PopupMenu from "../../../components/layout/menuPopup";

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardM from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import InfoIcon from "@material-ui/icons/Info";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import { ToolTip } from "../../../components/common/table/ToolTipCell";

import { dashboardContext } from "context/dashboardContext";

function BMCStatusCard({ data = [], bmcError, ...props }) {
  const useStyles = makeStyles(theme => ({
    root: {
      textAlign: "center",
      paddingTop: "1em",
      fontSize: "14px",
      "& a": {
        color: "black",
        "& :hover ": {
          color: "#166aab"
        }
      },
      "& :hover ": {
        color: "#166aab"
      }
    },

    headerStyles: {
      padding: "0px",
      backgroundColor: "#166aab",
      "& span": {
        color: "white"
      }
    },
    actionStyle: {
      "&:hover": {
        backgroundColor: "rgba(255,255,255,.08) !important"
      }
    },
    cardActions: {
      marginTop: "0px"
    },
    card: {
      height: "100%",
      display: "flex",
      flexDirection: "column"
    },
    listStyle: {
      textDecorationLine: "none",
      "& a": {
        color: "black",
        textDecorationLine: "none"
      },
      // "& a:hover": {
      //   color: "#F6F9FC"
      // },
      "& a  > div:nth-child(2)": {
        overflow: "hidden",
        whiteSpace: "noWrap",
        textOverflow: "ellipsis"
      },
      "& a > * >div:first-child": {
        width: "3em",
        height: "3em",
        marginRight: "1em",
        "& img": {
          width: "85%",
          height: "85%"
        }
      },
      "& span": {
        color: "black"
      }
    },
    count: {
      color: "#2E384D",
      fontSize: "1.3em",
      fontWeight: "601",
      paddingLeft: ".8em"
    },
    liveStyle: {
      color: "black",
      fontSize: ".8em",
      fontWeight: "501",
      padding: "0em 0em 0em 1em"
    },
    cardContentStyle: {
      padding: "0px 8px 0px 8px",
      height: "100%"
    },
    buttonStyle: {
      borderRadius: "3em",
      padding: "0px 16px 0px 0px",
      "&:hover": {
        borderRadius: "3em",
        backgroundColor: "rgba(63,81,181,0.08)"
      }
    }
  }));

  const classes = useStyles();

  function getOption() {
    return {
      tooltip: {
        trigger: "item",
        formatter: "{b}: {c} ({d}%)",
        confine: true
      },
      series: [
        {
          name: "BMC Status",
          type: "pie",
          radius: ["40%", "80%"],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: "inside",
            formatter: params => {
              if (params.percent > 5) return `${params.percent}%`;
              else return "";
            },
            fontSize: 12,
            fontFamily: "Roboto"
          },
          data: [
            {
              value: !!data ? data.CHILLED : 0,
              name: "Chilled",
              itemStyle: { color: "#296AAB" }
            },
            {
              value: !!data ? data.CHILLING : 0,
              name: "Chilling",
              itemStyle: { color: "#ff0505" }
            },
            {
              value: !!data ? data["UNDER CHILLED"] : 0,
              name: "Under Chilled",
              itemStyle: { color: "#ff8f00" }
            },
            {
              value: !!data ? data["NO DATA RECORDED"] : 0,
              name: "No Data",
              itemStyle: { color: "#1089ff" }
            },
            {
              value: !!data ? data.OTHERS : 0,
              name: "Others",
              itemStyle: { color: "#2f4858" }
            }
          ]
        }
      ]
    };
  }

  const { t } = props;

  const dropitems = [
    {
      item: t("card_dot_menu_close")
    }
  ];

  const { state: dashboardState } = useContext(dashboardContext);

  return (
    <CardM className={classes.card} elevation={2}>
      <CardHeader
        classes={{
          action: classes.cardActions, //to remove -8px margin
          root: classes.headerStyles
        }}
        avatar={
          <IconButton aria-label="search in bmc card">
            {/* <SearchIcon /> */}
          </IconButton>
        }
        action={
          <div style={{ display: "flex" }}>
            <IconButton
              aria-label="info in bmc card"
              classes={{ root: classes.actionStyle }}
            >
              <InfoIcon />
            </IconButton>
            {/* <IconButton aria-label="settings in bmc card">
              <MoreVertIcon />
            </IconButton> */}
            <PopupMenu
              droplist={dropitems}
              rootStyle={classes.actionStyle}
              onclick={props.onClick}
            />
          </div>
        }
        title={t("bmc card title")}
        subheader=""
      />
      <CardContent
        className="position-relative"
        classes={{ root: classes.cardContentStyle }}
        style={
          {
            paddingBottom: "0px"
          } /*to remove the default 24px padding for :last-child*/
        }
      >
        {props.status === "loading" && (
          <div className="card-loader">
            <CircularProgress />
            <span className="ml-2">Loading Data...</span>
          </div>
        )}
        {props.status === "failed" && (
          <div className="card-loader ">
            <span>
              {!!bmcError && !!bmcError.error
                ? !!bmcError.error.message
                  ? bmcError.error.message
                  : "Error in loading"
                : "Error in loading"}
            </span>
          </div>
        )}
        <Grid container alignContent="space-between" style={{ height: "100%" }}>
          <Grid container item xs={12}>
            <Grid
              item
              xs={12}
              md={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <ReactEcharts
                option={getOption()}
                style={{
                  height: "15rem",
                  width: "100%"
                }}
              // opts={{ renderer: "svg" }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "1em 0em 1em 0em" }}>
              <List component="ul" dense={true} className={classes.listStyle}>
                <ListItem
                  classes={{
                    button: classes.buttonStyle
                  }}
                  button
                  component={forwardRef((props, ref) => (
                    <NavLink {...props} innerRef={ref} />
                  ))}
                  to={{
                    pathname: "/all-bmc/chilled",
                    search: `?page=0&paging=&date=${dashboardState.currentDate}`
                  }}
                >
                  <ListItemAvatar>
                    <Avatar alt="" src={chilled} />
                  </ListItemAvatar>
                  <ToolTip name={t("chilled")}>
                    <ListItemText primary={t("chilled")} />
                  </ToolTip>
                  <div className={classes.count}>
                    {!!data ? data.CHILLED : 0}
                  </div>
                </ListItem>

                <ListItem
                  button
                  classes={{
                    button: classes.buttonStyle
                  }}
                  component={forwardRef((props, ref) => (
                    <NavLink {...props} innerRef={ref} />
                  ))}
                  to={{
                    pathname: "/all-bmc/chilling",
                    search: `?page=0&paging=&date=${dashboardState.currentDate}`
                  }}
                >
                  <ListItemAvatar>
                    <Avatar alt="" src={chilling} />
                  </ListItemAvatar>
                  <ToolTip name={t("chilling")}>
                    <ListItemText primary={t("chilling")}></ListItemText>
                  </ToolTip>
                  <div className={classes.count}>
                    {!!data ? data.CHILLING : 0}
                  </div>
                </ListItem>

                <ListItem
                  button
                  classes={{
                    button: classes.buttonStyle
                  }}
                  component={forwardRef((props, ref) => (
                    <NavLink {...props} innerRef={ref} />
                  ))}
                  to={{
                    pathname: "/all-bmc/under chilled",
                    search: `?page=0&paging=&date=${dashboardState.currentDate}`
                  }}
                >
                  <ListItemAvatar>
                    <Avatar alt="" src={under_chilled} />
                  </ListItemAvatar>
                  <ToolTip name={t("under_chilled")}>
                    <ListItemText primary={t("under chilled")}></ListItemText>
                  </ToolTip>
                  <div className={classes.count}>
                    {!!data ? data["UNDER CHILLED"] : 0}
                  </div>
                </ListItem>

                <ListItem
                  button
                  classes={{
                    button: classes.buttonStyle
                  }}
                  component={forwardRef((props, ref) => (
                    <NavLink {...props} innerRef={ref} />
                  ))}
                  to={{
                    pathname: "/all-bmc/no_data",
                    search: `?page=0&paging=&date=${dashboardState.currentDate}`
                  }}
                >
                  <ListItemAvatar>
                    <Avatar alt="" src={no_data} />
                  </ListItemAvatar>
                  <ToolTip name={t("no data")}>
                    <ListItemText primary={t("no data")}></ListItemText>
                  </ToolTip>
                  <div className={classes.count}>
                    {!!data ? data["NO DATA RECORDED"] : 0}
                  </div>
                </ListItem>

                <ListItem
                  button
                  classes={{
                    button: classes.buttonStyle
                  }}
                  component={forwardRef((props, ref) => (
                    <NavLink {...props} innerRef={ref} />
                  ))}
                  to={{
                    pathname: "/all-bmc/others",
                    search: `?page=0&paging=&date=${dashboardState.currentDate}`
                  }}
                >
                  <ListItemAvatar>
                    <Avatar alt="" src={others} />
                  </ListItemAvatar>
                  <ToolTip name={t("others")}>
                    <ListItemText primary={t("others")}></ListItemText>
                  </ToolTip>
                  <div className={classes.count}>
                    {!!data ? data.OTHERS : 0}
                  </div>
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            alignContent="center"
            style={{ padding: "1em", marginBottom: "1.6em" }}
            classes={{ root: classes.root }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={liveData} alt="blinkng live data indicator" />
            </div>
            <Link
              to={{
                pathname: "/all-bmc",
                search: `?date=${dashboardState.currentDate}`
              }}
            >
              <span className={classes.liveStyle}>{t("all bmcs")}</span>
            </Link>
          </Grid>
        </Grid>
      </CardContent>
    </CardM>
  );
}

export default withTranslation()(BMCStatusCard);
