import React, { useEffect, useContext }  from 'react';
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import 'bootstrap/dist/css/bootstrap.css';
import { Paper } from '@material-ui/core';
import PageHeader from "components/layout/PageHeader";
import ExcelIcon from "../../../components/common/elements/ExcelIcon";
import { AgentPaymentImportService } from "../../../services/agentpayment-import";
import SweetAlert from 'react-bootstrap-sweetalert';
import importAgentPaymentTemplate from "components/Files/importAgentPaymentTemplate.xlsx";
import { provisioningService } from "services/provisioningServices";
import './importAgentPayment.css';
import { errorContext } from "context/errorContext";

const ColorButton = withStyles(theme => ({
    root: {
        backgroundImage: "linear-gradient(to bottom, #34409A, #14AAE4)",
        width: "100%",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "linear-gradient(to bottom, #34409A, #14AAE4)"
        }
    }
}))(Button);

const ImportAgentPayment = () => {
    const agentPaymentImportService = new AgentPaymentImportService();
    const [showUploadSuccessAlert, setShowUploadSuccessAlert] = React.useState(false);
    const [showUploadFailureAlert, setShowUploadFailureAlert] = React.useState(false);
    const [showUploadFailureAlertMessage, setShowUploadFailureAlertMessage] = React.useState("");
    const [showUploadFailureAlertStatus, setShowUploadFailureAlertStatus] = React.useState("");

    const [agentPaymentImport, setAgentPaymentImport] = React.useState("");
    const [agentPaymentExcel, setAgentPaymentExcel] = React.useState("");
    const [agentPaymentFilename, setAgentPaymentFilename] = React.useState("");
    const user = JSON.parse(localStorage.getItem("currentUser"));

    const { dispatch: errorDispatch } = useContext(errorContext);

    useEffect(() => {
        provisioningService
        .verifyOtpNew('1313131313')
        .then((res) => {
          const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
        })
        .catch(error => {
          errorDispatch({
            type: "ERROR",
            error
          });
        });
    }, []);

    const resetHandler = (event) => {
        setAgentPaymentImport("");   
        setAgentPaymentFilename("");
    }
    
    const hideAlert = (event) => {
        setShowUploadFailureAlert(false);
        setShowUploadSuccessAlert(false);
        resetHandler();
    }

    const previewAgentPayment = (event) => {
        setAgentPaymentExcel(event.target.files[0]);
        setAgentPaymentFilename(event.target.files[0].name);
    }   

    const uploadAgentPayment = (event) => {
        const data = new FormData();
        data.append('file', agentPaymentExcel);
        agentPaymentImportService.triggerAgentPaymentImport(data).then((response) => {
            if (response.status === 200) {
                setShowUploadSuccessAlert(true);
                setAgentPaymentExcel("");
            } else {
                setShowUploadFailureAlert(true);
            }
        })
            .catch(function (error) {
                console.log(error);
                if (error.response.data) {
                    setShowUploadFailureAlert(true);
                    setShowUploadFailureAlertMessage(error.response.data.message);
                    setShowUploadFailureAlertStatus(error.response.data.status);
                } else {
                    //some other error happened
                    setShowUploadFailureAlert(true);
                }
            })
    }  

    const backAction = (event) => {
        window.history.go(-1);
    }

    if (!showUploadSuccessAlert && !showUploadFailureAlert) {
        return (
            <Grid container>
                <Grid item xs={12} style={{ marginBottom: "15px" }}>
                    <PageHeader title="Import Agent Payment"
                        breadcrumbs={[
                            { label: "Agent Payment" },
                            { label: "Import Agent Payment" }
                        ]} />
                </Grid>
                <Grid item xs={9} md={8}>
                    <Paper elevation={2} style={{ width: "100%", padding: "1.5em" }}>
                        {user.uiPrivileges.includes("ALL_PRIVILEGE") || user.uiPrivileges.includes("CONFIGURATION") ?
                            <header style={{
                                fontSize: "16px", color: "#166aab",
                                fontWeight: "bold"
                            }}>Agent Payment </header> : null}
                        {user.uiPrivileges.includes("ALL_PRIVILEGE") || user.uiPrivileges.includes("CONFIGURATION") ?
                            <Grid item container justify="space-between" alignItems="center"
                                style={{ padding: "1em" }}>
                                <Grid item container md={6} alignItems="center">
                                    <span style={{ fontSize: "14px" }}>Choose File </span>
                                    <ExcelIcon />
                                    <a href={importAgentPaymentTemplate} download="importAgentPaymentTemplate.xlsx">Download File
                                    <i className="fa fa-download" id="iconId"></i>
                                    </a>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    {agentPaymentExcel ? <div>
                                        <header style={{ fontSize: "14px", color: "#166aab" }}>{agentPaymentFilename}</header>
                                        <div style={{ display: "flex", marginTop: "10px" }}>
                                            <div style={{ width: "50%", marginRight: "3px" }}>
                                                <Button variant="contained" color="secondary" onClick={() => setAgentPaymentExcel("")}
                                                    fullWidth={true} startIcon={<DeleteIcon />} style={{ textTransform: "capitalize" }} >
                                                    Clear
                                    </Button>
                                            </div>
                                            <div style={{ width: "50%", marginLeft: "3px" }}>
                                                <Button variant="contained" color="primary" onClick={uploadAgentPayment}
                                                    fullWidth={true} startIcon={<CloudUploadIcon />} style={{ textTransform: "capitalize" }} >
                                                    Upload
                                    </Button>
                                            </div>
                                        </div>
                                    </div> : <ColorButton variant="contained"
                                        color="primary"
                                        component="label"
                                        className="align-self-end w-100 text-capitalize">
                                            Browse
                                    <Input type="file" onChange={previewAgentPayment}
                                                style={{ display: "none" }}
                                                inputProps={{ accept: ".xlsx, .xls, .csv" }}
                                                name="agentPaymentImport" value={agentPaymentImport} />
                                        </ColorButton>}
                                </Grid>
                            </Grid> : null}


                        <div className="row" style={{ marginTop: "30px" }}>
                            <div className="col-md-12 col-xs-12"
                                style={{ borderBottom: "1px solid #DEE9E8" }}></div>
                        </div><br />
                        <div className="row">
                            <div className="col-md-3 col-xs-3"></div>
                            <div className="col-md-3 col-xs-3"></div>
                            <div className="col-md-3 col-xs-3"></div>
                            <div className="col-md-3 col-xs-3">
                                <button style={{
                                    backgroundColor: "#166aab",
                                    border: "0",
                                    color: "white"
                                }}
                                    onClick={backAction}
                                    className="form-control">Back
                            </button>
                            </div>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        );
    } else if (showUploadSuccessAlert) {
        return (
            <>
            <SweetAlert success
                        title="Success!" 
                        onConfirm={hideAlert}
                        //disabled={ disableOkBtn ? true : false }
                        onCancel={hideAlert}
                        >
                <br />
                <span style={{ fontSize: "14px", color: "green" }}><b>File Upload Successfull !!</b></span>
            </SweetAlert>
            </>
        );
    } else {
        //return null;
        return (
            <>
                <SweetAlert danger
                        title="Failed!" 
                        onConfirm={hideAlert}
                        onCancel={hideAlert}
                >
                <br />
                <span style={{ fontSize: "14px", color: "orange" }}><b>Message: {showUploadFailureAlertMessage}</b></span>
                <br />
                <span style={{ fontSize: "14px", color: "orange" }}><b>Status: {showUploadFailureAlertStatus}</b></span>
                </SweetAlert>
            </>
        )
    }
}

export default ImportAgentPayment;
