import React, { useState, useEffect, useRef, useContext } from "react";

import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";

import CommonForm from "../../../components/common/form/common-form";
import { metadataService } from "../../../services/metadataServices";
import PageHeader from "../../../components/layout/PageHeader";
import FailureFeedback from "components/common/elements/FailureFeedback";
import AlertsConfigurationTable from "page-containers/ConfigurationPages/User/AlertsConfigurationTable";
import { errorContext } from "context/errorContext";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import "../../../components/Table.scss";
import UserPasswordChangeFields from "components/common/form/UserPasswordChangeFields";
import { useTranslation } from "react-i18next";


export default function UserForm({
  values,
  subOrganizations,
  subOrganizationsLoading,
  handleSubOrganizationSelect,
  handleSubOrganizationForSiteAssociationSelect,
  handleSubmit,
  createError,
  sitesList,
  sitesListLoading,
  sitesAssociationList,
  sitesAssociationListLoading,
  handleSiteSelectForAlertsConfiguration,
  handleSiteAssociationSelect,
  sitesAlertsConfiguration, // sites
  status,
  edit,
  formRef
}) {
  const { dispatch: errorDispatch } = useContext(errorContext);

  const org = JSON.parse(localStorage.getItem("currentUser")).organizationName;
  const dispatch = useDispatch();
  const formikRef = useRef(null);

  const [genderTypes, setGenderTypes] = useState([]);
  const [salutationTypes, setSalutationTypes] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [alertLanguages, setAlertLanguages] = useState([]);

  const { t } = useTranslation();

  const roles = useSelector(state => state.userConfiguration.toJS().roles);

  // DidMount
  useEffect(() => {
    metadataService
      .getGenderTypes()
      .then(data => {
        let types = data.map(type => type.typeName);
        setGenderTypes(types);
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });

    metadataService
      .getCountryCodes()
      .then(data => {
        let types = data.map(type => type.typeName);
        setCountryCodes(types);
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });

    metadataService
      .getSalutationTypes()
      .then(data => {
        let types = data.map(type => type.typeName);
        setSalutationTypes(types);
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });

    metadataService
      .getAlertLanguages()
      .then(data => {
        let types = data.map(type => type.typeName);
        setAlertLanguages(types);
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });

    dispatch({
      type: "USER_CONFIGURATION_FETCH_ROLES",
      orgName: org
    });
    //console.log("current", formikRef.current);
  }, []);

  const handleDialogClose = () => {
    dispatch({
      type: "USER_CONFIGURATION_STATUS_UPDATE",
      status: "idle"
    });
  };

  const fields = [
    {
      type: "completedPercentage",
      formikRef: formikRef,
      title: t("user_Form_Percentage_Label")
    },
    {
      type: "select",
      name: "salutation",
      label: t("user_Form_Salutation_Label"),
      placeholder: t("user_Form_Salutation_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      },
      option: salutationTypes.map(type => ({
        value: type,
        label: type
      })),
      visible: "hide"
    },
    {
      type: "text",
      name: "firstName",
      label: t("user_Form_First_Name_Label"),
      placeholder: t("user_Form_First_Name_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "lastName",
      label: t("user_Form_Last_Name_Label"),
      placeholder: t("user_Form_Last_Name_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "select",
      name: "gender",
      label: t("user_Form_Gender_Label"),
      placeholder: t("user_Form_Gender_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      },
      option: genderTypes.map(type => ({
        value: type,
        label: type
      })),
      visible: "hide"
    },
    {
      type: "text",
      name: "userName",
      autoComplete:"new-userName",
      label: t("user_Form_User_Name_Label"),
      placeholder: t("user_Form_User_Name_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    !edit && {      
      type: "password",
      name: "password",
      autoComplete: "new-password",
      label: t("user_Form_Password_Label"),
      placeholder:  t("user_Form_Password_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    !edit && {
      type: "password",
      name: "confirmPassword",
      label: t("user_Form_Confirm_Password_Label"),
      placeholder: t("user_Form_Confirm_Password_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "select",
      name: "mobileNumber[0].countryCode",
      label: t("user_Form_Country_Code_Label"),
      placeholder: t("user_Form_Country_Code_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      },
      option:
        countryCodes.length > 0
          ? countryCodes.map(type => ({
            value: type,
            label: type
          }))
          : []
    },
    {
      type: "text",
      name: "mobileNumber[0].mobileNumber",
      label: t("user_Form_Contact_Number_Label"),
      placeholder: t("user_Form_Contact_Number_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "emailId",
      label: t("user_Form_Email_Label"),
      placeholder: t("user_Form_Email_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "select",
      name: "roles",
      label: t("user_Form_Role_Label"),
      placeholder: t("user_Form_Role_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      },
      option:
        !!roles &&
        roles.map(role => ({
          value: role.roleName,
          label: role.roleName
        })),
      visible: "hide"
    },
    {
      type: "text",
      name: "organizationName",
      disabled: true,
      label: t("user_Form_Reporting_Org_Label"),
      placeholder: t("user_Form_Reporting_Org_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      },
      visible: "hide"
    },
    // edit
    //   ? {
    //     type: "header",
    //     name: t("user_Form_Edit_Change_Password_Label")
    //   }
    //   : null,
    // edit
    //   ? {
    //     type: "custom",
    //     component: ({ setFieldValue, values }) => (
    //       <UserPasswordChangeFields
    //         userField="userName"
    //         setFieldValue={setFieldValue}
    //         passwordField="password"
    //         confirmPasswordField="confirmPassword"
    //         values={values}
    //       />
    //     )
        // userField: "userName",
        // passwordField: "password",
        // confirmPasswordField: "confirmPassword"
      // }
      // : null,
    edit
      ? {
        type: "hr"
      }
      : {
        type: "hr"
      },

    // {
    //   type: "header",
    //   name: t("user_Form_Site_Allocation_Label")
    // },
    // {
    //   type: "multiSelectAll",
    //   name: "subOrganizationForSiteAssociation",
    //   label: t("user_Form_Sub_Org_Label"),
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "multi-select"
    //   },
    //   visible: "hide",
    //   option: subOrganizations.map(subOrg => ({
    //     label: subOrg.orgName,
    //     value: subOrg.orgName
    //   })),
    //   customOnChange: handleSubOrganizationForSiteAssociationSelect
    // },
    // {
    //   type: "multiSelectAll",
    //   name: "siteAssociationConfiguration",
    //   label: t("user_Form_Site_Association_Label"),
    //   placeholder: t("user_Form_Site_Association_Label"),
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   },
    //   option:
    //     sitesAssociationList.length > 0
    //       ? sitesAssociationList.map(site => ({
    //         value: site.siteName,
    //         label: site.siteName
    //       }))
    //       : [],
    //   visible: "hide",
    //   loading: sitesAssociationListLoading,
    //   customOnChange: handleSiteAssociationSelect
    // },
    // {
    //   type: "header",
    //   name: t("user_Form_Alerts_Config_Label")
    // },
    // {
    //   type: "multiSelectAll",
    //   name: "subOrganizationName",
    //   label: t("user_Form_Alert_Config_Sub_Org_Label"),
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "multi-select"
    //   },
    //   visible: "hide",
    //   option: subOrganizations.map(subOrg => ({
    //     label: subOrg.orgName,
    //     value: subOrg.orgName
    //   })),
    //   loading: subOrganizationsLoading,
    //   customOnChange: handleSubOrganizationSelect
    // },
    // {
    //   type: "multiSelectAll",
    //   name: "sitesForAlertsConfiguration",
    //   label: t("user_Form_Alert_Config_Site_Label"),
    //   placeholder: t("user_Form_Alert_Config_Site_Label"),
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   },
    //   option:
    //     sitesList.length > 0
    //       ? sitesList.map(site => ({
    //         value: site.siteName,
    //         label: site.siteName
    //       }))
    //       : [],
    //   visible: "hide",
    //   loading: sitesListLoading,
    //   customOnChange: handleSiteSelectForAlertsConfiguration
    // },
    // // {
    // //   type: "alertsConfigTable",
    // //   name: "userAlertConfiguration",
    // //   alertConfig: sitesAlertsConfiguration,
    // //   siteField: "siteAssociationConfiguration",
    // //   grid: {
    // //     xs: 12
    // //   }
    // // },
    // {
    //   type: "custom",
    //   component: ({ setFieldValue, values }) => (
    //     <AlertsConfigurationTable
    //       handleChange={setFieldValue}
    //       configValues={values["userAlertConfiguration"]}
    //       alertConfig={sitesAlertsConfiguration}
    //       sitesSelected={values["siteAssociationConfiguration"]}
    //       field={"userAlertConfiguration"}
    //       edit={edit}
    //       siteField={"siteAssociationConfiguration"}
    //     />
    //   )
    // },
    // {
    //   type: "select",
    //   name: "alertsLanguage",
    //   label: t("user_Form_Alert_Language_Label"),
    //   placeholder: t("user_Form_Alert_Language_Label"),
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   },
    //   option:
    //     alertLanguages.length > 0
    //       ? alertLanguages.map(type => ({
    //         value: type,
    //         label: type
    //       }))
    //       : []
    // }
  ];

  let validations = {
    salutation: Yup.string()
      .required(t("user_Form_Validation_Salutation_Type"))
      .typeError(t("user_Form_Validation_Salutation_Type")),
    firstName: Yup.string()
      .required(t("user_Form_Validation_First_Name"))
      .typeError(t("user_Form_Validation_First_Name")),
    // lastName: Yup.string()
    //   .required("You must enter a valid Last Name")
    //   .typeError("You must enter a valid Last Name"),
    gender: Yup.string()
      .required(t("user_Form_Validation_Gender"))
      .typeError(t("user_Form_Validation_Gender")),
    userName:
      !edit &&
      Yup.string()
        .min(3, t("user_Form_Validation_User_Name_Min_Char"))
        .required(t("user_Form_Validation_User_Name"))
        .typeError(t("user_Form_Validation_User_Name")),
    password: edit
      ? Yup.string()
        .min(6, t("user_Form_Validation_Password_Min_Char"))
        .max(15, t("user_Form_Validation_Password__Max_Char"))
        .typeError(t("user_Form_Validation_Password"))
      : Yup.string()
        .min(6, t("user_Form_Validation_Password_Min_Char"))
        .max(15, t("user_Form_Validation_Password__Max_Char"))
        .required(t("user_Form_Validation_Password"))
        .typeError(t("user_Form_Validation_Password")),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      t("user_Form_Validation_Confirm_Password")
    ),
    mobileNumber: Yup.array()
      .of(
        Yup.object().shape({
          countryCode: Yup.string().required(t("user_Form_Validation_Country_Code")),
          mobileNumber: Yup.number()
            .test(
              "len",
              t("user_Form_Validation_Mobile_Number_Length"),
              val => val.toString().length === 10
            )
            .required(t("user_Form_Validation_Mobile_Number"))
            .typeError(t("user_Form_Validation_Mobile_Number_Type_Error"))
        })
      )
      .required(""),
    emailId: Yup.string()
      .email(t("user_Form_Validation_Email"))
      .required(t("user_Form_Validation_Email_Required"))
      .typeError(t("user_Form_Validation_Email_Type_Error")),
    roles: Yup.string()
      .required(t("user_Form_Validation_Role"))
      .typeError(t("user_Form_Validation_Role")),
    organizationName: Yup.string()
      .required(t("user_Form_Validation_Org_Name"))
      .typeError(t("user_Form_Validation_Org_Name"))
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("user_Form_Header_Label")}
          breadcrumbs={[
            { label: t("user_Form_Breadcrum_Dashboard_Label"), link: "/dashboard" },
            { label: t("user_Form_Breadcrum_Configuration_Label") },
            { label: t("user_Form_Breadcrum_User_Label") }
          ]}
        />
      </Grid>

      <Grid container>
        <Paper elevation={2} style={{ padding: "2em", width: "100%" }}>
          {" "}
          <Grid item xs={12}>
            <h2>{t("user_Form_Title_Label")}</h2>
          </Grid>
          <Grid item>
            <CommonForm
              fields={fields}
              submitLabel={t("user_Form_Submit_Label")}
              submittingLabel={t("user_Form_Submitting_Label")}
              initialValues={values}
              validationSchema={Yup.object().shape(validations)}
              validateOnBlur={true}
              edit={edit}
              validateOnChange={true}
              onSubmit={(values, { resetForm, setSubmitting }) => {
                handleSubmit(values, resetForm, setSubmitting);
              }}
              formikRef={formikRef}
              buttonSize="3"
              buttonPosition="right"
            />
          </Grid>
        </Paper>
      </Grid>
      <FailureFeedback
        open={status === "failed"}
        status={!!createError ? createError.status : ""}
        message={
          !!createError
            ? !!createError.error.message
              ? createError.error.message
              : ""
            : ""
        }
        onClose={handleDialogClose}
      />
    </Grid>
  );
}
