import React, { useState, useContext, useMemo } from "react";

import { Link } from "react-router-dom";

import { chillingUnitService } from "services/chillingUnitService.js";
import PageHeader from "components/layout/PageHeader";
import TemplateForTables from "../../components/layout/innerPagesTemplate";
import DynamicPaginationTable from "components/common/table/DynamicPaginationTable";

import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import { dashboardContext } from "context/dashboardContext";
import { errorContext } from "context/errorContext";
import { useTranslation } from "react-i18next";

const InfoAlerts = (props) => {
  const [b, setB] = useState("");
  const [returnLink, setReturnLink] = useState("");
  const { t } = useTranslation();

  React.useEffect(() => {
    let link = "";
    if (props.location.pathname === "/totalsites/info-alerts") {
      link = `configuration/${props.location.state.from}`;
      setB("Total Sites");
      setReturnLink(link);
    } else if (props.location.pathname === "/all-bmc") {
      link = `/${props.location.pathname}/${props.location.state.state}`;
      setReturnLink(link);
      setB("All BMCs");
    }
  }, []);

  const [alertData, setData] = useState([]);
  const [status, setStatus] = React.useState("fetch");

  const [pagingInfo, setPaging] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);

  const dashboardData = useContext(dashboardContext);
  const { dispatch: errorDispatch } = useContext(errorContext);

  let query = useMemo(() => new URLSearchParams(props.location.search), [
    props.location,
  ]);

  React.useEffect(() => {
    // if alerts data loading, wait
    // else, if not alert data, and no site/bmc ids, redirect to dashboard
    // otherwise fetch data
    setHasNextPage(true);
    (async () => {
      setHasNextPage(true);
      setPageIndex(Number(query.get("page")));
      if (status !== "loading") {
        try {
          setStatus("loading");
          const result = await chillingUnitService.searchAlerts(
            "Info Alerts",
            query.get("paging"),
            24,
            query.get("date"),
            [query.get("chillingUnits")],
            [query.get("sites")]
          );
          if (result.content) {
            setData(result.content.RESULT);
            if (!!result.content.PAGING) {
              setPaging(result.content.PAGING);
            } else {
              setHasNextPage(false);
            }
          } else {
            setHasNextPage(false);
          }
          setStatus("idle");
        } catch (error) {
          errorDispatch({
            type: "ERROR",
            error,
          });
        }
      }
    })();
  }, [query]);

  const nextPage = () => {
    if (hasNextPage) {
      query.set("paging", pagingInfo);
      query.set("page", pageIndex + 1);
      props.history.push({
        path: "/critical-alerts",
        search: query.toString(),
      });
    }
  };

  const previousPage = () => {
    let index = pageIndex;
    if (index > 0) {
      let paging = pagingInfo;
      paging.pop();
      setPaging(paging);
      setPageIndex(--index);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("info_Alerts_Site_Name"),
        accessor: "siteName",
        Cell: ({ row }) => {
          return (
            <Link to={`/totalsites/site-details/${row.original.siteId}`}>
              <span>{row.original.siteName}</span>
            </Link>
          );
        },
      },
      {
        Header: t("info_Alerts_Bmc_Id"),
        accessor: "chillingUnitId",
      },
      {
        Header: t("info_Alerts_Type_Of_Alert"),
        accessor: "alertLevel",
      },
      {
        Header: t("info_Alerts_Message"),
        accessor: "alertMessage",
        Cell: ({ row }) => {
          return (
            <div
              style={{
                whiteSpace: "pre",
                textAlign: "left",
                paddingLeft: "1em",
              }}
            >
              {row.original.alertMessage}
            </div>
          );
        },
      },
    ],
    []
  );

  const data = React.useMemo(() => [...alertData]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("info_Alerts_Header_Label")}
          breadcrumbs={[
            { label: t("info_Alerts_Breadcrum_Dashboard_Label"), link: "/dashboard" },
            { label: t("info_Alerts_Breadcrum_All_Bmc") },
            { label: t("info_Alerts_Breadcrum_Events") },
          ]}
        />
      </Grid>
      <TemplateForTables needDropdown={false} importIcons>
        {status === "loading" ? (
          <Grid
            container
            xs={12}
            justify="center"
            alignItems="center"
            style={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
            <DynamicPaginationTable
              columns={columns}
              data={data}
              paginationInfo={{
                pageIndex,
                nextPage,
                previousPage,
                hasNextPage,
              }}
            />
          )}
      </TemplateForTables>
    </Grid>
  );
};

export default InfoAlerts;
