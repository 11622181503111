import React, { useState, useEffect } from "react";
import { FieldArray, Field, getIn } from "formik";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { TextField } from "formik-material-ui";

export default function DeviceParam({
  parentIndex,
  name,
  values,
  errors,
  setFieldError,
  setFieldTouched,
  setFieldValue,
  metaProps,
  devices
}) {
  const { deviceAttributes, applicationAttributes, unitValueTypes } = values;
  async function addAttributeProfile(childIndex, push) {
    try {
      let attributeValues = {
        deviceAttribute: getIn(
          values,
          `l0Config.${childIndex}.deviceAttribute`
        ),
        applicationAttribute: getIn(
          values,
          `l0Config.${childIndex}.applicationAttribute`
        ),
        unit: getIn(values, `l0Config.${childIndex}.unit`)
      };
      //condition to push to array
      push({
        deviceAttribute: "",
        applicationAttribute: "",
        unit: ""
      });
    } catch (e) {
      setFieldError(`${name}.${childIndex}.serialNumber`, e.message);
    }
  }
  return (
    <FieldArray name={`${name}`}>
      {({ remove, push, form }) => {
        let { values, setFieldError, setFieldTouched } = form;
        // let length = getIn(values, `${name}`).length;
        return (
          <>
            {getIn(values, `${name}`).length > 0 ? (
              getIn(values, `${name}`).map((items, childIndex) => (
                <>
                  <Grid
                    container
                    item
                    xs={12}
                    className="device-wrapper"
                    key={`${name}.${childIndex}`}
                    justify="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item className="pointer" align="center" xs={9} md={2} lg={1}>
                   
                    </Grid>
                    <Grid item xs={11} md={6} lg={3}>
                      <Field
                        select
                        name={`${name}.${childIndex}.deviceAttribute`}
                        label="Device Attribute"
                        className="placeholder-inner"
                        component={TextField}
                        margin="dense"
                        fullWidth={true}
                        variant="outlined"
                      >
                        <MenuItem disabled value="none">
                          Device Attribute
                        </MenuItem>
                        {deviceAttributes.map(option => (
                          <MenuItem
                            key={option.typeName}
                            value={option.typeDisplayName}
                          >
                            {option.typeName}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={11} md={6} lg={3}>
                      <Field
                        select
                        name={`${name}.${childIndex}.applicationAttribute`}
                        label="Application Attribute"
                        className="placeholder-inner"
                        component={TextField}
                        margin="dense"
                        fullWidth={true}
                        variant="outlined"
                      >
                        <MenuItem disabled value="none">
                          Application Attribute
                        </MenuItem>
                        {applicationAttributes.map(option => (
                          <MenuItem
                            key={option.typeName}
                            value={option.typeDisplayName}
                          >
                            {option.typeName}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={10} md={6} lg={3} >
                      <Field
                        select
                        name={`${name}.${childIndex}.unit`}
                        label="Units"
                        className="placeholder-inner"
                        component={TextField}
                        margin="dense"
                        fullWidth={true}
                        variant="outlined"
                      >
                        <MenuItem disabled value="none">
                          Units
                        </MenuItem>
                        {unitValueTypes.map(option => (
                          <MenuItem
                            key={option.typeName}
                            value={option.typeDisplayName}
                          >
                            {option.typeName}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>

                    <Grid
                      item
                      container
                      direction="row"
                      xs={1}
                      md={1}
                      alignItems="center"
                      justify="center"
                    >
                      {getIn(values, name).length > 0 &&
                        childIndex === getIn(values, name).length - 1 && (
                          <Grid item>
                            <button
                              type="button"
                              onClick={() => {
                                addAttributeProfile(childIndex + 1, push);
                              }}
                              className="add-icon"
                            >
                              <i
                                className="fa fa-plus-circle"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </Grid>
                        )}
                      {getIn(values, name).length > 1 && (
                        <Grid item>
                          <button
                            type="button"
                            className="add-icon delete-icon"
                            onClick={e => {
                              getIn(values, name).length > 0 &&
                                remove(childIndex);
                            }}
                          >
                            <i
                              class="fa fa-minus-circle"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              ))
            ) : (
                <>
                  <Grid
                    container
                    item
                    xs={12}
                    className="device-wrapper"
                    key={`${name}.[0]`}
                    justify="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs={11} md={6} lg={3}>
                      <Field
                        select
                        name={`${name}.[0].deviceAttribute`}
                        label="Device Attribute"
                        className="placeholder-inner"
                        component={TextField}
                        margin="dense"
                        fullWidth={true}
                        variant="outlined"
                      >
                        <MenuItem disabled value="none">
                          Device Attribute
                      </MenuItem>
                        {deviceAttributes.map(option => (
                          <MenuItem
                            key={option.typeName}
                            value={option.typeDisplayName}
                          >
                            {option.typeName}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={11} md={6} lg={3}>
                      <Field
                        select
                        name={`${name}.[0].applicationAttribute`}
                        label="Application Attribute"
                        className="placeholder-inner"
                        component={TextField}
                        margin="dense"
                        fullWidth={true}
                        variant="outlined"
                      >
                        <MenuItem disabled value="none">
                          Application Attribute
                      </MenuItem>
                        {applicationAttributes.map(option => (
                          <MenuItem
                            key={option.typeName}
                            value={option.typeDisplayName}
                          >
                            {option.typeName}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={11} md={6} lg={3}>
                      <Field
                        select
                        name={`${name}.[0].unit`}
                        label="Units"
                        className="placeholder-inner"
                        component={TextField}
                        margin="dense"
                        fullWidth={true}
                        variant="outlined"
                      >
                        <MenuItem disabled value="none">
                          Units
                      </MenuItem>
                        {unitValueTypes.map(option => (
                          <MenuItem
                            key={option.typeName}
                            value={option.typeDisplayName}
                          >
                            {option.typeName}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>

                    <Grid
                      item
                      container
                      direction="row"
                      xs={1}
                      md={1}
                      alignItems="center"
                      justify="center"
                    >
                      {getIn(values, name).length > 0 &&
                        0 === getIn(values, name).length - 1 && (
                          <Grid item>
                            <button
                              type="button"
                              onClick={() => {
                                addAttributeProfile(0 + 1, push);
                              }}
                              className="add-icon"
                            >
                              <i
                                className="fa fa-plus-circle"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </Grid>
                        )}
                      {getIn(values, name).length > 1 && (
                        <Grid item>
                          <button
                            type="button"
                            className="add-icon delete-icon"
                            onClick={e => {
                              getIn(values, name).length > 0 && remove(0);
                            }}
                          >
                            <i class="fa fa-minus-circle" aria-hidden="true"></i>
                          </button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
          </>
        );
      }}
    </FieldArray>
  );
}
