import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Scrollbars from "react-custom-scrollbars";
import Searchbar from "../../../components/common/elements/searchbar";
import { provisioningService } from "../../../services/provisioningServices";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  paperStyle: {
    paddingTop: ".5em",
    height: "100%"
  },
  checkboxRoot: {
    color: "#707070 ",
    "& svg": {
      width: ".6em",
      height: ".6em"
    }
  },
  CheckboxCheckedColor: {
    color: "#FF8886 !important"
  },
  totalSites: {
    lineHeight: "3em",
    borderTop: "1px solid lightgray",
    display: "flex",
    justifyContent: "center",
    fontWeight: "400",
    "& b": {
      //fontWeight: "700",
      fontSize: "14px",
      marginBottom: "0em",
      color: "#2E384D"
    }
  },
  loadMore: {
    textAlign: "center",
    cursor: "pointer"
  },
  F12: {
    fontSize: "12px"
  }
}));
function SiteSearchCard({ sites, totalSites }) {
  const [sitesList, setSitesList] = React.useState([]);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [filterList, setFilterList] = React.useState([]);
  const [status, setStatus] = React.useState("fetch");
  const [hasNextPage, setHasNextPage] = React.useState(true);
  const [totalPages, setTotalPages] = React.useState(1);
  // fetch -> fetching -> fetchAll -> done/failed
  // useEffect(() => {
  //   if (totalPages > 1 && status === "fetchAll") {
  //     let fetchArray = [];
  //     for (let i = 0; i < totalPages - 1; ++i) {
  //       const apiCall = provisioningService.getUserAssociatedSites(50, i + 1);
  //       fetchArray.push(apiCall);
  //     }
  //     let returnList = [];
  //     Promise.all(fetchArray)
  //       .then(siteList => {
  //         siteList.forEach(sites => {
  //           const list =
  //             sites.response.content[0].siteAssociationConfiguration.length >
  //             0 &&
  //             sites.response.content[0].siteAssociationConfiguration.map(
  //               site => {
  //                 return {
  //                   siteName: site.siteName,
  //                   isBookmarked: site.isBookmarked,
  //                   siteUUID: site.siteUUID
  //                 };
  //               }
  //             );
  //           returnList = [...returnList, ...list];
  //           const bookmarkedList = [...sitesList, ...returnList].filter(
  //             site => site.isBookmarked
  //           );
  //           const others = [...sitesList, ...returnList].filter(
  //             site => !site.isBookmarked
  //           );
  //           setSitesList([...bookmarkedList, ...others]);
  //         });
  //         setStatus("finished");
  //       })
  //       .catch(e => setStatus("error"));
  //   }
  // }, [totalPages, status]);
  // useEffect(() => {
  //   if (status === "fetch" && hasNextPage) {
  //     setStatus("fetching");
  //     provisioningService
  //       .getUserAssociatedSites(50, pageIndex)
  //       .then(sites => {
  //         const list =
  //           sites.response.content[0].siteAssociationConfiguration.length > 0 &&
  //           sites.response.content[0].siteAssociationConfiguration.map(site => {
  //             return {
  //               siteName: site.siteName,
  //               isBookmarked: site.isBookmarked,
  //               siteUUID: site.siteUUID
  //             };
  //           });
  //         const bookmarkedList = [...sitesList, ...list].filter(
  //           site => site.isBookmarked
  //         );
  //         const others = [...sitesList, ...list].filter(
  //           site => !site.isBookmarked
  //         );
  //         setSitesList([...bookmarkedList, ...others]);
  //         const { pageInfo } = sites.response;
  //         setHasNextPage(pageInfo.hasNextPage);
  //         setTotalPages(pageInfo.totalPages);
  //         setPageIndex(index => index + 1);
  //         if (pageInfo.hasNextPage) {
  //           setStatus("fetchAll");
  //         } else {
  //           setStatus("finished");
  //         }
  //       })
  //       .catch(error => {
  //         setStatus("error");
  //         setSitesList([]);
  //       });
  //   }
  // }, [status]);
  useEffect(() => {
    setStatus("fetching");
    provisioningService
      .getAllUsers()
      .then(list => {
        list.response.map(site => {
          return {
            siteName: site.siteName,
            isBookmarked: site.isBookmarked,
            siteUUID: site.siteUUID
          };
        });
        const bookmarkedList = list.response.filter(site => site.isBookmarked);
        const others = list.response.filter(site => !site.isBookmarked);
        // console.log([...bookmarkedList, ...others]);
        setSitesList([...bookmarkedList, ...others]);
        setStatus("finished");
      })
      .catch(error => {
        setStatus("error");
        setSitesList([]);
      });
  }, []);
  // set the sites list to filterTable on update
  useEffect(() => {
    setFilterList(sitesList);
  }, [sitesList]);
  // const handleLoadMore = () => {
  //   setPageIndex(pageIndex + 1);
  // };
  const classes = useStyles();
  const { t } = useTranslation();
  // filter the list when user makes a search
  const handleFilterList = e => {
    let updatedList = [...sitesList];
    updatedList = updatedList.filter(
      item =>
        item.siteName.toLowerCase().search(e.target.value.toLowerCase()) !== -1
    );
    setFilterList(updatedList);
  };
  const handleBookMarkClick = async index => {
    let site = sitesList[index];
    let updatedSites = [...sitesList];
    updatedSites[index].isBookmarked = !site.isBookmarked;
    // console.log(index);
    // console.log(updatedSites[index]);
    const result = await provisioningService.updateSitesBookmarks(updatedSites);
    // console.log(result.response[index]);
    // to sort the sites
    const compare = (a, b) => {
      if (a.siteName.toLowerCase() > b.siteName.toLowerCase()) {
        return 1;
      } else if (a.siteName.toLowerCase() < b.siteName.toLowerCase()) {
        return -1;
      }
      return 0;
    };

    const bookmarkedList = updatedSites
      .filter(site => site.isBookmarked)
      .sort(compare);
    const others = updatedSites
      .filter(site => !site.isBookmarked)
      .sort(compare);
    setSitesList([...bookmarkedList, ...others]);
  };
  return (
    <Paper elevation={2} classes={{ root: classes.paperStyle }}>
      <div style={{ padding: "1em" }}>
        <Searchbar onChange={handleFilterList} />
      </div>

      <Divider />
      <Scrollbars
        style={{ height: "300px" }}
        renderTrackVertical={props =>
          status === "finished" ? (
            <div {...props} className="track-vertical" />
          ) : (
              <div {...props} style={{ display: "none" }} />
            )
        }
        renderThumbVertical={props => (
          <div {...props} className="thumb-vertical" />
        )}
      >
        {status === "error" ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            No Sites Found
          </div>
        ) : (
            <div
            // style={{
            //   height: "300px",
            //   overflowY: "scroll",
            //   margin: "1em 1em 1em 0em",
            //   overflowX: "hidden"
            // }}
            // className="sitecard-scroll"
            >
              <FilteredList
                sitesList={filterList}
                totalSites={totalSites}
                allSites={sitesList}
                handleBookmark={handleBookMarkClick}
                classes={classes}
              />
            </div>
          )}
      </Scrollbars>

      <div className={classes.totalSites}>
        {t("total_sites")} : <b>&nbsp;{totalSites}</b>
      </div>
    </Paper>
  );
}

const FilteredList = ({
  sitesList,
  handleBookmark,
  clicked,
  classes,
  allSites
}) => {
  return (
    <List component="ul" aria-label="List of sites">
      {(sitesList || []).map((site, index) => (
        <ListItem style={{ padding: "0em 0em 0em 0em" }} key={index}>
          <ListItemIcon style={{ minWidth: "0em" }}>
            <Checkbox
              classes={{
                root: classes.checkboxRoot,
                checked: classes.CheckboxCheckedColor
              }}
              icon={<StarBorderIcon />}
              checkedIcon={<StarIcon />}
              checked={site.isBookmarked}
              onChange={() => {
                sitesList === allSites
                  ? handleBookmark(index)
                  : allSites.filter((s, rank) => {
                    // console.log(s, site, index, rank, s === site ? rank : 0);
                    if (s === site) return handleBookmark(rank);
                  });
              }}
            />
          </ListItemIcon>
          <Link to={`/configuration/site/uuid/${site.siteUUID}`}>
            <ListItemText
              primary={site.siteName}
              primaryTypographyProps={{
                classes: {
                  body1: classes.F12
                }
              }}
            />
          </Link>
        </ListItem>
      ))}
      {sitesList.length === 0 && (
        <ListItem className={classes.loadMore}>
          <ListItemText primary="No Result Found" />
        </ListItem>
      )}
    </List>
  );
};

export default SiteSearchCard;
