import React, { useState, useContext, useRef, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import * as Yup from "yup";

import metadataServices, {
  metadataService
} from "../../../services/metadataServices";
import { provisioningService } from "services/provisioningServices";
import CommonForm from "../../../components/common/form/common-form";
import FailureFeedback from "../../../components/common/elements/FailureFeedback";
import PageHeader from "components/layout/PageHeader";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import "../configuration.scss";
import "./Organization.scss";
import CountryStateDistrictSelector from "components/common/form/CountryStateDistrictSelector";

import { errorContext } from "context/errorContext";

function Organization({ dispatch, ...props }) {
  const [orgTypes, setOrgTypes] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [edit, setEdit] = useState(false);

  const [orgDetails, setOrgDetails] = useState(null);
  const [apiError, setError] = useState(null);
  const [status, setStatus] = useState("idle");

  const history = useHistory();

  let formikForm = useRef(null);

  let { dispatch: errorDispatch } = useContext(errorContext);
  const { id } = useParams();

  useEffect(() => {
    if (!!id) {
      fetchOrgDetails(id);
    }
    metadataServices("orgTypes")
      .then(data => {
        let types =
          data.length > 0 ? data.map(type => type.typeDisplayName) : [];
        setOrgTypes(types);
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });

    metadataServices("countryCodes")
      .then(data => {
        let types = ((!!data && data) || []).map(type => type.typeDisplayName);
        setCountryCodes(types);
      })
      .catch(e => {
        console.error("fetching country codes failed");
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
  }, []);

  useEffect(() => {
    if (edit && !!orgDetails && Number(id) !== orgDetails.orgId) {
      fetchOrgDetails(id);
      setStatus("loading");
    }
  }, [id]);

  async function fetchOrgDetails(id) {
    try {
      const data = await provisioningService.getOrgDetailsbyID(id);
      setOrgDetails(data);
      setEdit(true);
      setStatus("idle");
    } catch (error) {
      errorDispatch({
        type: "ERROR",
        error
      });
    }
  }

  const handleDialogClose = () => {
    setStatus("idle");
  };

  const handleSubmit = async (values, setSubmitting) => {
    if (edit) {
      values.isParent = true;
      editOrg(values, setSubmitting);
    } else {
      createOrg(values, setSubmitting);
    }
  };

  async function createOrg(values, setSubmitting) {
    try {
      const result = await provisioningService.createOrganization(values);
      setStatus("success");
      history.push({
        pathname: `/organization/profile/${result.orgName}`,
        state: {
          data: result
        }
      });
    } catch (e) {
      console.error(e);
      setError(e.error);
      setSubmitting(false);
      setStatus("error");
    }
  }
  async function editOrg(values, setSubmitting) {
    try {
      const result = await provisioningService.editOrganization(values);
      setStatus("success");
      history.push({
        pathname: `/organization/profile/${result.orgName}`,
        state: {
          data: result
        }
      });
    } catch (e) {
      setError(e.error);
      setSubmitting(false);
      setStatus("error");
    }
  }

  let fields = [
    {
      type: "completedPercentage",
      formikRef: formikForm,
      title: "Organization profile"
    },
    {
      type: "select",
      name: "orgType",
      label: "Org Type",
      placeholder: "Org Type",
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      },
      option: orgTypes.map(type => ({
        value: type,
        label: type
      })),
      visible: "hide"
    },
    {
      type: "text",
      name: "orgName",
      label: "Org Name",
      placeholder: "Org Name",
      disabled: !!id,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "orgDisplayName",
      label: "Org Display Name",
      placeholder: "Org Display Name",
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "orgUrl",
      label: "Website",
      placeholder: "Website",
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "header",
      name: "Address"
    },
    {
      type: "text",
      name: "address.pinCode",
      label: "PIN Code",
      placeholder: "pinCode",
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "address.addressLine1",
      label: "Address Line 1",
      placeholder: "Address Line 1",
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "address.villageTownCity",
      label: "Village / Town / City",
      placeholder: "Village / Town / City",
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "custom",
      component: ({ values, setFieldValue }) => (
        <CountryStateDistrictSelector
          edit={edit}
          countryField={"address.country"}
          stateField={"address.state"}
          districtField={"address.district"}
          handleChange={setFieldValue}
          values={values}
        />
      )
    },
    {
      type: "header",
      name: "Contact Information"
    },
    {
      type: "text",
      name: "contactDetails.contactPersonName",
      label: "Contact Person Name",
      placeholder: "Contact Person Name",
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "select",
      name: "contactDetails.countryCode",
      label: "Country Code",
      placeholder: "Country Code",
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      },
      option: countryCodes.map(type => ({
        value: type,
        label: type
      }))
    },
    {
      type: "text",
      name: "contactDetails.contactNumber",
      label: "Contact Number",
      placeholder: "Contact Number",
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "contactDetails.emailId",
      label: "Email",
      placeholder: "Email",
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "hr"
    }
  ];

  let validations = {
    orgType: Yup.string()
      .required("You must pick an organization type")
      .typeError("You must pick an organization type"),
    orgName: Yup.string()
      .required("You must enter a valid name")
      .typeError("You must enter a valid name")
      .min(4, "Organization Name must be atleast 4 characters")
      .max(20, "Organization Name cannot be more than 20 charaters"),
    orgDisplayName: Yup.string().required("You must enter a valid name"),
    address: Yup.object().shape({
      pinCode: Yup.number()
        .required("Please enter pincode")
        .typeError("That doesn't look like a pin code"),
      addressLine1: Yup.string().required("Please enter Address Line 1"),
      villageTownCity: Yup.string().required(
        "Please enter Village / Town / City"
      ),
      district: Yup.string().required("Please enter District"),
      state: Yup.string().required("Please enter State"),
      country: Yup.string().required("Please enter Country")
    }),
    contactDetails: Yup.object().shape({
      contactPersonName: Yup.string()
        .matches(/^[a-zA-Z ]{2,20}$/, {
          message: "Name must be between 2-20 characters long",
          excludeEmptyString: true
        })
        .required("Please enter Contact Person Name"),
      countryCode: Yup.string().required("Please enter Country Code"),
      contactNumber: Yup.number()
        .test("len", "Must be 10 Digits", val => val.toString().length === 10)
        .required("Please enter Contact Number")
        .typeError("That doesn't look like a phone number"),
      emailId: Yup.string()
        .email("Please enter a valid email")
        .required("Please enter Email")
    })
  };

  let hasData = false;
  let orgdata;

  if (edit && !!orgDetails) {
    if (Number(id) === orgDetails.orgId) {
      hasData = true;
      orgdata = orgDetails;
    }
  }

  let initialValuesDefault = {
    orgName: hasData ? orgdata.orgName : "",
    orgId: hasData ? orgdata.orgId : "",
    orgDisplayName: hasData ? orgdata.orgDisplayName : "",
    orgAbbreviatedName: hasData ? orgdata.orgAbbreviatedName : "",
    orgUrl: hasData ? orgdata.orgUrl : "",
    orgAddress1: hasData ? orgdata.orgAddress1 : "",
    orgType: hasData ? orgdata.orgType : "",
    address: {
      pinCode: hasData ? orgdata.address.pinCode : "",
      addressLine1: hasData ? orgdata.address.addressLine1 : "",
      district: hasData ? orgdata.address.district : "",
      villageTownCity: hasData ? orgdata.address.villageTownCity : "",
      state: hasData ? orgdata.address.state : "",
      country: hasData ? orgdata.address.country : ""
    },
    contactDetails: {
      contactPersonName: hasData
        ? orgdata.contactDetails.contactPersonName
        : "",
      countryCode: hasData ? orgdata.contactDetails.countryCode : "",
      contactNumber: hasData ? orgdata.contactDetails.contactNumber : "",
      emailId: hasData ? orgdata.contactDetails.emailId : ""
    },
    isParent: true,
    parentOrgId: null,
    rootOrgId: null
  };

  const formInitialValues = { ...initialValuesDefault };
  const showDashboard = props.showDashboard === undefined ? true : false;

  return (
    <Grid container xs={12}>
      <Grid item xs={12}>
        <PageHeader
          title="Organization"
          breadcrumbs={[
            { label: "Dashboard", link: "/dashboard" },
            { label: "Configuration" },
            { label: "Organization" }
          ]}
        />
      </Grid>
      <Grid container xs={12}>
        <Paper elevation={2} style={{ padding: "2em" }}>
          <Grid item>
            <h2>Organization Profile</h2>
          </Grid>
          <Grid item>
            {/* We call the common form here and pass our fields, initialValues and validations */}

            <CommonForm
              fields={fields}
              submitLabel={"Submit"}
              submittingLabel={"Submit"}
              initialValues={formInitialValues}
              validationSchema={Yup.object().shape({ ...validations })}
              validateOnBlur={true}
              validateOnChange={true}
              edit={edit}
              enableReinitialize
              onSubmit={(
                values,
                { setSubmitting, resetForm, setFieldError }
              ) => {
                handleSubmit(values, setSubmitting);
              }}
              formikRef={formikForm}
              buttonSize="3"
              buttonPosition="right"
            />
          </Grid>
        </Paper>
      </Grid>
      <FailureFeedback
        open={status === "error"}
        onClose={handleDialogClose}
        status={!!apiError && apiError.status}
        message={!!apiError && apiError.message}
      />
    </Grid>
  );
}

export default Organization;
