import React from "react";
import * as Yup from "yup";
import PageHeader from "components/layout/PageHeader";
import CommonForm from "components/common/form/common-form";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { TextField, CheckboxWithLabel } from "formik-material-ui";

import { Field, FieldArray, getIn } from "formik";
import TemplateForTables from "components/layout/innerPagesTemplate";

let breadcrumbs = [
  { label: "Dashboard", link: "/dashboard" },
  { label: "Configuration" },
  { label: "-----ENTER FORM PAGE NAME HERE------" }
];

export default function Form() {
  const formikForm = React.useRef(null);

  // replace this array with the fields description array for the form you are building

  let options = [
    {
      id: 22,
      typeName: "+1",
      typeDisplayName: "+1"
    },
    {
      id: 21,
      typeName: "+33",
      typeDisplayName: "+33"
    },
    {
      id: 20,
      typeName: "+91",
      typeDisplayName: "+91"
    }
  ];
  const fields = [
    {
      type: "completedPercentage",
      formikRef: formikForm,
      title: "----form title here----"
    },
    {
      type: "datePicker",
      name: "checklist",
      label: "checklsit",
      placeholder: "Organization",
      disabled: true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },

    {
      type: "datePicker",
      name: "siteId",
      label: "start date",
      maxDate: new Date(),
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "siteId",
      label: "Site ID",
      placeholder: "Site ID",
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    }

    // {
    //   type: "custom",
    //   component: ({ values }, renderFields) => (
    //     <TestFields
    //       name="task"
    //       values={getIn(values, "task")}
    //       rednerFields={renderFields}
    //     />
    //   )
    // }
    // {
    //   type: "text",
    //   name: "siteName",
    //   label: "Site Name",
    //   placeholder: "Site Name",
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // }
  ];

  let values = {
    checklist: new Date(),
    siteId: new Date(),
    task: [
      {
        taskId: "asdf",
        taskName: "",
        subTask: [
          {
            subTaskId: "",
            subTaskName: "",
            subTaskDescription: "",
            subTaskReading: ""
          }
        ]
      }
    ]
  };

  // add validations here for your form fields
  let validations = {
    organizationName: Yup.string().required(
      "You must enter an organization name"
    ),
    siteId: Yup.string().required("You must enter a site ID"),
    siteName: Yup.string(),
    longitude: Yup.number(),
    latitude: Yup.number(),
    country: Yup.string().required("Select a country"),
    state: Yup.string().required("Select a state"),
    district: Yup.string().required("enter a district"),
    pinCode: Yup.number()
      .required("Enter a pincode")
      .typeError("That doesn't look like a pin code"),
    villageTownCity: Yup.string().required("Enter a village/town/city"),
    addressLine1: Yup.string().required("Enter an address")
  };

  function handleSubmit(values) {
    console.log(values);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader title="Site" breadcrumbs={breadcrumbs} />
      </Grid>
      <Grid container>
        <Paper elevation={2} style={{ padding: "2em" }}>
          <Grid item>
            <TemplateForTables
            // importIcons
            >
              {/* {status === "loading" ? (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{ height: "50vh" }}
                >
                  <CircularProgress />
                </Grid>
              ) : (
                <Table
                  columns={columns}
                  data={data}
                  paginationInfo={{
                    pageIndex,
                    nextPage,
                    previousPage,
                    pageSize,
                    setPageSize,
                    pageInfo
                  }}
                />
              )} */}

              <CommonForm
                fields={fields}
                submitLabel={"Submit"}
                submittingLabel={"Submit"}
                initialValues={values}
                validationSchema={Yup.object().shape({ ...validations })}
                validateOnBlur={true}
                validateOnChange={true}
                onSubmit={(
                  values,
                  { setSubmitting, setFieldError, resetForm }
                ) => {
                  handleSubmit(values);
                }}
                formikRef={formikForm}
                buttonSize="3"
                inlineButton
                buttonPosition="right"
              />
            </TemplateForTables>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

function TestFields({ name, values, renderFields }) {
  let selectFields = [
    {
      type: "select"
    }
  ];

  return (
    <FieldArray
      name={name}
      render={() => {
        return (
          values.length > 0 &&
          values.map((item, index) => (
            <>
              <Field
                name={`${name}.${index}.taskId`}
                component={TextField}
                label="taskId"
              />
              <Field
                name={`${name}.${index}.taskName`}
                component={TextField}
                label="taskName"
              />
              {item.taskType === "select" &&
                selectFields.map(field => renderFields(field))}
              <Field
                name={`${name}.${index}.taskDone`}
                component={CheckboxWithLabel}
                label="taskDone"
              />
              <Field
                name={`${name}.${index}.comment`}
                component={TextField}
                label="comment"
              />
            </>
          ))
        );
      }}
    />
  );
}

function SubTasks({ name, values }) {
  return (
    <>
      <h1>subtask</h1>
      <FieldArray
        name={name}
        render={() => {
          return (
            values.length > 0 &&
            values.map((item, index) => (
              <>
                <Field name={`${name}.${index}.subTaskId`} />
                <Field name={`${name}.${index}.subTaskName`} />
                <Field name={`${name}.${index}.subTaskDescription`} />
              </>
            ))
          );
        }}
      />
    </>
  );
}
