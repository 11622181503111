import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { useSelector } from "react-redux";
import { CustomizedPrimaryButton } from "../elements/CustomizedPrimaryButton";
import { metadataService } from "services/metadataServices";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FieldArray, Field, getIn } from "formik";
import TextField from "@material-ui/core/TextField";

export default function WarehouseCategoryProdSelection({name,categoryProductData,getSelectedCategoryAndProducts,categorySelectedData,edit}){

    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [expandedCategories, setExpandedCategories] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isSelectAllIndeterminate, setIsSelectAllIndeterminate] = useState(false);
    const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
    const [indeterminateCategories, setIndeterminateCategories] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
      if(edit === true){
        if(categorySelectedData != undefined)
          setSelectedItems(categorySelectedData);
      }
    },[categorySelectedData]);

    // useEffect(() => {
    //   const allCategories = categoryProductData.map(category => category.categoryId);
    //   const allProducts = categoryProductData.flatMap(category => category.productList.map(product => product.productId));
    //   const selectedCategoryIds = selectedItems.map(item => item.categoryId);
    //   const selectedProductIds = selectedItems.flatMap(item => item.productList.map(product => product.productId));
    //   const allItems = [...allCategories, ...allProducts];
    //   const selectedIds = [...selectedCategoryIds, ...selectedProductIds];
    //   const allItemsSelected = selectedIds.length === allItems.length;
    //   const someItemsSelected = selectedIds.length > 0 && selectedIds.length < allItems.length;
  
    //   setIsSelectAllIndeterminate(someItemsSelected && !allItemsSelected);
    //   setIsSelectAllChecked(allItemsSelected);
    // }, [selectedItems, categoryProductData]);
  
    useEffect(() => {
      const newIndeterminateCategories = {};
  
      categoryProductData.forEach(category => {
        const categoryId = category.categoryId;
        const categoryProducts = category.productList.map(product => product.productId);
        const selectedCategory = selectedItems.find(item => item.categoryId === categoryId);
        const selectedCategoryProducts = selectedCategory ? selectedCategory.productList.map(product => product.productId) : [];


        // const selectedCategoryProducts = selectedItems
        //   .find(item => item.categoryId === categoryId)?.productList.map(product => product.productId) || [];
  
        if (selectedCategoryProducts.length > 0 && selectedCategoryProducts.length < categoryProducts.length) {
          newIndeterminateCategories[categoryId] = true;
        } else {
          newIndeterminateCategories[categoryId] = false;
        }
      });
  
      setIndeterminateCategories(newIndeterminateCategories);
    }, [selectedItems, categoryProductData]);
  
    const handleCategoryClick = (category, productList) => {
      const categoryExists = selectedItems.some(item => item.categoryId === category.categoryId);
      if (categoryExists) {
        setSelectedItems(
          selectedItems.filter(
            item => item.categoryId !== category.categoryId && !productList.some(p => p.productId === item.productId)
          )
        );
      } else {
        setSelectedItems([...selectedItems, category, ...productList]);
      }
    };

    const handleToggleCategoryExpand = (categoryId) => {
      const isExpanded = expandedCategories.includes(categoryId);
      if (isExpanded) {
        setExpandedCategories(expandedCategories.filter(id => id !== categoryId));
      } else {
        setExpandedCategories([...expandedCategories, categoryId]);
      }
    };

    const expandCategory = (categoryId) => {
      const isExpanded = expandedCategories.includes(categoryId);
      if (isExpanded) {
        setExpandedCategories(expandedCategories.filter(id => id !== categoryId));
      } else {
        setExpandedCategories([...expandedCategories, categoryId]);
      }
    };

    const handleProductClick = (product, category) => {
      const updatedItems = [...selectedItems];
      const categoryIndex = updatedItems.findIndex(item => item.categoryId === category.categoryId);
    
      if (categoryIndex !== -1) {
        const productIndex = updatedItems[categoryIndex].productList.findIndex(p => p.productId === product.productId);
      
        if (productIndex !== -1) {
          updatedItems[categoryIndex].productList.splice(productIndex, 1);
          if (updatedItems[categoryIndex].productList.length === 0) {
            updatedItems.splice(categoryIndex, 1);
          }
        } else {
          updatedItems[categoryIndex].productList.push(product);
        }
      } else {
        updatedItems.push({ ...category, productList: [product] });
      }

      setSelectedItems(updatedItems);
    };


    function handleConfigOpen() {
      setOpen(true);
    }

    function handleClose() {
      setOpen(false);
    }

    const handleSelectAll = () => {
      if (selectedItems.length === categoryProductData.length) {
        setSelectedItems([]);
      } else {
        setSelectedItems(categoryProductData.map(category => ({ ...category, productList: [...category.productList] })));
      }
    };

    const handleSearchChange = (event) => {
      setSearchQuery(event.target.value);
    };

    const filteredData = categoryProductData.filter(category => {
      if (category.categoryName.toLowerCase().includes(searchQuery.toLowerCase())) {
        return true;
      }if (category.productList) {
        return category.productList.some(product => product.productName.toLowerCase().includes(searchQuery.toLowerCase()));
      }
      return false;
    });

    const saveSelection = () => {
      getSelectedCategoryAndProducts(selectedItems);
      setOpen(false);
    }

    const isCategorySelected = (categoryId) => {
      const selectedCategory = selectedItems.find(item => item.categoryId === categoryId);
      return selectedCategory ? selectedCategory.productList.length === categoryProductData.find(cat => cat.categoryId === categoryId).productList.length : false;
    };
  
    const isProductSelected = (productId) => {
      for (let category of selectedItems) {
        if (category.productList != undefined && category.productList.find(product => product.productId === productId)) {
          return true;
        }
      }
      return false;
    };

    return(
      <>
        <IconButton onClick={handleConfigOpen}>
          <i className="fa fa-cog"></i>
        </IconButton>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          className="user-checklist"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            <h2>{t("Warehouse_Association_Category_Product_Dailogue_Header")}</h2>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container md={12} xs={12} lg={12}>
              <Grid item md={6}>
                <TextField
                  label="Search"
                  variant="outlined"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  style={{ marginBottom: '10px',width:"80%" }}
                />
              </Grid>
              <Grid item md={2} style={{float:"right"}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      indeterminate={selectedItems.length > 0 && selectedItems.length < categoryProductData.length}
                      checked={selectedItems.length === categoryProductData.length}
                      onChange={handleSelectAll}
                      color="primary"
                    />
                  }
                  label={"Select All"}
                  key="select All"
                />
              </Grid>
              <Grid item md={2}>
                <CustomizedPrimaryButton
                  onClick={() => saveSelection()}
                  color="primary"
                  label="Save"
                  width="1"
                  inlineButton="true"
                  position="right"
              ></CustomizedPrimaryButton>
              </Grid>
            </Grid>
            <Grid container md={12} xs={12} lg={12}>
              <Grid item md={12} xs={12} lg={12}>
                <FormGroup class="config-dialogue" >             
                  {filteredData &&
                    filteredData.map(type => (
                      <div key={type.categoryId}  >
                        {expandedCategories.includes(type.categoryId) ?(
                          <IconButton onClick={() => handleToggleCategoryExpand(type.categoryId)}>
                            <i className="fa fa-arrow-down"></i>
                            </IconButton>
                          ):<IconButton onClick={() => handleToggleCategoryExpand(type.categoryId)}>
                            <i className="fa fa-arrow-right"></i>
                          </IconButton> 
                        }
                        <FormControlLabel    
                         // onClick={() => handleCategoryClick(type.categoryId, type.productList)}
                          onClick={() => handleCategoryClick(type, type.productList)}
                          control={
                            <Checkbox
                             // checked={selectedItems.some(item => item.categoryId === type.categoryId)}
                             // indeterminate={indeterminateCategories[type.categoryId]}
                              indeterminate={!isCategorySelected(type.categoryId) && selectedItems.some(item => item.categoryId === type.categoryId)}
                              checked={isCategorySelected(type.categoryId)}
                              value={type.categoryId}
                              color="default"
                              style={{fontWeight:"bold"}}
                              
                             // checked={selectedCategories.includes(type.categoryId)}
                           />
                          }
                          label={<strong>{type.categoryName}</strong>}
                        />
                        {expandedCategories.includes(type.categoryId) && type.productList &&
                          type.productList.map(product => (                            
                            <div key={product.productId} style={{marginLeft:"40px"}}>
                             <FormControlLabel
                               control={
                                 <Checkbox
                                    //onChange={() => handleProductClick(product.productId, type.categoryId)}
                                    onChange={() => handleProductClick(product, type)}
                                    //checked={selectedProducts.includes(product.productId)}
                                 //   checked={selectedItems.some(item => item.productId === product.productId)}
                                    value={product.productName}
                                    color="default"
                                    checked={isProductSelected(product.productId)}
                                  />
                               }
                               label={product.productName}
                             />
                            </div>
                        ))}
                      </div>
                    ))}
                </FormGroup>
              </Grid>
            </Grid>
          </DialogContent>
        <DialogActions>
      </DialogActions>
    </Dialog>
      </>
  );
};
