import React, { useEffect, useContext, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Table from "components/common/table/DynamicPaginationTable2";
import PageHeader from "../../../components/layout/PageHeader.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import TemplateForTables from "../../../components/layout/innerPagesTemplate";
import { Grid, Paper } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { customeGroupService } from "services/customerGroupServices";
import { provisioningService } from "../../../services/provisioningServices.js";

import { errorContext } from "context/errorContext";
import addDays from "date-fns/addDays";

import "../../../components/Table.scss";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import CommonForm from "components/common/form/common-form";
import { func } from "prop-types";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
import FailureFeedback from "components/common/elements/FailureFeedback";

const CustomerGroupList = props => {

  const user = JSON.parse(localStorage.getItem("currentUser"));
  const { t } = useTranslation();
  const { dispatch: errorDispatch } = useContext(errorContext);
  const [sourceTypeData, setSourceTypeData] = React.useState([]);
  const [customerGroupData, setCustomerGroupData] = useState([]);
  const [tableData,setTableData] = useState([]);
  const formikForm = React.useRef(null);
  const [customerGroupStatus, setCustomerGroupStatus] = useState("loading");
  const [status, setStatus] = useState("loading");
  const [validationStatus ,setValidationStatus] = useState(false);

  const dropitems = [
    {
      item: (<Link to="/configuration/create-customergroup">Add Customer Group</Link>)
    }
  ];

  let viewDropDown = true;
  if(user.uiPrivileges.includes("CREATE_CUSTOMER_GROUP")) {
    viewDropDown = true;
  } else{
    viewDropDown = false;
  }

  
  function getSourceType(){
    customeGroupService.getAllSourceType().then(data => {
      if(data){
        setSourceTypeData(data);
        console.log(data);
        setCustomerGroupStatus("success");
      }
    }).catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
    });
  }

  function getCustomerGroupData(){
    customeGroupService.getAllCustomerGroup().then(data => {
      setCustomerGroupData(data);
    })
    .catch(error => {
       errorDispatch({
         type: "ERROR",
         error
       });
    });
  }
  
  useEffect(() => {
    provisioningService.verifyOtpNew('1313131313').then((res) => {
      const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
      const organizationId = JSON.parse(localStorage.getItem("orgId"));
      getSourceType();
      getCustomerGroupData();
    })
    .catch(error => {
       errorDispatch({
         type: "ERROR",
         error
       });
    });
  }, []);

  let validations = {
    sourceType: Yup.string().required(
      t("CustomerGroup_Form_SourceType_Validation_Message")),
  };

  const fields = [
    {
      type: "select",
      name: "sourceType",
      label: t("CustomerGroup_Form_Source_Type_Label"),
      placeholder: t("CustomerGroup_Form_Source_Type_Label"),
      option:
        sourceTypeData.length > 0
          ? sourceTypeData.map(sourceTypeData => ({
             value: sourceTypeData.sourceType,
             label: sourceTypeData.sourceType
            }))
        : [],
      grid: {
        xs: 12,
        sm: 2,
        md: 3,
        className: ""
      }
    }
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: "Customer Group Name",
        accessor: "name"
      },
      {
        Header: "Source Type",
        accessor: "sourceType"
      },
      {
        Header: "Created Time",
        accessor: "createdOn"
      },
      {
        Header: "Customer Group ID",
        accessor: "id"
      },
    ],
    []
  );

  let values = {
    sourceType: ""
  };

  function handleSubmit(values, resetForm, setSubmitting) {
    if(values.sourceType === ""){
      return;
    }
   
    console.log("customerGroupData",customerGroupData);
    console.log(values);

    setTableData(customerGroupData.filter(data =>{
      return data.sourceType === values.sourceType;
    }));
    
    setStatus("success");
    setSubmitting(false);
  }

  const data = React.useMemo(() => [...tableData]);
  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title="Customer Group"
          breadcrumbs={[
            { label: "Dashboard", link: "/dashboard" },
            { label: "Configuration" },
            { label: "Customer Group" }
          ]}
        />
      </Grid>
      <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%"
        }}
      >
      {customerGroupStatus === "loading" ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ):(
      <CommonForm
        fields={fields}
        submitLabel={t("site_Form_Submit_Label")}
        submittingLabel={t("site_Form_Submitting_Label")}
        initialValues={values}
        validationSchema={Yup.object().shape({ ...validations })}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={(
          values,
          { setSubmitting, setFieldError, resetForm, errors }
        ) => {
          if (!!errors) {
            setSubmitting(false);
          }
          handleSubmit(values, resetForm, setSubmitting);
        }}
        formikRef={formikForm}
        buttonSize="2"
        buttonPosition="right"
        inlineButton={true}
      />
      )}
      {/* <TemplateForTables
        dropdownitems={dropitems}
        needDropdown={true}
        importIcons
      > */}
        {status === "success" ? (
        //   <Grid
        //     container
        //     justify="center"
        //     alignItems="center"
        //     style={{ height: "50vh" }}
        //   >
        //    <CircularProgress />
        //   </Grid>
            
          <Table  
            columns={columns}
            data={data}
            needDropdown={viewDropDown}
            dropItems={dropitems}
            fileName={"customerGroupList.xls"}
          />
        ) :null}
      {/* </TemplateForTables> */}
      </Paper>

      {/* <SuccessFeedback
        open={successApproveNotification}
        onClose={handleDialogClose}
        successMessage={t("notification_list_Approve_Success_Message")}
      />
      <FailureFeedback open={failApproveNotification} onClose={handleDialogClose} />
      
      <SuccessFeedback
        open={sendNotificationStatus}
        onClose={handleSendDialogClose}
        successMessage={t("notification_list_Send_Success_Message")}
      />
      <FailureFeedback open={failSentNotification} onClose={handleSendDialogClose} />

      <FailureFeedback
        open={dateAlertMessage}
        onClose={handleDateClose}
        status={"Failed"}
        message={t("notification_list_Date_Range_Message")}
        showButtons={true}
      /> 

      <FailureFeedback
        open={validDateRangeMessage}
        onClose={handleValidDateAlertClose}
        status={"Failed"}
        message={t("notification_list_Valid_Date_Range_Message")}
        showButtons={true}
      />        */}
    </Grid>    
  );

};

export default CustomerGroupList;
