import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { getIn } from "formik";
import PageHeader from "components/layout/PageHeader";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
import CommonForm from "components/common/form/common-form";
import FailureFeedback from "components/common/elements/FailureFeedback";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import "components/responsive.scss";
import "../configuration.scss";
import "./CustomerGroup.scss";
import "../../../components/responsive.scss";

import { useTranslation } from "react-i18next";
import { string } from "prop-types";
import addDays from "date-fns/addDays";

export default function CustomerGroupForm({values,sourceTypeData,handleSubmit

}) {
    
    const { t } = useTranslation();
    const formikForm = React.useRef(null);

    let validations = {
      name: Yup.string().required(t("CustomerGroup_Form_Name_Validation_Message")).max(100, t("CustomerGroup_Form_Max_Character_Message")),
      sourceType:Yup.string().required(
        t("CustomerGroup_Form_SourceType_Validation_Message")),
      description:Yup.string().max(100,t("CustomerGroup_Form_Max_Character_Message"))
    };

    const fields = [
      {
        type: "text",
        name: "name",
        label: t("CustomerGroup_Form_Name_Label"),
        placeholder: t("CustomerGroup_Form_Name_Label"),
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "select",
        name: "sourceType",
        label: t("CustomerGroup_Form_Source_Type_Label"),
        placeholder: t("CustomerGroup_Form_Source_Type_Label"),
        option:
          sourceTypeData.length > 0
            ? sourceTypeData.map(sourceTypeData => ({
                value: sourceTypeData.sourceType,
                label: sourceTypeData.sourceType
              }))
            : [],
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "textarea",
        name: "description",
        label: t("CustomerGroup_Form_Description_Label"),
        placeholder: t("CustomerGroup_Form_Description_Label"),
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      }
    ];

    return (
      <Grid container>
        <Grid item xs={12}>
          <PageHeader
            title="Customer Group"
       //   breadcrumbs={breadcrumbs}
          />
        </Grid>
        <Grid container>
          <Paper elevation={2} style={{ padding: "2em",width:"100%" }}>
            <Grid item>
              <CommonForm
                fields={fields}
                submitLabel={t("site_Form_Submit_Label")}
                submittingLabel={t("site_Form_Submitting_Label")}
                initialValues={values}
                validationSchema={Yup.object().shape({ ...validations })}
                validateOnBlur={true}
                enableReinitialize
                validateOnChange={true}
                onSubmit={(
                  values,
                  { setSubmitting, setFieldError, resetForm, errors }
                ) => {
                  if (!!errors) {
                    setSubmitting(false);
                  }
                  handleSubmit(values, resetForm, setSubmitting);
                }}
                formikRef={formikForm}
                buttonSize="3"
                buttonPosition="right"
              />
            </Grid>
          </Paper>
        </Grid>
        {/* <SuccessFeedback
            open={status === "approvedSuccessfully"}
            onClose={handleDialogClose}
            successMessage={t("approve_Form_Success_Message")}
            createAnotherMesssage={t("approve_Form_Create_Another_Site")}
          />
    
          <SuccessFeedback
            open={status === "rejectedSuccessfully"}
            onClose={handleDialogClose}
            successMessage={t("approve_Form_Rejected_Message")}
            createAnotherMesssage={t("approve_Form_Create_Another_Site")}
          />
    
          <FailureFeedback
            open={status === "failed"}
            onClose={handleDialogClose}
            status={"Failed"}
            message={statusMessage}
            showButtons={true}
          />  */}
      </Grid>
    )
};
