import { func } from "prop-types";
import { utilityServices } from "./utilityServices";

function getToken() {
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const token = user && user.jwtAccessToken;
  return token;
}

async function getWarehouseListData(values) {
    const url = process.env.REACT_APP_GET_WAREHOUSE_LIST_DATA;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authToken: localStorage.getItem("idToken"),
        "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
        Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
      },
      body: JSON.stringify(values)
    }; 
    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        return await response.json();
      } else {
        throw response;
      }
    } catch (error) {
      let err = {
        status: error.status,
        error: !!error.body ? await error.json() : true
      };
      throw err;
    }
}

async function getAllCategoryData(pageIndex,pageSize) {
    const url = `${process.env.REACT_APP_GET_ALL_CATEGORY_DATA}?page=${pageIndex}&size=${pageSize}`;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authToken: localStorage.getItem("idToken"),
        "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
        Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
      }
    }; 
    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        return await response.json();
      } else {
        throw response;
      }
    } catch (error) {
      let err = {
        status: error.status,
        error: !!error.body ? await error.json() : true
      };
      throw err;
    }
}

async function associateWarehouse(values){
  const url = `${process.env.REACT_APP_WAREHOUSE_LIST}`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "gh7MvvJXnRQO44PatMnoAuBcgLNTfk",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    },
    body:JSON.stringify(values)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getWarehouseAssociateListData(size,pageIndex){
  const url = `${process.env.REACT_APP_ASSOCIATE_WAREHOUSE_LIST}?size=${size}&page=${pageIndex}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "gh7MvvJXnRQO44PatMnoAuBcgLNTfk",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function EditWarehouseAssociate(values,warehouseId){
  const url = `${process.env.REACT_APP_EDIT_WAREHOUSE_ASSOCIATE}/${warehouseId}`;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "gh7MvvJXnRQO44PatMnoAuBcgLNTfk",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    },
    body:JSON.stringify(values)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getEditDetails(warehouseId){
  const url = `${process.env.REACT_APP_WAREHOUSE_ASSOCIATE_GET_EDIT_DATA}?criteriaId=${warehouseId}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "gh7MvvJXnRQO44PatMnoAuBcgLNTfk", 
      // "Api-Key": process.env.REACT_APP_WAREHOUSE_API_KEY,
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function deleteWarehouseAssociation(warehouseAssociationId){
  const url = `${process.env.REACT_APP_DELETE_WAREHOUSE_ASSOCIATION}/${warehouseAssociationId}`;
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "gh7MvvJXnRQO44PatMnoAuBcgLNTfk",
      // "Api-Key": process.env.REACT_APP_WAREHOUSE_API_KEY,
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getVlccByCC(ccId){
  const url = `${process.env.REACT_APP_GET_VLCC_BY_CC_ID}?ccId=${ccId}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "gh7MvvJXnRQO44PatMnoAuBcgLNTfk",
      // "Api-Key": process.env.REACT_APP_WAREHOUSE_API_KEY,
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getProductDataByCategory(categoryIdList){
  const url = `${process.env.REACT_APP_GET_PRODUCT_DATA_BY_CATEGORY}?size=5000&page=0&locale=en&categoryIdList=${categoryIdList}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
      // "Api-Key": process.env.REACT_APP_WAREHOUSE_API_KEY,
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}


async function getCategoryProductData(categoryIdList){
  const url = `${process.env.REACT_APP_GET_WAREHOUSE_CATEGORY_PRODUCT_DATA}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
      // "Api-Key": process.env.REACT_APP_WAREHOUSE_API_KEY,
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

export const warehouseService = {
  getWarehouseListData,
  getAllCategoryData,
  getWarehouseAssociateListData,
  associateWarehouse,
  getEditDetails,
  EditWarehouseAssociate,
  deleteWarehouseAssociation,
  getVlccByCC,
  getProductDataByCategory,
  getCategoryProductData
};
