import React from "react";
import Chip from "@material-ui/core/Chip";
export default function DevicesChips({
  type,
  values,
  setFieldValue,
  deviceField
}) {
  function deleteDevice(serialNumber) {
    let devices = [...values];
    const updatedDevices = devices.filter(
      device => device.serialNumber !== serialNumber
    );
    setFieldValue(deviceField, updatedDevices);
  }

  const devices = [...values];
  if (devices !== []) {
    if (type === "L3") {
      return (
        <>
          {devices
            .filter(device => device.deviceType === "L3")
            .map(device => (
              <Chip style = {{marginLeft:"10px"}}
                key={device.serialNumber}
                label={device.serialNumber}
                className="gateway-label"
                onDelete={() => {
                  deleteDevice(device.serialNumber);
                }}
              />
            ))}
        </>
      );
    } else {
      return (
        <>
          {devices
            .filter(device => device.deviceType !== "L3")
            .map(device => (
              <Chip style = {{marginLeft:"10px"}}
                key={device.serialNumber}
                label={device.serialNumber}
                className="gateway-label"
                onDelete={() => {
                  deleteDevice(device.serialNumber);
                }}
              />
            ))}
        </>
      );
    }
  }

  return null;
}
