import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import PreventiveTemplateForm from "./PreventiveTemplateForm";
import { provisioningService } from "services/provisioningServices";


export default function EditChecklistTemplate() {
  const org = JSON.parse(localStorage.getItem("currentUser")).organizationName;
  const [templateDetails, setTemplateDetails] = useState(null);

  const { templateId } = useParams();
  const history = useHistory();

  // to initialize the values when create template form loads first time
  let values = {
    id: templateId,
    orgName: org,
    checklistName: templateDetails ? templateDetails.checklistName : "",
    checklistDescription: templateDetails ? templateDetails.checklistDescription : "",
    taskList: templateDetails ? templateDetails.taskList.map((currentItem, index) => {
      return {
        id: currentItem.taskId,
        taskName: currentItem.taskName,
        subTasks: currentItem.subTaskList.map((currentSubItem, subIndex) => {
          return {
            subTaskId: currentSubItem.subTaskId,
            subTaskName: currentSubItem.subTaskName,
            subTaskDescription: currentSubItem.subTaskDescription,
            taskType: currentSubItem.taskType,
            subTaskValue: currentSubItem.subTaskValue
          }
        })
      }
    })
      : []
  };

  //didMount
  useEffect(() => {
    // console.log("Details got for templateId --------------", templateId);
    provisioningService.getCheckListTemplateById(templateId).then(data => {
      setTemplateDetails(data);
      // console.log("Details got from API 11 ",data);
    });
    //  console.log("Details got from API ",templateDetails);
  }, []);

  async function handleSubmit(values, resetForm, setSubmitting) {
    // console.log('Submitting the form values - Edit Template',values);

    try {
      const repeat = null;
      const result = await provisioningService.editChecklistTemplate(templateId,
        values
      );

      // if (repeat.length > 0) {
      //   setFieldError(
      //     `${name}.${childIndex}.serialNumber`,
      //     "Device already added at the site level"
      //   );
      //   setFieldValue(
      //     `chillingUnitMonitoringDetailsList.${parentIndex}.l1DeviceInfo.${childIndex}.serialNumber`,
      //     "",
      //     false
      //   );
      //   setValidating(false);
      // } else {
      //   let deviceType = result.deviceType;
      //   if (deviceType === "powsens" || deviceType === "3ph_powsens") {
      //     setFieldValue(
      //       `chillingUnitMonitoringDetailsList.${parentIndex}.l1DeviceInfo.${childIndex}.deviceType`,
      //       deviceType,
      //       false
      //     );
      //   } else {
      //     resetCheckListFields(parentIndex, childIndex);
      //   }
      //   setValidating(false);
      //}
      // }
      resetForm();
      //redirect to list page
      history.push("/configuration/checklist-templates-list")
    } catch (e) {
      console.log(e);
      // setFieldError(`${name}.${childIndex}.serialNumber`, e.message);
      // resetCheckListFields(parentIndex, childIndex);
      // setValidating(false);
      setSubmitting(false);
    }

    //Make back end API call to save valuesresetDeviceFields
    //setSubmit to false
    //call resetForm
  }

  function resetCheckListFields(parentIndex, childIndex) {
    // setFieldValue(
    //   `chillingUnitMonitoringDetailsList.${parentIndex}.l1DeviceInfo.${childIndex}.deviceType`,
    //   "",
    //   false
    // );
    // setFieldValue(
    //   `chillingUnitMonitoringDetailsList.${parentIndex}.l1DeviceInfo.${childIndex}.deviceUsedFor`,
    //   "",
    //   false
    // );
  }

  // Calling the create template form with values(to initialize),
  return (
    <PreventiveTemplateForm
      values={values}
      handleSubmit={handleSubmit}
    />
  );
}
