import axios from 'axios';

class Service {
  constructor() {
    //console.log("Service is constructed");
  }

  getRetailerServiceURL() {
      this.serviceInstance = axios.create({
        //baseURL: 'http://localhost',
        baseURL: 'https://adminportal.smartmoo.com',
        timeout: 180000
      });
    return this.serviceInstance;
  }

}

export default (new Service());
