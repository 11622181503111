import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import dPP from "../../../src/assets/userprofile.png";

import PageHeader from "../../components/layout/PageHeader";
import { CustomizedPrimaryButton } from "../../components/common/elements/CustomizedPrimaryButton";
import { provisioningService } from "services/provisioningServices";
import Field from "./Field";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withWidth } from "@material-ui/core";
import { useTranslation } from "react-i18next";
const Profile = props => {
  const [userDetails, setUser] = React.useState(null);
  const [status, setStatus] = React.useState("idle");
  const data = useSelector(state => state.userConfiguration.toJS().userDetails);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  React.useEffect(() => {
    setStatus("loading");
    if (props.default) {
      const user = JSON.parse(localStorage.getItem("currentUser")).userName;
      provisioningService.getUserByUserName(user).then(data => {
        setUser(data.response);
        setStatus("success");
      });
    } else {
      setUser(data);
      setStatus("success");
      if (data === null) history.push("/dashboard");
    }
    dispatch({
      type: "USER_CONFIGURATION_STATUS_UPDATE",
      status: "idle"
    });
  }, []);

  return (
    <Grid container xs={12}>
      <Grid item xs={12}>
        <PageHeader
          title={t("user_Form_Percentage_Label")}
          breadcrumbs={[
            { label: t("user_Form_Breadcrum_Dashboard_Label"), link: "/dashboard" },
            { label: t("user_Form_Percentage_Label") }
          ]}
        />
      </Grid>

      <Grid item container xs={12} md={6}>
        <Paper elevation={2} style={{ width: "100%", position: "relative" }}>
          {status === "loading" && (
            <div className="card-loader">
              <CircularProgress />
            </div>
          )}
          <Grid item container xs={12} style={{ padding: "1.5em" }}>
            <h2>
              <span>{t("user_Form_Reporting_Org_Label")}</span> -{" "}
              {userDetails && userDetails.organizationName}
            </h2>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justify="flex-end"
            style={{ padding: "1.5em" }}
          >
            <Grid
              item
              container
              lg={4}
              md={12}
              xs={12}
              justify={
                props.width === "lg" || props.width === "xl"
                  ? "flex-start"
                  : "center"
              }
              style={{ padding: ".7rem 1em .7em 0em" }}
            >
              <img
                src={dPP}
                alt="user avatar"
                style={{ height: "10rem", width: "10rem" }}
              />
            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xs={12}
              style={{ wordWrap: "break-word", paddingBottom: "2rem" }}
            >
              <Field
                label="Full Name"
                value={
                  userDetails &&
                  `${userDetails.salutation ? userDetails.salutation : ""}.${userDetails.firstName} ${userDetails.lastName ? userDetails.lastName : ""}`
                }
              />
              <Field
                label={t("user_Form_Gender_Label")}
                value={
                  userDetails && !!userDetails.gender && userDetails.gender
                }
              />
              <Field
                label={t("config_Users_Add_UserName_Label")}
                value={userDetails && userDetails.userName}
              />
              <Field
                label={t("config_Users_Add_Mobile_Label")}
                value={userDetails && userDetails.mobileNumbers[0].mobileNumber}
                greyLabel={true}
              />
              <Field
                label={t("config_Users_Add_Email_Label")}
                value={userDetails && userDetails.emailId}
                greyLabel={true}
              />
              <Field label={t("menu_Role_Label")} value={userDetails && userDetails.roles[0]} />
            </Grid>
            <CustomizedPrimaryButton
              label={t("checklist_Edit_Label")}
              onClick={() => {
                history.push(
                  `/configuration/user/${userDetails && userDetails.userName}`
                );
              }}
              position="right"
              width="6"
            ></CustomizedPrimaryButton>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withWidth()(Profile);
