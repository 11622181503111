import React, { useState, useEffect,useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import EditRetailerForm from "./EditRetailerForm";
import ViewRetailerForm from "./ViewRetailerForm";
import { provisioningService } from "services/provisioningServices";
import { warehouseService } from "services/warehouseServices";
import format from "date-fns/format";
//import { number, string } from "prop-types";

//For Tab view - Starts
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import PageHeader from "../../../components/layout/PageHeader";
import { useTranslation } from "react-i18next";
import { customeGroupService } from "services/customerGroupServices";
import { errorContext } from "context/errorContext";
import { farmerAssociationService } from "services/farmerAssociationServices";

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  tabIndicator: {
    backgroundColor: "#1089ff"
  }
}));
//For Tab view - Ends


export default function EditRetailer() {
  const uiPrivilegesViewCheck = JSON.parse(localStorage.getItem("currentUser")).uiPrivileges.includes("VIEW_RETAILER");
  const uiPrivilegesEditCheck = JSON.parse(localStorage.getItem("currentUser")).uiPrivileges.includes("EDIT_RETAILER");
  const [retailerDetails, setRetailerDetails] = useState(null);

  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const { dispatch: errorDispatch } = useContext(errorContext);

  const [status, setStatus] = React.useState([]);
  const [extraDocData, setExtraDocData] = React.useState([]);
  const [customerGroupData, setCustomerGroupData] = React.useState([]);
  const [pbpData,setPbpData] = React.useState([]);
  const [roleList, setRoleList] = useState([]);
  const [warehouseData,setWarehouseData] = React.useState([]);
  const [warehouseMandatoryMessage,setWarehouseMandatoryMessage] = useState("");

  //For Tab view - Starts
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //For Tab view - Ends

  // to initialize the values when create template form loads first time
  let values = {
    id: id,
    name: retailerDetails ? retailerDetails.name : "",
    gender: retailerDetails ? retailerDetails.gender : "",
    dateOfBirth: retailerDetails ? retailerDetails.dateOfBirth : new Date(),
    addressLine1: retailerDetails ? retailerDetails.addressLine1 : "",
    retailerPincode: retailerDetails ? retailerDetails.retailerPincode : "",
    retailerVillage: retailerDetails ? retailerDetails.retailerVillage : "",
    retailerTaluk: retailerDetails ? retailerDetails.retailerTaluk : "",
    retailerDistrict: retailerDetails ? retailerDetails.retailerDistrict : "",
    retailerState: retailerDetails ? retailerDetails.retailerState : "",
    retailerCountry: retailerDetails ? retailerDetails.retailerCountry : "",
    
    storeName: retailerDetails ? retailerDetails.storeName : "",
    storeAddress1: retailerDetails ? retailerDetails.storeAddress1 : "",
    storePincode: retailerDetails ? retailerDetails.storePincode : "",
    storeVillage: retailerDetails ? retailerDetails.storeVillage : "",
    storeTaluk: retailerDetails ? retailerDetails.storeTaluk : "",
    storeDistrict: retailerDetails ? retailerDetails.storeDistrict : "",
    storeState: retailerDetails ? retailerDetails.storeState : "",
    storeCountry: retailerDetails ? retailerDetails.storeCountry : "",
    storePhoneNumber: retailerDetails ? retailerDetails.storePhoneNumber : "",
    status: retailerDetails ? retailerDetails.status : [],
    latLang: retailerDetails ? retailerDetails.latLang : "",
    isActive: retailerDetails ? retailerDetails.isActive : "",
    //View related extra fields
    mobileNumber: retailerDetails ? retailerDetails.mobileNumber : "",
    emailId: retailerDetails ? retailerDetails.emailId : "",
    earningsPerMonth: retailerDetails ? retailerDetails.earningsPerMonth : "",
    gst: retailerDetails ? retailerDetails.gst : "",
    license: retailerDetails ? retailerDetails.license : "",
    latLang: retailerDetails ? retailerDetails.latLang : "",
    noOfYearsAsRetailer: retailerDetails ? retailerDetails.noOfYearsAsRetailer : "",
    noOfYearsStayedInThatAddress: retailerDetails ? retailerDetails.noOfYearsStayedInThatAddress : "",
    otherOccupation: retailerDetails ? retailerDetails.otherOccupation : "",
    retailerRoofType: retailerDetails ? retailerDetails.retailerRoofType : "",
    retailerShopOwnership: retailerDetails ? retailerDetails.retailerShopOwnership : "",
    retailerPrimaryOccupation: retailerDetails ? retailerDetails.retailerPrimaryOccupation : "",
    totalVolumeSales: retailerDetails ? retailerDetails.totalVolumeSales : "",
    retailerUuid: retailerDetails ? retailerDetails.retailerUuid : "",
    mooretailReferenceId: retailerDetails ? retailerDetails.mooretailReferenceId : "",
    omsReferenceId: retailerDetails ? retailerDetails.omsReferenceId : "",
    territory: retailerDetails ? retailerDetails.territory : "",
    wareHouseCode: retailerDetails ? retailerDetails.wareHouseCode : "",
    lastOmsFieldUpdated: retailerDetails ? retailerDetails.lastOmsFieldUpdated : new Date(),
    //name: retailerDetails ? retailerDetails.name : "",
    location: retailerDetails ? retailerDetails.location : "",
    shopGstNo: retailerDetails ? retailerDetails.shopGstNo : "",
    affiliateShopEstDate: retailerDetails ? retailerDetails.affiliateShopEstDate : new Date(),
    //name: retailerDetails ? retailerDetails.name : "",
    accountName: retailerDetails ? retailerDetails.accountName : "",
    accountNumber: retailerDetails ? retailerDetails.accountNumber : "",
    bankName: retailerDetails ? retailerDetails.bankName : "",
    branch: retailerDetails ? retailerDetails.branch : "",
    ifsc: retailerDetails ? retailerDetails.ifsc : "",
    //name: retailerDetails ? retailerDetails.name : "",
    checkerName: retailerDetails ? retailerDetails.checkerName : "",
    checkerMobNo: retailerDetails ? retailerDetails.checkerMobNo : "",
    createdBy: retailerDetails ? retailerDetails.createdBy : "",
    createdOn: retailerDetails ? retailerDetails.createdOn : new Date(),
    updatedOn: retailerDetails ? retailerDetails.updatedOn : new Date(),
    employeeCode: retailerDetails ? retailerDetails.employeeCode : "",
    engineerMobNo: retailerDetails ? retailerDetails.engineerMobNo : "",
    providers: retailerDetails ? retailerDetails.providers : [],
    groupTag: retailerDetails ? retailerDetails.groupTag : [],
    fetchTag: retailerDetails ? retailerDetails.fetchTag : [],
    customTag: retailerDetails ? retailerDetails.customTag : [],
    //name: retailerDetails ? retailerDetails.name : "",
    customerGroup: retailerDetails ? retailerDetails.customerGroup :[],
    pbpName: retailerDetails ? retailerDetails.pbpName: [],
    roleName: retailerDetails ? retailerDetails.roleName:[],
    retailerExtraDocumentsDTOList: retailerDetails ? retailerDetails.retailerExtraDocumentsDTOList : [
      {
        documentName: '',
        document: ''
      }
    ]
   
  };

  //didMount
  useEffect(() => {
    warehouseService.getWarehouseListData().then(data => {
      setWarehouseData(data);
    }).catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });

    farmerAssociationService.getPbpList().then(data => {    
      setPbpData(data);
    }).catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });

    customeGroupService.getAllCustomerGroup().then(data => {
      setCustomerGroupData(data.filter(data =>{
        return data.sourceType === "mooretail_app";
      }));
    }).catch(error => {
       errorDispatch({
         type: "ERROR",
         error
       });
    });

    const orgName = JSON.parse(localStorage.getItem("currentUser")).organizationName;
    provisioningService.getRolesByOrgId("2").then(res => {
      setRoleList(res);
      setRoleList([...res, {id:1,roleName:"Select"}]);
    //  res.push({id:1,roleName:"Select"})
    }).catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });

    provisioningService.getStatusValuesInRetailerEdit().then(data1 => {
      if (data1) {
        //console.log("status:============ ", data1);
        setStatus(data1.status);
      }
    });

    provisioningService.getRetailerById(id).then(data => {
      //console.log("Individual Retailer:----- ", data);
      setRetailerDetails(data);
      setExtraDocData(data.retailerExtraDocumentsDTOList);
    });

  }, []);

  async function handleSubmit(values, resetForm, setSubmitting) {

    if(typeof(values.customerGroup) === "string"){
      let tmpCustomerGroup = [];
      tmpCustomerGroup.push(values.customerGroup);
      values.customerGroup = tmpCustomerGroup;
    }

    if(typeof(values.pbpName) === "string"){
      let tmpPbpName =[];
      tmpPbpName.push(values.pbpName);
      values.pbpName = tmpPbpName;
    }

    if(values.wareHouseCode){
      let selectedData = warehouseData.filter(data => {
        return (data.warehouseCode === values.wareHouseCode)
      });
      if(selectedData){
        values.wareHouseId = selectedData[0].warehouseId;
      }
    }

    if(values.roleName !=undefined && values.roleName === "Select"){
      values.roleName = "";      
    }

    setWarehouseMandatoryMessage("");
    if(values.roleName === "MQP_retailer"){
      if(values.wareHouseId === undefined){
        console.log("return");
        setWarehouseMandatoryMessage("Please Select the Warehouse");
        setSubmitting(false);
        return;
      }
    }

    try {
      const repeat = null;
      const result = await provisioningService.editRetailer(id,
        values
      );
      //resetForm();
      //redirect to list page
      history.push("/configuration/retailer-list")
    } catch (e) {
      console.log(e);
      setSubmitting(false);
    }
    //setSubmit to false
    //call resetForm
  }

  // Calling the create template form with values(to initialize),
  return (
    <>
     <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("retailer_Edit_Form_Header_Label")}
          breadcrumbs={[
            { label: t("retailer_Edit_Form_Breadcrumb_Dashboard_Label"), link: "/dashboard" },
            {
              label: t("retailer_Edit_Form_Breadcrumb_Retailer_Label"),
              link: "/configuration/retailer-list"
            },
            { label: t("retailer_Edit_Form_Breadcrumb_Retailer_Label") }
          ]}
        />
      </Grid>

      <Grid container>
        <Paper elevation={2} style={{ padding: "2em", width: "100%" }}>
          {" "}
          <Grid item xs={12}> 
          </Grid>
          <Grid item>

          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="Retailer"              
          >
          {uiPrivilegesViewCheck && <Tab label="View" {...a11yProps(0)} />}  
          {uiPrivilegesEditCheck && <Tab label="Edit" {...a11yProps(1)} />}  
          </Tabs>
          <TabPanel value={value} index={0} >
          {extraDocData.length>0 ? <ViewRetailerForm
              values={values}
              showSubmitButton={false}
              extraDocData={extraDocData} 
            /> : <ViewRetailerForm
            values={values}
            showSubmitButton={false}
          />}
          </TabPanel>
          <TabPanel value={value} index={1} >
            <EditRetailerForm
              values={values}
              handleSubmit={handleSubmit}
              showSubmitButton={true}
              status={status}
              customerGroupData={customerGroupData}
              pbpData={pbpData}
              roleList={roleList}
              warehouseData={warehouseData}
            />
          </TabPanel>
          </Grid>
          {warehouseMandatoryMessage && (
            <div style={{ color: "#f44336" }}>{warehouseMandatoryMessage}</div>
          )}
        </Paper>
      </Grid>
    </Grid>

    </>
  );
}
