import React, { useEffect, useState } from "react";
import { connect, getIn } from "formik";
import Grid from "@material-ui/core/Grid";
import { ColorChangingProgressBar } from "../elements/ColorChangingProgressBar";
import { useTranslation } from "react-i18next";

function FormCompletedPercentage({ formikRef, formik, title }) {
  const [completed, setCompleted] = useState(0);
  const { t } = useTranslation();
  useEffect(() => {
    if (!!formikRef.current) {
      let percent = fieldsPercentage(formikRef.current.fields);
      setCompleted(Math.round(percent));
    }
  }, [formik.values, formikRef.current]);

  function fieldsPercentage(fields) {
    let filledFields = Object.keys(fields).reduce((filled, field) => {
      if (!!getIn(formik.values, field)) {
        if (
          Array.isArray(getIn(formik.values, field)) &&
          getIn(formik.values, field).length === 0
        )
          return filled;
        return filled + 1;
      }
      return filled;
    }, 0);

    return (filledFields / Object.keys(fields).length) * 100;
  }

  return (
    <Grid
      item
      container
      xs={12}
      justify="center"
      direction="column"
      alignContent="center"
    >
      <span>
        {t("form_Complete")} <b>{completed}%</b> {!!title && ` of ${title}`}
      </span>
      <ColorChangingProgressBar value={completed} color={false} />
    </Grid>
  );
}

export default connect(FormCompletedPercentage);
