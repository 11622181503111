import React, { useEffect, useState } from "react";
import format from "date-fns/format";

export default function DisplayDate(props) {
  const [time, setTime] = useState(
    format(new Date(), "dd MMMM yyyy | h:mm aa")
  );
  useEffect(() => {
    let i = setInterval(() => tick(), 1000 * 60);
    return () => {
      clearInterval(i);
    };
  }, []);
  const tick = () => {
    setTime(format(new Date(), "dd MMMM yyyy | h:mm aa"));
  };
  return <span style={{ fontSize: "12px" }}>{time}</span>;
}
