import React, { useEffect, useContext, useState, useMemo } from "react";

import { Link } from "react-router-dom";

import Table from "components/common/table/DynamicPaginationTable";
import PageHeader from "../../../components/layout/PageHeader.js";
import { provisioningServices } from "../../../services/provisioningServices.js";

import CircularProgress from "@material-ui/core/CircularProgress";
import TemplateForTables from "../../../components/layout/innerPagesTemplate";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import { errorContext } from "context/errorContext";

import "../../../components/Table.scss";

const dropitems = [
  {
    item: <Link to="/configuration/site">Add New Site</Link>
  },
  { item: <Link to="/configuration/import-site">Import Site</Link> }
];

const TotalSite = props => {
  const { dispatch: errorDispatch } = useContext(errorContext);

  const [sites, setSites] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [status, setStatus] = useState("loading");

  let query = useMemo(() => new URLSearchParams(props.location.search), [
    props.location
  ]);

  const nextPage = () => {
    if (
      status !== "loading" &&
      pageInfo.hasNextPage &&
      Number(query.get("page")) < pageInfo.totalPages
    ) {
      query.set("page", Number(query.get("page")) + 1);
      props.history.push({
        pathname: props.location.pathname,
        search: query.toString()
      });
    }
  };
  const previousPage = () => {
    props.history.goBack();
  };
  const handlePageSize = size => {
    query.set("size", size);
    query.set("page", 0);
    props.history.push({
      pathname: props.location.pathname,
      search: query.toString()
    });
  };

  useEffect(() => {
    if (!!query.get("page") && !!query.get("size")) {
      setStatus("loading");
      provisioningServices("get", "sites", {
        pageSize: query.get("size"),
        pageIndex: query.get("page")
      })
        .then(data => {
          setSites(data.content);
          setPageInfo(data.pageInfo);
          setStatus("success");
        })
        .catch(error => {
          console.error(error);
          errorDispatch({
            type: "ERROR",
            error
          });
          setStatus("failed");
        });
    } else {
      props.history.push({
        pathname: props.location.pathname,
        search: `?page=0&size=10`
      });
    }
  }, [query]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Notification ID",
        accessor: "siteId",
        Cell: ({ row }) => {
          return (
            <Link to={`/totalsites/site-details/${row.original.siteId}`}>
              {row.original.deviceType === "BMC" && (
                <img
                  className="bmc-icon"
                  src="/assets/graph-icons/bmc.png"
                  alt=""
                />
              )}
              {row.original.deviceType === "ABC" && (
                <img
                  className="bmc-icon"
                  src="/assets/graph-icons/abc.png"
                  alt=""
                />
              )}
              <span>{row.original.siteId}</span>
            </Link>
          );
        }
      },
      {
        Header: "Notification Name",
        accessor: "siteName"
      },
      {
        Header: "Organization",
        accessor: "organizationName"
      },
      {
        Header: "Sub-Organization",
        accessor: "subOrganizationName"
      },
      {
        Header: "No. of BMCs",
        accessor: "numberOfChillingUnit"
      },
      {
        Header: "No. of Devices",
        accessor: "numberOfL1Devices"
      },
      {
        Header: "Actions",

        Cell: ({ row }) => {
          let link = `/configuration/site/${row.original.siteId}`;
          return (
            <>
              <td>
                <Link to={link}>
                  <IconButton>
                    <i className="fa fa-edit" aria-hidden="true"></i>
                  </IconButton>
                </Link>
              </td>
              {/* <td>
                <IconButton>
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </IconButton>
              </td> */}
            </>
          );
        }
      }
    ],
    []
  );

  const data = React.useMemo(() => [...sites]);
  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title="Total Sites"
          breadcrumbs={[
            { label: "Dashboard", link: "/dashboard" },
            { label: "Configuration" },
            { label: "Total Sites" }
          ]}
        />
      </Grid>
      <TemplateForTables
        dropdownitems={dropitems}
        needDropdown={true}
        importIcons
      >
        {status === "loading" ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
            <Table
              columns={columns}
              data={data}
              paginationInfo={{
                pageIndex: Number(query.get("page")),
                nextPage,
                previousPage,
                pageSize: Number(query.get("size")),
                setPageSize: handlePageSize,
                pageInfo
              }}
            />
          )}
      </TemplateForTables>
    </Grid>
  );
};

export default TotalSite;
