import React from "react";
// import ReactEcharts from "echarts-for-react";
import Tree from "react-d3-tree";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import { provisioningService } from "../../../services/provisioningServices";

import { makeStyles } from "@material-ui/core/styles";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import Backdrop from "@material-ui/core/Backdrop";
import { Grid } from "@material-ui/core";
import orgIcon from "../../../assets/icon_org.svg";
import subOrgIcon from "../../../assets/icon_suborg.svg";
import siteIcon from "../../../assets/icon_sites.svg";
import bmcIcon from "../../../assets/icon_bmc.svg";
import deviceIcon from "../../../assets/icon_devices.svg";
import chillingCenterIcon from "../../../assets/icon_cc.svg";
import plantIcon from "../../../assets/icon_plant.svg";
import routeIcon from "../../../assets/icon_route.svg";
import zoneIcon from "../../../assets/icon_zone.svg";
import othersIcon from "../../../assets/icon_others.svg";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 1302,
    //color: "#fff"
  }
}));

export default function OrgCompleteChart({ setOrgName, zoom = 0.5 }) {
  const [heirarchy, setHeirarchy] = React.useState({});
  const [graphLoading, setGraphLoading] = React.useState(false);
  const [openBottomMenu, setOpenBottomMenu] = React.useState(true);
  const [zoomValue, setZoom] = React.useState(1);
  const [fullScreen, setFullScreen] = React.useState(false);
  const [reset, setReset] = React.useState(Date.now());
  const [org, setOrg] = React.useState(false);
  const [plant, setPlant] = React.useState(false);
  const [chillingCenter, setChillingCenter] = React.useState(false);
  const [route, setRoute] = React.useState(false);
  const [zone, setZOne] = React.useState(false);
  const [others, setOthers] = React.useState(false);
  const [sites, setSites] = React.useState(false);
  const [bmc, setBmc] = React.useState(false);
  const [device, setDevice] = React.useState(false);

  const { t } = useTranslation();


  const classes = useStyles();
  React.useEffect(() => {
    setZoom(zoom);
  }, [zoom]);

  React.useEffect(() => {
    handleHeirarchyFetch();
  }, []);

  async function handleHeirarchyFetch() {
    setGraphLoading(true);
    const data = await provisioningService.getOrgSiteStructure();

    if (!!setOrgName) {
      setOrgName(data.orgName);
      setOrg(true);
    }
    let orgHeirarchy = {};
    orgHeirarchy.name = data.orgName;
    orgHeirarchy.icon = orgIcon;
    if (data.subOrganization.length > 0) {
      orgHeirarchy.children = handleSubOrgs(data.subOrganization);
      setHeirarchy([orgHeirarchy]);
    }
    setGraphLoading(false);
  }

  function handleSubOrgs(subOrganizations) {
    let subOrgs = [];
    subOrgs = subOrganizations.map(org => {
      let orgData = {};
      orgData.name = org.orgName;
      orgData.children = [];
      if (org.orgType.match(/plant/i)) {
        orgData.icon = plantIcon;
        setPlant(true);
      } else if (org.orgType.match(/chillingcenter/i)) {
        orgData.icon = chillingCenterIcon;
        setChillingCenter(true);
      } else if (org.orgType.match(/zone/i)) {
        orgData.icon = zoneIcon;
        setZOne(true);
      } else if (org.orgType.match(/route/i)) {
        orgData.icon = routeIcon;
        setRoute(true);
      } else {
        orgData.icon = subOrgIcon;
        setOthers(true);
      }
      if (org.sites && org.sites.length > 0) {
        orgData.children = handleSites(org.sites);
        setSites(true);
      }
      if (org.subOrganization.length > 0) {
        orgData.children.push(...handleSubOrgs(org.subOrganization));
      }
      orgData._collapsed = true;
      orgData.orgType = org.orgType;
      return orgData;
    });
    return [...subOrgs];
  }

  function handleSites(siteList) {
    let sites = [];
    sites = siteList.map(site => {
      let siteData = {};
      siteData.name = site.siteName;
      siteData.children = [];
      siteData.icon = siteIcon;
      if (site.chillingUnits.length > 0) {
        siteData.children = handleChillingUnits(site.chillingUnits);
        setBmc(true);
      }
      if (site.l1Devices.length > 0) {
        siteData.children.push(...handleSiteLevelDevices(site.l1Devices));
        setDevice(true);
      }
      if (site.l3Devices.length > 0) {
        siteData.children.push(...handleSiteLevelDevices(site.l3Devices));
        setDevice(true);
      }
      siteData._collapsed = true;
      return siteData;
    });
    return [...sites];
  }

  function handleChillingUnits(chillingUnitList) {
    let chillingUnits = [];
    chillingUnits = chillingUnitList.map(chillingUnit => {
      let chillingUnitData = {};
      chillingUnitData.name = chillingUnit.chillingUnitName;
      chillingUnitData.icon = bmcIcon;
      if (chillingUnit.l1DeviceInfo.length > 0) {
        chillingUnitData.children = handleDeviceInfo(chillingUnit.l1DeviceInfo);
        setDevice(true);
      }
      chillingUnitData._collapsed = true;
      return chillingUnitData;
    });
    return [...chillingUnits];
  }

  function handleDeviceInfo(l1DeviceInfoList) {
    let l1DeviceInfos = [];
    l1DeviceInfos = l1DeviceInfoList.map(l1DeviceInfo => {
      let l1DeviceInfoData = {};
      l1DeviceInfoData.name = l1DeviceInfo.deviceType + "\n" + l1DeviceInfo.serialNumber;
      l1DeviceInfoData.icon = deviceIcon;
      l1DeviceInfoData._collapsed = true;
      return l1DeviceInfoData;
    });
    return [...l1DeviceInfos];
  }

  function handleSiteLevelDevices(DeviceList) {
    let devices = [];
    devices = DeviceList.map(device => {
      let deviceData = {};
      deviceData.name = device.deviceType + "\n" + device.serialNumber;
      deviceData.icon = deviceIcon;
      deviceData._collapsed = true;
      return deviceData;
    });
    return [...devices];
  }

  const styles = {
    links: { stroke: "#C4CFDD" },
    nodes: {
      node: {
        circle: {
          fill: "#9B5FFB",
          stroke: "#E5E5FF",
          strokeWidth: 3
        },
        name: {
          stroke: "#000"
        }
      },
      leafNode: {
        circle: {
          fill: "#478FCC",
          stroke: "#E5E5FF",
          strokeWidth: 3
        },
        name: {
          stroke: "#97A4BA"
        }
      }
    }
  };

  const treeContainer = React.useRef(null);
  const fullScreenContainer = React.useRef(null);

  const [translate, setTranslate] = React.useState({
    x: 0,
    y: 0
  });

  React.useEffect(() => {
    if (fullScreen) {
      const dimensions = fullScreenContainer.current.getBoundingClientRect();
      setTranslate({
        x: dimensions.width / 2,
        y: 100
      });
    } else {
      const dimensions = treeContainer.current.getBoundingClientRect();
      setTranslate({
        x: dimensions.width / 2,
        y: 100
      });
    }
  }, [heirarchy, fullScreen]);

  return (
    <>
      {fullScreen ? (
        <Backdrop
          classes={{ root: classes.backdrop }}
          open={true}
          onClick={e => {
            if (e.target.nodeName === "DIV") {
              setFullScreen(false);
            }
          }}
        >
          <Grid
            container
            ref={fullScreenContainer}
            style={{
              position: "relative",
              height: "100%",
              width: "100%",
              backgroundColor: "white",
              opacity: "1"
            }}
            justify="flex-end"
          >
            <IconButton
              style={{ position: "absolute", top: "10px" }}
              onClick={() => setFullScreen(false)}
            >
              <FullscreenExitIcon />
            </IconButton>
            <Tree
              data={heirarchy}
              orientation="vertical"
              pathFunc="step"
              useCollapseData
              styles={styles}
              allowForeignObjects
              translate={translate}
              zoom={zoomValue}
              separation={{ siblings: 3, nonSiblings: 2 }}
              nodeLabelComponent={{
                render: <NodeLabel />,
                foreignObjectWrapper: {
                  width: 400,
                  x: 15,
                  y: -35
                }
              }}
            />
          </Grid>
        </Backdrop>
      ) : (
          <Grid
            container
            ref={treeContainer}
            alignItems="flex-end"
            style={{
              height: "100%",
              position: "relative",
              border: "1px solid #F1EAF0",
              borderRadius: "4px"
            }}
          >
            <Tree
              key={reset}
              data={heirarchy}
              orientation="vertical"
              pathFunc="step"
              useCollapseData
              styles={styles}
              allowForeignObjects
              translate={translate}
              zoom={zoomValue}
              separation={{ siblings: 3, nonSiblings: 2 }}

              nodeLabelComponent={{
                render: <NodeLabel />,
                foreignObjectWrapper: {
                  width: 400,
                  x: 15,
                  y: -35
                }
              }}
            />
            <Grid
              container
              justify="center"
              style={{
                position: "absolute",
                //backgroundColor: "rgba(140, 143, 146,.9)",
                backgroundColor: "rgba(230,230,230,.9)",
                borderRadius: "0px 4px "
              }}
            >
              <IconButton
                onClick={() => {
                  if (zoomValue > 0.1) {
                    setZoom(zoomValue - 0.1);
                  }
                }}
              >
                <ZoomOutIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  if (zoomValue < 0.9) {
                    setZoom(zoomValue + 0.1);
                  }
                }}
              >
                <ZoomInIcon />
              </IconButton>
              <IconButton onClick={() => setFullScreen(true)}>
                <FullscreenIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setZoom(0.5);
                  setReset(Date.now());
                }}
              >
                <FullscreenExitIcon />
              </IconButton>

              <Grid container justify="center"
              >

                {org === true ?
                  <span style={{ fontWeight: "bold", display: "inline-block", padding: "10px" }}>
                    {t("org_Complete_Tree_Organization")}
                      <object style={{ marginLeft: "5px", width: "24px", height: "24px", verticalAlign: "-5px", horizontalAlign: "80px" }} type={"image/svg+xml"} data={orgIcon}>
                    </object>
                  </span>
                  : null

                }


                {others === true ?
                  <span style={{ fontWeight: "bold", display: "inline-block", padding: "10px" }}>
                    {t("org_Complete_Tree_Sub_Organization")}
                      <object style={{ marginLeft: "5px", width: "24px", height: "24px", verticalAlign: "-5px", horizontalAlign: "80px" }} type={"image/svg+xml"} data={subOrgIcon}>
                    </object>
                  </span>
                  : null
                }

                {chillingCenter === true ?
                  <span style={{ fontWeight: "bold", display: "inline-block", padding: "10px" }}>
                    {t("org_Complete_Tree_Chilling_Center")}
            <object style={{ marginLeft: "5px", width: "24px", height: "24px", verticalAlign: "-5px", horizontalAlign: "80px" }} type={"image/svg+xml"} data={chillingCenterIcon}>
                    </object>
                  </span>
                  : null
                }

                {plant === true ?
                  <span style={{ fontWeight: "bold", display: "inline-block", padding: "10px" }}>
                    {t("org_Complete_Tree_Plant")}
            <object style={{ marginLeft: "5px", width: "24px", height: "24px", verticalAlign: "-5px", horizontalAlign: "80px" }} type={"image/svg+xml"} data={plantIcon}>
                    </object>
                  </span>
                  : null
                }
                {route === true ?
                  <span style={{ fontWeight: "bold", display: "inline-block", padding: "10px" }}>
                    {t("org_Complete_Tree_Route")}
              <object style={{ marginLeft: "5px", width: "24px", height: "24px", verticalAlign: "-5px", horizontalAlign: "80px" }} type={"image/svg+xml"} data={routeIcon}>
                    </object>
                  </span>
                  : null
                }

                {zone === true ?
                  <span style={{ fontWeight: "bold", display: "inline-block", padding: "10px" }}>
                    {t("org_Complete_Tree_Zone")}
              <object style={{ marginLeft: "5px", width: "24px", height: "24px", verticalAlign: "-5px", horizontalAlign: "80px" }} type={"image/svg+xml"} data={zoneIcon}>
                    </object>
                  </span>
                  : null
                }


                {/* <span style={{fontWeight:"bold",display:"inline-block", padding:"10px"}}>
            Others
            <object style ={{marginLeft:"5px",width:"24px",height:"24px", verticalAlign:"-5px", horizontalAlign:"80px"}} type={"image/svg+xml"} data={othersIcon}>
            </object>
            </span> */}

                {sites === true ?
                  <span style={{ fontWeight: "bold", display: "inline-block", padding: "10px" }}>
                    {t("org_Complete_Tree_Site")}
              <object style={{ marginLeft: "5px", width: "24px", height: "24px", verticalAlign: "-5px", horizontalAlign: "80px" }} type={"image/svg+xml"} data={siteIcon}>
                    </object>
                  </span>
                  : null
                }

                {bmc === true ?
                  <span style={{ fontWeight: "bold", display: "inline-block", padding: "10px" }}>
                    {t("org_Complete_Tree_BMC")}
            <object style={{ marginLeft: "5px", width: "24px", height: "24px", verticalAlign: "-5px", horizontalAlign: "80px" }} type={"image/svg+xml"} data={bmcIcon}>
                    </object>
                  </span>
                  : null
                }

                {device === true ?

                  <span style={{ fontWeight: "bold", display: "inline-block", padding: "10px" }}>
                    {t("org_Complete_Tree_Device")}
            <object style={{ marginLeft: "5px", width: "24px", height: "24px", verticalAlign: "-5px", horizontalAlign: "80px" }} type={"image/svg+xml"} data={deviceIcon}>
                    </object>
                  </span>
                  : null
                }
              </Grid>
            </Grid>
          </Grid>
        )}
    </>
  );
}

const NodeLabel = ({ nodeData }) => {
  return (
    <span style={{ fontSize: "30px", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
      {/* <Link to={`/organization/profile/${nodeData.name}`}>{nodeData.name}</Link> */}
      <object style={{ width: "50px", height: "50px", verticalAlign: "-8px" }} type={"image/svg+xml"} data={nodeData.icon}>
      </object>
      {" " + nodeData.name}
    </span>

  );
};
