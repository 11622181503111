import React, { useState, useEffect, useContext } from "react";
import { Grid, Paper } from "@material-ui/core";
import addDays from "date-fns/addDays";
import CommonForm from "../../../components/common/form/common-form";
import PageHeader from "components/layout/PageHeader";
import * as Yup from "yup";
import { provisioningService } from "services/provisioningServices";
import { errorContext } from "context/errorContext";
import { useHistory, Redirect } from "react-router-dom";
import BmcRating from "components/common/elements/BmcRating";
import { useTranslation } from "react-i18next";

function analyticBmcRating() {
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [month, setMonth] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
  );
  const [loading, setLoading] = useState(false);
  const [siteId, setSiteId] = useState(null);
  const [bmcList, setBmcList] = useState([]);
  const [rating, setRating] = useState(false);
  const [bmcId, setBmcId] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [siteDetails, setSiteDetails] = React.useState(null);
  const { dispatch: errorDispatch } = useContext(errorContext);
  const { t } = useTranslation();

  const user = JSON.parse(localStorage.getItem("currentUser"));
  const organizationName = user && user.organizationName;
  let values = {
    siteName: "",
    chillingUnitID: "",
    month: month
  };
  useEffect(() => {
    setLoading(true);
    provisioningService
      .getAllSitesByOrg(organizationName)
      .then(data => {
        setSiteDetails(
          data.content.filter(res => res.numberOfChillingUnit > 0)
        );
        setLoading(false);
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
  }, [organizationName]);

  const handleSite = val => setSite(val);

  const setSite = val => {
    provisioningService
      .getChillingUnitData(val)
      .then(data => {
        setBmcList(data);
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
    setRating(false);
  };
  const validations = {
    siteName: Yup.string().required("This Field is Required"),
    chillingUnitID: Yup.string().required("This Field is Required"),
    month: Yup.string().required("This Field is Required")
  };

  const fields = [
    {
      type: "select",
      name: "siteName",
      label: t("analytics_Bmc_Rating_Select_Site"),
      placeholder: "Select Site",
      option: siteDetails
        ? siteDetails.map(site => ({
            value: site.siteId,
            label: site.siteName
          }))
        : [],
      grid: {
        xs: 12,
        sm: 2,
        md: 3,
        className: ""
      },
      customOnChange: handleSite
    },
    {
      type: "select",
      name: "chillingUnitID",
      label: t("analytics_Bmc_Rating_Select_Chilling_Unit"),
      placeholder: "Select Chilling Unit",
      option: bmcList
        ? bmcList.map(bmc => ({
            value: bmc.chillingUnitID,
            label: bmc.chillingUnitName + " - " + bmc.chillingUnitID
          }))
        : [],
      grid: {
        xs: 12,
        sm: 2,
        md: 3,
        className: "align-center"
      }
    },
    {
      type: "monthPicker",
      name: "month",
      value: { month },
      label: t("analytics_Bmc_Rating_Select_Month"),
      grid: {
        xs: 12,
        sm: 2,
        className: "align-center"
      }
    }
  ];

  const handleSubmit = (values, setSubmitting) => {
    setIsSubmitted(false);
    setBmcId(values.chillingUnitID);
    setStartDate(
      new Date(values.month.getFullYear(), values.month.getMonth(), 1)
    );
    setEndDate(
      new Date(values.month.getFullYear(), values.month.getMonth() + 1, 0)
    );
    setRating(true);
    setIsSubmitted(true);
    setSubmitting(false);

  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("analytics_Bmc_Rating_Header_Label")}
          breadcrumbs={[
            {
              label: t("analytics_Bmc_Rating_Breadcrum_Dashboard_Label"),
              link: "/dashboard"
            },
            { label: t("analytics_Bmc_Rating_Breadcrum_Analytics_Label") },
            { label: t("analytics_Bmc_Rating_Breadcrum_Bmc_Rating_Label") }
          ]}
        />
      </Grid>
      <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%"
        }}
      >
        <Grid item style={{ marginBottom: "0.5em" }}>
          <CommonForm
            fields={fields}
            submitLabel={t("analytics_Bmc_Rating_Submit_Label")}
            submittingLabel={t("analytics_Bmc_Rating_Submitting_Label")}
            validationSchema={Yup.object().shape({ ...validations })}
            validateOnBlur={true}
            initialValues={values}
            validateOnChange={true}
            onSubmit={(initialValues, { setSubmitting }) => {
              handleSubmit(initialValues, setSubmitting);
            }}
            buttonPosition="right"
            buttonSize="2"
            inlineButton={true}
          />
        </Grid>

        {rating && isSubmitted ? (
          <Grid item xs={12}>
            <Paper
              elevation={2}
              style={{
                padding: "2em",
                width: "100%"
              }}
            >
              <BmcRating
                bmcId={bmcId}
                translate={{
                  x: 200,
                  y: 350
                }}
                startDate={startDate}
                endDate={endDate}
              />
            </Paper>
          </Grid>
        ) : null}
      </Paper>
    </Grid>
  );
}

export default analyticBmcRating;
