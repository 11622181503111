import React from "react";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {useTranslation} from "react-i18next";

export default function DynamicPagination({
  pageIndex,
  pageInfo,
  previousPage,
  pageSize,
  setPageSize,
  nextPage,
  hasNextPage
}) {

  const { t } = useTranslation();
  return (
    <Grid
      container
      justify="space-between"
      style={{ padding: "1em 0em 1em 0em" }}
    >
      <Grid item container xs={12} md={2} justify="space-around" alignItems="center" className="page-btm-data">
        <span>
          {t("pagination_Page")} &nbsp;
          <strong>{pageIndex + 1}</strong>
          {pageInfo && t("pagination_Of")}
          {pageInfo && <strong>{pageInfo.totalPages}</strong>}
        </span>
        {setPageSize && pageSize && (
          <Select
            variant="outlined"
            margin="dense"
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50, 100].map(pageSize => (
              <MenuItem key={pageSize} value={pageSize}>
                {t("pagination_Show")} {pageSize}
              </MenuItem>
            ))}
          </Select>
        )}
      </Grid>
      <Grid item>
        <Button
          onClick={() => previousPage()}
          disabled={pageIndex === 0}
          color="primary"
        >
          {t("pagination_Previous")}
        </Button>
        <Button
          onClick={() => nextPage()}
          disabled={
            !!hasNextPage
              ? !hasNextPage
              : !!pageInfo && !!pageInfo.hasNextPage
              ? !pageInfo.hasNextPage
              : true
          }
          color="primary"
        >
          {t("pagination_Next")}
        </Button>
      </Grid>
    </Grid>
  );
}
