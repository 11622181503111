import React, { useState, useContext, useEffect } from "react";

import { useTranslation } from "react-i18next";
import Table from "components/common/table/DynamicPaginationTable3";
import { chillingUnitService } from "services/chillingUnitService.js";
import PageHeader from "../../components/layout/PageHeader";
import CommonForm from "../../components/common/form/common-form";
import MuiAlert from "@material-ui/lab/Alert";
import { errorContext } from "context/errorContext";
import * as Yup from "yup";
import addDays from "date-fns/addDays";
import { Grid, Paper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { provisioningService } from "../../services/provisioningServices";
import "../../components/Table.scss";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const AlertsSearchAll = () => {
  const [alertsData, setData] = useState(null);
  const [bmcList, setBmcList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate] = React.useState(addDays(new Date(), -2));
  const [endDate] = React.useState(new Date());
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const organizationName = user && user.organizationName;
  const { dispatch: errorDispatch } = useContext(errorContext);
  const initialValues = {
    chillingUnitUuid: [],
    startDate: startDate,
    endDate: endDate
  };
  const handleSubmit = (values, cb) => {
    setLoading("loading");
    setData(null);
    cb();
    const alertTypes = ["All Alerts"];
    const uniqueId = [];
    const uniqueSite = [];
    const data = values.chillingUnitUuid.map(chills => {
      const [id, site] = chills.value.split("___");
      uniqueId.push(id);
      uniqueSite.push(site);
      return null;
    });
    // const [id, site] = values.chillingUnitUuid.split("___");
    const body = {
      alertTypes: alertTypes,
      chillingUnitUuids: uniqueId,
      siteUuids: uniqueSite,
      pagingSize: 100000,
      pagingState: "",
      hour: 24,
      startDate: values.startDate.toISOString(),
      endDate: values.endDate.toISOString(),
      toExclude: true
    };
    console.log(body);
    chillingUnitService
      .searchAllAlerts(body)
      .then(result => {
        if (result.hasOwnProperty("content")) {
          setData(result.content.RESULT);
        } else {
          setData([]);
        }
        setLoading(false);
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
        // console.log(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    provisioningService
      .getAllSitesByOrg(organizationName)
      .then(data => {
        const value = data.content.reduce((acc, cv) => {
          const site = cv.siteUUID;
          if (cv.chillingUnitCompresedDTOS.length !== 0) {
            const data = cv.chillingUnitCompresedDTOS.flat();
            const res = { siteUUID: site };
            const result = data.map(d => {
              return Object.assign({}, res, d);
            });
            return [...result, ...acc];
          }
          return [...acc];
        }, []);
        // var duplicates = value.reduce(function(acc, el, i, arr) {
        //   if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
        // }, []);
        // console.log(duplicates);
        // const res = [
        //   ...new Set(
        //     value.map(item => {
        //       return item;
        //     })
        //   )
        // ];
        setBmcList(value);
        setLoading(false);
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
        // console.log(error);
      });
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Chilling Unit ID",
        accessor: "chillingUnitId",
        aggregate: "uniqueCount",
        Aggregated: ({ cell }) => {
          const { value } = cell;
          return (
            <strong>
              {value === 1
                ? `${value} Chilling Unit`
                : `${value} Chilling Units`}
            </strong>
          );
        }
      },
      {
        Header: t("total_Bmc_Site_Name"),
        accessor: "siteName",
        aggregate: "uniqueCount",
        Aggregated: ({ cell }) => {
          const { value } = cell;
          return (
            <strong>{value === 1 ? `${value} site` : `${value} sites`}</strong>
          );
        }
      },
      {
        Header: "Site ID",
        accessor: "siteId",
        aggregate: "uniqueCount",
        Aggregated: ({ cell }) => {
          const { value } = cell;
          return (
            <strong>{value === 1 ? `${value} Id` : `${value} Ids`}</strong>
          );
        }
      },
      {
        Header: "Alert Level",
        accessor: "alertLevel",
        aggregate: "count",
        Aggregated: ({ cell }) => {
          const { value } = cell;
          return (
            <div>
              <strong>
                {value + " Total Alert" + (value === 1 ? "" : "s")}
              </strong>
            </div>
          );
        },
        Cell: ({ cell }) => {
          const { value } = cell;
          const color = value === "INFO" ? "blue" : "red";
          return <span style={{ color: color }}>{value}</span>;
        }
      },
      {
        Header: "Alert Name",
        accessor: "alertType",
        aggregate: "uniqueCount",
        Aggregated: ({ cell }) => {
          const { value } = cell;
          return (
            <div>
              <strong>
                {value === 1
                  ? `${value} Unique Alert`
                  : `${value} Unique Alerts`}
              </strong>
            </div>
          );
        }
        // ,
        // Cell: ({ value, row }) => {
        //   console.log(row);
        //   const color = row.values.alertLevel === "INFO" ? "blue" : "red";
        //   return <div style={{ color: color }}>{value}</div>;
        // }
      },
      {
        Header: "Alert Message",
        accessor: "alertMessage",
        disableGroupBy: true,
        aggregate: "count",
        Aggregated: ({ cell }) => {
          const { value } = cell;
          return (
            <div>
              <strong>
                {value === 1 ? `${value} Message` : `${value} Messages`}
              </strong>
            </div>
          );
        },
        Cell: ({ cell }) => {
          const { value } = cell;
          return (
            <div
              style={{
                whiteSpace: "break-spaces",
                textAlign: "left",
                overflow: "auto"
                // ,
                // paddingLeft: "1em",
                // paddingRight: "1em"
              }}
            >
              {value}
            </div>
          );
        }
      }
    ],
    []
  );
  const fields = [
    {
      type: "multiSelectSearch",
      name: "chillingUnitUuid",
      label: t("pm_View_Record_Select_Chilling_Unit"),
      placeholder: "Select Chilling Unit",
      option:
        bmcList.length !== 0
          ? bmcList.map(bmc => ({
              value: bmc.chillingUnitUuid + "___" + bmc.siteUUID,
              label: bmc.chillingUnitName + " - " + bmc.chillingUnitId
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3
      }
    },
    {
      type: "datePicker",
      name: "startDate",
      value: { startDate },
      label: t("pm_View_Record_Start_Date"),
      maxDate: startDate,
      grid: {
        xs: 12,
        sm: 3
      }
    },
    {
      type: "datePicker",
      name: "endDate",
      value: { endDate },
      maxDate: endDate,
      label: t("pm_View_Record_End_Date"),
      grid: {
        xs: 12,
        sm: 3
      }
    }
  ];

  const validations = {
    chillingUnitUuid: Yup.string()
      .required("Please select Chilling Unit")
      .nullable(),
    startDate: Yup.string().required("Start Date is needed"),
    endDate: Yup.string().required("End Date is needed")
  };

  const data = React.useMemo(() => alertsData);

  const title = "Alerts Search";
  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={title}
          breadcrumbs={[
            {
              label: t("total_Bmc_Breadcrum_Dashboard_Label"),
              link: "/dashboard"
            },
            { label: title }
          ]}
        />
      </Grid>
      <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%"
        }}
      >
        {bmcList.length !== 0 && (
          <CommonForm
            fields={fields}
            submitLabel={t("pm_View_Record_Submit_Label")}
            submittingLabel={t("pm_View_Record_Submitting_Label")}
            validationSchema={Yup.object().shape({ ...validations })}
            validateOnBlur={true}
            initialValues={initialValues}
            validateOnChange={true}
            onSubmit={(initialValues, { setSubmitting }) => {
              handleSubmit(initialValues, setSubmitting);
            }}
            buttonPosition="right"
            buttonSize="2"
            inlineButton={true}
            style={{ overflow: "auto" }}
          />
        )}
        {loading && (
          <Grid
            container
            xs={12}
            justify="center"
            alignItems="center"
            style={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        )}
      </Paper>
      {alertsData &&
        (alertsData.length !== 0 ? (
          <Table
            columns={columns}
            data={data}
            needDropdown={false}
            extraHeaders={false}
            fileName={"alertsData.xls"}
          />
        ) : (
          <Grid
            container
            xs={12}
            justify="center"
            alignItems="center"
            style={{ height: "50vh" }}
          >
            <Alert severity="info">No data found!</Alert>
          </Grid>
        ))}
    </Grid>
  );
};

export default AlertsSearchAll;
