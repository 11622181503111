function getToken() {
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const token = user && user.jwtAccessToken;
  return token;
}


async function getRawData(
  deviceType,
  deviceId,
  startDate,
  endDate,
  pageIndex,
  pageSize,
  eventName
) {

  let url = (eventName !== "RECEIVEDData" && eventName !== "VOLUME,TEMP") ? `/authgateway/deviceDataService/device/${deviceType}?deviceId=${deviceId}&startTime=${startDate}&endTime=${endDate}&page=${pageIndex}&size=${pageSize}&orderByDSC=processTime&eventName=${eventName}` : eventName === "RECEIVEDData"
    ? `/authgateway/deviceDataService/device/${deviceType}?deviceId=${deviceId}&startTime=${startDate}&endTime=${endDate}&page=${pageIndex}&size=${pageSize}&orderByDSC=processTime` : `/authgateway/deviceDataService/device/${deviceType}?deviceId=${deviceId}&startTime=${startDate}&endTime=${endDate}&page=${pageIndex}&size=${pageSize}&orderByDSC=processTime&eventName=${eventName}&include=false`;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      authToken : localStorage.getItem("idToken")

    }
  };

  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}



export const deviceDataService = {
  getRawData
}
