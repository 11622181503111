import React, { useState, useContext, useEffect, useMemo } from "react";

import { useHistory, useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import TemplateForTables from "../../components/layout/innerPagesTemplate";
import Table from "components/common/table/DynamicPaginationTable2";
import format from "date-fns/format";
import { chillingUnitService } from "services/chillingUnitService.js";
import PageHeader from "../../components/layout/PageHeader";
import ToolTipCell from "components/common/table/ToolTipCell";

import { errorContext } from "context/errorContext";
import { dashboardContext } from "context/dashboardContext";

import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import "../../components/Table.scss";

const TotalBMC3 = props => {
  const [bmcData, setData] = useState([]);
  const [status, setStatus] = useState("loading");
  const [pagingInfo, setPaging] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);

  const { t } = useTranslation();
  let { state } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { dispatch: errorDispatch } = useContext(errorContext);
  const { dispatch: dashboardDispatch, state: dashboardState } = useContext(
    dashboardContext
  );

  // const dropitems = [
  //   {
  //     item: (
  //       <Link to="/configuration/organization">
  //         {t("organization_configuration_list_dropdown_add_org")}
  //       </Link>
  //     )
  //   },
  //   {
  //     item: (
  //       <Link to="/configuration/suborganizationprofile">
  //         {t("organization_configuration_list_dropdown_add_sub_org")}
  //       </Link>
  //     )
  //   }
  // ];

  const handleBmcClick = (uuid, siteId) => {
    dispatch({
      type: "SITE_LOADING"
    });
    dispatch({
      type: "CHILLING_UNIT_ID_SELECTED",
      id: uuid
    });
    dispatch({
      type: "SITE_DETAILS_FETCH",
      siteId
    });
    dispatch({
      type: "CHILLING_LIST_FETCH",
      siteId
    });
    history.push(`/bmcgraph/${siteId}`);
  };

  let query = useMemo(() => new URLSearchParams(props.location.search), [
    props.location
  ]);

  useEffect(() => {
    let res = [];
    // let alerts = [];
    // // setPageIndex(Number(query.get("page")));
    // let date = "";
    // date = new Date();
    // chillingUnitService
    //   .getBmcStateTest(query.get("date"))
    //   .then(result => {
    //     console.log("first ", result.content.RESULT);
    //     res = result.content.RESULT.reduce((acc, next) => {
    //       const date = new Date(next.stateReceivedAt);
    //       next.stateReceivedAt = format(date, "dd MMM yyyy - HH:mm");
    //       return [...acc, next];
    //     }, []);
    //     for (let i = 0; i < alerts.length; i++) {
    //       res[i].infoAlertsCount = alerts[i].infoAlertsCount;
    //       res[i].criticalAlertsCount = alerts[i].criticalAlertsCount;
    //     }
    //     console.log("time diff ", (new Date() - date) / 1000);
    //     setData(res);
    //     setStatus("success");
    //   })
    //   .catch(error => {
    //     errorDispatch({
    //       type: "ERROR",
    //       error
    //     });
    //   });
    // chillingUnitService
    //   .getBmcAlertsTest(query.get("date"))
    //   .then(result => {
    //     console.log("second ", result.content.RESULT);
    //     alerts = result.content.RESULT.reduce((acc, next) => {
    //       const date = new Date(next.stateReceivedAt);
    //       next.stateReceivedAt = format(date, "dd MMM yyyy - HH:mm");
    //       return [...acc, next];
    //     }, []);
    //     // date = new Date();
    //   })
    //   .catch(error => {
    //     errorDispatch({
    //       type: "ERROR",
    //       error
    //     });
    //   });
    let date = new Date();
    Promise.all([
      chillingUnitService.getBmcStateTest(query.get("date")),
      chillingUnitService.getBmcAlertsTest(query.get("date"))
    ])
      .then(result => {
        try {
          if (
            result[0].hasOwnProperty("content") &&
            result[1].hasOwnProperty("content")
          ) {
            res = result[0].content.RESULT.reduce((acc, next) => {
              const date = new Date(next.stateReceivedAt);
              next.stateReceivedAt = format(date, "dd MMM yyyy - HH:mm");
              return [...acc, next];
            }, []);
            for (let i = 0; i < result[0].content.RESULT.length; i++) {
              // console.log(
              //   `result[0].content.RESULT[${i}].infoAlertsCount `,
              //   result[0].content.RESULT[i].infoAlertsCount
              // );
              result[0].content.RESULT[i].infoAlertsCount =
                result[1].content.RESULT[i].infoAlertsCount;
              // console.log(
              //   `result[1].content.RESULT[${i}].infoAlertsCount `,
              //   result[1].content.RESULT[i].infoAlertsCount
              // );
              // console.log(
              //   `result[0].content.RESULT[${i}].criticalAlertsCount `,
              //   result[0].content.RESULT[i].criticalAlertsCount
              // );
              result[0].content.RESULT[i].criticalAlertsCount =
                result[1].content.RESULT[i].criticalAlertsCount;
              // console.log(
              //   `result[1].content.RESULT[${i}].criticalAlertsCount `,
              //   result[1].content.RESULT[i].criticalAlertsCount
              // );
            }
            // console.log("time diff ", (new Date() - date) / 1000);
            // console.log("resolved ", res);
            setData(res);
            setStatus("success");
          } else {
            errorDispatch({
              type: "no_data",
              error: "no data"
            });
          }
        } catch (e) {
          const error = { status: "NO DATA" };
          errorDispatch({
            type: "ERROR",
            error
          });
        }
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
  }, []);

  // const nextPage = () => {
  //     if (hasNextPage) {
  //         query.set("paging", pagingInfo);
  //         query.set("page", pageIndex + 1);
  //         props.history.push({
  //             path: props.location.pathname,
  //             search: query.toString()
  //         });
  //     }
  // };

  // const previousPage = () => {
  //     let index = pageIndex;
  //     if (index > 0) {
  //         props.history.goBack();
  //     }
  // };

  const columns = React.useMemo(
    () => [
      {
        Header: t("total_Bmc_Bmc_Info"),
        columns: [
          {
            Header: t("total_Bmc_Bmc_Id"),
            accessor: "chillingUnitId",
            Cell: ({ row }) => {
              return (
                <span
                  className="info-text"
                  onClick={() =>
                    handleBmcClick(
                      row.original.chillingUnitUUID,
                      row.original.siteId
                    )
                  }
                >
                  {row.original.chillingUnitId}
                </span>
              );
            }
          },
          {
            Header: t("total_Bmc_Site_Name"),
            accessor: "siteName",
            Cell: ({ cell, row }) => {
              return <ToolTipCell>{row.original.siteName || null}</ToolTipCell>;
            }
          },
          {
            Header: t("total_Bmc_Site_Id"),
            accessor: "siteId"
          },
          {
            Header: t("total_Bmc_Capacity"),
            accessor: "capacity"
          }
        ]
      },
      {
        Header: t("total_Bmc_Data"),
        columns: [
          {
            Header: t("total_Bmc_Volume"),
            accessor: "volume"
          },
          // {
          //     Header: "Milk Lifted",
          //     accessor: "lastMilkLifted"
          //   },
          {
            Header: t("total_Bmc_Temperature"),
            accessor: "temperature"
          }
        ]
      },
      {
        Header: t("total_Bmc_States"),
        columns: [
          {
            Header: t("total_Bmc_Current_State"),
            accessor: "state",
            Cell: ({ row }) => {
              if (row.original.state === "NO_DATA") {
                return <span className="empty">{row.original.state}</span>;
              } else if (row.original.state === "UNDER_COOLED") {
                return (
                  <span className="under-chilled">{row.original.state}</span>
                );
              } else if (row.original.state === "COOLED") {
                return <span className="chilled">{row.original.state}</span>;
              } else return <span>{row.original.state}</span>;
            }
          },
          {
            Header: t("total_Bmc_Last_Updated"),
            accessor: "stateReceivedAt"
          }
        ]
      },
      {
        Header: " ",
        columns: [
          {
            Header: t("total_Bmc_Events"),
            accessor: "infoAlertsCount",
            Cell: ({ cell, row }) => {
              const {
                infoAlertsCount,
                siteUuid,
                chillingUnitUUID
              } = row.original;
              const enabled = infoAlertsCount > 0;

              return enabled ? (
                <Link
                  to={{
                    pathname: "/info-alerts",
                    search: `?sites=${siteUuid}&chillingUnits=${chillingUnitUUID}&paging=&page=0&date=${dashboardState.currentDate}`
                    // state: {
                    //   from: "all-bmc",
                    //   state: state,
                    //   siteUuid: [siteUuid],
                    //   chillingUnitUUID: [chillingUnitUUID]
                    // }
                  }}
                >
                  <span className="info-alert">{infoAlertsCount}</span>
                </Link>
              ) : (
                <span className="info-alert">{infoAlertsCount}</span>
              );
            }
          },
          {
            Header: t("total_Bmc_Alerts"),
            accessor: "criticalAlertsCount",
            Cell: ({ cell, row }) => {
              const {
                criticalAlertsCount,
                siteUuid,
                chillingUnitUUID
              } = row.original;
              const enabled = criticalAlertsCount > 0;

              return enabled ? (
                <Link
                  to={{
                    pathname: "/critical-alerts",
                    search: `?sites=${siteUuid}&chillingUnits=${chillingUnitUUID}&paging=&page=0&date=${dashboardState.currentDate}`
                  }}
                >
                  <span className="critical-alert">{criticalAlertsCount}</span>
                </Link>
              ) : (
                <span className="critical-alert">{criticalAlertsCount}</span>
              );
            }
          }
        ]
      }
    ],
    []
  );

  const data = React.useMemo(() => [...bmcData], [bmcData]);
  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("total_Bmc_All_Bmcs")}
          breadcrumbs={[
            {
              label: t("total_Bmc_Breadcrum_Dashboard_Label"),
              link: "/dashboard"
            },
            { label: t("total_Bmc_Breadcrum_All_Bmc_Label") }
          ]}
        />
      </Grid>
      {status === "loading" ? (
        <Grid
          container
          xs={12}
          justify="center"
          alignItems="center"
          style={{ height: "50vh" }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Table
          columns={columns}
          data={data}
          needDropdown={false}
          // dropItems={dropitems}
          extraHeaders={true}
          fileName={"bmcData.xls"}
        />
      )}
    </Grid>
  );
};

export default TotalBMC3;
