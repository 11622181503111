import React from "react";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// import "../page-containers/BMCStatusPage.css";
import { getIn } from "formik";
export default function CustomTimePicker({
  label,
  name,
  value = new Date(),
  handleTimeChange,
  setFieldTouched,
  error,
  touched
}) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TimePicker
        error={error && !!getIn(touched, name)}
        helperText={error && !!getIn(touched, name) ? error : ""}
        autoOk
        allowKeyboardControl
        onOpen={e => setFieldTouched(name, true)}
        variant="inline"
        margin="dense"
        clearable
        inputVariant="outlined"
        label={label}
        value={value}
        fullWidth={true}
        InputAdornmentProps={{ position: "end", style: { padding: "0px" } }}
        onChange={time => {
          handleTimeChange(time);
          setFieldTouched(name, true);
          handleTimeChange(time);
        }}
        KeyboardButtonProps={{ style: { marginRight: "-0.6em" } }}
        className="time-picker"
      />
    </MuiPickersUtilsProvider>
  );
}
