import React from "react";

import { Field, getIn } from "formik";

import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function SearchSingleSelect({ options, label, name, error, customOnChange = () => { },}) {
  
  const [open, setOpen] = React.useState(false);
  const loading = open && options.length === 0;
  //console.log("options", options);
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        let { setFieldValue, setFieldTouched, touched } = form;
        // console.log(
        //   "value and touched",
        //   field.value,
        //   field,
        //   form.touched,
        //   meta
        // );
        return (
          <Autocomplete
            error={error && !!getIn(touched, name)}
            options={options}
            getOptionLabel={option => option.label}
            onOpen={() => {
              field.onBlur();
              setOpen(true);
            }}
            onClose={() => {
              setFieldTouched(name, true, false);
              setOpen(false);
            }}
            onChange={(e, result) => {
              //console.log("but why?", result);
              if(result == null) customOnChange(result);
              if (result !== null ) customOnChange(result.value);
              //else setFieldValue("");
              
             // field.onChange();
              field.onBlur();
              if (result !== null) setFieldValue(name, result.value);
              else setFieldValue(name, result);
            }}
            loading={loading}
            renderInput={params => (
              <TextField
                helperText={error && !!getIn(touched, name) ? error : ""}
                error={error && !!getIn(touched, name)}
                {...params}
                variant="outlined"
                label={label}
                placeholder={label}
                fullWidth
                // inputProps={{
                //   ...params.inputProps,
                //   value: options.find((option) => option.value === field.value) ? params.inputProps.value : '' // Update the value manually
                // }}
              />
            )}
          />
        );
      }}
    </Field>
  );
}
