import React, { useState, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { provisioningService } from "services/provisioningServices";

import UserForm from "./UserForm";

export default function EditUser() {
  const org = JSON.parse(localStorage.getItem("currentUser")).organizationName;
  const { userName } = useParams();
  const status = useSelector(state => state.userConfiguration.toJS().status);
  const history = useHistory();

  const [userDetails, setUserDetails] = useState(null);
  const sitesList = useSelector(
    state => state.userConfiguration.toJS().sitesList
  );
  const sitesAssociationList = useSelector(
    state => state.userConfiguration.toJS().sitesAssociationList
  );

  //didMount
  useEffect(() => {
    dispatch({
      type: "USER_CONFIGURATION_RESET_SITES_CONFIGURATION"
    });
    provisioningService.getUserByUserName(userName).then(data => {
      setUserDetails(data.response);
    });
  }, []);
  // didUpdate userDetails, (edit details loaded)
  useEffect(() => {
    if (!!userDetails) {
      handleOrganizationSelect(org);
      !!userDetails.alertConfiguredSubOrgNames &&
        handleSubOrganizationSelect(userDetails.alertConfiguredSubOrgNames);
      !!userDetails.siteAssociationConfiguration &&
        handleSiteAssociationSelect(
          userDetails.siteAssociationConfiguration.map(site => site.siteName)
        );
      !!userDetails.siteAssignedSubOrgNames &&
        handleSubOrganizationForSiteAssociationSelect(
          userDetails.siteAssignedSubOrgNames
        );
      // if (!!userDetails && sitesAssociationList.length > 0) {
      //   !!userDetails.siteAssociationConfiguration &&
      //     handleSiteAssociationSelect(
      //       userDetails.siteAssociationConfiguration.map(site => site.siteName)
      //     );
      // }
    }
  }, [userDetails]);

  const [sitesAssociationLoaded, setSitesAssociationLoaded] = useState(false);

  useEffect(() => {
    if (
      !sitesAssociationLoaded &&
      sitesAssociationList.length > 0 &&
      !!userDetails &&
      !!userDetails.siteAssociationConfiguration
    ) {
      !!userDetails.siteAssociationConfiguration &&
        handleSiteAssociationSelect(
          userDetails.siteAssociationConfiguration.map(site => site.siteName)
        );
      setSitesAssociationLoaded(true);
    }
  }, [sitesAssociationList]);

  //if succeed editing...
  useEffect(() => {
    if (status === "success") {
      history.push(`/profile/success`);
      dispatch({
        type: "USER_CONFIGURATION_RESET_STATUS"
      });
      setUserDetails(null);
    }
  }, [status]);

  const handleOrganizationSelect = org => {
    dispatch({
      type: "USER_CONFIGURATION_ORGANIZATION_SELECT_UPDATED",
      org
    });
  };

  const dispatch = useDispatch();

  const subOrganizations = useSelector(
    state => state.userConfiguration.toJS().subOrganizations
  );

  const handleSubOrganizationSelect = (subOrganization, setFieldValue) => {
    dispatch({
      type: "USER_CONFIGURATION_SUB_ORGANIZATIONS_UPDATED",
      subOrganizationsSelected: subOrganization,
      setFieldValue,
      siteField: "sitesForAlertsConfiguration"
    });
  };
  const handleSubOrganizationForSiteAssociationSelect = (
    subOrganization,
    setFieldValue
  ) => {
    dispatch({
      type: "USER_CONFIGURATION_SUB_ORGANIZATIONS_FOR_SITE_ASSOCIATION_UPDATED",
      subOrganizationsSelected: subOrganization,
      setFieldValue,
      field: "siteAssociationConfiguration"
    });
  };
  //
  const handleSiteSelectForAlertsConfiguration = sites => {
    dispatch({
      type: "USER_CONFIGURATION_ALERTS_CONFIG_SITES_SELECTED_UPDATE",
      sitesSelected: sites
    });
  };

  const handleSiteAssociationSelect = sites => {
    dispatch({
      type: "USER_CONFIGURATION_SITE_ASSIGN",
      sitesAssigned: sites
    });
  };

  const sitesAlertsConfiguration = useSelector(
    state => state.userConfiguration.toJS().sitesAlertsConfiguration
  );

  let values = {
    userName: userDetails ? userDetails.userName : "",
    emailId: userDetails ? userDetails.emailId : "",
    // password: "",
    firstName: userDetails ? userDetails.firstName : "",
    lastName: userDetails ? userDetails.lastName : "",
    active: userDetails ? userDetails.active : 1,
    roles: userDetails ? (userDetails.roles ? userDetails.roles[0] : "") : "",
    gender: userDetails ? userDetails.gender : "",
    salutation: userDetails ? userDetails.salutation : "",
    mobileNumber: [
      {
        mobileNumber: userDetails
          ? userDetails.mobileNumbers[0].mobileNumber
          : "",
        countryCode: userDetails ? userDetails.mobileNumbers[0].countryCode : ""
      }
    ],
    organizationName: userDetails ? userDetails.organizationName : org,
    subOrganizationForSiteAssociation: userDetails
      ? !!userDetails.siteAssignedSubOrgNames &&
        userDetails.siteAssignedSubOrgNames
      : "",
    subOrganizationName: userDetails
      ? !!userDetails.alertConfiguredSubOrgNames &&
        userDetails.alertConfiguredSubOrgNames
      : "",
    isOauthAuthenticated: null,
    // for each alert config, filter the site with siteUuid from the siteList
    // take the siteName from the site data and return that array.
    // filter out the the null items from the returned array. [edge case]
    // remove repeated items from the list and pass to the initialValues.
    sitesForAlertsConfiguration: userDetails
      ? !!userDetails.userAlertConfiguration &&
        userDetails.userAlertConfiguration.map(alert => {
          return alert.siteName;
        })
      : "",
    siteAssociationConfiguration: userDetails
      ? userDetails.siteAssociationConfiguration &&
        userDetails.siteAssociationConfiguration.map(site => site.siteName)
      : [],
    userAlertConfiguration: userDetails
      ? userDetails.userAlertConfiguration
      : [],
    alertsLanguage: userDetails
      ? !!userDetails.alertsLanguage
        ? userDetails.alertsLanguage
        : ""
      : "",
    password: "",
    confirmPassword: "",
    enabled: true,
    locked: false,
    expired: false
  };

  function handleSubmit(values, resetForm) {
    dispatch({
      type: "USER_CONFIGURATION_EDIT_USER",
      values,
      resetForm,
      userId: userDetails.userId
    });
  }

  return !!userDetails ? (
    <UserForm
      values={values}
      handleSubmit={handleSubmit}
      status={status}
      subOrganizations={subOrganizations}
      handleSubOrganizationSelect={handleSubOrganizationSelect}
      handleSubOrganizationForSiteAssociationSelect={
        handleSubOrganizationForSiteAssociationSelect
      }
      sitesList={sitesList}
      sitesAssociationList={sitesAssociationList}
      handleSiteSelectForAlertsConfiguration={
        handleSiteSelectForAlertsConfiguration
      }
      handleSiteAssociationSelect={handleSiteAssociationSelect}
      sitesAlertsConfiguration={
        !!sitesAlertsConfiguration
          ? sitesAlertsConfiguration
          : userDetails.userAlertConfiguration
      }
      edit
    />
  ) : null;
}
