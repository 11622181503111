import { Map } from "immutable";

let initialState = Map({
  data: {
    vol: [],
    temp: [],
    grid: [],
    agitator: [],
    dg: [],
    comp: [],
    milkpump: []
  },
  markPoints: {
    grid: [],
    milkpump: [],
    dg: [],
    agitator: [],
    comp: []
  },
  criticalAlerts: [],
  criticalAlertsMarkPoints: [],
  alerts: null,
  status: "loading",
  uuid: ""
});

export default function chillingGraph(state = initialState, action) {
  switch (action.type) {
    case "CHILLING_GRAPH_LOADING":
      return state.set("status", "loading");
    case "CHILLING_GRAPH_DATA_FETCH_SUCCESS":
      return state.set("data", action.data).set("status", "success");
    case "CHILLING_UNIT_MARK_POINT_COMPUTED_SUCCESS":
      return state.set("markPoints", action.markPoints);
    case "CHILLING_GRAPH_ALERTS_DATA_FETCH_SUCCESS":
      return state.set("alerts", action.alerts);
    case "CHILLING_GRAPH_CRITICAL_ALERTS":
      return state.set("criticalAlerts", action.alerts);
    case "CHILLING_GRAPH_CRITICAL_MARKERS":
      return state.set("criticalAlertsMarkPoints", action.markPoints);
    case "CHILLING_UNIT_ID_SELECTED":
      return state.set("uuid", action.id);
    default:
      return state;
  }
}
