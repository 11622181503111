import React, { useEffect, useContext, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import "../../../components/Table.scss";
import { provisioningService } from "../../../services/provisioningServices.js";
import Table from "components/common/table/DynamicPaginationTable2";
import PageHeader from "components/layout/PageHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import { errorContext } from "context/errorContext";

import { UseTranslation, useTranslation } from "react-i18next";
import * as Yup from "yup";
import CommonForm from "components/common/form/common-form";
import Paper from "@material-ui/core/Paper";
import addDays from "date-fns/addDays";
//import SweetAlert from 'react-bootstrap-sweetalert';

const AgentPaymentList = props => {

  const { t } = useTranslation();

  const [status, setStatus] = React.useState("loading");
  const [agentPaymentStatus, setAgentPaymentStatus] = React.useState("loading");
  const [pageInfo, setPageInfo] = React.useState({});
  const [agentPaymentList, setAgentPaymentList] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(10);
  const [pageIndex, setPageIndex] = React.useState(0);

  const [fromDate,setFromDate] = React.useState(addDays(new Date(), -7));
  const [toDate,setToDate] = React.useState(new Date());

  const { dispatch: errorDispatch } = useContext(errorContext);

  let values = {
    fromDate: addDays(new Date(), -7),
    toDate: new Date()
  };

  const formikForm = React.useRef(null);

  const fields = [
    {
      type: "datePicker",
      name: "fromDate",
      label: t("agent_Payment_Form_FromDate_Label"),
      placeholder: t("agent_Payment_Form_FromDate_Label"),
      value: { fromDate },
      grid: {
        xs: 12,
        sm: 2,
        md: 3,
        className: ""
      }
    },
    {
      type: "datePicker",
      name: "toDate",
      value: { toDate },
      label: t("agent_Payment_Form_ToDate_Label"),
      grid: {
        xs: 12,
        sm: 2,
        md: 3,
        className: ""
      }
    }
  ]; 

  let validations = {
    fromDate: Yup.string().required(
        t("agent_Payment_Form_Validation_FromDate")
    ),
    toDate: Yup.string().required(
        t("agent_Payment_Form_Validation_ToDate")
    )
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("agent_Payment_PaymentDateTime_Label"),
        accessor: "paymentDateTime"
      },
      {
        Header: t("agent_Payment_AgentUnqId_Label"),
        accessor: "agentUnqId"
      },
      {
        Header: t("agent_Payment_MobileNumber_Label"),
        accessor: "mobileNumber"
      },
      {
        Header: t("agent_Payment_NetAmount_Label"),
        accessor: "netAmount"
      },
      {
        Header: t("agent_Payment_Commission_Label"),
        accessor: "detailsBreakUp.commission"
      },
      {
        Header: t("agent_Payment_Addition1_Label"),
        accessor: "detailsBreakUp.addition1"
      },
      {
        Header: t("agent_Payment_Addition2_Label"),
        accessor: "detailsBreakUp.addition2"
      },
      {
        Header: t("agent_Payment_OtherCommission_Label"),
        accessor: "detailsBreakUp.otherCommission"
      },
      {
        Header: t("agent_Payment_Recovery_Label"),
        accessor: "detailsBreakUp.recovery"
      },
      {
        Header: t("agent_Payment_Deduction2_Label"),
        accessor: "detailsBreakUp.deduction2"
      },
      {
        Header: t("agent_Payment_Deduction3_Label"),
        accessor: "detailsBreakUp.deduction3"
      },
      {
        Header: t("agent_Payment_NetPayableAmount_Label"),
        accessor: "detailsBreakUp.netPayableAmount"
      }
    ],
    []
  );


  useEffect(() => {
    
    setStatus("loading");
    setAgentPaymentStatus("success");

    var datetime = new Date(fromDate);
    let sDate = datetime
      .toISOString()
      .replace("Z", " ")
      .replace("T", " ")
      .trim()
      .substring(0,10);   
  
    var datetime = new Date(toDate);
    let eDate = datetime
        .toISOString()
        .replace("Z", " ")
        .replace("T", " ")
        .trim()
        .substring(0,10);

    provisioningService
      .verifyOtpNew('1313131313')
      .then((res) => {
        const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");

          provisioningService
          .getAgentPaymentList(sDate,eDate)
          .then(data => {
            setAgentPaymentList(data.content || []);
            setPageInfo(data.pageInfo);
            setStatus("success");
          })
          .catch(error => {
            errorDispatch({
              type: "ERROR",
              error
            });
          });

      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
    
  }, [ ]);

  function handleSubmit(values, resetForm, setSubmitting) {

    var datetime = new Date(values.fromDate);
    let sDate = datetime
      .toISOString()
      .replace("Z", " ")
      .replace("T", " ")
      .trim()
      .substring(0,10);   
  
    var datetime = new Date(values.toDate);
    let eDate = datetime
        .toISOString()
        .replace("Z", " ")
        .replace("T", " ")
        .trim()
        .substring(0,10);

    provisioningService
          .getAgentPaymentList(sDate,eDate)
          .then(data => {
            console.log("Hanlde Submit --- ", data);
            setAgentPaymentList(data.content || []);
            setPageInfo(data.pageInfo);
            setStatus("success");
          })
          .catch(error => {
            errorDispatch({
              type: "ERROR",
              error
            });
          });
    setSubmitting(false);
  }

  const data = React.useMemo(() => [...agentPaymentList]);

  const nextPage = () => {
    if (
      status !== "loading" &&
      pageInfo.hasNextPage &&
      pageIndex < pageInfo.totalPages
    ) {
      let index = pageIndex;
      setPageIndex(++index);
    }
  };
  const previousPage = () => {
    let index = pageIndex;
    if (index > 0) {
      setPageIndex(--index);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("agent_Payment_Header_Label")}
          breadcrumbs={[
            { label: t("agent_Payment_Breadcrumb_Dashboard_Label"), link: "/dashboard" },
            { label: t("agent_Payment_Breadcrumb_Config_Label") },
            { label: t("agent_Payment_Breadcrumb_Agent_Payment_Label") }
          ]}
        />
      </Grid>

      <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%"
        }}
      >

{agentPaymentStatus === "loading" ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ):(
      <CommonForm
        fields={fields}
        submitLabel={t("site_Form_Submit_Label")}
        submittingLabel={t("site_Form_Submitting_Label")}
        initialValues={values}
        validationSchema={Yup.object().shape({ ...validations })}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={(
          values,
          { setSubmitting, setFieldError, resetForm, errors }
        ) => {
          if (!!errors) {
            setSubmitting(false);
          }
          handleSubmit(values, resetForm, setSubmitting);
        }}
        formikRef={formikForm}
        buttonSize="2"
        buttonPosition="right"
        inlineButton={true}
      />
      )}
    
      {status === "loading" ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: "50vh" }}
        >
          <CircularProgress />
        </Grid>
      ) : {...agentPaymentList.length > 0 ? (
          <Table
            columns={columns}
            data={data}
            fileName={"agentPaymentList.xls"}
          />
        ) : 
        <>
        <div>
        <span style={{ fontSize: "14px", color: "red" }}><b>No Data Available !!</b></span>
        <br />
        <span style={{ fontSize: "14px", color: "red" }}><b>Please select different date range !!</b></span>
        <br />
        </div>
        </>
      }}

      </Paper>
    </Grid>
  );
};

export default AgentPaymentList;
