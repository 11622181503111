import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import WarehouseAssociateForm from "./WarehouseAssociateForm";
import { provisioningService } from "services/provisioningServices";
import format from "date-fns/format";
import { number, string } from "prop-types";
import SweetAlert from 'react-bootstrap-sweetalert';
import { useLocation } from 'react-router-dom';
import { warehouseService } from "services/warehouseServices"; 
import { errorContext } from "context/errorContext";
import { customeGroupService } from "services/customerGroupServices";

export default function EditWarehouse() {

    const { id,associationId,name } = useParams();

    const location = useLocation();
  //  const editWarehouseData = location.state.from;
    const { dispatch: errorDispatch } = useContext(errorContext);
    const [warehouseData,setWarehouseData] = React.useState([]);
    const [customerGroupData, setCustomerGroupData] = React.useState([]); 
    const [categoryData, setCategoryData] = React.useState([]);
    const [organizationData, setOrganizationData] = React.useState([]);
    const [pageInfo, setPageInfo] = React.useState(null);
    const [status, setStatus] = useState("loading"); 
    const [stateData,setStateData] = React.useState([]);
    const [mqpData,setMqpData] = React.useState([]);
    const [edit,setEdit] = React.useState(true);
    const [warehouseEditData,setWarehouseEditData] = React.useState([]);   

    const [mandatoryMQPErrorMessage,setMandatoryMQPErrorMessage] = useState("");
    const [pincodeSelectionError,setPincodeSelectionError] = useState("");
    const [warehouseAssociateStatus, setWarehouseAssociateStatus] = useState("loading");
    const [warehouseStatusMessage,setWarehouseStatusMessage] =  React.useState("");
    const [warehouseFailedMessage,setWarehouseFailedMessage] = React.useState("");
    const [mepData,setMepData] = React.useState([]);
    const [productData,setProductData] = React.useState([]);
    const [categoryProductData,setCategoryProductData] = React.useState("");
    const [selectedCategoryAndProducts,setSelectedCategoryAndProducts] = React.useState([]);

    function getVlccData(societyUniqueId){
      warehouseService.getVlccByCC(societyUniqueId).then(data =>{
        setMepData(data);
        setMepData([...data, {vlccId:1, vlccName:"Select"}]);
        setStatus("success");
      }).catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });  
    }

    function getCCData(value){
      provisioningService.getCCByOrg(value.orgId).then(data => {
        console.log("data",data);
        setMqpData(data);
        setMqpData([...data, {chillingCenterId:1, chillingCenterName:"Select"}]);       
        getVlccData(value.ccId);
      }).catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      }); 
    }

    function getAllProductData(value){
      warehouseService.getProductDataByCategory(value.categories.join()).then(data =>{
        setProductData(data.content);
      }).catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      }); 
    }

    function invokeInitialData (value){
      warehouseService.getWarehouseListData().then(data => {
        setWarehouseData(data);
      }).catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
      
      customeGroupService.getAllCustomerGroup().then(data => {
        setCustomerGroupData(data.filter(data =>{
           return data.sourceType === "smartfarms_app";
        }));
      }).catch(error => {
         errorDispatch({
           type: "ERROR",
           error
         });
      });
       
      let pageSize = 0;
      let pageIndex = 3000;
      warehouseService.getAllCategoryData(pageSize,pageIndex).then(data => {
        setCategoryData(data.content);
        setPageInfo(data.totalPages);
        getAllProductData(value);
      }).catch(error => {
        console.error(error); 
        errorDispatch({
          type: "ERROR",
          error
        });
      });
        
      provisioningService.getAllOrgs().then(data => {
        setOrganizationData(data);
        setOrganizationData([{orgId:1,orgName:"Select"},...data]);
        getCCData(value);       
      }).catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });

      warehouseService.getCategoryProductData().then(data => {
        setCategoryProductData(data);
      }).catch(error => {
          errorDispatch({
            type: "ERROR",
            error
          });
      });
    }

    function getWarehouseData (){
      warehouseService.getEditDetails(associationId).then((data) =>{
        console.log(data);
        setWarehouseEditData(data);
        setSelectedCategoryAndProducts(data.categoryList);
        invokeInitialData(data);
      }).catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });   
    }

    const values = {
      id:  warehouseEditData ? warehouseEditData.id:"",
      ccId: warehouseEditData ? warehouseEditData.ccId:"",
      orgId:warehouseEditData ? warehouseEditData.orgId:"",
      warehouseId:warehouseEditData ? warehouseEditData.warehouseId:"",   
      isMQP:warehouseEditData ? warehouseEditData.isMQP:"",
      isMEP:warehouseEditData ? warehouseEditData.isMEP:"",
      customerGroup:warehouseEditData ? warehouseEditData.customerGroup:"",
      categories:warehouseEditData ? warehouseEditData.categoryList:"",      
      pincodeBased:warehouseEditData ? warehouseEditData.pincodeBased:"",
      societyUniqueId: warehouseEditData ? warehouseEditData.societyUniqueId:"",
      productId: warehouseEditData ? warehouseEditData.productId:""


    };

    
    let pincode = [];
    if(warehouseEditData.pincode){
      console.log(warehouseEditData.pincode);     
      for(var i =0;i<warehouseEditData.pincode.length;i++){
        let tmpObj = {};
        tmpObj.pincode = warehouseEditData.pincode[i];
        pincode.push(tmpObj);
      }
    }
    warehouseEditData.pincodeBased = pincode;

    useEffect(() => {
      provisioningService.verifyOtpNew('1313131313').then((res) => {       
        const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
        const organizationId = JSON.parse(localStorage.getItem("orgId"));
        getWarehouseData();     
        
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });   
    }, []);


    function handleSubmit(values, resetForm, setSubmitting){
    
      values.wharehouseId = parseInt(id);    
      setMandatoryMQPErrorMessage("");
      setPincodeSelectionError("");    

      values.categories = selectedCategoryAndProducts;
  
      if(values.wharehouseId === "" || values.customerGroup.length === 0  || selectedCategoryAndProducts.length === 0){
        setMandatoryMQPErrorMessage("Please Select Customer Group, Categories and Products.");
        setSubmitting(false);
        return;
      }
      
      if(values.isMQP === true && values.isMEP === false){
        if(values.orgId === 1 || values.ccId === "" || values.ccId === 1){
          setMandatoryMQPErrorMessage("Please Select Organization and MQP");
          setSubmitting(false);
          return;          
        }else if(values.orgId === "" || values.ccId === 1 || values.ccId === ""){
          setMandatoryMQPErrorMessage("Please Select Organization and MQP");
          setSubmitting(false);
          return;
        }
      }else if(values.isMEP === true && values.isMQP === false){
        if(values.orgId === "" || values.ccId === 1 || values.ccId === "" || values.societyUniqueId === ""){
          setMandatoryMQPErrorMessage("Please Select Organization, MQP and MEP");
          setSubmitting(false);
          return;
        }
  
        if(values.orgId.length > 0 || values.ccId === 1 || values.ccId.length > 0 || values.societyUniqueId.length === 0){
          setMandatoryMQPErrorMessage("Please Select MEP");
          setSubmitting(false);
          return;
        }
      }else if(values.isMQP === true && values.isMEP === true){
        setMandatoryMQPErrorMessage("Please Select Either MQP or MEP");
        setSubmitting(false);
        return;
      }else {
        if(values.pincodeBased.length === 0){
          setPincodeSelectionError("Please select the Pincode");
          setSubmitting(false);
          return;
        }
      }

      if(values.orgId === 1){
        values.orgId = "";
        values.ccId = "";
      }else{    
        if(values.orgId != "" && values.ccId === ""){
          setMandatoryMQPErrorMessage("Please Select MQP");
          setSubmitting(false);
          return;
        }
      }
  
      values.orgId = parseInt(values.orgId);
      values.ccId = parseInt(values.ccId);
  
      values.pincode = [];
  
      if(values.pincodeBased.length > 0){
        for(var i=0;i<values.pincodeBased.length;i++){
          values.pincode.push(parseInt(values.pincodeBased[i].pincode));
        }
      }
  
      delete values["pincodeBased"];
  
      warehouseService.EditWarehouseAssociate(values,associationId).then(data => {
        console.log(data);
        setWarehouseStatusMessage(data.message);
        setWarehouseAssociateStatus("success");
        setSubmitting(false);
        resetForm();   
      }).catch(error => {
        setWarehouseFailedMessage("failed");
        errorDispatch({
          type: "ERROR",
          error
        });
      });
    }

    const getSelectedCategoryAndProducts = (data) => {
      setSelectedCategoryAndProducts(data);
    };

    return ( 
        <WarehouseAssociateForm
          values = {values}
          organizationData = {organizationData}
          ccData = {mqpData}
          vlccData = {mepData}
          stateData = {stateData}
          customerGroupData={customerGroupData}
          categoryData={categoryData}
          status = {status}
          edit = {edit}
          warehouseName={name}
          handleSubmit = {handleSubmit}
          mandatoryMQPErrorMessage = {mandatoryMQPErrorMessage}
          pincodeSelectionError = {pincodeSelectionError}
          warehouseAssociateStatus = {warehouseAssociateStatus}
          warehouseStatusMessage = {warehouseStatusMessage}
          warehouseFailedMessage = {warehouseFailedMessage}
          prodData = {productData}
          categoryProductData={categoryProductData}
          getSelectedCategoryAndProducts = {getSelectedCategoryAndProducts}
        /> 
      );
}
