import React from "react";
import SuccessIcon from "../../../assets/success.png";
import { Link } from "react-router-dom";
import {
  CustomizedPrimaryButton,
  WhiteButton
} from "../elements/CustomizedPrimaryButton";

import { Dialog, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Grid } from "@material-ui/core";

const styles = {
  paper: {
    border: 0,
    position: "relative",
    height: "30rem",
    width: "25rem",
    fontSize: "1.2rem",
    borderRadius: "5px",
    padding: "2rem",
    margin: 8
  }
};

const DialogBox = ({ classes, ...others }) => (
  <Dialog classes={classes} {...others} />
);

const FeedbackBox = withStyles(styles)(DialogBox);

export default function SuccessFeedback({
  open,
  onClose,
  createAnotherMesssage,
  createAnotherLink,
  handleCreateAnother,
  successMessage,
  showButtons = true,
  message = "",
  subMessage = ""
}) {
  return (
    <FeedbackBox
      open={open}
      onClose={onClose}
      aria-labelledby="form-submit-feedback"
      status="success"
      className="success-msg"
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          padding: "0.5rem",
          zIndex: "1",
          color: "#fff"
        }}
      >
        <HighlightOffIcon />
      </IconButton>

      <div className="d-flex flex-column align-items-center justify-center h-100 position-relative">
        <div className="d-flex flex-column align-items-center justify-center w-100">
          <p className="w-100 text-center">
            <img
              src={SuccessIcon}
              alt="success icon"
              style={{
                maxWidth: "7rem",
                margin: "0 auto",
                display: "block"
              }}
            />
            {successMessage}
          </p>
        </div>

        {showButtons && (
          <div style={{ width: "100%" }}>
            <div style={{ margin: "1em 1em" }}>
              <CustomizedPrimaryButton
                variant="contained"
                color="primary"
                size="large"
                onClick={onClose}
                label="OK"
                width={10}
                position="center"
              ></CustomizedPrimaryButton>
            </div>
            <div style={{ margin: "1em 1em" }}>
              <Link to="/dashboard">
                <CustomizedPrimaryButton
                  variant="contained"
                  className="btn-cstm-ltblue"
                  color="primary"
                  size="large"
                  label="Go To Dashboard"
                  width={10}
                  position="center"
                ></CustomizedPrimaryButton>
              </Link>
            </div>
            {createAnotherLink && (
              <div style={{ margin: "1em 1em" }}>
                <Link to={createAnotherLink}>
                  <WhiteButton
                    variant="outlined"
                    size="large"
                    onClick={onClose}
                    width={10}
                    position="center"
                  >
                    {createAnotherMesssage}
                  </WhiteButton>
                </Link>
              </div>
            )}
          </div>
        )}
        {message !== "" && (
          <div className="text-center mt-4">
            <strong>{message}</strong>
            {subMessage !== "" && (
              <>
                <br />
                <Typography variant="caption" color="inherit">
                  {subMessage}
                </Typography>
              </>
            )}
          </div>
        )}
      </div>
    </FeedbackBox>
  );
}
