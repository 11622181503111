import React, { useState ,useContext, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { FieldArray,Field , getIn} from "formik";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";
import { errorContext } from "context/errorContext";
import * as Yup from "yup";
import CommonForm from "components/common/form/common-form";
import CustomCalenderPopUp from "components/common/form/CustomCalenderPopUp";
import {Radio,RadioGroup} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomDatePicker from "../elements/DatePicker";
import { Schedule } from "@material-ui/icons";
import CustomTimePicker from "../elements/TimePicker";

export default function NotificationScheduler({
    name,
    metaProps = { margin: "dense", variant: "outlined", fullWidth: true },
    setFieldValue,
    values
  }) {

    
    let schedulerSelectedData = {
      scheduleDate:new Date()
    };

    const formikForm = React.useRef(null);
    const { t } = useTranslation();

    const deliveryTypeData = [
      { value:"NO_REPEAT", label:"Once"},
      { value:"CUSTOM",label:"Periodic"}];

    const optionForOnce = [
      { value:"Manual Push", label:"Manual Push"},
      { value:"Schedule",label:"Schedule"}];

    const minDate =  new Date();

    const occurrences = ["Daily","Every weekday(Monday to Friday)","Custom","HOUR","MIN"];
    const [displayOnceOptions, setDisplayOnceOptions] = useState(false);
    const [displayPeriodicOptions,setDisplayPeriodicOptions] = useState(false);
    const [displayScheduleOptions,setDisplayScheduleOptions] = useState(false);
    const [customOccurance,setCustomOccurance] = useState(false);
    const [displayCustomPopUp,setDisplayCustomPopUp] = useState(false);
   
   
    let validations = {
    };

    useEffect(() => {
      console.log("Values in scheduler",values);
     
    }, [values]);


    const changeDeliveryType = (e) => {
      console.log("on change");
      console.log(e.target.value);
      console.log("values",values);
      values.type = "";
      if(e.target.value === "NO_REPEAT"){
        values.type = deliveryTypeData[0].value;
        setDisplayOnceOptions(true);
        setDisplayPeriodicOptions(false);
      }else if(e.target.value === "CUSTOM"){
        values.type = deliveryTypeData[1].value;
        setDisplayPeriodicOptions(true);
        setDisplayOnceOptions(false);
        setDisplayScheduleOptions(false);
      }
      setFieldValue(values);
      console.log("values 2nd time",values);
    }

    function ChangeOccurances(value){
      if(value === "Custom"){
        setCustomOccurance(true);
      }
      console.log(value);
    }

    const changeDeliveryOption = (e) =>{
      if(e.target.value === "Manual Push"){
        setDisplayScheduleOptions(false);
        values.deliveryOption = "Manual";
      }else if(e.target.value === "Schedule"){
        setDisplayScheduleOptions(true);
        values.deliveryOption = "Schedule";
      }
      setFieldValue(values);
    }

  
    function handleSubmit(values, resetForm, setSubmitting){   

    }

    function handleDateChange(e){
     // schedulerSelectedData.scheduleDate = 
      console.log(e);
    }

    function handleTimeChange(e){
      console.log("Time",e);
    }

    async function handleOccurencesSelect(data) {
      values.params.repeatOn.MON = false;
      values.params.repeatOn.TUE = false;
      values.params.repeatOn.WED = false;
      values.params.repeatOn.THU = false;
      values.params.repeatOn.FRI = false;
      values.params.repeatOn.SAT = false;
      values.params.repeatOn.SUN = false;  
      if(data === "Every weekday(Monday to Friday)"){
        console.log(data);
        values.params.repeatOn.MON = true;
        values.params.repeatOn.TUE = true;
        values.params.repeatOn.WED = true;
        values.params.repeatOn.THU = true;
        values.params.repeatOn.FRI = true;
        values.params.repeatsEveryUnit = "WEEK";
      }else if(data === "Daily"){
        values.params.repeatOn.MON = true;
        values.params.repeatOn.TUE = true;
        values.params.repeatOn.WED = true;
        values.params.repeatOn.THU = true;
        values.params.repeatOn.FRI = true;
        values.params.repeatOn.SAT = true;
        values.params.repeatOn.SUN = true; 
        
        values.params.repeatsEveryUnit = "DAY";

      }else if(data === "HOUR"){
        values.params.repeatsEveryUnit = "HOUR";
      }else if(data === "MIN"){
        values.params.repeatsEveryUnit = "MIN";
      }
      setDisplayCustomPopUp(false);      
      if(data === "Custom"){
        setDisplayCustomPopUp(true);
      }
    }

    function setFieldTouched(){
      console.log("setFieldTouched");
    }
  
    return (
      <>
      <Grid item container spacing={3}>
        <Grid item xs={12} md={6}>
          <header style={{ color:"black"}}>Choose the Delivery Type</header>
          <RadioGroup onChange={changeDeliveryType} row>
            {deliveryTypeData.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label} />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>
      
      {displayOnceOptions ? (
        <Grid item container spacing={3}>
          <Grid item xs={12} md={6}>
            <header style={{ color:"black"}}>Choose Occurences</header>
            <RadioGroup onChange={changeDeliveryOption} row>
              {optionForOnce.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label} />
              ))}
            </RadioGroup>
          </Grid>
        </Grid>
      ):null}

      {displayScheduleOptions ? (
        <Grid item container spacing={3}>
          <Grid item xs={12} md={4}>
            <CustomDatePicker
              formikRef={formikForm}
              name={"startTime"}
              label={"Schedule Date"}
              value={getIn(values, "startTime")}          
              handleDateChange={date => setFieldValue("scheduler.startTime", date)}
              setFieldTouched={setFieldTouched}
              // maxDate={maxDate}
              minDate={minDate}
             // error={getIn(errors, "startTime")}
              // touched={touched}
              readOnly={true}
              editable={false}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomTimePicker
              formikRef={formikForm}
              name={"scheduleTime"}
              label={"Schedule Time"}
              value={getIn(values, "scheduleTime")}
              handleTimeChange={time => setFieldValue("scheduler.scheduleTime", time)}
              setFieldTouched={setFieldTouched}
              // error={getIn(errors, name)}
              // touched={touched}
            />
          </Grid>
        </Grid>        
      ):null}

      {displayPeriodicOptions ? (
        <>
        <Grid item container spacing={3}>
          <Grid item xs={12} md={4} >
            <header style={{ color:"black" , whiteSpace:"pre-wrap"}}>Choose Occurences</header>
          </Grid>
        </Grid>
        <Grid item container spacing={3}>
          <Grid item xs={12} md={3}>
            <CustomDatePicker
              formikRef={formikForm}
              name={"periodicDate"}
              label={"Start Date"}
              value={getIn(values, "periodicDate")}
              handleDateChange={date => setFieldValue("scheduler.periodicDate", date)}
              setFieldTouched={setFieldTouched}
              // maxDate={maxDate}
              minDate={minDate}
              // error={getIn(errors, name)}
              // touched={touched}
              readOnly={true}
              editable={false}
              // disabled={disabled}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <CustomTimePicker
              formikRef={formikForm}
              name={"periodicTime"}
              label={"Time"}
              value={getIn(values, "periodicTime")}
              handleTimeChange={time => setFieldValue("scheduler.periodicTime", time)}
              setFieldTouched={setFieldTouched}
              // error={getIn(errors, name)}
              // touched={touched}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Field
              name={"occurences"}
              label={"Occurences"}
              component={TextField}
              select
              value={getIn(values, "occurences")}
              // error={countryError}
              inputProps={{
                onChange: async e => {
                  let { value } = e.target;
                  //handleChange("occurences", value);
                  await handleOccurencesSelect(value);
                }
               // value: getIn(values, countryField)
              }}
              {...metaProps}
            >
              <MenuItem disabled value="none">
                {"Occurences"}
              </MenuItem>
              {occurrences.map(occurrences => (
                <MenuItem key={occurrences} value={occurrences}>
                  {occurrences}
                </MenuItem>
              ))}
            </Field>
          </Grid>
        </Grid>
        </>
      ):null}

      {displayCustomPopUp ? (
        <CustomCalenderPopUp
          name="CustomCalender"
          setFieldValue={setFieldValue}
          values={values}
          isOpen={displayCustomPopUp}
          handleChange={setFieldValue}
        />
      ):null}

      </>
    );

}
