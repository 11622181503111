import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { getIn } from "formik";
import PageHeader from "components/layout/PageHeader";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
import CommonForm from "components/common/form/common-form";
import FailureFeedback from "components/common/elements/FailureFeedback";


import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import "components/responsive.scss";
import "../configuration.scss";
// import "./Notification.scss";
import "../../../components/responsive.scss";
import CountryStateDistrictSelector from "components/common/form/CountryStateDistrictSelector";

import { useTranslation } from "react-i18next";
import { string } from "prop-types";
import addDays from "date-fns/addDays";
//import OrganizationSelectionAgent from "components/common/form/OrganizationSelectionAgent";
import OrganizationSelectionAgentCriteria from "components/common/form/OrganizationSelectionAgentCriteria";

export default function AgentCriteriaForm({
  values,
  handleSubmit,
  editStatus,
  createError,
  edit,
  pbpData,
  pbpStatus,
  servicesOffering,
  agentName,
  orgData
}) {
  
  // console.log("Form Values:====", values);
  // console.log("Form agentName:====", agentName);
  const title = agentName+" - Edit Agent Criteria";
  const { t } = useTranslation();

  // console.log(pbpData, servicesOffering);
  // console.log("Values:========= ", values);
  //const [pbpData,setPbpData] = React.useState([]);
  const [isDisplaySubmitButton,setIsDisplaySubmitButton] = React.useState(false);

  useEffect(() => {
    // for(var i=0;i<pbpData.length;i++){
    //   for(var k=0;k<values.pbp.length;k++){
    //     if(pbpData[i].name === values.pbp[k].pbpName){
    //       const pbp = pbpData[i];
    //       console.log("Selected PBP:========= ", pbp); 
    //     }
    //   }
    // }
    // const pbp = pbpData.find(pbpData =>pbpData.name === values.pbp[0].name);  
    // console.log("Selected PBP:========= ", pbp); 
    // // if(pbp != undefined){
    // //   setPbpData(pbp)
    // // }
  }, [pbpData]);

  const formikForm = React.useRef(null);

  const fields = [
    {
      type: "multiObjectSelectAll",
      name: "pbp",
      mapper: "name",
      label: t("agent_Association_Form_PBP_Label"),
      placeholder: t("agent_Association_Form_PBP_Label"),
      option:pbpData.length > 0
        ? pbpData.map(pbpData => ({
            value: pbpData.name,
            label: pbpData.name,
            data: pbpData
          }))
        : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "multi-select"
      },
      //customOnChange:loadFetchTagData
      mandatory: "mandatory"
    },
    {
      type: "multiSelectAll",
      name: "servicesOffering",
      label: t("agent_Association_Form__ServicesOffering_Label"),
      placeholder: t("agent_Association_Form__ServicesOffering_Label"),
      option:servicesOffering.length > 0
        ? servicesOffering.map(servicesOffering => ({
            value: servicesOffering,
            label: servicesOffering
          }))
        : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "multi-select"
      },
      mandatory: "mandatory"
    },
    {
      type: "textarea",
      name: "criteriaTagNames",
      label: t("Criteria_Form_Criteria_Tags_Label"),
      placeholder: t("Criteria_Form_Criteria_Tags_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "custom",
      component: ({ setFieldValue, values }, renderFields) => (
        <>
          <span>
            <h3 className="mandatoryField">{t("agent_Association_Form_Organization_Selection_Label")}</h3>
          </span>
          <OrganizationSelectionAgentCriteria
            name={"organization"}
            organizationData={orgData}
            setFieldValue={setFieldValue}
            values={values["organizationBased"]}
            data={values}
          />
        </>
      ),
      grid: {
        xs: 12,
        className: "title-on-border-box bg-blue-l"
      }
    }  
    // {
    //   type: "customHeader",
    //   name: "Organization"
    // },
    // {
    //   type: "text",
    //   name: "organizationName",
    //   label: t("agent_Criteria_Form_Organization_Name_Label"),
    //   placeholder: t("agent_Criteria_Form_Organization_Name_Label"),
    //   disabled:true,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },
    // {
    //   type: "text",
    //   name: "chillingCenterName",
    //   label: t("agent_Criteria_Form_Chilling_Center_Name_Label"),
    //   placeholder: t("agent_Criteria_Form_Chilling_Center_Name_Label"),
    //   disabled:true,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },
    // {
    //   type: "text",
    //   name: "vlccName",
    //   label: t("agent_Criteria_Form_Vlcc_Name_Label"),
    //   placeholder: t("agent_Criteria_Form_Vlcc_Name_Label"),
    //   disabled:true,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // }
  ];

  let validations = {
    
  };

  const dispatch = useDispatch();
  const history = useHistory();

  function invokeOpenPopUp(){
    setIsDisplaySubmitButton(true);
    
  }
  
  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={title}
          //title={t("agent_Criteria_Form_Label")}
          //breadcrumbs={breadcrumbs}
        />
      </Grid>
      <Grid container>
        <Paper elevation={2} style={{ padding: "2em" }}>
          <Grid item>
            <CommonForm
              fields={fields}
              submitLabel={t("site_Form_Submit_Label")}
              submittingLabel={t("site_Form_Submitting_Label")}
              initialValues={values}
              validationSchema={Yup.object().shape({ ...validations })}
              validateOnBlur={true}
              edit={edit}
              enableReinitialize
              validateOnChange={true}
              onSubmit={(
                values,
                { setSubmitting, setFieldError, resetForm, errors }
              ) => {
                if (!!errors) {
                  setSubmitting(false);
                }
                handleSubmit(values, resetForm, setSubmitting);
              }}
              formikRef={formikForm}
              buttonSize="3"
              buttonPosition="right"
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
