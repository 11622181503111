import React from "react";
import Chip from "@material-ui/core/Chip";
import "./form.scss";

export default function OrganizationChipRetailerAgent({
  values,
  setFieldValue
}) {

  function getVlccName(data){
    if(data.organizationName && data.ccName && data.vlccName){
      return "Organization:" +data.organizationName.orgName +", Chilling Center: " +data.ccName.chillingCenterName +", Vlcc: " +data.vlccName.vlccName;
    }else if(data.organizationName && data.ccName){
      return "Organization:" +data.organizationName.orgName +", Chilling Center: " +data.ccName.chillingCenterName;
    }else{
      return "Organization:" +data.organizationName.orgName;
    }
  }
  
  function deleteOrganizationData(data) {
    let orgData = [...values];
    
    let indexValue = 0;
    for(var i=0;i<values.length;i++){
      if(JSON.stringify(values[i]) === JSON.stringify(data)){
        indexValue = i;
        console.log("indexValue",indexValue);
        values.splice(indexValue,1);
        break;
      }
    }

    setFieldValue(values);
  }

  let orgData = [];
  try{
    orgData = [...values];
  }catch{
    console.log("error");
  }

  if (orgData !== []) {
    return (
      <>   
      { orgData.map((data,index) => (
        <Chip
            style={{ marginLeft: "10px",
           marginTop:"5px", paddingTop: "5px" }}
            key={data.orgId}
            //label={"Organization:" +data.organizationName.orgName +", Chilling Center: " +data.ccName.chillingCenterName +", Vlcc: " +data.vlccName.map((obj) => obj.vlccName).join(',')}
            label={getVlccName(data)}
            className="gateway-label"
            onDelete={() => {
                deleteOrganizationData(data);
            } } />     
        ))}
      </>
    );
  }

  return null;
}
