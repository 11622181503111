import React, { useState } from "react";

import Tree from "react-d3-tree";
import { provisioningService } from "../../../services/provisioningServices";

import IconButton from "@material-ui/core/IconButton";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Checkbox from "@material-ui/core/Checkbox";
import Backdrop from "@material-ui/core/Backdrop";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import DateFormatter from "../../../components/common/elements/DateFormatter";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  minSliderColor: {
    color: "#DC3912",
    [theme.breakpoints.down("md")]: {
      width: "50%"
    }
  },
  maxSliderColor: {
    color: "#88CC55",
    [theme.breakpoints.down("md")]: {
      width: "50%"
    }
  },
  backdrop: {
    zIndex: 1302,
    color: "#fff"
  }
}));
const RatingSlider = withStyles({
  // root: props =>
  //   props.color === "red"
  //     ? {
  //         color: "red",
  //         height: 4
  //       }
  //     : {
  //         color: "#52af77",
  //         height: 4
  //       },
  //DynamicSheet update not working now
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: "currentColor",
    border: "2px solid white",
    marginLeft: -12,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)"
  },
  track: {
    height: 4,
    borderRadius: 4
  },
  rail: {
    height: 4,
    borderRadius: 4,
    background: "white",
    opacity: "1"
  }
})(Slider);

export default function BmcRating({ bmcId,
  zoom = 0.5,
  startDate,
  endDate
}) {
  const classes = useStyles();
  const [ratingData, setRatingData] = useState(null);
  const [ratingTree, setRatingTree] = useState({});
  const [graphLoading, setGraphLoading] = useState(false);
  const [zoomValue, setZoom] = useState(1);
  const [ratingOn, setRatingOn] = useState(false);
  const [openBottomMenu, setOpenBottomMenu] = useState(true);
  const [minRating, setMinRating] = useState(2);
  const [maxRating, setMaxRating] = useState(4);
  const [reset, setReset] = useState(Date.now());
  const [fullScreen, setFullScreen] = useState(false);
  const [rating, setRating] = useState(false);
  const [status, setStatus] = useState("loading");
  const { t } = useTranslation();

  function onMinSliderChange(e, value) {
    setMinRating(value);
    if (value >= maxRating) {
      setMaxRating(value);
    }
  }

  function onMaxSliderChange(e, value) {
    setMaxRating(value);
    if (value < minRating) {
      setMinRating(value);
    }
  }

  // on change of zoom
  React.useEffect(() => {
    setZoom(zoom);
  }, [zoom]);

  React.useEffect(() => {
    startDate = startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate();
    endDate = endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate();
    provisioningService.getBmcRating(bmcId, startDate, endDate).then(data => {
      setStatus("loading")
      setRating(false);
      setRatingData(data);
      // console.log("this data", data)
      // console.log("attein", data.attributes)
      if (data.attributes != undefined)
        setRating(true);
      setStatus("success")
    });
  }, [bmcId]);

  // update tree on change in rating inputs
  React.useEffect(() => {
    // console.log("rating", rating)
    if (!!ratingData && rating) {
      handleRatingData(ratingData);
    }
  }, [ratingData, ratingOn, minRating, maxRating, rating]);

  React.useEffect(() => {
    if (fullScreen) {
      setRatingOn(true);
    }
  }, [fullScreen]);

  async function handleRatingData(data) {
    setGraphLoading(true);
    // let depth = 0;
    let radius = 40;
    let ratingData = {};
    ratingData.name = data.attributeName;
    ratingData.attributes = {};

    if (ratingOn) {
      ratingData.attributes = {
        ...ratingData.attributes,
        Rating: data.rating
      };
    }
    let fillColor = "#f00";
    let strokeColor = "#f00";
    if (data.rating >= minRating && data.rating <= maxRating) {
      strokeColor = "white";
      fillColor = "#FF8C00";
    }
    if (data.rating < minRating) {
      strokeColor = "white";
      fillColor = "#DC3912";
    }
    if (data.rating > maxRating) {
      strokeColor = "white";
      fillColor = "#88CC55";
    }
    ratingData.nodeSvgShape = {
      shape: "circle",
      shapeProps: {
        //r: data.width + 10,
        r: data.attributes.length + 50,
        cx: -30,
        cy: 0,
        stroke: strokeColor,
        fill: fillColor
      }
    };
    if (data.attributes && data.attributes.length > 0) {
      ratingData.children = handleAttributes(data.attributes, radius);

      setRatingTree([ratingData]);
    }
    setGraphLoading(false);
  }

  function handleAttributes(attributes, radius) {
    let attr = attributes.map(attribute => {
      let data = {};
      data.name = attribute.attributeName;
      data.attributes = {};
      if (ratingOn) {
        data.attributes = {
          ...data.attributes,
          Rating: attribute.rating
        };
      }

      let fillColor = "#f00";
      let strokeColor = "#f00";
      if (attribute.rating >= minRating && attribute.rating <= maxRating) {
        strokeColor = "white";
        fillColor = "#FF8C00";
      }
      if (attribute.rating < minRating) {
        strokeColor = "white";
        fillColor = "#DC3912";
      }
      if (attribute.rating > maxRating) {
        strokeColor = "white";
        fillColor = "#88CC55";
      }
      data.nodeSvgShape = {
        shape: "circle",
        shapeProps: {
          //r: attribute.width + 10,
          r: attribute.attributes ? attribute.attributes.length + 20 : 10,
          cx: 0,
          cy: 0,
          stroke: strokeColor,
          fill: fillColor
        }
      };
      // data._collapsed = true;
      if (attribute.attributes && attribute.attributes.length > 0) {
        data.children = handleAttributes(attribute.attributes, radius * 0.66);
      }
      return data;
    });
    return [...attr];
  }

  const styles = {
    links: { stroke: "#97a4ba" },
    nodes: {
      node: {
        circle: {
          strokeWidth: 3
        },
        name: {
          stroke: "#000",
          // strokeWidth: 0.5,
          fontSize: "24px"
        },
        attributes: {
          stroke: "#000",
          // strokeWidth: 0.5,
          fontSize: "24px"
        }
      },
      leafNode: {
        circle: {
          strokeWidth: 3
        },
        name: {
          stroke: "#000",
          // strokeWidth: 0.5,
          fontSize: "24px"
        },
        attributes: {
          stroke: "#000",
          // strokeWidth: 0.5,
          fontSize: "24px"
        }
      }
    }
  };

  function valuetext(value) {
    return `${value}`;
  }

  const treeContainer = React.useRef(null);
  const fullScreenContainer = React.useRef(null);

  const [translate, setTranslate] = React.useState({
    x: 0,
    y: 0
  });

  React.useEffect(() => {
    if (rating && fullScreen) {
      const dimensions = fullScreenContainer.current.getBoundingClientRect();
      setTranslate({
        x: 100,
        y: dimensions.height / 2
      });
    } else if (rating) {
      const dimensions = treeContainer.current.getBoundingClientRect();
      setTranslate({
        x: 100,
        y: dimensions.height / 2
      });
    }
  }, [ratingData, fullScreen, rating]);

  return (
    <>
      {rating && fullScreen ? (
        <Backdrop
          classes={{ root: classes.backdrop }}
          open={true}
          onClick={e => {
            if (e.target.nodeName === "DIV") {
              setFullScreen(false);
            }
          }}
        >
          <Grid
            container
            ref={fullScreenContainer}
            justify="flex-end"
            style={{
              height: "100vh",
              width: "100%",
              backgroundColor: "white",
              //border: "1px solid #F1EAF0",
              position: "relative",
              borderRadius: "4px",
              overflow: "hidden"
            }}
          >
            <IconButton
              style={{ top: "10px", position: "absolute" }}
              onClick={() => setFullScreen(false)}
            >
              <FullscreenExitIcon style={{ color: "black" }} />
            </IconButton>
            <Tree
              data={ratingTree}
              orientation="horizontal"
              styles={styles}
              transitionDuration={0}
              allowForeignObjects
              nodeLabelComponent={{
                render: <NodeLabel />,
                foreignObjectWrapper: {
                  width: 300,
                  height: 150,
                  x: 0,
                  y: -24
                }
              }}
              // initialDepth={2}
              separation={{ siblings: 2, nonSiblings: 2 }}
              depthFactor={400}
              translate={translate}
              zoom={zoomValue}
            />
          </Grid>
        </Backdrop>
      ) : rating ? (
        <Grid
          ref={treeContainer}
          container
          alignItems="flex-end"
          justify="flex-end"
          style={{
            height: "70vh",
            width: "100%",
            //backgroundColor: "#e9f3fe",
            border: "1px solid #F1EAF0",
            position: "relative",
            borderRadius: "4px",
            overflow: "hidden"
          }}
        >
          <Tree
            key={reset}
            data={ratingTree}
            orientation="horizontal"
            useCollapseData
            styles={styles}
            transitionDuration={0}
            allowForeignObjects
            nodeLabelComponent={{
              render: <NodeLabel />,
              foreignObjectWrapper: {
                width: 300,
                height: 150,
                x: 0,
                y: -24
              }
            }}
            // initialDepth={2}
            separation={{ siblings: 2, nonSiblings: 2 }}
            depthFactor={400}
            translate={translate}
            zoom={zoomValue}
          />
          {/*************************BOTTOM PART*************************************/}
          <Grid container justify="center" style={{ position: "absolute" }}>
            <Grid
              item
              style={{
                backgroundColor: "black",
                opacity: ".6",
                borderRadius: "4px 4px 0px 0px"
              }}
            >
              <IconButton
                onClick={() => setOpenBottomMenu(!openBottomMenu)}
                style={{ color: "white" }}
                size="small"
              >
                {openBottomMenu ? (
                  <KeyboardArrowDownIcon />
                ) : (
                    <KeyboardArrowUpIcon />
                  )}
              </IconButton>
            </Grid>

            <Grid
              elevation={2}
              style={{
                backgroundColor: "black",
                opacity: ".6",
                height: "2px",
                width: "100%"
              }}
            ></Grid>
            <Slide
              direction="up"
              in={openBottomMenu}
              unmountOnExit
              mountOnEnter
            >
              <Grid
                container
                justify="space-between"
                wrap="wrap-reverse"
                style={{
                  padding: "0em 0em .5em 0em",
                  borderRadius: "0px 0px 4px 4px",
                  backgroundColor: "#8c8f92",
                  color: "white",
                  opacity: ".9"
                }}
              >
                <Grid item container xs={12} md={9}>
                  <Grid
                    container
                    item
                    xs={12}
                    md={2}
                    alignItems="center"
                    style={{ padding: "0em 1em" }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={ratingOn}
                          color="primary"
                          onChange={() => setRatingOn(!ratingOn)}
                          value="info"
                          style={{ color: "white", padding: "4px 0" }}
                        />
                      }
                      label={t("bmc_Rating_Show_Rating")}
                      style={{ margin: "0" }}
                    />
                  </Grid>
                  <Grid item container xs={12} md={10} justify="space-between">
                    <Grid
                      item
                      container
                      xs={12}
                      md={4}
                      alignItems="center"
                      justify="space-around"
                      style={{ padding: " 0em 1em" }}
                    >
                      <RatingSlider
                        max={5}
                        value={minRating}
                        onChangeCommitted={onMinSliderChange}
                        step={0.5}
                        marks={[0, 2.5, 5]}
                        aria-labelledby="input-slider"
                        valueLabelDisplay="off"
                        classes={{
                          root: classes.minSliderColor
                        }}
                      />
                      <span>
                        <span
                          style={{
                            borderRadius: "4em",
                            display: "inline-block",
                            background: "#DC3912",
                            border: "2px solid white",
                            minWidth: "1.3em",
                            minHeight: "1.3em",
                            marginRight: "5px",
                            verticalAlign: "middle"
                          }}
                        ></span>
                        {`Rating < ${minRating} `}
                      </span>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      md={4}
                      alignItems="flex-end"
                      justify="center"
                      style={{ padding: ".5em" }}
                    >
                      <span>
                        <span
                          style={{
                            borderRadius: "4em",
                            display: "inline-block",
                            background: "#FF8C00",
                            border: "1px solid white",
                            minWidth: "1.3em",
                            minHeight: "1.3em",
                            marginRight: "5px",
                            verticalAlign: "middle"
                          }}
                        ></span>
                        {minRating === maxRating
                          ? `${t("bmc_Rating_Rating")} = ${minRating} `
                          : `${t("bmc_Rating_Rating")} >=${minRating} & <= ${maxRating} `}
                      </span>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      md={4}
                      alignItems="center"
                      justify="space-around"
                      style={{ padding: "0em 1em" }}
                    >
                      <RatingSlider
                        // min={minRating === maxRating ? 0 : minRating}
                        min={0}
                        max={5}
                        step={0.5}
                        value={maxRating}
                        onChangeCommitted={onMaxSliderChange}
                        aria-labelledby="input-slider"
                        valueLabelDisplay="off"
                        classes={{
                          root: classes.maxSliderColor
                        }}
                      />
                      <span>
                        <span
                          style={{
                            borderRadius: "4em",
                            display: "inline-block",
                            background: "#88CC55",
                            border: "2px solid white",
                            minWidth: "1.3em",
                            minHeight: "1.3em",
                            marginRight: "5px",
                            verticalAlign: "middle"
                          }}
                        ></span>
                        {`${t("bmc_Rating_Rating")} > ${maxRating}`}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container xs={12} md={3} justify="center">
                  <IconButton
                    onClick={() => {
                      if (zoomValue < 0.9) {
                        setZoom(zoomValue + 0.1);
                      }
                    }}
                    style={{
                      color: "white",
                      padding: "4px .5em"
                    }}
                  >
                    <ZoomInIcon style={{ fontSize: 30 }} />
                  </IconButton>

                  <IconButton
                    onClick={() => {
                      if (zoomValue > 0.1) {
                        setZoom(zoomValue - 0.1);
                      }
                    }}
                    style={{
                      color: "white",
                      padding: "4px .5em"
                    }}
                  >
                    <ZoomOutIcon style={{ fontSize: 30 }} />
                  </IconButton>

                  <IconButton
                    onClick={() => {
                      setZoom(0.5);
                      setReset(Date.now());
                    }}
                    style={{
                      color: "white",
                      padding: "4px .5em "
                    }}
                  >
                    <FullscreenExitIcon style={{ fontSize: 30 }} />
                  </IconButton>

                  <IconButton
                    onClick={() => setFullScreen(true)}
                    style={{
                      color: "white",
                      padding: "4px .5em "
                    }}
                  >
                    <FullscreenIcon style={{ fontSize: 30 }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Slide>
          </Grid>
          {/******************************************************************************************/}
        </Grid>
      ) : status === "success" ? (
        <Grid
          container
          xs={12}
          justify="center"
          alignItems="center"
          style={{ height: "5vh" }}
        >

          <div>{t("bmc_Rating_No_Data")}</div>
        </Grid>
      ) : (
              <Grid
                container
                xs={12}
                justify="center"
                alignItems="center"
                style={{ height: "50vh" }}
              >
                <CircularProgress />
              </Grid>
            )}
    </>
  );
}

const nodeStyles = makeStyles(theme => ({
  nodeName: {
    color: "#478fcc",
    fontWeight: "600",
    fontSize: "20px",
    "&:hover": {
      color: "black"
    }
  }
}));

const NodeLabel = ({ nodeData }) => {
  const classes = nodeStyles();
  return (
    <span style={{ display: "block", paddingLeft: "1.5em" }}>
      <span className={classes.nodeName}>{nodeData.name}</span>
      {!!nodeData.attributes &&
        Object.entries(nodeData.attributes).length > 0 &&
        nodeData.attributes.constructor === Object && (
          <span style={{ fontSize: "18px", color: "black", display: "block" }}>
            Rating :&nbsp;
            <span
              style={{
                fontSize: "24px",
                fontWeight: "600",
                color: "black",
                padding: "5px 10px",
                background: "white",
                width: "2.5em",
                display: "inline-block",
                borderRadius: "1.5em/2em",
                border: "1px solid #478fcc",
                textAlign: "center"
              }}
            >
              {nodeData.attributes.Rating}
            </span>
          </span>
        )}
    </span>
  );
};
