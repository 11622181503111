import React, { useState, useEffect, useContext } from "react";
import { Grid, Paper } from "@material-ui/core";
import PageHeader from "../../../components/layout/PageHeader";
import * as Yup from "yup";
import { analyticService } from "services/analyticsServices";
import { errorContext } from "context/errorContext";
import DetailedGraph from "../../Dashboard/components/DetailedGraph";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
function AnalyticsBmcGraph() {
  const { t } = useTranslation();
  const [fields, setFields] = useState(null);
  const { dispatch: errorDispatch } = useContext(errorContext);

  const handleSubmit = (values, setSubmitting) => {};
  const validations = {
    order: Yup.string().required("Please Select Order"),
    limit: Yup.string().required("Please Select Limit"),
    attribute: Yup.string().required("Please Select Attribute")
  };
  useEffect(() => {
    analyticService
      .fetchMetaData()
      .then(data => {
        const limit = data[0].value;
        const order = data[2].value;
        const attribute = data[1].value;
        setFields([
          {
            type: "select",
            name: "limit",
            label: "Limit",
            placeholder: "Limit",
            option:
              limit.length > 0
                ? limit.map(l => ({
                    value: l,
                    label: l
                  }))
                : [],
            grid: {
              xs: 12,
              sm: 2,
              md: 3
            }
          },
          {
            type: "select",
            name: "order",
            label: "Order",
            placeholder: "Order",
            option:
              order.length > 0
                ? order.map(d => ({
                    value: d,
                    label: d
                  }))
                : [],
            grid: {
              xs: 12,
              sm: 2,
              md: 3
            }
          },
          {
            type: "select",
            name: "attribute",
            label: "Attribute",
            placeholder: "Attribute",
            option:
              attribute.length > 0
                ? attribute.map(a => ({
                    value: a,
                    label: a
                  }))
                : [],
            grid: {
              xs: 12,
              sm: 2,
              md: 3
            }
          }
        ]);
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
  }, []);
  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={"Analytics Graph"}
          breadcrumbs={[
            {
              label: t("analytics_Bmc_Rating_Breadcrum_Dashboard_Label"),
              link: "/dashboard"
            },
            { label: t("analytics_Bmc_Rating_Breadcrum_Analytics_Label") },
            { label: "Analytics Graph" }
          ]}
        />
      </Grid>
      <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%"
        }}
      >
        <Grid item style={{ marginBottom: "0.5em" }}>
          {fields ? (
            <DetailedGraph
              fields={fields}
              validations={validations}
              cb={null}
              height={{ height: "500px" }}
            />
          ) : (
            <Grid
              container
              xs={12}
              justify="center"
              alignItems="center"
              style={{ height: "50vh" }}
            >
              <CircularProgress />
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
}

export default AnalyticsBmcGraph;
