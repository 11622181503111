function getToken() {
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const token = user && user.jwtAccessToken;
  return token;
}

async function getChillingData(bmcId, fromTime, toTime, fields) {
  let url;
  if (fields === "volume,timeData") {
    url = `/authgateway/notificationService/bmc/VOLUME/?bmcId=${bmcId}&startTime=${fromTime}&endTime=${toTime}&orderByDsc=processTime`;
  } else
    url = `/authgateway/notificationService/bmc/TEMPERATURE/?bmcId=${bmcId}&startTime=${fromTime}&endTime=${toTime}&orderByDsc=processTime`;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      Accept: "application/json",
      authToken : localStorage.getItem("idToken")

    }
  };
  try {
    const response = await fetch(url, requestOptions);
    console.warn("response: ", response);
    return response.json();
  } catch (error) {
    console.error("getting graph data failed :", error);
    throw error;
  }
}

async function getAlertsData(bmcId, fromTime, toTime) {
  let url = `/authgateway/notificationService/bmc/ALERTS/?orderByDsc=occurredOn&bmcId=${bmcId}&startTime=${fromTime}&endTime=${toTime}`;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      Accept: "application/json",
      authToken : localStorage.getItem("idToken")

    }
  };

  try {
    const response = await fetch(url, requestOptions);
    console.warn("response: ", response);
    return response.json();
  } catch (error) {
    console.error("getting graph data failed :", error);
    throw error;
  }
}

async function getChillingEventsData(bmcId, fromTime, toTime, eventNames) {
  let url;
  if (eventNames === "VOLUME") {
    url = `/authgateway/notificationService/bmc/VOLUME/?bmcId=${bmcId}&startTime=${fromTime}&endTime=${toTime}&orderByDsc=processTime`;
  } else if (eventNames === "TEMP") {
    url = `/authgateway/notificationService/bmc/TEMPERATURE/?bmcId=${bmcId}&startTime=${fromTime}&endTime=${toTime}&orderByDsc=processTime`;
  } else if (eventNames === "ALL") {
    url = `/authgateway/notificationService/bmc/EVENT/?bmcId=${bmcId}&startTime=${fromTime}&endTime=${toTime}&orderByDSC=processTime`;
  } else {
    url = `/authgateway/notificationService/bmc/EVENT/?bmcId=${bmcId}&startTime=${fromTime}&endTime=${toTime}&eventName=${eventNames}&orderByDsc=processTime`;
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      Accept: "application/json",
      authToken : localStorage.getItem("idToken")

    }
  };
  try {
    const response = await fetch(url, requestOptions);
    console.warn("response: ", response);
    return response.json();
  } catch (error) {
    console.error("getting graph data failed :", error);
    throw error;
  }
}

async function getDeviceEvents(bmcId) {
  let url = `/authgateway/notificationService/deviceEvent/bmc/?chillingUnitUUID=${bmcId}`;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      Accept: "application/json",
      authToken : localStorage.getItem("idToken")

    }
  };
  try {
    const response = await fetch(url, requestOptions);
    console.warn("response: ", response);
    return response.json();
  } catch (error) {
    console.error("getting graph data failed :", error);
    throw error;
  }
}

export const chillingGraphServices = {
  getChillingData,
  getChillingEventsData,
  getAlertsData,
  getDeviceEvents
};
