function getToken() {
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const token = user && user.jwtAccessToken;
  return token;
}

async function fetchGraphData(
  values = {
    order: "Top",
    limit: 10,
    attribute: "By Chilling Duration"
  }
) {
  let url =
    process.env.REACT_APP_ANALYTICS_GET_CHART_DATA +
    `?chartId=home_page_chart&order=${values.order}&limit=${values.limit}&attribute=${values.attribute}&ratingWise=false`;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Api-Key": process.env.REACT_APP_ANALYTICS_KEY,
      "Content-Type": "application/json",
      authToken : localStorage.getItem("idToken")

    }
  };

  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}
async function fetchMetaData() {
  let url = process.env.REACT_APP_ANALYTICS_GET_META_DATA;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Api-Key": process.env.REACT_APP_ANALYTICS_KEY,
      "Content-Type": "application/json",
      authToken : localStorage.getItem("idToken")

    }
  };

  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

export const analyticService = {
  fetchGraphData,
  fetchMetaData
};
