import React, { createContext, useReducer } from "react";
import format from "date-fns/format";

const initialState = {
  uuid: null,
  alertsData: null,
  alertStatus: "loading",
  alertError: null,
  bmcData: null,
  currentDate: new Date().toISOString(),
  hours: 24,
  bmcStatus: "loading",
  bmcError: null,
  // alerts search API state
  alertSearchStatus: "loading",
  siteUuids: [],
  chillingUnitUuids: []
};
const dashboardContext = createContext();
const { Provider } = dashboardContext;

const DashboardProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "CHILLING_UNIT_CHANGE":
        const newState = { ...state, uuid: action.uuid };
        return newState;
      case "DASHBOARD_HOURS_UPDATE":
        return {
          ...state,
          hours: action.hours
        };
      case "DASHBOARD_CURRENT_DATE_UPDATE":
        return {
          ...state,
          currentDate: action.currentDate
        };
      case "DASHBOARD_BMC_CARD_DATA_FETCH":
        return {
          ...state,
          bmcStatus: "loading"
        };
      case "DASHBOARD_BMC_CARD_DATA_FETCH_SUCCESS":
        return {
          ...state,
          bmcData: action.data,
          bmcStatus: "success"
        };
      case "DASHBOARD_BMC_CARD_DATA_FETCH_FAIL":
        return {
          ...state,
          bmcError: action.error,
          bmcStatus: "failed"
        };
      // ALERTS SEARCH
      case "SITE_ALERTS_SEARCH":
        return {
          ...state,
          siteUuids: action.siteUuids,
          chillingUnitUuids: action.chillingUnitUUID
        };
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { dashboardContext, DashboardProvider };
