import React, { useEffect, useContext,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { authContext } from "context/authContext";
import { notificationService } from "services/notificationServices";
import { errorContext } from "context/errorContext";
import addDays from "date-fns/addDays";
import { Grid, Paper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation } from 'react-router-dom'; 
import { isAfter } from "date-fns";
import { approveServices } from "services/approveServices";
import FailureFeedback from "components/common/elements/FailureFeedback";
import { useTranslation } from "react-i18next";
import AgentCriteriaForm from "./AgentCriteriaForm";
import { agentAssociationService } from "services/agentAssociationServices";
import { agentCriteriaService } from "../../../services/agentCriteriaServices.js";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
import { provisioningService } from "services/provisioningServices";
// import ConfirmPopUp from "components/common/elements/ConfirmPopUp";

export default function EditAgentCriteria() {

    const { dispatch: errorDispatch } = useContext(errorContext);

    const createError = useSelector(state => state.site.toJS().createError);

    const location = useLocation();
    //console.log("Location:========= ", location);
    const history = useHistory();
    const { t } = useTranslation();
    const { agentName } = useParams();
    const editAgentCriteriaData = location.state.from;
    //const agentName1 = location.state.from.agentName;
    const editStatus = "";
    const edit = "";
    const [pbpData, setPbpData] = React.useState([]);
    const [pbpStatus,setPbpStatus] = React.useState("loading");
    const [servicesOffering, setServicesOffering] = React.useState([]);
    const [status,setStatus] = React.useState(false);
    const [failedStatus,setFailedStatus] = React.useState(false);
    const [openConfirmDialog,setOpenConfirmDialog] = React.useState(false);
    const [orgData,setOrgData ] = React.useState([]);
    const [selectionError,setSelectionError] = useState("");

    useEffect(() => {
      getMasterData();
      provisioningService.getAllOrgs().then(data => {
        setOrgData(data);
      }).catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
    }, []);

    function getMasterData(){
      agentAssociationService.getAgentOriginAndServicesOffering().then(data => {
        // const filteredData = data.filter(data =>data.name === "moogrow"); 
        for(var i=0;i<data.pbp.length;i++){
          delete data.pbp[i]["gst"];
          delete data.pbp[i]["address"];
          delete data.pbp[i]["territories"];
        }
        //console.log("PBP re:------", data.pbp);
        //console.log("Agent Origin data:==========> ", data);
        setPbpData(data.pbp);
        setServicesOffering(data.servicesOffering);
   
       }).catch(error => {
         errorDispatch({
           type: "ERROR",
           error
         });
       });
    }

    const values = {
      criteriaId: !!editAgentCriteriaData ? editAgentCriteriaData.id : "",
      criteriaTags: !!editAgentCriteriaData ? editAgentCriteriaData.criteriaTags : "",
      criteriaTagNames: !!editAgentCriteriaData ? editAgentCriteriaData.criteriaTagNames : "",
      servicesOffering: !!editAgentCriteriaData ? editAgentCriteriaData.servicesOffering : "",
      pbp: !!editAgentCriteriaData ? editAgentCriteriaData.pbp : [{}],
      // organizationName: !!editAgentCriteriaData && !!editAgentCriteriaData.criteria && !!editAgentCriteriaData.criteria.organizationBased  ? editAgentCriteriaData.criteria.organizationBased[0].organizationName : "",
      // chillingCenterName: !!editAgentCriteriaData && !!editAgentCriteriaData.criteria && !!editAgentCriteriaData.criteria.organizationBased  ? editAgentCriteriaData.criteria.organizationBased[0].ccName : "",
      // vlccName: !!editAgentCriteriaData && !!editAgentCriteriaData.criteria && !!editAgentCriteriaData.criteria.organizationBased  ? editAgentCriteriaData.criteria.organizationBased[0].vlccName : ""
      criteria: !!editAgentCriteriaData && !!editAgentCriteriaData.criteria && !!editAgentCriteriaData.criteria  ? editAgentCriteriaData.criteria : {},
      organizationBased: !!editAgentCriteriaData && !!editAgentCriteriaData.criteria && !!editAgentCriteriaData.criteria.organizationBased  ? editAgentCriteriaData.criteria.organizationBased : []
    }

//console.log("editAgentCriteriaData", editAgentCriteriaData.pbp && editAgentCriteriaData.pbp);

    function handleSubmit(values, resetForm, setSubmitting) {
      //console.log("Values in HandleSubmit:----- ", values);
      setSelectionError("");
      if(values.criteria.organizationBased.length === 0){
        setSelectionError("Please select the Organization, Chilling Center and Vlcc by Clicking on Plus Symbol");
        setSubmitting(false);
        return;
      }
      // values.criteria = {};
      // values.criteria.organizationBased = values.organizationBased;
      //setOpenConfirmDialog(true);
      // setSubmitting(false);

      editAgentCriteriaData.pbp = values.pbp;
      editAgentCriteriaData.servicesOffering = values.servicesOffering;
      editAgentCriteriaData.criteriaTags = values.criteriaTags;
      editAgentCriteriaData.criteriaTagNames = values.criteriaTagNames;

      // for(var k=0;k<editAgentCriteriaData.criteria.organizationBased.length;k++){
      //   editAgentCriteriaData.criteria.organizationBased[k] = values.criteria.organizationBased[k];
      // }

      delete editAgentCriteriaData["agentOrigin"];

      for(var k=0;k<editAgentCriteriaData.pbp.length;k++){
        delete editAgentCriteriaData.pbp[k].address;
        delete editAgentCriteriaData.pbp[k].gst;
        delete editAgentCriteriaData.pbp[k].territories;
      }


    let orgArr = [];    
    
    for(var i=0;i<values.criteria.organizationBased.length;i++){

      if(values.criteria.organizationBased[i].orgId && 
        values.criteria.organizationBased[i].organizationName  &&
        !values.criteria.organizationBased[i].chillingCenterId){
        let orgObjOnlyOrg = {organizationName:"",orgId:null};
        orgObjOnlyOrg.organizationName = values.criteria.organizationBased[i].organizationName;
        orgObjOnlyOrg.orgId = values.criteria.organizationBased[i].orgId;
        orgArr.push(orgObjOnlyOrg);
      }

      if(values.criteria.organizationBased[i].orgId && 
        values.criteria.organizationBased[i].organizationName &&
        values.criteria.organizationBased[i].chillingCenterId &&
        values.criteria.organizationBased[i].ccName &&
        !values.criteria.organizationBased[i].vlccId){
        let orgObjOnlyOrgAndCc = {organizationName:"",orgId:null,ccName:"",chillingCenterId:null};
        orgObjOnlyOrgAndCc.organizationName = values.criteria.organizationBased[i].organizationName;
        orgObjOnlyOrgAndCc.orgId = values.criteria.organizationBased[i].orgId;
        orgObjOnlyOrgAndCc.ccName = values.criteria.organizationBased[i].ccName;
        orgObjOnlyOrgAndCc.chillingCenterId = values.criteria.organizationBased[i].chillingCenterId;
        orgArr.push(orgObjOnlyOrgAndCc);
      }

      if(values.criteria.organizationBased[i].orgId && 
        values.criteria.organizationBased[i].organizationName &&
        values.criteria.organizationBased[i].chillingCenterId &&
        values.criteria.organizationBased[i].ccName &&
        values.criteria.organizationBased[i].vlccId &&
        values.criteria.organizationBased[i].vlccName){
        let orgObjOnlyOrgAndCcAndVlcc = {organizationName:"",orgId:null,ccName:"",chillingCenterId:null,vlccName:"",vlccId:null,societyUnqId:null};
        orgObjOnlyOrgAndCcAndVlcc.organizationName = values.criteria.organizationBased[i].organizationName;
        orgObjOnlyOrgAndCcAndVlcc.orgId = values.criteria.organizationBased[i].orgId;
        orgObjOnlyOrgAndCcAndVlcc.ccName = values.criteria.organizationBased[i].ccName;
        orgObjOnlyOrgAndCcAndVlcc.chillingCenterId = values.criteria.organizationBased[i].chillingCenterId;
        orgObjOnlyOrgAndCcAndVlcc.vlccName = values.criteria.organizationBased[i].vlccName;
        orgObjOnlyOrgAndCcAndVlcc.vlccId = values.criteria.organizationBased[i].vlccId;
        orgObjOnlyOrgAndCcAndVlcc.societyUnqId = values.criteria.organizationBased[i].societyUnqId;
        orgArr.push(orgObjOnlyOrgAndCcAndVlcc);
      }

      if(values.criteria.organizationBased[i].organizationName && (values.criteria.organizationBased[i].ccName == '' || values.criteria.organizationBased[i].ccName == null) && values.criteria.organizationBased[i].vlccName == ''){
          let orgObjOrg = {organizationName:"",orgId:null};
            orgObjOrg.organizationName = values.criteria.organizationBased[i].organizationName.orgName;
            orgObjOrg.orgId = values.criteria.organizationBased[i].organizationName.orgId;
            orgArr.push(orgObjOrg);
      }

      if(values.criteria.organizationBased[i].organizationName && values.criteria.organizationBased[i].ccName && values.criteria.organizationBased[i].vlccName == ''){
        let orgObjOrgCc = {organizationName:"",orgId:null,ccName:"",chillingCenterId:null};
          orgObjOrgCc.organizationName = values.criteria.organizationBased[i].organizationName.orgName;
          orgObjOrgCc.orgId = values.criteria.organizationBased[i].organizationName.orgId;
          orgObjOrgCc.ccName = values.criteria.organizationBased[i].ccName.chillingCenterName;
          orgObjOrgCc.chillingCenterId = values.criteria.organizationBased[i].ccName.chillingCenterId;
          orgArr.push(orgObjOrgCc);
      }

      if(values.criteria.organizationBased[i].organizationName && values.criteria.organizationBased[i].ccName && values.criteria.organizationBased[i].vlccName && values.criteria.organizationBased[i].vlccName[0].vlccName){
        for(var j=0;j<values.criteria.organizationBased[i].vlccName.length;j++){
          let orgObj = {organizationName:"",orgId:null,ccName:"",chillingCenterId:null,vlccName:"",vlccId:null,societyUnqId:null};
            orgObj.organizationName = values.criteria.organizationBased[i].organizationName.orgName;
            orgObj.orgId = values.criteria.organizationBased[i].organizationName.orgId;
            orgObj.ccName = values.criteria.organizationBased[i].ccName.chillingCenterName;
            orgObj.chillingCenterId = values.criteria.organizationBased[i].ccName.chillingCenterId;
            orgObj.vlccName = values.criteria.organizationBased[i].vlccName[j].vlccName;
            orgObj.vlccId = values.criteria.organizationBased[i].vlccName[j].vlccId;
            orgObj.societyUnqId = values.criteria.organizationBased[i].vlccName[j].societyUnqId;
            orgArr.push(orgObj);
            //console.log("shouldn't hit")
        }
      }
    }

    values.criteria.organizationBased = orgArr;
      
      //console.log("Constr orgBased to send to banckend:----- ", values.criteria.organizationBased);
      //console.log("Edit Criteria Data to Backend:========== ", editAgentCriteriaData);
      // console.log("CriteriaId from values:====== ", values.criteriaId);

      agentCriteriaService.editAgentCriteria(values.criteriaId,editAgentCriteriaData).then(data => {
        //console.log(data);
        setStatus(true);
        setSubmitting(false);
      }).catch(error => {
        setStatus(false);
        setSubmitting(false);
        setFailedStatus(true);
      }); 

      // setOpenConfirmDialog(false);
    }

    const closeConfirmDialog = () => {
      setOpenConfirmDialog(false);
    }

    const handleDialogClose = () => {
      history.push("/configuration/agent-criteria");
    };

    return (
      <>
      <Grid container>
        <AgentCriteriaForm
          values={values}
          handleSubmit={handleSubmit}
          editStatus={editStatus}
          edit={edit}
          createError={createError}
          pbpData={pbpData}
          pbpStatus={pbpStatus}
          servicesOffering={servicesOffering}
          agentName={agentName}
          orgData = {orgData}
        />
        {selectionError && (
          <div style={{ color: "#f44336" }}>{selectionError}</div>
        )}
      </Grid>

      <SuccessFeedback
        open={status === true}
        onClose={handleDialogClose}
        successMessage={t("agent_Criteria_Form_Edit_Success_Message")}
      />

      <FailureFeedback
        open={failedStatus === true}
        onClose={handleDialogClose}
        status={"Failed"}
        message={t("agent_Criteria_Form_Edit_Failed_Message")}
        showButtons={true} />
      </>
    );
};
