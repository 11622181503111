import { Map } from "immutable";

let initialState = Map({
  responseStatus: null,
  user: null,
  loggedIn: false,
  status: "idle",
  token: null
});

export default function authentication(state = initialState, action) {
  switch (action.type) {
    case "UN_AUTHORIZED_LOGIN":
      return state;
    case "AUTHENTICATION_LOGIN_SUCCESS":
      return state.set("status", "success");
    case "AUTHENTICATION_LOGIN_FAIL":
      return state.set("status", "failed");
    default:
      return state;
  }
}
