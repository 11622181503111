import React, { useEffect, useContext, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authContext } from "context/authContext";
import addDays from "date-fns/addDays";
import { errorContext } from "context/errorContext";
import { useTranslation } from "react-i18next";
import FailureFeedback from "components/common/elements/FailureFeedback";
import { provisioningService } from "../../../services/provisioningServices.js";
import { criteriaService } from "../../../services/criteriaServices.js";
//import Table from "components/common/table/DynamicPaginationTable";
import { Grid, Paper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import PageHeader from "../../../components/layout/PageHeader.js";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";

import Table from "components/common/table/DynamicPaginationTable2";


const CriteriaList = props => {

// export default function CriteriaList() {

    const [status, setStatus] = React.useState("loading");
    const [criteriaStatus, setCriteriaStatus] = useState("loading");
    const [criteriaListData,setCriteriaListData] = React.useState([]);
    
    const { dispatch: errorDispatch } = useContext(errorContext);
    let viewDropDown = false;

    const [pageInfo, setPageInfo] = React.useState(null);

    // const location = useLocation();
    let query = useMemo(() => new URLSearchParams(props.location.search), [
      props.location
    ]);

    const nextPage = () => {
      if (
        status !== "loading" &&
        Number(query.get("page")) < pageInfo
      ) {
        query.set("page", Number(query.get("page")) + 1);
        props.history.push({
          pathname: props.location.pathname,
          search: query.toString()
        });
      }
    };

    const previousPage = () => {
      props.history.goBack();
    };

    const dropitems = [
    ];

    function getCriteriaList(){
      if (!!query.get("page") && !!query.get("size")) {
        let pageSize = query.get("size");
        let pageIndex = query.get("page");
        criteriaService.getCriteriaList(pageSize,pageIndex).then(data => {
          console.log(data);
          setCriteriaListData(data.content);
          setPageInfo(data.totalPages)
          setCriteriaStatus("success");
        }).catch(error => {
          console.error(error);
            errorDispatch({
            type: "ERROR",
            error
          });
          setCriteriaStatus("failed");
        });
      }else {
        props.history.push({
          pathname: props.location.pathname,
          search: `?page=0&size=5000`
        });
      }
    }

    useEffect(() => {
      provisioningService.verifyOtpNew('1313131313').then((res) => {
        const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
        const organizationId = JSON.parse(localStorage.getItem("orgId"));
        getCriteriaList();
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
    }, [query]);

    const columns = React.useMemo(
      () => [
        {
          Header: "Criteria Tags",
          accessor: "criteriaTags"
        },
        {
          Header: "User Type",
          accessor: "userType"
        },
        {
          Header: "Territory Name",
          accessor: "entityName"
        },
        {
          Header: "Edit",
          Cell: ({ row }) => {
            let linkDairyFarmer = `/configuration/editcriteria/dairy/${row.original.id}`;
            let linkSelfRegFarmer =  `/configuration/editcriteria/selfreg/${row.original.id}`;
            return (
              <>
                {row.original.userType === "DAIRY_FARMER"  && (
                    <Link to={{
                        pathname: linkDairyFarmer,
                        state: {
                          from: row.original
                        }
                      }}>
                      <IconButton>
                        <i className="fa fa-edit" aria-hidden="true"></i>
                      </IconButton>
                    </Link>
                  
                )} 
                {row.original.userType === "SELF_REGISTERED"  && (
                    <Link to={{
                        pathname: linkSelfRegFarmer,
                        state: {
                          from: row.original
                        }
                      }}>
                      <IconButton>
                        <i className="fa fa-edit" aria-hidden="true"></i>
                      </IconButton>
                    </Link>
                  
                )} 
                </>
              )
            }
          }
        ],
      []
    );

    const handlePageSize = size => {
      query.set("size", size);
      query.set("page", 0);
      props.history.push({
        pathname: props.location.pathname,
        search: query.toString()
      });
    };


    const data = React.useMemo(() => [...criteriaListData]);
    return(
      <Grid container>
        <Grid item xs={12}>
          <PageHeader
            title="Criteria"
            breadcrumbs={[
              { label: "Dashboard", link: "/dashboard" },
              { label: "Configuration" },
              { label: "Criteria" }
            ]}
          />
        </Grid>
        <Paper
          elevation={2}
          style={{
            padding: "2em",
            width: "100%"
          }}
        >
       
        {criteriaStatus === "loading" ? (
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ height: "50vh" }}
            >
             <CircularProgress />
            </Grid>
          ) : (
            // <Table
            //   columns={columns}
            //   data={data}
            //   paginationInfo={{
            //     pageIndex: Number(query.get("page")),
            //     nextPage,
            //     previousPage,
            //     pageSize: Number(query.get("size")),
            //     setPageSize: handlePageSize,
            //     pageInfo
            //   }}
            // />

            <Table  
              columns={columns}
              data={data}
              needDropdown={viewDropDown}
              dropItems={dropitems}
              fileName={"criteriaList.xls"}
            />
          )}
       
        </Paper>        
      </Grid>  
    );
};


export default CriteriaList;
