import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";

import CommonForm from "../../../components/common/form/common-form";
import { metadataService } from "../../../services/metadataServices";
import PageHeader from "../../../components/layout/PageHeader";
// import LoanProductName from "components/common/form/LoanProductName";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { errorContext } from "context/errorContext";
import { useTranslation } from "react-i18next";

//Import added for Date field
import addDays from "date-fns/addDays";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Switch,
  Box,
  withStyles,
} from "@material-ui/core";

export default function pbpForm({ 
  values, 
  handleSubmit, 
  page,   
  showSubmitButton=true,
}) 

{
  const loadedPage = page;
  const formikForm = React.useRef(null);
  const { t } = useTranslation();
  const { dispatch: errorDispatch } = useContext(errorContext);
  
  const fields = [
    
    {
      type: "text",
      name: "name",
      label: t("pbp_Name"),
      placeholder: t("pbp_Name"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },    
   
    {
      type: "text",
      name: "address",
      label: t("pbp_Address"),
      placeholder: t("pbp_Address"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },    
    {
      type: "text",
      name: "gst",
      label: t("pbp_gst"),
      placeholder: t("pbp_gst"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
  ]

  // if(loadedPage === "add"){
    let validationsAdd = {
        name: Yup.string().required(t("pbp_Name"))
        .max(20, 'Max 20 characters allowed'), 
        address: Yup.string().required(t("pbp_Address"))
        .max(500, 'Max 500 characters allowed'),       
        gst: Yup.string().required(t("pbp_gst"))
        //.matches(/^[0-9]+$/, "Must be only digits")
        //.matches(/^[+-]?\d+(\.\d+)?$/,"Must be only digits")  //  It will accepts float value only
        .matches(/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/,"Enter 15-digit unique alphanumeric code")
        .min(15, 'Enter 15-digit unique alphanumeric code')
        .max(15, 'Enter 15-digit unique alphanumeric code'),
    }
  // }else{
       let validationsEdit = {
        name: Yup.string().required(t("pbp_Name"))
        .max(20, 'Max 20 characters allowed'), 
        address: Yup.string().required(t("pbp_Address"))
        .max(500, 'Max 500 characters allowed'),       
        gst: Yup.string().required(t("pbp_gst"))
        //.matches(/^[0-9]+$/, "Must be only digits")
        //.matches(/^[+-]?\d+(\.\d+)?$/,"Must be only digits")  //  It will accepts float value only
        .matches(/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/,"Enter 15-digit unique alphanumeric code")        
        .min(15, 'Enter 15-digit unique alphanumeric code')
        .max(15, 'Enter 15-digit unique alphanumeric code'),
     }
    
  return (

    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("pbp_Form_Header_Label")}
          breadcrumbs={[
            { label: t("pbp_Form_Breadcrumb_Dashboard_Label"), link: "/dashboard" },
            {
              label: t("pbp_Form_Breadcrumb_pbp_Label"),
              link: "/configuration/pbp-list"
            },
            { label: t("pbp_Form_Breadcrumb_pbp_Label") }
          ]}
        />
      </Grid>

      <Grid container>
        <Paper elevation={2} style={{ padding: "2em", width: "100%" }}>
          {" "}
          <Grid item xs={12}>
            {loadedPage == "add" &&
              <h5>{t("pbp_Form_Title_Add_Label")}</h5>
            }
            {loadedPage == "edit" &&
              <h4>{t("pbp_Form_Title_Edit_Label")}</h4>
            }
          </Grid>
          <Grid item>
            {loadedPage == "add" &&
              <CommonForm
                fields={fields}
                submitLabel={t("pbp_Form_Submit_Label")}
                submittingLabel={t("pbp_Form_Submitting_Label")}
                initialValues={values}
                validationSchema={Yup.object().shape(validationsAdd)}
                //validateOnBlur={true}
                //validateOnChange={true}
                showSubmitButton={showSubmitButton}
                onSubmit={(values, { resetForm, setSubmitting }) => {
                  //console.log("Actual Values Passed:- ", values);
                  handleSubmit(values, resetForm, setSubmitting);
                }}
                formikRef={formikForm}
                buttonSize="3"
                buttonPosition="right"
                enableReinitialize
              />
            }
            {loadedPage == "edit" &&
              <CommonForm
                fields={fields}
                submitLabel={t("pbp_Form_Submit_Label")}
                submittingLabel={t("pbp_Form_Submitting_Label")}
                initialValues={values}
                validationSchema={Yup.object().shape(validationsEdit)}
                validateOnBlur={true}
                validateOnChange={true}
                showSubmitButton={showSubmitButton}
                onSubmit={(values, { resetForm, setSubmitting }) => {
                  //console.log("Actual Values Passed:- ", values);
                  handleSubmit(values, resetForm, setSubmitting);
                }}
                formikRef={formikForm}
                buttonSize="3"
                buttonPosition="right"
                enableReinitialize
              />
            }
          </Grid>
        </Paper>
      </Grid>
    </Grid>
    
  );
}
