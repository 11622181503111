
async function editAgentCriteria(id,values) {
  const url = `${process.env.REACT_APP_EDIT_AGENT_CRITERIA}/${id}`;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "gh7MvvJXnRQO44PatMnoAuBcgLNTfk",
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`
    },
    body: JSON.stringify(values)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}
  
async function getAgentCriteriaList(pageSize,pageIndex) {
  const url = `${process.env.REACT_APP_GET_AGENT_CRITERIA_LIST}?page=${pageIndex}&size=${pageSize}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "gh7MvvJXnRQO44PatMnoAuBcgLNTfk",
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`
    },
    body: JSON.stringify()
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

export const agentCriteriaService = {
  getAgentCriteriaList,
  editAgentCriteria
};