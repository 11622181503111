import React from "react";

import clsx from "clsx";

import SiteFooter from "components/layout/SiteFooter";
import SideBar from "./SideBar";
import SiteWrapperMobile from "./SiteWrapperTest";

import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Scrollbars from "react-custom-scrollbars";

const useStyles = makeStyles(theme => ({
  button: {
    position: "absolute",
    top: "0",
    left: "0",
    color: "#fff"
  },
  main: {
    backgroundColor: "#F6F9FC",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: `calc(100%-73px)`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  mainShift: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginLeft: "240px",
    width: `calc(100% - 240px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  }
}));

function SiteWrapper(props) {
  const [menuOpen, setMenuOpen] = React.useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const classes = useStyles();

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
    setMobileOpen(!mobileOpen);
  };

  const matches = useMediaQuery("(min-width:800px)");
  return (
    <Scrollbars
        style={{ height: "100vh", width: "100vw" }}
        renderThumbVertical={props => (
          <div {...props} className="thumb-vertical" />
        )}
        renderTrackVertical={props => (
          <div {...props} className="track-vertical track-vertical-index" />
        )}
      >
    <CssBaseline>
      {matches && <SideBar open={menuOpen} handleClose={handleToggleMenu} />}

      <Container
        className={clsx(classes.main, {
          [classes.mainShift]: matches ? menuOpen : null
        })}
        style={
          matches
            ? menuOpen
              ? { marginLeft: "240px" }
              : { marginLeft: "73px" }
            : { marginLeft: "0px" }
        }
      >
        <IconButton onClick={handleToggleMenu} className={classes.button}>
          <MenuIcon />
        </IconButton>
        {matches && props.children}
        {!matches && <SiteWrapperMobile>{props.children}</SiteWrapperMobile>}
        <SiteFooter />
      </Container>
    </CssBaseline>
      </Scrollbars>
    
  );
}

export default SiteWrapper;
