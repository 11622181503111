import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import AgentForm from "./AgentForm";
import { provisioningService } from "services/provisioningServices";
import format from "date-fns/format";
import { number, string } from "prop-types";
import SweetAlert from 'react-bootstrap-sweetalert';


export default function EditAgent() {
  const org = JSON.parse(localStorage.getItem("currentUser")).organizationName;
  const [agentDetails, setAgentDetails] = useState(null);

  const { id } = useParams();
  const history = useHistory();
  const [editSuccess, setEditSuccess] = React.useState(false);
  const [editFailure, setEditFailure] = React.useState(false);
  const [failureMsg, setFailureMsg] = React.useState("");

  // const [organization, setOrganization] = React.useState([]);
  // // const [type, setType] = React.useState([]);
  // // const [category, setCategory] = React.useState([]);
  // const [states, setStates] = React.useState([]);
  // const [districts, setDistricts] = React.useState([]);
  // const [taluks, setTaluks] = React.useState([]);
  // const [villages, setVillages] = React.useState([]);

  const hideAlert = (event) => {
    setEditSuccess(false);
    setEditFailure(false);
  }

  // to initialize the values when create template form loads first time
  let values = {
    id: id,
    // organization: organization,
    // organizationName: agentDetails ? agentDetails.organizationName : "",
    // organizationid: agentDetails ? agentDetails.organizationid : "",
    name: agentDetails ? agentDetails.name : "",
    gender: agentDetails ? agentDetails.gender : "",
    dateOfBirth: agentDetails ? agentDetails.dateOfBirth : new Date(),
    addressLine1: agentDetails ? agentDetails.addressLine1 : "",
    // addressLine2: agentDetails ? agentDetails.addressLine2 : "",
    mobileNumber: agentDetails ? agentDetails.mobileNumber : "",
    emailID: agentDetails ? agentDetails.emailID : "",
    username: agentDetails ? agentDetails.username : "",
    password: agentDetails ? agentDetails.password : ""
    // agentPincode: agentDetails ? agentDetails.agentPincode : "",
    // agentVillage: agentDetails ? agentDetails.agentVillage : "",
    // agentTaluk: agentDetails ? agentDetails.agentTaluk : "",
    // agentDistrict: agentDetails ? agentDetails.agentDistrict : "",
    // agentState: agentDetails ? agentDetails.agentState : "",
    // agentCountry: agentDetails ? agentDetails.agentCountry : "",
    // agreement: agentDetails ? agentDetails.agreement : "",
    // status: agentDetails ? agentDetails.status : "",
    // creditLimit: agentDetails ? agentDetails.creditLimit : "",
    // creditDays: agentDetails ? agentDetails.creditDays : "",
    // socialSecurityNumber: agentDetails ? agentDetails.socialSecurityNumber : "",
    // phoneNumber: agentDetails ? agentDetails.phoneNumber : "",
    // location: agentDetails ? agentDetails.location : "",
    // license: agentDetails ? agentDetails.license : "",
    // gst: agentDetails ? agentDetails.gst : "",
    // latLang: agentDetails ? agentDetails.latLang : "",
    // createdDate: agentDetails ? agentDetails.createdDate: new Date(),
    // updatedDate: agentDetails ? agentDetails.updatedDate: new Date(),
    // createdBy: agentDetails ? agentDetails.createdBy: "",
    // updatedBy: agentDetails ? agentDetails.updatedBy: "",
    // accountName: agentDetails ? agentDetails.accountName: "",
    // accountNumber: agentDetails ? agentDetails.accountNumber: "",
    // bankName: agentDetails ? agentDetails.bankName : "",
    // branch: agentDetails ? agentDetails.branch : "",
    // ifsc: agentDetails ? agentDetails.ifsc : "",
    // storeName: agentDetails ? agentDetails.storeName : "",
    // storeAddress1: agentDetails ? agentDetails.storeAddress1 : "",
    // storeAddress2: agentDetails ? agentDetails.storeAddress2 : "",
    // storePincode: agentDetails ? agentDetails.storePincode : "",
    // storeVillage: agentDetails ? agentDetails.storeVillage : "",
    // storeTaluk: agentDetails ? agentDetails.storeTaluk : "",
    // storeDistrict: agentDetails ? agentDetails.storeDistrict : "",
    // storeState: agentDetails ? agentDetails.storeState : "",
    // storeCountry: agentDetails ? agentDetails.storeCountry : "",
    // pnumber: agentDetails ? agentDetails.pnumber : ""

  };

  //didMount
  useEffect(() => {

    // provisioningService.getOrgsAgent().then(data2 => {
    //   if (data2) {
    //     setOrganization(data2);
    //   }
    // });

    provisioningService.getAgentById(id).then(data => {
      setAgentDetails(data);
    });

    // provisioningService.getStatesAgent().then(data => {
    //   if (data.states) {
    //     console.log("States:-==========", data.states);
    //     setStates(data.states);
    //   }
    // });

    // provisioningService.getDistrictsAgent("Karnataka").then(data => {
    //   if (data.districts) {
    //     console.log("Districts:-========== ", data.districts);
    //     setDistricts(data.districts);
    //   }
    // });

    // provisioningService.getTaluksAgent("Udupi").then(data => {
    //   if (data.taluks) {
    //     console.log("Taluks:-========== ", data.taluks);
    //     setTaluks(data.taluks);
    //   }
    // });

    // provisioningService.getVillagesAgent("Mangalore").then(data => {
    //   if (data.villages) {
    //     console.log("Villages:-========== ", data.villages);
    //     setVillages(data.villages);
    //   }
    // });

  }, []);

  async function handleSubmit(values, resetForm, setSubmitting) {

    // if(typeof(values.validFromDate) === "string" || typeof(values.validToDate) === "string"){
      var datetime = new Date(values.dateOfBirth);
      values.dateOfBirth = datetime
        .toISOString().substring(0,10);
        // .replace("00:00:00.000","00:01:00.000")
        // .replace("Z", " ")
        // .replace("T", " ");
    //   var datetime = new Date(values.validToDate);
    //   values.validToDate = datetime
    //     .toISOString()
    //     .replace("00:00:00.000","23:59:59.999")
    //     .replace("Z", " ")
    //     .replace("T", " ");
    // }else{
    //   var datetime = new Date(values.validFromDate.setUTCHours(0, 1, 0, 0));
    //   values.validFromDate = datetime
    //     .toISOString()
    //     .replace("Z", " ")
    //     .replace("T", " ");
    //   var datetime = new Date(values.validToDate.setUTCHours(23, 59, 59, 999));
    //   values.validToDate = datetime
    //     .toISOString()
    //     .replace("Z", " ")
    //     .replace("T", " ");
    // }

    // var organizationObj = values.organization.filter(function (item) {
    //   return item.organizationName === values.organizationName;
    // })[0];

    // values.organization = organizationObj;

    // delete values["organization"];
    //delete values["dateOfBirth"];
    // delete values["addressLine2"];
    // delete values["organizationid"];
    // delete values["organizationName"];
    // delete values["agentPincode"];
    // delete values["agentVillage"];
    // delete values["agentTaluk"];
    // delete values["agentDistrict"];
    // delete values["agentState"];
    // delete values["agentCountry"];
    // delete values["creditLimit"];
    // delete values["creditDays"];
    // delete values["socialSecurityNumber"];
    // delete values["phoneNumber"];
    // delete values["location"];
    // delete values["license"];
    // delete values["gst"];
    // delete values["latLang"];
    // delete values["createdDate"];
    // delete values["updatedDate"];
    // delete values["createdBy"];
    // delete values["updatedBy"];
    // delete values["accountName"];
    // delete values["accountNumber"];
    // delete values["bankName"];
    // delete values["branch"];
    // delete values["ifsc"];
    // delete values["storeName"];
    // delete values["storeAddress1"];
    // delete values["storeAddress2"];
    // delete values["storePincode"];
    // delete values["storeVillage"];
    // delete values["storeTaluk"];
    // delete values["storeDistrict"];
    // delete values["storeState"];
    // delete values["storeCountry"];
    // delete values["pnumber"];

    //console.log("Edit Post obj:-------- ", values);
    try {
      const repeat = null;
      const result = await provisioningService.editAgent(id,
        values
      );
      setEditSuccess(true);
      //resetForm();
      //redirect to list page
      setTimeout(() => {
        history.push("/configuration/agent-list");
      }, 3000); 
    } catch (e) {
      //console.log(e);
      setEditFailure(true);
      setFailureMsg(e.error.message);
      setSubmitting(false);
    }
    //setSubmit to false
    //call resetForm
  }

  // Calling the create template form with values(to initialize),
  return (
    <>
      
      <AgentForm
        values={values}
        handleSubmit={handleSubmit}
        page="edit"
        // organization={organization}
        // states={states}
        // districts={districts}
        // taluks={taluks}
        // villages={villages}
      />

    {editSuccess ? 
      <SweetAlert success
        title="Agent Edited Successfully"
        onConfirm={hideAlert}
        //disabled={ disableOkBtn ? true : false }
        onCancel={hideAlert}
        timeout={2000}
      ></SweetAlert> 
    : null}

    {editFailure ? 
        <SweetAlert danger
          title={failureMsg}
          onConfirm={hideAlert}
          //disabled={ disableOkBtn ? true : false }
          onCancel={hideAlert}
          //timeout={3000}
        ></SweetAlert> 
      : null}

    </>
  );
}
