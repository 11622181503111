import service from './agentpayment-service.js';
  
export class AgentPaymentImportService {

    triggerAgentPaymentImport(data) {
        const options = {
            headers: {
                authToken: localStorage.getItem("idToken"),
                Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
                "Content-Type": "multipart/form-data",
                "Api-Key": 'e87MPVwlgORoG9J8Pod4j7L5p0eQfC'
            }
        };
        //returns Promise object
        return service.getAgentPaymentServiceURL().post('/datasyncservice/nexgen/agent/payment-template-import', data, options);
    }

}