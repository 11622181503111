import React from "react";
import PageHeader from "components/layout/PageHeader";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

export default function Faq() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={"FAQs"}
          breadcrumbs={[
            { label: "Dashboard", link: "/dashboard" },
            { label: "FAQs" }
          ]}
        />
      </Grid>
      <Grid item className="report-category">
        <Paper elevation={2} style={{ padding: "2em" }}>
          <div className="mt-4">
            <h3>
              <strong>1. Network Issue</strong>
            </h3>
            <ul>
              <li>
                <p>
                  Second dot blinking in contrak modules Seven Segment Display.
                </p>
              </li>
              <li>
                <p>Sending wrong Date and Time.</p>
              </li>
              <li>
                <p>TPC not charged above the rated voltage.</p>
              </li>
              <li>
                <p>SIM connection error</p>
              </li>
            </ul>

            <h3>
              <strong>2. Feedback Issue</strong>
            </h3>
            <ul>
              <li>
                <p>COMP FB LED is off in the contrak module.</p>
              </li>
              <li>
                <p>Grid FB LED is off in the contrak module.</p>
              </li>
              <li>
                <p>DG FB LED is off in the contrak module.</p>
              </li>
              <li>
                <p>AGITATOR FB LED is off in the contrak module.</p>
              </li>
            </ul>

            <h3>
              <strong>3.TPC Issue</strong>
            </h3>
            <ul>
              <li>
                <p>Battery(TPC)</p>
              </li>
              <li>
                <p>Input and Output Voltage</p>
              </li>
              <li>
                <p>TPC charging issue</p>
              </li>
            </ul>

            <h3>
              <strong>4. Sensor Issue</strong>
            </h3>
            <p>Temperature Sensor:</p>
            <ul>
              <li>
                <p>
                  Error message on the seven segment display in the contrak
                  module(tttt).
                </p>
              </li>
              <li>
                <p>Temperature not changing in the display when MV changes.</p>
              </li>
              <li>
                <p>Temperature sensor placement issue.</p>
              </li>
            </ul>

            <p>Volume Sensor:</p>
            <ul>
              <li>
                <p>Housing issue.</p>
              </li>
              <li>
                <p>Placement issue.</p>
              </li>
              <li>
                <p>Loose contact of the volume sensor connector.</p>
              </li>
              <li>
                <p>Physical damage of the volume sensor.</p>
              </li>
              <li>
                <p>Sensor drift</p>
              </li>
            </ul>

            <h3>
              <strong>6. Process connection issue</strong>
            </h3>

            <h3>
              <strong>7. Contrak Module</strong>
            </h3>
            <ul>
              <li>
                <p>Feedback LED's not turning ON.</p>
              </li>
              <li>
                <p>Seven segment display not turning ON.</p>
              </li>
              <li>
                <p>
                  All LED's in the module are turned ON and display is freezed.
                </p>
              </li>
              <li>
                <p>
                  Temperature or Volume data is not changing in the Contrak
                  module display.
                </p>
              </li>
              <li>
                <p>Module not connecting to the network.</p>
              </li>
            </ul>

            <h3>
              <strong>8. Electrical Noise Issue</strong>
            </h3>

            <h3>
              <strong>9. Line voltage fluctuations</strong>
            </h3>

            <hr />

            <h3>
              <strong>
                1. Why second dot will blink in seven segment display from left
                in contrak module?
              </strong>
            </h3>
            <p>
              The Second dot will blink on the seven segment dispaly of contrak
              module due to the following reasons
            </p>
            <ul>
              <li>
                <p>
                  The Error code in display password mode, should be 0.
                  <br />
                  If the value is not 0, then check for the cases below.
                  <br />
                  Case 1:If the error code is 10, then reboot the board.
                  <br />
                  Case 2:If the error code is 11,12 then check for sim
                  activation, internet connectivity.
                  <br />
                  Case 3:If the error code is 13 to 15 then it could be unknown
                  issue.
                </p>
              </li>
            </ul>

            <ul>
              <li>
                <p>
                  Check for the signal strength in display password mode by
                  pressing enter key.
                  <br />
                  If the value is between 10 to 30 then the network is good.
                </p>
              </li>
            </ul>

            <ul>
              <li>
                <p>
                  If the error code is 0 and the signal strength is 99 or less
                  then 10 then check for the antenna position. Change the
                  antenna position to get good signal strength.
                </p>
              </li>
            </ul>

            <h3>
              <strong>
                2. What is the cause for the Contrak module to send wrong date
                and time?
              </strong>
            </h3>

            <ul>
              <li>
                <p>
                  If the board is sending wrong date and time then first check
                  for the service provider settings.
                </p>
              </li>
              <li>
                <p>
                  Check by interchanging the SIM to different service provider.
                </p>
              </li>
            </ul>

            <h3>
              <strong>
                3. What is the cause for the SIM to stop working in the running
                site?
              </strong>
            </h3>

            <ul>
              <li>
                <p>
                  The Error code in display password mode, should be 0.
                  <br />
                  If the value is not 0, then check for the cases below.
                  <br />
                  Case 1:If the error code is 10, then reboot the board.
                  <br />
                  Case 2:If the error code is 11,12 then check for sim
                  activation,internet connectivity.
                  <br />
                  Case 3:If the error code is 13 to 15 then it could be unknown
                  issue.
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p>
                  Check for the signal strength in display password mode by
                  pressing enter key.
                  <br />
                  If the value is between 10 to 30 then the network is good.
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p>
                  If the error code is 0 and the signal strength is 99 or less
                  then 10 then check for the antenna position. Change the
                  antenna position to get good signal strength.
                </p>
              </li>
              <li>
                <p>
                  The network isssue comes when TPC is not charged above the
                  rated voltage.
                </p>
              </li>
              <li>
                <p>
                  Remove the SIM from the module and check out with different
                  service provider.
                </p>
              </li>
              <li>
                <p>
                  Try to send an SMS from the registered SIM to confirm the
                  working of the SIM.
                </p>
              </li>
            </ul>

            <h3>
              <strong>
                4. What happens when TPC is not charged above the rated voltage?
              </strong>
            </h3>
            <p>
              The network isssue comes when TPC is not charged above the rated
              voltage.
            </p>
            <ul>
              <li>
                <p>
                  First check wheather grid or DG is available to charge the
                  TPC.
                </p>
              </li>
              <li>
                <p>
                  Check the input to the TPC(230 Vac), and 5A fuse wheather it
                  has blown out.
                </p>
              </li>
              <li>
                <p>
                  Check the output of the TPC(12 V), also check for 0.5A fuse
                  wheather it has blown out.
                </p>
              </li>
            </ul>

            <h3>5. Why the COMP FB LED is off on the contral module?</h3>
            <p>
              The COMPFB Led is off on the contrak module due to the following
              reasons
            </p>
            <ul>
              <li>
                <p>
                  If VPR is used, Compfb led is off for the various cases as
                  below
                  <br />
                  Case 1: R Y B would have been interchanged at the site.
                  <br />
                  Case 2: VPR might gone bad.
                  <br />
                  (The secondary relay does not switch ON if phase is correct).
                  Case 3: Output voltage of the VPR should be 4.45 V.
                  <br />
                  Case 4:25 pin connector cable would have gone bad or the
                  feedback wire would have got disconnected.
                </p>
              </li>
            </ul>

            <ul>
              <li>
                <p>
                  If CT sensor is used, Compfb led is off for the various cases
                  as below
                  <br />
                  Case 1: The voltage rating must be in the below range:
                  <br />
                  Input: 4.5V at Rand B cable.
                  <br />
                  Output: 0.7 V-3 V at Gand B cable.
                  <br />
                  If the Output is less than 0.7 V then may have to increase the
                  number of turns of the CT Sensor coil.
                </p>
              </li>
              <li>
                <p>There might be loose contact at AC section or DC section.</p>
              </li>
              <li>
                <p>The COMPFB CT sensor IC(4N35) might have gone bad.</p>
              </li>
            </ul>

            <h3>
              <strong>
                6. Why the AGZ FB LED is continously on/off when no power in the
                contral module?
              </strong>
            </h3>
            <p>If CT sensor is used, then</p>
            <ul>
              <li>
                <p>
                  The AGZ FB led is continously OFF if the Vcc(Red Cable) is
                  open.
                  <br />
                  Check input at Red and Blue cable.
                  <br />
                  Check output at Green and Blue.
                </p>
              </li>
              <li>
                <p>
                  The AGZ FB led is continously ON if the Gnd(Blue cable) is
                  open.
                  <br />
                  Check input at Red and Blue cable.
                  <br />
                  Check output at Green and Blue cable.
                </p>
              </li>
              <li>
                <p>There might be loose contact at any end of the connector.</p>
              </li>
              <li>
                <p>
                  If Relay PCB is used, then
                  <br />
                  The Agitator feedback is OFF due to the failure of relay PCB
                  because of power fluctuations,electrical surge etc
                </p>
              </li>
            </ul>

            <h3>
              <strong>
                7. Why the Grid FB LED is off on the contral module?
              </strong>
            </h3>
            <p>
              The Grid FB led is off, on the contrak module due to following
              reasons
            </p>
            <ul>
              <li>
                <p>
                  If VPR is used, Grid FB led is off due to following reasons.
                  <br />
                  Case 1:R Y B would have been interchanged at the site.
                  <br />
                  Case 2:VPR might havegone bad.
                  <br />
                  (The secondary relay does not switch ON if phase is correct).{" "}
                  <br />
                  Case 3:Output voltage of the VPR should be 4.45 V.
                </p>
              </li>
              <li>
                <p>
                  If CT sensor is used, Grid FB is off for the various cases as
                  below
                  <br />
                  Case 1: The voltage rating must be in the below range
                  <br />
                  Input: 4.45V at Red and Blue cable.
                  <br />
                  Output: 0.7 V-3 V at Green and Blue cable.
                  <br />
                  If the Output is less than 0.7 V then may have to increase the
                  number of turns of the CT Sensor coil.
                </p>
              </li>
              <li>
                <p>There might be loose contacts.</p>
              </li>
              <li>
                <p>The Grid FB IC might have gone bad.</p>
              </li>
            </ul>

            <h3>
              <strong>8. Why the DG FB LED is off on the contrak module</strong>
            </h3>
            <p>
              If there is relay failure the DG FB LED is off on the contrak
              module, or there might be an issue with the output of the led in
              the panel board.
            </p>
            <ul>
              <li>
                <p>
                  Cathode 4.43V.
                  <br />
                  Anode 2.5V.
                </p>
              </li>
            </ul>

            <h3>
              <strong>9.Why the CHARGER ON Led is off on the TPC?</strong>
            </h3>
            <ul>
              <li>
                <p>
                  The 5A fuse in the TPC would have blown out, in such case the
                  charger on Led is off on the TPC with grid supply.
                </p>
              </li>
            </ul>

            <h3>
              <strong>
                10. What are the various issues that causes the failure of TPC?
              </strong>
            </h3>

            <ol>
              <li>
                <p>1. TPC backup issue</p>
              </li>
              <li>
                <p>2. Not charging the TPC to the rated voltage.</p>
              </li>
              <li>
                <p>3. Fuse issue(5A and 0.5A.)</p>
              </li>
              <li>
                <p>4. No output from the TPC(12V).</p>
              </li>
              <li>
                <p>5. Circuit failure of the TPC.</p>
              </li>
              <li>
                <p>6. Transformer failure.</p>
              </li>
            </ol>

            <h3>
              <strong>
                11. What are two main parameters that causes the failure of fuse
                box?
              </strong>
            </h3>
            <ul>
              <li>
                <p>
                  High volatge and Short circuit are the two main parameters
                  that causes the failure of fuse box.
                </p>
              </li>
            </ul>

            <h3>
              <strong>
                12. Why the Error message(tttt) will appear on the Seven segment
                dispaly on the contrak module?
              </strong>
            </h3>
            <ul>
              <li>
                <p>
                  The Error message tttt will appear on the seven segment
                  dispaly of contrak module if there is accuracy mismatch
                  between temperature sensor output voltage and the temperature
                  of the seven segment display of contrak module.
                  <br />
                  If there is any accuracy mismatch in that case we may have to
                  analyse the output of the temperature sensor.
                  <br />
                  Also the placement of the temperature sensor in the BMC.
                </p>
              </li>
            </ul>

            <h3>
              <strong>
                13. What happens when the temperature sensor is not placed in
                the BMC in proper position?
              </strong>
            </h3>
            <ul>
              <li>
                <p>
                  The output of temperature sensor is always 0 if there is any
                  loose contact or if temperature sensor is not placed in the
                  proper position in the BMC.
                </p>
              </li>
            </ul>

            <h3>
              <strong>
                14. What steps to be taken when temperature does not change in
                the display of contrak module with mV change?
              </strong>
            </h3>
            <p>
              The below steps to be taken if temperature doesn't changes with mV
            </p>
            <ul>
              <li>
                <p>
                  Case 1: Analyse the output voltage of the temperature sensor,
                  and match the accuracy with temperature of the seven segment
                  display of contrak module.
                </p>
              </li>
              <li>
                <p>Case 2: Check mV at the temperature connector.</p>
              </li>
              <li>
                <p>
                  Case 3: Might be the board issue(Required to send board for
                  office testing).
                </p>
              </li>
            </ul>

            <h3>
              <strong>
                15. What are the effects of Temperature sensor placement issue?
              </strong>
            </h3>

            <ul>
              <li>
                <p>
                  The temperature exceeds above the valid temperature(70C) if
                  the placement is not proper in the BMC.
                  <br />
                  If the placement has changed in the BMC then place it back to
                  the normal position and tighten the screws of the BMC to avoid
                  future misplacement.
                </p>
              </li>
            </ul>

            <h3>
              <strong>
                16. What are the various parameters that causes the failure of
                relay pcb?
              </strong>
            </h3>
            <p>
              The various parameters that damages the relay pcb is as given
              below
            </p>
            <ul>
              <li>
                <p>1. High voltage causes the relay pcb to burn</p>
              </li>
              <li>
                <p>2. Electrical surge</p>
              </li>
              <li>
                <p>3. Voltage fluctuations.</p>
              </li>
            </ul>

            <h3>
              <strong>
                17. Why the drift of volume occurs from actual in the field?
              </strong>
            </h3>
            <p>
              Drift of volume from actual occurs for the various reasons as
              listed below
            </p>
            <ol>
              <li>
                <p>
                  One of the cause is due to change of position of sensor
                  attached to the process pipe.
                </p>
              </li>
              <li>
                <p>
                  Diaphragm getting damage during clean in process(CIP) of BMC,
                  where cleaning brush is used for cleaning near the sensor.
                </p>
              </li>
              <li>
                <p>Drift of volume,when initial placed in field.</p>
              </li>
              <li>
                <p>
                  Noise effecting ADC values resulting in unstable volume
                  display.
                </p>
              </li>
            </ol>

            <h3>
              <strong>
                18. What are the actions taken in the field to avoid the drift
                of volume from actual?
              </strong>
            </h3>
            <p>
              Below are the actions taken to avoid drift of volume from actual
            </p>
            <ol>
              <li>
                <p>
                  Change of position has been avoided by placing stand attached
                  to ground from sensor.
                </p>
              </li>
              <li>
                <p>
                  Steel plate is placed in process pipe below sensor placement
                  which protects sensor diaphragm getting damaged.
                </p>
              </li>
              <li>
                <p>
                  Testing of new sensors before deploying to the field to avoid
                  initial drift.
                </p>
              </li>
            </ol>

            <h3>
              <strong>
                19. Why the volume part disappers in the chilling graph of
                contrak portal?
              </strong>
            </h3>
            <ul>
              <li>
                <p>
                  The main reason for the disappearance of the volume in the
                  chilling graph is the loose contact of the volume sensor
                  connector.
                </p>
              </li>
              <li>
                <p>
                  The other reason could be an issue with the volume sensor.
                </p>
              </li>
            </ul>

            <h3>
              <strong>
                20.What are various issues that the volume sensor will encounter
                in the field?
              </strong>
            </h3>
            <p>The various issues are listed below</p>
            <ol>
              <li>
                <p>Drift in the volume from actual.</p>
              </li>
              <li>
                <p>
                  Volume will not be stable in both morning and evening
                  sessions.
                </p>
              </li>
              <li>
                <p>Constant drift at lower and higher volume.</p>
              </li>
              <li>
                <p>
                  Malfunctioning and the random behaviour at the volume sensor.
                </p>
              </li>
              <li>
                <p>
                  Major failure occurs if the output current is not in range.
                </p>
              </li>
              <li>
                <p>No change in the output irrespective of pressure applied.</p>
              </li>
            </ol>

            <h3>
              <strong>21. Why the housing is important for the volume?</strong>
            </h3>
            <ul>
              <li>
                <p>
                  Housing is provided to the volume sensor in the field mainly
                  to protect against water spillage in the field and also to
                  avoid moisture formation on the volume sensor.
                </p>
              </li>
            </ul>

            <h3>
              <strong>
                22. What are the precautions taken while selecting a process
                connection for the different BMC?
              </strong>
            </h3>
            <p>
              Below are the different parameters to be considered while
              selecting the process connection for the particular BMC
            </p>
            <ol>
              <li>
                <p>Process connection mismatch</p>
              </li>
              <li>
                <p>SS pipe alignment</p>
              </li>
              <li>
                <p>Gaskets</p>
              </li>
              <li>
                <p>Leakage</p>
              </li>
              <li>
                <p>Volume sensor thread mismatch.</p>
              </li>
            </ol>

            <h3>
              <strong>
                23. Why the seven segment display do not turn ON on the contrak
                module?
              </strong>
            </h3>
            <ul>
              <li>
                <p>
                  If the seven segment display does not turn ON on the contrak
                  module then there might be an issue with the FRC cable
                  connected from HMS board to panel board.
                </p>
              </li>
            </ul>

            <h3>
              <strong>
                24. What are the steps taken when the module does not connect to
                the network?
              </strong>
            </h3>
            <p>
              If the module does not connect to the network then check for the
              various cases below
            </p>
            <ol>
              <li>
                <p>Check for the service provider settings</p>
              </li>
              <li>
                <p>
                  Check for the sim position in the sim slot for its correct
                  position
                </p>
              </li>
              <li>
                <p>
                  Check the sim by interchanging to different service provider
                </p>
              </li>
            </ol>

            <h3>
              <strong>25. What is the cause for the connectors failure?</strong>
            </h3>
            <ul>
              <li>
                <p>Loose crimping causes the failure of the connectors.</p>
              </li>
            </ul>

            <h3>
              <strong>
                26. What are the steps taken if there is any electrical noise?
              </strong>
            </h3>
            <p>
              Electrical noise issue comes if Gnd is not proper, which inturn
              causes the ADC variation.
              <br />
              The below are the steps taken to address the noise issue
            </p>
            <ul>
              <li>
                <p>Check for the earthing points.</p>
              </li>
              <li>
                <p>Check for the disturbance from lights,focus lights.</p>
              </li>
              <li>
                <p>Address the TPC,Board,Transformer grounding.</p>
              </li>
            </ul>

            <h3>
              <strong>
                27. What are the problems related to quality of power supply?
              </strong>
            </h3>
            <ul>
              <li>
                <p>Voltage fluctuations,power surges and spikes.</p>
              </li>
            </ul>
          </div>
          <div className="table-responsive">
            <h2>
              <strong>Alert Definitions</strong>
            </h2>
            <div className="cattle-profile mt-4">
              <table className="table table-bordered table-vertical table-hover table-responsive">
                <thead>
                  <tr>
                    <th>Alert name</th>
                    <th>Description</th>
                    <th>Level</th>
                    <th>definition</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>agitator_on</td>
                    <td>Agitator On</td>
                    <td>info</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>agitator_off</td>
                    <td>Agitator Off</td>
                    <td>info</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>compressor_on</td>
                    <td>Compressor On</td>
                    <td>info</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>chilling_pump_on</td>
                    <td>Chilling pump On</td>
                    <td>info</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>chilling_pump_off</td>
                    <td>Chilling pump Off</td>
                    <td>info</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>chilling_started</td>
                    <td>Chilling started</td>
                    <td>info</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>chilling_completed</td>
                    <td>Chilling completed</td>
                    <td>info</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>milk_lifted</td>
                    <td>Milk lifted</td>
                    <td>info</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>cip_detected</td>
                    <td>CIP</td>
                    <td>info</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>grid_on</td>
                    <td>Grid On</td>
                    <td>info</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>grid_off</td>
                    <td>Grid Off</td>
                    <td>info</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>agitator_malfunction</td>
                    <td>Agitator malfunction</td>
                    <td>critical</td>
                    <td>
                      <p>
                        <strong>
                          Detect agitator not running when chilling is in
                          progress
                        </strong>
                        <br />
                        <br />
                        milk-volume should be greater than 100 <br />
                        milk temperature &gt;= 4.1 (milk is not yet chilled)
                        <br />
                        agitator is off
                        <br />
                        volume variation should not be more than 10
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>agitator_malfunction_stage_2</td>
                    <td>Agitator malfunction stage-2</td>
                    <td>critical</td>
                    <td>
                      <p>
                        same as stage-1 above with condition persisting for more
                        than 20 minutes
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>agitator_malfunction_stage_3</td>
                    <td>Agitator malfunction stage-3</td>
                    <td>critical</td>
                    <td>
                      <p>
                        same as stage-1 above with condition persisting for more
                        than 30 minutes
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>chilling_pump_malfunction</td>
                    <td>Chilling pump malfunction</td>
                    <td>critical</td>
                    <td>
                      <p>
                        milk-volume should be greater than 100 <br />
                        chilling pump is off
                        <br />
                        volume variation should not be more than 10
                        <br />
                        milk temperature &gt;= 4.1 (milk is not yet chilled)
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>chilling_pump_malfunction_stage_2</td>
                    <td>Chilling pump malfunction stage-2</td>
                    <td>critical</td>
                    <td>
                      <p>
                        same as stage-1 above with condition persisting for more
                        than 30 minutes
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>chilling_pump_malfunction_stage_3</td>
                    <td>Chilling pump malfunction stage-3</td>
                    <td>critical</td>
                    <td>
                      <p>
                        same as stage-1 above with condition persisting for more
                        than 60 minutes
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>compressor_malfunction</td>
                    <td>Compressor malfunction</td>
                    <td>critical</td>
                    <td>
                      <p>
                        <strong>
                          Compressor turned off when chilling is not yet
                          complete
                        </strong>
                        <br />
                        <br />
                        milk-volume should be greater than 100 <br />
                        volume variation should not be more than 10 <br />
                        compressor is off <br />
                        battery temperature is greater than -4 degrees
                        <br />
                        Grid is on
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>compressor_malfunction_stage_2</td>
                    <td>Compressor malfunction stage-2</td>
                    <td>critical</td>
                    <td>
                      <p>
                        milk-volume should be greater than 100
                        <br />
                        volume variation should not be more than 10
                        <br />
                        compressor is off <br />
                        battery temperature is greater than -4 degrees for more
                        than <strong>10 mins</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>compressor_malfunction_stage_3</td>
                    <td>Compressor malfunction stage-3</td>
                    <td>critical</td>
                    <td>
                      <p>
                        milk-volume should be greater than 100
                        <br />
                        volume variation should not be more than 10
                        <br />
                        compressor is off <br />
                        battery temperature is greater than -4 degrees for more
                        than <strong>30 mins</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>condenser_malfunction</td>
                    <td>Condenser malfunction</td>
                    <td>critical</td>
                    <td>
                      <p>
                        milk-volume should be greater than 100
                        <br />
                        volume variation should not be more than 10
                        <br />
                        condenser is off <br />
                        battery temperature is greater than -4 degrees
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>condenser_malfunction_stage_2</td>
                    <td>Condenser malfunction stage-2</td>
                    <td>critical</td>
                    <td>
                      <p>
                        same as stage-1 above with condition persisting for more
                        than 30 minutes
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>condenser_malfunction_stage_3</td>
                    <td>Condenser malfunction stage-3</td>
                    <td>critical</td>
                    <td>
                      <p>
                        same as stage-1 above with condition persisting for more
                        than 60 minutes
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>pump2_malfunction</td>
                    <td>Pump2 malfunction</td>
                    <td>critical</td>
                    <td>
                      <p>
                        <strong>
                          Detect charging pump(pump2) not running when battery
                          temperature is high
                        </strong>
                        <br />
                        <br />
                        milk volume should be greater than 100
                        <br />
                        grid is on
                        <br />
                        charging pump(pump2) is off
                        <br />
                        volume variation should not be more than 10
                        <br />
                        battery temperature is greater than -4 degrees
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>pump2_malfunction_stage_2</td>
                    <td>Pump2 malfunction stage-2</td>
                    <td>critical</td>
                    <td>
                      <p>
                        same as stage-1 above with condition persisting for more
                        than 30 minutes
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>pump2_malfunction_stage_3</td>
                    <td>Pump2 malfunction stage-3</td>
                    <td>critical</td>
                    <td>
                      <p>
                        same as stage-1 above with condition persisting for more
                        than 60 minutes
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>grid_off_stage_2</td>
                    <td>Grid off stage-2</td>
                    <td>critical</td>
                    <td>
                      <p>
                        milk volume should be greater than 100
                        <br />
                        grid is off for more than 15 mins
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>grid_off_stage_3</td>
                    <td>Grid off stage-3</td>
                    <td>critical</td>
                    <td>
                      <p>
                        milk volume should be greater than 100
                        <br />
                        grid is off for more than 30 mins
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>milk_lifted_undercooled</td>
                    <td>Milk lifted undercooled</td>
                    <td>critical</td>
                    <td>
                      <p>
                        milk lifted with temperature greater than 4.1 degrees
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>milk_undercooled</td>
                    <td>Milk undercooled</td>
                    <td>critical</td>
                    <td>
                      <p>
                        <strong>
                          Detect issue with rate of temperature decrease
                        </strong>
                        <br />
                        <br />
                        milk volume should be greater than 100
                        <br />
                        milk temperature greater than 4.1
                        <br />
                        volume variation should not be more than 0.5 kg
                        <br />
                        In a 15 minutes window, rate of temperature decrease
                        less than 0.6 degrees(when milk temperature is greater
                        than 10)
                        <br />
                        In a 15 minutes window, rate of temperature decrease
                        less than 0.3 degrees(when milk temperature is less than
                        10)
                        <br />
                        Alert occurrence is limited to max one per hour.
                        <br />
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>milk_undercooled_stage_2</td>
                    <td>Milk undercooled stage-2</td>
                    <td>critical</td>
                    <td>
                      <p>
                        same as stage-1 above with time window as 20 minutes
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>milk_undercooled_stage_3</td>
                    <td>Milk undercooled stage-3</td>
                    <td>critical</td>
                    <td>
                      <p>
                        same as stage-1 above with time window as 40 minutes
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>agitator_malfunction_after_chilling</td>
                    <td>Agitator malfunction after chilling</td>
                    <td>critical</td>
                    <td>
                      <p>
                        <strong>
                          Detect malfunction in agitator after chilling. The
                          agitator is supposed to run for 2 minutes after every
                          13 minutes
                        </strong>
                        <br />
                        <br />
                        milk volume should be greater than 100
                        <br />
                        chilling pump is off
                        <br />
                        milk temperature less than 4.1 degrees
                        <br />
                        agitator run duration less than 110 seconds or greater
                        than 130 seconds
                        <br />
                        OR
                        <br />
                        agitator off duration less than 770 seconds (&lt;13
                        mins) or 790 seconds.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>milk_overchilled</td>
                    <td>Milk overchilled</td>
                    <td>critical</td>
                    <td>
                      <p>
                        milk volume should be greater than 100
                        <br />
                        chilling pump is on
                        <br />
                        milk temperature less than 3 degrees
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>cip_not_done</td>
                    <td>CIP not done</td>
                    <td>critical</td>
                    <td>
                      <p>
                        <strong>Detect CIP activity not done</strong>
                        <br />
                        <br />
                        milk volume should be greater than 100
                        <br />
                        then milk volume should become less than 100
                        <br />
                        followed by milk volume should be less than 50
                        <br />
                        followed by milk volume should be less than 10 within 30
                        mins of previous event
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>panel_trip</td>
                    <td>Panel trip</td>
                    <td>critical</td>
                    <td>
                      <p>
                        <strong>
                          Detect promethean panel getting tripped during the
                          chilling process
                        </strong>
                        <br />
                        <br />
                        milk volume should be greater than 100
                        <br />
                        milk temperature greater than 4.1
                        <br />
                        grid is on
                        <br />
                        chillingpump, compressor and agitator are off
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>panel_trip_stage_2</td>
                    <td>Panel trip stage-2</td>
                    <td>critical</td>
                    <td>
                      <p>same as stage-1 above</p>>
                    </td>
                  </tr>
                  <tr>
                    <td>tss_temp_alert</td>
                    <td>TSS Temperature</td>
                    <td>critical</td>
                    <td>
                      <p>
                        Scheduled cron alert at 5:30 AM and 3:30 PM if battery
                        temp is greater than -4 at that point in time. ABC
                        in-charge should should get alert for the ABC to which
                        he is associated
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>tss_temp_alert_stage_2</td>
                    <td>TSS Temperature stage-2</td>
                    <td>critical</td>
                    <td>
                      <p>
                        same as stage-1
                        <br />
                        CC IC/Maintenance/RDE role users should get alert for
                        all ABCs to which they are associated in one message
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}