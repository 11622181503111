import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import PageHeader from "components/layout/PageHeader.js";
import { provisioningService } from "../../../services/provisioningServices";
import "./RatingTemplate.scss";
import commonForm from "components/common/form/common-form";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { BorderBottom } from "@material-ui/icons";
import { analyticServices } from "services/analytic Services";
import FailureFeedback from "../../../components/common/elements/FailureFeedback";

const ColorButton = withStyles(theme => ({
  root: {
    backgroundImage: "linear-gradient(to bottom, #34409A, #14AAE4)",
    width: "150px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "linear-gradient(to bottom, #34409A, #14AAE4)"
    }
  }
}))(Button);

function ratingTemplate() {
  const { t } = useTranslation();
  const [templateData, setTemplateData] = useState("");
  const [firstChildRating, setFirstChildRating] = useState(true);
  const [secondChildRating, setSecondChildRating] = useState(true);
  const [thirdChildRating, setThirdChildRating] = useState(true);
  const [firstLevelErrorIndex, setFirstLevelErrorIndex] = useState("");
  const [secondLevelErrorIndex, setSecondLevelErrorIndex] = useState("");
  const [thirdLevelErrorIndex, setThirdLevelErrorIndex] = useState("");

  const [apiError, setError] = useState(null);
  const [status, setStatus] = useState("idle");

  useEffect(() => {
    const orgName = provisioningService.getOrgName();
    analyticServices.getAttributes(orgName).then(data => {
      setTemplateData(data);
    });
  }, []);

  const handleDialogClose = () => {
    setStatus("idle");
  };

  // const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#0088FE', '#00C49F'];
  const colors = ["#ECF4FC", "#e6ecf9", "#fff7f1", "#fef4ec"];
  let foundSpan = "";
  function handleChange(
    value,
    attributes,
    upperLevelattributes,
    level,
    firstLevelIndex,
    secondLevelIndex,
    ThirdLevelIndex
  ) {
    let totalRating = 0;
    attributes.node.weightage = value.replace(/[^0-9]/g, "");
    upperLevelattributes.children.map(attributes => {
      totalRating += parseInt(attributes.node.weightage);
    });

    if (totalRating !== 100) {
      if (level === 1) {
        setFirstChildRating(false);
        setFirstLevelErrorIndex(firstLevelIndex);
        setSecondLevelErrorIndex("");
        setThirdLevelErrorIndex("");
      } else if (level === 2) {
        setSecondChildRating(false);
        setFirstLevelErrorIndex(firstLevelIndex);
        setSecondLevelErrorIndex(secondLevelIndex);
        setThirdLevelErrorIndex("");
      } else {
        setThirdChildRating(false);
        setFirstLevelErrorIndex(firstLevelIndex);
        setSecondLevelErrorIndex(secondLevelIndex);
        setThirdLevelErrorIndex(ThirdLevelIndex);
      }
    } else if (totalRating === 100) {
      level === 1
        ? setFirstChildRating(true)
        : level === 2
          ? setSecondChildRating(true)
          : setThirdChildRating(true);
      setFirstLevelErrorIndex("");
      setSecondLevelErrorIndex("");
      setThirdLevelErrorIndex("");
    }
  }

  function findSpan(attributes) {
    let span = "";

    if (attributes.children.length > 0) span = attributes.children.length;

    attributes.children.length > 0 &&
      attributes.children.map(attributes => {
        if (attributes.children && attributes.children.length > 0)
          span += attributes.children.length;

        attributes.children &&
          attributes.children.length > 0 &&
          attributes.children.map(attributes => {
            if (attributes.children && attributes.children.length > 0)
              span += attributes.children.length;
          });
      });
    return (foundSpan = span + 1);
  }

  async function handleSave() {
    try {
      let apiCall = true;
      let firstLevelCount = 0;
      templateData.children.length > 0 && templateData.children.map(
        (firstLevel, firstLevelIndex) => {
          firstLevelCount += parseInt(firstLevel.node.weightage);
          
          let secondLevelCount = 0;
          firstLevel.children.length > 0 && firstLevel.children.map(
            (secondLevel, secondLevelIndex) => {
              secondLevelCount += parseInt(secondLevel.node.weightage);

              let thirdLevelCount = 0;
              secondLevel.children.length && secondLevel.children.map(
                (thirdLevel, thirdLevelIndex) => {
                  thirdLevelCount += parseInt(thirdLevel.node.weightage);
                }
              )
              if (thirdLevelCount !== 100) {
                setThirdChildRating(false);
                setFirstLevelErrorIndex(firstLevelIndex);
                setSecondLevelErrorIndex(secondLevelIndex);
                apiCall=false;
              }
            }
          )
          if (secondLevelCount !== 100) {
            setSecondChildRating(false);
            setFirstLevelErrorIndex(firstLevelIndex);
            apiCall=false;
          }

        }
      )
      if (firstLevelCount !== 100) {
        setFirstChildRating(false);
        setFirstLevelErrorIndex(0);
        apiCall=false;
      }

      const data = apiCall ?
       await analyticServices.updateAttributes(templateData) : null;
    } catch (e) {
      setError(e.error);
      setStatus("error");
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("analytics_Rating_Template_Header_Label")}
          breadcrumbs={[
            {
              label: t("analytics_Rating_Template_Breadcrum_Dashboard_Label"),
              link: "/dashboard"
            },
            { label: t("analytics_Rating_Template_Breadcrum_Analytics_Label") },
            {
              label: t(
                "analytics_Rating_Template_Breadcrum_Rating_Template_Label"
              )
            }
          ]}
        />
      </Grid>

      {templateData.node && (
        <table className="table table-bordered table-vertical table hover">
          <thead>
            <tr>
              <th style={{ verticalAlign: "middle" }}>Attribute</th>
              <th style={{ verticalAlign: "middle" }}>Total Value</th>

              <th style={{ verticalAlign: "middle" }}>Level-1 Attributes</th>
              <th style={{ verticalAlign: "middle" }}>
                Value Breakup
                <br />
                <small>(Sum should get 100%)</small>
              </th>

              <th style={{ verticalAlign: "middle" }}>Level-2 Attributes</th>
              <th style={{ verticalAlign: "middle" }}>
                Value Breakup
                <br />
                <small>(Sum should get 100%)</small>
              </th>

              <th style={{ verticalAlign: "middle" }}>Level-3 Attributes</th>
              <th style={{ verticalAlign: "middle" }}>
                Value Breakup
                <br />
                <small>(Sum should get 100%)</small>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                rowSpan={findSpan(templateData)}
                style={{ verticalAlign: "middle" }}
              >
                {templateData.node.attributeName}
              </td>
              <td
                rowSpan={findSpan(templateData)}
                style={{ verticalAlign: "middle" }}
              >
                <input
                  type=""
                  placeholder="100%"
                  size="4"
                  value="100 %"
                  disabled={true}
                />
              </td>
            </tr>

            {templateData.children.length > 0 &&
              templateData.children.map(
                (attributesFirstLevel, indexFirstLevel) => {
                  return (
                    <>
                      <tr
                        style={{
                          backgroundColor:
                            indexFirstLevel % 2 === 0 ? "#ECF4FC" : "#fff7f1"
                        }}
                      >
                        <td
                          rowSpan={findSpan(attributesFirstLevel)}
                          style={{ verticalAlign: "middle" }}
                        >
                          {attributesFirstLevel.node.attributeName}
                        </td>
                        <td
                          rowSpan={findSpan(attributesFirstLevel)}
                          style={{ verticalAlign: "middle" }}
                        >
                          <div>
                            <input
                              size="4"
                              type=""
                              onChange={e =>
                                handleChange(
                                  e.target.value,
                                  attributesFirstLevel,
                                  templateData,
                                  1,
                                  indexFirstLevel
                                )
                              }
                              defaultValue={
                                attributesFirstLevel.node.weightage + " %"
                              }
                            />
                            {firstChildRating === false ? (
                              <span
                                style={{
                                  color: "red",
                                  display: "inline-block"
                                }}
                              >
                                <small>
                                  Sum of the higlighted values should be 100
                                </small>
                              </span>
                            ) : (
                                ""
                              )}
                          </div>
                        </td>
                      </tr>

                      {attributesFirstLevel.children.length > 0 &&
                        attributesFirstLevel.children.map(
                          (attributesSecondLevel, indexSecondLevel) => {
                            return (
                              <>
                                <tr
                                  style={{
                                    backgroundColor:
                                      indexFirstLevel % 2 === 0
                                        ? indexSecondLevel % 2 === 0
                                          ? "#ECF4FC"
                                          : "#e6ecf9"
                                        : indexSecondLevel % 2 != 0
                                          ? "#fef4ec"
                                          : "#fff7f1"
                                  }}
                                >
                                  <td
                                    rowSpan={findSpan(attributesSecondLevel)}
                                    style={{ verticalAlign: "middle" }}
                                  >
                                    {attributesSecondLevel.node.attributeName}
                                  </td>
                                  <td
                                    rowSpan={findSpan(attributesSecondLevel)}
                                    style={{ verticalAlign: "middle" }}
                                  >
                                    <div>
                                      <input
                                        size="4"
                                        type=""
                                        onChange={e =>
                                          handleChange(
                                            e.target.value,
                                            attributesSecondLevel,
                                            attributesFirstLevel,
                                            2,
                                            indexFirstLevel,
                                            indexSecondLevel
                                          )
                                        }
                                        defaultValue={
                                          attributesSecondLevel.node.weightage +
                                          " %"
                                        }
                                      />
                                      {secondChildRating === false &&
                                        firstLevelErrorIndex ===
                                        indexFirstLevel ? (
                                          <span
                                            style={{
                                              color: "red",
                                              display: "inline-block"
                                            }}
                                          >
                                            <small>
                                              Sum of the higlighted values should
                                              be 100
                                          </small>
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                    </div>
                                  </td>
                                </tr>
                                {attributesSecondLevel.children.length > 0 &&
                                  attributesSecondLevel.children.map(
                                    (attributesThirdLevel, indexThirdLevel) => {
                                      return (
                                        <>
                                          <tr
                                            style={{
                                              backgroundColor:
                                                indexFirstLevel % 2 === 0
                                                  ? indexSecondLevel % 2 === 0
                                                    ? "#ECF4FC"
                                                    : "#e6ecf9"
                                                  : indexSecondLevel % 2 != 0
                                                    ? "#fef4ec"
                                                    : "#fff7f1"
                                            }}
                                          >
                                            <td
                                              style={{
                                                verticalAlign: "middle"
                                              }}
                                            >
                                              {
                                                attributesThirdLevel.node
                                                  .attributeName
                                              }
                                            </td>
                                            <td
                                              style={{
                                                verticalAlign: "middle"
                                              }}
                                            >
                                              <div>
                                                <input
                                                  size="4"
                                                  type=""
                                                  onChange={e =>
                                                    handleChange(
                                                      e.target.value,
                                                      attributesThirdLevel,
                                                      attributesSecondLevel,
                                                      3,
                                                      indexFirstLevel,
                                                      indexSecondLevel,
                                                      indexThirdLevel
                                                    )
                                                  }
                                                  defaultValue={
                                                    attributesThirdLevel.node
                                                      .weightage + " %"
                                                  }
                                                />
                                                {thirdChildRating === false &&
                                                  firstLevelErrorIndex ===
                                                  indexFirstLevel &&
                                                  secondLevelErrorIndex ===
                                                  indexSecondLevel ? (
                                                    <span
                                                      style={{
                                                        color: "red",
                                                        display: "inline-block"
                                                      }}
                                                    >
                                                      <small>
                                                        Sum of the higlighted
                                                        values should be 100
                                                    </small>
                                                    </span>
                                                  ) : (
                                                    ""
                                                  )}
                                              </div>
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    }
                                  )}
                              </>
                            );
                          }
                        )}
                    </>
                  );
                }
              )}
          </tbody>
        </table>
      )}

      <FailureFeedback
        open={status === "error"}
        onClose={handleDialogClose}
        status={!!apiError && apiError.status}
        message={!!apiError && apiError.message}
      />

      <Grid container justify="flex-end" style={{ padding: "20px" }}>
        <ColorButton
          variant="contained"
          color="primary"
          onClick={handleSave}
        //   className={className}
        //   type={!!type ? type : undefined}
        >
          {"Save"}
        </ColorButton>
      </Grid>
    </Grid>
  );
}

export default ratingTemplate;
