import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { getIn } from "formik";
import PageHeader from "components/layout/PageHeader";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
import CommonForm from "components/common/form/common-form";
import FailureFeedback from "components/common/elements/FailureFeedback";
import LoanProductName from "components/common/form/LoanProductName";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import "components/responsive.scss";
import "../configuration.scss";
import "../../../components/responsive.scss";
import ApproveProductLangList from "components/common/form/ApproveProductLangList";

import { useTranslation } from "react-i18next";
import { string } from "prop-types";
import addDays from "date-fns/addDays";

export default function ApproveForm({values,edit,language,status, handleSubmit,showSubmitButton,statusMessage}){
  const { t } = useTranslation();
  const formikForm = React.useRef(null);

  let breadcrumbs = [
    {
      label: t("notification_Form_Breadcrum_Dashboard_Label"),
      link: "/dashboard"
    },
    { label: t("notification_Form_Breadcrum_Configuration_Label") },
    { label: t("notification_Form_Breadcrum_Notification_Label") }
  ];

  const statusSelect = ["PENDING","APPROVED","REJECTED"]; 

  const fields = [
    // {
    //   type: "text",
    //   name: "amount",
    //   label: t("approve_Product_Form_amount_Label"),
    //   placeholder: t("approve_Product_Form_amount_Label"),
    //   disabled:true,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },
    // {
    //   type: "text",
    //   name: "barCode",
    //   label: t("approve_Product_Form_barCode_Label"),
    //   placeholder: t("approve_Product_Form_barCode_Label"),
    //   disabled:true,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },
    // {
    //   type: "text",
    //   name: "batch",
    //   label: t("approve_Product_Form_batch_Label"),
    //   placeholder: t("approve_Product_Form_batch_Label"),
    //   disabled:true,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },
    // {
    //   type: "text",
    //   name: "benefits",
    //   label: t("approve_Product_Form_benefits_Label"),
    //   placeholder: t("approve_Product_Form_benefits_Label"),
    //   disabled:true,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },
    // {
    //   type: "text",
    //   name: "brand",
    //   label: t("approve_Product_Form_brand_Label"),
    //   placeholder: t("approve_Product_Form_brand_Label"),
    //   disabled:true,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },
    {
      type: "text",
      name: "bulkBuyAllowed",
      label: t("approve_Product_Form_bulkBuyAllowed_Label"),
      placeholder: t("approve_Product_Form_bulkBuyAllowed_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    // {
    //   type: "text",
    //   name: "buyingPrice",
    //   label: t("approve_Product_Form_buyingPrice_Label"),
    //   placeholder: t("approve_Product_Form_buyingPrice_Label"),
    //   disabled:true,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },
    {
      type: "text",
      name: "category",
      label: t("approve_Product_Form_category_Label"),
      placeholder: t("approve_Product_Form_category_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    // {
    //   type: "text",
    //   name: "categoryDisplayName",
    //   label: t("approve_Product_Form_categoryDisplayName_Label"),
    //   placeholder: t("approve_Product_Form_categoryDisplayName_Label"),
    //   disabled:true,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },
    {
      type: "text",
      name: "categoryTree",
      label: t("approve_Product_Form_categoryTree_Label"),
      placeholder: t("approve_Product_Form_categoryTree_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    // {
    //   type: "datePicker",
    //   name: "createdDate",
    //   label: t("approve_Product_Form_createdDate_Label"),
    //   placeholder: t("approve_Product_Form_createdDate_Label"),
    //   disabled:true,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },
    {
      type: "text",
      name: "description",
      label: t("approve_Product_Form_description_Label"),
      placeholder: t("approve_Product_Form_description_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    // {
    //   type: "datePicker",
    //   name: "expiryDate",
    //   label: t("approve_Product_Form_expiryDate_Label"),
    //   placeholder: t("approve_Product_Form_expiryDate_Label"),
    //   disabled:true,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },
    {
      type: "text",
      name: "fetchTag",
      label: t("approve_Product_Form_fetchTag_Label"),
      placeholder: t("approve_Product_Form_fetchTag_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "gstPercentage",
      label: t("approve_Product_Form_gstPercentage_Label"),
      placeholder: t("approve_Product_Form_gstPercentage_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "howToUse",
      label: t("approve_Product_Form_howToUse_Label"),
      placeholder: t("approve_Product_Form_howToUse_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    // {
    //   type: "text",
    //   name: "hsn",
    //   label: t("approve_Product_Form_hsn_Label"),
    //   placeholder: t("approve_Product_Form_hsn_Label"),
    //         disabled:true,
    //         grid: {
    //           xs: 12,
    //           sm: 3,
    //           className: "mb-2"
    //         }
    //     },
    {
            type: "text",
            name: "id",
            label: t("approve_Product_Form_id_Label"),
            placeholder: t("approve_Product_Form_id_Label"),
            disabled:true,
            grid: {
              xs: 12,
              sm: 3,
              className: "mb-2"
            }
        },
        // {
        //     type: "text",
        //     name: "image",
        //     label: t("approve_Product_Form_image_Label"),
        //     placeholder: t("approve_Product_Form_image_Label"),
        //     disabled:true,
        //     grid: {
        //       xs: 12,
        //       sm: 3,
        //       className: "mb-2"
        //     }
        // },
        {
            type: "text",
            name: "imageURL",
            label: t("approve_Product_Form_imageURL_Label"),
            placeholder: t("approve_Product_Form_imageURL_Label"),
            disabled:true,
            grid: {
              xs: 12,
              sm: 3,
              className: "mb-2"
            }
        },
        {
            type: "text",
            name: "imageUrls",
            label: t("approve_Product_Form_imageUrls_Label"),
            placeholder: t("approve_Product_Form_imageUrls_Label"),
            disabled:true,
            grid: {
              xs: 12,
              sm: 3,
              className: "mb-2"
            }
        },
        // {
        //     type: "text",
        //     name: "initialSize",
        //     label: t("approve_Product_Form_initialSize_Label"),
        //     placeholder: t("approve_Product_Form_initialSize_Label"),
        //     disabled:true,
        //     grid: {
        //       xs: 12,
        //       sm: 3,
        //       className: "mb-2"
        //     }
        // },
        {
            type: "text",
            name: "isActive",
            label: t("approve_Product_Form_isActive_Label"),
            placeholder: t("approve_Product_Form_isActive_Label"),
            disabled:true,
            grid: {
              xs: 12,
              sm: 3,
              className: "mb-2"
            }
        },
        {
            type: "text",
            name: "isProductEligible",
            label: t("approve_Product_Form_isProductEligible_Label"),
            placeholder: t("approve_Product_Form_isProductEligible_Label"),
            disabled:true,
            grid: {
              xs: 12,
              sm: 3,
              className: "mb-2"
            }
        },
        // {
        //     type: "text",
        //     name: "itemCode",
        //     label: t("approve_Product_Form_itemCode_Label"),
        //     placeholder: t("approve_Product_Form_itemCode_Label"),
        //     disabled:true,
        //     grid: {
        //       xs: 12,
        //       sm: 3,
        //       className: "mb-2"
        //     }
        // },
        // {
        //     type: "text",
        //     name: "itemGroup",
        //     label: t("approve_Product_Form_itemGroup_Label"),
        //     placeholder: t("approve_Product_Form_itemGroup_Label"),
        //     disabled:true,
        //     grid: {
        //       xs: 12,
        //       sm: 3,
        //       className: "mb-2"
        //     }
        // },
        // {
        //     type: "text",
        //     name: "itemName",
        //     label: t("approve_Product_Form_itemName_Label"),
        //     placeholder: t("approve_Product_Form_itemName_Label"),
        //     disabled:true,
        //     grid: {
        //       xs: 12,
        //       sm: 3,
        //       className: "mb-2"
        //     }
        // },
        // {
        //     type: "text",
        //     name: "languages",
        //     label: t("approve_Product_Form_languages_Label"),
        //     placeholder: t("approve_Product_Form_languages_Label"),
        //     disabled:true,
        //     grid: {
        //       xs: 12,
        //       sm: 3,
        //       className: "mb-2"
        //     }
        // },
        {
            type: "text",
            name: "licenses",
            label: t("approve_Product_Form_licenses_Label"),
            placeholder: t("approve_Product_Form_licenses_Label"),
            disabled:true,
            grid: {
              xs: 12,
              sm: 3,
              className: "mb-2"
            }
        },
        // {
        //     type: "datePicker",
        //     name: "manufacturingDate",
        //     label: t("approve_Product_Form_manufacturingDate_Label"),
        //     placeholder: t("approve_Product_Form_manufacturingDate_Label"),
        //     disabled:true,
        //     grid: {
        //       xs: 12,
        //       sm: 3,
        //       className: "mb-2"
        //     }
        // },
        {
            type: "text",
            name: "maxBulkQtyAllowed",
            label: t("approve_Product_Form_maxBulkQtyAllowed_Label"),
            placeholder: t("approve_Product_Form_maxBulkQtyAllowed_Label"),
            disabled:true,
            grid: {
              xs: 12,
              sm: 3,
              className: "mb-2"
            }
        },
        {
            type: "text",
            name: "maxQtyAllowed",
            label: t("approve_Product_Form_maxQtyAllowed_Label"),
            placeholder: t("approve_Product_Form_maxQtyAllowed_Label"),
            disabled:true,
            grid: {
              xs: 12,
              sm: 3,
              className: "mb-2"
            }
        },
        // {
        //     type: "text",
        //     name: "method",
        //     label: t("approve_Product_Form_method_Label"),
        //     placeholder: t("approve_Product_Form_method_Label"),
        //     disabled:true,
        //     grid: {
        //       xs: 12,
        //       sm: 3,
        //       className: "mb-2"
        //     }
        // },
        {
            type: "text",
            name: "minBulkQtyAllowed",
            label: t("approve_Product_Form_minBulkQtyAllowed_Label"),
            placeholder: t("approve_Product_Form_minBulkQtyAllowed_Label"),
            disabled:true,
            grid: {
              xs: 12,
              sm: 3,
              className: "mb-2"
            }
        },
        {
            type: "text",
            name: "minQtyAllowed",
            label: t("approve_Product_Form_minQtyAllowed_Label"),
            placeholder: t("approve_Product_Form_minQtyAllowed_Label"),
            disabled:true,
            grid: {
              xs: 12,
              sm: 3,
              className: "mb-2"
            }
        },
        {
            type: "text",
            name: "omsReferenceId",
            label: t("approve_Product_Form_omsReferenceId_Label"),
            placeholder: t("approve_Product_Form_omsReferenceId_Label"),
            disabled:true,
            grid: {
              xs: 12,
              sm: 3,
              className: "mb-2"
            }
        },
        {
            type: "text",
            name: "price",
            label: t("approve_Product_Form_price_Label"),
            placeholder: t("approve_Product_Form_price_Label"),
            disabled:true,
            grid: {
              xs: 12,
              sm: 3,
              className: "mb-2"
            }
        },
        {
            type: "text",
            name: "priority",
            label: t("approve_Product_Form_priority_Label"),
            placeholder: t("approve_Product_Form_priority_Label"),
            disabled:true,
            grid: {
              xs: 12,
              sm: 3,
              className: "mb-2"
            }
        },
        {
            type: "text",
            name: "productCode",
            label: t("approve_Product_Form_productCode_Label"),
            placeholder: t("approve_Product_Form_productCode_Label"),
            disabled:true,
            grid: {
              xs: 12,
              sm: 3,
              className: "mb-2"
            }
        },
        // {
        //     type: "text",
        //     name: "productDescriptionDisplayName",
        //     label: t("approve_Product_Form_productDescriptionDisplayName_Label"),
        //     placeholder: t("approve_Product_Form_productDescriptionDisplayName_Label"),
        //     disabled:true,
        //     grid: {
        //       xs: 12,
        //       sm: 3,
        //       className: "mb-2"
        //     }
        // },
        // {
        //     type: "text",
        //     name: "productDisplayName",
        //     label: t("approve_Product_Form_productDisplayName_Label"),
        //     placeholder: t("approve_Product_Form_productDisplayName_Label"),
        //     disabled:true,
        //     grid: {
        //       xs: 12,
        //       sm: 3,
        //       className: "mb-2"
        //     }
        // },
        {
            type: "text",
            name: "productGroup",
            label: t("approve_Product_Form_productGroup_Label"),
            placeholder: t("approve_Product_Form_productGroup_Label"),
            disabled:true,
            grid: {
              xs: 12,
              sm: 3,
              className: "mb-2"
            }
        },
        {
            type: "text",
            name: "productName",
            label: t("approve_Product_Form_productName_Label"),
            placeholder: t("approve_Product_Form_productName_Label"),
            disabled:true,
            grid: {
              xs: 12,
              sm: 3,
              className: "mb-2"
            }
        },
        {
            type: "text",
            name: "productType",
            label: t("approve_Product_Form_productType_Label"),
            placeholder: t("approve_Product_Form_productType_Label"),
            disabled:true,
            grid: {
              xs: 12,
              sm: 3,
              className: "mb-2"
            }
        },
        {
            type: "text",
            name: "productUUID",
            label: t("approve_Product_Form_productUUID_Label"),
            placeholder: t("approve_Product_Form_productUUID_Label"),
            disabled:true,
            grid: {
              xs: 12,
              sm: 3,
              className: "mb-2"
            }
        },
        {
            type: "text",
            name: "quantity",
            label: t("approve_Product_Form_quantity_Label"),
            placeholder: t("approve_Product_Form_quantity_Label"),
            disabled:true,
            grid: {
              xs: 12,
              sm: 3,
              className: "mb-2"
            }
        },
        {
            type: "text",
            name: "rating",
            label: t("approve_Product_Form_rating_Label"),
            placeholder: t("approve_Product_Form_rating_Label"),
            disabled:true,
            grid: {
              xs: 12,
              sm: 3,
              className: "mb-2"
            }
        },
        {
            type: "text",
            name: "searchTag",
            label: t("approve_Product_Form_searchTag_Label"),
            placeholder: t("approve_Product_Form_searchTag_Label"),
            disabled:true,
            grid: {
              xs: 12,
              sm: 3,
              className: "mb-2"
            }
        },
        {
            type: "text",
            name: "sellingPrice",
            label: t("approve_Product_Form_sellingPrice_Label"),
            placeholder: t("approve_Product_Form_sellingPrice_Label"),
            disabled:true,
            grid: {
              xs: 12,
              sm: 3,
              className: "mb-2"
            }
        },
        // {
        //     type: "text",
        //     name: "sku",
        //     label: t("approve_Product_Form_sku_Label"),
        //     placeholder: t("approve_Product_Form_sku_Label"),
        //     disabled:true,
        //     grid: {
        //       xs: 12,
        //       sm: 3,
        //       className: "mb-2"
        //     }
        // },
        // {
        //     type: "text",
        //     name: "status",
        //     label: t("approve_Product_Form_status_Label"),
        //     placeholder: t("approve_Product_Form_status_Label"),
        //     disabled:true,
        //     grid: {
        //       xs: 12,
        //       sm: 3,
        //       className: "mb-2"
        //     }
        // },
        {
            type: "text",
            name: "stepCount",
            label: t("approve_Product_Form_stepCount_Label"),
            placeholder: t("approve_Product_Form_stepCount_Label"),
            disabled:true,
            grid: {
              xs: 12,
              sm: 3,
              className: "mb-2"
            }
        },
        // {
        //     type: "text",
        //     name: "stockUom",
        //     label: t("approve_Product_Form_stockUom_Label"),
        //     placeholder: t("approve_Product_Form_stockUom_Label"),
        //     disabled:true,
        //     grid: {
        //       xs: 12,
        //       sm: 3,
        //       className: "mb-2"
        //     }
        // },
        {
            type: "text",
            name: "uom",
            label: t("approve_Product_Form_uom_Label"),
            placeholder: t("approve_Product_Form_uom_Label"),
            disabled:true,
            grid: {
              xs: 12,
              sm: 3,
              className: "mb-2"
            }
        },     
        {
          type: "custom",
          component: ({ values, errors }, renderFields) => (
            <ApproveProductLangList
              name="languageList"
              languageValues={language}
              values={values}
              errors={errors}
              renderFields={renderFields}
            />
          )
        },
        {
          type: "textarea",
          name: "remarks",
          label: t("approve_Form_remarks_Label"),
          placeholder: t("approve_Form_remarks_Label"),
          disabled:!showSubmitButton,
          grid: {
            xs: 12,
            sm: 3,
            className: "mb-2"
          }
        },
        {
          type: "select",
          name: "status",
          label: t("approve_Form_status_Label"),
          placeholder: t("approve_Form_status_Label"),
          disabled:!showSubmitButton,
          option:
          statusSelect.length > 0
              ? statusSelect.map(statusSelect => ({
                  value: statusSelect,
                  label: statusSelect
                }))
              : [],
          grid: {
            xs: 12,
            sm: 3,
            className: "mb-2"
          }
        } 
    ];

    let validations = {
      remarks: Yup.string().required(t("approve_form_remarks_mandatory_message")),
      status: Yup.string().required(t("approve_form_status_mandatory_message"))
    };

    const history = useHistory();
    const handleDialogClose = () => {
      // history.push("/configuration/approve");
      history.push({pathname:"/configuration/approve" ,
        state: { 
          from: "1"
        }
      });
    };

    return (
      <Grid container>
        <Grid item xs={12}>
          <PageHeader
            title="Product"
            breadcrumbs={breadcrumbs}
          />
        </Grid>
        <Grid container>
          <Paper elevation={2} style={{ padding: "2em" }}>
            <Grid item>
              <CommonForm
                fields={fields}
                submitLabel={t("site_Form_Submit_Label")}
                submittingLabel={t("site_Form_Submitting_Label")}
                initialValues={values}
                validationSchema={Yup.object().shape({ ...validations })}
                validateOnBlur={true}
                edit={edit}
                enableReinitialize
                validateOnChange={true}
                onSubmit={(
                  values,
                   { setSubmitting, setFieldError, resetForm, errors }
                  ) => {
                  if (!!errors) {
                    setSubmitting(false);
                  }
                  handleSubmit(values, resetForm, setSubmitting);
                }}
                showSubmitButton={showSubmitButton}
                formikRef={formikForm}
                buttonSize="3"
                buttonPosition="right"
              />
            </Grid>
          </Paper>
        </Grid>
        
        <SuccessFeedback
          open={status === "approvedSuccessfully"}
          onClose={handleDialogClose}
          successMessage={t("approve_Form_Success_Message")}
          createAnotherMesssage={t("approve_Form_Create_Another_Site")}
        />

        <SuccessFeedback
          open={status === "rejectedSuccessfully"}
          onClose={handleDialogClose}
          successMessage={t("approve_Form_Rejected_Message")}
          createAnotherMesssage={t("approve_Form_Create_Another_Site")}
        />

      <FailureFeedback
        open={status === "failed"}
        onClose={handleDialogClose}
        status={"Failed"}
        message={statusMessage}
        showButtons={true}
      /> 

      </Grid>
    )
}
