import React, { useState, useEffect } from "react";
import { FieldArray, Field, getIn } from "formik";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { TextField } from "formik-material-ui";
import { useTranslation } from "react-i18next";

export default function ApproveProductLangList({ name, languageValues, renderFields, elemProp }) {
  
  function handleTypeChange(){

  }

  const { t } = useTranslation();
  return (
    <FieldArray name={name}>
      {({ insert, remove, push, form }) => {
        let {
          values,
          errors,
          touched,
          setFieldTouched,
          setFieldValue,
          handleChange
        } = form;
        return (
          <>
            { values.languageList.length > 0 ? (
              <Grid item xs={12}>
                <h3 style={{ color: "black" }}>{t("language_Selection_Label")}</h3>
              </Grid>
            ):(<></>)}
            {getIn(values, name).length > 0 &&
              getIn(values, name).map((languageList, childIndex) => (               
                <Grid
                  container
                  item
                  xs={12}
                  className="mb-0 d-flex flex-direction-column device-wrapper"
                  key={childIndex}
                >
                    <Grid
                      item
                      container
                      xs={12}
                      spacing={2}
                      alignContent="flex-start"
                      className="site-inner"
                    >
                      <Grid item xs={11} md={3}>
                        <Field
                          select
                          name={`${name}.${childIndex}.language`}
                          label={t("product_Form_language_Label")}
                          component={TextField}
                          margin="dense"
                          fullWidth={true}
                          variant="outlined"
                          disabled
                          inputProps={{
                            onChange: e => {
                              handleChange(e);
                              handleTypeChange(
                                e.target.value,
                                childIndex,
                                setFieldValue,
                                setFieldTouched,
                                values
                              );
                            }
                          }}
                        >
                          <MenuItem disabled value="none">
                            {"Choose"}
                          </MenuItem>
                          {languageValues.map(value => (
                            <MenuItem key={value} value={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      <Grid item xs={11} md={3}>
                        <Field
                          name={`${name}.${childIndex}.product`}
                          label={t("product_Form_product_Label")}
                          className="placeholder-inner"
                          component={TextField}
                          value={`${name}.${childIndex}.product`}
                          margin="dense"
                          fullWidth={true}
                          disabled
                          variant="outlined"
                        ></Field>
                      </Grid>
                      <Grid item xs={11} md={3}>
                        <Field
                          name={`${name}.${childIndex}.category`}
                          label={t("product_Form_category_Label")}
                          className="placeholder-inner"
                          component={TextField}
                          value={`${name}.${childIndex}.category`}
                          margin="dense"
                          fullWidth={true}
                          disabled
                          variant="outlined"
                        ></Field>
                      </Grid>
                      <Grid item xs={11} md={3}>
                        <Field
                          name={`${name}.${childIndex}.benefits`}
                          label={t("product_Form_benefits_Label")}
                          className="placeholder-inner"
                          component={TextField}
                          value={`${name}.${childIndex}.benefits`}
                          margin="dense"
                          fullWidth={true}
                          disabled
                          variant="outlined"
                          size="medium"
                          multiline="true"
                          minRows="5"
                        ></Field>
                      </Grid>
                      <Grid item xs={11} md={3}>
                        <Field
                          name={`${name}.${childIndex}.howToUse`}
                          label={t("product_Form_howToUse_Label")}
                          className="placeholder-inner"
                          component={TextField}
                          value={`${name}.${childIndex}.howToUse`}
                          margin="dense"
                          fullWidth={true}
                          disabled
                          variant="outlined"
                          multiline="true"
                          minRows="5"
                        ></Field>
                      </Grid>
                      <Grid item xs={11} md={3}>
                        <Field
                          name={`${name}.${childIndex}.description`}
                          label={t("product_Form_description_Label")}
                          className="placeholder-inner"
                          component={TextField}
                          value={`${name}.${childIndex}.description`}
                          margin="dense"
                          fullWidth={true}
                          disabled
                          variant="outlined"
                          multiline="true"
                          minRows="5"
                        ></Field>
                      </Grid>
                    </Grid>
                  </Grid>
              ))}
          </>
        );
      }}
    </FieldArray>
  );
}
