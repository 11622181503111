import React, { useEffect, useContext,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { authContext } from "context/authContext";
import { notificationService } from "services/notificationServices";
import { errorContext } from "context/errorContext";
import addDays from "date-fns/addDays";
import { Grid, Paper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation } from 'react-router-dom'; 
import { isAfter } from "date-fns";
import { approveServices } from "services/approveServices";
import FailureFeedback from "components/common/elements/FailureFeedback";
import { useTranslation } from "react-i18next";
import CriteriaDairyFarmerForm from "./CriteriaDairyFarmerForm";
import { farmerAssociationService } from "services/farmerAssociationServices";
import { criteriaService } from "../../../services/criteriaServices.js";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
// import ConfirmPopUp from "components/common/elements/ConfirmPopUp";

export default function EditCriteriaForDairyFarmer() {

    const { dispatch: errorDispatch } = useContext(errorContext);

    const createError = useSelector(state => state.site.toJS().createError);

    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    const editDairyFarmerData = location.state.from;
    const editStatus = "";
    const edit = "";
    const [pbpData, setPbpData] = React.useState([]);
    const [pbpStatus,setPbpStatus] = React.useState("loading");
    const [status,setStatus] = React.useState(false);
    const [failedStatus,setFailedStatus] = React.useState(false);
    const [openConfirmDialog,setOpenConfirmDialog] = React.useState(false);

    useEffect(() => {
      getListData();
    }, []);

    function getListData(){
      farmerAssociationService.getPbpList().then(data => {    
        setPbpData(data);
        setPbpStatus("success");
      }).catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
    }

    const values = {
      criteriaId: !!editDairyFarmerData ? editDairyFarmerData.id : "",
      criteriaTags: !!editDairyFarmerData ? editDairyFarmerData.criteriaTags : "",
      entityId: !!editDairyFarmerData ? editDairyFarmerData.entityId : "",
      pbpId: !!editDairyFarmerData ? editDairyFarmerData.pbpId : "",
      customerGroup: !!editDairyFarmerData ? editDairyFarmerData.customerGroup : "",
      userType: !!editDairyFarmerData ? editDairyFarmerData.userType : "",
      organizationName: !!editDairyFarmerData && !!editDairyFarmerData.criteria && !!editDairyFarmerData.criteria.organizationBased  ? editDairyFarmerData.criteria.organizationBased[0].organizationName : "",
      chillingCenterName: !!editDairyFarmerData && !!editDairyFarmerData.criteria && !!editDairyFarmerData.criteria.organizationBased  ? editDairyFarmerData.criteria.organizationBased[0].ccName : ""
    }

    // function invokeHandleSubmit(){
    //   const territory = pbpData.find(pbpData =>pbpData.id === values.pbpId);
    //   const entityData = territory.territories.find(territory =>territory.id === values.entityId);
     
    //   editDairyFarmerData.entityName = entityData.name;
      
    //   console.log(handleSubmitData);

    //   criteriaService.editCriteria(values.criteriaId,editDairyFarmerData).then(data => {
    //     console.log(data);
    //     setStatus("success");
    //     // setSubmitting(false);
    //   }).catch(error => {
    //     // errorDispatch({
    //     //   type: "ERROR",
    //     //   error
    //     // });
    //     setStatus("failed");
    //     // setSubmitting(false);
    //   }); 
    //   setOpenConfirmDialog(false);
    // }

    let handleSubmitData = {};
    function handleSubmit(values, resetForm, setSubmitting) {
      //setOpenConfirmDialog(true);
     // setSubmitting(false);

      const territory = pbpData.find(pbpData =>pbpData.id === values.pbpId);
      const entityData = territory.territories.find(territory =>territory.id === values.entityId);
     
      editDairyFarmerData.entityName = entityData.name;
      editDairyFarmerData.entityId = entityData.id;

      criteriaService.editCriteria(values.criteriaId,editDairyFarmerData).then(data => {
        console.log(data);
        setStatus(true);
        setSubmitting(false);
      }).catch(error => {
        setStatus(false);
        setSubmitting(false);
        setFailedStatus(true);
      }); 
      // setOpenConfirmDialog(false);
    }

    const closeConfirmDialog = () => {
      setOpenConfirmDialog(false);
    }

    const handleDialogClose = () => {
      history.push("/configuration/criteria");
    };

    return (
      <>
      <Grid container>
        <CriteriaDairyFarmerForm
          values={values}
          handleSubmit={handleSubmit}
          editStatus={editStatus}
          edit={edit}
          createError={createError}
          pbpData={pbpData}
          pbpStatus={pbpStatus}
        />
      </Grid>

      {/* <SuccessFeedback
        open={openConfirmDialog === true}
        onClose={invokeSubmitFunction}
        successMessage={t("notification_Form_Edit_Success_Message")}
        createAnotherMesssage={t("site_Form_Create_Another_Site")}
      /> */}

      {/* <ConfirmPopUp
        open = {openConfirmDialog}
        onSubmit={invokeHandleSubmit}
        onClose ={closeConfirmDialog}
        successMessage={t("criteria_Form_Confirm_Edit_Message")}
      /> */}

      <SuccessFeedback
        open={status === true}
        onClose={handleDialogClose}
        successMessage={t("criteria_Form_Edit_Success_Message")}
      />

      <FailureFeedback
        open={failedStatus === true}
        onClose={handleDialogClose}
        status={"Failed"}
        message={t("criteria_Form_Edit_Failed_Message")}
        showButtons={true} />
      </>
    );
};
