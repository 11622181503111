import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ErrorProvider } from "context/errorContext";
import { DashboardProvider } from "context/dashboardContext";
import { AuthProvider } from "context/authContext";
import i18n from "./i18n";
import "./index.scss";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";
// import "assets/styles/bootstrap.min.css";
import MainApp from "./App.router";
import store from "./redux/store";
import SpinningLoader from "./components/common/elements/SpinningLoader";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
const theme = createMuiTheme({
  overrides: {
    // MuiFormLabel: {
    //   root: {
    //     fontSize: 12
    //   }
    // },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: {}
        }
      }
    },
    MuiInputLabel: {
      root: {
        fontSize: 12
      },
      outlined: {},
      shrink: {
        "&&&": {
          transform: "translate(14px,-6px) scale(.9)",
          transformOrigin: "top center"
        }
      }
    },
    MuiTypography: {
      body1: {
        fontSize: 12
      }
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: "rgba(63, 81, 181, 0.08)"
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        fontSize: 12,
        "&$disabled": {
          backgroundColor: "#ddd"
        },
        "&:hover:not($disabled):not($focused) $notchedOutline": {
          borderColor: "#166aab"
        }
      }
    }
  }
});
const eventLogger = (event, error) => {
  // console.log("onKeycloakEvent", event, error);
};

const tokenLogger = tokens => {
  // console.log("onKeycloakTokens", tokens);
};
const rootElement = document.getElementById("root");
ReactDOM.render(
  <Provider store={store}>
    <ReactKeycloakProvider
      authClient={keycloak}
      onEvent={eventLogger}
      onTokens={tokenLogger}
    >
      <AuthProvider>
        <ErrorProvider>
          <DashboardProvider>
            <MuiThemeProvider theme={theme}>
              <React.Suspense fallback={<SpinningLoader />}>
                <MainApp />
              </React.Suspense>
            </MuiThemeProvider>
          </DashboardProvider>
        </ErrorProvider>
      </AuthProvider>
    </ReactKeycloakProvider>
  </Provider>,
  rootElement
);
