import React, { useEffect, useContext, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import "../../../components/Table.scss";
import { provisioningService } from "../../../services/provisioningServices.js";
import { retailerAgentAssociationService } from "services/retailerAgentAssociationServices";
import Table from "components/common/table/DynamicPaginationTable2";
import PageHeader from "components/layout/PageHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import { errorContext } from "context/errorContext";

import { UseTranslation, useTranslation } from "react-i18next";
import * as Yup from "yup";
import CommonForm from "components/common/form/common-form";
import Paper from "@material-ui/core/Paper";

const RetailerAgentList = props => {

    const { t } = useTranslation();
  
    const [retailerStatus, setRetailerStatus] = React.useState("loading");
    const [pageInfo, setPageInfo] = React.useState({});
    const [retailerAgentList, setRetailerAgentList] = React.useState([]);
    const [pageSize, setPageSize] = React.useState(10);
    const [pageIndex, setPageIndex] = React.useState(0);
  
    const { dispatch: errorDispatch } = useContext(errorContext);
  
    const columns = React.useMemo(
      () => [
        {
          Header: t("Retailer_Agent_id_Label"),
          accessor: "id",
        },
        {
          Header: t("Retailer_Agent_Name_Label"),
          accessor: "retailerName"
        },
        {
          Header: t("Retailer_Agent_Mobile_Label"),
          accessor: "mobileNumber"
        },
        {
          Header: t("Retailer_Agent_Role_Name"),
          accessor:"roleName"
        },
        {
          Header: t("Retailer_Agent_Association_Label"),
          Cell: ({ row }) => {
            let link = `/configuration/associate-edit/${row.original.id}/${row.original.retailerName}`;
            return (
              <Link to={{
                pathname: link,
                state: {
                    from: row.original
                }
              }}>
              {/* <Link to={link} > */}
                <IconButton>
                  <i className="fa fa-group" aria-hidden="true"></i>
                </IconButton>
              </Link>
            );
          }
        }
      ],
      []
    );    

    function getRetailerAgentList(){
      setRetailerStatus("loading");  
      retailerAgentAssociationService.getRetailerAgentListData("mooMark").then(data => {
        setRetailerAgentList(data);
        setRetailerStatus("success");
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });     
    }  
  
    useEffect(() => {
      provisioningService.fetchRetailerToken('1313131313').then((res) => {
        const bearerTokenFromUserSignUpService = localStorage.getItem("bearerTokenRetailer");
        getRetailerAgentList();
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });      
    }, []);
  
    const data = React.useMemo(() => [...retailerAgentList]);
  
    const nextPage = () => {
      if (
        retailerStatus !== "loading" &&
        pageInfo.hasNextPage &&
        pageIndex < pageInfo.totalPages
      ) {
        let index = pageIndex;
        setPageIndex(++index);
      }
    };
    const previousPage = () => {
      let index = pageIndex;
      if (index > 0) {
        setPageIndex(--index);
      }
    };

    const dropitems = [
      {
        // item: (
        //   // <Link to="/configuration/agent-create">
        //   //  {t("agent_Add_Label")}
        //   // </Link>
        // )
      }
    ];
  
    return (
      <Grid container>
        <Grid item xs={12}>
          {/* <PageHeader
            title={t("agent_Header_Label")}
            breadcrumbs={[
              { label: t("retailer_Breadcrumb_Dashboard_Label"), link: "/dashboard" },
              { label: t("retailer_Breadcrumb_Config_Label") },
              { label: t("retailer_Breadcrumb_Retailer_Label") }
            ]}
          /> */}
        </Grid>
  
        <Paper
          elevation={2}
          style={{
            padding: "2em",
            width: "100%"
          }}
        >
      
        {retailerStatus === "loading" ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : ( 
            <Table
              columns={columns}
              data={data}
              needDropdown={false}
              dropItems={dropitems}
              fileName={"retailerAgentsList.xls"}
            />
         )} 
  
        </Paper>
      </Grid>
    );
  };
  
  export default RetailerAgentList;
  
