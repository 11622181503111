import React, { useState } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Skeleton from "@material-ui/lab/Skeleton";
import { Field } from "formik";
import { CircularProgress } from "@material-ui/core";
export default function MultiSelect({
  field,
  handleChange,
  value,
  label,
  loading,
  options,
  disabled,
  onChange,
  mandatory
}) {
  //console.log("mandatory:=====------", mandatory);
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);
  const allSelectChange = () => {
    let flag = value.length !== options.length;
    let data = [];
    if (!isChecked && flag) {
      data = options.map(t => t.value);
      handleChange(field, data);
    } else {
      handleChange(field, data);
    }
    !!onChange && onChange(data, handleChange);
  };

  const newValue = result => {
    let filtered = [...value, result];
    if (value.includes(result)) {
      setIsChecked(false);
      filtered = value.filter(v => v !== result);
      handleChange(field, filtered);
    } else {
      handleChange(field, filtered);
    }
    !!onChange && onChange(filtered, handleChange);
  };

  const renderValue = (selectedValue) => {
    // Map the selected value to a different display value
    let mappingLabels=[];
    const mappedValue = options.find((option) => {
       if(selectedValue.includes(option.value)) {
         mappingLabels.push(option.label);
       }
    });
     return mappingLabels.join(", ");
  };

  if(mandatory === "mandatory"){

    return (
      <Field name={field}>
        {({ form: { setFieldValue } }) => (
          <FormControl variant="outlined" fullWidth={true} margin="dense">
            <InputLabel ref={inputLabel} id={field} htmlFor={field} className="mandatoryField">
              {label}
            </InputLabel>
            <Select
              id={field}
              value={value}
              multiple
              fullWidth={true}
              disabled={disabled}
              renderValue={renderValue}
              onChange={e => {
                handleChange(field, e.target.value);
                !!onChange && onChange(value, handleChange);
              }}
              labelWidth={labelWidth}
            >
              {/* <ListItem value="" button onClick={handleSelectAll}>
            <Checkbox checked={selectAll} />
            <ListItemText primary="select all" />
          </ListItem> */}
              {!!loading && (
                <div className="text-center">
                  <CircularProgress />
                </div>
              )}
              {!loading && options.length !== 0 && (
                <>
                  <MenuItem value={-1} key={-1} onClick={allSelectChange}>
                    <Checkbox
                      checked={value.length === options.length}
                      color="primary"
                    />
                    <ListItemText primary={"Select All"} />
                  </MenuItem>
                  {options.map(option => (
                    <MenuItem
                      value={option.value}
                      key={option.value}
                      onClick={() => newValue(option.value)}
                    >
                      <Checkbox
                        checked={value.includes(option.value)}
                        color="primary"
                      />
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))}
                </>
              )}
            </Select>
          </FormControl>
        )}
      </Field>
    );
  
  }else{

  return (
    <Field name={field}>
      {({ form: { setFieldValue } }) => (
        <FormControl variant="outlined" fullWidth={true} margin="dense">
          <InputLabel ref={inputLabel} id={field} htmlFor={field}>
            {label}
          </InputLabel>
          <Select
            id={field}
            value={value}
            multiple
            fullWidth={true}
            disabled={disabled}
            renderValue={renderValue}
            onChange={e => {
              handleChange(field, e.target.value);
              !!onChange && onChange(value, handleChange);
            }}
            labelWidth={labelWidth}
          >
            {/* <ListItem value="" button onClick={handleSelectAll}>
          <Checkbox checked={selectAll} />
          <ListItemText primary="select all" />
        </ListItem> */}
            {!!loading && (
              <div className="text-center">
                <CircularProgress />
              </div>
            )}
            {!loading && options.length !== 0 && (
              <>
                <MenuItem value={-1} key={-1} onClick={allSelectChange}>
                  <Checkbox
                    checked={value.length === options.length}
                    color="primary"
                  />
                  <ListItemText primary={"Select All"} />
                </MenuItem>
                {options.map(option => (
                  <MenuItem
                    value={option.value}
                    key={option.value}
                    onClick={() => newValue(option.value)}
                  >
                    <Checkbox
                      checked={value.includes(option.value)}
                      color="primary"
                    />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </>
            )}
          </Select>
        </FormControl>
      )}
    </Field>
  );

}

}
