import { func } from "prop-types";
import { utilityServices } from "./utilityServices";

function getToken() {
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const token = user && user.jwtAccessToken;
  return token;
}

async function getAllCategories() {
  const url = process.env.REACT_APP_GET_ALL_CATEGORIES;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`
    } 
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

export const categoryService = {
    getAllCategories
};
