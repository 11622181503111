import React from 'react';
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import 'bootstrap/dist/css/bootstrap.css';
import { Paper } from '@material-ui/core';
import PageHeader from "components/layout/PageHeader";
import ExcelIcon from "../../../components/common/elements/ExcelIcon";
import { WarehouseImportService } from "../../../services/warehouse-import";
import SweetAlert from 'react-bootstrap-sweetalert';
//import importWarehouseTemplate from "components/Files/importWarehouseTemplate.xlsx";
import { provisioningService } from "services/provisioningServices";
import './importWarehouse.css';
import { useEffect, useContext } from 'react';
import { errorContext } from "context/errorContext";


const ColorButton = withStyles(theme => ({
    root: {
        backgroundImage: "linear-gradient(to bottom, #34409A, #14AAE4)",
        width: "100%",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "linear-gradient(to bottom, #34409A, #14AAE4)"
        }
    }
}))(Button);

const ImportWarehouse = () => {
    const importWarehouseTemplate = "https://adminportal.smartmoo.com/resourceservice/images/warehouseAssociationExcel/importWarehouseTemplate.xlsx";
    const warehouseImportService = new WarehouseImportService();
    const [showUploadSuccessAlert, setShowUploadSuccessAlert] = React.useState(false);
    const [showUploadFailureAlert, setShowUploadFailureAlert] = React.useState(false);
    const [showImportFailedRecordsAlert, setShowImportFailedRecordsAlert] = React.useState(false);
    const [showImportAllRecordsProcessedAlert, setShowImportAllRecordsProcessedAlert] = React.useState(false);
    const [failedRecordsData ,setFailedRecordsData] = React.useState([]);
    const [hideProcessingElem, setHideProcessingElem] = React.useState(true);
    const [disableOkBtn, setDisableOkBtn] = React.useState(true);
    const [showUploadPartialSuccessAlert, setShowUploadPartialSuccessAlert] = React.useState(false);

    const [warehouseImport, setWarehouseImport] = React.useState("");
    const [warehouseExcel, setWarehouseExcel] = React.useState("");
    const [warehouseFilename, setWarehouseFilename] = React.useState("");
    const user = JSON.parse(localStorage.getItem("currentUser"));

    const { dispatch: errorDispatch } = useContext(errorContext);

    const resetHandler = (event) => {
        setWarehouseImport("");   
        setWarehouseFilename("");
    }
    
    const hideAlert = (event) => {
        setShowUploadFailureAlert(false);
        setShowUploadSuccessAlert(false);
        setShowImportFailedRecordsAlert(false);
        setShowImportAllRecordsProcessedAlert(false);
        setShowUploadPartialSuccessAlert(false);
        resetHandler();
    }

    const previewWarehouse = (event) => {
        setWarehouseExcel(event.target.files[0]);
        setWarehouseFilename(event.target.files[0].name);
    }   

    const uploadWarehouse = (event) => {
        const data = new FormData();
        data.append('file', warehouseExcel);
        warehouseImportService.triggerWarehouseImport(data).then((response) => {
            //console.log("Import response:======== ", response);
            if ((response.status === 200) && (response.data.success) && (response.data.failed.length === 0)) {
                //console.log("Warehouse Import Response :-------- ", response);
                setShowUploadSuccessAlert(true);
                setWarehouseExcel("");
                setShowImportFailedRecordsAlert(false);
                setShowUploadPartialSuccessAlert(false);
                setDisableOkBtn(false);
            } else if ((response.status === 200) && (response.data.success.length === 0) && (response.data.failed)) {
                //console.log("Error Msg:- ", response.data);
                setFailedRecordsData(response.data.failed);
                setShowUploadSuccessAlert(false);
                setWarehouseExcel("");
                setShowImportFailedRecordsAlert(true);
                setShowUploadPartialSuccessAlert(false);
            } else if ((response.status === 200) && (response.data.success.length > 0) && (response.data.failed.length > 0)) {
                //console.log("Error Msg:- ", response.data);
                setFailedRecordsData(response.data.failed);
                setShowUploadSuccessAlert(false);
                setWarehouseExcel("");
                setShowImportFailedRecordsAlert(true);
                setShowUploadPartialSuccessAlert(true);
            } else {
                setShowUploadFailureAlert(true);
            }
        })
            .catch(function (error) {
                console.log(error);
                if (error.response) {
                    setShowUploadFailureAlert(true);
                } else {
                    //some other error happened
                    setShowUploadFailureAlert(true);
                }
            })
    }  

    const backAction = (event) => {
        window.history.go(-1);
    }

    if (!showUploadSuccessAlert && !showUploadFailureAlert && !showImportFailedRecordsAlert && !showUploadPartialSuccessAlert) {
        return (
            <Grid container>
                <Grid item xs={12} style={{ marginBottom: "15px" }}>
                    <PageHeader title="Import Warehouse"
                        breadcrumbs={[
                            { label: "Warehouse" },
                            { label: "Import Warehouse" }
                        ]} />
                </Grid>
                <Grid item xs={9} md={8}>
                    <Paper elevation={2} style={{ width: "100%", padding: "1.5em" }}>
                        {user.uiPrivileges.includes("ALL_PRIVILEGE") || user.uiPrivileges.includes("WAREHOUSE_ASSOCIATION") ?
                            <header style={{
                                fontSize: "16px", color: "#166aab",
                                fontWeight: "bold"
                            }}>Warehouse </header> : null}
                        {user.uiPrivileges.includes("ALL_PRIVILEGE") || user.uiPrivileges.includes("WAREHOUSE_ASSOCIATION") ?
                            <Grid item container justify="space-between" alignItems="center"
                                style={{ padding: "1em" }}>
                                <Grid item container md={6} alignItems="center">
                                    <span style={{ fontSize: "14px" }}>Choose File </span>
                                    <ExcelIcon />
                                    <a href={importWarehouseTemplate} download="importWarehouseTemplate.xlsx">Download File
                                    <i className="fa fa-download" id="iconId"></i>
                                    </a>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    {warehouseExcel ? <div>
                                        <header style={{ fontSize: "14px", color: "#166aab" }}>{warehouseFilename}</header>
                                        <div style={{ display: "flex", marginTop: "10px" }}>
                                            <div style={{ width: "50%", marginRight: "3px" }}>
                                                <Button variant="contained" color="secondary" onClick={() => setWarehouseExcel("")}
                                                    fullWidth={true} startIcon={<DeleteIcon />} style={{ textTransform: "capitalize" }} >
                                                    Clear
                                    </Button>
                                            </div>
                                            <div style={{ width: "50%", marginLeft: "3px" }}>
                                                <Button variant="contained" color="primary" onClick={uploadWarehouse}
                                                    fullWidth={true} startIcon={<CloudUploadIcon />} style={{ textTransform: "capitalize" }} >
                                                    Upload
                                    </Button>
                                            </div>
                                        </div>
                                    </div> : <ColorButton variant="contained"
                                        color="primary"
                                        component="label"
                                        className="align-self-end w-100 text-capitalize">
                                            Browse
                                    <Input type="file" onChange={previewWarehouse}
                                                style={{ display: "none" }}
                                                inputProps={{ accept: ".xlsx, .xls, .csv" }}
                                                name="warehouseImport" value={warehouseImport} />
                                        </ColorButton>}
                                </Grid>
                            </Grid> : null}


                        <div className="row" style={{ marginTop: "30px" }}>
                            <div className="col-md-12 col-xs-12"
                                style={{ borderBottom: "1px solid #DEE9E8" }}></div>
                        </div><br />
                        <div className="row">
                            <div className="col-md-3 col-xs-3"></div>
                            <div className="col-md-3 col-xs-3"></div>
                            <div className="col-md-3 col-xs-3"></div>
                            <div className="col-md-3 col-xs-3">
                                <button style={{
                                    backgroundColor: "#166aab",
                                    border: "0",
                                    color: "white"
                                }}
                                    onClick={backAction}
                                    className="form-control">Back
                            </button>
                            </div>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        );
    } else if (showUploadSuccessAlert) {
        return (
            <>
            <SweetAlert success
                        title="Success!" 
                        onConfirm={hideAlert}
                        //disabled={ disableOkBtn ? true : false }
                        onCancel={hideAlert}
                        >
                <br />
                <span style={{ fontSize: "14px", color: "green" }}><b>File Upload Successfull !!</b></span>
            </SweetAlert>
            </>
        );
    } else if (showUploadPartialSuccessAlert){
        return (        
            <SweetAlert warning
                        title="Partially Failed!" 
                        onConfirm={hideAlert}
                        //disabled={ disableOkBtn ? true : false }
                        onCancel={hideAlert}
                        >
                <br />
                <span style={{ fontSize: "14px", color: "red" }}><b>Partial File Uploaded !!</b></span>
                <br />  
                <span style={{ fontSize: "14px", color: "red" }}><b>Refer below table for unsuccessfull records !!</b></span>
                <br />          
                <table className="table table-bordered" id="errorListTable">
                    <thead>
                        <tr>
                            <th style={{ fontSize: "12px" }}>Row Number</th>
                            <th style={{ fontSize: "12px" }}>Status</th>
                            <th style={{ fontSize: "12px" }}>Message</th>
                        </tr>
                        </thead>
                    <tbody>
                        {failedRecordsData.map((item, index) => {
                            return (
                            <React.Fragment key={index}>
                                    <tr>                                       
                                        <td style={{ fontSize: "15px" }}>{item.rowNumber}</td>
                                        <td style={{ fontSize: "15px" }}>{item.status}</td>
                                        <td style={{ fontSize: "15px", whiteSpace:"pre-line", wordBreak:"break-word"}}>
                                            {item.message.map((msg,index) => {
                                                return(
                                                    <React.Fragment key={index}>
                                                        {msg}
                                                        <br/>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </td>                                       
                                    </tr>
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>                           
            </SweetAlert>
        );
    } else {
        return (        
            <SweetAlert danger
                        title="Failed!" 
                        onConfirm={hideAlert}
                        //disabled={ disableOkBtn ? true : false }
                        onCancel={hideAlert}
                        >
                <br />
                <span style={{ fontSize: "14px", color: "red" }}><b>File Upload Unsuccessfull !!</b></span>
                <br />          
                <table className="table table-bordered" id="errorListTable">
                    <thead>
                        <tr>
                            <th style={{ fontSize: "12px" }}>Row Number</th>
                            <th style={{ fontSize: "12px" }}>Status</th>
                            <th style={{ fontSize: "12px" }}>Message</th>
                        </tr>
                        </thead>
                    <tbody>
                        {failedRecordsData.map((item, index) => {
                            return (
                            <React.Fragment key={index}>
                                    <tr>                                       
                                        <td style={{ fontSize: "15px" }}>{item.rowNumber}</td>
                                        <td style={{ fontSize: "15px" }}>{item.status}</td>
                                        <td style={{ fontSize: "15px", whiteSpace:"pre-line", wordBreak:"break-word"}}>
                                            {item.message.map((msg,index) => {
                                                return(
                                                    <React.Fragment key={index}>
                                                        {msg}
                                                        <br/>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </td>
                                    </tr>
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>                           
            </SweetAlert>
        );
    } 
    
}

export default ImportWarehouse;
