import React, { forwardRef } from "react";
import ListItem from "@material-ui/core/ListItem";
import { NavLink } from "react-router-dom";
export default function LinkListItem({
  link,
  onClick,
  selectedStyle,
  children,
  //configSelected,
  level,
  buttonStyle,
  Open
}) {
  if (!link || typeof link !== "string") {
    return (
      <ListItem
        button
        className={Open === true ? selectedStyle : ""}
        classes={{
          button: buttonStyle
        }}
        children={children}
        onClick={onClick}
        selected={Open}
        style={{ paddingLeft: `${level}em` }}
      />
    );
  }
  return (
    <ListItem
      style={{ paddingLeft: `${level}em` }}
      onClick={onClick}
      button
      classes={{
        button: buttonStyle
      }}
      className={Open === true ? selectedStyle : ""}
      children={children}
      component={forwardRef((props, ref) => (
        <NavLink
          //  activeClassName={selectedStyle}
          //  exact={false}
          {...props}
          innerRef={ref}
        />
      ))}
      to={link}
    />
  );
}
