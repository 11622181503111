import { func } from "prop-types";
import { utilityServices } from "./utilityServices";

function getToken() {
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const token = user && user.jwtAccessToken;
  return token;
}



async function getPbpList(){
  const url = `${process.env.REACT_APP_GET_PBP_LIST}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "gh7MvvJXnRQO44PatMnoAuBcgLNTfk",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function associateFarmer(values){
  const url = `${process.env.REACT_APP_FARMER_ASSOCIATE}`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "gh7MvvJXnRQO44PatMnoAuBcgLNTfk",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    },
    body:JSON.stringify(values)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function validationFarmerAssociateSelection(values){
  const url = `${process.env.REACT_APP_VALIDATE_FARMER_ASSOCIATE_SELECTION}`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "gh7MvvJXnRQO44PatMnoAuBcgLNTfk",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    },
    body:JSON.stringify(values)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

export const farmerAssociationService = {  
  getPbpList,
  associateFarmer,
  validationFarmerAssociateSelection

};
