import React, { useState, useContext, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { ErrorMessage, getIn } from "formik";
import PageHeader from "components/layout/PageHeader";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
import CommonForm from "components/common/form/common-form";
import FailureFeedback from "components/common/elements/FailureFeedback";
import PincodeSelection from "components/common/form/PincodeSelection";
import { provisioningService } from "services/provisioningServices";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import "components/responsive.scss";
import "../configuration.scss";
import "../../../components/responsive.scss";

import { useTranslation } from "react-i18next";
import { string } from "prop-types";
import { errorContext } from "context/errorContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Radio,RadioGroup} from "@material-ui/core";
import WarehouseCategoryProdSelection from "components/common/form/WarehouseCategoryProdSelection"

import './importWarehouse.css';

import {
  FormControl,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Switch,
  Box,
  withStyles,
} from "@material-ui/core";
import { warehouseService } from "services/warehouseServices";
import { Category } from "@material-ui/icons";

export default function WarehouseAssociateForm({
  values,
  warehouseData,
  ccData,
  vlccData,
  warehouseName,
  warehouseId,
  organizationData,
  customerGroupData,
  categoryData,
  categoryProductData,
  status,
  edit,
  handleSubmit,
  mandatoryMQPErrorMessage,
  pincodeSelectionError,
  warehouseAssociateStatus,
  warehouseStatusMessage,
  warehouseFailedMessage,
  prodData,
  getSelectedCategoryAndProducts
}){

  const title = warehouseName +" - Warehouse Association";
  const id = warehouseId;

  const { t } = useTranslation();
  const formikForm = React.useRef(null);
  const { dispatch: errorDispatch } = useContext(errorContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const [mqpData,setMqpData] = React.useState([]);
  const [mepData,setMepData] = React.useState([]);
  const [productData,setProductData] = React.useState([]);
 
  
  useEffect(() => {
    if(edit === true && status === "success"){
      setMqpData(ccData);
      setMepData(vlccData);
      setProductData(prodData);
    }
  }, [status]); 

  function loadMqpData(value){
    provisioningService.getCCByOrg(value).then(data => {
      console.log("data",data);
      setMqpData(data);    
    }).catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });  
  }

  function loadMepData(value){
    console.log(value);
    warehouseService.getVlccByCC(value).then(data =>{
      setMepData(data);
    }).catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });  
  }

  function loadProductData(value){
    console.log(value);
    warehouseService.getProductDataByCategory(value.join()).then(data =>{
      setProductData(data.content);
    }).catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    }); 
  }
 
  const handleDialogClose = () => {
    history.push("/configuration/warehouse-list")
  };

  let validations = {
    // wharehouseId:Yup.string().required(
    //   "Is required"
    // ).nullable(),
    // customerGroup : Yup.string().required(
    //   "Is required"
    // ).nullable(),
    // categories: Yup.string().required(
    //   "Is required"
    // ).nullable(),
  };

  const warehouseType = [
    {value:"isMQP",label:"MQP"},
    {value:"isMEP",label:"MEP"},
    {value:"others",label:"Others"},
  ];

  const warehouseChange = (e) => {
    console.log(e);
  }

  const fields = [  
    // {
    //   type: "custom",
    //   grid: {
    //     xs: 12,
    //     sm: 4,
    //     className: ""
    //   },
    //   component: ({ values, setFieldValue }, renderFields) => (
    //     <FormControl>
    //       <FormLabel> Warehouse Type</FormLabel>
    //       <RadioGroup onChange={warehouseChange}  defaultValue="Never" row>
    //         {warehouseType.map((option) => (
    //           <FormControlLabel
    //             key={option.value}
    //             value={option.value}
    //             control={<Radio />}
    //             label={option.label} />
    //         ))}
    //       </RadioGroup>
    //     </FormControl>
    //   )
    // },
    {
      type: "custom",
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      },
      component: ({ values, setFieldValue }, renderFields) => (
        <FormControlLabel 
          control={
              <Switch checked={values.isMQP} onChange={(e) => setFieldValue("isMQP",!values.isMQP)} name="isMQP" value={values.isMQP} />
          }
          label={t("Warehouse_Association_Form_isMQP_Label")}
          labelPlacement="top"
        />
      )
    },
    {
      type: "custom",
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      },
      component: ({ values, setFieldValue }, renderFields) => (
        <FormControlLabel 
          control={
              <Switch checked={values.isMEP} onChange={(e) => setFieldValue("isMEP",!values.isMEP)} name="isMEP" value={values.isMEP} />
          }
          label={t("Warehouse_Association_Form_isMEP_Label")}
          labelPlacement="top"
        />
      )
    },
    edit === true ? (
      {
        type: "select",
        name: "orgId",
        label: t("Warehouse_Association_Form_Organization_Label"),
        placeholder: t("agent_Association_Form_Organization_Label"),
        option:organizationData.length > 0
          ? organizationData.map(organizationData => ({
              value: organizationData.orgId,
              label: organizationData.orgName
            }))
          : [],
        grid: {
          xs: 12,
          sm: 3,
          className: "multi-select"
        },
        mandatory: "mandatory",
        customOnChange:loadMqpData
      }
    ):(  
    {
      type: "singleSelectSearch",
      name: "orgId",
      label: t("Warehouse_Association_Form_Organization_Label"),
      placeholder: t("agent_Association_Form_Organization_Label"),
      option:organizationData.length > 0
        ? organizationData.map(organizationData => ({
            value: organizationData.orgId,
            label: organizationData.orgName
          }))
        : [],
      grid: {
        xs: 12,
        sm: 3,
      //  className: "multi-select singleSelect"
      className:"MuiSelect-selectMenu"
      },
      mandatory: "mandatory",
      customOnChange:loadMqpData
    }),
    {
      type: "select",
      name: "ccId",
      label: t("Warehouse_Association_Form_MQP_Label"),
      placeholder: t("Warehouse_Association_Form_MQP_Label"),
      option:mqpData.length > 0
        ? mqpData.map(mqpData => ({
            value: mqpData.chillingCenterId,
            label: mqpData.chillingCenterName
          }))
        : [],     
      grid: {
        xs: 12,
        sm: 3,
        className: "multi-select"
      },
      mandatory: "mandatory",
      customOnChange:loadMepData
    },
    {
      type: "select",
      name: "societyUniqueId",
      label: t("Warehouse_Association_Form_MEP_Label"),
      placeholder: t("Warehouse_Association_Form_MEP_Label"),
      option:mepData.length > 0
      ? mepData.map(mepData => ({
          value: mepData.societyUnqId,
          label: mepData.vlccName
        }))
      : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "multi-select"
      },
      mandatory: "mandatory"
    },
    {
      type: "multiSelectAll",
      name: "customerGroup",
      label: t("Warehouse_Association_Form_Customer_Group_Label"),
      placeholder: t("Warehouse_Association_Form_Customer_Group_Label"),
      option:customerGroupData.length > 0
        ? customerGroupData.map(data => ({
            value: data.name,
            label: data.name
          }))
        : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "multi-select"
      },
      mandatory: "mandatory"
    },
    // {
    //   type: "multiSelectAll",
    //   name: "categories",
    //   label: t("Warehouse_Association_Form_Category_Label"),
    //   placeholder: t("Warehouse_Association_Form_Category_Label"),
    //   option:categoryData.length > 0
    //     ? categoryData.map(categoryData => ({
    //         value: categoryData.id,
    //         label: categoryData.categoryName
    //       }))
    //     : [],
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "multi-select"
    //   },
    //   mandatory: "mandatory",
    //   customOnChange:loadProductData
    // },
    // {
    //   type: "multiSelectAll",
    //   name: "productId",
    //   label: t("Warehouse_Association_Form_Product_List_Label"),
    //   placeholder: t("Warehouse_Association_Form_Product_List_Label"),
    //   option:productData.length > 0
    //     ? productData.map(productData => ({
    //         value: productData.id,
    //         label: productData.productName
    //       }))
    //     : [],
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "multi-select"
    //   },
    //   mandatory: "mandatory"
    // },
    
    // {
    //   type: "textarea",
    //   name: "categoryDisplay",
    //   value: {selectedCategoryValue},
    //   label: t("Warehouse_Association_Form_Category_Label"),
    //   placeholder: t("Warehouse_Association_Form_Category_Label"),
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   },
    //   disabled:true

    // },
    {
      type: "custom",
      component: ({ setFieldValue, values }, renderFields) => (
        <>
          {/* <span>
            <h3>Select Products</h3>
          </span> */}
          <WarehouseCategoryProdSelection
            name={"Product"}
            categoryProductData = {categoryProductData}
            getSelectedCategoryAndProducts = {getSelectedCategoryAndProducts}
            categorySelectedData = {values.categories}
            edit = {edit}
          />
        </>
      ),
      grid: {
        xs: 3,
       // className: "title-on-border-box bg-blue-l",
      }
    },  
    {
      type: "custom",
      component: ({ setFieldValue, values }, renderFields) => (
        <>
          <span>
            <h3>{t("Warehouse_Association_Form_Pincode_Label")}</h3>
          </span>
          <PincodeSelection
            name={"pincode"}
            setFieldValue={setFieldValue}
            values={values["pincodeBased"]}
            warehouseChip={true}
          />
        </>
      ),
      grid: {
        xs: 12,
        className: "title-on-border-box bg-blue-l",
      }
    }  
  ];

  return(
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={title}
          breadcrumbs={[
            { label: "Dashboard", link: "/dashboard" },
            { label: "Configuration" },
            { label: "Warehouse Association" }
          ]}
        />
      </Grid>

      <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%"
        }}
      >
        {status === "success" ? (
        <CommonForm
          fields={fields}
          submitLabel={t("site_Form_Submit_Label")}
          submittingLabel={t("site_Form_Submitting_Label")}
          initialValues={values}
          validationSchema={Yup.object().shape({ ...validations })}
          validateOnBlur={true}
          //  edit={edit}
          enableReinitialize
          validateOnChange={true}
          onSubmit={(
            values,
              { setSubmitting, setFieldError, resetForm, errors }
            ) => {
            if (!!errors) {
              setSubmitting(false);
            }
            handleSubmit(values, resetForm, setSubmitting);
          }}
          formikRef={formikForm}
          buttonSize="3"
          buttonPosition="right"
        />
        ):
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        }
        {mandatoryMQPErrorMessage && (
          <div style={{ color: "#f44336" }}>{mandatoryMQPErrorMessage}</div>
        )}
        {pincodeSelectionError && (
          <div style={{ color: "#f44336" }}>{pincodeSelectionError}</div>
        )}
      {  /* {serOffSelectError && (
          <div style={{ color: "#f44336" }}>{serOffSelectError}</div>
        )}
        {selectionError && (
          <div style={{ color: "#f44336" }}>{selectionError}</div>
        )} */}
    </Paper>
    <SuccessFeedback
      open={warehouseAssociateStatus === "success"}
      onClose={handleDialogClose}
      successMessage={warehouseStatusMessage}
      createAnotherMesssage={warehouseStatusMessage}
    />
    <FailureFeedback
      open={warehouseAssociateStatus === "failed"}
      onClose={handleDialogClose}
      status={"Failed"}
      message={warehouseFailedMessage}
    />
  </Grid>   
  );
}
