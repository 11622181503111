import React, { useEffect, useContext, useState, useMemo } from "react";
import { errorContext } from "context/errorContext";
import addDays from "date-fns/addDays";

import "../../../components/Table.scss";
import { useTranslation } from "react-i18next";

import { farmerAssociationService } from "services/farmerAssociationServices";
import { provisioningService } from "services/provisioningServices";

//import FarmerAssociationForm from "./FarmerAssociationForm";
import BannerForm from "./BannerForm.js";
import { notificationService } from "services/notificationServices";
import { useLocation } from 'react-router-dom';
import {bannerService} from "services/bannerService.js"

import { pbpService } from "services/pbpServices";
import { number } from "prop-types";

import SweetAlert from 'react-bootstrap-sweetalert';
import ImageComponent from "components/ImageComponent";
import { Image, Language, NoEncryption } from "@material-ui/icons";
import { useParams, useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";

export default function CreateBanner() {
  const { t } = useTranslation();
  const history = useHistory();

  const { dispatch: errorDispatch } = useContext(errorContext);
  const [value, setValue] = React.useState(0);
  const [postOfficeData, setPostOfficeData] = React.useState([]);

  const [stateData,setStateData] = React.useState([]);
  const [status, setStatus] = useState("loading");
  const [pbpData, setPbpData] = React.useState([]);
  const [fetchTagOnPbp, setFetchTagOnPbp] = React.useState([]);
  const [orgData,setOrgData ] = React.useState([]);

 // const [actionType, setActionType] = React.useState([]);
  const [allDropDownDataBanner, setAllDropDownDataBanner] = React.useState([]); 
  const [picture, setPicture] = React.useState([]);
  const [appType, setappType] = React.useState([]);

  const [createSuccess, setCreateSuccess] = React.useState(false);
  const [createFailure, setCreateFailure] = React.useState(false);
  const [failureMsg, setFailureMsg] = React.useState("");  

  const [language, setLanguage] = React.useState([]); 
  const [urlError,setUrlError] = useState("");
  const [actionType, setactionType] = React.useState([]);

  useEffect(() => {
    provisioningService.verifyOtpNew('1313131313').then((res) => {
      const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
      const organizationId = JSON.parse(localStorage.getItem("orgId"));
      getListData();
    })
    .catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });
  }, []);

 /* useEffect(() => {
    notificationService.getNotificationUIOptions('*').then(data => {
      if (data) {
        setAllDropDownData(data);
        //setLanguage(data.LANGUAGE);
        setactionType(data.APP_ACTION_SMART_FARMS);
        //setMimeType(data.MIME_TYPE);
        setappType(data.PRODUCTS);
        //setPublishChannel(data.PUBLISH_CHANNEL);
        //setTopicName(data.TOPIC_NAME);
      }
    });
  }, []); */

  useEffect(() => {
    bannerService.getBannerOptions('*').then(data => {
      if (data) {
        setAllDropDownDataBanner(data);        
        setactionType(data.APP_ACTION_SF);        
        setappType(data.APP_TYPE);        
      }
    });
  }, []);
 
  function getListData(value){
    farmerAssociationService.getPbpList().then(data => {    
      setPbpData(data);
    }).catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });

    provisioningService.getAllOrgs().then(data => {
      setOrgData(data);
    }).catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });

    provisioningService.getAllStates().then(data =>{
      setStateData(data.states);
      setStatus("success");
    }).catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });

    notificationService.getNotificationUIOptions('LANGUAGE').then(data => {
      if (data) {
        setLanguage(data);
      }
    });
  } 

  const formikForm = React.useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };   

  const hideAlert = (event) => {
    setCreateSuccess(false);
    setCreateFailure(false);
  } 
     
  let values = {  
    startDate: new Date(),    // addDays(new Date(), -10),
    endDate: new Date(),
    id: null,
    name: "",
    dimentionType : null,
    sortOrder:"",
    hlink: null,
    actionPage: {},
    actionType:"", 
    adImageUrl:{}, 
    pageName: null,
    position: null, 
    // appType: "",   // appType
    fetchTag: "",    

    userType: "",
    organizationBased:[],    // used for add the organisation array pressing + button
    pincodeList:[],         // used for add the pincode list array pressing + button    

    languageList:[
      {
        language: "ENGLISH"        
      }
    ],

    extraData:{},
    extraDataList: [
      {
        subKey: "",
        subValue: ""
      }
    ],
    
    appType: [],  
    actionType: ""

  };

  async function handleSubmit(values, resetForm, setSubmitting) 
  {    
    for(var i=0;i<values.extraDataList.length;i++){
      values.extraData[values.extraDataList[i].subKey] = values.extraDataList[i].subValue;
    }

    delete values["extraDataList"];

    for (let i = 0; i < values.languageList.length; i++)
    {
      let languageObject = values.languageList[i];      
      if (languageObject.language != undefined){
        if(languageObject.language === "ENGLISH") 
        {       
          if (languageObject && typeof languageObject === 'object')
          {
            { 
              let enURL = languageObject.url;
                if (enURL != undefined){
                values.en = enURL
                setUrlError("");
              }
              else{
                setUrlError("Please enter the URL");
                // resetForm(values, true);
                return;              
              }
            }          
          }
        }
      else if(languageObject.language === "KANNADA")
      {       
        if (languageObject && typeof languageObject === 'object')
        {
         let kaURL = languageObject.url;
          if(kaURL != undefined){
            values.kn = kaURL
            setUrlError("");
            }
            else{
              setUrlError("Please enter the URL");
              resetForm(values, true);
              return;              
            }
        }
      }
      else if(languageObject.language === "HINDI")
      {       
        if (languageObject && typeof languageObject === 'object')
        {
         let hiURL = languageObject.url;
          if (hiURL != undefined){
            values.hi = hiURL
            setUrlError("");
            }
            else{
              setUrlError("Please enter the URL");
              resetForm(values, true);
              return;              
            }
        }
      }
      else if(languageObject.language === "MARATI")
      {       
        if (languageObject && typeof languageObject === 'object')
        {
         let mrURL = languageObject.url;
          if (mrURL != undefined){
            values.mr = mrURL
            setUrlError("");
            }
            else{
              setUrlError("Please enter the URL");
              resetForm(values, true);
              return;              
            }
        }
      }
      else if(languageObject.language === "TELUGU")
      {       
        if (languageObject && typeof languageObject === 'object')
        {
         let teURL = languageObject.url;
          if (teURL != undefined){
            values.te = teURL
            setUrlError("");
            }
            else{
              setUrlError("Please enter the URL");
              resetForm(values, true);
              return;              
            }
        }
      }
      else if(languageObject.language === "TAMIL")
      {       
        if (languageObject && typeof languageObject === 'object')
        {
         let taURL = languageObject.url;
          if (taURL != undefined){
            values.ta = taURL
            setUrlError("");
            }
            else{
              setUrlError("Please enter the URL");
              resetForm(values, true);
              return;              
            }
        }
      }
    }
    else
    {
      setUrlError("Please select the Language");
      resetForm(values, true);
      return;   
    }     

    /*  values.adImageUrl = {  
          "en": { 
            "url": values.en          
          },
          "kn": {
            "url": values.kn
          },
          "hi": {
            "url": values.hi
          },
          "mr": {
            "url": values.mr
          },
          "te": {
            "url": values.te
          },
          "ta": {
            "url": values.ta
          }          
        } */

        // Initialize adImageUrl outside the loop
      values.adImageUrl = {};

      // Loop through languageList
      //for (let i = 0; i < values.languageList.length; i++) {
    if (values.en !== undefined) {   
        values.adImageUrl.en = {
          "url": values.en
        }
    }     
    if (values.kn !== undefined) {   
        values.adImageUrl.kn = {
            "url": values.kn
        }
    }
    if (values.hi !== undefined) {   
        values.adImageUrl.hi = {
            "url": values.hi
        }
    }
    if (values.mr !== undefined) {   
        values.adImageUrl.mr = {
            "url": values.mr
        }
    }
    if (values.te !== undefined) {   
        values.adImageUrl.te = {
            "url": values.te
        }
    }
    if (values.ta !== undefined) {   
        values.adImageUrl.ta = {
            "url": values.ta
        }
    }        
  
          
    } 

    values.fetchTag = [
      values.fetchTag
      ]

    values.actionPage = values.extraData;
    values.appType = values.appType.toLowerCase();

    console.log("Clicked",values);
    try {
      const repeat = null;
      const result = await bannerService.BannerCreate(values);
      console.log("Result:============= ", result);
      if(result.data === null && result.message && result.status === "failure"){
        //console.log("If Condition:==========")
        //setCreateFailure(true);
        //setFailureMsg(result.message);
        setSubmitting(false);
      }else{

        try {
          const repeat = null;
          //const result = await pbpService.PBPcreate(values);
          resetForm();
          setCreateSuccess(true);
          //resetForm();        
          //redirect to list page
          setTimeout(() => {
            history.push("/configuration/banner_list");
          }, 3000);

        } catch (e) {
          // console.error(e);
          // setCreateFailure(true);
          // setFailureMsg(e.error.message);
          // setSubmitting(false);
        }

      }
    } catch (e) {
      //console.error(e);
    }
    
  }
  
  return (      
      <>
      <grid item xs={11} md={3}>  
           
      <BannerForm
        values = {values}
        //orgData = {orgData}
        actionType={actionType}   
        handleSubmit={handleSubmit}  
        page="add"
        appType={appType}        
        language={language}  
        urlError = {urlError}
        allDropDownDataBanner = {allDropDownDataBanner}                              
      />         
      </grid>

      {createSuccess ? 
        <SweetAlert success
          title="Banner Created Successfully"
          onConfirm={hideAlert}
          //disabled={ disableOkBtn ? true : false }
          onCancel={hideAlert}
          timeout={2000}
        ></SweetAlert> 
      : null}

      {createFailure ? 
        <SweetAlert danger
          title={failureMsg}
          onConfirm={hideAlert}
          //disabled={ disableOkBtn ? true : false }
          onCancel={hideAlert}
          //timeout={3000}
        ></SweetAlert> 
      : null}

      {/* {urlError &&  (              
          <div style={{ color: "#f44336", textAlign: "center"}}>{urlError}</div>              
      )}  */}

      </>
    );  
  
}
