import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";

import CommonForm from "../../../components/common/form/common-form";
import { metadataService } from "../../../services/metadataServices";
import PageHeader from "../../../components/layout/PageHeader";
import LoanProductName from "components/common/form/LoanProductName";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { errorContext } from "context/errorContext";
import { useTranslation } from "react-i18next";

//Import added for Date field
import addDays from "date-fns/addDays";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Switch,
  Box,
  withStyles,
} from "@material-ui/core";


export default function EditRetailerForm({ values, handleSubmit, page, status, category, showSubmitButton=true,customerGroupData,pbpData,roleList,warehouseData }) {
  
  const [dateOfBirth] = React.useState(new Date());
  const gender = ['Male', 'Female', 'Others'];

  const formikForm = React.useRef(null);

  const { t } = useTranslation();

  const { dispatch: errorDispatch } = useContext(errorContext);
  const [backendIsActive, setBackendIsActive] = useState(values.isActive);
  const [frontendIsActive, setFrontendIsActive] = useState(values.isActive);
  const [frontendStatus, setFrontendStatus] = useState("");
  const handleToggle = (event, setFieldValue) => {
    setFrontendIsActive(event.target.checked);
    setFieldValue(event.target.name, event.target.checked);
    console.log("values" , values);
    console.log("frontendIsActive",frontendIsActive);
    console.log("backendIsActive",backendIsActive);
    console.log("chekced value",event.target.checked);
  }

  const handleStatus = val => {
    setFrontendStatus(val);
  }
    
  // useEffect(() => {
  //   metadataService
  //     .getGenderTypes()
  //     .then(data => {
  //       let types = data.map(type => type.typeName);
  //       setGender(types);
  //     })
  //     .catch(error => {
  //       errorDispatch({
  //         type: "ERROR",
  //         error
  //       });
  //     });
  // }, []);

  //To diplay the common exceptions at app level
  const fields = [
    {
      type: "completedPercentage",
      formikRef: formikForm,
      title: t("retailer_Form_Percentage_Edit_Label") 
    },
    {
      type: "header",
      name: "Retailer"
    },
    {
      type: "text",
      name: "name",
      label: t("retailer_Edit_Form_Retailer_Name"),
      placeholder: t("retailer_Edit_Form_Retailer_Name"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "select",
      name: "gender",
      label: t("retailer_Edit_Form_Retailer_Gender"),
      placeholder: t("retailer_Edit_Form_Retailer_Gender"),
      option:
      gender.length > 0
          ? gender.map(gender => ({
              value: gender,
              label: gender
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "datePicker",
      name: "dateOfBirth",
      value: { dateOfBirth },
      label: t("retailer_Edit_Form_Retailer_DOB"),
      maxDate: new Date(),
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "addressLine1",
      label: t("retailer_Edit_Form_Retailer_AddressLine1"),
      placeholder: t("retailer_Edit_Form_Retailer_AddressLine1"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "retailerPincode",
      label: t("retailer_Edit_Form_Retailer_Pincode"),
      placeholder: t("retailer_Edit_Form_Retailer_Pincode"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "retailerVillage",
      label: t("retailer_Edit_Form_Retailer_Village"),
      placeholder: t("retailer_Edit_Form_Retailer_Village"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "retailerTaluk",
      label: t("retailer_Edit_Form_Retailer_Taluk"),
      placeholder: t("retailer_Edit_Form_Retailer_Taluk"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "retailerDistrict",
      label: t("retailer_Edit_Form_Retailer_District"),
      placeholder: t("retailer_Edit_Form_Retailer_District"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "retailerState",
      label: t("retailer_Edit_Form_Retailer_State"),
      placeholder: t("retailer_Edit_Form_Retailer_State"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "retailerCountry",
      label: t("retailer_Edit_Form_Retailer_Country"),
      placeholder: t("retailer_Edit_Form_Retailer_Country"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "select",
      name: "status",
      label: t("retailer_Edit_Form_Status"),
      placeholder: t("retailer_Edit_Form_Status"),
      option:
      status.length > 0
          ? status.map(status => ({
              value: status,
              label: status
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      },
      customOnChange:handleStatus
    },
    {
      type: "text",
      name: "latLang",
      label: t("retailer_Edit_Form_LatLong"),
      placeholder: t("retailer_Edit_Form_LatLong"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "header",
      name: "Store"
    },
    {
      type: "text",
      name: "storeName",
      label: t("retailer_Edit_Form_Store_Name"),
      placeholder: t("retailer_Edit_Form_Store_Name"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "storeAddress1",
      label: t("retailer_Edit_Form_Store_Address1"),
      placeholder: t("retailer_Edit_Form_Store_Address1"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "storePincode",
      label: t("retailer_Edit_Form_Store_Pincode"),
      placeholder: t("retailer_Edit_Form_Store_Pincode"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "storeVillage",
      label: t("retailer_Edit_Form_Store_Village"),
      placeholder: t("retailer_Edit_Form_Store_Village"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "storeTaluk",
      label: t("retailer_Edit_Form_Store_Taluk"),
      placeholder: t("retailer_Edit_Form_Store_Taluk"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "storeDistrict",
      label: t("retailer_Edit_Form_Store_District"),
      placeholder: t("retailer_Edit_Form_Store_District"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "storeState",
      label: t("retailer_Edit_Form_Store_State"),
      placeholder: t("retailer_Edit_Form_Store_State"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "storeCountry",
      label: t("retailer_Edit_Form_Store_Country"),
      placeholder: t("retailer_Edit_Form_Store_Country"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "storePhoneNumber",
      label: t("retailer_Edit_Form_Store_PhoneNumber"),
      placeholder: t("retailer_Edit_Form_Store_PhoneNumber"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "select",
      name: "customerGroup",
      label: t("retailer_Edit_Form_Customer_Group_Label"),
      placeholder: t("retailer_Edit_Form_Customer_Group_Label"),
      option:
        customerGroupData.length > 0
          ? customerGroupData.map(customerGroupData => ({
             value: customerGroupData.name,
             label: customerGroupData.name
            }))
        : [],
      grid: {
        xs: 12,
        sm: 2,
        md: 3,
        className: ""
      }
    },
    {
      type: "multiSelect",
      name: "pbpName",
      label: t("retailer_Edit_Form_pbpData_Label"),
      placeholder: t("retailer_Edit_Form_pbpData_Label"),
      option:
        pbpData.length > 0
          ? pbpData.map(pbpData => ({
             value: pbpData.name,
             label: pbpData.name
            }))
        : [],
      grid: {
        xs: 12,
        sm: 2,
        md: 3,
        className: ""
      }
    },
    {
      type: "select",
      name: "roleName",
      label: t("retailer_Edit_Form_Role_Label"),
      placeholder: t("retailer_Edit_Form_Role_Label"),
      option:
        roleList.length > 0
          ? roleList.map(roleList => ({
             value: roleList.roleName ,
             label: roleList.roleName
            }))
        : [],
      grid: {
        xs: 12,
        sm: 2,
        md: 3,
        className: ""
      }
    },
    {
      type: "select",
      name: "wareHouseCode",
      label: t("retailer_Edit_Form_Warehouse_Label"),
      placeholder: t("retailer_Edit_Form_Warehouse_Label"),
      option:warehouseData.length > 0
        ? warehouseData.map(warehouseData => ({
            value: warehouseData.warehouseCode,
            label: warehouseData.warehouseCode
          }))
        : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "multi-select"
      }
    },
    {
      type: "custom",
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      },
      component: ({values, setFieldValue}) => (
        <FormControlLabel 
          control={
              <Switch checked={values.isActive} onChange={(event) => handleToggle(event, setFieldValue)}  name="isActive" />
          }
          label={t("retailer_Edit_Form_IsActive_Label")}
          labelPlacement="top"
        />
      )
    }
  ]

  let validations = (frontendIsActive, backendIsActive, status, frontendStatus) => {
    if (frontendIsActive === false) {
      console.log("inside if");
      return Yup.object().shape({
        name: Yup.string().nullable(true),
        gender: Yup.string().nullable(true),
        dateOfBirth: Yup.string().nullable(true),
        addressLine1: Yup.string().nullable(true),
        retailerPincode: Yup.string()
          .nullable(true)
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(6, 'Must be exactly 6 digits')
          .max(6, 'Must be exactly 6 digits'),
        retailerVillage: Yup.string().nullable(true),
        retailerTaluk: Yup.string().nullable(true),
        retailerDistrict: Yup.string().nullable(true),
        retailerState: Yup.string().nullable(true),
        retailerCountry: Yup.string().nullable(true),
        status: Yup.string().nullable(true),
        latLang: Yup.string().nullable(true),
        storeName: Yup.string().nullable(true),
        storeAddress1: Yup.string().nullable(true),
        storePincode: Yup.string()
          .nullable(true)
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(6, 'Must be exactly 6 digits')
          .max(6, 'Must be exactly 6 digits'),
        storeVillage: Yup.string().nullable(true),
        storeTaluk: Yup.string().nullable(true),
        storeDistrict: Yup.string().nullable(true),
        storeState: Yup.string().nullable(true),
        storeCountry: Yup.string().nullable(true),
        storePhoneNumber: Yup.string()
          .nullable(true)
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(10, 'Must be exactly 10 digits')
          .max(10, 'Must be exactly 10 digits'),
        customerGroup: Yup.string().nullable(true),
        pbpName: Yup.string().nullable(true)
      });
    } 
    else {
      console.log("inside else block");
    // if((frontendIsActive === true && backendIsActive === false && status === "verified") && (frontendIsActive === true && backendIsActive === false && status === "verified")){
    return Yup.object().shape({
    name: Yup.string().required(t("retailer_Edit_Form_Retailer_Name")),
    gender: Yup.string().required(t("retailer_Edit_Form_Retailer_Gender")),
    dateOfBirth: Yup.string().required(t("retailer_Edit_Form_Retailer_DOB")),
    addressLine1: Yup.string().required(t("retailer_Edit_Form_Retailer_AddressLine1")),
    retailerPincode: Yup.string().required(t("retailer_Edit_Form_Retailer_Pincode"))
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(6, 'Must be exactly 6 digits')
    .max(6, 'Must be exactly 6 digits'),
    retailerVillage: Yup.string().required(t("retailer_Edit_Form_Retailer_Village")),
    retailerTaluk: Yup.string().required(t("retailer_Edit_Form_Retailer_Taluk")),
    retailerDistrict: Yup.string().required(t("retailer_Edit_Form_Retailer_District")),
    retailerState: Yup.string().required(t("retailer_Edit_Form_Retailer_State")),
    retailerCountry: Yup.string().required(t("retailer_Edit_Form_Retailer_Country")),
    status: Yup.string().required(t("retailer_Edit_Form_Status")),
    latLang: Yup.string().nullable(true),
    storeName: Yup.string().required(t("retailer_Edit_Form_Store_Name")),
    storeAddress1: Yup.string().required(t("retailer_Edit_Form_Store_Address1")),
    storePincode: Yup.string().required(t("retailer_Edit_Form_Store_Pincode"))
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(6, 'Must be exactly 6 digits')
    .max(6, 'Must be exactly 6 digits'),
    storeVillage: Yup.string().required(t("retailer_Edit_Form_Store_Village")),
    storeTaluk: Yup.string().required(t("retailer_Edit_Form_Store_Taluk")),
    storeDistrict: Yup.string().required(t("retailer_Edit_Form_Store_District")),
    storeState: Yup.string().required(t("retailer_Edit_Form_Store_State")),
    storeCountry: Yup.string().required(t("retailer_Edit_Form_Store_Country")),
    storePhoneNumber: Yup.string().required(t("retailer_Edit_Form_Store_PhoneNumber"))
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(10, 'Must be exactly 10 digits')
    .max(10, 'Must be exactly 10 digits'),
    customerGroup: Yup.string().required(t("retailer_Edit_Form_Customer_Group_Validation_Label")),
    // pbpName: Yup.string().required(t("retailer_Edit_Form_pbpData_Validation_Label"))
    });
   }
  };

  return (
    <CommonForm
      fields={fields}
      submitLabel={t("retailer_Form_Submit_Label")}
      submittingLabel={t("retailer_Form_Submitting_Label")}
      initialValues={values}
      validationSchema={validations(frontendIsActive, backendIsActive, values.status,frontendStatus)}
      validateOnBlur={true}
      validateOnChange={true}
      showSubmitButton={showSubmitButton}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        //console.log("Actual Values Passed:- ", values);
        handleSubmit(values, resetForm, setSubmitting);
      }}
      formikRef={formikForm}
      buttonSize="3"
      buttonPosition="right"
      enableReinitialize
    />
  );
}
