const getHeaders = (columns, data, check) => {
    let header = [];
    let csvData = [];
    let acc = [];
    if (check) {
        columns.map(c => {
            header = [...header, ...c.columns];
        });
    } else {
        header = columns;
    }
    const value = header.map(h => {
        const accessor = h.accessor;
        acc = [...acc, accessor];
        return h.Header;
    });
    // console.log("columns ", columns);
    // console.log("data ", data);
    //remove actions from download
    const index = value.indexOf("Actions");
    if (index > -1) {
        //remove actions from header
        value.splice(index, 1);
        //remove actions from accessor
        acc.splice(index, 1);
    }
    //push to stack
    csvData.push(value);
    data.map(d => {
        const newArr = [];
        acc.forEach(item => {
            // console.log(d[item]);
            if (d[item] !== undefined) newArr.push(d[item]);
            else newArr.push("");
        });
        csvData.push(newArr);
    });
    // console.log(csvData);
    return csvData;
};

const jsonConverterForReports = async (xs, key, sortKey) => {
    return xs.reduce(function (rv, x) {
        let v = key instanceof Function ? key(x) : x[key];
        let el = rv.find(r => r && r.key.toUpperCase() === v.toUpperCase());

        if (el) {
            el.values.push(x);
            el.values.sort(function (a, b) {
                return a[sortKey].toLowerCase().localeCompare(b[sortKey].toLowerCase());
            });
        } else {
            rv.push({ key: v.toUpperCase(), values: [x] });
        }

        return rv;
    }, []);
};


const getRawDataHeaders = (columns, data, check) => {
    let header = [];
    let csvData = [];
    let acc = [];
    if (check) {
        columns.map((c, index) => {
            if (c.columns) {
                if (columns[index].Header != " ")
                    c.columns.map(col => {
                        col.Header = columns[index].Header + "." + col.Header
                    })
            }
            header = [...header, ...c.columns];
        })
    } else {
        header = columns;
    }
    const value = header.map(h => {
        const accessor = h.accessor;
        acc = [...acc, accessor];
        return h.Header;
    });
    const index = value.indexOf("Actions");
    if (index > -1) {
        //remove actions from header
        value.splice(index, 1);
        //remove actions from accessor
        acc.splice(index, 1);
    }
    //push to stack
    csvData.push(value);
    data.map(d => {
        const newArr = [];
        acc.forEach(item => {
            // if (d[item] !== undefined) newArr.push(d[item]);
            // else newArr.push("");
            // newArr.push(getValue(d,item))
            newArr.push(resolvePath(d, item, null))
        });
        csvData.push(newArr);
    });
    // console.log(csvData);
    return csvData;
};

// function getValue(data, item) {
//     var arr = item.split('.');
//     while (arr.length) {
//         data = data[arr.shift()];
//     }
//     return data;
// }

const resolvePath = (data, path, defaultValue) => path
    .split(/[\.\[\]\'\"]/)
    .filter(item => item)
    .reduce((obj, item) => obj ? obj[item] : defaultValue, data)



export const utilityServices = {
    getHeaders,
    jsonConverterForReports,
    getRawDataHeaders
};
