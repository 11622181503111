import React, { useState, useContext, useMemo } from "react";

import { Link } from "react-router-dom";

import { chillingUnitService } from "services/chillingUnitService.js";
import PageHeader from "components/layout/PageHeader";
import TemplateForTables from "../../components/layout/innerPagesTemplate";
import Table from "components/common/table/DynamicPaginationTable";

import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import { dashboardContext } from "context/dashboardContext";
import { errorContext } from "context/errorContext";

import "../../components/Table.scss";
import { useTranslation } from "react-i18next";

const CriticalAlerts = props => {
  const [alertData, setData] = useState([]);
  const [status, setStatus] = React.useState("fetch");
  const [pagingInfo, setPaging] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);

  const { dispatch: errorDispatch } = useContext(errorContext);

  const { t } = useTranslation();

  let query = useMemo(() => new URLSearchParams(props.location.search), [
    props.location
  ]);

  React.useEffect(() => {
    // if alerts data loading, wait
    // otherwise fetch data
    (async () => {
      setHasNextPage(true);
      setPageIndex(Number(query.get("page")));
      if (status !== "loading") {
        // console.log("here 1");
        try {
          setStatus("loading");
          const result = await chillingUnitService.searchAlerts(
            "Critical Alerts",
            query.get("paging"),
            24,
            query.get("date"),
            [query.get("chillingUnits")],
            [query.get("sites")]
          );
          if (result.content) {
            setData(result.content.RESULT);
            if (!!result.content.PAGING) {
              setPaging(result.content.PAGING);
            } else {
              setHasNextPage(false);
            }
          } else {
            setHasNextPage(false);
          }
          setStatus("idle");
        } catch (error) {
          errorDispatch({
            type: "ERROR",
            error
          });
        }
      }
    })();
  }, [query]);

  const nextPage = () => {
    if (hasNextPage) {
      query.set("paging", pagingInfo);
      query.set("page", pageIndex + 1);
      props.history.push({
        path: "critical-alerts",
        search: query.toString()
      });
    }
  };

  const previousPage = () => {
    let index = pageIndex;
    if (index > 0) {
      let paging = pagingInfo;
      paging.pop();
      setPaging(paging);
      setPageIndex(--index);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("critical_Alerts_Site_Name"),
        accessor: "siteName",
        Cell: ({ row }) => {
          return (
            <Link to={`/totalsites/site-details/${row.original.siteId}`}>
              <span>{row.original.siteName}</span>
            </Link>
          );
        }
      },
      {
        Header: t("critical_Alerts_Bmc_Id"),
        accessor: "chillingUnitId"
      },
      {
        Header: t("critical_Alerts_Type_Of_Alert"),
        accessor: "alertLevel"
      },
      {
        Header: t("critical_Alerts_Message"),
        accessor: "alertMessage",
        Cell: ({ row }) => {
          return (
            <div
              style={{
                whiteSpace: "pre",
                textAlign: "left",
                paddingLeft: "1em"
              }}
            >
              {row.original.alertMessage}
            </div>
          );
        }
      }
    ],
    []
  );
  const data = React.useMemo(() => [...alertData]);
  const [returnLink, setReturnLink] = useState("");
  const [b, setB] = useState("");
  // React.useEffect(() => {
  //   let link = "";
  //   if (!!props.location.state) {
  //     if (props.location.state.from === "all-bmc") {
  //       link = `/${props.location.state.from}/${props.location.state.state}`;
  //       setReturnLink(link);
  //       setB("All BMCs");
  //     } else if (props.location.state.from === "totalsites") {
  //       link = `configuration/${props.location.state.from}`;
  //       setReturnLink(link);
  //       setB("Total Sites");
  //     }
  //   }
  // }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("critical_Alerts_Header_Label")}
          breadcrumbs={[
            {
              label: t("critical_Alerts_Breadcrum_Dashboard_Label"),
              link: "/dashboard"
            },
            { label: t("critical_Alerts_Breadcrum_All_Bmc") },
            { label: t("critical_Alerts_Breadcrum_Events") }
          ]}
        />
      </Grid>
      <TemplateForTables needDropdown={false} importIcons>
        {status === "loading" ? (
          <Grid
            container
            xs={12}
            justify="center"
            alignItems="center"
            style={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Table
            columns={columns}
            data={data}
            paginationInfo={{
              pageIndex,
              nextPage,
              previousPage,
              hasNextPage
            }}
          />
        )}
      </TemplateForTables>
    </Grid>
  );
};

export default CriticalAlerts;
