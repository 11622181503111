import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
export default function PrivateRoute({
  component: Component,
  metaProps,
  ...rest
}) {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const { keycloak } = useKeycloak();
  return (
    <Route
      {...rest}
      render={props =>
        !!keycloak && !!keycloak.authenticated ? (
          <Component {...metaProps} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}
