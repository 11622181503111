import React from "react";
import pdfIcon from "../../../assets/pdfred.png";

export default function PdfIcon() {
  return (
    <img
      src={pdfIcon}
      alt="pdf icon"
	    style={{ width: "3rem",height:"100%" }}
    />
  );
}
