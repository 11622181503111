import { func } from "prop-types";
import { utilityServices } from "./utilityServices";

function getToken() {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    const token = user && user.jwtAccessToken;
    return token;
}

async function getApproveListByStatus(prodStatus){
  const url = `${process.env.REACT_APP_GET_PRODUCT_BY_STATUS}/${prodStatus}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
       authToken: localStorage.getItem("idToken"),
       "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
       Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function adminUpdateStatus(id,itemStatus,values){
  const url = `${process.env.REACT_APP_GET_PRODUCT_BY_STATUS}/${id}/${itemStatus}`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    },
    body: JSON.stringify(values)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getDataByAppType(itemType,itemStatus,appType){
  const url = `${process.env.REACT_APP_GET_APPROVED_DATA_BY_APPTYPE_AND_STATUS}/${itemType}/${itemStatus}?appType=${appType}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
     // "Api-Key": process.env.REACT_APP_APPROVE_FLOW_API_KEY,
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    }
   // body: JSON.stringify(values)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
} 

async function bulkApproveRejectPriceData(status,data){
  const url = `${process.env.REACT_APP_BULK_PRICE_APRROVE_REJECT}/${status}`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    },
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

export const approveServices = {
  getApproveListByStatus,
  adminUpdateStatus,
  getDataByAppType,
  bulkApproveRejectPriceData
};
