import { func } from "prop-types";
import { utilityServices } from "./utilityServices";
import { language } from "i18next";

function getToken() {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    const token = user && user.jwtAccessToken;
    return token;
}

async function BannerCreate(values){
  const url = process.env.REACT_APP_BANNER_CREATE
  const requestOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    authToken: localStorage.getItem("idToken"),
    "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
    Authorization:`Bearer ${localStorage.getItem("bearerToken")}`    
  },
  body: JSON.stringify(values)
};
try {
  const response = await fetch(url, requestOptions);
  if (response.ok) {
    return await response.json();
  } else {
    throw response;
  }
} catch (error) {
  let err = {
    status: error.status,
    error: !!error.body ? await error.json() : true
  };
  throw err;
}
}

async function getBannerList(fetchtag,apptype,language){  
  //const url = `${process.env.REACT_APP_GET_BANNER_LIST}?appType=${apptype}`;  // &locale=${language  
  const url = `${process.env.REACT_APP_GET_BANNER_LIST}?appType=${apptype}&locale=${language}`;
  //const url = `${process.env.REACT_APP_GET_BANNER_LIST}?appType=SMART_FARMS&locale=en`; 
  const requestOptions = {
    method: "GET",
    headers: {
     // "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),      
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`,
      fetchTag: (fetchtag) // Passing dropdownfield
    },
    body: JSON.stringify()
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getFetchTag(values){
  const url = process.env.REACT_APP_GET_BANNER_LIST_FETCH_TAG;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",      
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getBannerOptions(App_data){
  
   //const url = `${process.env.REACT_APP_GET_BANNER_FORM_BANNER_OPTIONS}?keyName=${"*"}&type=${"BANNER"}`; 
  //  const url = `http://localhost/cloudnotificationservice/nexgen/ui/bannerOptions?keyName=${App_data}&type=BANNER`;  
  // const url= `http://164.52.196.23/cloudnotificationservice/nexgen?keyName=APP_TYPE&type=BANNER`;
   const url = `${process.env.REACT_APP_GET_BANNER_FORM_BANNER_OPTIONS}?keyName=${App_data}&type=BANNER`;
   const requestOptions = {
     method: "GET",
     headers: {
      // "Content-Type": "application/json",
       authToken: localStorage.getItem("idToken"),      
       "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
       Authorization:`Bearer ${localStorage.getItem("bearerToken")}`       
     },
     body: JSON.stringify()
   };
   try {
     const response = await fetch(url, requestOptions);
     if (response.ok) {
       return await response.json();
     } else {
       throw response;
     }
   } catch (error) {
     let err = {
       status: error.status,
       //error: !!error.body ? await error.json() : true
     };
     throw err;
   }
}

export const bannerService = { 
  BannerCreate,
  getBannerList,
  getFetchTag,
  getBannerOptions  
};
