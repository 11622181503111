import React, { useEffect, useContext, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import PageHeader from "../../../components/layout/PageHeader.js";
import { approveServices } from "../../../services/approveServices.js";

import CircularProgress from "@material-ui/core/CircularProgress";
import TemplateForTables from "../../../components/layout/innerPagesTemplate.js";
import { Grid, Paper } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

import { errorContext } from "context/errorContext";
import addDays from "date-fns/addDays";

import "../../../components/Table.scss";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import CommonForm from "components/common/form/common-form";
import { func } from "prop-types";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
import FailureFeedback from "components/common/elements/FailureFeedback";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Table from "components/common/table/DynamicPaginationTable2";
import { agentAssociationService } from "services/agentAssociationServices";
import { provisioningService } from "services/provisioningServices";

import WarehouseAssociateForm from "./WarehouseAssociateForm";
import { warehouseService } from "services/warehouseServices";
import { customeGroupService } from "services/customerGroupServices";

const WarehouseAssociation = props => {

  const { t } = useTranslation();
  const { id, name } = useParams();
  const { dispatch: errorDispatch } = useContext(errorContext);
  const [value, setValue] = React.useState(0);
  const [postOfficeData, setPostOfficeData] = React.useState([]);

  const [stateData,setStateData] = React.useState([]);
  const [status, setStatus] = useState("loading"); 
  const [orgData,setOrgData ] = React.useState([]);
  const [pageInfo, setPageInfo] = React.useState(null);

  const [warehouseData,setWarehouseData] = React.useState([]);
  const [customerGroupData, setCustomerGroupData] = React.useState([]); 
  const [categoryData, setCategoryData] = React.useState([]);
  const [organizationData, setOrganizationData] = React.useState([]);
  const [edit,setEdit] = React.useState(false);

  const [mandatoryMQPErrorMessage,setMandatoryMQPErrorMessage] = useState("");
  const [pincodeSelectionError,setPincodeSelectionError] = useState("");
  const [warehouseAssociateStatus, setWarehouseAssociateStatus] = useState("loading");
  const [warehouseStatusMessage,setWarehouseStatusMessage] =  React.useState("");
  const [warehouseFailedMessage,setWarehouseFailedMessage] = React.useState("");
  const [categoryProductData,setCategoryProductData] = React.useState("");

  let query = useMemo(() => new URLSearchParams(props.location.search), [
    props.location
  ]);
  
  function invokeInitialData (){
    warehouseService.getWarehouseListData().then(data => {
      setWarehouseData(data);
    }).catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });
  
    customeGroupService.getAllCustomerGroup().then(data => {
      setCustomerGroupData(data.filter(data =>{
        return data.sourceType === "smartfarms_app";
      }));
    }).catch(error => {
       errorDispatch({
         type: "ERROR",
         error
       });
    });
   
    let pageSize = 0;
    let pageIndex = 3000;
    warehouseService.getAllCategoryData(pageSize,pageIndex).then(data => {
      setCategoryData(data.content);
      setPageInfo(data.totalPages);
    }).catch(error => {
      console.error(error); 
        errorDispatch({
          type: "ERROR",
          error
      });
    });

    warehouseService.getCategoryProductData().then(data => {
      setCategoryProductData(data);
      setStatus("success");
    }).catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
    });
    
    provisioningService.getAllOrgs().then(data => {
      setOrganizationData(data);
    }).catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
    });
  }

  useEffect(() => {
    provisioningService.verifyOtpNew('1313131313').then((res) => {       
      const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
      const organizationId = JSON.parse(localStorage.getItem("orgId"));     
      invokeInitialData();
    })
    .catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });   
  }, []);

  const formikForm = React.useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let values = {
    ccId:"",
    orgId:"",
    societyUniqueId:"",
    warehouseId:"",   
    isMQP:false,
    isMEP:false,
    customerGroup:[],
    categories:[],      
    pincodeBased:[],
    productId:[]
  };  

  function handleSubmit(values, resetForm, setSubmitting){

    values.categories = selectedCategoryAndProducts;
    
    values.warehouseId = parseInt(id);    
    setMandatoryMQPErrorMessage("");
    setPincodeSelectionError("");    

    if(values.warehouseId === "" || values.customerGroup.length === 0  || selectedCategoryAndProducts.length === 0 ){
      setMandatoryMQPErrorMessage("Please Select Customer Group, Categories and Products.");
      setSubmitting(false);
      return;
    }
    
    if(values.isMQP === true && values.isMEP === false){
      if(values.orgId === "" && values.ccId === ""){
        setMandatoryMQPErrorMessage("Please Select Organization and MQP");
        setSubmitting(false);
        return;
      }

      if(values.orgId.length > 0 && values.ccId.length === 0){
        setMandatoryMQPErrorMessage("Please Select MQP");
        setSubmitting(false);
        return;
      }
    }else if(values.isMEP === true && values.isMQP === false){
      if(values.orgId === "" && values.ccId === "" && values.societyUniqueId === ""){
        setMandatoryMQPErrorMessage("Please Select Organization, MQP and MEP");
        setSubmitting(false);
        return;
      }

      if(values.orgId.length > 0 &&  values.ccId.length > 0 &&values.societyUniqueId.length === 0){
        setMandatoryMQPErrorMessage("Please Select MEP");
        setSubmitting(false);
        return;
      }
    }else if(values.isMQP === true && values.isMEP === true){
      setMandatoryMQPErrorMessage("Please Select Either MQP or MEP");
      setSubmitting(false);
      return;
    }else {
      if(values.pincodeBased.length === 0){
        setPincodeSelectionError("Please select the Pincode");
        setSubmitting(false);
        return;
      }
    }   

    values.orgId = parseInt(values.orgId);
    values.ccId = parseInt(values.ccId);

    values.pincode = [];

    if(values.pincodeBased.length > 0){
      for(var i=0;i<values.pincodeBased.length;i++){
        values.pincode.push(parseInt(values.pincodeBased[i].pincode));
      }
    }

    delete values["pincodeBased"];

    warehouseService.associateWarehouse(values).then(data => {
      console.log(data);
      setWarehouseStatusMessage(data.message);
      setWarehouseAssociateStatus("success");
      setSubmitting(false);
      resetForm();   
    }).catch(error => {
      setWarehouseFailedMessage("failed");
      errorDispatch({
        type: "ERROR",
        error
      });
     
    });
  }

  const [selectedCategoryAndProducts,setSelectedCategoryAndProducts] = React.useState([]);
  const getSelectedCategoryAndProducts = (data) => {
    setSelectedCategoryAndProducts(data);
  };

  return ( 
    <WarehouseAssociateForm
      values = {values}
      warehouseData = {warehouseData}
      warehouseName = {name}
      warehouseId = {id}
      organizationData = {organizationData}
      stateData = {stateData}
      customerGroupData={customerGroupData}
      categoryData={categoryData}
      categoryProductData={categoryProductData}
      status = {status}
      edit = {edit}
      handleSubmit = {handleSubmit}
      mandatoryMQPErrorMessage = {mandatoryMQPErrorMessage}
      pincodeSelectionError = {pincodeSelectionError}
      warehouseAssociateStatus = {warehouseAssociateStatus}
      warehouseStatusMessage = {warehouseStatusMessage}
      warehouseFailedMessage = {warehouseFailedMessage}
      getSelectedCategoryAndProducts = {getSelectedCategoryAndProducts}
    /> 
  );
}

export default WarehouseAssociation;
