import React from 'react';
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import 'bootstrap/dist/css/bootstrap.css';
import { Paper } from '@material-ui/core';
import PageHeader from "components/layout/PageHeader";
import ExcelIcon from "../../../components/common/elements/ExcelIcon";
import { RetailerImportService } from "../../../services/retailer-import";
import SweetAlert from 'react-bootstrap-sweetalert';
import importRetailerTemplate from "components/Files/importRetailerTemplate.xlsx";
import { provisioningService } from "services/provisioningServices";
import './importRetailer.css';

const ColorButton = withStyles(theme => ({
    root: {
        backgroundImage: "linear-gradient(to bottom, #34409A, #14AAE4)",
        width: "100%",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "linear-gradient(to bottom, #34409A, #14AAE4)"
        }
    }
}))(Button);

const ImportRetailer1 = () => {
    const retailerImportService = new RetailerImportService();
    const [showUploadSuccessAlert, setShowUploadSuccessAlert] = React.useState(false);
    const [showUploadFailureAlert, setShowUploadFailureAlert] = React.useState(false);
    const [showImportFailedRecordsAlert, setShowImportFailedRecordsAlert] = React.useState(false);
    const [showImportAllRecordsProcessedAlert, setShowImportAllRecordsProcessedAlert] = React.useState(false);
    const [failedRecordsData ,setFailedRecordsData] = React.useState([]);
    const [hideProcessingElem, setHideProcessingElem] = React.useState(true);
    const [disableOkBtn, setDisableOkBtn] = React.useState(true);

    const [retailerImport, setRetailerImport] = React.useState("");
    const [retailerExcel, setRetailerExcel] = React.useState("");
    const [retailerFilename, setRetailerFilename] = React.useState("");
    const user = JSON.parse(localStorage.getItem("currentUser"));

    const resetHandler = (event) => {
        setRetailerImport("");   
        setRetailerFilename("");
    }
    
    const hideAlert = (event) => {
        setShowUploadFailureAlert(false);
        setShowUploadSuccessAlert(false);
        setShowImportFailedRecordsAlert(false);
        setShowImportAllRecordsProcessedAlert(false);
        resetHandler();
    }

    const previewRetailer = (event) => {
        setRetailerExcel(event.target.files[0]);
        setRetailerFilename(event.target.files[0].name);
    }   

    const uploadRetailer = (event) => {
        const data = new FormData();
        data.append('retailerUploadDocument', retailerExcel);
        retailerImportService.triggerRetailerImport(data).then((response) => {
            if (response.status === 200) {
                setShowUploadSuccessAlert(true);
                setRetailerExcel("");
                setShowImportFailedRecordsAlert(false);
                setHideProcessingElem(true);
                setDisableOkBtn(true);
                const failedRecordRequestId = response.data.requestId;
                setTimeout(() => {
                    provisioningService.getRetailerImportFailedRecords(failedRecordRequestId).then(data1 => {
                        setFailedRecordsData(data1[0].failedRecords);
                        setShowUploadSuccessAlert(true);
                        setShowImportFailedRecordsAlert(true);
                        setHideProcessingElem(false);
                        setDisableOkBtn(false);
                        if(data1[0].failedRecords[0].importStatus.status.includes('Success')){
                            setShowImportAllRecordsProcessedAlert(true);
                            setShowImportFailedRecordsAlert(false);
                        }else{
                            setShowImportAllRecordsProcessedAlert(false);                       
                        }
                      });  
                }, 180000);

            } else {
                setShowUploadFailureAlert(true);
            }
        })
            .catch(function (error) {
                console.log(error);
                if (error.response) {
                    setShowUploadFailureAlert(true);
                } else {
                    //some other error happened
                    setShowUploadFailureAlert(true);
                }
            })
    }  

    const backAction = (event) => {
        window.history.go(-1);
    }

    if (!showUploadSuccessAlert && !showUploadFailureAlert) {
        return (
            <Grid container>
                <Grid item xs={12} style={{ marginBottom: "15px" }}>
                    <PageHeader title="Import Retailer Details"
                        breadcrumbs={[
                            { label: "Retailers" },
                            { label: "Import Retailer Details" }
                        ]} />
                </Grid>
                <Grid item xs={9} md={8}>
                    <Paper elevation={2} style={{ width: "100%", padding: "1.5em" }}>
                        {user.uiPrivileges.includes("ALL_PRIVILEGE") || user.uiPrivileges.includes("CONFIGURATION") ?
                            <header style={{
                                fontSize: "16px", color: "#166aab",
                                fontWeight: "bold"
                            }}>Retailers </header> : null}
                        {user.uiPrivileges.includes("ALL_PRIVILEGE") || user.uiPrivileges.includes("CONFIGURATION") ?
                            <Grid item container justify="space-between" alignItems="center"
                                style={{ padding: "1em" }}>
                                <Grid item container md={6} alignItems="center">
                                    <span style={{ fontSize: "14px" }}>Choose File </span>
                                    <ExcelIcon />
                                    <a href={importRetailerTemplate} download="importRetailerTemplate.xlsx">Download File
                                    <i className="fa fa-download" id="iconId"></i>
                                    </a>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    {retailerExcel ? <div>
                                        <header style={{ fontSize: "14px", color: "#166aab" }}>{retailerFilename}</header>
                                        <div style={{ display: "flex", marginTop: "10px" }}>
                                            <div style={{ width: "50%", marginRight: "3px" }}>
                                                <Button variant="contained" color="secondary" onClick={() => setRetailerExcel("")}
                                                    fullWidth={true} startIcon={<DeleteIcon />} style={{ textTransform: "capitalize" }} >
                                                    Clear
                                    </Button>
                                            </div>
                                            <div style={{ width: "50%", marginLeft: "3px" }}>
                                                <Button variant="contained" color="primary" onClick={uploadRetailer}
                                                    fullWidth={true} startIcon={<CloudUploadIcon />} style={{ textTransform: "capitalize" }} >
                                                    Upload
                                    </Button>
                                            </div>
                                        </div>
                                    </div> : <ColorButton variant="contained"
                                        color="primary"
                                        component="label"
                                        className="align-self-end w-100 text-capitalize">
                                            Browse
                                    <Input type="file" onChange={previewRetailer}
                                                style={{ display: "none" }}
                                                inputProps={{ accept: ".xlsx, .xls, .csv" }}
                                                name="retailerImport" value={retailerImport} />
                                        </ColorButton>}
                                </Grid>
                            </Grid> : null}


                        <div className="row" style={{ marginTop: "30px" }}>
                            <div className="col-md-12 col-xs-12"
                                style={{ borderBottom: "1px solid #DEE9E8" }}></div>
                        </div><br />
                        <div className="row">
                            <div className="col-md-3 col-xs-3"></div>
                            <div className="col-md-3 col-xs-3"></div>
                            <div className="col-md-3 col-xs-3"></div>
                            <div className="col-md-3 col-xs-3">
                                <button style={{
                                    backgroundColor: "#166aab",
                                    border: "0",
                                    color: "white"
                                }}
                                    onClick={backAction}
                                    className="form-control">Back
                            </button>
                            </div>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        );
    } else if (showUploadSuccessAlert) {
        return (
            <>
            <SweetAlert success
                        //title="Success!" 
                        onConfirm={hideAlert}
                        disabled={ disableOkBtn ? true : false }
                        onCancel={hideAlert}
                        >
                <br />
                <span style={{ fontSize: "14px", color: "green" }}><b>File Upload Successfull !!</b></span>
                <br />
                { hideProcessingElem &&
                    <>
                    <span style={{fontSize: "16px", color: "red", fontWeight: "bold"}}><b>Records processing, wait for few minutes to see the processed results !!</b></span>
                    <br /> 
                    <div className="lds-hourglass"></div>
                    </>
                }         
                <br />             
                <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "5px" }}>                  
                { showImportFailedRecordsAlert &&
                    <h5 style={{textAlign: "left", fontWeight: "bold", margin: "0 auto"}}>Failed Records (while processing)</h5>
                }
                { showImportAllRecordsProcessedAlert &&
                    <h5 style={{textAlign: "left", fontWeight: "bold", margin: "0 auto"}}>Processed Results</h5>
                }
                 </div>
                { showImportFailedRecordsAlert &&
                    <table className="table table-bordered" id="errorListTable">
                       <thead>
                         <tr>
                             <th style={{ fontSize: "12px" }}>Name</th>
                             <th style={{ fontSize: "12px" }}>Status</th>
                             <th style={{ fontSize: "12px" }}>Message</th>
                         </tr>
                         </thead>
                        <tbody>
                         {failedRecordsData.map((item, index) => {
                             return (
                                <React.Fragment key={index}>
                                     <tr>                                       
                                         <td style={{ fontSize: "15px" }}>{item.name}</td>
                                         <td style={{ fontSize: "15px" }}>{item.importStatus.status}</td>
                                         <td style={{ fontSize: "15px", whiteSpace:"pre-line" }}>{item.importStatus.message}</td>
                                     </tr>
                                 </React.Fragment>
                             );
                         })}
                        </tbody>
                    </table>           
                }

                { showImportAllRecordsProcessedAlert &&
                    <table className="table table-bordered" id="errorListTable">
                       <thead>
                         <tr>
                             <th style={{ fontSize: "12px" }}>Status</th>
                             <th style={{ fontSize: "12px" }}>Message</th>
                         </tr>
                         </thead>
                        <tbody>
                         {failedRecordsData.map((item, index) => {
                             return (
                                <React.Fragment key={index}>
                                     <tr> 
                                         <td style={{ fontSize: "15px" }}>{item.importStatus.status}</td>
                                         <td style={{ fontSize: "15px", whiteSpace:"pre-line" }}>{item.importStatus.message}</td>
                                     </tr>
                                 </React.Fragment>
                             );
                         })}
                        </tbody>
                    </table>           
                }
            </SweetAlert>
            </>
        );
    } else {
        return null;
    }
}

export default ImportRetailer1;
