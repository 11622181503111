import { all, call, put, takeEvery, fork, select } from "redux-saga/effects";
import { chillingGraphServices } from "services/chillingGraphServices";
import closestTo from "date-fns/closestTo";
import parseISO from "date-fns/parseISO";
import compareAsc from "date-fns/compareAsc";

function* fetchGraphData() {
  yield takeEvery("CHILLING_GRAPH_FETCH_DATA", function* fetchGraph(action) {
    try {
      if (action.UUID) {
        {
          const fetchParams = yield call(
            chillingGraphServices.getDeviceEvents,
            action.UUID
          );
          const dataArray = [
            "Volume",
            "Battery Temperature",
            "Temperature",
            "Milk pump",
            "GRID",
            "DG",
            "Compressor feedback",
            "Aggitator feedback"
          ];
          const newData = fetchParams.records;
          const fallBackArray = [
            "VOLUME",
            "TEMP2",
            "TEMP",
            "MILKPUMPIBT",
            "GRID",
            "DG",
            "COMPFBIBT",
            "AGZFB"
          ];
          // console.log(newData)
          // console.log(fallBackArray);
          newData.map(data => {
            dataArray.map((d, index) => {
              if (data.displayEventName.toLowerCase() === d.toLowerCase()) {
                fallBackArray[index] = data.eventName;
              }
            });
          });
          const dataChecker = fetchParams.records.map(res => res.eventName);
          if (!dataChecker.includes("DG")) {
            dataChecker.push("DG");
          }
          const result = dataChecker.sort().reverse();
          const [
            vol = { result: { records: [] } },
            batteryTemp = { result: { records: [] } },
            temp = { result: { records: [] } },
            milkpump = { result: { records: [] } },
            grid = { result: { records: [] } },
            dg = { result: { records: [] } },
            comp = { result: { records: [] } },
            agitator = { result: { records: [] } }
          ] = yield all(
            fallBackArray
              .sort()
              .reverse()
              .map(r => {
                return call(
                  chillingGraphServices.getChillingEventsData,
                  action.UUID,
                  action.timePeriod[0],
                  action.timePeriod[1],
                  r
                );
              })
          );
          const tempData = temp.result.records.map(record => {
            let timeNow = new Date(record.timeData.processedTime).toISOString();
            return {
              name: timeNow,
              value: [timeNow, record.temperature],
              symbol: "none"
            };
          });
          const volData = vol.result.records.map(record => {
            let timeNow = new Date(record.timeData.processedTime).toISOString();
            return {
              name: timeNow,
              value: [timeNow, record.volume],
              symbol: "none"
            };
          });
          const gridData = grid.result.records.map(record => {
            let timeNow = new Date(record.timeData.processedTime).toISOString();
            return {
              name: timeNow,
              value: [timeNow, record.eventState.numericValue],
              symbol: "none"
            };
          });
          const agitatorData = agitator.result.records.map(record => {
            let timeNow = new Date(record.timeData.processedTime).toISOString();
            return {
              name: timeNow,
              value: [timeNow, record.eventState.numericValue],
              symbol: "none"
            };
          });
          const compData = comp.result.records.map(record => {
            let timeNow = new Date(record.timeData.processedTime).toISOString();
            return {
              name: timeNow,
              value: [timeNow, record.eventState.numericValue],
              symbol: "none"
            };
          });
          const dgData = dg.result.records.map(record => {
            let timeNow = new Date(record.timeData.processedTime).toISOString();
            return {
              name: timeNow,
              value: [timeNow, record.eventState.numericValue],
              symbol: "none"
            };
          });
          const milkpumpData = milkpump.result.records.map(record => {
            let timeNow = new Date(record.timeData.processedTime).toISOString();
            return {
              name: timeNow,
              value: [timeNow, record.eventState.numericValue],
              symbol: "none"
            };
          });
          const batteryTempData = batteryTemp.result.records.map(record => {
            let timeNow = new Date(record.timeData.processedTime).toISOString();
            return {
              name: timeNow,
              value: [timeNow, record.eventValue],
              symbol: "none"
            };
          });
          const data = {
            vol: volData,
            temp: tempData,
            grid: gridData,
            agitator: agitatorData,
            dg: dgData,
            comp: compData,
            milkpump: milkpumpData,
            batteryTemp: batteryTempData,
            action: action
          };
          yield put({
            type: "CHILLING_GRAPH_DATA_FETCH_SUCCESS",
            data
          });

          // yield put({
          //   type: "CHILLING_GRAPH_COMPUTE_MARKPOINTS",
          //   data
          // });

          // yield fork(computeMarkPoints, data);

          yield put({
            type: "CHILLING_GRAPH_CRITICAL_MARKPOINTS"
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  });
}

function* fetchAlertsData() {
  yield takeEvery("CHILLING_GRAPH_ALERTS_DATA_FETCH", function* fetchData(
    action
  ) {
    try {
      const alerts = yield call(
        chillingGraphServices.getAlertsData,
        action.bmcId,
        action.timePeriod[0],
        action.timePeriod[1]
      );
      yield put({
        type: "CHILLING_GRAPH_ALERTS_DATA_FETCH_SUCCESS",
        alerts: alerts.result
      });

      const critical = alerts.result.records.filter(alert => {
        return alert.priority === "CRITICAL";
      });

      yield put({
        type: "CHILLING_GRAPH_CRITICAL_ALERTS",
        alerts: critical
      });
    } catch (e) {
      yield put({
        type: "CHILLING_GRAPH_ALERTS_DATA_FETCH_FAILED"
      });
    }
  });
}

function* computeMarkPoints(data) {
  const { grid, vol, dg, agitator, milkpump, comp } = data;

  let gridPoints = [];
  if (grid.length > 0) {
    let prevGrid = grid[0].value[1];

    for (let i = 0; i < grid.length; i++) {
      let current = grid[i].value[1]; //{name: timeString, value [time, value]}
      let markPointData;

      if (current !== prevGrid) {
        markPointData = grid[i];
        gridPoints.push(markPointData);
      }
      prevGrid = current;
    }
  }

  let agitatorPoints = [];
  if (agitator.length > 0) {
    let prevAgitator = agitator[0].value[1];

    for (let i = 0; i < agitator.length; i++) {
      let current = agitator[i].value[1]; //{name: timeString, value [time, value]}
      let markPointData;

      if (current !== prevAgitator) {
        markPointData = agitator[i];
        agitatorPoints.push(markPointData);
      }
      prevAgitator = current;
    }
  }

  let dgPoints = [];
  if (dg.length > 0) {
    let prevDg = dg[0].value[1];

    for (let i = 0; i < dg.length; i++) {
      let current = dg[i].value[1]; //{name: timeString, value [time, value]}
      let markPointData;

      if (current !== prevDg) {
        markPointData = dg[i];
        dgPoints.push(markPointData);
      }
      prevDg = current;
    }
  }

  let compPoints = [];
  if (comp.length > 0) {
    let prevComp = comp[0].value[1];

    for (let i = 0; i < comp.length; i++) {
      let current = comp[i].value[1]; //{name: timeString, value [time, value]}
      let markPointData;

      if (current !== prevComp) {
        markPointData = comp[i];
        compPoints.push(markPointData);
      }
      prevComp = current;
    }
  }

  let milkPumpPoints = [];
  if (milkpump.length > 0) {
    let prevMilkpump = milkpump[0].value[1];

    for (let i = 0; i < milkpump.length; i++) {
      let current = milkpump[i].value[1]; //{name: timeString, value [time, value]}
      let markPointData;

      if (current !== prevMilkpump) {
        markPointData = milkpump[i];
        milkPumpPoints.push(markPointData);
      }
      prevMilkpump = current;
    }
  }

  let endLoop = false;

  let volIndex = 0;
  let agitatorIndex = 0;
  let agitatorMarkers = [];
  let gridIndex = 0;
  let gridMarkers = [];
  let compIndex = 0;
  let compMarkers = [];
  let milkpumpIndex = 0;
  let milkpumpMarkers = [];
  let dgIndex = 0;
  let dgMarkers = [];

  while (!endLoop) {
    // if grid time greater return 1, equal 0, less -1
    // if greater or equal, add marker to the vol point
    if (
      agitatorIndex < agitatorPoints.length &&
      compareAsc(
        parseISO(agitatorPoints[agitatorIndex].name),
        parseISO(vol[volIndex].name)
      ) === -1
    ) {
      let marker =
        agitatorPoints[agitatorIndex].value[1] === 1
          ? {
            name: "agitator on",
            coord: [vol[volIndex].name, vol[volIndex].value[1]],
            symbol: "image://assets/graph-icons/agitator-on.png",
            symbolOffset: [0, 0],
            symbolSize: 25
          }
          : {
            name: "agitator off",
            // coord: [matchingVol[0].name, matchingVol[0].value[1]],
            coord: [vol[volIndex].name, vol[volIndex].value[1]],
            symbol: "image://assets/graph-icons/agitator-off.png",
            symbolOffset: [0, 0],
            symbolSize: 25
          };
      agitatorMarkers.push(marker);
      ++agitatorIndex;
    }
    if (
      compIndex < compPoints.length &&
      compareAsc(
        parseISO(compPoints[compIndex].name),
        parseISO(vol[volIndex].name)
      ) === -1
    ) {
      let marker =
        compPoints[compIndex].value[1] === 1
          ? {
            name: "comp on",
            coord: [vol[volIndex].name, vol[volIndex].value[1]],
            symbol: "image://assets/graph-icons/comp-on.png",
            symbolOffset: [0, 0],
            symbolSize: 25
          }
          : {
            name: "comp off",
            coord: [vol[volIndex].name, vol[volIndex].value[1]],
            symbol: "image://assets/graph-icons/comp-off.png",
            symbolOffset: [0, 0],
            symbolSize: 25
          };
      compMarkers.push(marker);
      ++compIndex;
    }
    if (
      milkpumpIndex < milkPumpPoints.length &&
      compareAsc(
        parseISO(milkPumpPoints[milkpumpIndex].name),
        parseISO(vol[volIndex].name)
      ) === -1
    ) {
      let marker =
        milkPumpPoints[milkpumpIndex].value[1] === 1
          ? {
            name: "milkpump on",
            coord: [vol[volIndex].name, vol[volIndex].value[1]],
            symbol: "image://assets/graph-icons/milkpump-on.png",
            symbolOffset: [0, 0],
            symbolSize: 25
          }
          : {
            name: "milkpump off",
            coord: [vol[volIndex].name, vol[volIndex].value[1]],
            symbol: "image://assets/graph-icons/milkpump-off.png",
            symbolOffset: [0, 0],
            symbolSize: 25
          };
      milkpumpMarkers.push(marker);
      ++milkpumpIndex;
    }
    if (
      dgIndex < dgPoints.length &&
      compareAsc(
        parseISO(dgPoints[dgIndex].name),
        parseISO(vol[volIndex].name)
      ) === -1
    ) {
      let marker =
        dgPoints[dgIndex].value[1] === 1
          ? {
            name: "dg on",
            coord: [vol[volIndex].name, vol[volIndex].value[1]],
            symbol: "image://assets/graph-icons/dg-on.png",
            symbolOffset: [0, 0],
            symbolSize: 25
          }
          : {
            name: "dg off",
            coord: [vol[volIndex].name, vol[volIndex].value[1]],
            symbol: "image://assets/graph-icons/dg-off.png",
            symbolOffset: [0, 0],
            symbolSize: 25
          };
      dgMarkers.push(marker);
      ++dgIndex;
    }
    if (
      gridIndex < gridPoints.length &&
      // (compareAsc(
      //   parseISO(gridPoints[gridIndex].name),
      //   parseISO(vol[volIndex].name)
      // ) === -1
      compareAsc(
        parseISO(gridPoints[gridIndex].name),
        parseISO(vol[volIndex].name)
      ) === -1
    ) {
      let marker =
        gridPoints[gridIndex].value[1] === 1
          ? {
            name: "grid on",
            coord: [vol[volIndex].name, vol[volIndex].value[1]],
            symbol: "image://assets/graph-icons/grid-on.png",
            symbolOffset: [0, 0],
            symbolSize: 25
          }
          : {
            name: "grid off",
            // coord: [matchingVol[0].name, matchingVol[0].value[1]],
            coord: [vol[volIndex].name, vol[volIndex].value[1]],
            symbol: "image://assets/graph-icons/grid-off.png",
            symbolOffset: [0, 0],
            symbolSize: 25
          };
      gridMarkers.push(marker);
      ++gridIndex;
    }
    ++volIndex;
    if (volIndex >= vol.length) {
      endLoop = true;
    }

    // volDates[volIndex];
    // gridPoints[gridIndex];
  }

  let markPoints = {
    agitator: agitatorMarkers,
    grid: gridMarkers,
    dg: dgMarkers,
    comp: compMarkers,
    milkpump: milkpumpMarkers
  };

  yield put({
    type: "CHILLING_UNIT_MARK_POINT_COMPUTED_SUCCESS",
    markPoints
  });
}

function* computeCriticalMarks() {
  yield takeEvery("CHILLING_GRAPH_CRITICAL_MARKPOINTS", function* computeMarks(
    alerts
  ) {
    try {
      const critical = yield select(
        state => state.chillingGraph.toJS().criticalAlerts
      );
      const vol = yield select(state => state.chillingGraph.toJS().data.vol);
      const markPoints = [];
      if (critical.length > 0) {
        for (let i = 0; i < critical.length; i++) {
          let current = critical[i];

          let volDates = vol.map(v => new Date(v.name));
          const match = closestTo(parseISO(current.occurredOn), volDates);

          const matchingVol = vol.filter(item => {
            if (+parseISO(item.name) === +match.getTime()) {
            }
            return +parseISO(item.name) === +match.getTime();
          });
          let markPointData = {
            name: "critical",
            coord: [matchingVol[0].name, matchingVol[0].value[1]],
            symbol: "image://assets/graph-icons/alert.png",
            symbolOffset: [0, 0],
            symbolSize: 25,
            alertName: current.alertName
          };

          markPoints.push(markPointData);
        }
        yield put({
          type: "CHILLING_GRAPH_CRITICAL_MARKERS",
          markPoints
        });
      }
    } catch (e) {
      console.log("error in critical points", e);
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(fetchGraphData),
    fork(fetchAlertsData),
    // fork(computeMarkPoints),
    fork(computeCriticalMarks)
  ]);
}
