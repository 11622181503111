import React from "react";

import { Link } from "react-router-dom";

import SidebarMenuItems from "./SidebarMenuItems";

import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";

const useStyles = makeStyles({
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  }
});

export default function MobileSidebar({ open, handleClose }) {
  const classes = useStyles();

  return (
    <div>
      <Drawer open={open} onClose={handleClose}>
        <div className={classes.list} id="main-menu" role="presentation">
          <div className={classes.toolbar}>
            <Link id="navbar-logo" to="/dashboard" className="mb-8 ">
              <img
                src="/assets/Stellapps_logo.png"
                alt=""
                style={{ width: "80%", display: "block", margin: "1rem auto" }}
                onClick={handleClose}
              />
            </Link>
          </div>
          <List>
            <SidebarMenuItems handleClose={handleClose} open={open} />
          </List>
        </div>
      </Drawer>
    </div>
  );
}
