import service from './warehouse-import-service.js';

export class WarehouseImportService {

    triggerWarehouseImport(data) {
        const options = {
            headers: {
                //authToken: localStorage.getItem("idToken"),
                //Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
                "Content-Type": "multipart/form-data",
                "Api-Key": 'gh7MvvJXnRQO44PatMnoAuBcgLNTfk'
            }
        };
        //returns Promise object
        return service.getWarehouseServiceURL().post('/stellappsAgentService/api/criteria/warehouse/associate/upload', data, options);
    }

}