import React, { useState, useEffect } from "react";
import { FieldArray, Field, getIn } from "formik";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { TextField } from "formik-material-ui";
import { useTranslation } from "react-i18next";

export default function BannerExtraData({ name, TaskValuesTypes, renderFields, elemProp }) {

  const { t } = useTranslation();
  return (
    <FieldArray name={name}>
      {({ insert, remove, push, form }) => {
        let {
          values,
          errors,
          touched,
          setFieldTouched,
          setFieldValue,
          handleChange
        } = form;
        return (
          <>
            <Grid item xs={12}>
             {elemProp === "Extra Data" ? <h6 style={{ color:"black"}}>{t("notification_Form_ExtraData_Label")}</h6> : null}
            </Grid>
            {/* {console.log("COming from", values)}; */}

            {getIn(values, name).length > 0 &&
              getIn(values, name).map((subTask, childIndex) => (
                <Grid
                  container
                  item
                  xs={12}
                  className="mb-0 d-flex flex-direction-column device-wrapper"
                  key={childIndex}
                >
                  <Grid
                    item
                    container
                    xs={12}
                    spacing={2}
                    alignContent="flex-start"
                    className="site-inner"
                  >
                    <Grid item xs={11} md={3}>
                      <Field
                        type="text"
                        name={`${name}.${childIndex}.subKey`}
                        label={t("loanInsurance_Form_Key_Label")}
                        component={TextField}
                        value={`${name}.${childIndex}.subKey`}
                        disabled
                        error={getIn(
                          errors,
                          `${name}.${childIndex}.subKey`
                        )}
                        margin="dense"
                        fullWidth={true}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={11} md={3}>
                      <Field
                        name={`${name}.${childIndex}.subValue`}
                        label={t("loanInsurance_Form_Value_Label")}
                        className="placeholder-inner"
                        component={TextField}
                        value={`${name}.${childIndex}.subValue`}
                        margin="dense"
                        fullWidth={true}
                        variant="outlined"
                      ></Field>
                    </Grid>
 
                    <Grid item container xs={1} md={3}>
                      {getIn(values, name).length > 0 &&
                        childIndex === getIn(values, name).length - 1 && (
                          <button
                            type="button"
                            onClick={() => {
                              if (
                                !getIn(errors, `${name}.${childIndex}`) &&
                                getIn(touched, `${name}.${childIndex}`)
                              ) {
                                push({
                                  subKey: "",
                                  subValue: "",
                                  // taskType: "",
                                  // subTaskValue: {}
                                });
                              } else {
                                Object.keys(
                                  getIn(values, `${name}.${childIndex}`)
                                ).forEach(item => {
                                  item !== "subTaskValue" &&
                                    setFieldTouched(
                                      `${name}.${childIndex}.${item}`,
                                      true,
                                      true
                                    );
                                  if (
                                    getIn(
                                      errors,
                                      `${name}.${childIndex}.${item}`
                                    )
                                  ) {
                                    item !== "subTaskValue" &&
                                      setFieldTouched(
                                        `${name}.${childIndex}.${item}`,
                                        true,
                                        true
                                      );
                                  }
                                });
                              }
                            }}
                          className="add-icon"       
                          >
                            {/* <i          //  Requirement not given so commented
                              className="fa fa-plus-circle"
                              aria-hidden="true"
                            ></i> */}
                          </button>
                        )}
                      {getIn(values, name).length > 1 && (
                        <button
                          type="button"
                          className="add-icon delete-icon"
                          onClick={e => {
                            getIn(values, name).length > 0 &&
                              remove(childIndex);
                          }}
                        >
                          <i className="fa fa-minus-circle" aria-hidden="true"></i>
                        </button>
                      )}
                    </Grid>
                  </Grid>


                  {/* </Grid> */}
                </Grid>
              ))}
          </>
        );
      }}
    </FieldArray>
  );
}
