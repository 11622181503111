import React, { useEffect, useContext,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { authContext } from "context/authContext";
import { errorContext } from "context/errorContext";
import addDays from "date-fns/addDays";
import { Grid, Paper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation } from 'react-router-dom'; 
import { isAfter } from "date-fns";
import FailureFeedback from "components/common/elements/FailureFeedback";
import { useTranslation } from "react-i18next";
import CriteriaSelfRegFarmerForm from "./CriteriaSelfRegFarmerForm";
import { farmerAssociationService } from "services/farmerAssociationServices";
import { criteriaService } from "../../../services/criteriaServices.js";
import SuccessFeedback from "components/common/elements/SuccessFeedback";

export default function EditCriteriaForSelfRegFarmer() {

    const { dispatch: errorDispatch } = useContext(errorContext);

    const createError = useSelector(state => state.site.toJS().createError);
    const history = useHistory();
    const { t } = useTranslation();
    const location = useLocation();
    const editSelfRegFarmerData = location.state.from;
    const editStatus = "";
    const edit = "";

    console.log(editSelfRegFarmerData);

    const [pbpData, setPbpData] = React.useState([]);
    const [pbpStatus,setPbpStatus] = React.useState("loading");
    const [status,setStatus] = React.useState(false);
    const [failedStatus,setFailedStatus] = React.useState(false);


    useEffect(() => {
      getListData();
    }, []);

    function getListData(){
      farmerAssociationService.getPbpList().then(data => {    
        setPbpData(data);
        setPbpStatus("success");
      }).catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
    }

    const values = {
      criteriaId: !!editSelfRegFarmerData ? editSelfRegFarmerData.id : "",
      criteriaTags: !!editSelfRegFarmerData ? editSelfRegFarmerData.criteriaTags : "",
      entityId: !!editSelfRegFarmerData ? editSelfRegFarmerData.entityId : "",
      pbpId: !!editSelfRegFarmerData ? editSelfRegFarmerData.pbpId : "",
      customerGroup: !!editSelfRegFarmerData ? editSelfRegFarmerData.customerGroup :"",
      userType: !!editSelfRegFarmerData ? editSelfRegFarmerData.userType : "",
      pincode: !!editSelfRegFarmerData && !!editSelfRegFarmerData.criteria && !!editSelfRegFarmerData.criteria.pincodeBased  ? editSelfRegFarmerData.criteria.pincodeBased[0].pincode : ""
    }

    const handleDialogClose = () => {
      history.push("/configuration/criteria");
    };

    function handleSubmit(values, resetForm, setSubmitting) {
      const territory = pbpData.find(pbpData =>pbpData.id === values.pbpId);
      const entityData = territory.territories.find(territory =>territory.id === values.entityId);
     
      editSelfRegFarmerData.entityName = entityData.name;
      editSelfRegFarmerData.entityId = entityData.id;

      criteriaService.editCriteria(values.criteriaId,editSelfRegFarmerData).then(data => {
        console.log(data);
        setStatus(true);
        setSubmitting(false);
      }).catch(error => {
        setStatus(false);
        setSubmitting(false);
        setFailedStatus(true);
      }); 
    }

    return (
      <>
        <Grid container>
          <CriteriaSelfRegFarmerForm
            values={values}
            handleSubmit={handleSubmit}
            editStatus={editStatus}
            edit={edit}
            createError={createError}
            pbpData={pbpData}
            pbpStatus={pbpStatus}
          />
        </Grid>

        <SuccessFeedback
          open={status === true}
          onClose={handleDialogClose}
          successMessage={t("criteria_Form_Edit_Success_Message")}
        />

        <FailureFeedback
          open={failedStatus === true}
          onClose={handleDialogClose}
          status={"Failed"}
          message={t("criteria_Form_Edit_Failed_Message")}
          showButtons={true} />
      </>
    );
};
