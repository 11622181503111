import React from "react";

import { Field, getIn } from "formik";

import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function SearchMultiSelect({
  options,
  name,
  label,
  placeholder,
  error
}) {
  return (
    <Field>
      {({ field: { value }, form: { setFieldValue, touched } }) => (
        // <Select
        //   fullWidth
        //   variant="outlined"
        //   value={getIn(value, name)}
        //   label="select this item"
        // >
        <Autocomplete
          multiple
          options={options}
          disableCloseOnSelect
          getOptionLabel={option => option.label}
          onChange={(event, newValue) => {
            setFieldValue(name, newValue);
            // console.log(getIn(value, name));
          }}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                style={{ marginRight: 8 }}
                checked={selected}
                color="primary"
              />
              {option.label}
            </React.Fragment>
          )}
          // style={{ width: 500 }}
          renderInput={params => (
            <TextField
              {...params}
              helperText={error && !!getIn(touched, name) ? error : ""}
              error={error && !!getIn(touched, name)}
              variant="outlined"
              margin="dense"
              label={label}
              placeholder={placeholder}
              fullWidth
            />
          )}
        />
        // </Select>
      )}
    </Field>
  );
}
