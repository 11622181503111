import React, { useEffect, useState, useContext } from "react";

import { Link, useParams, useLocation } from "react-router-dom";

import Field from "./Field";
import {
  CustomizedPrimaryButton,
  WhiteButton
} from "../../components/common/elements/CustomizedPrimaryButton";
import PageHeader from "../../components/layout/PageHeader";
import OrgChart from "components/common/elements/OrganizationTree";
import OrgCompleteChart from "components/common/elements/OrgCompleteTree";
import Table from "components/common/table/DynamicPaginationTable";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";


// import "./Profile.scss";
import TemplateForTables from "components/layout/innerPagesTemplate";
import { CircularProgress, IconButton, withWidth } from "@material-ui/core";
import { provisioningService } from "services/provisioningServices";
import { errorContext } from "context/errorContext";
import userConfiguration from "redux/reducers/userConfigurationReducer";
import { useTranslation } from "react-i18next";

const OrganizationProfile = props => {
  const [orgDetails, setOrgDetails] = useState(null);
  const [status, setStatus] = useState("loading");
  const [subOrg, setSubOrg] = React.useState(false);
  const user = JSON.parse(localStorage.getItem("currentUser"));
  // const dispatch = useDispatch();

  const { dispatch: errorDispatch } = useContext(errorContext);
  const { organization } = useParams();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (!!location.state && !!location.state.data) {
      setOrgDetails(location.state.data);
      setStatus("success");
    } else {
      getOrgDetails(organization);
    }
  }, []);

  useEffect(() => {
    if (status !== "loading") {
      getOrgDetails(organization);
    }
  }, [organization]);

  useEffect(() => {
    if (status !== "loading" && !!orgDetails && orgDetails.parentOrgId) {
      setSubOrg(true);
    }
  }, [orgDetails]);

  async function getOrgDetails(name) {
    try {
      const data = await provisioningService.getOrgByName(organization);
      setOrgDetails(data);
      setStatus("success");
    } catch (error) {
      setStatus("failed");
      errorDispatch({
        type: "ERROR",
        error
      });
    }
  }

  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={12}>
        <PageHeader
          title={!subOrg ? t("org_Profile_Org_Details") : t("org_Profile_Sub_Org_Details")}
          breadcrumbs={[
            { label: t("org_Profile_Breadcrum_Dashboard_Label"), link: "/dashboard" },
            {
              label: !subOrg
                ? t("org_Profile_Breadcrum_Org_Label")
                : t("org_Profile_Breadcrum_Sub_Org_Label")
            }
          ]}
        />
      </Grid>

      <Grid item container xs={12} md={6}>
        {!!orgDetails && status !== "loading" && (
          <Paper elevation={2} style={{ padding: "2em", width: "100%" }}>
            <Grid item>
              <h2>{orgDetails.orgName}</h2>
            </Grid>
            <Grid item container xs={12}>
              <Field
                label={subOrg ? t("org_Profile_Sub_Org_Type_Label") : t("org_Profile_Org_Type_Label")}
                value={orgDetails.orgType}
              />
              <Field
                label={subOrg ? t("org_Profile_Sub_Org_Name_Label") : t("org_Profile_Org_Name_Label")}
                value={orgDetails.orgName}
              />
              <Field
                label={subOrg ? t("org_Profile_Sub_Org_Display_Name_Label") :t("org_Profile_Org_Display_Name_Label")}
                value={orgDetails.orgDisplayName}
              />
              <Field
                label={t("org_Profile_Website_Label")}
                value={orgDetails.orgUrl}
                greyLabel={true}
              />
              <h3>{t("org_Profile_Address_Label")}</h3>
              <Field
                label={t("org_Profile_Address_Label")}
                value={
                  <span style={{ fontSize: "inherit" }}>
                    {`${orgDetails.address.addressLine1 ? orgDetails.address.addressLine1+"," : ""}
                ${orgDetails.address.villageTownCity ? orgDetails.address.villageTownCity : ""}`}
                    <br />
                    {`${orgDetails.address.district ? orgDetails.address.district+ " - " : ""} ${orgDetails.address.pinCode ? orgDetails.address.pinCode+"," : ""}`}
                    <br />
                    {`${orgDetails.address.state ? orgDetails.address.state+"," : ""}
                    ${orgDetails.address.country ? orgDetails.address.country : ""}`}
                  </span>
                }
              />
              <h3>{t("org_Profile_Contact_Info_Label")}</h3>
              <Field
                label={t("org_Profile_Contact_Persone_Label")}
                value={orgDetails.contactDetails.contactPersonName}
              />
              <Field
                label={t("org_Profile_Mobile_Label")}
                value={orgDetails.contactDetails.contactNumber}
                greyLabel={true}
              />
              <Field
                label={t("org_Profile_Email_Label")}
                value={orgDetails.contactDetails.emailId}
                greyLabel={true}
              />
            </Grid>

            <Grid
              item
              container
              xs={12}
              spacing={1}
              style={{ marginTop: "1em" }}
            >
              {!!user.uiPrivileges &&
              user.uiPrivileges.includes("ORGANIZATION_CONFIGURATION") ||
              user.uiPrivileges.includes("ALL_PRIVILEGE") ?
              <Grid item md={4} xs={12}>
                <Link to="/configuration/suborganizationprofile">
                  <WhiteButton
                    variant="contained"
                    size="medium"
                    className="w-100 text-capitalize"
                  >
                    {t("org_Profile_Create_Sub_Org_Label")}
                  </WhiteButton>
                </Link>
              </Grid> :null }
              {!!user.uiPrivileges &&
              user.uiPrivileges.includes("SITE_CONFIGURATION") ||
              user.uiPrivileges.includes("ALL_PRIVILEGE") ?
              <Grid item md={4} xs={12}>
                <Link to="/configuration/site">
                  <WhiteButton
                    variant="outlined"
                    size="medium"
                    className="w-100 text-capitalize"
                  >
                    {t("org_Profile_Create_Site_Label")}
                  </WhiteButton>
                </Link>
              </Grid> :null}
              {!!user.uiPrivileges &&
              user.uiPrivileges.includes("ORGANIZATION_CONFIGURATION") ||
              user.uiPrivileges.includes("ALL_PRIVILEGE") ?
              <Grid item md={4} xs={12}>
                <Link
                  to={
                    !!orgDetails.rootOrgId
                      ? `/configuration/suborganizationprofile/${orgDetails.orgId}`
                      : `/configuration/organization/${orgDetails.orgId}`
                  }
                >
                  <CustomizedPrimaryButton
                    variant="contained"
                    color="primary"
                    size="medium"
                    className="w-100"
                    label={t("org_Profile_Edit")}
                  ></CustomizedPrimaryButton>
                </Link>
              </Grid> :null }
            </Grid> 
          </Paper>
        )}
      </Grid>
      <Grid item container direction="column" xs={12} md={6}>
        {status !== "loading" && !!orgDetails && !orgDetails.parentOrgId && (
          <Paper elevation={2} style={{ padding: "2em", height: "100%" }}>
            <Grid
              item
              container
              style={
                props.width === "lg" || props.width === "xl"
                  ? { height: "100%" }
                  : { height: "auto" }
              }
            >
              <Grid item>
                <h2>{t("org_Profile_Tree_View_Label")}</h2>
              </Grid>
              <Grid item style={{ height: "81%", width: "100%" }}>
                {/* <OrgChart organization={orgDetails.orgId} zoom={0.4} /> */}
                <OrgCompleteChart zoom={0.4} />
              </Grid>
              <Grid
                item
                container
                justify="flex-end"
                style={{ marginTop: "1em" }}
              >
                <Grid item xs={12} md={6}>
                  <Link to={`/organization-tree`}>
                    <CustomizedPrimaryButton
                      variant="contained"
                      color="primary"
                      label={t("org_Profile_Enlarge")}
                    />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Grid>
      <Grid item xs={12}>
        {status !== "loading" && !!orgDetails && !orgDetails.parentOrgId && (
          <SubOrgList organization={organization} />
        )}
      </Grid>
    </Grid>
  );
};

export default withWidth()(OrganizationProfile);

function SubOrgList({ organization }) {
  const { t } = useTranslation();
  const columns = React.useMemo(
    () => [
      {
        Header: t("org_Profile_Sub_Org_Name"),
        accessor: "orgName",
        Cell: ({ cell }) => {
          return (
            <Link to={`/organization/profile/${cell.value}`}>{cell.value}</Link>
          );
        }
      },
      {
        Header: t("org_Profile_Sub_Org_Display_Name"),
        accessor: "orgDisplayName"
      },
      {
        Header: t("org_Profile_Sub_Org_Type"),
        accessor: "orgType"
      },
      {
        Header: t("org_Profile_Parent_Name"),
        accessor: "parentOrgName"
      },
      {
        Header: t("org_Profile_Address_Contact_Person"),
        accessor: "address.addressLine1"
      },
      {
        Header: t("org_Profile_Contact_Number"),
        accessor: "contactDetails.contactNumber"
      },
      {
        Header: t("org_Profile_Email"),
        accessor: "contactDetails.emailId"
      },
      {
        Header: t("org_Profile_Actions"),
        Cell: ({ row }) => {
          let link = `/configuration/suborganizationprofile/${row.original.orgId}`;
          return (
            <>
              <td>
                <Link to={link}>
                  <IconButton>
                    <i className="fa fa-edit" aria-hidden="true"></i>
                  </IconButton>
                </Link>
              </td>
              {/* <td>
                <IconButton>
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </IconButton>
              </td> */}
            </>
          );
        }
      }
    ],
    []
  );

  const [subOrganizations, setSubOrganizations] = useState([]);
  const [status, setStatus] = React.useState("loading");
  //const [orgData, setOrgData] = React.useState([]);

  const [pageSize, setPageSize] = React.useState(10);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageInfo, setPageInfo] = React.useState({});

  const nextPage = () => {
    if (
      status !== "loading" &&
      pageInfo.hasNextPage &&
      pageIndex < pageInfo.totalPages
    ) {
      let index = pageIndex;
      setPageIndex(++index);
    }
  };
  const previousPage = () => {
    let index = pageIndex;
    if (index > 0) {
      setPageIndex(--index);
    }
  };

  useEffect(() => {
    if (status !== "loading") {
      setStatus("loading");
      setPageIndex(0);
      setPageInfo({});
      provisioningService
        .getSubOrganizationsByOrgName(organization, pageIndex, pageSize)
        .then(res => {
          setSubOrganizations(res.content);
          setPageInfo(res.pageInfo);
          setStatus("success");
        })
        .catch(e => {
          setStatus("failed");
        });
    }
  }, [organization]);
  useEffect(() => {
    // if (status !== "loading") {
    setStatus("loading");
    provisioningService
      .getSubOrganizationsByOrgName(organization, pageIndex, pageSize)
      .then(res => {
        setSubOrganizations(res.content);
        setPageInfo(res.pageInfo);
        setStatus("success");
      })
      .catch(e => {
        setStatus("failed");
      });
    // }
  }, [pageIndex, pageSize]);

  const data = React.useMemo(() => [...subOrganizations]);
  if (subOrganizations.length > 0) {
    return (
      <TemplateForTables>
        {status === "loading" ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
            <div
              style={{
                padding: "1em 0"
              }}
            >
              <Table
                columns={columns}
                data={data}
                paginationInfo={{
                  pageIndex,
                  nextPage,
                  previousPage,
                  pageSize,
                  setPageSize,
                  pageInfo
                }}
                subOrg
              />
            </div>
          )}
      </TemplateForTables>
    );
  } else {
    return null;
  }
}
