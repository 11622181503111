import React, { useState } from "react";
import { FieldArray, Field, getIn } from "formik";
import Grid from "@material-ui/core/Grid";
import { TextField } from "formik-material-ui";
import SubTaskFields from "./SubTaskFields";
import { useTranslation } from "react-i18next";

export default function TaskFields({ name, TaskValuesTypes, renderFields }) {
  const { t } = useTranslation();
  return (
    <FieldArray name={name}>
      {({ insert, remove, push, form }) => {
        let { values, errors, touched, setFieldTouched } = form;
        return (
          <>
            {getIn(values, name).length > 0 &&
              getIn(values, name).map((taskList, parentIndex) => (
                <Grid
                  container
                  item
                  xs={12}
                  spacing={2}
                  className="mb-0 d-flex flex-direction-column device-wrapper"
                  key={parentIndex}
                >
                  <Grid item xs={12} spacing={2}>
                    <h3 style={{ color: "black" }}>{t("checkList_Form_Task_Details_Header")}</h3>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    spacing={2}
                    className="site-inner"
                  >
                    <Grid item xs={11} md={3}>
                      <Field
                        type="text"
                        name={`${name}.${parentIndex}.taskName`}
                        label={t("checkList_Form_Task_Title_Label")}
                        component={TextField}
                        margin="dense"
                        fullWidth={true}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      md={6}
                      lg={3}
                      alignContent="center"
                      container
                    >
                      {getIn(values, name).length > 0 &&
                        parentIndex === getIn(values, name).length - 1 && (
                          <button
                            type="button"
                            onClick={() => {
                              // is subtask fields ok ?
                              getIn(
                                values,
                                `${name}.${parentIndex}.subTasks`
                              ).forEach((item, index) => {
                                Object.keys(item).forEach(key => {
                                  // console.log(
                                  //   "item is ",
                                  //   `${name}.${parentIndex}.subTasks.${index}.${key}`
                                  // );

                                  key !== "subTaskValue" &&
                                    setFieldTouched(
                                      `${name}.${parentIndex}.subTasks.${index}.${key}`,
                                      true,
                                      true
                                    );
                                  if (
                                    getIn(errors, `${name}.${index}.${key}`)
                                  ) {
                                    key !== "subTaskValue" &&
                                      setFieldTouched(
                                        `${name}.${parentIndex}.subTasks.${index}.${key}`,
                                        true,
                                        true
                                      );
                                  }
                                });
                              });
                              // is task name ok ?
                              setFieldTouched(
                                `${name}.${parentIndex}.taskName`,
                                true,
                                true
                              );
                              if (
                                getIn(touched, `${name}.${parentIndex}`) &&
                                !getIn(errors, `${name}.${parentIndex}`)
                              ) {
                                push({
                                  taskName: "",
                                  subTasks: [
                                    {
                                      subTaskName: "",
                                      subTaskDescription: "",
                                      taskType: "",
                                      subTaskValue: {}
                                    }
                                  ]
                                });
                              }
                            }}
                            className="add-icon"
                          >
                            <i
                              className="fa fa-plus-circle"
                              aria-hidden="true"
                            ></i>
                          </button>
                        )}

                      {getIn(values, name).length > 1 && (
                        <button
                          type="button"
                          className="add-icon delete-icon"
                          onClick={e => {
                            getIn(values, name).length > 0 &&
                              remove(parentIndex);
                          }}
                        >
                          <i class="fa fa-minus-circle" aria-hidden="true"></i>
                        </button>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} className="site-inner">
                    <SubTaskFields
                      name={`${name}.${parentIndex}.subTasks`}
                      TaskValuesTypes={TaskValuesTypes}
                      renderFields={renderFields}
                    />
                  </Grid>
                  <hr />
                </Grid>
              ))}
          </>
        );
      }}
    </FieldArray>
  );
}
