import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
// import { TextField } from "formik-material-ui";
import Grid from "@material-ui/core/Grid";
import { Field } from "formik";
import DevicesChips from "./GatewayChips";
import { provisioningService } from "services/provisioningServices";

export default function SerialNumberField({
  name,
  gatewayLabel,
  sensorLabel,
  metaProps = { margin: "dense", variant: "outlined", fullWidth: true },
  setFieldValue,
  values
}) {
  const [gatewaySerialNumberValue, setGatewayValue] = useState("");
  const [gatewaySerialNumberError, setGatewayError] = useState("");
  const [sensorSerialNumberValue, setSensorValue] = useState("");
  const [sensorSerialNumberError, setSensorError] = useState("");

  async function handleSerialNumberAdd(type) {
    let serialNumber;
    if (type === "L3") serialNumber = gatewaySerialNumberValue;
    else serialNumber = sensorSerialNumberValue;
    let devices = [...values];
    let error = false;
    if (
      devices.filter(device => device.serialNumber === serialNumber).length > 0
    ) {
      type === "L3"
        ? setGatewayError("Already Existing")
        : setSensorError("Already Existing");
      error = true;
    }
    if (gatewaySerialNumberValue === "" && type === "L3") {
      error = true;
      setGatewayError("Please Enter Gateway Serial Number");
    }
    if (sensorSerialNumberValue === "" && type === "powsens") {
      error = true;
      setSensorError("Please Enter Sensor Serial Number");
    }
    if (!error) {
      try {
        const result = await provisioningService.validateDevice(
          type,
          serialNumber
        );
        const deviceData = {
          monitoringProductType: result.monitoringProductType,
          deviceType: result.deviceType,
          serialNumber: result.serialNumber
        };
        type === deviceData.deviceType
          ? devices.push(deviceData)
          : type === "L3"
            ? setGatewayError("Invalid Gateway Serial Number")
            : setSensorError("Invalid Sensor Serial Number");
        if (type === "L3") {
          setGatewayValue("");
        } else {
          setSensorValue("");
        }
        setFieldValue(name, devices);
      } catch (e) {
        console.error(e.message);
        if (type === "L3") {
          !e.message.includes("JSON.parse")
            ? setGatewayError(e.message)
            : setGatewayError("Please Enter Gateway Serial Number");
        } else
          !e.message.includes("JSON.parse")
            ? setSensorError(e.message)
            : setSensorError("Please Enter Sensor Serial Number");
      }
    }
  }
  return (
    <>
      <Grid container item xs={12} className="common-device" spacing={2}>
        <Grid item xs={10} md={3}>
          <Field
            type={"text"}
            name={name}
            label={gatewayLabel}
            component={TextField}
            placeholder={"Serial Number"}
            value={gatewaySerialNumberValue}
            error={!!gatewaySerialNumberError}
            inputProps={{
              onChange: e => {
                setGatewayValue(e.target.value);
                setGatewayError("");
              },
              value: gatewaySerialNumberValue
            }}
            {...metaProps}
          />
          {gatewaySerialNumberError && (
            <div style={{ color: "#f44336" }}>{gatewaySerialNumberError}</div>
          )}
        </Grid>
        <Grid item container alignItems="center" xs={2} md={1}>
          <button
            type="button"
            onClick={() => handleSerialNumberAdd("L3")}
            className="add-icon"
          >
            <i className="fa fa-plus-circle" aria-hidden="true"></i>
          </button>
        </Grid>
        <Grid item style={{ marginTop: "8px", marginLeft: "-10px" }}>
          <DevicesChips
            values={values}
            type="L3"
            deviceField={name}
            setFieldValue={setFieldValue}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} className="common-device" spacing={2}>
        <Grid item xs={10} md={3}>
          <Field
            type={"text"}
            name={name}
            label={sensorLabel}
            component={TextField}
            placeholder={"Serial Number"}
            value={sensorSerialNumberValue}
            error={!!sensorSerialNumberError}
            inputProps={{
              onChange: e => {
                setSensorValue(e.target.value);
                setSensorError("");
              },
              value: sensorSerialNumberValue
            }}
            {...metaProps}
          />
          {sensorSerialNumberError && (
            <div style={{ color: "#f44336" }}>{sensorSerialNumberError}</div>
          )}
        </Grid>
        <Grid item container alignItems="center" xs={2} md={1}>
          <button
            type="button"
            onClick={() => handleSerialNumberAdd("powsens")}
            className="add-icon"
          >
            <i className="fa fa-plus-circle" aria-hidden="true"></i>
          </button>
        </Grid>
        <Grid item style={{ marginTop: "8px", marginLeft: "-10px" }}>
          <DevicesChips
            type="powsens"
            deviceField={name}
            values={values}
            setFieldValue={setFieldValue}
          />
        </Grid>
      </Grid>
    </>
  );
}
