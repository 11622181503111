import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { analyticService } from "services/analyticsServices";
import PageHeader from "../../components/layout/PageHeader";
import BMCStatusCard from "./components/BMCStatusCard";
import LiveData from "./components/LiveData";
import AlertsCard from "./components/AlertsCard";
import DetailedGraph from "./components/DetailedGraph";
import SiteSearchCard from "./components/SiteSearchCard";
import RenderIf from "components/Auth/RenderIf";
import { chillingUnitService } from "services/chillingUnitService";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import { dashboardContext } from "context/dashboardContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./Dashboard.scss";
import "../../components/responsive.scss";

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2)
  },
  boxPaddingRight: {
    paddingBottom: "1em",
    paddingTop: "1em",
    [theme.breakpoints.up("md")]: {
      paddingRight: "1em"
    }
  },
  boxPaddingLeft: {
    paddingBottom: "1em",
    paddingTop: "1em",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "1em"
    }
  }
}));

export default function Dashboard() {
  const dashboardData = useContext(dashboardContext);
  const { hours, currentDate } = dashboardData.state;
  const { dispatch } = dashboardData;
  const [fields, setFields] = useState(null);
  const [showBMC, setShowBMC] = useState(true);
  const [showAlert, setShowAlert] = useState(true);
  const [order, setOrder] = useState(null);
  const [limit, setLimit] = useState(null);
  const [attribute, setAttribute] = useState(null);
  const [bmcStatus, setBmcStatus] = useState("idle");
  const [bmcData, setBmcData] = useState(null);
  const [bmcError, setBmcError] = useState(null);
  const [alertStatus, setAlertStatus] = useState("idle");
  const [alertsData, setAlertsData] = useState(null);
  const [alertError, setAlertError] = useState(null);

  useEffect(() => {
    let currentDate = new Date().toISOString();
    dispatch({
      type: "DASHBOARD_CURRENT_DATE_UPDATE",
      currentDate
    });
  }, []);

  // useEffect(() => {
  //   if (!bmcData) {
  //     setBmcStatus("loading");
  //     chillingUnitService
  //       .getBmcStatusData(hours)
  //       .then(data => {
  //         setBmcData(data);
  //         setBmcStatus("success");
  //       })
  //       .catch(error => {
  //         setBmcStatus("failed");
  //         setBmcError(error);
  //       });
  //   }
  //   if (!alertsData) {
  //     setAlertStatus("loading");
  //     chillingUnitService
  //       .getAlertStatusData(hours, currentDate)
  //       .then(data => {
  //         setAlertsData(data.alertsAggregate);
  //         setAlertStatus("success");
  //       })
  //       .catch(error => {
  //         setAlertStatus("failed");
  //         setAlertError(error);
  //       });
  //   }
  // }, []);

  // useEffect(() => {
  //   if (!!alertsData || alertStatus === "loading") {
  //     setAlertStatus("loading");
  //     chillingUnitService
  //       .getAlertStatusData(hours, currentDate)
  //       .then(data => {
  //         setAlertsData(data.alertsAggregate);
  //         setAlertStatus("success");
  //       })
  //       .catch(error => {
  //         setAlertStatus("failed");
  //         setAlertError(error);
  //       });
  //   }
  // }, [hours]);

  // useEffect(() => {
  //   analyticService.fetchMetaData().then(data => {
  //     const limit = data[0].value;
  //     const order = data[2].value;
  //     const attribute = data[1].value;
  //     setOrder(order);
  //     setLimit(limit);
  //     setAttribute(attribute);
  //     setFields([
  //       {
  //         type: "select",
  //         name: "limit",
  //         label: "Limit",
  //         placeholder: "Limit",
  //         option:
  //           limit.length > 0
  //             ? limit.map(l => ({
  //                 value: l,
  //                 label: l
  //               }))
  //             : [],
  //         grid: {
  //           xs: 4,
  //           sm: 2,
  //           md: 3
  //         }
  //       },
  //       {
  //         type: "select",
  //         name: "order",
  //         label: "Order",
  //         placeholder: "Order",
  //         option:
  //           order.length > 0
  //             ? order.map(d => ({
  //                 value: d,
  //                 label: d
  //               }))
  //             : [],
  //         grid: {
  //           xs: 4,
  //           sm: 2,
  //           md: 3
  //         }
  //       },
  //       {
  //         type: "select",
  //         name: "attribute",
  //         label: "Attribute",
  //         placeholder: "Attribute",
  //         option:
  //           attribute.length > 0
  //             ? attribute.map(a => ({
  //                 value: a,
  //                 label: a
  //               }))
  //             : [],
  //         grid: {
  //           xs: 4,
  //           sm: 2,
  //           md: 3
  //         }
  //       }
  //     ]);
  //   });
  // }, []);

  const validations = {
    order: Yup.string().required("Required"),
    limit: Yup.string().required("Required"),
    attribute: Yup.string().required("Required")
  };

  const onSliderChange = hours => {
    setAlertStatus("loading");
    dispatch({
      type: "DASHBOARD_HOURS_UPDATE",
      hours
    });
  };

  const { t } = useTranslation();
  const classes = useStyles();
  return (
    
    <>
      <h1>Admin Portal Dashboard</h1>
      <PageHeader
        title={t("dashboard_DashboardTab_Label")}
        dashboard
        dashboardData={bmcData}
      />
    </>
 
    // <Grid container>
    //   <Grid item xs={12}>
    //     <PageHeader
    //       title={t("dashboard_DashboardTab_Label")}
    //       dashboard
    //       dashboardData={bmcData}
    //     />
    //   </Grid>
    //   {/* bmcData && ( */}
    //   <Grid
    //     item
    //     xs={12}
    //     container
    //     justify="center"
    //     style={{ padding: "1em 0em 1em 0em" }}
    //   >
    //     <RenderIf privilege={["LIVE_TILE", "ALL_PRIVILEGE"]}>
    //       <Grid item container md={4} xs={12} direction="column">
    //         <LiveData
    //           date={currentDate}
    //           name={t("dashboard_DashboardTab_Live_Data")}
    //           loading={bmcData ? bmcData.siteAggregate : true}
    //           value={bmcData ? bmcData.siteAggregate.liveData.toFixed(2) : 0}
    //         />
    //       </Grid>
    //     </RenderIf>
    //   </Grid>
    //   <Grid container item xs={12}>
    //     <Grid container item>
    //       {showBMC && (
    //         <RenderIf privilege={["BMC_TILE", "ALL_PRIVILEGE"]}>
    //           <Grid item md={6} xs={12} className={classes.boxPaddingRight}>
    //             <BMCStatusCard
    //               data={
    //                 !!bmcData &&
    //                 !!bmcData.chillingUnitAggregate &&
    //                 bmcData.chillingUnitAggregate.chillingUnitStatesCount
    //               }
    //               count={
    //                 !!bmcData && !!bmcData.siteAggregate
    //                   ? bmcData.siteAggregate.assignedChillingUnitCount
    //                   : 0
    //               }
    //               status={bmcStatus}
    //               bmcError={bmcError}
    //               onClick={() => setShowBMC(false)}
    //             />
    //           </Grid>
    //         </RenderIf>
    //       )}

    //       {showAlert && (
    //         <RenderIf privilege={["ALERTS_TILE", "ALL_PRIVILEGE"]}>
    //           <Grid item md={6} xs={12} className={classes.boxPaddingLeft}>
    //             <React.Suspense fallback="loading...">
    //               <AlertsCard
    //                 alertsData={!!alertsData && alertsData.alertCountMap}
    //                 status={alertStatus}
    //                 alertError={alertError}
    //                 onSliderChange={onSliderChange}
    //                 onClick={() => setShowAlert(false)}
    //               />
    //             </React.Suspense>
    //           </Grid>
    //         </RenderIf>
    //       )}
    //     </Grid>
    //     <Grid container item>
    //       <RenderIf privilege={["GRAPH_TILE", "ALL_PRIVILEGE"]}>
    //         <Grid item xs={12} md={9} className={classes.boxPaddingRight}>
    //           {fields ? (
    //             <DetailedGraph
    //               limit={limit}
    //               order={order}
    //               attribute={attribute}
    //               fields={fields}
    //               validations={validations}
    //               cb={function(value, index) {
    //                 // Formatted to be string of 8 characters and then ...
    //                 const text =
    //                   value.length > 8 ? value.substring(0, 7) + "..." : value;
    //                 return text;
    //               }}
    //             />
    //           ) : (
    //             <Grid
    //               container
    //               xs={12}
    //               justify="center"
    //               alignItems="center"
    //               style={{ height: "50vh" }}
    //             >
    //               <CircularProgress />
    //             </Grid>
    //           )}
    //         </Grid>
    //       </RenderIf>
    //       <RenderIf privilege={["SITE_LIST_TILE", "ALL_PRIVILEGE"]}>
    //         <Grid item xs={12} md={3} className={classes.boxPaddingLeft}>
    //           <SiteSearchCard
    //             totalSites={
    //               !!bmcData && !!bmcData.siteAggregate
    //                 ? bmcData.siteAggregate.assignedSiteCount
    //                 : 0
    //             }
    //           />
    //         </Grid>
    //       </RenderIf>
    //     </Grid>
    //   </Grid>
    // </Grid>
  );
}
