import React from "react";
import Chip from "@material-ui/core/Chip";

export default function OrganizationChip({
  values,
  setFieldValue
}) {
  
  function deleteOrganizationData(data) {
    let orgData = [...values];
    
    let indexValue = 0;
    for(var i=0;i<values.length;i++){
      if(JSON.stringify(values[i]) === JSON.stringify(data)){
        indexValue = i;
        console.log("indexValue",indexValue);
        values.splice(indexValue,1);
        break;
      }
    }
    console.log(values);
    setFieldValue(values);
  }

  let orgData = [];
  try{
    orgData = [...values];
    console.log("orgData",orgData);
  }catch{
    console.log("error");
  }

  if (orgData !== []) {
    return (
      <>   
      { orgData.map((data,index) => (
        <Chip
            style={{ marginLeft: "10px",
           marginTop:"5px" }}
            key={data.orgId}
            label={"Organization:" +data.organizationName +", Chilling Center: " +data.ccName }
            className="gateway-label"
            onDelete={() => {
                deleteOrganizationData(data);
            } } />     
        ))}
      </>
    );
  }

  return null;
}
