import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    load: ["en", "fr"],
    fallbackLng: "en",
    // ns: ["common"],
    // defaultNS: "common",
    debug: true,
    // backend: {
    //   loadPath: "/locales/{{lang}}/{{ns}}.json"
    // },
    interpolation: {
      escapeValue: false
    },
    detection: {
      // order and from where user language should be detected
      order: ["querystring", "path", "localStorage"],
      // keys or params to lookup language from
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
      // cache user language on
      caches: ["localStorage", "cookie"],
      excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)
      // optional htmlTag with lang attribute, the default is:
      htmlTag: document.documentElement
    }
  });

export default i18n;
