import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    background: "#444",
    borderRadius: "8px",
    padding: "10px",
    color: "#fff",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}))(Tooltip);

export default function ToolTipCell({ children }) {
  const [openTip, setOpenTip] = React.useState(false);
  if (!!children) {
    if (typeof children === "string") {
      return (
        <HtmlTooltip
          disableFocusListener
          disableTouchListener
          open={openTip}
          onOpen={e => {
            // console.log(
            //   e.target.offsetWidth,
            //   e.target.parentNode.offsetWidth,
            //   e.target.offsetWidth > e.target.parentNode.offsetWidth
            // );
            if (e.target.offsetWidth > e.target.parentNode.offsetWidth - 16) {
              setOpenTip(true);
            } else {
              setOpenTip(false);
            }
          }}
          onClose={() => {
            setOpenTip(false);
          }}
          title={
            <React.Fragment>
              <Typography color="inherit">{children}</Typography>
            </React.Fragment>
          }
        >
          <Typography component={"span"} noWrap>
            {children}
          </Typography>
        </HtmlTooltip>
      );
    } else {
      return children;
    }
  }
  return null;
}
export const ToolTip = ({ children, name }) => {
  const [openTip, setOpenTip] = React.useState(false);
  return (
    <HtmlTooltip
      disableTouchListener
      disableFocusListener
      title={name}
      open={openTip}
      onOpen={e => {
        if (e.target.offsetWidth >= e.target.parentNode.offsetWidth - 16) {
          setOpenTip(true);
        } else {
          setOpenTip(false);
        }
      }}
      onClose={() => {
        setOpenTip(false);
      }}
    >
      {children}
    </HtmlTooltip>
  );
};
