import { func } from "prop-types";
import { utilityServices } from "./utilityServices";

function getToken() {
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const token = user && user.jwtAccessToken;
  return token;
}

async function createNotifications(values) {
  const url = process.env.REACT_APP_NOTIFICATION_CREATE_NOTIFICATION;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
      "portalToken":`${JSON.parse(localStorage.getItem("currentUser")).jwtAccessToken}`
     // "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIzNTQ0OTQ0MiIsImlhdCI6MTY2NjkzMzQzMCwiZXhwIjoxNjY4MTQzMDMwfQ.xEA_a1TNpAkUqKwX5rW7mSTAuSnOINEj3o9EylS-5c7ElugdDE31caBLNRf_h0VR6GQlXKgrOghN-Zs9qxVUfg"
    },
    body: JSON.stringify(values)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getNotificationList(productCode,startDate,endDate) {
  const url = `${process.env.REACT_APP_GET_NOTIFICATION_LIST}?fromDate=${startDate}&toDate=${endDate}&productCode=${productCode}&sourceAppName=UI-PORTAL`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`
    },
    body: JSON.stringify()
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function editNotification(notification) {
  const url = `${process.env.REACT_APP_EDIT_NOTIFICATION}`;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`
      //"Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIzNTQ0OTQ0MiIsImlhdCI6MTY2NjkzMzQzMCwiZXhwIjoxNjY4MTQzMDMwfQ.xEA_a1TNpAkUqKwX5rW7mSTAuSnOINEj3o9EylS-5c7ElugdDE31caBLNRf_h0VR6GQlXKgrOghN-Zs9qxVUfg"
    },
    body: JSON.stringify(notification)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw await response.json();
    }
  } catch (error) {
    let err = {
      status: error.message,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getNotificationUIOptions(type) {
  const url = `${process.env.REACT_APP_GET_NOTIFICATION_OPTIONS}?type=${type}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`
     // "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIzNTQ0OTQ0MiIsImlhdCI6MTY2NjkzMzQzMCwiZXhwIjoxNjY4MTQzMDMwfQ.xEA_a1TNpAkUqKwX5rW7mSTAuSnOINEj3o9EylS-5c7ElugdDE31caBLNRf_h0VR6GQlXKgrOghN-Zs9qxVUfg"
    },
    body: JSON.stringify()
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw await response.json();
    }
  } catch (error) {
    let err = {
      status: error.message,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getNotificationbyID(notificationId) {
  const url = `${process.env.REACT_APP_GET_NOTIFICATION_BY_ID}/${notificationId}`;
  console.log("URL"+url);
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`
     // "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIzNTQ0OTQ0MiIsImlhdCI6MTY2NjkzMzQzMCwiZXhwIjoxNjY4MTQzMDMwfQ.xEA_a1TNpAkUqKwX5rW7mSTAuSnOINEj3o9EylS-5c7ElugdDE31caBLNRf_h0VR6GQlXKgrOghN-Zs9qxVUfg"
    },
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function generateOTP(mobileNum) {
  const url = `${process.env.REACT_APP_GENERATE_OTP}?mobileNum=${mobileNum}`;
  const requestOptions = {
    method: "GET",
    headers: {
     // "Content-Type": "application/json",
      //Authorization: `Bearer ${getToken()}`,
     // authToken: localStorage.getItem("idToken"),
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC"
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function verifyOTP(mobileNum,otp,transactionId) {
  const url = `${process.env.REACT_APP_VERIFY_OTP}?mobileNum=${mobileNum}&otp=${otp}&transactionId=${transactionId}`;
  const requestOptions = {
    method: "GET",
    headers: {
     // "Content-Type": "application/json",
      //Authorization: `Bearer ${getToken()}`,
  //    authToken: localStorage.getItem("idToken"),
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC"
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json().then(responseJson => {
        this.use_response(responseJson, response.headers)
      });
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function invokeApproveNotification(data) {
  const url = process.env.REACT_APP_APPROVE_NOTIFICATION;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
     // "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIzNTQ0OTQ0MiIsImlhdCI6MTY2NjkzMzQzMCwiZXhwIjoxNjY4MTQzMDMwfQ.xEA_a1TNpAkUqKwX5rW7mSTAuSnOINEj3o9EylS-5c7ElugdDE31caBLNRf_h0VR6GQlXKgrOghN-Zs9qxVUfg",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC"
    },
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response;
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function sendNotification(data) {
  const url = `${process.env.REACT_APP_SEND_NOTIFICATION}`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
    //  "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIzNTQ0OTQ0MiIsImlhdCI6MTY2NjkzMzQzMCwiZXhwIjoxNjY4MTQzMDMwfQ.xEA_a1TNpAkUqKwX5rW7mSTAuSnOINEj3o9EylS-5c7ElugdDE31caBLNRf_h0VR6GQlXKgrOghN-Zs9qxVUfg",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC"
    },
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function notificationScheduler(data) {
  const url = `${process.env.REACT_APP_NOTIFICATION_SCHEDULER}`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
    //  "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIzNTQ0OTQ0MiIsImlhdCI6MTY2NjkzMzQzMCwiZXhwIjoxNjY4MTQzMDMwfQ.xEA_a1TNpAkUqKwX5rW7mSTAuSnOINEj3o9EylS-5c7ElugdDE31caBLNRf_h0VR6GQlXKgrOghN-Zs9qxVUfg",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
      "portalToken":`${JSON.parse(localStorage.getItem("currentUser")).jwtAccessToken}`
    },
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

export const notificationService = {
  getNotificationUIOptions,
  getNotificationList,
  createNotifications,
  getNotificationbyID,
  editNotification,
  generateOTP,
  verifyOTP,
  invokeApproveNotification,
  sendNotification,
  notificationScheduler
};
