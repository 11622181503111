import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import reportsLogo from "assets/icon-reports.svg";
import PageHeader from "components/layout/PageHeader";
import ReportPopup from "./ReportPopup";
import { errorContext } from "context/errorContext";
import { provisioningService } from "../../../services/provisioningServices";
import {
  Typography,
  Grid,
  Paper,
  Tab,
  AppBar,
  CircularProgress,
  Tooltip,
  Tabs,
  Zoom,
  withStyles
} from "@material-ui/core";
// import TabScrollButton  from "@material-ui/core/Tabs/TabScrollButton";
import { TabScrollButton } from '@material-ui/core';
import "./Reports.scss";
import { useTranslation } from "react-i18next";
//import DisplayDate from "../../../components/common/elements/DisplayDate";
//import Breadcrumbs from "@material-ui/core/Breadcrumbs";
//import { Link } from "react-router-dom";
import { authContext } from "context/authContext";

function TabPanel(props) {
  //console.log(props);
  const { children, value, index, desc, ...other } = props;
  const BlueOnGreenTooltip = withStyles({
    tooltip: {
      color: "white",
      backgroundColor: "#34409a"
    },
    arrow: {
      color: "#34409a"
    }
  })(Tooltip);
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Zoom in={true} style={{ transitionDelay: "500ms" }}>
          <div className="reportcard">
            <object
              style={{ width: "50px", height: "50px" }}
              type={"image/svg+xml"}
              data={reportsLogo}
            />
            <div className="card-body">
              {/* <BlueOnGreenTooltip title={desc} arrow placement="bottom"> */}
              <BlueOnGreenTooltip title={children} arrow placement="bottom">
                <p className="card-title">{children}</p>
              </BlueOnGreenTooltip>
            </div>
          </div>
        </Zoom>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`
  };
}

// const useStyles = makeStyles(theme => ({
//   root: {
//     flexGrow: 1,
//     width: "100%"
//   }
// }));

const ReportTabs = () => {
  const [value, setValue] = useState(0); //for appbars
  const [loading, setLoading] = useState(false);
  const [rep, setReports] = useState(null); //selecting reports
  const [id, setIds] = useState(null); //selecting ids
  const [showPopup, setPopup] = useState(false); //status of popup
  const [src, setSrc] = useState(""); //setting the link of url
  const [data, setData] = useState(null); //getting data from backend
  const [name, setName] = useState(""); //passing name for popup header
  const { dispatch: errorDispatch } = useContext(errorContext);
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { authState, authDispatch } = useContext(authContext);

  // useEffect(()=>{
  //     const data = { 
  //       "REPORTS" : {
  //             "key" : "MILK COLLECTION AND LIFTING",
  //             "values" : [
  //               {
  //               "category" : "Milk Collection and Lifting",
  //               "description" : "Milk lifting by BMC wise",
  //               "displayName" : "Milk lifting by BMC wise",
  //               "id" : "86f71bf3-c5d8-47f1-8bae-22857167ffd3",
  //               "name" : "Milk lifting by BMC wise",
  //               "url" : "https://cv2.smartmoo.com/jasperserver/flow.html?_flowId=viewReportFlow&_flowId=viewReportFlow&ParentFolderUri=/Reports/contrak_reports&reportUnit=/Reports/contrak_reports/Milk_Lifting_Report&standAlone=true&decorate=no&j_username=admin&j_password=admin&userName=admin@moomark.in&userLocale=en&reportParams=xcdjtl36B2Q6s2o56elQ6fhjmFaV7AnRa75m+bgD5LS/hW/8kda+TcSS9WtQ2C0fnQ8ho3vRx6I9pUa7A6Ow+x5xSwRC8qAKOB+KYA2j67RDUja3QVPMMluOvmhz8qYU"
  //               }

  //             ]
  //           }      
  //     }
  //     console.log("useEffect Called", data);
  // }, [authState.language])

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      provisioningService
        .getReportsData(i18n.language)
        .then(data => {
          setData(data);
          setLoading(false);
          //console.log("Reports Data from API:- ", data);
          setClick(data.REPORTS[0]);
        })
        .catch(error => {
          errorDispatch({
            type: "ERROR",
            error
          });
        });
    };
    if (
      (!!user.uiPrivileges && user.uiPrivileges.includes("REPORTS")) ||
      user.uiPrivileges.includes("ALL_PRIVILEGES")
    ) {
      if (authState.language) getData();
    } else {
      history.push("/403");
    }
  }, [authState.language]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setClick = rep => {
    // console.log("rep ", rep);
    setReports(rep.values);
  };

  const hideIt = () => {
    setPopup(false);
  };

  const selectedReportId = (url, name) => {
    // console.log(url);
    //call reports from here
    //setIds(id);
    setName(name);
    setSrc(url);
    setPopup(true);
  };

  const MyTabScrollButton = withStyles(theme => ({
    root: {
      width: 10,
      overflow: 'hidden',
      transition: 'width 0.5s',
      '&.Mui-disabled': {
        width: 0
      }
    }
  }))(TabScrollButton);
  return (
    <Grid container className="position-relative">
      {/* <div className="card-loader">
        <span>Feature Coming Soon...</span>
      </div> */}
      <Grid item xs={12}>
        <PageHeader
          title={t("config_Users_List_TableHeading_Reports_Label")}
          breadcrumbs={[
            {
              label: t("total_Bmc_Breadcrum_Dashboard_Label"),
              link: "/dashboard"
            },
            { label: t("config_Users_List_TableHeading_Reports_Label") }
          ]}
        />
      </Grid>
      {loading ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: "50vh" }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Paper elevation={2} style={{ padding: "2em", width: "100%" }}>
          <Grid item className="report-category" xs={12}>
            {data !== null && (
              <>
                <AppBar position="static" color="inherit">
                  <Tabs
                    ScrollButtonComponent={MyTabScrollButton}
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable force tabs example"
                    scrollButtons="auto"
                  >
                    {data.REPORTS.map((rep, index) => (
                      <Tab
                        label={rep.key}
                        key={index}
                        {...a11yProps(index)}
                        onClick={() => setClick(rep)}
                      />
                    ))}
                  </Tabs>
                </AppBar>
                <div className="reports">
                  {rep &&
                    rep.map(reports => (
                      <TabPanel
                        value={value}
                        index={value}
                        //key={reports.id}
                        key={reports.name}
                        onClick={() =>
                          selectedReportId(reports.url, reports.name)
                        }
                        desc={reports.description}
                      >
                        {reports.name}
                      </TabPanel>
                    ))}
                </div>
                {showPopup ? (
                  <ReportPopup
                    src={src}
                    showPopup={showPopup}
                    hideIt={hideIt}
                    name={name}
                  />
                ) : (
                  ""
                )}
              </>
            )}
          </Grid>
        </Paper>
      )}
    </Grid>
  );
};
export default ReportTabs;
