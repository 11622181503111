import React from "react";

import { Link } from "react-router-dom";

import { ColorChangingProgressBar } from "../../../components/common/elements/ColorChangingProgressBar";

import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: "center",
    paddingTop: "1em",
    fontSize: "12px",
    "& a": {
      color: "black",
      "& :hover ": {
        color: "#166aab"
      }
    },
    "& :hover ": {
      color: "#166aab"
    }
  }
}));
export default function LiveData({ value, date, loading, name }) {
  const classes = useStyles();
  return (
    <Link
      to={{
        pathname: "/allbmc",
        search: `?date=${date}`
      }}
    >
      <Paper classes={{ root: classes.root }}>
        <b>
          {name} :&nbsp; {value} %
        </b>
        <ColorChangingProgressBar value={value} />
      </Paper>
    </Link>
  );
}
