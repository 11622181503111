import reducer from "./reducers/rootReducer";
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";

import userConfigSaga from "./sagas/userConfigurationSagas";
import siteConfigSaga from "./sagas/siteConfigurationSaga";
import orgConfigSaga from "./sagas/organizationConfigurationSagas";
import chillingGraphSaga from "./sagas/chillingGraphSaga";
import authenticationSaga from "./sagas/authenticationSaga";

function* rootSaga() {
  yield all([
    authenticationSaga(),
    siteConfigSaga(),
    userConfigSaga(),
    orgConfigSaga(),
    chillingGraphSaga()
  ]);
}

const sagaMiddleware = createSagaMiddleware();

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  composeEnhancer(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);

export default store;
