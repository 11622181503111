import React, { useReducer, createContext } from "react";

let initialState = {
  error: false,
  status: null,
  message: ""
};

const errorContext = createContext(initialState);
const { Provider } = errorContext;
const ErrorProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, { type, error }) => {
    console.log("type ", type, error);
    switch (type) {
      case "ERROR":
        if (error.status === 500) {
          return { ...state, error: true, status: 500 };
        } else if (error.status === 401) {
          return { ...state, error: true, status: 401 };
        } else if (error.status === 403) {
          return { ...state, error: true, status: 403 };
        } else if (error.status === 503) {
          return { ...state, error: true, status: 503 };
        } else if (error.status === 404 || error.status === 400) {
          return { ...state, error: true, status: 404 };
        } else {
          return { ...state, error: true, status: "error" };
        }
      case "no_data":
        return { ...state, error: true, status: "no_data" };
      case "RESET_ERROR":
        return initialState;
      default:
        return state;
    }
  }, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { errorContext, ErrorProvider };
