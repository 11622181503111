import { provisioningService } from "services/provisioningServices";
import { metadataService } from "services/metadataServices";
import {
  put,
  takeEvery,
  takeLatest,
  fork,
  call,
  all,
  select
} from "redux-saga/effects";

function* watchSiteConfiguration() {
  yield takeEvery("SITE_CONFIG_SUB_ORG_FETCH", fetchSubOrgByOrgName);
  yield takeEvery("SITE_CONFIGURATION_DEVICE_ADD", validateDevice);
  yield takeEvery("SITE_CONFIGURATION_CREATE_SITE", createSite);
  yield takeEvery("SITE_SERIAL_NUMBER_CHANGE", validateSerial);
  yield takeLatest("GET_EDIT_SITE_DETAILS_BY_ID", getSiteDetailsbyID);
  yield takeLatest("CHILLING_LIST_FETCH", getChillingUnitData);
}

function* editSite() {
  yield takeLatest("SITE_CONFIGURATION_EDIT_SITE", function* ({
    payload: { values, resetForm, siteId }
  }) {
    let formValues = { ...values };

    let chillingUnitlength =
      formValues.chillingUnitMonitoringDetailsList.length;
    if (chillingUnitlength === 0) {
      delete formValues.chillingUnitMonitoringDetailsList;
    } else {
      if (formValues.chillingUnitMonitoringDetailsList.length > 0) {
        formValues.chillingUnitMonitoringDetailsList.forEach(unit => {
          if (unit.chillingUnit.vendor === "Others") {
            unit.chillingUnit.vendor = unit.chillingUnit.otherVendor;
            delete unit.chillingUnit.otherVendor;
          }
        });
      }
    }
    if (formValues.devices.length === 0) {
      delete formValues.devices;
    }

    try {
      const result = yield call(
        provisioningService.editSite,
        siteId,
        formValues
      );

      yield put({
        type: "SITE_CONFIGURATION_EDIT_SITE_SUCCESS",
        result
      });
      resetForm();
    } catch (e) {
      yield put({
        type: "SITE_CONFIGURATION_CREATE_SITE_FAILED",
        error: e.message
      });
    }
  });
}

function* getSiteDetails() {
  yield takeLatest("SITE_DETAILS_FETCH", function* getDetails({ siteId }) {
    try {
      const site = yield call(provisioningService.getSiteById, siteId);
      yield put({
        type: "GET_SITE_DETAILS_SUCCESS",
        site
      });
    } catch (error) {
      yield put({
        type: "ERROR",
        error
      });
      console.error("error in fetching details");
    }
  });
}

function* getChillingUnitData({ siteId }) {
  try {
    const chillingUnitData = yield call(
      provisioningService.getChillingUnitData,
      siteId
    );
    let uuid = chillingUnitData[0].chillingUnitUUID;
    yield put({
      type: "CHILLING_UNIT_ID_SELECTED",
      id: uuid
    });
    yield put({
      type: "CHILLING_UNIT_LIST_UPDATE",
      chillingUnitData
    });
  } catch (error) {
    yield put({
      type: "ERROR",
      error
    });
    console.error("error in fetching chilling list data");
  }
}

function* getSiteDetailsbyID({ siteId }) {
  try {
    const result = yield call(provisioningService.getSiteById, siteId);
    if (!!result.siteLevelDevices) {
      const devices = result.siteLevelDevices.map(device => ({
        monitoringProductType: device.monitoringProductType,
        deviceType: device.deviceType,
        serialNumber: device.serialNumber
      }));
      yield put({
        type: "SITE_CONFIGURATION_DEVICE_VALIDATION_SUCCESS",
        devices
      });
    }
    yield put({
      type: "GET_EDIT_SITE_DETAILS_BY_ID_SUCCESS",
      site: result
    });
  } catch (error) {
    yield put({
      type: "ERROR",
      error: error
    });
  }
}

function* createSite({ payload: { values, resetForm, setSubmitting } }) {
  let formValues = { ...values };
  let devices = yield select(state => state.site.toJS().devices);
  if (!!devices.length) formValues.devices = devices;

  delete formValues.gatewaySerialNumber;
  delete formValues.sensorSerialNumber;

  if (formValues.devices.length === 0) {
    delete formValues.devices;
  }

  if (formValues.chillingUnitMonitoringDetailsList.length > 0) {
    formValues.chillingUnitMonitoringDetailsList.forEach(unit => {
      if (unit.chillingUnit.vendor === "Others") {
        unit.chillingUnit.vendor = unit.chillingUnit.otherVendor;
        delete unit.chillingUnit.otherVendor;
      }
    });
  } else {
    delete formValues.chillingUnitMonitoringDetailsList;
  }

  // console.log("values in creation", formValues);

  try {
    setSubmitting(false);
    const result = yield call(provisioningService.createSite, formValues);

    yield put({
      type: "SITE_CONFIGURATION_CREATE_SITE_SUCCESS",
      result: result
    });
    yield call(resetForm);
  } catch (e) {
    yield put({
      type: "SITE_CONFIGURATION_CREATE_SITE_FAILED",
      error: e
    });
    // yield put({
    //   type: "ERROR",
    //   error: e
    // });
    setSubmitting(false);
  }
}

function* validateDevice({ payload }) {
  // console.log("inside validate device");

  const { serialNumber, type, field, setFieldValue, setFieldError } = payload;
  try {
    const devices = yield select(state => state.site.toJS().devices);
    if (
      devices.filter(device => device.serialNumber === serialNumber).length > 0
    ) {
      setFieldError(field, "Already existing");
    } else {
      const result = yield call(
        provisioningService.validateDevice,
        serialNumber
      );
      const deviceData = {
        monitoringProductType: result.monitoringProductType,
        deviceType: result.deviceType,
        serialNumber: result.serialNumber
      };

      let success = false;
      if (deviceData.deviceType === "L3" && type === "L3") {
        devices.push(deviceData);
        setFieldValue(field, "");
        success = true;
      } else if (deviceData.deviceType !== "L3" && type === "L1") {
        devices.push(deviceData);
        setFieldValue(field, "");
        success = true;
      } else if (deviceData.deviceType !== "L3" && type === "L3") {
        setFieldError(
          field,
          "The Serial Number is not associated to a gateway device"
        );
      } else if (deviceData.deviceType === "L3" && type === "L1") {
        setFieldError(
          field,
          "The Serial Number is not associated to a sensor device"
        );
      }

      if (success) {
        yield put({
          type: "SITE_CONFIGURATION_DEVICE_VALIDATION_SUCCESS",
          devices
        });
      }
    }
  } catch (e) {
    yield put({
      type: "SITE_CONFIGURATION_DEVICE_VALIDATION_FAILED",
      validationError: e.message
    });
    // setFieldValue(field, "");
    setFieldError(field, e.message);
  }
}

function* validateSerial(payload) {
  // console.log("1");

  const { serialNumber, index, parent, setFieldValue, setFieldError } = payload;
  let name = `chillingUnitMonitoringDetailsList.${parent}.l1DeviceInfo.${index}.deviceType`;
  try {
    const result = yield call(provisioningService.validateDevice, serialNumber);
    let deviceType = result.deviceType;
    yield put({
      type: "SITE_CONFIGURATION_DEVICE_TYPE_SUCCESS",
      deviceType,
      index
    });

    setFieldValue(name, deviceType);
  } catch (e) {
    console.error(e);
    yield put({
      type: "SITE_CONFIGURATION_DEVICE_TYPE_FAILED",
      validationError: e.message,
      index,
      parent
    });
    setFieldError(
      `chillingUnitMonitoringDetailsList.${parent}.l1DeviceInfo.${index}.serialNumber`,
      e.message
    );
  }
}
// function* getApplicationAttributes({ deviceType }) {
//   metadataService
//     .getApplicationAttributes()
//     .then((data) => setApplicationAttributes(data));
// }

function* fetchSubOrgByOrgName({ org }) {
  try {
    const subOrganizations = yield call(
      provisioningService.getSubOrganizationsMetaList,
      org
    );
    yield put({
      type: "SITE_CONFIG_SUB_ORG_FETCH_SUCCEED",
      subOrganizations
    });
  } catch (e) {
    yield put({
      type: "ERROR",
      error: e
    });
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchSiteConfiguration),
    fork(editSite),
    fork(getSiteDetails)
  ]);
}
