import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import LoanInsuranceForm from "./LoanInsuranceForm";
import { provisioningService } from "services/provisioningServices";
import format from "date-fns/format";
import { number, string } from "prop-types";


export default function EditLoanInsurance() {
  const org = JSON.parse(localStorage.getItem("currentUser")).organizationName;
  const [loanInsuranceDetails, setLoanInsuranceDetails] = useState(null);

  const { productId } = useParams();
  const history = useHistory();

  const [organization, setOrganization] = React.useState([]);
  const [type, setType] = React.useState([]);
  const [category, setCategory] = React.useState([]);

  // to initialize the values when create template form loads first time
  let values = {
    productId: productId,
    organization: organization,
    organizationName: loanInsuranceDetails ? loanInsuranceDetails.organizationName : "",
    organizationId: loanInsuranceDetails ? loanInsuranceDetails.organizationId : "",
    productName: loanInsuranceDetails ? loanInsuranceDetails.productName : "",
    productCode: loanInsuranceDetails ? loanInsuranceDetails.productCode : "",
    productCategory: loanInsuranceDetails ? loanInsuranceDetails.productCategory : [],
    description: loanInsuranceDetails ? loanInsuranceDetails.description : "",
    amount: loanInsuranceDetails ? loanInsuranceDetails.amount : "",
    productType: loanInsuranceDetails ? loanInsuranceDetails.productType : [],
    productTenure: loanInsuranceDetails ? loanInsuranceDetails.productTenure : "",
    validFromDate: loanInsuranceDetails ? loanInsuranceDetails.validFromDate : new Date(),
    validToDate: loanInsuranceDetails ? loanInsuranceDetails.validToDate : new Date(),
    consentURL: loanInsuranceDetails ? loanInsuranceDetails.consentURL : "",
    imageURL: loanInsuranceDetails ? loanInsuranceDetails.imageURL : "",
    premium: loanInsuranceDetails ? loanInsuranceDetails.premium : "",
    status: loanInsuranceDetails ? loanInsuranceDetails.status : "",
    dispDescription: loanInsuranceDetails ? loanInsuranceDetails.dispDescription : {},
    dispPremium: loanInsuranceDetails ? loanInsuranceDetails.dispPremium : {},
    dispAmount: loanInsuranceDetails ? loanInsuranceDetails.dispAmount : {},
    active: loanInsuranceDetails ? loanInsuranceDetails.active : "",
    largeTicket: loanInsuranceDetails ? loanInsuranceDetails.largeTicket : "",
    consentRequired: loanInsuranceDetails ? loanInsuranceDetails.consentRequired : "",
    productEligible: loanInsuranceDetails ? loanInsuranceDetails.productEligible : "",
    productDispName: loanInsuranceDetails ? loanInsuranceDetails.productDispName : {},
    productDispCategory: loanInsuranceDetails ? loanInsuranceDetails.productDispCategory : {},
    dispProductTenure: loanInsuranceDetails ? loanInsuranceDetails.dispProductTenure : {},
    productDispNameReplicaList: loanInsuranceDetails ? loanInsuranceDetails.productDispNameReplicaList:[],
    productDispCategoryReplicaList: loanInsuranceDetails ? loanInsuranceDetails.productDispCategoryReplicaList:[],
    productDispTenureReplicaList: loanInsuranceDetails ? loanInsuranceDetails.productDispTenureReplicaList:[],
    productDispPremiumReplicaList: loanInsuranceDetails ? loanInsuranceDetails.productDispPremiumReplicaList:[],
    productDispAmountReplicaList: loanInsuranceDetails ? loanInsuranceDetails.productDispAmountReplicaList:[],
    productDispDescriptionReplicaList: loanInsuranceDetails ? loanInsuranceDetails.productDispDescriptionReplicaList:[]

  };

  //didMount
  useEffect(() => {

    provisioningService.getAllOrgList().then(data2 => {
      if (data2) {
        setOrganization(data2);
      }
    });

    provisioningService.getTypeAndCategoryInLoan().then(data3 => {
      if (data3) {
        setType(data3.type);
        setCategory(data3.category);
      }
    });

    provisioningService.getLoanInsuranceByProductId(productId).then(data => {
      
      let productDispNameReplicaList = [];
      let productDispNameObj = data.productDispName;
      for (let key in productDispNameObj) {
        if (productDispNameObj.hasOwnProperty(key)) {
          let productDispNameReplicaObj = {};
            let value = productDispNameObj[key];
            productDispNameReplicaObj.subKey = key;
            productDispNameReplicaObj.subValue = value;
            productDispNameReplicaList.push(productDispNameReplicaObj);
        }
      }

      let productDispCategoryReplicaList = [];
      let productDispCategoryObj = data.productDispCategory;
      for (let key in productDispCategoryObj) {
        if (productDispCategoryObj.hasOwnProperty(key)) {
          let productDispCategoryReplicaObj = {};
            let value = productDispCategoryObj[key];
            productDispCategoryReplicaObj.subKey = key;
            productDispCategoryReplicaObj.subValue = value;
            productDispCategoryReplicaList.push(productDispCategoryReplicaObj);
        }
      }

      let productDispTenureReplicaList = [];
      let productDispTenureObj = data.dispProductTenure;
      for (let key in productDispTenureObj) {
        if (productDispTenureObj.hasOwnProperty(key)) {
          let productDispTenureReplicaObj = {};
            let value = productDispTenureObj[key];
            productDispTenureReplicaObj.subKey = key;
            productDispTenureReplicaObj.subValue = value;
            productDispTenureReplicaList.push(productDispTenureReplicaObj);
        }
      }

      let productDispPremiumReplicaList = [];
      let productDispPremiumObj = data.dispPremium;
      for (let key in productDispPremiumObj) {
        if (productDispPremiumObj.hasOwnProperty(key)) {
          let productDispPremiumReplicaObj = {};
            let value = productDispPremiumObj[key];
            productDispPremiumReplicaObj.subKey = key;
            productDispPremiumReplicaObj.subValue = value;
            productDispPremiumReplicaList.push(productDispPremiumReplicaObj);
        }
      }

      let productDispAmountReplicaList = [];
      let productDispAmountObj = data.dispAmount;
      for (let key in productDispAmountObj) {
        if (productDispAmountObj.hasOwnProperty(key)) {
          let productDispAmountReplicaObj = {};
            let value = productDispAmountObj[key];
            productDispAmountReplicaObj.subKey = key;
            productDispAmountReplicaObj.subValue = value;
            productDispAmountReplicaList.push(productDispAmountReplicaObj);
        }
      }

      let productDispDescriptionReplicaList = [];
      let productDispDescriptionObj = data.dispDescription;
      for (let key in productDispDescriptionObj) {
        if (productDispDescriptionObj.hasOwnProperty(key)) {
          let productDispDescriptionReplicaObj = {};
            let value = productDispDescriptionObj[key];
            productDispDescriptionReplicaObj.subKey = key;
            productDispDescriptionReplicaObj.subValue = value;
            productDispDescriptionReplicaList.push(productDispDescriptionReplicaObj);
        }
      }

      data.productDispNameReplicaList = productDispNameReplicaList;
      data.productDispCategoryReplicaList = productDispCategoryReplicaList;
      data.productDispTenureReplicaList = productDispTenureReplicaList;
      data.productDispPremiumReplicaList = productDispPremiumReplicaList;
      data.productDispAmountReplicaList = productDispAmountReplicaList;
      data.productDispDescriptionReplicaList = productDispDescriptionReplicaList;

      setLoanInsuranceDetails(data);

    });

  }, []);

  async function handleSubmit(values, resetForm, setSubmitting) {

    if(typeof(values.validFromDate) === "string" || typeof(values.validToDate) === "string"){
      var datetime = new Date(values.validFromDate);
      values.validFromDate = datetime
        .toISOString()
        .replace("00:00:00.000","00:01:00.000")
        .replace("Z", " ")
        .replace("T", " ");
      var datetime = new Date(values.validToDate);
      values.validToDate = datetime
        .toISOString()
        .replace("00:00:00.000","23:59:59.999")
        .replace("Z", " ")
        .replace("T", " ");
    }else{
      var datetime = new Date(values.validFromDate.setUTCHours(0, 1, 0, 0));
      values.validFromDate = datetime
        .toISOString()
        .replace("Z", " ")
        .replace("T", " ");
      var datetime = new Date(values.validToDate.setUTCHours(23, 59, 59, 999));
      values.validToDate = datetime
        .toISOString()
        .replace("Z", " ")
        .replace("T", " ");
    }

    var organizationObj = values.organization.filter(function (item) {
      return item.organizationName === values.organizationName;
    })[0];

    values.organization = organizationObj;

    for(var i=0;i<values.productDispNameReplicaList.length;i++){
      values.productDispName[values.productDispNameReplicaList[i].subKey] = values.productDispNameReplicaList[i].subValue;
    }

    for(var j=0;j<values.productDispCategoryReplicaList.length;j++){
      values.productDispCategory[values.productDispCategoryReplicaList[j].subKey] = values.productDispCategoryReplicaList[j].subValue;
    }

    for(var k=0;k<values.productDispTenureReplicaList.length;k++){
      values.dispProductTenure[values.productDispTenureReplicaList[k].subKey] = values.productDispTenureReplicaList[k].subValue;
    }

    for(var l=0;l<values.productDispPremiumReplicaList.length;l++){
      values.dispPremium[values.productDispPremiumReplicaList[l].subKey] = values.productDispPremiumReplicaList[l].subValue;
    }

    for(var m=0;m<values.productDispAmountReplicaList.length;m++){
      values.dispAmount[values.productDispAmountReplicaList[m].subKey] = values.productDispAmountReplicaList[m].subValue;
    }

    for(var n=0;n<values.productDispDescriptionReplicaList.length;n++){
      values.dispDescription[values.productDispDescriptionReplicaList[n].subKey] = values.productDispDescriptionReplicaList[n].subValue;
    }

    delete values["productDispNameReplicaList"];
    delete values["productDispCategoryReplicaList"];
    delete values["productDispTenureReplicaList"];
    delete values["productDispPremiumReplicaList"];
    delete values["productDispAmountReplicaList"];
    delete values["productDispDescriptionReplicaList"];
    delete values["organization"];

    //console.log("Edit Post obj:-------- ", values);
    try {
      const repeat = null;
      const result = await provisioningService.editLoanInsurance(productId,
        values
      );
      //resetForm();
      //redirect to list page
      history.push("/configuration/loan-insurance-list")
    } catch (e) {
      console.log(e);
      setSubmitting(false);
    }
    //setSubmit to false
    //call resetForm
  }

  // Calling the create template form with values(to initialize),
  return (
      <LoanInsuranceForm
        values={values}
        handleSubmit={handleSubmit}
        page="edit"
        organization={organization}
        type={type}
        category={category}
      />
  );
}
