import React, { useEffect, useContext, useState, useMemo } from "react";
import { Link } from "react-router-dom";

import Table from "components/common/table/ControlledPaginationTable";
import { provisioningService } from "services/provisioningServices.js";
import PageHeader from "components/layout/PageHeader";
import TemplateForTables from "../../../components/layout/innerPagesTemplate";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import { errorContext } from "context/errorContext";

import "../../../components/Table.scss";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ToolTip } from "../../../components/common/table/ToolTipCell";
const RoleList = props => {
  const [roleList, setRoleList] = useState([]);
  const [status, setStatus] = useState("loading");

  const { dispatch: errorDispatch } = useContext(errorContext);

  let query = useMemo(() => new URLSearchParams(props.location.search), [
    props.location
  ]);

  const { t } = useTranslation();
  const dropitems = [
    {
      item: (
        <Link to="/configuration/role">{t("role_List_Add_Role_Label")}</Link>
      )
    }
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: t("role_List_Role_Label"),
        accessor: "roleName",
        Cell: ({ row }) => {
          return (
            <>
              <ToolTip name={row.original.roleName}>
                <span>{row.original.roleName}</span>
              </ToolTip>
            </>
          );
        }
      },
      {
        Header: t("role_List_Actions_Label"),

        Cell: ({ row }) => {
          let link = `/configuration/role/${row.original.roleName}`;
          return (
            <>
              <td>
                <Link to={link}>
                  <IconButton>
                    <i className="fa fa-edit" aria-hidden="true"></i>
                  </IconButton>
                </Link>
              </td>
              {/* <td>
                <IconButton>
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </IconButton>
              </td> */}
            </>
          );
        }
      }
    ],
    []
  );

  useEffect(() => {
    const orgName = JSON.parse(localStorage.getItem("currentUser"))
      .organizationName;
    provisioningService
      .getRoles(orgName)
      .then(res => {
        setRoleList(res);
        setStatus("success");
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
  }, []);

  const data = React.useMemo(() => [...roleList]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("role_List_Header_Label")}
          breadcrumbs={[
            {
              label: t("role_List_Breadcrum_Dashboard_Label"),
              link: "/dashboard"
            },
            { label: t("role_List_Breadcrum_Configuration_Label") },
            { label: t("role_List_Breadcrum_Role_List_Label") }
          ]}
        />
      </Grid>
      <TemplateForTables
        dropdownitems={dropitems}
        needDropdown={true}
        importIcons
        md={6}
      >
        {status === "loading" ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Table columns={columns} data={data} />
        )}
      </TemplateForTables>
    </Grid>
  );
};

export default RoleList;
