function getToken() {
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const token = user && user.jwtAccessToken;
  return token;
}

async function getBmcStatusData(hours) {
  let url = `${process.env.REACT_APP_CHILLINGUNIT_BMC_CARD}?hours=${hours}`;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      authToken : localStorage.getItem("idToken")
    }
  };

  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getSitesAggregateAlerts(hours, currentDate, pagingState) {
  let url = `${process.env.REACT_APP_CHILLINGUNIT_TOTAL_SITES_AGGREGATE_ALERTS}hour=${hours}&currentDate=${currentDate}&pagingState=${pagingState}`;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      authToken : localStorage.getItem("idToken")
    }
  };

  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    console.error(error);
    throw err;
  }
}

async function getAlertStatusData(hours, currentDate) {
  let url = `${process.env.REACT_APP_CHILLINGUNIT_ALERT_CARD}?hours=${hours}&currentDate=${currentDate}`;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      authToken : localStorage.getItem("idToken")
    }
  };

  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getBmcState(
  state,
  fetchAll = false,
  pagingState = "",
  hour,
  currentDate
) {
  let url;
  // state is the parameter passed in the url
  if (state === "no_data") {
    url = `/authgateway/chillingUnitService/sites/chillingUnits/chillingState?toExclude=false&pagingState=${pagingState}&pageSize=5000&chillingUnitStates=out of service,power off&hour=${hour}&currentDate=${currentDate}`;
  } else if (state === "others") {
    url = `/authgateway/chillingUnitService/sites/chillingUnits/chillingState?toExclude=true&pagingState=${pagingState}&pageSize=5000&chillingUnitStates=chilled,chilling,under chilled,out of service,power off&hour=${hour}&currentDate=${currentDate}`;
  } else if (state === "livedata") {
    url = `/authgateway/chillingUnitService/sites/chillingUnits/chillingState?toExclude=true&pagingState=${pagingState}&chillingUnitStates=out of service,power off&hour=${hour}&currentDate=${currentDate}`;
  } else {
    url = `authgateway/chillingUnitService/sites/chillingUnits/${state}?pagingState=&hour=24&currentDate=${currentDate}&pageSize=5000`;
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      authToken : localStorage.getItem("idToken")
    }
  };

  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getAlertsState(
  state,
  hours,
  currentDate,
  pagingState = "",
  otherValues
) {
  let url;
  if (state === "Other Critical Alerts" || state === "All Alerts") {
    url = `/authgateway/chillingUnitService/sites/chillingunit/selected/alerts?hour=${hours}&alertTypes=${otherValues}&toExclude=true&pageSize=1000&pagingState=${pagingState}&currentDate=${currentDate}`;
  } else {
    url = `/authgateway/chillingUnitService/sites/chillingunit/selected/alerts?hour=${hours}&alertTypes=${state}&toExclude=false&pageSize=1000&pagingState=${pagingState}&currentDate=${currentDate}`;
  }

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      authToken : localStorage.getItem("idToken")
    }
  };

  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function searchAlerts(
  alertTypes,
  pagingState = "",
  hours,
  currentDate,
  chillingUnitUuids = [],
  siteUuids = []
) {
  let url = `/authgateway/chillingUnitService/sites/chillingunit/search/alerts`;
  let body = {
    alertTypes: [alertTypes],
    chillingUnitUuids: chillingUnitUuids,
    siteUuids: siteUuids,
    pagingState: pagingState,
    hour: hours,
    currentDate: currentDate,
    toExclude: false
  };

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      authToken : localStorage.getItem("idToken"),
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  };

  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getVolumeGraphData() {
  const url = `/authgateway/chillingUnitService/data/overallVolumeGraph`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken : localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    // console.log("response got from API 11111 text ", response);
    if (response.ok) {
      // console.log("ok");
      return await response.json();
    } else {
      // console.log("notok");
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getVolumeGraphToday() {
  const url = `/authgateway/chillingUnitService/data/overallVolumeGraphToday`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken : localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    // console.log("response got from API 11111 text ", response);
    if (response.ok) {
      // console.log("ok");
      return await response.json();
    } else {
      // console.log("notok");
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getChecklistByUuid(chillingUnitUuid, startDate, endDate) {
  let url = `${process.env.REACT_APP_GET_CHECKLIST_BY_UUID}?chillingUnitUuid=${chillingUnitUuid}&startDate=${startDate}&endDate=${endDate}`;
  //let url = `${process.env.REACT_APP_GET_CHECKLIST_BY_UUID}?chillingUnitUuid=${chillingUnitUuid}&startDate=2020-01-06&endDate=2020-01-09`;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      authToken : localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getRealTimeData() {
  const url = `/authgateway/chillingUnitService/notification`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken : localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    // console.log("Notification response got from API  ", response);
    // console.log("Notification response type  ", typeof response);
    if (response.ok) {
      // console.log("ok");
      return await response.json();
    } else {
      // console.log("notok");
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function enterCheckListRecords(values) {
  let url = `/authgateway/chillingUnitService/preventive_maintenance`;

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
      authToken : localStorage.getItem("idToken")
    },
    body: JSON.stringify(values)
  };

  try {
    const response = await fetch(url, requestOptions);
    // console.log("response ", response.ok);
    if (response.ok) {
      return {
        data: "CheckList Created",
        status: response.ok
      };
    } else {
      return {
        data: response.error,
        status: response.ok
      };
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getChecklistByChecklistName(orgName, checklistName) {
  let url = `/authgateway/chillingUnitService/preventive_maintenance/${orgName}?checklistName=${checklistName}`;

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      authToken : localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function chillingUnitTemperature(
  pagingState,
  siteUuid,
  chillingUnitUuid,
  startDate,
  endDate
) {
  let url = `/authgateway/chillingUnitService/sites/chillingunit/temp/alerts`;
  let body = {
    chillingUnitUuids: chillingUnitUuid,
    siteUuids: siteUuid,
    pagingState: pagingState,
    //hour: hours,
    startDate: startDate,
    endDate: endDate,
    toExclude: false
  };
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
      authToken : localStorage.getItem("idToken")
    },
    body: JSON.stringify(body)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function chillingUnitVolume(
  pagingState,
  siteUuid,
  chillingUnitUuid,
  startDate,
  endDate
) {
  let url = `/authgateway/chillingUnitService/sites/chillingunit/volume/alerts`;
  let body = {
    chillingUnitUuids: chillingUnitUuid,
    siteUuids: siteUuid,
    pagingState: pagingState,
    //hour: hours,
    startDate: startDate,
    endDate: endDate,
    toExclude: false
  };
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
      authToken : localStorage.getItem("idToken")

    },
    body: JSON.stringify(body)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getBmcStateTest(currentDate) {
  const url = `/authgateway/chillingUnitService/sites/chillingUnits/chillingStateOnly?chillingUnitStates=ALL&pagingState=&currentDate=${currentDate}&hour=24&pageSize=5000&toExclude=true`;
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      authToken : localStorage.getItem("idToken")

    }
  };

  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getBmcAlertsTest(currentDate) {
  // state is the parameter passed in the url
  const url = `/authgateway/chillingUnitService/sites/chillingUnits/chillingUnitAlerts?chillingUnitStates=ALL&pagingState=&currentDate=${currentDate}&hour=24&pageSize=5000&toExclude=true`;
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      authToken : localStorage.getItem("idToken")

    }
  };

  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getBmcStateLive(currentDate) {
  const url = `/authgateway/chillingUnitService/sites/chillingUnits/chillingStateOnly?chillingUnitStates=out of service,power off&pagingState=&currentDate=${currentDate}&hour=24&pageSize=5000&toExclude=true`;
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      authToken : localStorage.getItem("idToken")

    }
  };

  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getBmcAlertsLive(currentDate) {
  // state is the parameter passed in the url
  const url = `/authgateway/chillingUnitService/sites/chillingUnits/chillingUnitAlerts?chillingUnitStates=out of service,power off&pagingState=&currentDate=${currentDate}&hour=24&pageSize=5000&toExclude=true`;
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      authToken : localStorage.getItem("idToken")

    }
  };

  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

//search all alerts

async function searchAllAlerts(body) {
  let url = `${process.env.REACT_APP_ALERTS_SEARCH_ALL}`;

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
      authToken : localStorage.getItem("idToken")

    },
    body: JSON.stringify(body)
  };

  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

export const chillingUnitService = {
  getSitesAggregateAlerts,
  getBmcStatusData,
  getBmcState,
  getAlertStatusData,
  getAlertsState,
  searchAlerts,
  getVolumeGraphData,
  getVolumeGraphToday,
  getChecklistByUuid,
  getRealTimeData,
  getChecklistByChecklistName,
  enterCheckListRecords,
  chillingUnitTemperature,
  chillingUnitVolume,
  getBmcStateTest,
  getBmcAlertsTest,
  getBmcStateLive,
  searchAllAlerts,
  getBmcAlertsLive
};
