import React, { useEffect, useContext, useState, useMemo } from "react";

import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
//import Table from "components/common/table/ControlledPaginationTable";
import Table from "components/common/table/DynamicPaginationTable2";
import PageHeader from "../../../components/layout/PageHeader.js";

import CircularProgress from "@material-ui/core/CircularProgress";
import TemplateForTables from "../../../components/layout/innerPagesTemplate";
import { Grid, Paper } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { notificationService } from "services/notificationServices";
import { provisioningService } from "../../../services/provisioningServices.js";

import { errorContext } from "context/errorContext";
import addDays from "date-fns/addDays";

import "../../../components/Table.scss";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import CommonForm from "components/common/form/common-form";
import { func } from "prop-types";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
import FailureFeedback from "components/common/elements/FailureFeedback";
import { productService } from "services/productServices.js";

import { bannerService } from "services/bannerService";

const bannerList = props => {

  const { dispatch: errorDispatch } = useContext(errorContext);
  const [product, setProduct] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [fetchTag, setFetchTag] = useState([]);
  const [status, setStatus] = useState("loading");

  const [startDate] = React.useState(addDays(new Date(), -2));
  const [endDate] = React.useState(new Date());
  const [pbpData,setPbpData] = React.useState([]);
  const [fetchTagOnPbp, setFetchTagOnPbp] = React.useState([]);

  const [fetchTagStatus, setFetchTagStatus] = useState("loading");

  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();
  const createError = useState({});
  const history = useHistory();

  const user = JSON.parse(localStorage.getItem("currentUser"));
  const [banner, setBanner] = useState([]);  
  const [nodataerror, setNodataerror] = useState ();

  const loadFetchTagData = value => {
    const territory = pbpData.find(pbpData =>pbpData.id === value);
    if(territory != undefined){      
      setFetchTag(territory.territories)
    }
    console.log(territory);    
  }

  const dropitems = [
    {
      item: (<Link to="/configuration/createproduct">Add Product</Link>)
    }
  ];

  let viewDropDown = false;

  let query = useMemo(() => new URLSearchParams(props.location.search), [
    props.location
  ]);

  function handleSubmit(values, resetForm, setSubmitting) {    
    getBannerist(values);
    setSubmitting(false);    
  }

  const { t } = useTranslation();

  function getBannerist(values){    
  bannerService.getBannerList(values.fetchTag, values.appType, values.locale).then((data) => {
  console.log("handleSubmit",data)
  setNodataerror('');

  for (let i = 0; i < data.length; i++) {
    let key = Object.keys(data[i].adImageUrl);
    let tempCode = [];
    
    for (let j = 0; j < key.length; j++) {
      //console.log("if condition",data[i].adImageUrl[key[j]].url);
      //console.log("status",data[i].adImageUrl[key[j]].url !='')
      if (data[i].adImageUrl[key[j]].url != null) {
        tempCode.push(key[j]);
        console.log("languages", data[i]);
      }
    }
  
    console.log("tempcode", tempCode);
    data[i].tempLanguage = tempCode.toString();
  }

    setBanner(data || []);
    setPageInfo(data.pageInfo);
    setStatus("success");
    })

    // For commented here because of dont show the error window

    // .catch(error => {
    //   errorDispatch({
    //     type: "ERROR",
    //     error
    //   });
    //   setStatus("failed");
    // });

    if (data.length===0||null) {
      setNodataerror('No data found, of selected parameters.');      
    } else {
      setNodataerror(''); 

    }  
  }

  function getFetchTag(){
    bannerService.getFetchTag().then((data) => {
      setFetchTag(data.fetchTags);   // postman key value also passing here
      setFetchTagStatus("success");
      setStatus("success");
    })
    .catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });      
      setStatus("failed");
    }); 
  }

  function getPbpList(){
    productService.getPbpList().then(data =>{
      if(data){
        setPbpData(data);
        setFetchTagStatus("success");
      }
    });
  }
 
  useEffect(() => {
    provisioningService.verifyOtpNew('1313131313').then((res) => {
      const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
      const organizationId = JSON.parse(localStorage.getItem("orgId"));
      //getPbpList();
      getFetchTag();
    })
    .catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });   
  }, []);  

  const handleDialogClose = () => {
  };

  const appType = [
    //{name:"smart_farms",value:"smart_farms"},
    {name:"SMART_FARMS",value:"smart_farms"},
    {name:"MOORETAIL",value:"MOORETAIL"}];

  const locale = [
    {name:"HINDI",value:"hi"},
    {name:"ENGLISH",value:"en"},
    {name:"KANNADA",value:"kn"},
    {name:"MARATI", value:"mr"},
    {name:"TELUGU", value:"te"},
    {name:"TAMIL", value:"ta"}];

  // function getFetchTag(){
  //   productService.getFetchTag().then((data) => {
  //     setFetchTagValues(data);
  //     setFetchTagStatus("success");
  //     setStatus("success");
  //   })
  //   .catch(error => {
  //     errorDispatch({
  //       type: "ERROR",
  //       error
  //     });
  //     setStatus("failed");
  //   }); 
  // }

  let values = {
      fetchTag: "",
      appType: "",
      locale: ""
      } 

  const fields = [
    // {
    //   type: "text",
    //   name: "fetchTag",
    //   label: t("fetchTag_Name"),
    //   placeholder: t("fetchTag_Name"),
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },   
    
    {
      type: "singleSelectSearch",  //singleSelectSearch
      name: "fetchTag",
      label: t("banner_List_fetchTag_Name_Label"),
      placeholder: t("banner_List_fetchTag_Name_Label"),
      option:
      fetchTag.length > 0
          ? fetchTag.map(fetchTag => ({
              value: fetchTag,
              label: fetchTag
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        //md: 4,
        className: "mb-2"
      }
    },

    {
      type: "singleSelectSearch",
      name: "appType",
      label: t("banner_List_appType_Label"),
      placeholder: t("banner_List_appType_Label"),
      option:appType.length > 0
          ? appType.map(appType => ({
              value: appType.value,
              label: appType.name
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,        
        className: "mb-2"
      }
    }, 
    {
      type: "select",
      name: "locale",
      label: t("banner_List_language_Label"),
      placeholder: t("banner_List_language_Label"),
      option:locale.length > 0
          ? locale.map(locale => ({
              value: locale.value,
              label: locale.name
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,        
        className: "mb-2"
      }
    },          
  ];  

  const formikForm = React.useRef(null);

  const nextPage = () => {
    if (
      status !== "loading" &&
      pageInfo.hasNextPage &&
      Number(query.get("page")) < pageInfo.totalPages
    ) {
      query.set("page", Number(query.get("page")) + 1);
      props.history.push({
        pathname: props.location.pathname,
        search: query.toString()
      });
    }
  };
  const previousPage = () => {
    props.history.goBack();
  };
  const handlePageSize = size => {
    query.set("size", size);
    query.set("page", 0);
    props.history.push({
      pathname: props.location.pathname,
      search: query.toString()
    });
  };

  let validations = {
    fetchTag: Yup.string().required(t("bannerList_Form_Validation_fetchTag")),
    appType: Yup.string().required(t("bannerList_Form_Validation_appType")),
    locale: Yup.string().required(t("bannerList_Form_Validation_Language"))
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Banner Image",
        Cell: ({ row }) => {
          return (
            <>
              <img src={row.original.adImageUrl.url} width="50" height="50"/>
            </>
          )
        }
      },

      /* {
        Header: t("banner_List_language_Header"),
        accessor: "tempLanguage",       //   Multi language display        
        // Cell: ({ row }) => {
        //       return (
        //         <>
        //           <string src={row.original.adImageUr} />
        //         </>
        //       )
        //     }       
      }, */
   
      {
        Header: t("Name"),
        accessor: "name",
        
      },
      {
        Header: t("Sort Order"),
        accessor: "sortOrder"
      },
      {
        Header: t("Start Date"),
        accessor: "startDate",
      },
      {
        Header: t("End Date"),
        accessor: "endDate"
      },      
      {
        Header: t("Action Type"),
        accessor: "actionType"
      },       
    ],
    []
  );

  const data = React.useMemo(() => [...banner]);
  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title="Banner List"
          breadcrumbs={[
            { label: "Dashboard", link: "/dashboard" },
            { label: "Configuration" },
            { label: "Banner List" }
          ]}
        />
      </Grid>
      <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%"
        }}
      >

    {fetchTagStatus === "loading" ? (
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ height: "50vh" }}
      >
      <CircularProgress />
      </Grid>
      ):(
      <CommonForm
        fields={fields}
        submitLabel={t("bannerList_Form_Submit_Label")}
        submittingLabel={t("bannerList_Form_Submitting_Label")}
        initialValues={values}
        validationSchema={Yup.object().shape({ ...validations })}       
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={(
          values,
          { setSubmitting, setFieldError, resetForm, errors }
        ) => {
          if (!!errors) {
            setSubmitting(false);
          }
          handleSubmit(values, resetForm, setSubmitting);
        }}
        formikRef={formikForm}
        buttonSize="2"
        buttonPosition="right"
        inlineButton={true}
      />
      )}  
      
        {status === "success" ? (
          // <Grid
          //   container
          //   justify="center"
          //   alignItems="center"
          //   style={{ height: "50vh" }}
          // >
          //  <CircularProgress />
          // </Grid>
          <Table
          columns={columns}
          data={data}
          needDropdown={viewDropDown}
          dropItems={dropitems}
          //fileName={"notificationList.xls"}
        />
        ) : null}

      {nodataerror && 
        <div style={{ color: 'red', whiteSpace: 'nowrap', marginLeft: "350px", marginTop: "20px" }}>
      {nodataerror}</div>}
        
      {/* </TemplateForTables> */}
      </Paper>

      {/* <SuccessFeedback
        open={ }
        onClose={handleDialogClose}
        successMessage={t("notification_list_Approve_Success_Message")}
      />
      <FailureFeedback open={failApproveNotification} onClose={handleDialogClose} /> */}
      
     
    </Grid>    
  );
};

export default bannerList;
