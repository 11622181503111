import React from "react";
import excelIcon from "../../../assets/excel.png";

export default function ExcelIcon() {
  return (
    <img
      src={excelIcon}
      alt="excel icon"
      style={{ width: "3rem", height: "100%" }}
    />
  );
}
