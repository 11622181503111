import React, { useState, useContext, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { ErrorMessage, getIn } from "formik";
import PageHeader from "components/layout/PageHeader";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
import CommonForm from "components/common/form/common-form";
import FailureFeedback from "components/common/elements/FailureFeedback";
import LoanProductName from "components/common/form/LoanProductName";
import { notificationService } from "services/notificationServices";
import GeographySelection from "components/common/form/GeographySelection";
import PincodeSelection from "components/common/form/PincodeSelection";
import OrganizationSelectionAgent from "components/common/form/OrganizationSelectionAgent";
import { provisioningService } from "services/provisioningServices";
import { agentAssociationService } from "services/agentAssociationServices";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import "components/responsive.scss";
import "../configuration.scss";
import "../../../components/responsive.scss";

import { useTranslation } from "react-i18next";
import { string } from "prop-types";
import addDays from "date-fns/addDays"
import { errorContext } from "context/errorContext";

import {
  FormControl,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Switch,
  Box,
  withStyles,
} from "@material-ui/core";

export default function AgentAssociationForm({
  values,
  orgData,
  stateData,
  pbpData,
  status,
  agentOrigin,
  servicesOffering,
  agentName
}){

  const title = agentName+" - Agent Association";

  const { t } = useTranslation();
  const formikForm = React.useRef(null);
  const { dispatch: errorDispatch } = useContext(errorContext);

  const [postOfficeData, setPostOfficeData] = React.useState([]);
  const [districtData , setDistrictData] = React.useState([]);
  const [talukData ,setTalukData] = React.useState([]);
  const [villageData, setVillageData] = React.useState([]);
  const [fetchTagOnPbp, setFetchTagOnPbp] = React.useState([]);
  const [vlccData, setVlccData] = React.useState([]);
  const [chillingCenterData,setChillingCenterData] = React.useState([]);
  const [agentAssociateStatus, setAgentAssociateStatus] = useState("loading");

  const [isGeogSelection,setIsGeogSelection] = React.useState(false);
  const [isPincodeSelection,setIsPinCodeSelection] = React.useState(false);
  const [isOrgSelection,setIsOrgSelection] = React.useState(false);
  const [isToggleSelection,setIsToggleSelection] = React.useState(false);
  const [toggle,setToggle] = React.useState(false);
  const [selectionError,setSelectionError] = useState("");
  const [pbpSelectError,setPbpSelectError] = useState("");
  const [serOffSelectError,setSerOffSelectError] = useState("");


  const loadFetchTagData = value => {
    const territory = pbpData.find(pbpData =>pbpData.id === value);
    if(territory != undefined){
      setFetchTagOnPbp(territory.territories)
    }
  }

  function loadFetchTag(){  
    const territory = pbpData.find(pbpData =>pbpData.id === 1);
    if(territory != undefined){
      setFetchTagOnPbp(territory.territories)
    }
  }

  useEffect(() => {
    loadFetchTag();
  }, [pbpData]);
 
  const [villageByPinCode,setVillageByPinCode] = React.useState([]);
  const [stateByPinCode,setStateByPinCode] = React.useState([]);
  const [districtByPinCode,setDistrictByPinCode] = React.useState([]);
  const [talukByPinCode,setTalukByPinCode] = React.useState([]);
  const [statusMessage,setStatusMessage] =  React.useState("");
  const [failedMessage,setFailedMessage] = React.useState("");

  function getGeogByPinCode(value){
    if(value.length === 6){
      provisioningService.getGeographyByPincode(value).then(data => {
        setPostOfficeData(data.postOfficeDetails);
        setStateByPinCode(data.postOfficeDetails[0].name);

        values.villageByPinCode = data.postOfficeDetails[0].name;
        
      }).catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
    }
  }

  function getCCByOrganization(value){
    provisioningService.getCCByOrg(value).then(data => {
      setChillingCenterData(data);
    }).catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
    });
  }

  function getVLCCByCC(value){
    provisioningService.getVlccByOrg(value).then(data => {
      setVlccData(data);
    }).catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
    });
  }
   
  function handleSubmit(values, resetForm, setSubmitting){
   
     values.criteria = {};
     values.criteria.organizationBased = values.organizationBased;
     values.criteria.pincodeBased = values.pincodeBased;
     values.criteria.geoLocationBased = values.geoLocationBased;
     setSelectionError("");
     setPbpSelectError("");
     setSerOffSelectError("");

    if(values.criteria.organizationBased.length === 0){
      setSelectionError("Please select the Organization, Chilling Center and Vlcc by Clicking on Plus Symbol");
      setSubmitting(false);
      return;
    }

    if(values.pbp.length === 0){
      setPbpSelectError("Please select PBP");
      setSubmitting(false);
      return;
    }

    if(values.servicesOffering.length === 0){
      setSerOffSelectError("Please select Services Offering");
      setSubmitting(false);
      return;
    }

   
    for(var j=0;j<values.pbp.length;j++){
      delete values.pbp[j].address;
      delete values.pbp[j].gst;
      delete values.pbp[j].territories;
    }

     delete values["geoLocationBased"];
     delete values["organizationBased"];
     delete values["pincodeBased"];

    let orgArr = [];    
    
    for(var i=0;i<values.criteria.organizationBased.length;i++){

      if(values.criteria.organizationBased[i].organizationName && (values.criteria.organizationBased[i].ccName == '' || values.criteria.organizationBased[i].ccName == null) && values.criteria.organizationBased[i].vlccName == ''){
          let orgObjOrg = {organizationName:"",orgId:null};
            orgObjOrg.organizationName = values.criteria.organizationBased[i].organizationName.orgName;
            orgObjOrg.orgId = values.criteria.organizationBased[i].organizationName.orgId;
            orgArr.push(orgObjOrg);
      }

      if(values.criteria.organizationBased[i].organizationName && values.criteria.organizationBased[i].ccName && values.criteria.organizationBased[i].vlccName == ''){
        let orgObjOrgCc = {organizationName:"",orgId:null,ccName:"",chillingCenterId:null};
          orgObjOrgCc.organizationName = values.criteria.organizationBased[i].organizationName.orgName;
          orgObjOrgCc.orgId = values.criteria.organizationBased[i].organizationName.orgId;
          orgObjOrgCc.ccName = values.criteria.organizationBased[i].ccName.chillingCenterName;
          orgObjOrgCc.chillingCenterId = values.criteria.organizationBased[i].ccName.chillingCenterId;
          orgArr.push(orgObjOrgCc);
      }

      for(var j=0;j<values.criteria.organizationBased[i].vlccName.length;j++){
        let orgObj = {organizationName:"",orgId:null,ccName:"",chillingCenterId:null,vlccName:"",vlccId:null,societyUnqId:null};
          orgObj.organizationName = values.criteria.organizationBased[i].organizationName.orgName;
          orgObj.orgId = values.criteria.organizationBased[i].organizationName.orgId;
          orgObj.ccName = values.criteria.organizationBased[i].ccName.chillingCenterName;
          orgObj.chillingCenterId = values.criteria.organizationBased[i].ccName.chillingCenterId;
          orgObj.vlccName = values.criteria.organizationBased[i].vlccName[j].vlccName;
          orgObj.vlccId = values.criteria.organizationBased[i].vlccName[j].vlccId;
          orgObj.societyUnqId = values.criteria.organizationBased[i].vlccName[j].societyUnqId;
          orgArr.push(orgObj);
      }
    }

    values.criteria.organizationBased = orgArr;

    //console.log("Ultimate Values been passed to API:========= ", values);

    agentAssociationService.validationAgentAssociateSelection(values).then(responseData => {
      //console.log("responseData from API",responseData);
      if(responseData.status === "Success"){
        agentAssociationService.associateAgent(values).then(data => {
          setAgentAssociateStatus("success");
          setStatusMessage(data.message);
          setSubmitting(false);
          resetForm();     
          //console.log(values); 
        }).catch(error => {
          setAgentAssociateStatus("failed");
           errorDispatch({
            type: "ERROR",
            error
           });
         
          setSubmitting(false);
          resetForm();
        });
      }else{
        setFailedMessage(responseData.message);
        setAgentAssociateStatus("failed");
        setSubmitting(false);
        resetForm();
        //console.log(responseData);
      }        
    }).catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });
    
  }

  const dispatch = useDispatch();
  const history = useHistory();
  const handleDialogClose = () => {
    setAgentAssociateStatus("Loading"); 
  };

  const fields = [
    {
      type: "multiSelectAll",
      name: "pbp",
      label: t("agent_Association_Form_PBP_Label"),
      placeholder: t("agent_Association_Form_PBP_Label"),
      option:pbpData.length > 0
        ? pbpData.map(pbpData => ({
            value: pbpData,
            label: pbpData.name
          }))
        : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "multi-select"
      },
      //customOnChange:loadFetchTagData
      mandatory: "mandatory"
    },
    {
      type: "multiSelectAll",
      name: "servicesOffering",
      label: t("agent_Association_Form__ServicesOffering_Label"),
      placeholder: t("agent_Association_Form__ServicesOffering_Label"),
      option:servicesOffering.length > 0
        ? servicesOffering.map(servicesOffering => ({
            value: servicesOffering,
            label: servicesOffering
          }))
        : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "multi-select"
      },
      mandatory: "mandatory"
    },
    // {
    //   type: "custom",
    //   component: ({ setFieldValue, values }, renderFields) => (
    //     <>
    //       <span>
    //         <h3>{t("agent_Association_Form_Pincode_Selection_Label")}</h3>
    //       </span>
    //       <PincodeSelection
    //         name={"pincode"}
    //         setFieldValue={setFieldValue}
    //         values={values["pincodeBased"]}
    //         data={values}
    //       />
    //     </>
    //   ),
    //   grid: {
    //     xs: 12,
    //     className: "title-on-border-box bg-blue-l"
    //   }
    // },
    {
      type: "custom",
      component: ({ setFieldValue, values }, renderFields) => (
        <>
          <span>
            <h3 className="mandatoryField">{t("agent_Association_Form_Organization_Selection_Label")}</h3>
          </span>
          <OrganizationSelectionAgent
            name={"organization"}
            organizationData={orgData}
            setFieldValue={setFieldValue}
            values={values["organizationBased"]}
            data={values}
          />
        </>
      ),
      grid: {
        xs: 12,
        className: "title-on-border-box bg-blue-l"
      }
    }  
  ];

  // let validations = {
  //   // pbpId: Yup.array().required(),
  //   // // .min(1, "At least one option is required")
  //   // // .required("Is Required"),
  //   pbp: Yup.array()
  //     .min(1, 'Pick at least 1 option')
  //     .of(
  //       Yup.object().shape({
  //         label: Yup.string().required(),
  //         //value: Yup.string().required(),
  //       })
  //   ),
  //   servicesOffering: Yup.array()
  //   // .min(1, "At least one option is required")
  //   //   .required("Is Required")
  // };

  return(
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={title}
          breadcrumbs={[
            { label: "Dashboard", link: "/dashboard" },
            { label: "Configuration" },
            { label: "Agent Association" }
          ]}
        />
      </Grid>

      <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%"
        }}
      >
        {status === "success" ? (
      <CommonForm
          fields={fields}
          submitLabel={t("site_Form_Submit_Label")}
          submittingLabel={t("site_Form_Submitting_Label")}
          initialValues={values}
          //validationSchema={Yup.object().shape({ ...validations })}
          validateOnBlur={true}
          //  edit={edit}
          enableReinitialize
          validateOnChange={true}
          onSubmit={(
            values,
              { setSubmitting, setFieldError, resetForm, errors }
            ) => {
            if (!!errors) {
              setSubmitting(false);
            }
            handleSubmit(values, resetForm, setSubmitting);
          }}
          formikRef={formikForm}
          buttonSize="3"
          buttonPosition="right"
        />
        ):null}
        {pbpSelectError && (
          <div style={{ color: "#f44336" }}>{pbpSelectError}</div>
        )}
        {serOffSelectError && (
          <div style={{ color: "#f44336" }}>{serOffSelectError}</div>
        )}
        {selectionError && (
          <div style={{ color: "#f44336" }}>{selectionError}</div>
        )}
    </Paper>
    <SuccessFeedback
      open={agentAssociateStatus === "success"}
      onClose={handleDialogClose}
      successMessage={statusMessage}
      createAnotherMesssage={statusMessage}
    />
    <FailureFeedback
      open={agentAssociateStatus === "failed"}
      onClose={handleDialogClose}
      status={"Failed"}
      message={failedMessage}
      //showButtons={false}
    />
  </Grid>   
  );
}
