import React, { useEffect } from "react";

import { withRouter } from "react-router-dom";

import LinkListItem from "../common/elements/ListItemWithRouterLink";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: 36
  },
  overflowstyle: {
    overflow: "hidden !important"
  },
  hide: {
    display: "none"
  },
  nested: {
    paddingLeft: theme.spacing(2)
  },
  selectedItem: {
    overflow: "visible",
    "& div:nth-child(3)": {
      color: "white"
    },
    color: "white",
    "& span": {
      color: "white"
    },
    backgroundColor: "#2A2C6A !important",
    "& :hover": {
      borderRadius: "0px"
    }
  },
  listRoot: {
    backgroundColor: "#34409A",
    color: "white",
    overflow: "hidden !important",
    wordBreak: "break-word",
    whiteSpace: "normal",
    "& >a": {
      "& hover": {
        color: "white"
      }
    }
  },
  buttonStyle: {
    overflow: "hidden !important",

    "& div:nth-child(1)": {
      minWidth: "34px"
    },
    "& div:nth-child(2)": {
      overflow: "hidden",
      maxWidth: "136px"
    },
    "&:hover": {
      backgroundColor: "#478FCC"
    }
  },
  secActB: {
    overflow: "hidden !important",
    fontSize: "1.3rem",
    color: "#656565"
  },
  secActW: {
    overflow: "hidden !important",
    fontSize: "1.3rem",
    color: "white"
  },
  F12: {
    fontSize: "12px",
    whiteSpace: "nowrap"
  }
}));

let clickedmenu = "";
//to handle cases like "configuration" which does not have a url..in such cases
// props.location.pathaname.includes(items.name.toLowerCase()) checking fails because
// at rerender the "location" props will not have changed

function Menu(props) {
  const { handleClose, isM, items, level, link, draweropen } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (props.location.pathname === "/dashboard") setOpen(false);
  }, [props.location.pathname]);

  useEffect(() => {
    if (props.location.pathname !== "/dashboard") {
      props.location.pathname.includes(items.name.toLowerCase() || items.url) ||
        items.name === clickedmenu
        ? setOpen(true)
        : setOpen(false);
    }

    if (
      items.name === "Preventive Maintenance" &&
      props.location.pathname.includes("/preventive")
    ) {
      setOpen(true);
    }
    if (
      items.name === "Home" &&
      props.location.pathname.includes("/dashboard")
    ) {
      setOpen(true);
    }
    if (clickedmenu === items.name && !open) {
      setOpen(false);
    }
  }, [clickedmenu, items]);

  const handleClick = e => {
    setOpen(!open);
    clickedmenu = e.currentTarget.textContent;
    if (open) {
      setOpen(false);
    } else {
      e.currentTarget.textContent === items.name
        ? setOpen(!open)
        : props.location.pathname.includes(
          items.name.toLowerCase() || items.url
        ) || items.name === clickedmenu
          ? setOpen(open)
          : setOpen(!open);
    }

    isM && !items.children && handleClose();
  };
  const listitem = (
    <LinkListItem
      onClick={handleClick}
      link={link}
      selectedStyle={classes.selectedItem}
      Open={open}
      level={level}
      buttonStyle={classes.buttonStyle}
    >
      <ListItemIcon>
        {items.icon ? (
          <img
            src={open ? items.iconWhite : items.icon}
            alt=""
            style={{ width: "34px", height: "34px" }}
          />
        ) : (
            <img
              src={items.iconWhite}
              alt=""
              style={{ width: "34px", height: "34px" }}
            />
          )}
      </ListItemIcon>
      {draweropen && (
        <>
          <ListItemText
            primary={items.name}
            primaryTypographyProps={{
              classes: {
                body1: classes.F12
              }
            }}
          />
          <ListItemSecondaryAction
            classes={
              level === 0
                ? { root: classes.secActB }
                : { root: classes.secActW }
            }
            style={{ overflow: "hidden" }}
          >
            {items.children ? (
              open ? (
                <KeyboardArrowDownIcon />
              ) : (
                  <KeyboardArrowRightIcon />
                )
            ) : (
                <KeyboardArrowRightIcon />
              )}
          </ListItemSecondaryAction>
        </>
      )}
    </LinkListItem>
  );
  const listitemC = items.children ? (
    <Collapse
      in={open}
      unmountOnExit={true}
      timeout={600}
      classes={{
        container: classes.overflowstyle,
        entered: classes.overflowstyle,
        hidden: classes.overflowstyle,
        wrapper: classes.overflowstyle,
        wrapperInner: classes.overflowstyle
      }}
    >
      <List
        component="div"
        disablePadding
        classes={{
          root: classes.listRoot
        }}
      >
        {items.children.map(item =>
          !!item ? (
            <Menu
              handleClose={handleClose}
              isM={isM}
              items={item}
              level={level + 1}
              key={item.name}
              link={item.url}
              draweropen={draweropen}
              selectedStyle={classes.selectedItem}
            />
          ) : null
        )}
      </List>
    </Collapse>
  ) : (
      ""
    );
  return (
    <>
      {!!listitem ? listitem : null}
      {!!listitemC ? listitemC : null}
    </>
  );
}
export default Menu = withRouter(Menu);
