import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";

import CommonForm from "../../../components/common/form/common-form";
import { errorContext } from "context/errorContext";
import { useTranslation } from "react-i18next";
import DocDownloadRetailer from "./DocDownloadRetailer";

//Import added for Date field
import addDays from "date-fns/addDays";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Switch,
  Box,
  withStyles,
} from "@material-ui/core";


export default function ViewRetailerForm({ values, showSubmitButton, extraDocData }) {
  
  const [dateOfBirth] = React.useState(new Date());
  const [lastOmsFieldUpdated] = React.useState(new Date());
  const [affiliateShopEstDate] = React.useState(new Date());
  const [createdOn] = React.useState(new Date());
  const [updatedOn] = React.useState(new Date());
  const [toggle,setToggle] = React.useState(false);

  const handleToggle = (event) => {
    setToggle(!toggle)
    values[event.target.name] = !values[event.target.name]
  }

  // Context Menu disabling or Mouse right click disabling
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault()
    }
    document.addEventListener("contextmenu", handleContextMenu)
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu)
    }
  }, [])
 
  const formikForm = React.useRef(null);

  const { t } = useTranslation();

  const { dispatch: errorDispatch } = useContext(errorContext);

  const fields = [
    {
      type: "header",
      name: "Retailer Info"
    },
    {
      type: "text",
      name: "name",
      label: t("retailer_View_Form_Retailer_Name"),
      placeholder: t("retailer_View_Form_Retailer_Name"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "gender",
      label: t("retailer_View_Form_Retailer_Gender"),
      placeholder: t("retailer_View_Form_Retailer_Gender"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "datePicker",
      name: "dateOfBirth",
      value: { dateOfBirth },
      label: t("retailer_View_Form_Retailer_DOB"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "addressLine1",
      label: t("retailer_View_Form_Retailer_AddressLine1"),
      placeholder: t("retailer_View_Form_Retailer_AddressLine1"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },

    {
      type: "text",
      name: "mobileNumber",
      label: t("retailer_View_Form_Retailer_MobileNo"),
      placeholder: t("retailer_View_Form_Retailer_MobileNo"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "emailId",
      label: t("retailer_View_Form_Retailer_EmailId"),
      placeholder: t("retailer_View_Form_Retailer_EmailId"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "earningsPerMonth",
      label: t("retailer_View_Form_Retailer_EarningsPerMonth"),
      placeholder: t("retailer_View_Form_Retailer_EarningsPerMonth"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "gst",
      label: t("retailer_View_Form_Retailer_Gst"),
      placeholder: t("retailer_View_Form_Retailer_Gst"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "license",
      label: t("retailer_View_Form_Retailer_License"),
      placeholder: t("retailer_View_Form_Retailer_License"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },

    {
      type: "number",
      name: "retailerPincode",
      label: t("retailer_View_Form_Retailer_Pincode"),
      placeholder: t("retailer_View_Form_Retailer_Pincode"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "retailerVillage",
      label: t("retailer_View_Form_Retailer_Village"),
      placeholder: t("retailer_View_Form_Retailer_Village"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "retailerTaluk",
      label: t("retailer_View_Form_Retailer_Taluk"),
      placeholder: t("retailer_View_Form_Retailer_Taluk"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "retailerDistrict",
      label: t("retailer_View_Form_Retailer_District"),
      placeholder: t("retailer_View_Form_Retailer_District"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "retailerState",
      label: t("retailer_View_Form_Retailer_State"),
      placeholder: t("retailer_View_Form_Retailer_State"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "retailerCountry",
      label: t("retailer_View_Form_Retailer_Country"),
      placeholder: t("retailer_View_Form_Retailer_Country"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "latLang",
      label: t("retailer_View_Form_Retailer_LatLong"),
      placeholder: t("retailer_View_Form_Retailer_LatLong"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "noOfYearsAsRetailer",
      label: t("retailer_View_Form_Retailer_NoOfYearsAsRetailer"),
      placeholder: t("retailer_View_Form_Retailer_NoOfYearsAsRetailer"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "noOfYearsStayedInThatAddress",
      label: t("retailer_View_Form_Retailer_NoOfYearsStayedInThatAddress"),
      placeholder: t("retailer_View_Form_Retailer_NoOfYearsStayedInThatAddress"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "otherOccupation",
      label: t("retailer_View_Form_Retailer_OtherOccupation"),
      placeholder: t("retailer_View_Form_Retailer_OtherOccupation"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "retailerRoofType",
      label: t("retailer_View_Form_Retailer_RoofType"),
      placeholder: t("retailer_View_Form_Retailer_RoofType"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "retailerShopOwnership",
      label: t("retailer_View_Form_Retailer_ShopOwnership"),
      placeholder: t("retailer_View_Form_Retailer_ShopOwnership"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "retailerPrimaryOccupation",
      label: t("retailer_View_Form_Retailer_PrimaryOccupation"),
      placeholder: t("retailer_View_Form_Retailer_PrimaryOccupation"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "totalVolumeSales",
      label: t("retailer_View_Form_Retailer_TotalVolumeSales"),
      placeholder: t("retailer_View_Form_Retailer_TotalVolumeSales"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "custom",
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      },
      component: ({}) => (
        <FormControlLabel 
          control={
              <Switch disabled={true} checked={values.isActive} onChange={handleToggle} name="isActive" value={values.isActive} />
          }
          label={t("retailer_View_Form_Retailer_IsActive")}
          labelPlacement="top"
        />
      )
    }, 
    {
      type: "text",
      name: "retailerUuid",
      label: t("retailer_View_Form_Retailer_Uuid"),
      placeholder: t("retailer_View_Form_Retailer_Uuid"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "mooretailReferenceId",
      label: t("retailer_View_Form_Retailer_MooretailReferenceId"),
      placeholder: t("retailer_View_Form_Retailer_MooretailReferenceId"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "omsReferenceId",
      label: t("retailer_View_Form_Retailer_OmsReferenceId"),
      placeholder: t("retailer_View_Form_Retailer_OmsReferenceId"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "territory",
      label: t("retailer_View_Form_Retailer_Territory"),
      placeholder: t("retailer_View_Form_Retailer_Territory"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "wareHouseId",
      label: t("retailer_View_Form_Retailer_Warehouse"),
      placeholder: t("retailer_View_Form_Retailer_Warehouse"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "datePicker",
      name: "lastOmsFieldUpdated",
      value: { lastOmsFieldUpdated },
      label: t("retailer_View_Form_Retailer_LastOmsFieldUpdated"),
      placeholder: t("retailer_View_Form_Retailer_LastOmsFieldUpdated"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },


    {
      type: "header",
      name: "Retailer Shop Info"
    },
    {
      type: "text",
      name: "storeName",
      label: t("retailer_View_Form_Store_Name"),
      placeholder: t("retailer_View_Form_Store_Name"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "storeAddress1",
      label: t("retailer_View_Form_Store_Address1"),
      placeholder: t("retailer_View_Form_Store_Address1"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "number",
      name: "storePincode",
      label: t("retailer_View_Form_Store_Pincode"),
      placeholder: t("retailer_View_Form_Store_Pincode"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "storeVillage",
      label: t("retailer_View_Form_Store_Village"),
      placeholder: t("retailer_View_Form_Store_Village"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "storeTaluk",
      label: t("retailer_View_Form_Store_Taluk"),
      placeholder: t("retailer_View_Form_Store_Taluk"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "storeDistrict",
      label: t("retailer_View_Form_Store_District"),
      placeholder: t("retailer_View_Form_Store_District"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "storeState",
      label: t("retailer_View_Form_Store_State"),
      placeholder: t("retailer_View_Form_Store_State"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "storeCountry",
      label: t("retailer_View_Form_Store_Country"),
      placeholder: t("retailer_View_Form_Store_Country"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "number",
      name: "storePhoneNumber",
      label: t("retailer_View_Form_Store_PhoneNumber"),
      placeholder: t("retailer_View_Form_Store_PhoneNumber"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },  
    {
      type: "text",
      name: "location",
      label: t("retailer_View_Form_Store_Location"),
      placeholder: t("retailer_View_Form_Store_Location"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "shopGstNo",
      label: t("retailer_View_Form_Store_GstNo"),
      placeholder: t("retailer_View_Form_Store_GstNo"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "datePicker",
      name: "affiliateShopEstDate",
      value: { affiliateShopEstDate },
      label: t("retailer_View_Form_Store_AffiliateShopEstDate"),
      placeholder: t("retailer_View_Form_Store_AffiliateShopEstDate"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },


    {
      type: "header",
      name: "Bank Info"
    },
    {
      type: "text",
      name: "accountName",
      label: t("retailer_View_Form_Bank_AccountName"),
      placeholder: t("retailer_View_Form_Bank_AccountName"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "number",
      name: "accountNumber",
      label: t("retailer_View_Form_Bank_AccountNumber"),
      placeholder: t("retailer_View_Form_Bank_AccountNumber"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "bankName",
      label: t("retailer_View_Form_Bank_BankName"),
      placeholder: t("retailer_View_Form_Bank_BankName"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "branch",
      label: t("retailer_View_Form_Bank_Branch"),
      placeholder: t("retailer_View_Form_Bank_Branch"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "number",
      name: "ifsc",
      label: t("retailer_View_Form_Bank_Ifsc"),
      placeholder: t("retailer_View_Form_Bank_Ifsc"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },



    {
      type: "header",
      name: "Other Info"
    },
    {
      type: "text",
      name: "checkerName",
      label: t("retailer_View_Form_CheckerName"),
      placeholder: t("retailer_View_Form_CheckerName"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "checkerMobNo",
      label: t("retailer_View_Form_CheckerMobNo"),
      placeholder: t("retailer_View_Form_CheckerMobNo"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "createdBy",
      label: t("retailer_View_Form_CreatedBy"),
      placeholder: t("retailer_View_Form_CreatedBy"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "datePicker",
      name: "createdOn",
      value: { createdOn },
      label: t("retailer_View_Form_CreatedOn"),
      placeholder: t("retailer_View_Form_CreatedOn"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "datePicker",
      name: "updatedOn",
      value: { updatedOn },
      label: t("retailer_View_Form_UpdatedOn"),
      placeholder: t("retailer_View_Form_UpdatedOn"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "employeeCode",
      label: t("retailer_View_Form_EmployeeCode"),
      placeholder: t("retailer_View_Form_EmployeeCode"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "engineerMobNo",
      label: t("retailer_View_Form_EngineerMobNo"),
      placeholder: t("retailer_View_Form_EngineerMobNo"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "providers",
      label: t("retailer_View_Form_Providers"),
      placeholder: t("retailer_View_Form_Providers"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "groupTag",
      label: t("retailer_View_Form_GroupTag"),
      placeholder: t("retailer_View_Form_GroupTag"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "fetchTag",
      label: t("retailer_View_Form_FetchTag"),
      placeholder: t("retailer_View_Form_FetchTag"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "customTag",
      label: t("retailer_View_Form_CustomTag"),
      placeholder: t("retailer_View_Form_CustomTag"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },

    {
      type: "header",
      name: "Extra Documents"
    },
    {
      type: "custom",
      component: () => (
        <DocDownloadRetailer
          name="retailerExtraDocumentsDTOList"
          extraDocData={extraDocData}
        />
      )
    }

  ]

  return (
    <CommonForm
      fields={fields}     
      initialValues={values}
      showSubmitButton={showSubmitButton}    
      buttonSize="3"
      buttonPosition="right"
      enableReinitialize
    />  
  );
}
