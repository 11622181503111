import React, { useEffect, useContext,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { authContext } from "context/authContext";
import ApproveProductForm from "./ApproveProductForm";
import { approveServices } from "services/approveServices";
import { errorContext } from "context/errorContext";
import addDays from "date-fns/addDays";
import { Grid, Paper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation } from 'react-router-dom'; 
import { isAfter } from "date-fns";
import { useTranslation } from "react-i18next";
import { notificationService } from "services/notificationServices";
import { provisioningService } from "../../../services/provisioningServices.js";
import FailureFeedback from "components/common/elements/FailureFeedback";

export default function ViewProduct() {

  const { t } = useTranslation();
  const formikForm = React.useRef(null);

  const { dispatch: errorDispatch } = useContext(errorContext);

  const [status, setStatus] = useState("loading");
  const [language, setLanguage] = React.useState([]);
  const [statusMessage,setStatusMessage] =  React.useState("");
  const [validStatusSelection, setValidStatusSelection] = useState(false);
  let showSubmitButton = false;

  function getLanguageData(){
    notificationService.getNotificationUIOptions('LANGUAGE').then(optionData => {
      if (optionData) {
        setLanguage(optionData);
      }
    });
  }

  useEffect(() => {
    provisioningService.verifyOtpNew('1313131313').then((res) => {
      const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
      const organizationId = JSON.parse(localStorage.getItem("orgId"));
      getLanguageData();
    })
    .catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });
  }, []);

  const location = useLocation();
  const productViewData = location.state.from;

  let  languageList = [];
  if(productViewData!=undefined && productViewData.after!=undefined && productViewData.after.languages!=undefined){
    let languageObj = productViewData.after.languages;
    for(let key in languageObj){
      if(languageObj.hasOwnProperty(key)){
        let languageReplicaObj = {};
        let lngObj = {};
        let value = languageObj[key];
        console.log(key,value);
        if(key == "en"){
          languageReplicaObj.language = "ENGLISH";
        } else if (key == "kn"){
          languageReplicaObj.language = "KANNADA";
        } else if( key == "hi"){
          languageReplicaObj.language = "HINDI";
        }
        Object.assign(lngObj , languageReplicaObj, value);
                  
        console.log("languageObj" , lngObj);
        languageList.push(lngObj);
      }
    }
  }

  const values =  {
    amount:!!productViewData && !!productViewData.after? productViewData.after.amount :"",
    barCode:!!productViewData && !!productViewData.after? productViewData.after.barCode:"",
    batch:!!productViewData && !!productViewData.after? productViewData.after.batch:"",
    benefits:!!productViewData && !!productViewData.after? productViewData.after.benefits:"",
    brand:!!productViewData && !!productViewData.after? productViewData.after.brand:"",
    bulkBuyAllowed:!!productViewData && !!productViewData.after? productViewData.after.bulkBuyAllowed:"",
    buyingPrice:!!productViewData && !!productViewData.after? productViewData.after.buyingPrice:"",
    category:!!productViewData && !!productViewData.after ? productViewData.after.itemGroup:"",
    categoryDisplayName:!!productViewData && !!productViewData.after? productViewData.after.categoryDisplayName:"",
    categoryTree:!!productViewData && !!productViewData.after? productViewData.after.categoryTree:"",
    createdDate:!!productViewData && !!productViewData.after? productViewData.after.createdDate:"",
    description:!!productViewData && !!productViewData.after? productViewData.after.description:"",
    expiryDate:!!productViewData && !!productViewData.after? productViewData.after.expiryDate:"",
    fetchTag:!!productViewData && !!productViewData.after? productViewData.after.fetchTag:"",
    gstPercentage:!!productViewData && !!productViewData.after? productViewData.after.gstPercentage:"",
    howToUse:!!productViewData && !!productViewData.after? productViewData.after.howToUse:"",
    hsn:!!productViewData && !!productViewData.after? productViewData.after.hsn:"",
    id:!!productViewData ? productViewData.id:"",
    image:!!productViewData && !!productViewData.after? productViewData.after.image:"",
    imageURL:!!productViewData && !!productViewData.after? productViewData.after.imageURL:"",
    imageUrls:!!productViewData && !!productViewData.after? productViewData.after.imageUrls:"",
    initialSize:!!productViewData && !!productViewData.after? productViewData.after.initialSize:"",
    isActive:!!productViewData && !!productViewData.after? productViewData.after.isActive:"",
    isProductEligible:!!productViewData && !!productViewData.after? productViewData.after.isProductEligible:"",
    itemCode:!!productViewData && !!productViewData.after? productViewData.after.itemCode:"",
    itemGroup:!!productViewData && !!productViewData.after? productViewData.after.itemGroup:"",
    itemName:!!productViewData && !!productViewData.after? productViewData.after.itemName:"",
    languages:!!productViewData && !!productViewData.after? productViewData.after.languages:"",
    licenses:!!productViewData && !!productViewData.after? productViewData.after.licenses:"",
    manufacturingDate:!!productViewData && !!productViewData.after? productViewData.after.manufacturingDate:"",
    maxBulkQtyAllowed:!!productViewData && !!productViewData.after? productViewData.after.maxBulkQtyAllowed:"",
    maxQtyAllowed:!!productViewData && !!productViewData.after? productViewData.after.maxQtyAllowed:"",
    method:!!productViewData && !!productViewData.after? productViewData.after.method:"",
    minBulkQtyAllowed:!!productViewData && !!productViewData.after? productViewData.after.minBulkQtyAllowed:"",
    minQtyAllowed:!!productViewData && !!productViewData.after? productViewData.after.minQtyAllowed:"",
    omsReferenceId:!!productViewData && !!productViewData.after? productViewData.after.omsReferenceId:"",
    price:!!productViewData && !!productViewData.after? productViewData.after.price:"",
    priority:!!productViewData && !!productViewData.after? productViewData.after.priority:"",
    productCode:!!productViewData && !!productViewData.after? productViewData.after.productCode:"",
    productDescriptionDisplayName:!!productViewData && !!productViewData.after? productViewData.after.productDescriptionDisplayName:"",
    productDisplayName:!!productViewData && !!productViewData.after? productViewData.after.productDisplayName:"",
    productGroup:!!productViewData && !!productViewData.after? productViewData.after.productGroup:"",
    productName:!!productViewData && !!productViewData.after? productViewData.after.productName:"",
    productType:!!productViewData && !!productViewData.after? productViewData.after.productType:"",
    productUUID:!!productViewData && !!productViewData.after? productViewData.after.productUUID:"",
    quantity:!!productViewData && !!productViewData.after? productViewData.after.quantity:"",
    rating:!!productViewData && !!productViewData.after? productViewData.after.rating:"",
    searchTag:!!productViewData && !!productViewData.after? productViewData.after.searchTag:"",
    sellingPrice:!!productViewData && !!productViewData.after? productViewData.after.sellingPrice:"",
    sku:!!productViewData && !!productViewData.after? productViewData.after.sku:"",
    status:!!productViewData ? productViewData.status:"",
    stepCount:!!productViewData && !!productViewData.after? productViewData.after.stepCount:"",
    stockUom:!!productViewData && !!productViewData.after? productViewData.after.stockUom:"",
    uom:!!productViewData && !!productViewData.after? productViewData.after.uom:"",
    languageList:!!languageList ? languageList:"",
    remarks:!!productViewData ? productViewData.remarks:"",
  };

  if(productViewData.status === "APPROVED" || productViewData.status === "REJECTED"){
    showSubmitButton = false ;
  }else{
    showSubmitButton = true ;
  }

  function handleSubmit(values, resetForm, setSubmitting) {
    console.log("View of values",values);
  
    productViewData.remarks = values.remarks;
    productViewData.status = values.status;  
    
    if(values.status === "PENDING"){
      setValidStatusSelection(true);
      setSubmitting(false);
      return;
    }
       
    approveServices.adminUpdateStatus(values.id,values.status,productViewData).then(data => {
      console.log(values);
      setSubmitting(false);
      if(values.status === "APPROVED"){
        setStatus("approvedSuccessfully");
      }else if(values.status === "REJECTED"){
        setStatus("rejectedSuccessfully");
      }
    })
    .catch(error => {
      console.error(error);
      if(error.error.message !=undefined){
        setStatusMessage(error.error.message);
      }
      setStatus("failed");
      setSubmitting(false);
    });
  }

  const handleClose = () => {
    setValidStatusSelection(false);
  };

  return(
    <>
      <Grid container>
        <ApproveProductForm
          values={values}
          handleSubmit={handleSubmit}
          language={language}
          status={status}
          showSubmitButton={showSubmitButton}
          statusMessage={statusMessage} />
      </Grid>
      
      <FailureFeedback
        open={validStatusSelection}
        onClose={handleClose}
        status={"Failed"}
        message={t("approve_Form_select_approve_reject_status")}
        showButtons={true} />
    </>
  );
}
