import { func } from "prop-types";
import { utilityServices } from "./utilityServices";

function getToken() {
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const token = user && user.jwtAccessToken;
  return token;
}

async function getItemLevelConfigurationAllProducts(pageSize = "", pageIndex = ""){
  const url = `${process.env.REACT_APP_GET_ITEMLEVELCONFIGURATION_ALL_PRODUCTS}?page=${pageIndex}&size=${pageSize}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Get Item Level Config List 
async function getItemLevelConfigList() {
  const url = `productService/api/materialRequest/productByFetchTag`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": 'e87MPVwlgORoG9J8Pod4j7L5p0eQfC',
      //Authorization: `Bearer ${getToken()}`,
      "locale": "kn",
      "fetchTag": "mooRetail_Banner",
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    console.log("Catch in prov", error);
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}


// Get Item Level Config List - Fetch by Id
async function getItemLevelConfigListById(id) {
    const url = `${process.env.REACT_APP_GET_ITEMLEVELCONFIG_DETAILS}/${id}`;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": 'e87MPVwlgORoG9J8Pod4j7L5p0eQfC',
        //Authorization: `Bearer ${getToken()}`,
        "locale": "kn",
        "fetchTag": "mooRetail_Banner",
        Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
        authToken: localStorage.getItem("idToken")
      }
    };
    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        return await response.json();
      } else {
        throw response;
      }
    } catch (error) {
      let err = {
        status: error.status,
        error: !!error.body ? await error.json() : true
      };
      throw err;
    }
  }

  // Get Item Level Config MasterData
async function getItemLevelConfigMasterData() {
  const url = `productService/api/product/masterData`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": 'e87MPVwlgORoG9J8Pod4j7L5p0eQfC',
      //Authorization: `Bearer ${getToken()}`,
      // "locale": "kn",
      // "fetchTag": "mooRetail_Banner",
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    console.log("Catch in prov", error);
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Get Item Level Config Price Mapping List - Fetch by Id
async function getItemLevelConfigPriceMappingList(id) {
  const url = `${process.env.REACT_APP_GET_ITEMLEVELCONFIGPRICEMAPPING_LIST}/${id}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": 'e87MPVwlgORoG9J8Pod4j7L5p0eQfC',
      //Authorization: `Bearer ${getToken()}`,
      "locale": "kn",
      "fetchTag": "mooRetail_Banner",
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Item level Configuration - Save 
async function saveItemLevelConfigurations(config) {
  const url = `${process.env.REACT_APP_SAVE_ITEMLEVELCONFIGURATIONS}`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": 'gh7MvvJXnRQO44PatMnoAuBcgLNTfk',
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
      authToken: localStorage.getItem("idToken")
    },
    body: JSON.stringify(config)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

// Get Item Level Config Price Mapping List - Fetch by Id and Params
async function getItemLevelConfigPriceMappingListBasedParams(id, pageSize = "", pageIndex = "", appType, customerGroup, territory) {
  const url = `${process.env.REACT_APP_GET_ITEMLEVELCONFIGPRICEMAPPING_LIST}/${id}?appType=${appType}&customerGroup=${customerGroup}&territory=${territory}&page=${pageIndex}&size=${pageSize}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": 'e87MPVwlgORoG9J8Pod4j7L5p0eQfC',
      //Authorization: `Bearer ${getToken()}`,
      "locale": "kn",
      "fetchTag": "mooRetail_Banner",
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
      authToken: localStorage.getItem("idToken")
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}


export const itemLevelConfigurationService = {  
  getItemLevelConfigurationAllProducts,
  getItemLevelConfigList,
  getItemLevelConfigListById,
  getItemLevelConfigMasterData,
  getItemLevelConfigPriceMappingList,
  saveItemLevelConfigurations,
  getItemLevelConfigPriceMappingListBasedParams
};
