import React, { useEffect, useContext,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { authContext } from "context/authContext";
import ApprovePriceForm from "./ApprovePriceForm";
import { notificationService } from "services/notificationServices";
import { errorContext } from "context/errorContext";
import addDays from "date-fns/addDays";
import { Grid, Paper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation } from 'react-router-dom'; 
import { isAfter } from "date-fns";
import { approveServices } from "services/approveServices";
import FailureFeedback from "components/common/elements/FailureFeedback";
import { useTranslation } from "react-i18next";

export default function ViewPrice() {
  
  const { t } = useTranslation();
    const { dispatch: errorDispatch } = useContext(errorContext);

    const createError = useSelector(state => state.site.toJS().createError);

    const location = useLocation();
    const priceViewData = location.state.from;
    let showSubmitButton = false;

    const [status, setStatus] = useState("loading");
    const [statusMessage,setStatusMessage] =  React.useState("");
    const [validStatusSelection, setValidStatusSelection] = useState(false);

    if(priceViewData.status === "APPROVED" || priceViewData.status === "REJECTED"){
      showSubmitButton = false ;
    }else{
      showSubmitButton = true ;
    }

    const values =  {
      sellingPrice:!!priceViewData && !!priceViewData.after ? priceViewData.after.sellingPrice:"",
      beforeSellingPrice:!!priceViewData && !!priceViewData.before ? priceViewData.before.sellingPrice:"",
      mrp:!!priceViewData && !!priceViewData.after ? priceViewData.after.mrp:"",
      beforeMrp:!!priceViewData && !!priceViewData.before ? priceViewData.before.mrp:"",
      taxPercentage:!!priceViewData && !!priceViewData.after ? priceViewData.after.taxPercentage:"",
      beforeTaxPercentage:!!priceViewData && !!priceViewData.before ? priceViewData.before.taxPercentage:"",
      discountInPercentage:!!priceViewData && !!priceViewData.after ? priceViewData.after.discountInPercentage:"",
      beforeDiscountInPercentage:!!priceViewData && !!priceViewData.before ? priceViewData.before.discountInPercentage:"",
      isActive:!!priceViewData && !!priceViewData.after ? priceViewData.after.isActive:"",
      beforeIsActive:!!priceViewData && !!priceViewData.before ? priceViewData.before.isActive:"",
      appType:!!priceViewData && !!priceViewData.after ? priceViewData.after.appType:"",
      cgstPercentage:!!priceViewData && !!priceViewData.after ? priceViewData.after.cgstPercentage:"",
      id:!!priceViewData && !!priceViewData.after ? priceViewData.after.id:"",
      igstPercentage:!!priceViewData && !!priceViewData.after ? priceViewData.after.igstPercentage:"",
      mappingTag:!!priceViewData && !!priceViewData.after ? priceViewData.after.mappingTag:"",
      productId:!!priceViewData && !!priceViewData.after ? priceViewData.after.productId:"",
      productOmsReferenceId:!!priceViewData && !!priceViewData.after ? priceViewData.after.productOmsReferenceId:"",
      sgstPercentage:!!priceViewData && !!priceViewData.after ? priceViewData.after.sgstPercentage:"",
      createdAt:!!priceViewData ? priceViewData.createdAt :"",
      entityId:!!priceViewData ? priceViewData.entityId :"",
      entityType:!!priceViewData ? priceViewData.entityType :"",
      id:!!priceViewData ? priceViewData.id:"",
      lastUpdated:!!priceViewData ? priceViewData.lastUpdated:"",
      omsReferenceId:!!priceViewData ? priceViewData.omsReferenceId:"",
      origin:!!priceViewData ? priceViewData.origin:"",
      remarks:!!priceViewData ? priceViewData.remarks:"",
      requestId:!!priceViewData ? priceViewData.requestId:"",
      status:!!priceViewData ? priceViewData.status:"",
      updatedAt:!!priceViewData ? priceViewData.updatedAt:"",
      customerGroup:!!priceViewData && !!priceViewData.after ? priceViewData.after.customerGroup:"",
      approvalUpdatedAt:!!priceViewData ? new Date(priceViewData.approvalUpdatedAt):""
    };

  function handleSubmit(values, resetForm,setSubmitting) {
    console.log("View of values",values);
  
    priceViewData.remarks = values.remarks;
    priceViewData.status = values.status;

    if(values.status === "PENDING"){
      setValidStatusSelection(true);
      setSubmitting(false);
      return;
    }    
       
    approveServices.adminUpdateStatus(values.id,values.status,priceViewData).then(data => {
      console.log(values);
      if(values.status === "APPROVED"){
        setStatus("approvedSuccessfully");
      }else if(values.status === "REJECTED"){
        setStatus("rejectedSuccessfully");
      }
      setSubmitting(false);
    })
    .catch(error => {
      setStatus("failed");
      if(error.error.message !=undefined){
        setStatusMessage(error.error.message);
      }
      console.error(error);
      console.log(error.error.message);
     
      setSubmitting(false);
    });
  }

  const handleClose = () => {
    setValidStatusSelection(false);
  };

  return(
    <>
      <Grid container>
        <ApprovePriceForm
          values={values}
          handleSubmit={handleSubmit}
          status={status}
          showSubmitButton={showSubmitButton}
          createError = {createError}
          statusMessage = {statusMessage}
        />
      </Grid>

      <FailureFeedback
        open={validStatusSelection}
        onClose={handleClose}
        status={"Failed"}
        message={t("approve_Form_select_approve_reject_status")}
        showButtons={true} />
      
    </>
  );
}
