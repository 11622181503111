import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
//import Button from "@material-ui/core/Button";
import SuccessIcon from "../../../assets/success.png";
import ErrorMsgIcon from "../../../assets/error.png";
import { CustomizedPrimaryButton } from "../elements/CustomizedPrimaryButton";
export default function Popups({ open, setOpen, message, status }) {
  const handleClose = () => {
    setOpen(false);
    // history.push('/dashboard');
  };

  return (
    <div>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {" "}
          <div className="d-flex flex-column align-items-center justify-center h-100 position-relative">
            <div
              className="d-flex flex-column align-items-center justify-center w-100 mb-2"
              style={{ background: status ? "blue" : "orange" }}
            >
              {/* <i className="fa fa-check"></i> */}
              <img
                src={status ? SuccessIcon : ErrorMsgIcon}
                alt={status ? "success icon" : "error icon"}
                style={{
                  margin: "0 auto",
                  display: "block"
                }}
              />
              <h5 className="w-100 mb-4 text-center text-white"><i>{message}</i></h5>
            </div>
            {/* {!status && ( */}
            <CustomizedPrimaryButton
              variant="contained"
              color="primary"
              onClick={handleClose}
              label="Close"
            ></CustomizedPrimaryButton>
            {/* )} */}
          </div>
        </DialogTitle>
      </Dialog>
    </div>
  );
}
