import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Skeleton from "@material-ui/lab/Skeleton";
import { Field } from "formik";

export default function MultiSelect({
  field,
  handleChange,
  value,
  label,
  loading,
  options,
  onChange
}) {
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <Field name={field}>
      {({ form: { setFieldValue } }) => (
        <FormControl variant="outlined" fullWidth={true} margin="dense">
          <InputLabel ref={inputLabel} id={field} htmlFor={field}>
            {label}
          </InputLabel>
          <Select
            id={field}
            value={value}
            multiple
            fullWidth={true}
            renderValue={selected => selected.join(", ")}
            onChange={e => {
              handleChange(field, e.target.value);
              onChange && onChange(e.target.value, handleChange);
            }}
            labelWidth={labelWidth}
          >
            {/* <ListItem value="" button onClick={handleSelectAll}>
          <Checkbox checked={selectAll} />
          <ListItemText primary="selec all" />
        </ListItem> */}
            {!!loading && <Skeleton variant="rect" width="100%" height={30} />}
            {!loading &&
              options.map(option => (
                <MenuItem value={option.value} key={option.value}>
                  <Checkbox
                    checked={value.indexOf(option.value) > -1}
                    color="primary"
                  />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    </Field>
  );
}
