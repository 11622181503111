import React, { useState ,useContext } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { FieldArray,Field , getIn} from "formik";
import { provisioningService } from "services/provisioningServices";
import { farmerAssociationService } from "services/farmerAssociationServices";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";
import { errorContext } from "context/errorContext";
import PincodeChip from "./PincodeChip";
import WarehousePincodeChip from "./WarehousePincodeChip";

export default function PincodeSelection({
    name,
    metaProps = { margin: "dense", variant: "outlined", fullWidth: true },
    setFieldValue,
    values,
    warehouseChip = false
  }) {

    const [pincodeValue, setPincodeValue] = useState("");
    const [postOfficeData, setPostOfficeData] = React.useState([]);
    const [stateByPincode, setStateByPinCode] = useState("");
    const [districtByPincode, setDistrictByPincode] = useState("");
    const [talukByPinCode,setTalukByPincode] = useState("");
    const [] = useState("");

    const [pincodeError,setPincodeError] = useState("");

    const [selectedPostOfficeValue, setSelectedPostOfficeValue] = React.useState([]);

    const { dispatch: errorDispatch } = useContext(errorContext);
  
    const { t } = useTranslation();
  
    function handlePincodeChange(e,setFieldValue,values){
      if(e.length === 6){
        setPincodeError("");
        provisioningService.getGeographyByPincode(e).then(data => {
         // setPostOfficeData(data.postOfficeDetails);
          setStateByPinCode(data.postOfficeDetails[0].state);
          setDistrictByPincode(data.postOfficeDetails[0].district);
          setTalukByPincode(data.postOfficeDetails[0].block);
          
        }).catch(error => {
          setPincodeError("Please select the Valid Pincode");
          setStateByPinCode("");
          setDistrictByPincode("");
          setTalukByPincode("");
          return;
        });
      }else{
        setPincodeError("Please select the Valid Pincode");
        setStateByPinCode("");
        setDistrictByPincode("");
        setTalukByPincode("");
        return;
      }
    }   

    function handlePostOfficeChange(e,setFieldValue,value){

    }
  
    async function handlePincodeData() {

      if(pincodeValue === ""){
        setPincodeError("Please select the Valid Pincode");
        setStateByPinCode("");
        setDistrictByPincode("");
        setTalukByPincode("");
        return;
      }

      let selectedData = {};

      selectedData.pincode = pincodeValue;
      selectedData.state = stateByPincode;
      selectedData.district = districtByPincode;
      selectedData.taluk = talukByPinCode;   

      let isDuplicate =false;
      for(var i=0;i<values.length;i++){
        if(values[i].pincode === pincodeValue){
          isDuplicate = true;
          break;
        }
      }
      if(!isDuplicate){
        values.push(selectedData);
      }

      setFieldValue(values);

      setPincodeValue("");
      setSelectedPostOfficeValue("");
      setStateByPinCode("");
      setDistrictByPincode("");
      setTalukByPincode("");
     
    }
  
    return (
        <Grid container item xs={12} className="mb-0 d-flex flex-direction-column device-wrapper">
          <Grid item container xs={12}  spacing={2} alignContent="flex-start" className="site-inner">
            <Grid item xs={11} md={3}>
               <Field
                type={"text"}
                name={name}
                label={t("Farmer_Association_Form_Pincode_Selection_Label")}
                component={TextField}
                placeholder={"Pin Code"}
                value={pincodeValue}
                inputProps={{
                   onChange: e => {
                   setPincodeValue(e.target.value); 
                   handlePincodeChange(
                     e.target.value,
                     values
                   );
                 }
                }}
                {...metaProps}
                
            />
            {pincodeError && (
              <div style={{ color: "#f44336" }}>{pincodeError}</div>
            )}
            </Grid>
           
            {/* <Grid item xs={11} md={3}>
              <Field 
                select
                name={name}
                label={t("Farmer_Association_Form_PostOffice_Label")}
                component={TextField}
                margin="dense"
                fullWidth={true}
                variant="outlined"
                value={selectedPostOfficeValue}
                inputProps={{
                  onChange: e => {
                    setSelectedPostOfficeValue(e.target.value);
                    handlePostOfficeChange(
                        e.target.value,
                        values
                      );
                    }
                  }}
                >
                <MenuItem disabled value="none">
                  {"Choose"}
                </MenuItem>
                {postOfficeData.map(value => (
                  <MenuItem key={value.name} value={value.name}>
                    {value.name}
                  </MenuItem>
                ))}
              </Field>
            </Grid> */}

            <Grid item xs={11} md={3}>
               <Field
                type={"text"}
                name={name}
                label={t("Farmer_Association_Form_state_Label")}
                component={TextField}
                placeholder={"State"}
                value={stateByPincode}
                disabled
                {...metaProps}
            />
            </Grid>
            <Grid item xs={11} md={3}>
               <Field
                type={"text"}
                name={name}
                label={t("Farmer_Association_Form_district_Label")}
                component={TextField}
                placeholder={"District"}
                value={districtByPincode}
                disabled
                {...metaProps}
            />
            </Grid>
            <Grid item xs={11} md={3}>
               <Field
                type={"text"}
                name={name}
                label={t("Farmer_Association_Form_taluk_Label")}
                component={TextField}
                placeholder={"Taluk"}
                value={talukByPinCode}
                disabled
                {...metaProps}
            />
            </Grid>
  
                  
         </Grid>
         <Grid item xs={11} md={3}>
          <Grid item container alignItems="center" xs={2} md={1}>
            <button
              type="button"
              onClick={() => handlePincodeData()}
              className="add-icon"
              color="primary"
              variant="contained"
              size="medium"
            >
              <i className="fa fa-plus-circle" aria-hidden="true"></i>
            </button>
          </Grid>
          <Grid item style={{ marginTop: "8px", marginLeft: "-10px" }}>
            {warehouseChip ?(
              <WarehousePincodeChip
                values = {values}
                setFieldValue={setFieldValue}
              />             
            ):(
              <PincodeChip
              values={values}
              setFieldValue={setFieldValue}
            />
            )}
          </Grid>
          </Grid>
        </Grid>
        );
      // }}
      // </FieldArray>
        
   // );
  }
