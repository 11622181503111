import React from "react";
import { Field, getIn } from "formik";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import { TextField } from "formik-material-ui";
import metaDataServices from "services/metadataServices";
import { useTranslation } from "react-i18next";

export default function CountryStateDistrictSelector({
  // field,
  edit,
  values,
  stateField,
  districtField,
  countryField,
  handleChange,
  metaProps = {
    margin: "dense",
    variant: "outlined",
    fullWidth: true
  }
}) {
  const [countryNames, setCountryNames] = React.useState([]);
  const [countryLoading, setCountryLoading] = React.useState(false);
  const [stateNames, setStateNames] = React.useState([]);
  const [districtNames, setDistrictNames] = React.useState([]);
  const { t } = useTranslation();
  React.useEffect(() => {
    (async () => {
      if (countryNames.length === 0 && !countryLoading) {
        setCountryLoading(true);
        let data = await metaDataServices("countryNames");
        setCountryNames(data);
        setCountryLoading(false);
      }
      if (edit) {
        handleEditFetch();
      }
    })();
  }, [getIn(values, countryField)]);

  async function handleEditFetch() {
    !!getIn(values, countryField) &&
      (await handleCountrySelect(getIn(values, countryField)));
    // handleChange(stateField, getIn(values, stateField));
    !!getIn(values, stateField) &&
      (await handleStateSelect(getIn(values, stateField)));
  }

  async function handleCountrySelect(country) {
    try {
      // const data = await metadataService.getStateNames(country);
      const data = await metaDataServices("stateNames", {
        country
      });
      const states = data.map(state => state.typeDisplayName);
      setStateNames(states);
      // handleChange(districtField, "");
    } catch (e) {
      console.log("error occurred while getting state names", e.message);
    }
  }

  async function handleStateSelect(state) {
    try {
      // const data = await metadataService.getDistrictNames(state);
      const data = await metaDataServices("districtNames", {
        state
      });

      const districts = await data.map(district => district.typeDisplayName);
      setDistrictNames(districts);
    } catch (e) {
      console.log(e.message);
    }
  }

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12} md={3}>
        <Field
          name={countryField}
          label={t("selector_Form_Country_Label")}
          component={TextField}
          select
          // value={getIn(values, countryField)}
          // error={countryError}
          inputProps={{
            onChange: async e => {
              let { value } = e.target;
              handleChange(countryField, value);
              await handleCountrySelect(value);
            }
            // value: getIn(values, countryField)
          }}
          {...metaProps}
        >
          <MenuItem disabled value="none">
            {t("selector_Form_Select_Country_Label")}
          </MenuItem>
          {countryNames.map(country => (
            <MenuItem key={country.typeName} value={country.typeName}>
              {country.typeDisplayName}
            </MenuItem>
          ))}
        </Field>
      </Grid>

      <Grid item xs={12} md={3}>
        <Field
          name={stateField}
          label={t("selector_Form_State_Label")}
          component={TextField}
          select
          inputProps={{
            onChange: async e => {
              let { value } = e.target;
              handleChange(stateField, value);
              await handleStateSelect(value);
            }
            // value: stateValue
          }}
          {...metaProps}
        >
          <MenuItem disabled value="none">
            {!getIn(values, countryField)
              ? t("selector_Form_Select_Country_First_Label")
              : t("selector_Form_Select_State_Label")}
          </MenuItem>
          {stateNames.length > 0 &&
            stateNames.map(state => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
        </Field>
      </Grid>
      <Grid item xs={12} md={3}>
        <Field
          name={districtField}
          label={t("selector_Form_District_Label")}
          component={TextField}
          select
          {...metaProps}
        >
          <MenuItem disabled value="none">
            {!getIn(values, stateField)
              ? t("selector_Form_Select_State_First_Label")
              : t("selector_Form_Select_District_Label")}
          </MenuItem>
          {districtNames.length > 0 &&
            districtNames.map(district => (
              <MenuItem key={district} value={district}>
                {district}
              </MenuItem>
            ))}
        </Field>
      </Grid>
    </Grid>
  );
}
