import React, { useEffect, useContext,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { authContext } from "context/authContext";
import ProductForm from "./ProductForm";
import { notificationService } from "services/notificationServices";
import { errorContext } from "context/errorContext";
import addDays from "date-fns/addDays";
import { productService } from "services/productServices";
import { Grid, Paper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import FailureFeedback from "components/common/elements/FailureFeedback";
import { useTranslation } from "react-i18next";

export default function EditProduct() {
  const org = JSON.parse(localStorage.getItem("currentUser")).organizationName;
  const { productId } = useParams();
  
  const createError = useSelector(state => state.site.toJS().createError);
  
  const dispatch = useDispatch();
  const { authState } = useContext(authContext);

  const [productData, setProductData] = useState({});
  const [status, setStatus] = useState("loading");
  const [editStatus , setEditStatus] = useState("loading");

  const [language, setLanguage] = React.useState([]);
  const [languageListStatus, setLanguageListStatus] = useState("loading");
  const [validDateRangeAlertMessage, setValidDateRangeAlertMessage] = useState(false);
  const [pbpData,setPbpData] = React.useState([]);
  
  const { dispatch: errorDispatch } = useContext(errorContext);
  const { t } = useTranslation();

  const handleValidDateAlertClose = () => {
    setValidDateRangeAlertMessage(false);
  }

  function getProductDetails(){
    productService.getproductById(productId).then(data => {      
      let languageList = [];
      let languageObj = data.languages;
      for(let key in languageObj){
        if(languageObj.hasOwnProperty(key)){
          let languageReplicaObj = {};
          let lngObj = {};
          let value = languageObj[key];
          console.log(key,value);
          if(key == "en"){
            languageReplicaObj.language = "ENGLISH";
          } else if (key == "kn"){
            languageReplicaObj.language = "KANNADA";
          } else if( key == "hi"){
            languageReplicaObj.language = "HINDI";
          }

          Object.assign(lngObj , languageReplicaObj, value);
          // lngObj.batch = data.batch;
          // lngObj.howToUse = data.howToUse;
          
          // if(data.category != null && data.category.categoryName!= null){
          //   lngObj.Category = data.category.categoryName;
          // }
         // lngObj.product = data.productName ;

          console.log("languageObj" , lngObj);
          languageList.push(lngObj);
        }
      }

      if(languageList.length === 0){
        languageList = [{
          language:"",
          product: "",
          benefits:"",       
          Category:"",
          howToUse:"",
          description:""        
        }];
      }

      data.languageList = languageList;

      setProductData(data);
      setLanguageListStatus("success");

    })
    .catch(error => {
      console.error(error);
        errorDispatch({
        type: "ERROR",
        error
      });
      setStatus("failed");
    });
  }

  // fetch notification details and initial data.
  useEffect(() => {
    productService.getPbpList().then(data =>{
      if(data){
        setPbpData(data);
      }
    });

    notificationService.getNotificationUIOptions('LANGUAGE').then(optionData => {
      if (optionData) {
        setLanguage(optionData);
        getProductDetails();
      }
    });   
  }, []);

  function handleSubmit(values, resetForm,setSubmitting) {
    console.log("Edit product");
    setStatus("success");
    setEditStatus("success");
    values.language = {};
    let lang = {};
    for(var i=0;i<values.languageList.length;i++){
      
      let languageVal;
      if(values.languageList[i].language === "ENGLISH"){
        languageVal = "en";
      } else if (values.languageList[i].language === "KANNADA"){
        languageVal = "kn";
      } else if( values.languageList[i].language === "HINDI"){
        languageVal = "hi";
      }

      lang[languageVal] =  values.languageList[i];

      console.log(lang);

      delete values.languageList[i]["language"];
    }

    values.language = lang;

    var createdDate = new Date(values.createdDate);
  
    var expiryDate = new Date(values.expiryDate);

    let diffInMs = Math.floor(expiryDate - createdDate);
    let days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    console.log(days);

    if(days < 0){
      setSubmitting(false);
      setValidDateRangeAlertMessage(true);
      return;
    }

    delete values["categoryName"];
    delete values["categoryId"];

    // if (values.publishChannel) {
    //   let publishC = values.publishChannel;
    //   values.publishChannel = [];
    //   values.publishChannel.push(publishC);
    // }
    
    
    // let languageList = [];
    //   let languageObj = data.languages;
    //   for(let key in values.languageList){
    //     if(languageObj.hasOwnProperty(key)){
    //       let languageReplicaObj = {};
    //       let lngObj = {};
    //       let value = languageObj[key];
    //       console.log(key,value);
    //       if(key == "en"){
    //         languageReplicaObj.language = "ENGLISH";
    //       } else if (key == "kn"){
    //         languageReplicaObj.language = "KANNADA";
    //       } else if( key == "hi"){
    //         languageReplicaObj.language = "HINDI";
    //       }

    //       Object.assign(lngObj , languageReplicaObj, value);
    //       // lngObj.batch = data.batch;
    //       // lngObj.howToUse = data.howToUse;
          
    //       // if(data.category != null && data.category.categoryName!= null){
    //       //   lngObj.Category = data.category.categoryName;
    //       // }
    //      // lngObj.product = data.productName ;

    //       console.log("languageObj" , lngObj);
    //       languageList.push(lngObj);
    //     }

    

    // console.log("edit Values",values);

    productService.editProduct(productId,values).then(data => {
      console.log(data);
     // setNotificationData(data);
     // setStatus("success");
      setEditStatus("success");
    })
    .catch(error => {
      console.error(error);
        errorDispatch({
        type: "ERROR",
        error
      });
      setStatus("failed");
    });
  }

  const [otherVendors, setOtherVendors] = React.useState(false);
  const [edit,setEdit] = React.useState(true);

  const handleVendorSelect = value => {
    if (value === "Others") {
      setOtherVendors(true);
    } else {
      setOtherVendors(false);
    }
  };

  let values = {
    amount: !!productData ? productData.amount : "",
    bar_code: !!productData ? productData.barCode : "",
    batch: !!productData ? productData.batch : "",
    benefits: !!productData ? productData.benefits : "",
    brand: !!productData ? productData.brand : "",
    bulkBuyAllowed:!!productData ? productData.bulkBuyAllowed : "",
    buyingPrice:!!productData ? productData.buyingPrice : "",
    categoryTree:!!productData ? productData.categoryTree : "",
    createdDate:!!productData ? productData.createdDate : "",
    description:!!productData ? productData.description : "",
    expiryDate:!!productData ? productData.expiryDate :"",
    gstPercentage:!!productData ? productData.gstPercentage : "",
    howToUse:!!productData ? productData.howToUse : "",
    hsn: !!productData ? productData.hsn :"",
    imageUrl:!!productData ? productData.imageURL :"" ,
    initialSize:!!productData ? productData.initialSize :"",
    isActive:!!productData ? productData.isActive :"",
    isActiveEligible:!!productData ? productData.isProductEligible :"",
    language:!!language ? language[0] :"",
    licenses:!!productData ? productData.licenses :"",
    manufacturingDate:!!productData ? productData.manufacturingDate :"",
    maxBulkQty:!!productData ? productData.maxBulkQtyAllowed : "",
    maxQtyAllowed:!!productData ? productData.maxQtyAllowed :"",
    method:!!productData ? productData.method :"",
    minBulkQty:!!productData ? productData.minBulkQtyAllowed :"",
    minQtyAllowed:!!productData ? productData.minQtyAllowed :"",
    priority:!!productData ? productData.priority :"" ,
    productCode:!!productData ? productData.productCode : "",
    productGroup:!!productData ? productData.productGroup : "",
    productName:!!productData ? productData.productName : "",
    productType:!!productData ? productData.productType : "",
    productUuid:!!productData ? productData.productUUID : "",
    quantity:!!productData ? productData.quantity : "",
    rating:!!productData ? productData.rating : "",
    searchTag:!!productData.category ? productData.category.searchTag : "",
    sellingPrice:!!productData.price ? productData.price.sellingPrice : "",
    sku:!!productData ? productData.sku :"",
    status:!!productData ? productData.status :"",
    stepCount:!!productData ? productData.stepCount :"",
    uom:!!productData ? productData.uom :"",
    variant:!!productData ? productData.variants:"",
    categoryId:!!productData.category ? productData.category.id:"",
    languageList:!!productData ? productData.languageList:"",
    language:!!productData ? productData.language:"",
    categoryName:!!productData.category ? productData.category.categoryName:""    
    // pbpId:!!productData ? productData,
    // provider:!!productData ? productData,
   // fetchTag:!!productData.category ? productData.category.fetchTag:""
    
  };

  return (
    <>
    <Grid container>
    { languageListStatus === "loading" ? (
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ height: "50vh" }}
      >
        <CircularProgress />
      </Grid>
    ):(
    <ProductForm
      values={values}
      language={language}
      pbpData = {pbpData}
      editStatus={editStatus}
      edit={edit}
      handleSubmit={handleSubmit}
    />
    )}
    </Grid>

    <FailureFeedback
      open={validDateRangeAlertMessage}
      onClose={handleValidDateAlertClose}
      status={"Failed"}
      message={t("product_list_Valid_Date_Range_Message")}
      showButtons={true} />

    </>
  );
}
