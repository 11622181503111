import React, { useState } from "react";

import { Field, getIn } from "formik";
import { TextField } from "formik-material-ui";

import { provisioningService } from "services/provisioningServices";
import { CustomizedPrimaryButton } from "../elements/CustomizedPrimaryButton";
import Popups from "../popups/Popups";
import { useTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";

export default function UserPasswordChangeField({
  values,
  userField,
  passwordField,
  confirmPasswordField,
  setFieldValue,
  metaProps = { margin: "dense", variant: "outlined", fullWidth: true }
}) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(false);
  const { t } = useTranslation();
  const handlePasswordChange = async () => {
    if (getIn(values, passwordField) === getIn(values, confirmPasswordField)) {
      const result = await provisioningService.resetUserPassword(
        getIn(values, userField),
        getIn(values, passwordField)
      );
      setFieldValue(passwordField, "", false);
      setFieldValue(confirmPasswordField, "", false);
      setStatus(result.status);
      setOpen(true);
      setMessage(result.message);
    }
  };
  return (
    <>
      <Grid item container spacing={2}>
        <Grid item xs={12} md={3}>
          <Field
            type={"password"}
            name={passwordField}
            label={t("user_Form_Edit_New_Password_Label")}
            component={TextField}
            placeholder="New Password"
            // value={password}
            // error={passwordError}
            {...metaProps}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Field
            type={"password"}
            name={confirmPasswordField}
            label={t("user_Form_Edit_Confirm_Password_Label")}
            component={TextField}
            placeholder="Confirm Password"
            // value={confirmPassword}
            // error={confirmPasswordError}
            {...metaProps}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomizedPrimaryButton
            label={t("user_Form_Edit_Reset_Password_Label")}
            type="button"
            onClick={handlePasswordChange}
            className="mt-2"
          />
        </Grid>
      </Grid>
      {open && (
        <Popups
          open={open}
          setOpen={setOpen}
          message={message}
          status={status}
        />
      )}
    </>
  );
}
