import React, { useState, useEffect, useRef } from "react";
import PageHeader from "../../components/layout/PageHeader";
import { withTranslation } from "react-i18next";
import format from "date-fns/format";
import SockJS from "sockjs-client";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";
import ChartWrapper from "page-containers/Dashboard/components/ChartWrapper";
import ReactEcharts from "echarts-for-react";
export default function RealTimeChart() {
  var now = +new Date(1997, 9, 3);
  var oneDay = 24 * 3600 * 1000;
  var value = Math.random() * 1000;
  const [status, setStatus] = React.useState("loading");
  const [data, setData] = useState([]);
  useEffect(() => {
    const url = `/pushNotification/notification`;
    // // setStatus("loading");
    const eventSource = new EventSource(url);
    eventSource.onopen = () => {
      // console.log("i am open");
    };
    eventSource.onmessage = async message => {
      var dataStr = JSON.parse(message.data);
      // console.log("name ",dataStr.name)
      // console.log("value ",dataStr.value)
      setData(data => [...data, dataStr]);
      setStatus("success");
    };
    eventSource.addEventListener("error", e => {
      console.error("error", e);
    });
  }, []);
  useEffect(() => {
    // let sock = new SockJS(
    //   "http://localhost/chillingUnitService/pushNotification/gs-guide-websocket"
    // );
    // sock.onopen = function() {
    //   console.log("open");
    //   sock.send("test");
    // };
    // sock.onmessage = function(e) {
    //   console.log("message", e.data);
    //   sock.close();
    // };
  }, []);

  const optionData = () => ({
    title: {
      text: "Real Time Chart"
    },
    xAxis: {
      type: "time",
      splitLine: {
        show: false
      },
      name: "Time ----------> ",
      nameLocation: "center",
      nameGap: 30,
      axisLabel: {
        show: true
      }
    },
    yAxis: {
      type: "value",
      boundaryGap: [0, "100%"],
      splitLine: {
        show: false
      },
      name: "Volume In Ltrs ----------> ",
      nameLocation: "center",
      nameGap: 40
    },
    series: [
      {
        name: "Real Time Volume Got",
        type: "line",
        showSymbol: true,
        hoverAnimation: false,
        data: data
      }
    ]
  });
  return (
    <Paper style={{ height: "100%" }} elevation={2}>
      <Grid item xs={12} style={{ borderTop: "1px solid lightgrey" }}>
        <ReactEcharts option={optionData()}></ReactEcharts>
      </Grid>
    </Paper>
  );
}