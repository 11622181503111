import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { getIn } from "formik";
import PageHeader from "components/layout/PageHeader";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
import CommonForm from "components/common/form/common-form";
import FailureFeedback from "components/common/elements/FailureFeedback";
import NotificationExtraData from "components/common/form/NotificationExtraData";
import { notificationService } from "services/notificationServices";
import NotificationScheduler from "components/common/form/NotificationScheduler";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import "components/responsive.scss";
import "../configuration.scss";
import "./Notification.scss";
import "../../../components/responsive.scss";
import CountryStateDistrictSelector from "components/common/form/CountryStateDistrictSelector";

import { useTranslation } from "react-i18next";
import { string } from "prop-types";
import addDays from "date-fns/addDays";

export default function NotificationForm({
  values,
  siteUUID = false,
  language,
  mimeType,
  actionUrl,
  previewUrl,
  publishChannel,
  topicName,
  productCode,
  targetUsers,
  appAction,
  allDropDownData,
  handleSubmit,
  status,
  editStatus,
  createError,
  edit,
  otherVendors,
  handleVendorSelect
}) {
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [locale, setLocale] = useState([]);
  const [timeZone, setTimeZone] = useState([]);
  const [validFrom] = React.useState(addDays(new Date(), -2));
  const [validUpTo] = React.useState(new Date());
  const [publishChannelStatus, setPublishChannelStatus] = React.useState(true);
  const [appActionReplica, setAppActionReplica] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState();
  const [selectedAppActionKey, setSelectedAppAction] = React.useState();
  // const [deviceAttributeValues, setDeviceAttributeValues] = useState([]);

  const { t } = useTranslation();

  let breadcrumbs = [
    {
      label: t("notification_Form_Breadcrum_Dashboard_Label"),
      link: "/dashboard"
    },
    { label: t("notification_Form_Breadcrum_Configuration_Label") },
    { label: t("notification_Form_Breadcrum_Notification_Label") }
  ];

  const deliveryTypeData = [
    { value:"NO_REPEAT", label:"Once"},
    { value:"CUSTOM",label:"Periodic"}];
  const optionForOnce = ["Manual Push","Schedule"];
  const occurrences = ["Custom"];
  const [displayOnceOptions, setDisplayOnceOptions] = useState(false);
  const [displayPeriodicOptions,setDisplayPeriodicOptions] = useState(false);
  const [displayScheduleOptions,setDisplayScheduleOptions] = useState(false);
  const [customOccurance,setCustomOccurance] = useState(false);

  const changeDeliveryType = (e) => {
    console.log("on change");
    console.log(e.target.value);
    values[0].type = "";
    if(e.target.value === "NO_REPEAT"){
      values[0].type = deliveryTypeData[0].value;
      setDisplayOnceOptions(true);
      setDisplayPeriodicOptions(false);
    }else if(e.target.value === "CUSTOM"){
      values[0].type = deliveryTypeData[1].value;
      setDisplayPeriodicOptions(true);
      setDisplayOnceOptions(false);
      setDisplayScheduleOptions(false);
    }
  }


  useEffect(() => {
    if (values.publishChannel.includes("TOPIC_NOTIFICATION")) {
      setPublishChannelStatus(false);
    }
    setAppActionReplica(appAction);
  }, [publishChannel]);

  const handlePublishChannelStatus = value => {
    if (value.includes("TOPIC_NOTIFICATION")) {
      setPublishChannelStatus(false);
    } else {
      setPublishChannelStatus(true);
    }
  };

  let selectedProdCode;
  const handleAppAction = value => {
    selectedProdCode = "APP_ACTION_" + value;
    setSelectedProduct(value);

    if (allDropDownData[selectedProdCode]) {
      setAppActionReplica(allDropDownData[selectedProdCode]);
    }
  };

  const getExtraDataKey = value => {
    console.log("selectedProduct" + selectedProduct);
    let selectedAppAction =
      "EXTRA_DATA_APP_ACTION_" + selectedProduct + "_" + value;

    values.extraDataList[0].subKey = "";
    if (allDropDownData[selectedAppAction]) {
      values.extraDataList[0].subKey = allDropDownData[selectedAppAction][0];
    }
  };

  const org = JSON.parse(localStorage.getItem("currentUser")).organizationName;

  const formikForm = React.useRef(null);

  const fields = [
    {
      type: "select",
      name: "language",
      label: t("notification_Form_language_Label"),
      placeholder: t("notification_Form_language_Label"),
      option:
        language.length > 0
          ? language.map(language => ({
              value: language,
              label: language
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "select",
      name: "mimeType",
      label: t("notification_Form_MimeType_Label"),
      placeholder: t("notification_Form_MimeType_Label"),
      option:
        mimeType.length > 0
          ? mimeType.map(mimeType => ({
              value: mimeType,
              label: mimeType
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "url",
      name: "previewUrl",
      label: t("notification_Form_PreviewUrl_Label"),
      placeholder: t("notification_Form_PreviewUrl_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "actionUrl",
      label: t("notification_Form_ActionUrl_Label"),
      placeholder: t("notification_Form_ActionUrl_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "select",
      name: "productCode",
      label: t("notification_Form_productCode_Label"),
      placeholder: t("notification_Form_productCode_Label"),
      option:
        productCode.length > 0
          ? productCode.map(productCode => ({
              value: productCode,
              label: productCode
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      },
      customOnChange: handleAppAction
    },
    {
      type: "select",
      name: "appAction",
      label: t("notification_Form_appAction_Label"),
      placeholder: t("notification_Form_appAction_Label"),
      option:
        appActionReplica.length > 0
          ? appActionReplica.map(appActionReplica => ({
              value: appActionReplica,
              label: appActionReplica
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      },
      customOnChange: getExtraDataKey
    },
    {
      type: "multiSelectAll",
      name: "publishChannel",
      label: t("notification_Form_publishChannel_Label"),
      placeholder: t("notification_Form_publishChannel_Label"),
      option:
        publishChannel.length > 0
          ? publishChannel.map(publishChannel => ({
              value: publishChannel,
              label: publishChannel
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "multi-select"
      },
      customOnChange: handlePublishChannelStatus
    },
    {
      type: "select",
      name: "topicName",
      label: t("notification_Form_topicName_Label"),
      placeholder: t("notification_Form_topicName_Label"),
      option:
        topicName.length > 0
          ? topicName.map(topicName => ({
              value: topicName,
              label: topicName
            }))
          : [],
      disabled: publishChannelStatus,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "targetUsers",
      label: t("notification_Form_targetUsers_Label"),
      placeholder: t("notification_Form_targetUsers_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "datePicker",
      name: "validFrom",
      label: t("notification_Form_validFrom_Label"),
      placeholder: t("notification_Form_validFrom_Label"),
      value: { validFrom },
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "datePicker",
      name: "validUpTo",
      value: { validUpTo },
      label: t("notification_Form_validUpTo_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "textarea",
      name: "title",
      label: t("notification_Form_Title_Label"),
      placeholder: t("notification_Form_Title_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "textarea",
      name: "content",
      label: t("notification_Form_Content_Label"),
      placeholder: t("notification_Form_Content_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "custom",
      component: ({ values, errors }, renderFields) => (
        <NotificationExtraData
          name="extraDataList"
          values={values}
          errors={errors}
          renderFields={renderFields}
          elemProp="Extra Data"
        />
      )
    },
    edit === false ? (
    {
      type: "custom",
      component: ({ setFieldValue, values }, renderFields) => (
        <>
          <span>
            <h3>{t("notification_Form_scheduler_Label")}</h3>
          </span>
          <NotificationScheduler
            name={"scheduler"}
            setFieldValue={setFieldValue}
            values={values["scheduler"]}
          />
        </>
      ),
      grid: {
        xs: 12,
        className: "title-on-border-box bg-blue-l"
      }
    }
    ):null
  ];

  // check if duplicate at site level
  // check if duplicate in the chilling unit
  // check if duplicate at different chilling unit

  let validations = {
    title: Yup.string().required(t("notification_Form_Validation_Title")),
    // content: Yup.string().required(t("notification_Form_Validation_Content")),
    language: Yup.string()
      .required(t("notification_Form_Validation_Language"))
      .typeError(t("site_Form_Validation_Sub_Org_Name_Type_Error")),
    mimeType: Yup.string().required(t("notification_Form_Validation_MimeType")),
    // previewUrl: Yup.string()
    //   .required(t("notification_Form_Mandatory_PreviewUrl"))
    //   .matches( mimeTypeValue === "IMG",
    //     t("notification_Form_Validation_PreviewUrl")
    //   ),
    // actionUrl: Yup.string()
    //   .required(t("notification_Form_Mandatory_ActionUrl"))
    //   .matches(
    //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%_]+&?)?$/,
    //     t("notification_Form_Validation_ActionUrl")
    //   ),
    appAction: Yup.string().required(
      t("notification_Form_Validation_AppAction")
    ),
    publishChannel: Yup.string().required(
      t("notification_Form_Validation_PublishChannel")
    ),
    productCode: Yup.string().required(
      t("notification_Form_Validation_ProductCode")
    ),
    targetUsers: Yup.string().required(
      t("notification_Form_Validation_TargetUsers")
    ),
    validFrom: Yup.string().required(
      t("notification_Form_Validation_ValidFrom")
    ),
    validUpTo: Yup.string().required(
      t("notification_Form_Validation_ValidUpTo")
    ),
    medaDataList: Yup.array().of(
      Yup.object().shape({
        subKey: Yup.string().required(t("loanInsurance_Form_Key_Label")),
        subValue: Yup.string().required(t("loanInsurance_Form_Value_Label"))
      })
    )
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const handleDialogClose = () => {
    console.log("edit" + edit);
    if (editStatus === "success" || status === "success") {
      history.push("/configuration/notification");
      dispatch({
        type: "SITE_CONFIGURATION_RESET_STATUS"
      });
    }
    dispatch({
      type: "SITE_CONFIGURATION_STATUS_UPDATE",
      status: "idle"
    });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("notification_Form_Header_Label")}
          breadcrumbs={breadcrumbs}
        />
      </Grid>
      <Grid container>
        <Paper elevation={2} style={{ padding: "2em" }}>
          <Grid item>
            {/* <h2>{t("notification_Form_Label")}</h2> */}
          </Grid>
          {/* <Grid container className="d-block d-md-flex ">
            <Grid item width={12} className="site-id-label">
              <label>
                {t("notification_Form_Title_Org_Label")} : <b>{org}</b>
              </label>
            </Grid>
          </Grid> */}
          <Grid item>
            <CommonForm
              fields={fields}
              submitLabel={t("site_Form_Submit_Label")}
              submittingLabel={t("site_Form_Submitting_Label")}
              initialValues={values}
              validationSchema={Yup.object().shape({ ...validations })}
              validateOnBlur={true}
              edit={edit}
              enableReinitialize
              validateOnChange={true}
              onSubmit={(
                values,
                { setSubmitting, setFieldError, resetForm, errors }
              ) => {
                if (!!errors) {
                  setSubmitting(false);
                }
                handleSubmit(values, resetForm, setSubmitting);
              }}
              formikRef={formikForm}
              buttonSize="3"
              buttonPosition="right"
            />
          </Grid>
        </Paper>
      </Grid>
      <SuccessFeedback
        open={status === "success"}
        onClose={handleDialogClose}
        successMessage={t("notification_Form_Success_Message")}
        createAnotherMesssage={t("site_Form_Create_Another_Site")}
      />
      <SuccessFeedback
        open={editStatus === "success"}
        onClose={handleDialogClose}
        successMessage={t("notification_Form_Edit_Success_Message")}
        createAnotherMesssage={t("site_Form_Create_Another_Site")}
      />
      <FailureFeedback
        open={status === "failed"}
        onClose={handleDialogClose}
        status={!!createError ? createError.status : ""}
        message={
          !!createError
            ? !!createError.error.message
              ? createError.error.message
              : ""
            : ""
        }
      />
    </Grid>
  );
}
