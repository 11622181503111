import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { authContext } from "context/authContext";
import SiteForm from "./SiteForm";

export default function EditSite() {
  const org = JSON.parse(localStorage.getItem("currentUser")).organizationName;
  const { siteId } = useParams();
  const siteData = useSelector(state => state.site.toJS().editSiteValues);
  const createError = useSelector(state => state.site.toJS().createError);
  const status = useSelector(state => state.site.toJS().status);
  const subOrganizations = useSelector(
    state => state.site.toJS().subOrganizations
  );
  const dispatch = useDispatch();
  const { authState } = useContext(authContext);
  // fetch site details and initial data.
  useEffect(() => {
    dispatch({
      type: "GET_EDIT_SITE_DETAILS_BY_ID",
      siteId
    });
    dispatch({
      type: "SITE_CONFIG_SUB_ORG_FETCH",
      org
    });
  }, []);
  function handleSubmit(values, resetForm) {
    if (values.latitude && values.latitude !== "") {
      const data = values.latitude.split(",");
      values.longitude = data[1].trim();
      values.latitude = data[0];
    }
    dispatch({
      type: "SITE_CONFIGURATION_EDIT_SITE",
      payload: { values, resetForm, siteId }
    });
  }

  const [otherVendors, setOtherVendors] = React.useState(false);

  const handleVendorSelect = value => {
    if (value === "Others") {
      setOtherVendors(true);
    } else {
      setOtherVendors(false);
    }
  };
  const values = {
    organizationName: !!siteData ? siteData.organizationName : org,
    subOrganizationName: !!siteData ? siteData.subOrganizationName : "",
    siteId: !!siteData ? siteData.siteId : "",
    siteName: !!siteData ? siteData.siteName : "",
    siteUuid: !!siteData ? siteData.siteUUID : "",
    gatewaySerialNumber: "",
    sensorSerialNumber: "",
    devices: !!siteData
      ? siteData.siteLevelDevices
        ? siteData.siteLevelDevices.map(device => ({
            deviceType: device.deviceType,
            monitoringProductType: device.monitoringProductType,
            serialNumber: device.serialNumber
          }))
        : []
      : [],
    chillingUnitMonitoringDetailsList:
      !!siteData &&
      !!siteData.chillingUnitMonitoringDetailsList &&
      !!siteData.chillingUnitMonitoringDetailsList
        ? siteData.chillingUnitMonitoringDetailsList.map(
            (unit, chillingUnitIndex) => {
              return {
                l1DeviceInfo: unit.l1DeviceInfo
                  ? unit.l1DeviceInfo.map((l1device, l1Index) => {
                      return {
                        serialNumber: unit.l1DeviceInfo[l1Index].serialNumber,
                        gatewaySerialNumber:
                          unit.l1DeviceInfo[l1Index].gatewaySerialNumber,
                        deviceType: unit.l1DeviceInfo[l1Index].deviceType,
                        l0Config: unit.l1DeviceInfo[l1Index].l0Config
                          ? unit.l1DeviceInfo[l1Index].l0Config.map(
                              (l1device, l0Index) => {
                                return {
                                  deviceAttribute:
                                    unit.l1DeviceInfo[l1Index].l0Config[l0Index]
                                      .deviceAttribute,
                                  applicationAttribute:
                                    unit.l1DeviceInfo[l1Index].l0Config[l0Index]
                                      .applicationAttribute,
                                  unit:
                                    unit.l1DeviceInfo[l1Index].l0Config[l0Index]
                                      .unit
                                };
                              }
                            )
                          : [
                              {
                                deviceAttribute: "",
                                applicationAttribute: "",
                                unit: ""
                              }
                            ]
                      };
                    })
                  : [
                      {
                        serialNumber: "",
                        gatewaySerialNumber: "",
                        deviceType: "",
                        l0Config: [
                          {
                            deviceAttribute: "",
                            applicationAttribute: "",
                            unit: ""
                          }
                        ]
                      }
                    ],
                productType: {
                  name: unit.productType.name
                },
                chillingUnitId: unit.chillingUnitId,
                chillingUnitUuid: unit.chillingUnitUUID,
                chillingUnit:
                  unit.productType.name === "ABC"
                    ? {
                        societyLongId: unit.chillingUnit.societyLongId,
                        societyId: unit.chillingUnit.societyId,
                        collectionCenterType:
                          unit.chillingUnit.collectionCenterType,
                        chillingCenterName:
                          unit.chillingUnit.chillingCenterName,
                        chillingCenterId: unit.chillingUnit.chillingCenterId,
                        capacity: unit.chillingUnit.capacity,
                        cutOffTemperature: unit.chillingUnit.cutOffTemperature,
                        cutInTemperature: unit.chillingUnit.cutInTemperature,
                        chillingCapacity: unit.chillingUnit.chillingCapacity,
                        ownerName: unit.chillingUnit.ownerName,
                        countryCode: unit.chillingUnit.countryCode,
                        ownerContactNum: unit.chillingUnit.ownerContactNum,
                        vendor: unit.chillingUnit.vendor,
                        manufacturedYear: unit.chillingUnit.manufacturedYear,
                        methodOfCooling: unit.chillingUnit.methodOfCooling
                      }
                    : {
                        bmcId: unit.chillingUnit.bmcId,
                        bmcName: unit.chillingUnit.bmcName,
                        capacity: unit.chillingUnit.capacity,
                        cutOffTemperature: unit.chillingUnit.cutOffTemperature,
                        cutInTemperature: unit.chillingUnit.cutInTemperature,
                        chillingCapacity: unit.chillingUnit.chillingCapacity,
                        customerBmcId: unit.chillingUnit.customerBmcId,
                        ownerName: unit.chillingUnit.ownerName,
                        countryCode: unit.chillingUnit.countryCode,
                        ownerContactNum: unit.chillingUnit.ownerContactNum,
                        vendor: unit.chillingUnit.vendor,
                        manufacturedYear: unit.chillingUnit.manufacturedYear,
                        methodOfCooling: unit.chillingUnit.methodOfCooling
                      }
              };
            }
          )
        : [
            {
              l1DeviceInfo: [
                {
                  serialNumber: "",
                  gatewaySerialNumber: "",
                  deviceType: "",
                  l0Config: [
                    {
                      deviceAttribute: "",
                      applicationAttribute: "",
                      unit: ""
                    }
                  ]
                }
              ]
            }
          ],
    altitude: !!siteData
      ? siteData.coordinates
        ? siteData.coordinates.altitude
          ? siteData.coordinates.altitude
          : "0"
        : "0"
      : "0",
    latitude: !!siteData
      ? siteData.coordinates
        ? siteData.coordinates.longitude && siteData.coordinates.longitude
          ? `${siteData.coordinates.latitude},${siteData.coordinates.longitude}`
          : "0,0"
        : "0,0"
      : "0,0",
    country: !!siteData
      ? !!siteData.address.country
        ? siteData.address.country
        : ""
      : "",
    state: !!siteData
      ? !!siteData.address.state
        ? siteData.address.state
        : ""
      : "",
    district: !!siteData
      ? !!siteData.address.district
        ? siteData.address.district
        : ""
      : "",
    pinCode: !!siteData
      ? siteData.address.pinCode
        ? siteData.address.pinCode
        : ""
      : "",
    villageTownCity: !!siteData
      ? !!siteData.address.villageTownCity
        ? siteData.address.villageTownCity
        : ""
      : "",
    locale: !!siteData && !!siteData.locale ? siteData.locale : "en-IN",
    zoneId: !!siteData && !!siteData.timeZone ? siteData.timeZone : "",
    addressLine1: !!siteData
      ? !!siteData.address.addressLine1
        ? siteData.address.addressLine1
        : ""
      : ""
  };
  return (
    <SiteForm
      values={values}
      siteUUID={!!siteData ? siteData.siteUUID : false}
      subOrganizations={subOrganizations}
      handleSubmit={handleSubmit}
      status={status}
      createError={createError}
      edit={true}
      otherVendors={otherVendors}
      handleVendorSelect={handleVendorSelect}
    />
  );
}
