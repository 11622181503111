import React, { useEffect } from "react";
import { deviceDataService } from "../../../services/deviceDataService";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination
} from "react-table";
import "../../Table.scss";
import ToolTipCell from "./ToolTipCell";
import DynamicPagination from "./DynamicPagination";
import TemplateForTables from "../../layout/innerPagesTemplateRawData";
import { utilityServices } from "../../../services/utilityServices";
import format from "date-fns/format";

function Table({
  columns,
  data,
  paginationInfo,
  showPagination = true,
  extraHeaders,
  fileName = "file.xls",
  deviceType,
  deviceId,
  startDate,
  endDate,
  eventName = ""
}) {
  const { getTableProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }
    },
    useGlobalFilter,
    useSortBy
  );

  const [record, setRecord] = React.useState(null);
  const [csvData, setCsvData] = React.useState(0);
  // let csvData=0;
  useEffect(() => {
    if (paginationInfo.pageIndex === 0) {
      localStorage.setItem("loaded", false);
      deviceDataService
        .getRawData(
          deviceType,
          deviceId,
          new Date(startDate).toISOString(),
          new Date(endDate).toISOString(),
          0,
          100000,
          eventName
        )
        .then(details => {
          if (details.content) {
            // setRecord(details.content[0].attributeList);
            const res = details.content[0].attributeList.reduce((acc, next) => {
              const date = new Date(next.processTime);
              next.processTime = format(date, "dd MMM yyyy - HH:mm:ss");
              return [...acc, next];
            }, []);
            console.log(res)
            localStorage.setItem("rawData", JSON.stringify(res));
            setRecord(JSON.parse(localStorage.getItem("rawData")));
            localStorage.setItem("loaded", true);
          }
        });
    } else if (localStorage.getItem("loaded") === "true") {
      setRecord(JSON.parse(localStorage.getItem("rawData")));
    }
  }, [data]);

  useEffect(() => {
    if (record && record.length > 0) {
      setCsvData(
        utilityServices.getRawDataHeaders(columns, record, extraHeaders)
      );
      // csvData=utilityServices.getRawDataHeaders(columns, record, extraHeaders);
    }
  }, [record]);

  return (
    <>
      <TemplateForTables
        needDropdown={false}
        importIcons
        data={csvData}
        fileName={fileName}
        searchBar={false}
      >
        <div style={{ overflow: "auto" }}>
          <table {...getTableProps()} className="data-table">
            <thead>
              {(headerGroups || []).map((headerGroup, hGroupIndex, idx) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={hGroupIndex + idx.toString()}
                >
                  {headerGroup.headers.map((column, idx) => {
                    if (column.Header === "Actions") {
                      return (
                        <th
                          {...column.getHeaderProps()}
                          key={column.header + idx.toString()}
                          colSpan={2}
                          width={120}
                        >
                          {column.render("Header")}
                        </th>
                      );
                    }
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className="alert-header"
                        key={column.header + idx.toString()}
                        width={
                          headerGroups.length > 1
                            ? hGroupIndex === 0
                              ? headerGroup.headers.length * 120
                              : column.width !== 150
                              ? column.width
                              : 120
                            : column.width !== 150
                            ? column.width
                            : 120
                        }
                      >
                        {column.render("Header")}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {rows.map(
                (row, i) =>
                  prepareRow(row) || (
                    <tr {...row.getRowProps()} key={i}>
                      {row.cells.map((cell, j) => {
                        if (cell.column.Header === "Actions") {
                          return cell.render("Cell");
                        }
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={i + j + 1}
                            width={
                              cell.column.width !== 150
                                ? cell.column.width
                                : 120
                            }
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
        {showPagination && (
          <DynamicPagination
            previousPage={paginationInfo.previousPage}
            nextPage={paginationInfo.nextPage}
            pageIndex={paginationInfo.pageIndex}
            hasNextPage={
              !!paginationInfo.hasNextPage ? paginationInfo.hasNextPage : false
            }
            pageInfo={
              !!paginationInfo.pageInfo ? paginationInfo.pageInfo : null
            }
            pageSize={
              !!paginationInfo.pageSize ? paginationInfo.pageSize : null
            }
            setPageSize={
              !!paginationInfo.setPageSize ? paginationInfo.setPageSize : null
            }
          />
        )}
      </TemplateForTables>
    </>
  );
}

export default Table;
