import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { useSelector } from "react-redux";
import { CustomizedPrimaryButton } from "../../common/elements/CustomizedPrimaryButton";
import { metadataService } from "services/metadataServices";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { categoryService } from "services/categoryServices";
import orgIcon from "../../../assets/icon_org.svg";
import CategoryTree from "./CategoryTree";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from "@material-ui/core/styles";



export default function CategoryTreePopUp({
    categoryIdField,
    categoryNameField,
    categoryTreeField,
    handleChange
  }) {

  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const styles = {
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
    },
  };

  function handleConfigOpen() {
    setOpen(true);
  }

  function handleSaveClick() {
    handleClose();
   // handleSave(index, values);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleCategoryClick(data){
    handleChange(categoryIdField,data.data.id)
    handleChange(categoryNameField,data.data.categoryName)
    handleChange(categoryTreeField,data.data.hierarchyTree)
  }  

  return (
    <>
    <IconButton onClick={handleConfigOpen}>
      <i
        className="fa fa-cog"
        // style={{
        //   color: changeColor ? "green" : "#296AAB",
        //   fontSize: "1.5rem"
        // }}
      ></i>
    </IconButton>

    <Dialog
      fullWidth={true}
      maxWidth="md"
      className={"user-checklist"}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <h2>{t("category_tree_pop_up_title")}</h2>
      </DialogTitle>
      <DialogContent dividers
        style={{height:'80vh'}}>
        <CategoryTree
          handleCategoryClick={handleCategoryClick}
          handleClose={handleClose}
        />
         
      </DialogContent>
      {/* <DialogActions>
        <CustomizedPrimaryButton
          onClick={handleSaveClick}
          variant="contained"
          color="primary"
          label={t("category_tree_pop_up_Save_Label")}
          width="6"
          position="center"
        ></CustomizedPrimaryButton>
      </DialogActions> */}
      </Dialog>

    </>
  );
}
