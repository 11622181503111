import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useEffect, useState, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import "../../../components/Table.scss";
import { provisioningServices } from "../../../services/provisioningServices.js";
import { errorContext } from "context/errorContext";
import Table from "components/common/table/DynamicPaginationTable2";
import PageHeader from "components/layout/PageHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";


const userList = props => {
  const { dispatch: errorDispatch } = useContext(errorContext);

  const [status, setStatus] = useState("loading");
  const [pageInfo, setPageInfo] = useState({});
  const [userData, setUserData] = useState([]);

  let query = useMemo(() => new URLSearchParams(props.location.search), [
    props.location
  ]);

  const { t } = useTranslation();

  const dropitems = [
    {
      item: <Link to="/configuration/user">{t("user_List_Add_User_Label")}</Link>
    }
  ];
  useEffect(() => {
    if (!!query.get("page") && !!query.get("size")) {
      setStatus("loading");
      provisioningServices("get", "users", {
        pageSize: query.get("size"),
        pageIndex: query.get("page")
      })
        .then(data => {
          setUserData(data.response.content || []);
          setPageInfo(data.response.pageInfo);
          setStatus("success");
        })
        .catch(error => {
          console.error(error);
          errorDispatch({
            type: "ERROR",
            error
          });
          setStatus("failed");
        });
    } else {
      props.history.push({
        pathname: props.location.pathname,
        search: `?page=0&size=1000`
      });
    }
  }, [query]);

  const columns = React.useMemo(
    () => [
      {
        Header: t("user_List_Name_Label"),
        accessor: "firstName"
      },
      {
        Header: t("user_List_User_Name_Label"),
        accessor: "userName"
      },
      {
        Header: t("user_List_Org_Label"),
        accessor: "organizationName"
      },
      {
        Header: t("user_List_Email_Label"),
        accessor: "emailId"
      },
      {
        Header: t("user_List_Role_Label"),
        accessor: "roles"
      },
      {
        Header: t("user_List_Actions_Label"),
        Cell: ({ row }) => {
          let link = `/configuration/user/${row.original.userName}`;
          return (
            <>
              <td>
                <Link to={link}>
                  <IconButton>
                    <i className="fa fa-edit" aria-hidden="true"></i>
                  </IconButton>
                </Link>
              </td>
              {/* <td>
                <IconButton>
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </IconButton>
              </td> */}
            </>
          );
        }
      }
    ],
    []
  );

  const data = React.useMemo(() => [...userData]);
  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("user_List_Header_Label")}
          breadcrumbs={[
            { label: t("user_List_Breadcrum_Dashboard_Label"), link: "/dashboard" },
            { label: t("user_List_Breadcrum_Configuration_Label") },
            { label: t("user_List_Breadcrum_User_List_Label") }
          ]}
        />
      </Grid>
      {status === "loading" ? (
        <Grid
          container
          xs={12}
          justify="center"
          alignItems="center"
          style={{ height: "50vh" }}
        >
          <CircularProgress />
        </Grid>
      ) : (
          <Table
            columns={columns}
            data={data}
            needDropdown={true}
            dropItems={dropitems}
            fileName={"userList.xls"}
          />
        )}
    </Grid>
  );
};

export default userList;
