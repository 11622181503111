import React, { useEffect, useContext,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { authContext } from "context/authContext";
import NotificationForm from "./NotificationForm";
import { notificationService } from "services/notificationServices";
import { errorContext } from "context/errorContext";
import addDays from "date-fns/addDays";
import { Grid, Paper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function EditNotification() {
  const org = JSON.parse(localStorage.getItem("currentUser")).organizationName;
  const { notificationId } = useParams();
 
  // const notificationData = useSelector(
  //   state => state.site.toJS().editSiteValues
  // );
  const createError = useSelector(state => state.site.toJS().createError);
  //const status = useSelector(state => state.site.toJS().status);
  const subOrganizations = useSelector(
    state => state.site.toJS().subOrganizations
  );
  const dispatch = useDispatch();
  const { authState } = useContext(authContext);

  const [notificationData, setNotificationData] = useState({});
  const [status, setStatus] = useState("loading");
  const [editStatus , setEditStatus] = useState("loading");

  const [language, setLanguage] = React.useState([]);
  const [appAction, setAppAction] = React.useState([]);
  const [mimeType, setMimeType] = React.useState([]);
  const [productCode, setProductCode] = React.useState([]);
  const [publishChannel, setPublishChannel] = React.useState([]);
  const [topicName,setTopicName] = React.useState([]);
  const [actionUrl,setActionUrl] = React.useState([]);
  const [allDropDownData, setAllDropDownData] = React.useState([]);
  //const [appActionStatus, setAppActionStatus] = React.useState(false);
  const [appActionStatus, setAppActionStatus] = useState("loading");
  
 
  let extraDataList = [];

  const { dispatch: errorDispatch } = useContext(errorContext);

  // fetch notification details and initial data.
  useEffect(() => {
    let dropdownData ;
    notificationService.getNotificationUIOptions('*').then(optionData => {
      if (optionData) {
        dropdownData = optionData;
        setAllDropDownData(optionData);
        setLanguage(optionData.LANGUAGE);
        setAppAction(optionData.APP_ACTION_SMART_FARMS);
        setMimeType(optionData.MIME_TYPE);
        setProductCode(optionData.PRODUCTS);
        setPublishChannel(optionData.PUBLISH_CHANNEL);
        setTopicName(optionData.TOPIC_NAME);
      }
    });

    notificationService.getNotificationbyID(notificationId).then(data => {

      extraDataList = [];
      let extraDataObj = data.actualMessage.extraData;
      for (let key in extraDataObj) {
        if (extraDataObj.hasOwnProperty(key)) {
          let extraDataReplicaObj = {};
            let value = extraDataObj[key];
            console.log(key, value);
            extraDataReplicaObj.subKey = key;
            extraDataReplicaObj.subValue = value;
            console.log(extraDataReplicaObj);
            extraDataList.push(extraDataReplicaObj);
        }
      }

      data.actualMessage.extraDataList = extraDataList;

      setNotificationData(data);
      
      if(data.actualMessage.productCode != ""){
        let selectedProdCode = "APP_ACTION_"+data.actualMessage.productCode;

        if(dropdownData != undefined){
          if(dropdownData[selectedProdCode]){
            setAppAction(dropdownData[selectedProdCode]);
            setAppActionStatus("success");
          }
        }
        setAppActionStatus("success");
      }
    })
    .catch(error => {
      console.error(error);
        errorDispatch({
        type: "ERROR",
        error
      });
      setStatus("failed");
    });
  }, []);

  let days = 0;
  function handleSubmit(values, resetForm, setSubmitting) {

    if (typeof(values.targetUsers) === "string") {
      values.targetUsers = values.targetUsers.split(',');
    }

    // if (values.publishChannel) {
    //   // let publishC = values.publishChannel;
    //   // values.publishChannel = [];
    //   values.publishChannel = values.publishChannel[0];
    // }
    
    for(var i=0;i<values.extraDataList.length;i++){
      values.extraData[values.extraDataList[i].subKey] = values.extraDataList[i].subValue;
    }

    // new Date(new Date().setHours(5,31,0,0)).toISOString(); 

    if(typeof(values.validFrom) != "string"){ 
      var startDateTime = new Date(values.validFrom.setHours(5,31,0,0));
      values.validFrom = startDateTime
        .toISOString()
        .replace("Z", " ")
        .replace("T", " ");
    }

    if(typeof(values.validUpTo) != "string"){
      var endDateTime = new Date(values.validUpTo.setHours(29,29,0,0));
      values.validUpTo = endDateTime
        .toISOString()
        .replace("Z", " ")
        .replace("T", " ");
    }

    let value = {};
    value.id = notificationData.id;
    value.actualMessage = values;

    value.actualMessage.sourceAppName = "UI-PORTAL";

    //delete value.actualMessage["extraDataList"];

    console.log(value);

    notificationService.editNotification(value).then(data => {
      console.log(data);
     // setNotificationData(data);
     // setStatus("success");
      setEditStatus("success");
    })
    .catch(error => {
      console.error(error);
        errorDispatch({
        type: "ERROR",
        error
      });
      setStatus("failed");
    });
  }

  const [otherVendors, setOtherVendors] = React.useState(false);
  const [edit,setEdit] = React.useState(true);

  const handleVendorSelect = value => {
    if (value === "Others") {
      setOtherVendors(true);
    } else {
      setOtherVendors(false);
    }
  };

  const values = {
    title: !!notificationData.actualMessage ? notificationData.actualMessage.title : "",
    content: !!notificationData.actualMessage ? notificationData.actualMessage.content : "",
    language: !!notificationData.actualMessage ? notificationData.actualMessage.language : "",
    mimeType: !!notificationData.actualMessage ? notificationData.actualMessage.mimeType : "",
    previewUrl: !!notificationData.actualMessage ? notificationData.actualMessage.previewUrl : "",
    actionUrl: !!notificationData.actualMessage ? notificationData.actualMessage.actionUrl : "",
    appAction: !!notificationData.actualMessage ? notificationData.actualMessage.appAction : "",
    productCode: !!notificationData.actualMessage ? notificationData.actualMessage.productCode : "",
    targetUsers: !!notificationData.actualMessage ? notificationData.actualMessage.targetUsers : "",
    validFrom: !!notificationData.actualMessage ? notificationData.actualMessage.validFrom : new Date(),
    validUpTo: !!notificationData.actualMessage ? notificationData.actualMessage.validUpTo : new Date(),
    publishChannel: !!notificationData.actualMessage ? notificationData.actualMessage.publishChannel : "",
    topicName:!!notificationData.actualMessage ? notificationData.actualMessage.topicName : "",
    extraDataList: !!notificationData.actualMessage ?notificationData.actualMessage.extraDataList : [],
    extraData: !!notificationData.actualMessage ? notificationData.actualMessage.extraData: []
  };

  return (
    <Grid container>
    { appActionStatus === "loading" ? (
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ height: "50vh" }}
      >
        <CircularProgress />
      </Grid>
    ):(
    <NotificationForm
      values={values}
      language={language}
      mimeType={mimeType}
      actionUrl={actionUrl}
      publishChannel={publishChannel}
      productCode={productCode}
      allDropDownData = {allDropDownData}
      appAction={appAction}
      topicName={topicName}
      handleSubmit={handleSubmit}
      editStatus={editStatus}
      edit={edit}
      createError={createError}
      otherVendors={otherVendors}
      handleVendorSelect={handleVendorSelect}
    />
    )}
    </Grid>
  );
}
