import React, { useState, useEffect, useRef, useContext } from "react";
import { Formik } from "formik";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ReactEcharts from "echarts-for-react";
import { useTranslation } from "react-i18next";
// import { format } from "date-fns";
import PropTypes from "prop-types";
// import CustomSelect from "../../../components/common/elements/CustomSelect";
import { analyticService } from "../../../services/analyticsServices";
import { errorContext } from "context/errorContext";
import CommonForm from "../../../components/common/form/common-form";
// import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";
import { format, toDate } from "date-fns";
function TabPanel(props) {
  const { children, value, index } = props;
  return value == index && children;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

//-------------

function DetailedGraph({
  limit = null,
  order = null,
  attribute = null,
  validations,
  fields,
  cb,
  height = { height: "320px" }
}) {
  const useStyles = makeStyles(theme => ({
    formControl: {
      marginLeft: 2,
      minWidth: "100%"
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    tabIndicator: {
      backgroundColor: "#1089ff"
    }
  }));

  const [volumeGraphData, setVolumeGraphData] = React.useState([]);
  const [status, setStatus] = React.useState("loading");
  const [labelWidth, setLabelWidth] = useState(0);
  const { dispatch: errorDispatch } = useContext(errorContext);
  const inputLabel = React.useRef(null);
  const getOptionsData = volumeGraphData;
  const classes = useStyles();

  const [currentChart, setCurrentChart] = useState("Total Volume Chilled");
  const [tabValue, setTabValue] = useState(0);
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [getOptions, setOptions] = useState(null);
  const { t } = useTranslation();
  const setGraph = data => {
    data.xAxis[0].axisLabel.formatter = cb;
    data.yAxis[0].axisLabel = {
      formatter: function(value, index) {
        var d = Number(value);
        var h = Math.floor(d / 3600);
        return h + "h";
      }
    };
    if (cb === null) {
      data.xAxis[0].axisLabel.rotate = 60;
      data.xAxis[0].nameGap = 200;
    }
    data.tooltip.formatter = function(params) {
      const site = params[0].axisValue;
      const siteName = site.substring(0, site.lastIndexOf("-")).trim();
      const siteId = site.substring(site.lastIndexOf("-") + 1).trim();
      var dataInSeconds = Number(params[0].data);
      var h = Math.floor(dataInSeconds / 3600) + "h";
      var m = Math.floor((dataInSeconds % 3600) / 60) + "m";
      var s = Math.floor((dataInSeconds % 3600) % 60) + "s";
      const time = h + " " + m + " " + s;
      const value = `Site Name: ${siteName}<br/>Site Id: ${siteId}<br/>Time: ${time}`;
      return value;
    };
    return data;
  };
  const handleSubmit = values => {
    setStatus("loading");
    analyticService
      .fetchGraphData(values)
      .then(data => {
        setOptions(setGraph(data));
        setStatus("success");
      })
      .catch(error => {
        // errorDispatch({
        //   type: "ERROR",
        //   error
        // });
        console.error("error ", error);
      });
  };
  useEffect(() => {
    setLabelWidth(!!inputLabel.current && !!inputLabel.current.offsetWidth);
  }, []);
  useEffect(() => {
    setStatus("loading");
    analyticService
      .fetchGraphData()
      .then(data => {
        setOptions(setGraph(data));
        setStatus("success");
      })
      .catch(error => {
        // errorDispatch({
        //   type: "ERROR",
        //   error
        // });
        console.error("error ", error);
      });
  }, [fields]);

  const check = cb => {
    cb();
  };
  const initialValues = {
    order: "Top",
    limit: 10,
    attribute: "By Chilling Utilization"
  };
  return (
    <Paper style={{ height: "100%", position: "relative" }} elevation={2}>
      <Grid container>
        <Grid item xs={12} justify="space-between" style={{ padding: ".5em" }}>
          {/* <div className="card-loader"> */}
          {/* <span>Feature Coming Soon...</span> */}
          {/* </div> */}
          {/* <CustomSelect
                    label="Select"
                    name="currentChart"
                    value={currentChart}
                    handleValueChange={handleSelectChange}
                    options={["Total Volume Chilled", "Temperature"]}
                  /> */}

          {cb === null ? (
            <CommonForm
              fields={fields}
              submitLabel={t("checkList_Form_Submit_Label")}
              submittingLabel={t("checkList_Form_Submitting_Label")}
              validationSchema={Yup.object().shape({ ...validations })}
              validateOnBlur={true}
              initialValues={initialValues}
              validateOnChange={true}
              onSubmit={(values, { setSubmitting, setFieldError }) => {
                check(setSubmitting);
                check(setFieldError);
                handleSubmit(values);
              }}
              inlineButton={true}
              buttonSize="3"
              buttonPosition="right"
              style={{ overflow: "auto" }}
            />
          ) : (
            <Grid container spacing={2}>
              <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {({ values, setFieldValue, handleChange, submitForm }) => {
                  return (
                    <>
                      <Grid item container xs={4} sm={2} md={3} style={{ margin: "18px" }}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel
                            ref={inputLabel}
                            id="demo-simple-select-outlined-label"
                          >
                            Select Order
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={e => {
                              e.persist();
                              setFieldValue("order", e.target.value, false); // last argument prevents validation from running
                              submitForm();
                            }}
                            value={values.order}
                            labelWidth={labelWidth}
                          >
                            <MenuItem disabled={true}>Select order</MenuItem>
                            {order.length
                              ? (order || []).map((org, index) => (
                                  <MenuItem key={index} value={org}>
                                    {org}
                                  </MenuItem>
                                ))
                              : ""}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item container xs={4} sm={2} md={3} style={{ margin: "18px" }}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel
                            ref={inputLabel}
                            id="demo-simple-select-outlined-label"
                          >
                            Select Limit
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={e => {
                              e.persist();
                              setFieldValue("limit", e.target.value, false); // last argument prevents validation from running
                              submitForm();
                            }}
                            value={values.limit}
                            labelWidth={labelWidth}
                          >
                            <MenuItem disabled={true}>Select Limit</MenuItem>
                            {limit.length
                              ? (limit || []).map((org, index) => (
                                  <MenuItem key={index} value={org}>
                                    {org}
                                  </MenuItem>
                                ))
                              : ""}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item container xs={4} sm={2} md={3} style={{ margin: "18px" }}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel
                            ref={inputLabel}
                            id="demo-simple-select-outlined-label"
                          >
                            Select attribute
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={e => {
                              e.persist();
                              setFieldValue("attribute", e.target.value, false); // last argument prevents validation from running
                              submitForm();
                            }}
                            value={values.attribute}
                            labelWidth={labelWidth}
                          >
                            <MenuItem disabled={true}>
                              Select attribute
                            </MenuItem>
                            {attribute.length
                              ? (attribute || []).map((org, index) => (
                                  <MenuItem key={index} value={org}>
                                    {org}
                                  </MenuItem>
                                ))
                              : ""}
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  );
                }}
              </Formik>
            </Grid>
          )}
        </Grid>
        <Grid item container xs={12} justify="flex-end">
          <Grid item xs={12} style={{ borderTop: "1px solid lightgrey" }}>
            <br />
            {status === "success" ? (
              <ReactEcharts
                option={getOptions}
                showLoading={!loading}
                style={height}
              />
            ) : (
              <Grid
                container
                xs={12}
                justify="center"
                alignItems="center"
                style={{ height: "50vh" }}
              >
                <CircularProgress />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default DetailedGraph;
