import React, { useState, useEffect } from "react";
import { FieldArray, Field, getIn } from "formik";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { TextField } from "formik-material-ui";
import { useTranslation } from "react-i18next";

export default function SubTaskFields({ name, TaskValuesTypes, renderFields }) {
  function handleTypeChange(
    type,
    childIndex,
    setFieldValue,
    setFieldTouched,
    values
  ) {
    let prevType = getIn(values, `${name}.${childIndex}.taskType`);
    if (type === prevType) return;
    setFieldValue(`${name}.${childIndex}.subTaskValue`, {}, false);
    if (type === "COST") {
      setFieldValue(
        `${name}.${childIndex}.subTaskValue.currency[0]`,
        "",
        false
      );
      setFieldTouched(
        `${name}.${childIndex}.subTaskValue.currency[0]`,
        false,
        false
      );
    }
    if (type === "SELECT") {
      setFieldValue(
        `${name}.${childIndex}.subTaskValue.list[0].`,
        { name: "", value: "" },
        false
      );
      setFieldTouched(
        `${name}.${childIndex}.subTaskValue.list[0]`,
        false,
        false
      );
    }
    if (type === "RANGE") {
      setFieldValue(`${name}.${childIndex}.subTaskValue.minValue`, "", false);
      setFieldTouched(
        `${name}.${childIndex}.subTaskValue.minValue`,
        false,
        false
      );
      setFieldValue(`${name}.${childIndex}.subTaskValue.maxValue`, "", false);
      setFieldTouched(
        `${name}.${childIndex}.subTaskValue.maxValue`,
        false,
        false
      );
      setFieldValue(`${name}.${childIndex}.subTaskValue.type`, "", false);
      setFieldTouched(`${name}.${childIndex}.subTaskValue.type`, false, false);
    }
  }
  const { t } = useTranslation();
  return (
    <FieldArray name={name}>
      {({ insert, remove, push, form }) => {
        let {
          values,
          errors,
          touched,
          setFieldTouched,
          setFieldValue,
          handleChange
        } = form;
        return (
          <>
            <Grid item xs={12}>
              <h3 style={{ color: "black" }}>{t("checkList_Form_Sub_Task_Details_Header")}</h3>
            </Grid>
            {getIn(values, name).length > 0 &&
              getIn(values, name).map((subTask, childIndex) => (
                <Grid
                  container
                  item
                  xs={12}
                  className="mb-0 d-flex flex-direction-column device-wrapper"
                  key={childIndex}
                >
                  <Grid
                    item
                    container
                    xs={12}
                    spacing={2}
                    alignContent="flex-start"
                    className="site-inner"
                  >
                    <Grid item xs={11} md={3}>
                      <Field
                        type="text"
                        name={`${name}.${childIndex}.subTaskName`}
                        label={t("checkList_Form_Sub_Task_Title_Label")}
                        component={TextField}
                        value={`${name}.${childIndex}.subTaskName`}
                        error={getIn(
                          errors,
                          `${name}.${childIndex}.subTaskName`
                        )}
                        margin="dense"
                        fullWidth={true}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={11} md={3}>
                      <Field
                        name={`${name}.${childIndex}.subTaskDescription`}
                        label={t("checkList_Form_Sub_Task_Description")}
                        className="placeholder-inner"
                        component={TextField}
                        value={`${name}.${childIndex}.subTaskDescription`}
                        margin="dense"
                        fullWidth={true}
                        variant="outlined"
                      ></Field>
                    </Grid>
                    <Grid item xs={11} md={3}>
                      <Field
                        select
                        name={`${name}.${childIndex}.taskType`}
                        label={t("checkList_Form_Sub_Task_Type_Label")}
                        component={TextField}
                        margin="dense"
                        fullWidth={true}
                        variant="outlined"
                        inputProps={{
                          onChange: e => {
                            handleChange(e);
                            handleTypeChange(
                              e.target.value,
                              childIndex,
                              setFieldValue,
                              setFieldTouched,
                              values
                            );
                          }
                        }}
                      >
                        <MenuItem disabled value="none">
                          {"Choose"}
                        </MenuItem>
                        {TaskValuesTypes.map(value => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item container xs={1} md={3}>
                      {getIn(values, name).length > 0 &&
                        childIndex === getIn(values, name).length - 1 && (
                          <button
                            type="button"
                            onClick={() => {
                              if (
                                !getIn(errors, `${name}.${childIndex}`) &&
                                getIn(touched, `${name}.${childIndex}`)
                              ) {
                                push({
                                  subTaskName: "",
                                  subTaskDescription: "",
                                  taskType: "",
                                  subTaskValue: {}
                                });
                              } else {
                                Object.keys(
                                  getIn(values, `${name}.${childIndex}`)
                                ).forEach(item => {
                                  item !== "subTaskValue" &&
                                    setFieldTouched(
                                      `${name}.${childIndex}.${item}`,
                                      true,
                                      true
                                    );
                                  if (
                                    getIn(
                                      errors,
                                      `${name}.${childIndex}.${item}`
                                    )
                                  ) {
                                    item !== "subTaskValue" &&
                                      setFieldTouched(
                                        `${name}.${childIndex}.${item}`,
                                        true,
                                        true
                                      );
                                  }
                                });
                              }
                            }}
                            className="add-icon"
                          >
                            <i
                              className="fa fa-plus-circle"
                              aria-hidden="true"
                            ></i>
                          </button>
                        )}
                      {getIn(values, name).length > 1 && (
                        <button
                          type="button"
                          className="add-icon delete-icon"
                          onClick={e => {
                            getIn(values, name).length > 0 &&
                              remove(childIndex);
                          }}
                        >
                          <i class="fa fa-minus-circle" aria-hidden="true"></i>
                        </button>
                      )}
                    </Grid>
                  </Grid>

                  {/* <Grid container justify="flex-end"> */}
                  {getIn(values, `${name}.${childIndex}.taskType`) ===
                    "COST" && (
                    <>
                      <FieldArray
                        name={`${name}.${childIndex}.subTaskValue.currency`}
                      >
                        {({ remove, push, form }) => {
                          let { values, setFieldError, setFieldTouched } = form;
                          return (
                            <>
                              {!!getIn(
                                values,
                                `${name}.${childIndex}.subTaskValue.currency`
                              ) ? (
                                getIn(
                                  values,
                                  `${name}.${childIndex}.subTaskValue.currency`
                                ).map((item, index) => (
                                  <>
                                    <Grid
                                      container
                                      item
                                      justify="flex-end"
                                      spacing={2}
                                      key={`${name}.currency.${index}`}
                                    >
                                      <Grid item xs={9} md={3}>
                                        <Field
                                          type="text"
                                          name={`${name}.${childIndex}.subTaskValue.currency[${index}]`}
                                          label="currency"
                                          component={TextField}
                                          margin="dense"
                                          fullWidth={true}
                                          variant="outlined"
                                        />
                                      </Grid>
                                      <Grid item container xs={3}>
                                        {getIn(
                                          values,
                                          `${name}.${childIndex}.subTaskValue.currency`
                                        ).length > 1 && (
                                          <button
                                            type="button"
                                            className="add-icon delete-icon"
                                            onClick={e => {
                                              getIn(
                                                values,
                                                `${name}.${childIndex}.subTaskValue.currency`
                                              ).length > 0 && remove(index);
                                            }}
                                          >
                                            <i
                                              class="fa fa-minus-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        )}
                                        {getIn(
                                          values,
                                          `${name}.${childIndex}.subTaskValue.currency`
                                        ).length > 0 &&
                                          index ===
                                            getIn(
                                              values,
                                              `${name}.${childIndex}.subTaskValue.currency`
                                            ).length -
                                              1 && (
                                            <button
                                              type="button"
                                              onClick={() => {
                                                let isError = getIn(
                                                  errors,
                                                  `${name}.${childIndex}.subTaskValue.currency`
                                                );
                                                let currencies = getIn(
                                                  values,
                                                  `${name}.${childIndex}.subTaskValue.currency`
                                                );
                                                if (!!isError) {
                                                  currencies.forEach(
                                                    (
                                                      currency,
                                                      currencyIndex
                                                    ) => {
                                                      setFieldTouched(
                                                        `${name}.${childIndex}.subTaskValue.currency.${currencyIndex}`,
                                                        true,
                                                        true
                                                      );
                                                    }
                                                  );
                                                } else {
                                                  push("");
                                                }
                                              }}
                                              className="add-icon"
                                            >
                                              <i
                                                className="fa fa-plus-circle"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          )}
                                      </Grid>
                                    </Grid>
                                  </>
                                ))
                              ) : (
                                <Grid
                                  container
                                  item
                                  justify="flex-end"
                                  spacing={2}
                                >
                                  <Grid item xs={9} md={3}>
                                    <Field
                                      type="text"
                                      name={`${name}.${childIndex}.subTaskValue.currency[0]`}
                                      label="currency"
                                      component={TextField}
                                      margin="dense"
                                      fullWidth={true}
                                      variant="outlined"
                                    />
                                  </Grid>
                                  <Grid item container xs={3}>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        if (
                                          getIn(
                                            values,
                                            `${name}.${childIndex}.subTaskValue.currency`
                                          ) &&
                                          getIn(
                                            values,
                                            `${name}.${childIndex}.subTaskValue.currency`
                                          ).length > 0
                                        ) {
                                          push("");
                                        } else {
                                          setFieldTouched(
                                            `${name}.${childIndex}.subTaskValue.currency[0]`,
                                            true,
                                            false
                                          );
                                          setFieldError(
                                            `${name}.${childIndex}.subTaskValue.currency[0]`,
                                            "Enter a currency",
                                            false
                                          );
                                        }
                                      }}
                                      className="add-icon"
                                    >
                                      <i
                                        className="fa fa-plus-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </Grid>
                                </Grid>
                              )}
                            </>
                          );
                        }}
                      </FieldArray>
                    </>
                  )}
                  {getIn(values, `${name}.${childIndex}.taskType`) ===
                    "RANGE" && (
                    <>
                      <Grid container justify="flex-end" spacing={2}>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field
                            type="text"
                            name={`${name}.${childIndex}.subTaskValue.minValue`}
                            label="minValue"
                            component={TextField}
                            margin="dense"
                            fullWidth={true}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field
                            type="text"
                            name={`${name}.${childIndex}.subTaskValue.maxValue`}
                            label="maxValue"
                            component={TextField}
                            margin="dense"
                            fullWidth={true}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Field
                            type="text"
                            name={`${name}.${childIndex}.subTaskValue.type`}
                            label="type"
                            component={TextField}
                            margin="dense"
                            fullWidth={true}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {getIn(values, `${name}.${childIndex}.taskType`) ===
                    "BOOLEAN" && <></>}

                  {getIn(values, `${name}.${childIndex}.taskType`) ===
                    "SELECT" && (
                    <>
                      <FieldArray
                        name={`${name}.${childIndex}.subTaskValue.list`}
                      >
                        {({ insert, remove, push, form }) => {
                          let { values, setFieldTouched, setFieldError } = form;
                          return (
                            <>
                              {!!getIn(
                                values,
                                `${name}.${childIndex}.subTaskValue.list`
                              ) ? (
                                getIn(
                                  values,
                                  `${name}.${childIndex}.subTaskValue.list`
                                ).map((item, index) => (
                                  <>
                                    <Grid
                                      container
                                      justify="flex-end"
                                      spacing={2}
                                    >
                                      <Grid
                                        item
                                        xs={10}
                                        md={6}
                                        container
                                        spacing={1}
                                      >
                                        <Grid item xs={12} md={6}>
                                          <Field
                                            type="text"
                                            name={`${name}.${childIndex}.subTaskValue.list[${index}].name`}
                                            label="name"
                                            component={TextField}
                                            margin="dense"
                                            fullWidth={true}
                                            variant="outlined"
                                          />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                          <Field
                                            type="text"
                                            name={`${name}.${childIndex}.subTaskValue.list[${index}].value`}
                                            label="value"
                                            component={TextField}
                                            margin="dense"
                                            fullWidth={true}
                                            variant="outlined"
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        item
                                        container
                                        xs={2}
                                        direction="row"
                                      >
                                        {getIn(
                                          values,
                                          `${name}.${childIndex}.subTaskValue.list`
                                        ).length > 1 && (
                                          <button
                                            type="button"
                                            className="add-icon delete-icon"
                                            onClick={e => {
                                              getIn(
                                                values,
                                                `${name}.${childIndex}.subTaskValue.list`
                                              ).length > 0 && remove(index);
                                            }}
                                          >
                                            <i
                                              class="fa fa-minus-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        )}
                                        {getIn(
                                          values,
                                          `${name}.${childIndex}.subTaskValue.list`
                                        ).length > 0 &&
                                          index ===
                                            getIn(
                                              values,
                                              `${name}.${childIndex}.subTaskValue.list`
                                            ).length -
                                              1 && (
                                            <button
                                              type="button"
                                              onClick={() => {
                                                let isError = getIn(
                                                  errors,
                                                  `${name}.${childIndex}.subTaskValue.list`
                                                );
                                                let selectValues = getIn(
                                                  values,
                                                  `${name}.${childIndex}.subTaskValue.list`
                                                );
                                                if (!!isError) {
                                                  selectValues.forEach(
                                                    (select, selectIndex) => {
                                                      setFieldTouched(
                                                        `${name}.${childIndex}.subTaskValue.list.${selectIndex}.name`,
                                                        true,
                                                        true
                                                      );
                                                      setFieldTouched(
                                                        `${name}.${childIndex}.subTaskValue.list.${selectIndex}.value`,
                                                        true,
                                                        true
                                                      );
                                                    }
                                                  );
                                                } else {
                                                  push({ name: "", value: "" });
                                                }
                                              }}
                                              className="add-icon"
                                            >
                                              <i
                                                className="fa fa-plus-circle"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          )}
                                      </Grid>
                                    </Grid>
                                  </>
                                ))
                              ) : (
                                <>
                                  <Grid
                                    container
                                    justify="flex-end"
                                    spacing={2}
                                  >
                                    <Grid
                                      item
                                      xs={10}
                                      md={6}
                                      container
                                      spacing={1}
                                    >
                                      <Grid item xs={12} md={6}>
                                        <Field
                                          type="text"
                                          name={`${name}.${childIndex}.subTaskValue.list[0].name`}
                                          label="name"
                                          component={TextField}
                                          margin="dense"
                                          fullWidth={true}
                                          variant="outlined"
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={6}>
                                        <Field
                                          type="text"
                                          name={`${name}.${childIndex}.subTaskValue.list[0].value`}
                                          label="value"
                                          component={TextField}
                                          margin="dense"
                                          fullWidth={true}
                                          variant="outlined"
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={2} container>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          if (
                                            getIn(
                                              values,
                                              `${name}.${childIndex}.subTaskValue.list`
                                            ) &&
                                            getIn(
                                              values,
                                              `${name}.${childIndex}.subTaskValue.list`
                                            ).length > 0
                                          ) {
                                            push({ name: "", value: "" });
                                          } else {
                                            setFieldTouched(
                                              `${name}.${childIndex}.subTaskValue.list[0].value`,
                                              true,
                                              false
                                            );
                                            setFieldTouched(
                                              `${name}.${childIndex}.subTaskValue.list[0].name`,
                                              true,
                                              false
                                            );
                                            setFieldError(
                                              `${name}.${childIndex}.subTaskValue.list[0].name`,
                                              "Enter a Name",
                                              false
                                            );
                                            setFieldError(
                                              `${name}.${childIndex}.subTaskValue.list[0].value`,
                                              "Enter a Value",
                                              false
                                            );
                                          }
                                        }}
                                        className="add-icon"
                                      >
                                        <i
                                          className="fa fa-plus-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                    </Grid>
                                  </Grid>
                                </>
                              )

                              //console.log('values priniting ---- >>> ',getIn(values,`${childIndex}.subTaskValue`))
                              }
                            </>
                          );
                        }}
                      </FieldArray>
                    </>
                  )}
                  {/* </Grid> */}
                </Grid>
              ))}
          </>
        );
      }}
    </FieldArray>
  );
}
