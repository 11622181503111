import {
  call,
  takeLatest,
  takeEvery,
  all,
  fork,
  select,
  put
} from "redux-saga/effects";
import { authenticationService } from "services/authServices";

function* watchAuthentication() {
  yield takeLatest("AUTHENTICATION_LOGIN", login);
  yield takeLatest("AUTHENTICATION_LOGOUT", logout);
}

function* login({ userName, password, rememberMe }) {
  try {
    const user = yield call(authenticationService.login, userName, password);
    user.body["rememberMe"] = rememberMe;
    const userBody = yield call(JSON.stringify, user.body);
    yield put({
      type: "RESET_APP"
    });
    // ("currentUser", user.body);

    localStorage.setItem("currentUser", userBody);
    // console.log("userMe",userBody)
    const userData = JSON.parse(localStorage.getItem("currentUser"));

    yield put({
      type: "AUTHENTICATION_LOGIN_SUCCESS",
      userData
    });
  } catch (e) {
    console.error("logging in failed", e.message);
    yield put({
      type: "AUTHENTICATION_LOGIN_FAIL",
      message: e.message
    });
  }
}

function* logout() {
  try {
    yield call(authenticationService.logout);
    localStorage.clear();
    yield put({
      type: "RESET_APP"
    });
  } catch (e) {
    console.error("error in logging out");
  }
}

export default function* rootSaga() {
  yield all([fork(watchAuthentication)]);
}
