import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import ErrorMsgIcon from "../../../assets/error.png";
import { CustomizedPrimaryButton } from "../elements/CustomizedPrimaryButton";

const styles = {
  paper: {
    border: 0,
    position: "relative",
    height: "30rem",
    width: "30rem",
    fontSize: "1.2rem",
    borderRadius: "5px",
    padding: "2rem",
    margin: 8
  }
};

const DialogBox = ({ classes, ...others }) => (
  <Dialog classes={classes} {...others} />
);

const FeedbackBox = withStyles(styles)(DialogBox);

export default function FailureFeedback({
  open,
  onClose,
  status,
  message,
  showButtons = true
}) {
  return (
    <FeedbackBox
      open={open}
      onClose={onClose}
      aria-labelledby="form-submit-feedback"
      status="success"
      className="error-msg"
    >
      <div className="d-flex flex-column align-items-center justify-center h-100 position-relative">
        <div className="d-flex flex-column align-items-center justify-center w-100">
          <div
            style={{
              height: "100%",
              display: "block",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              textAlign: "center"
            }}
          >
            <p className="">
              <img
                src={ErrorMsgIcon}
                alt="error icon"
                style={{
                  maxWidth: "7rem",
                  margin: "0 auto",
                  display: "block"
                }}
              />
              {!!status ? status : "Something Went Wrong!"}
            </p>
            <div className="info-msg">
              {!!message ? (
                message
              ) : (
                <>
                  <p>If you are facing the issue for the first time,</p>
                  <p>Check with our Support team for further Clarification.</p>
                </>
              )}
            </div>
          </div>
          {showButtons && (
            <div style={{ width: "100%", padding: "1em 1em" }}>
              <CustomizedPrimaryButton
                variant="contained"
                color="primary"
                size="large"
                onClick={onClose}
                label="OK"
                width={6}
                position="center"
              ></CustomizedPrimaryButton>
            </div>
          )}
        </div>
      </div>
    </FeedbackBox>
  );
}
