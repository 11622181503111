import React, { useState, useEffect, useContext } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
import getDate from "date-fns/getDate";
import set from "date-fns/set";
import PageHeader from "../../components/layout/PageHeader.js";
import addDays from "date-fns/addDays";
import CommonForm from "../../components/common/form/common-form";
import { useParams } from "react-router-dom";
import { provisioningService } from "../../services/provisioningServices";
import { chillingUnitService } from "../../services/chillingUnitService";
import { errorContext } from "context/errorContext";
import format from "date-fns/format";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import RawData from "./RawData";
import TemperatureData from "./TemperatureData";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  tabIndicator: {
    backgroundColor: "#1089ff"
  }
}));

export default function DeviceData() {
  const [startDate, setStartDate] = React.useState(
    addDays(new Date(), -1).setHours(0, 0, 0, 0)
  );
  const [endDate, setEndDate] = React.useState(new Date());
  const [startTime, setStartTime] = useState(new Date().setHours(0, 0, 0, 0));
  const [endTime, setEndTime] = useState(new Date());
  const [bmcList, setBmcList] = useState([]);
  const [siteDetails, setSiteDetails] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = React.useState([]);
  const [tabValue, setTabValue] = useState(null);
  const [tabValue1, setTabValue1] = useState(null);
  const [contrakv1Tabs, setContrakv1Tabs] = useState([
    { tabName: "Volume Data", deviceType: "CONTRAK_V1", eventName: "VOLUME" },
    {
      tabName: "Temperature Data",
      deviceType: "CONTRAK_V1",
      eventName: "TEMP"
    },
    {
      tabName: "Event Data",
      deviceType: "CONTRAK_V1",
      eventName: "VOLUME,TEMP"
    },
    {
      tabName: "Received Data",
      deviceType: "CONTRAK_V1",
      eventName: "RECEIVEDData"
    }
  ]);

  const [change, setChange] = useState(false);
  const [serialNumber, setSerialNumber] = useState(null);
  const [deviceType, setDeviceType] = useState(null);
  const [feedback, setFeedback] = useState([]);
  const [temperature, setTemperature] = useState([]);
  const { siteId, siteUUID, chillingUnitUUID } = useParams();
  const { t } = useTranslation();

  const { dispatch: errorDispatch } = useContext(errorContext);
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const organizationName = user && user.organizationName;

  const classes = useStyles();

  let values = {
    siteName: siteId,
    chillingUnitName: chillingUnitUUID,
    startDate: startDate,
    endDate: endDate,
    startTime: startTime,
    endTime: endTime
  };

  useEffect(() => {
    setLoading(true);
    setSite(siteId);
    provisioningService
      .getAllSitesByOrg(organizationName)
      .then(data => {
        setSiteDetails(
          data.content.filter(res => res.numberOfChillingUnit > 0)
        );
        setLoading(false);
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });

    provisioningService
      .getChillingUnitData(siteId)
      .then(data => {
        setBmcList(data);
      })
      .catch(error => {
        //   errorDispatch({
        //     type: "ERROR",
        //     error
        //   });
      });
  }, [organizationName]);

  useEffect(() => {
    let devices = [];
    bmcList &&
      bmcList.map(bmc => {
        if (bmc.chillingUnitUUID === chillingUnitUUID) {
          bmc.l1DeviceInfo.map(device => {
            let deviceDetails = {
              deviceType: device.deviceType,
              deviceUuid: device.deviceUuid,
              serialNumber: device.serialNumber
            };
            devices.push(deviceDetails);
          });
        }
      });
    setTab(devices);
  }, [bmcList]);

  useEffect(() => {
    tab[0] && setTabValue(tab[0].deviceUuid);
    tab[0] && setSerialNumber(tab[0].serialNumber);
    tab[0] && handleFeedsensTempsens(tab[0].serialNumber);
    tab[0] && setDeviceType(tab[0].deviceType);
    tab[0] &&
      setTabValue1({
        contrakv1deviceId: tab[0].deviceUuid,
        eventName: contrakv1Tabs[0].eventName
      });
  }, [tab]);

  const handleSite = val => setSite(val);

  const setSite = val => {
    setTab([]);
    provisioningService
      .getChillingUnitData(val)
      .then(data => {
        setBmcList(data);
      })
      .catch(error => {
        //   errorDispatch({
        //     type: "ERROR",
        //     error
        //   });
      });
  };

  const handleChillingUnit = val => setTabInfo(val);

  const setTabInfo = val => {
    let devices = [];
    bmcList.map(bmc => {
      if (bmc.chillingUnitUUID === val) {
        bmc.l1DeviceInfo.map(device => {
          let deviceDetails = {
            deviceType: device.deviceType,
            deviceUuid: device.deviceUuid,
            serialNumber: device.serialNumber
          };
          devices.push(deviceDetails);
        });
      }
    });
    devices[0] &&
      setTabValue1({
        contrakv1deviceId: devices[0].deviceUuid,
        eventName: contrakv1Tabs[0].eventName
      });
    setTab(devices);
    setTabValue(devices[0] && devices[0].deviceUuid);
    setSerialNumber(devices[0] && devices[0].serialNumber);
    handleFeedsensTempsens(devices[0] && devices[0].serialNumber);
    setDeviceType(devices[0] && devices[0].deviceType);
    setChange(!change);
  };

  const handleFeedsensTempsens = sensorSerialNumber => {
    let feedbacks = [];
    let temperatures = [];
    bmcList &&
      bmcList.map(bmc => {
        bmc.l1DeviceInfo.map(device => {
          if (device.deviceType === "feedsens" && sensorSerialNumber === device.serialNumber) {
            device.l0Config.map(feed => {
              if (feed.deviceAttribute === "Feedback #1") {
                var feed = {
                  feedback: "0",
                  feedbackStatus: feed.applicationAttribute
                };
                feedbacks.push(feed);
              }

              if (feed.deviceAttribute === "Feedback #2") {
                var feed = {
                  feedback: "1",
                  feedbackStatus: feed.applicationAttribute
                };
                feedbacks.push(feed);
              }

              if (feed.deviceAttribute === "Feedback #3") {
                var feed = {
                  feedback: "2",
                  feedbackStatus: feed.applicationAttribute
                };
                feedbacks.push(feed);
              }

              if (feed.deviceAttribute === "Feedback #4") {
                var feed = {
                  feedback: "3",
                  feedbackStatus: feed.applicationAttribute
                };
                feedbacks.push(feed);
              }
            });
          } else if (device.deviceType === "tempsens" && sensorSerialNumber === device.serialNumber) {
            device.l0Config.map(temp => {
              if (temp.deviceAttribute === "Temperature #1") {
                var temp = {
                  temperature: "0",
                  tempStatus: temp.applicationAttribute
                };
                temperatures.push(temp);
              }

              if (temp.deviceAttribute === "Temperature #2") {
                var temp = {
                  temperature: "1",
                  tempStatus: temp.applicationAttribute
                };
                temperatures.push(temp);
              }

              if (temp.deviceAttribute === "Temperature #3") {
                var temp = {
                  temperature: "2",
                  tempStatus: temp.applicationAttribute
                };
                temperatures.push(temp);
              }

              if (temp.deviceAttribute === "Temperature #4") {
                var temp = {
                  temperature: "3",
                  tempStatus: temp.applicationAttribute
                };
                temperatures.push(temp);
              }
            });
          }
        });
      });
    setFeedback(feedbacks);
    setTemperature(temperatures);
  }

  const handleTimeUpdate = values => {
    let startDate = values.startTime;
    let endDate = values.endTime;
    startDate = set(startDate, {
      year: getYear(values.startDate),
      month: getMonth(values.startDate),
      date: getDate(values.startDate)
    });

    endDate = set(endDate, {
      year: getYear(values.endDate),
      month: getMonth(values.endDate),
      date: getDate(values.endDate)
    });
    setStartDate(startDate);
    setEndDate(endDate);
  };
  const handleSubmit = (values, setSubmitting) => {
    handleTimeUpdate(values);
    setChange(!change);
    setSubmitting(false);
  };

  const fields = [
    {
      type: "select",
      name: "siteName",
      label: t("Device_Data_Select_Site"),
      placeholder: "Select Site",
      option: siteDetails
        ? siteDetails.map(site => ({
          value: site.siteId,
          label: site.siteName
        }))
        : [],
      grid: {
        xs: 12,
        sm: 2,
        md: 6,
        className: ""
      },
      customOnChange: handleSite
    },
    {
      type: "select",
      name: "chillingUnitName",
      label: t("Device_Data_Select_Chilling_Unit"),
      placeholder: "Select Chilling Unit",
      option: bmcList
        ? bmcList.map(bmc => ({
          value: bmc.chillingUnitUUID,
          label: bmc.chillingUnitName + " - " + bmc.chillingUnitID
        }))
        : [],
      grid: {
        xs: 12,
        sm: 2,
        md: 6,
        className: "align-center"
      },
      customOnChange: handleChillingUnit
    },
    {
      type: "datePicker",
      name: "startDate",
      value: { startDate },
      label: t("Device_Data_Start_Date"),
      maxDate: new Date(),
      grid: {
        xs: 12,
        sm: 2,
        md: 3,
        className: "align-center"
      }
    },
    {
      type: "timePicker",
      name: "startTime",
      label: t("Device_Data_Start_Time"),
      placeholder: "Start Time",
      grid: {
        xs: 12,
        sm: 2,
        md: 2
      },
      className: "time-picker"
    },
    {
      type: "datePicker",
      name: "endDate",
      value: { endDate },
      label: t("Device_Data_End_Date"),
      maxDate: new Date(),
      grid: {
        xs: 12,
        sm: 2,
        md: 3,
        className: "align-center"
      }
    },
    {
      type: "timePicker",
      name: "endTime",
      label: t("Device_Data_End_Time"),
      ser: "End Time",
      grid: {
        xs: 12,
        sm: 2,
        md: 2
      },
      className: "time-picker"
    }
  ];

  const handleTabChange = (event, value) => {
    tab.map(t => {
      if (t.deviceUuid === value && tabValue !== value) {
        setChange(!change);
        setTabValue(value);
        setDeviceType(t.deviceType);
        setSerialNumber(t.serialNumber);
        handleFeedsensTempsens(t.serialNumber);
      }
      return null;
    });
  };
  const contrakv1TabChange = (event, value) => {
    contrakv1Tabs.map(t => {
      if (t.eventName === value) {
        setTabValue1({
          contrakv1deviceId: tab[0].deviceUuid,
          eventName: value
        });
      }
    });

    setChange(!change);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("Device_Data_Header_Label")}
          breadcrumbs={[
            {
              label: t("Device_Data_Breadcrum_Dashboard_Label"),
              link: "/dashboard"
            },
            {
              label: t("Device_Data_Breadcrum_Site_Details_Label"),
              link: "/totalsites/site-details/" + siteId
            },
            { label: t("Device_Data_Breadcrum_Device_Data_Label") }
          ]}
        />
      </Grid>
      <Paper
        elevation={2}
        style={{ padding: "2em", width: "100%", marginBottom: 40 }}
      >
        <Grid item>
          <CommonForm
            fields={fields}
            submitLabel={t("Device_Data_Submit_Label")}
            submittingLabel={t("Device_Data_Submitting_Label")}
            validateOnBlur={true}
            initialValues={values}
            validateOnChange={true}
            onSubmit={(initialValues, { setSubmitting }) => {
              handleSubmit(initialValues, setSubmitting);
            }}
            buttonPosition="center"
            buttonSize="2"
            inlineButton={true}
          />
        </Grid>
      </Paper>
      <Grid item>
        <Paper square>
          {
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="tabs"
              classes={{
                indicator: classes.tabIndicator
              }}
            >
              {tab.map(element => {
                return (
                  <Tab
                    key={element.deviceUuid}
                    value={element.deviceUuid}
                    label={element.deviceType.replace("ABC", "")}
                  />
                );
              })}
            </Tabs>
          }
        </Paper>
        {deviceType === "CONTRAK_V1" ? (
          <Grid item>
            <Paper square>
              <Tabs
                value={tabValue1.eventName}
                onChange={contrakv1TabChange}
                indicatorColor="primary"
                textColor="primary"
                aria-label="tabs"
                classes={{
                  indicator: classes.tabIndicator
                }}
              >
                {contrakv1Tabs.map(element => {
                  return (
                    <Tab
                      key={element.eventName}
                      value={element.eventName}
                      label={element.tabName}
                    />
                  );
                })}
              </Tabs>
            </Paper>
          </Grid>
        ) : null}
      </Grid>
      {tab.length > 0 && deviceType === "feedsens" ? (
        <Grid item xs={12}>
          {tabValue && change == false ? (
            <RawData
              deviceId={tabValue}
              startDate={startDate}
              endDate={endDate}
              deviceType={deviceType}
              serialNumber={serialNumber}
              feedback={feedback}
            />
          ) : null}
          {tabValue && change == true ? (
            <RawData
              deviceId={tabValue}
              startDate={startDate}
              endDate={endDate}
              deviceType={deviceType}
              serialNumber={serialNumber}
              feedback={feedback}
            />
          ) : null}
        </Grid>
      ) : null}

      {tab.length > 0 && deviceType === "tempsens" ? (
        <Grid item xs={12}>
          {tabValue && change == false ? (
            <RawData
              deviceId={tabValue}
              startDate={startDate}
              endDate={endDate}
              deviceType={deviceType}
              serialNumber={serialNumber}
              temperature={temperature}
            />
          ) : null}
          {tabValue && change == true ? (
            <RawData
              deviceId={tabValue}
              startDate={startDate}
              endDate={endDate}
              deviceType={deviceType}
              serialNumber={serialNumber}
              temperature={temperature}
            />
          ) : null}
        </Grid>
      ) : null}



      {tab.length > 0 && deviceType === "CONTRAK_V1" ? (
        <Grid item xs={12}>
          {tabValue1 && change == false ? (
            <RawData
              deviceId={tabValue1.contrakv1deviceId}
              startDate={startDate}
              endDate={endDate}
              deviceType={deviceType}
              serialNumber={serialNumber}
              eventName={tabValue1.eventName}
            />
          ) : null}
          {tabValue1 && change == true ? (
            <RawData
              deviceId={tabValue1.contrakv1deviceId}
              startDate={startDate}
              endDate={endDate}
              deviceType={deviceType}
              serialNumber={serialNumber}
              eventName={tabValue1.eventName}
            />
          ) : null}
        </Grid>
      ) : null}

      {tab.length > 0 &&
        deviceType !== "feedsens" &&
        deviceType !== "CONTRAK_V1" &&
        deviceType !== "tempsens" ? (
          <Grid item xs={12}>
            {tabValue && change == false ? (
              <RawData
                deviceId={tabValue}
                startDate={startDate}
                endDate={endDate}
                deviceType={deviceType}
                serialNumber={serialNumber}
              />
            ) : null}
            {tabValue && change == true ? (
              <RawData
                deviceId={tabValue}
                startDate={startDate}
                endDate={endDate}
                deviceType={deviceType}
                serialNumber={serialNumber}
              />
            ) : null}
          </Grid>
        ) : null}

      {tab.length === 0 ?
        (
          <Grid
            container
            xs={12}
            justify="center"
            alignItems="center"
            style={{ height: "50vh" }}
          >
            <div> {t("raw_Data_No_Data")} </div>
          </Grid>
        ) : null
      }
    </Grid>
  );
}
