import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import stellapsLogo from "../../../assets/Stellapps_logo.png";
import { PageLoadingProgressBar } from "../elements/ColorChangingProgressBar";

export default function SpinningLoader(props) {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      style={{ height: "100vh" }}
    >
      <Grid item xs={6} md={4}>
        <img src={stellapsLogo} alt="stellapps logo" style={{ width: "85%" }} />
      </Grid>
      <Grid container justify="center" alignItems="center" direction="column">
        <Grid item>
          <Typography variant="h5" style={{ color: "#14AAE4",marginBottom:"2rem" }}>
            Loading...
          </Typography>
        </Grid>
        <Grid item style={{ width: "40%" }}>
          <PageLoadingProgressBar />
        </Grid>
      </Grid>
    </Grid>
  );
}
