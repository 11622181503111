import React from "react";

import clsx from "clsx";
import { Scrollbars } from "react-custom-scrollbars";

import SidebarMenuItems from "./SidebarMenuItems";
import Appbar from "./Appbar";

import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";

export default function Sidebar({ open, handleClose }) {
  let drawerWidth = 240;
  const useStyles = makeStyles(theme => ({
    root: {
      display: "flex"
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    hide: {
      display: "none"
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap"
    },
    drawerOpen: {
      marginTop: "5em",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      marginTop: "5em",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1
      }
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      // padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    darwerPaper: {
      overflowX: "visible"
    }
  }));
  const classes = useStyles();

  return (
    <CssBaseline>
      <div className={classes.root}>
        <Appbar
          handleClose={handleClose}
          drawerWidth={drawerWidth}
          position="fixed"
        />
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: clsx(classes.darwerPaper, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
          open={open}
        >
          <Scrollbars
            autoHide
            style={{ height: "90vh" }}
            renderTrackVertical={props => (
              <div {...props} className="track-vertical" />
            )}
            renderThumbVertical={props => (
              <div {...props} className="thumb-vertical" />
            )}
          >
            <List
              style={{
                padding: "0em 0em 2em 0em"
              }}
            >
              <SidebarMenuItems handleClose={handleClose} open={open} />
            </List>
          </Scrollbars>
        </Drawer>
      </div>
    </CssBaseline>
  );
}
