import React, { useState, useEffect, useContext } from "react";
import DateFormatter from "../../components/common/elements/DateFormatter";
import Grid from "@material-ui/core/Grid";
import DynamicPaginationTable from "components/common/table/DynamicPaginationTable";
import CircularProgress from "@material-ui/core/CircularProgress";
import TemplateForTables from "../../components/layout/innerPagesTemplate";
import { deviceDataService } from "../../services/deviceDataService";
import Table from "components/common/table/DynamicPaginationRawData";
import { useTranslation } from "react-i18next";

export default function RawData({
  deviceId,
  startDate,
  endDate,
  deviceType,
  serialNumber,
  feedback,
  temperature,
  eventName = ""
}) {
  const [hasNextPage, setHasNextPage] = useState(false);
  const [record, setRecord] = React.useState([]);
  const [status, setStatus] = React.useState("success");
  // const [deviceType, setDeviceType] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(10);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageInfo, setPageInfo] = React.useState({});
  const { t } = useTranslation();
  const [value, setValue] = React.useState(2);
  const [change, setChange] = useState(false);
  const [setSerialNumber] = useState(null);

  const indsensColumns = React.useMemo(() => [
    {
      Header: " ",
      columns: [
        {
          Header: t("raw_Data_Indsens_Event_Time"),
          accessor: "processTime",
          Cell: ({ row }) => {
            return (
              <div
                style={{
                  whiteSpace: "pre",
                  textAlign: "left"
                  // paddingLeft: "1em"
                }}
              >
                <DateFormatter date={row.original.processTime} />
              </div>
            );
          }
        }
      ]
    },
    {
      Header: t("raw_Data_Indsens_Temperature"),
      columns: [
        {
          Header: t("raw_Data_Indsens_Milk"),
          accessor: "attributes.milkTemperature.value",
          Cell: ({ row }) =>
            Number(row.original.attributes.milkTemperature.value).toFixed(2)
        },
        {
          Header: t("raw_Data_Indsens_Battery"),
          accessor: "attributes.batteryTemperature.value",
          Cell: ({ row }) =>
            Number(row.original.attributes.batteryTemperature.value).toFixed(2)
        },
        {
          Header: t("raw_Data_Indsens_Aux"),
          accessor: "attributes.auxiliaryTemperature.value",
          Cell: ({ row }) =>
            Number(row.original.attributes.auxiliaryTemperature.value).toFixed(
              2
            )
        }
      ]
    },
    {
      Header: " ",
      columns: [
        {
          Header: t("raw_Data_Indsens_Chilling_Pump_State"),
          accessor: "attributes.pumpStatus"
        }
      ]
    },
    {
      Header: t("raw_Data_Indsens_Agitator"),
      columns: [
        {
          Header: t("raw_Data_Indsens_Agitator_State"),
          accessor: "attributes.agitatorStatus"
        },
        {
          Header: t("raw_Data_Indsens_Agitator_Run_Duration"),
          accessor: "attributes.agitatorRunDuration.value"
        },
        {
          Header: t("raw_Data_Indsens_Agitator_Off_Duration"),
          accessor: "attributes.agitatorOffDuration.value"
        }
      ]
    },
    {
      Header: t("raw_Data_Indsens_Compressor"),
      columns: [
        {
          Header: t("raw_Data_Indsens_Compressor_State"),
          accessor: "attributes.compressorStatus"
        },
        {
          Header: t("raw_Data_Indsens_Compressor_Duration"),
          accessor: "attributes.compressorRunDuration.value"
        },
        {
          Header: t("raw_Data_Indsens_Compressor_Current"),
          accessor: "attributes.compressorCurrent"
        }
      ]
    },
    {
      Header: t("raw_Data_Indsens_Pump2"),
      columns: [
        {
          Header: t("raw_Data_Indsens_Pump2_State"),
          accessor: "attributes.pump2RelayStatus"
        },
        {
          Header: t("raw_Data_Indsens_Pump2_Current"),
          accessor: "attributes.pumpCurrent"
        }
      ]
    },
    {
      Header: " ",
      columns: [
        {
          Header: t("raw_Data_Indsens_Ac"),
          accessor: "attributes.alternatingCurrent.value"
        }
      ]
    },
    {
      Header: " ",
      columns: [
        {
          Header: t("raw_Data_Indsens_Dc"),
          accessor: "attributes.directCurrent.value"
        }
      ]
    },
    {
      Header: t("raw_Data_Indsens_Relay"),
      columns: [
        {
          Header: t("raw_Data_Indsens_Relay_Condensor"),
          accessor: "attributes.condenserRelayStatus"
        },
        {
          Header: t("raw_Data_Indsens_Relay_Invertor"),
          accessor: "attributes.inverterRelayStatus"
        },
        {
          Header: t("raw_Data_Indsens_Relay_Valve"),
          accessor: "attributes.valveRelayStatus"
        },
        {
          Header: t("raw_Data_Indsens_Relay_Alarm"),
          accessor: "attributes.alarmRelayStatus"
        }
      ]
    },
    {
      Header: " ",
      columns: [
        {
          Header: t("raw_Data_Indsens_Alarm_Code"),
          accessor: "attributes.alarmCode"
        }
      ]
    }
  ]);

  const weighsensColumns = React.useMemo(() => [
    {
      Header: t("raw_Data_Weighsens_Event_Time"),
      accessor: "processTime",
      Cell: ({ row }) => <DateFormatter date={row.original.processTime} />
    },
    {
      Header: t("raw_Data_Weighsens_Volume"),
      accessor: "attributes.weight.value"
    },
    {
      Header: t("raw_Data_Weighsens_Unit"),
      accessor: "attributes.weight.unit"
    }
  ]);

  const cv1TempColumns = React.useMemo(() => [
    {
      Header: "Temperature Data",
      columns: [
        {
          Header: "Event Time",
          accessor: "processTime",
          Cell: ({ row }) => <DateFormatter date={row.original.processTime} />
        },
        {
          Header: "Value",
          accessor: "attributes.value"
        },
        {
          Header: "Unit",
          accessor: "attributes.unit"
        }
      ]
    }
  ]);

  const cv1VolumeColumns = React.useMemo(() => [
    {
      Header: "Volume Data",
      columns: [
        {
          Header: "Event Time",
          accessor: "processTime",
          Cell: ({ row }) => <DateFormatter date={row.original.processTime} />
        },
        {
          Header: "Value",
          accessor: "attributes.value"
        },
        {
          Header: "Unit",
          accessor: "attributes.unit"
        }
      ]
    }
  ]);

  const cv1ReceivedColumns = React.useMemo(() => [
    {
      Header: "Received Data",
      columns: [
        {
          Header: "Event Time",
          accessor: "processTime",
          Cell: ({ row }) => <DateFormatter date={row.original.processTime} />
        },
        {
          Header: "Event Name",
          accessor: "attributes.eventName"
        },
        {
          Header: "Value",
          accessor: "attributes.value"
        },
        {
          Header: "Unit",
          accessor: "attributes.unit"
        }
      ]
    }
  ]);

  const cv1EventColumns = React.useMemo(() => [
    {
      Header: "Event Data",
      columns: [
        {
          Header: "Event Time",
          accessor: "processTime",
          Cell: ({ row }) => <DateFormatter date={row.original.processTime} />
        },
        {
          Header: "Event Name",
          accessor: "attributes.eventName"
        },
        {
          Header: "Value",
          accessor: "attributes.value"
        },
        {
          Header: "Unit",
          accessor: "attributes.unit"
        }
      ]
    }
  ]);
  const volsensColumns = React.useMemo(() => [
    {
      Header: t("raw_Data_Volsens_Event_Time"),
      accessor: "processTime",
      Cell: ({ row }) => <DateFormatter date={row.original.processTime} />
    },
    {
      Header: t("raw_Data_Volsens_Volume"),
      accessor: "attributes.volume.value"
    },
    {
      Header: t("raw_Data_Volsens_Unit"),
      accessor: "attributes.volume.unit"
    }
  ]);

  // const tempsensColumns = temperature && temperature.length > 0 ? React.useMemo(() => [
  //   {
  //     Header: t("raw_Data_Tempsens_Event_Time"),
  //     accessor: "processTime",
  //     Cell: ({ row }) => <DateFormatter date={row.original.processTime} />
  //   },
  //   {
  //     Header: temperature[0].tempStatus,
  //     accessor: "attributes.temperature.values["+temperature[0].temperature+"]",
  //     // Cell: ({ row }) => row.original.attributes.temperature.values[temperature[0].temperature]

  //   },
  //   {
  //     Header: temperature[1].tempStatus,
  //     accessor: "attributes.temperature.values["+temperature[1].temperature+"]",
  //     // Cell: ({ row }) => row.original.attributes.temperature.values[temperature[1].temperature]
  //   }
  // ]):null;

  function handleTempColumns() {
    let cols = [];

    console.log("tempereature ", temperature);
    var header = {
      Header: t("raw_Data_Tempsens_Event_Time"),
      accessor: "processTime",
      Cell: ({ row }) => <DateFormatter date={row.original.processTime} />
    };
    cols.push(header);
    if (temperature[0]) {
      header = {
        Header: temperature[0].tempStatus,
        accessor:
          "attributes.temperature.values[" + temperature[0].temperature + "]"
      };
      cols.push(header);
    }

    if (temperature[1]) {
      header = {
        Header: temperature[1].tempStatus,
        accessor:
          "attributes.temperature.values[" + temperature[1].temperature + "]"
      };
      cols.push(header);
    }

    if (temperature[2]) {
      header = {
        Header: temperature[2].tempStatus,
        accessor:
          "attributes.temperature.values[" + temperature[2].temperature + "]"
      };
      cols.push(header);
    }

    if (temperature[3]) {
      header = {
        Header: temperature[3].tempStatus,
        accessor:
          "attributes.temperature.values[" + temperature[3].temperature + "]"
      };
      cols.push(header);
    }

    return cols;
  }

  const tempsensColumns =
    temperature && temperature.length > 0
      ? React.useMemo(() => handleTempColumns())
      : null;

  const powsensColumns = React.useMemo(() => [
    {
      Header: " ",
      columns: [
        {
          Header: t("raw_Data_Powsens_Event_Time"),
          accessor: "processTime",
          Cell: ({ row }) => <DateFormatter date={row.original.processTime} />
        }
      ]
    },
    {
      Header: " ",
      columns: [
        {
          Header: t("raw_Data_Powsens_Phase"),
          accessor: "attributes.numberOfPhases"
        }
      ]
    },
    {
      Header: " ",
      columns: [
        {
          Header: t("raw_Data_Powsens_Total_Energy"),
          accessor: "attributes.totalEnergy"
        }
      ]
    },
    {
      Header: " ",
      columns: [
        {
          Header: t("raw_Data_Powsens_Phase_Color"),
          accessor: "attributes.phase[0].phaseColor"
        }
      ]
    },
    {
      Header: t("raw_Data_Powsens_Current"),
      columns: [
        {
          Header: t("raw_Data_Powsens_Current_Value"),
          accessor: "attributes.phase[0].current.value"
        },
        {
          Header: t("raw_Data_Powses_Current_Unit"),
          accessor: "attributes.phase[0].current.unit"
        }
      ]
    },
    {
      Header: t("raw_Data_Powsens_Volt"),
      columns: [
        {
          Header: t("raw_Data_Powsens_Volt_Value"),
          accessor: "attributes.phase[0].volt.value"
        },
        {
          Header: t("raw_Data_Powsens_Volt_Unit"),
          accessor: "attributes.phase[0].volt.unit"
        }
      ]
    },
    {
      Header: t("raw_Data_Powsens_Power"),
      columns: [
        {
          Header: t("raw_Data_Powsens_Value"),
          accessor: "attributes.phase[0].power.value"
        },
        {
          Header: t("raw_Data_Powsens_Unit"),
          accessor: "attributes.phase[0].power.unit"
        }
      ]
    },
    {
      Header: " ",
      columns: [
        {
          Header: t("raw_Data_Powsens_Energy"),
          accessor: "attributes.phase[0].energy"
        }
      ]
    }
  ]);

  const feedsensColumns =
    feedback && feedback.length > 0
      ? React.useMemo(() =>
          [
            {
              Header: t("raw_Data_Feedsens_Event_Time"),
              accessor: "processTime",
              Cell: ({ row }) => (
                <DateFormatter date={row.original.processTime} />
              )
            }
          ].concat(
            feedback.map(f => {
              return {
                Header: f.feedbackStatus,
                accessor: "attributes.states[" + f.feedback + "]"
              };
            })
          )
        )
      : null;

  const nextPage = () => {
    if (
      status !== "loading" &&
      pageInfo.hasNextPage &&
      pageIndex < pageInfo.totalPages
    ) {
      let index = pageIndex;
      setPageIndex(++index);
    }
  };
  const previousPage = () => {
    let index = pageIndex;
    if (index > 0) {
      setPageIndex(--index);
    }
  };

  useEffect(() => {
    setStatus("loading");
    deviceDataService
      .getRawData(
        deviceType,
        deviceId,
        new Date(startDate).toISOString(),
        new Date(endDate).toISOString(),
        pageIndex,
        pageSize,
        eventName
      )
      .then(data => {
        if (data.content) {
          setRecord(data.content[0].attributeList);
          // setDeviceType(data.content[0].deviceType);
          setPageInfo(data.pageInfo);
        }
        setStatus("success");
      });
    // }
  }, [pageIndex, pageSize]);

  return (
    <Grid container>
      <TemplateForTables>
        <Grid
          container
          xs={12}
          justify="center"
          alignItems="center"
          style={{ height: "5vh", marginBottom: "20px" }}
        >
          <Grid item style={{ fontWeight: "bold" }}>
            <div>
              {t("raw_Data_Device_Id")} : {deviceId}
            </div>

            <div>
              {t("raw_Data_Serial_Number")} : {serialNumber}
            </div>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "30px" }}>
          {status === "loading" ? (
            <Grid
              container
              xs={12}
              justify="center"
              alignItems="center"
              style={{ height: "50vh" }}
            >
              <CircularProgress />
            </Grid>
          ) : record.length > 0 &&
            status === "success" &&
            (deviceType === "indsens" || deviceType === "indsensABC") ? (
            <Table
              columns={indsensColumns}
              data={record}
              needDropdown={false}
              extraHeaders={true}
              fileName={"indsensData.xls"}
              deviceType={deviceType}
              deviceId={deviceId}
              startDate={startDate}
              endDate={endDate}
              paginationInfo={{
                pageIndex,
                nextPage,
                previousPage,
                pageSize,
                setPageSize,
                pageInfo
              }}
            />
          ) : record.length > 0 &&
            status === "success" &&
            deviceType === "weighsens" ? (
            <Table
              columns={weighsensColumns}
              data={record}
              needDropdown={false}
              extraHeaders={false}
              fileName={"weighsensData.xls"}
              deviceType={deviceType}
              deviceId={deviceId}
              startDate={startDate}
              endDate={endDate}
              paginationInfo={{
                pageIndex,
                nextPage,
                previousPage,
                pageSize,
                setPageSize,
                pageInfo
              }}
            />
          ) : record.length > 0 &&
            status === "success" &&
            deviceType === "CONTRAK_V1" &&
            eventName === "VOLUME" ? (
            <Table
              columns={cv1VolumeColumns}
              data={record}
              needDropdown={false}
              extraHeaders={true}
              fileName={"contrakv1.xls"}
              deviceType={"CONTRAK_V1"}
              deviceId={deviceId}
              startDate={startDate}
              endDate={endDate}
              eventName={eventName}
              paginationInfo={{
                pageIndex,
                nextPage,
                previousPage,
                pageSize,
                setPageSize,
                pageInfo
              }}
            />
          ) : record.length > 0 &&
            status === "success" &&
            deviceType === "CONTRAK_V1" &&
            eventName === "TEMP" ? (
            <Table
              columns={cv1TempColumns}
              data={record}
              needDropdown={false}
              extraHeaders={true}
              fileName={"contrakv1.xls"}
              deviceType={"CONTRAK_V1"}
              deviceId={deviceId}
              startDate={startDate}
              endDate={endDate}
              eventName={eventName}
              paginationInfo={{
                pageIndex,
                nextPage,
                previousPage,
                pageSize,
                setPageSize,
                pageInfo
              }}
            />
          ) : record.length > 0 &&
            status === "success" &&
            deviceType === "CONTRAK_V1" &&
            eventName === "RECEIVEDData" ? (
            <Table
              columns={cv1ReceivedColumns}
              data={record}
              needDropdown={false}
              extraHeaders={true}
              fileName={"contrakv1.xls"}
              deviceType={"CONTRAK_V1"}
              deviceId={deviceId}
              startDate={startDate}
              endDate={endDate}
              eventName={eventName}
              paginationInfo={{
                pageIndex,
                nextPage,
                previousPage,
                pageSize,
                setPageSize,
                pageInfo
              }}
            />
          ) : record.length > 0 &&
            status === "success" &&
            deviceType === "CONTRAK_V1" &&
            eventName === "VOLUME,TEMP" ? (
            <Table
              columns={cv1EventColumns}
              data={record}
              needDropdown={false}
              extraHeaders={true}
              fileName={"contrakv1.xls"}
              deviceType={"CONTRAK_V1"}
              deviceId={deviceId}
              startDate={startDate}
              endDate={endDate}
              eventName={eventName}
              paginationInfo={{
                pageIndex,
                nextPage,
                previousPage,
                pageSize,
                setPageSize,
                pageInfo
              }}
            />
          ) : record.length > 0 &&
            status === "success" &&
            deviceType === "tempsens" ? (
            <Table
              columns={tempsensColumns}
              data={record}
              needDropdown={false}
              extraHeaders={false}
              fileName={"tempsensData.xls"}
              deviceType={deviceType}
              deviceId={deviceId}
              startDate={startDate}
              endDate={endDate}
              paginationInfo={{
                pageIndex,
                nextPage,
                previousPage,
                pageSize,
                setPageSize,
                pageInfo
              }}
            />
          ) : record.length > 0 &&
            status === "success" &&
            deviceType === "volsens" ? (
            <Table
              columns={volsensColumns}
              data={record}
              needDropdown={false}
              extraHeaders={false}
              fileName={"volsensData.xls"}
              deviceType={deviceType}
              deviceId={deviceId}
              startDate={startDate}
              endDate={endDate}
              paginationInfo={{
                pageIndex,
                nextPage,
                previousPage,
                pageSize,
                setPageSize,
                pageInfo
              }}
            />
          ) : record.length > 0 &&
            status === "success" &&
            deviceType === "powsens" ? (
            <Table
              columns={powsensColumns}
              data={record}
              needDropdown={false}
              extraHeaders={true}
              fileName={"powsensData.xls"}
              deviceType={deviceType}
              deviceId={deviceId}
              startDate={startDate}
              endDate={endDate}
              paginationInfo={{
                pageIndex,
                nextPage,
                previousPage,
                pageSize,
                setPageSize,
                pageInfo
              }}
            />
          ) : record.length > 0 &&
            status === "success" &&
            deviceType === "feedsens" ? (
            <Table
              columns={feedsensColumns}
              data={record}
              needDropdown={false}
              extraHeaders={false}
              fileName={"feedsensData.xls"}
              deviceType={deviceType}
              deviceId={deviceId}
              startDate={startDate}
              endDate={endDate}
              paginationInfo={{
                pageIndex,
                nextPage,
                previousPage,
                pageSize,
                setPageSize,
                pageInfo
              }}
            />
          ) : (
            <Grid
              container
              xs={12}
              justify="center"
              alignItems="center"
              style={{ height: "5vh" }}
            >
              <div> {t("raw_Data_No_Data")} </div>
            </Grid>
          )}
        </Grid>
      </TemplateForTables>
    </Grid>
  );
}
