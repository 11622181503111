import React from "react";

import Grid from "@material-ui/core/Grid";

export default function Field({ label, value, greyLabel }) {
  return (
    <Grid
      item
      container
      justify="flex-start"
      style={{ padding: ".5em .5em .5em 0em", flexWrap: "nowrap" }}
      xs={12}
    >
      <Grid item xs={6} >
        <span
        style={
          greyLabel?
          {fontSize: "13px",color:"#97A4B4"
          }:{
            fontSize:"13px",
            color:"inherit"
          }
        }
          // style={
          //   (greyLabel===true
          //     ? { backgroundColor: "#ccc !important" }
          //     : { backgroundColor: "inherit" },
          //   {  })
          // }
        >
          {label}
        </span>
      </Grid>

      <Grid item xs={6} md={8}>
        <span style={{ color: "#148ae4", fontSize: "13px" }}>
          {value}
        </span>
      </Grid>
    </Grid>
  );
}
