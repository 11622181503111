import React, { useState, useContext, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import PageHeader from "components/layout/PageHeader";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
import CommonForm from "components/common/form/common-form";
import FailureFeedback from "components/common/elements/FailureFeedback";
import PincodeSelection from "components/common/form/PincodeSelection";
import OrganizationSelection from "components/common/form/OrganizationSelection";
import { provisioningService } from "services/provisioningServices";
import { customeGroupService } from "services/customerGroupServices";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import "components/responsive.scss";
import "../configuration.scss";
import "../../../components/responsive.scss";

import { useTranslation } from "react-i18next";
import { string } from "prop-types";
import addDays from "date-fns/addDays"
import { errorContext } from "context/errorContext";
import { ReactDOM } from "react-dom";
import ImageComponent from "components/ImageComponent";
import BannerLanguageList from "components/common/form/BannerLanguageList";

import BannerExtraData from "components/common/form/BannerExtraData";
import { bannerService } from "services/bannerService";

export default function BannerForm({
  values,
  appType,
  //orgData,  
  handleSubmit,
  page,
  language,
  urlError,
  allDropDownDataBanner,
  actionType  

}) {
  const loadedPage = page;
  const { t } = useTranslation();
  const formikForm = React.useRef(null);
  const { dispatch: errorDispatch } = useContext(errorContext);

  //const createError = useSelector(state => state.site.toJS().createError);

  const [postOfficeData, setPostOfficeData] = React.useState([]);
  const [districtData, setDistrictData] = React.useState([]);
  const [talukData, setTalukData] = React.useState([]);
  const [villageData, setVillageData] = React.useState([]);
  const [fetchTagOnPbp, setFetchTagOnPbp] = React.useState([]);
  const [vlccData, setVlccData] = React.useState([]);
  const [chillingCenterData, setChillingCenterData] = React.useState([]);
  const [selectionError, setSelectionError] = useState("");
  const [customerGroupData, setCustomerGroupData] = React.useState([]);

  const userType = [
    { name: "Dairy Farmer", value: "DAIRY_FARMER" },
    { name: "On Boarded Farmer", value: "SELF_REGISTERED" }];
  
  // const appType = [
  //   { name: "smart_farms", value: "smart_farms" },
  //   { name: "MOORETAIL", value: "MOORETAIL" }];

  const [farmerAssociateStatus, setFarmerAssociateStatus] = useState("loading");

  const [isGeogSelection, setIsGeogSelection] = React.useState(false);
  const [isPincodeSelection, setIsPinCodeSelection] = React.useState(false);
  const [isOrgSelection, setIsOrgSelection] = React.useState(false);
  const [isToggleSelection, setIsToggleSelection] = React.useState(false);
  const [toggle, setToggle] = React.useState(false);

  const [startDate,setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [actionTypeReplica, setactionTypeReplica] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState();
  const [error, setError] = useState('');  

  let selectedProdCode;
  const handleactionType = value => {
    selectedProdCode = value;

    if (selectedProdCode!==null) {
      setError('');     
    } 

    console.log("selectedProdCode" + selectedProdCode);
    setSelectedProduct(value);

    if (allDropDownDataBanner[selectedProdCode]) {
      setApp_Action(allDropDownDataBanner[selectedProdCode]);
    }
    
  };

  const getExtraDataKey = value => {
    console.log("selectedProduct" + selectedProduct);

    if (selectedProduct===undefined||null) {
      setError('Please select the App Type before choosing the Action Type.');
    } else {
      setError('');
    }    
   
    // let selectedactionType =
    //   "EXTRA_DATA_APP_ACTION_SF_" + selectedProduct + "_" + value;    

      let selectedactionType =
      "EXTRA_DATA_APP_ACTION_SF_" + value;

    values.extraDataList[0].subKey = "";
    //if (selectedProduct!==undefined||null) {
      if (allDropDownDataBanner[selectedactionType]) {
          values.extraDataList[0].subKey = allDropDownDataBanner[selectedactionType][0];
         }
       //}
  };

  function getCustomerGroupData() {
    customeGroupService.getAllCustomerGroup().then(data => {
      setCustomerGroupData(data.filter(data => {
        return data.sourceType === "smartfarms_app";
      }));
    }).catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });
  }

  const [stateByPinCode, setStateByPinCode] = React.useState([]);
  const [districtByPinCode, setDistrictByPinCode] = React.useState([]);
  const [statusMessage, setStatusMessage] = React.useState("");
  const [failedMessage, setFailedMessage] = React.useState("");

  const [app_Type,setApp_Type]=useState("");
  const [app_Action,setApp_Action]=useState("");

  // useEffect(() => {
  //   setactionTypeReplica(actionType);
  // });

  // Bellow code is working but new implimentation done so commented
  /* let appTYPE = "APP_TYPE";
  let appACTION = "APP_ACTION_SF";

  const [app_Type,setApp_Type]=useState("");
  const [app_Action,setApp_Action]=useState("");
  
  useEffect(() => {
    bannerService.getBannerOptions(appTYPE).then(data=>{      
      setApp_Type(data);
    })
  },[]);
  console.log("app_Type==============================",app_Type);

  useEffect(() => {
    bannerService.getBannerOptions(appACTION).then(data=>{      
      setApp_Action(data);
    })
  },[]);
  console.log("app_Action==============================",app_Action); */    

  useEffect(() => {
    bannerService.getBannerOptions('APP_TYPE').then(data => {
      if (data) {
        setApp_Type(data);
      }
    })
  },[]);

  useEffect(() => {
    bannerService.getBannerOptions('APP_ACTION_SF').then(data => {
      if (data) {
        setApp_Action(data);
      }
    })
  },[]);

  function getGeogByPinCode(value) {
    if (value.length === 6) {
      provisioningService.getGeographyByPincode(value).then(data => {
        setPostOfficeData(data.postOfficeDetails);
        setStateByPinCode(data.postOfficeDetails[0].name);
        values.villageByPinCode = data.postOfficeDetails[0].name;
      }).catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
    }
  }

  function getCCByOrganization(value) {
    provisioningService.getCCByOrg(value).then(data => {
      setChillingCenterData(data);
    }).catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });
  }

  function getVLCCByCC(value) {
    provisioningService.getVlccByOrg(value).then(data => {
      setVlccData(data);
    }).catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });
  }

  function toggleSelection(value) {
    values = {
      pincodeList: [],
      organizationBased: []
    };
    setSelectionError("");
    console.log("toggle selection", value);
    if (value === "DAIRY_FARMER") {
      setIsOrgSelection(true);
      setIsGeogSelection(false);
      setIsPinCodeSelection(false);
      setIsToggleSelection(false);
    } else if (value === "SELF_REGISTERED") {
      setIsPinCodeSelection(true);
      setIsOrgSelection(false);
      setIsToggleSelection(true);
      setToggle(false);
    } else if (value === "BOTH") {
      setIsGeogSelection(true);
      setIsOrgSelection(true);
      setIsPinCodeSelection(true);
      setIsToggleSelection(false);
    }
  }

  const handleToggle = (event) => {
    if (toggle == true) {
      setIsGeogSelection(false);
      setIsPinCodeSelection(true);
    } else {
      setIsGeogSelection(true);
      setIsPinCodeSelection(false);
    }
    setToggle(!toggle);

    values[event.target.name] = !values[event.target.name]
    console.log("Toggle: ", values[event.target.name]);
    console.log("Toggle: ", event.target.name);
  }

  const dispatch = useDispatch();
  const history = useHistory();
  const handleDialogClose = () => {
    setFarmerAssociateStatus("Loading");
  };

  let validations = {
    name: Yup.string().required(t("banner_Form_Validation_Name")),
    appType: Yup.string().required(t("banner_Form_Validation_appType")),   
    sortOrder: Yup.string().required(t("banner_Form_Validation_SortOrder"))                        
      .matches(/^[0-9]+$/, "Must be only digits"),
    actionType: Yup.string().required(t("banner_Form_Validation_ActionType")),
    fetchTag: Yup.string().required(t("banner_Form_Validation_fetchTag")),
    languageList: Yup.array().of(
      Yup.object().shape({
      url: Yup.string().required(t("banner_Form_Validation_Url")),
    language: Yup.string().required(t("banner_Form_Validation_Language")),
    })),
  };

  const handleStartDateChange = (startDate, setFieldValue, values) => {
    setStartDate(startDate);
    setFieldValue('startDate', startDate);
    if (startDate > values.endDate) {
      setFieldValue('endDate', startDate);
    }
  };  

  const fields = [
    {
      type: "text",
      name: "name",
      label: t("banner_Name"),
      placeholder: t("banner_Name"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "select",
      name: "appType",      
      label: t("banner_Form_appType_Label"),
      placeholder: t("banner_Form_appType_Label"),
      option:
      app_Type.length > 0
          ? app_Type.map(app_Type => ({
              value: app_Type,
              label: app_Type
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      },      
      customOnChange: handleactionType
    },
    {
      type: "datePicker",
      name: "startDate",
      label: t("banner_Form_startDate_Label"),
      placeholder: t("banner_Form_startDate_Label"),
      value: { startDate },
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      },
      customOnChange: handleStartDateChange
    },
    {
      type: "datePicker",
      name: "endDate",
      value:  {endDate} ,
      minDate: startDate > new Date() ? startDate : new Date(),
      label: t("banner_Form_endDate_Label"),
      placeholder: t("banner_Form_endDate_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "sortOrder",
      label: t("banner_Form_sortOrder_Label"),
      placeholder: t("banner_Form_sortOrder_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },    
    {      
      type: "select",
      name: "actionType",      
      label: t("banner_Form_actionType_Label"),
      placeholder: t("banner_Form_actionType_Label"),     
      option:
        app_Action.length > 0
          ? app_Action.map(app_Action => ({
              value: app_Action,
              label: app_Action
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      },           
      customOnChange: getExtraDataKey
    },  

    {
      type: "text",
      name: "hlink",
      label: t("banner_Form_hlink_Label"),
      placeholder: t("banner_Form_hlink_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    }, 
    
    {
      type: "text",
      name: "fetchTag",
      label: t("banner_Form_fetchTag_Label"),
      placeholder: t("banner_Form_fetchTag_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },    

    {
      type: "custom",
      component: ({ values, errors }, renderFields) => (
        <BannerExtraData
          name="extraDataList"
          values={values}
          errors={errors}
          renderFields={renderFields}
          elemProp="Extra Data"
        />
      )
    },    

    {
      type: "custom",
      component: ({ values, errors }, setFieldValue, renderFields) => (
        <BannerLanguageList
          name="languageList"
          languageValues={language}
          setFieldValue={setFieldValue}
          values={values}
          errors={errors}
          renderFields={renderFields}
        />
      )
    },   

  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title="Banner"
          breadcrumbs={[
            { label: "Dashboard", link: "/dashboard" },
            { label: "Configuration" },
            { label: "Banner" }
          ]}
        />
      </Grid>

      <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%"
        }}
      >
        <Grid item>
          {loadedPage == "add" &&
            <CommonForm
              fields={fields}
              submitLabel={t("site_Form_Submit_Label")}
              submittingLabel={t("site_Form_Submitting_Label")}
              initialValues={values}
              validationSchema={Yup.object().shape({ ...validations })}
              validateOnBlur={true}
              enableReinitialize
              validateOnChange={true}              
              onSubmit={(values, { resetForm, setSubmitting }) => {
                handleSubmit(values, resetForm, setSubmitting);
              }}

              formikRef={formikForm}
              buttonSize="3"
              buttonPosition="right"
            />
          }
        </Grid>
        {/* ):null} */}
        {urlError && (
          <div style={{ color: "#f44336" }}>{urlError}</div>
        )}

        {selectionError && (
          <div style={{ color: "#f44336" }}>{selectionError}</div>
        )}

        {error && 
        <div style={{ color: 'red', whiteSpace: 'nowrap', marginLeft: "260px", marginTop: "-30px" }}>{error}</div>}

      </Paper>

      <SuccessFeedback
        open={farmerAssociateStatus === "success"}
        onClose={handleDialogClose}
        successMessage={statusMessage}
        createAnotherMesssage={statusMessage}
      />
      <FailureFeedback
        open={farmerAssociateStatus === "failed"}
        onClose={handleDialogClose}
        status={"Failed"}
        message={failedMessage}
        showButtons={false}
      />
    </Grid>

  );
}
