import React, { useState } from "react";
import { FieldArray, Field, getIn } from "formik";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { TextField } from "formik-material-ui";
import { useTranslation } from "react-i18next";
import ImageComponent from "components/ImageComponent";
import BrowseButtonArticleDoc from "components/BrowseButtonArticleDoc";

export default function ArticleLanguageList({
  name,
  languageValues,
  setFieldValue,
  values,
  renderFields,
  toggle,
  elemProp,
  hierarchyType
  
}) {
  const [imageUrlLang, setImageUrlLang] = useState("");
  const [urlError, setUrlError] = useState("");

  //const [ hierarchyType, setHierarchyType] = useState("");

  const handleTypeChange = () => {
    // Handle type change logic here
  };

  const getPictureEng = value => {
    setImageUrlLang(value);
    // if (values.languageList[0].language === 'ENGLISH') values.en = value;
    // else if (values.languageList[1].language === 'KANNADA') values.kn = value;
    // else if (values.languageList[2].language === 'HINDI') values.hi = value;
    // else if (values.languageList[3].language === 'MARATI') values.mr = value;
    // else if (values.languageList[4].language === 'TELUGU') values.te = value;
    // else if (values.languageList[5].language === 'TAMIL') values.ta = value;

    if (values.languageList[0].language === 'HINDI') values.hi = value;
    else if (values.languageList[1].language === 'ENGLISH') values.en = value;
    else if (values.languageList[2].language === 'KANNADA') values.kn = value;
    else if (values.languageList[3].language === 'MARATI') values.mr = value;
    else if (values.languageList[4].language === 'TELUGU') values.te = value;
    else if (values.languageList[5].language === 'TAMIL') values.ta = value;

    setFieldValue(values);
  };

  const handleSetValue = (name,childIndex) => {
    console.log('valuesfieldssss')
    setFieldValue(`${name}.${childIndex}.url`, '');
    
  }

  const { t } = useTranslation();
  values.isActive = toggle;
  console.log('valuesfield',values)
  return (
    <FieldArray name={name}>
      {({ insert, remove, push, form }) => {
        let {
          value,
          errors,
          touched,
          setFieldTouched,
          setFieldValue,
          handleChange,
          isSubmitting
        } = form;

        return (
          <>
            {getIn(values, name).length > 0 &&
              getIn(values, name).map((languageList, childIndex) => {
                const isTouched = getIn(touched, `${name}.${childIndex}.url`);
                if (isSubmitting && !isTouched) {
                  setFieldTouched(`${name}.${childIndex}.url`, true);
                  setFieldTouched(`${name}.${childIndex}.language`, true);
                  setFieldTouched(`${name}.${childIndex}.name`, true);
                }

                return (
                  <Grid
                    container
                    item
                    xs={12}
                    md={12}
                    className="mb-0 d-flex flex-direction-column device-wrapper"
                    key={childIndex}
                    spacing={2}
                  >
                    <Grid
                      item
                      container
                      xs={12}
                      md={9} // Increased width for the main content
                      className="site-inner"
                      spacing={2}
                    >
                      <Grid item xs={12} md={3}>
                        <Field
                          select
                          name={`${name}.${childIndex}.language`}
                          label={t("articleLanguageList_Form_language_Label")}
                          component={TextField}
                          margin="dense"
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            onChange: e => {
                              handleChange(e);
                              handleTypeChange(
                                e.target.value,
                                childIndex,
                                setFieldValue,
                                setFieldTouched,
                                values
                              );
                            }
                          }}
                        >
                          <MenuItem disabled value="none">
                            {t("Choose")}
                          </MenuItem>
                          {languageValues.map(value => (
                            <MenuItem key={value} value={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <Field
                          name={`${name}.${childIndex}.name`}
                          label={t("articleLanguageList_Form_name_Label")}
                          component={TextField}
                          margin="dense"
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            onChange: e => {
                              handleChange(e);
                              handleTypeChange(
                                e.target.value,
                                childIndex,
                                setFieldValue,
                                setFieldTouched,
                                values
                              );
                            }
                          }}
                        >
                          <MenuItem disabled value="none">
                            {t("Choose")}
                          </MenuItem>
                          {languageValues.map(value => (
                            <MenuItem key={value} value={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>

                      <Grid item xs={12} md={6}>
                      {(hierarchyType !== "MCategory" && hierarchyType !== "SCategory")  ? (
                        <Field
                          name={`${name}.${childIndex}.url`}
                          label={t("articleLanguageList_Form_Url_Label")}
                          variant="outlined"
                          placeholder={t("articleLanguageList_Form_Url_Label")}
                          component={TextField}
                          value={getIn(values, `${name}.${childIndex}.url`)}
                          margin="dense"
                          fullWidth
                          size="large"
                          multiline
                          minRows={1}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{
                            onChange: e => {
                              getPictureEng(e.target.value);
                            }
                          }}
                        />
                      ):null}
                      </Grid>

                      {getIn(values, `${name}.${childIndex}.url`) && (
                        <Grid item xs={12} md={2} container justifyContent="center">
                          {/* <ImageComponent
                            name="imageEng"
                            values={getIn(values, `${name}.${childIndex}.url`)}
                            errors={errors}
                            rendertypeFields={renderFields}
                          /> */}
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={1} md={1} alignItems="left" style={{marginRight:"5px",marginTop:"-15px" }}>
                      {hierarchyType === "MCategory" || hierarchyType === "SCategory" ?
                      values.languageList[childIndex].url='' :
                      (
                        <BrowseButtonArticleDoc                               
                        handleMyClick={imagePath => {
                          //values.isActive = toggle;
                          setFieldValue(`${name}.${childIndex}.url`, imagePath);
                          setUrlError("");
                        }}
                      />
                      )}
                    </Grid>

                    <Grid item container spacing={1} xs={12} md={2} style={{marginRight:"10px"}}>
                      {getIn(values, name).length > 0 &&
                        childIndex === getIn(values, name).length - 1 && (
                          <Grid item style={{ marginTop: "5px", marginRight:"-30px" }}>
                            <button
                              type="button"
                              onClick={() => {                                
                                setFieldTouched(`${name}.${childIndex}.url`, true);                                
                                setFieldTouched(`${name}.${childIndex}.language`, true);
                                setFieldTouched(`${name}.${childIndex}.name`, true);
                                if (
                                  !getIn(errors, `${name}.${childIndex}`) &&
                                  getIn(touched, `${name}.${childIndex}`)
                                ) {
                                  push({
                                    url: "",
                                    language: "",
                                    name: ""
                                  });
                                } else {
                                  Object.keys(
                                    getIn(values, `${name}.${childIndex}`)
                                  ).forEach(item => {
                                    item !== "subTaskValue" &&
                                      setFieldTouched(
                                        `${name}.${childIndex}.${item}`,
                                        true,
                                        true
                                      );
                                    if (
                                      getIn(
                                        errors,
                                        `${name}.${childIndex}.${item}`
                                      )
                                    ) {
                                      item !== "subTaskValue" &&
                                        setFieldTouched(
                                          `${name}.${childIndex}.${item}`,
                                          true,
                                          true
                                        );
                                    }
                                  });
                                }
                              }}
                              className="add-icon"
                              style={{ marginLeft:'100px'}}
                            >
                              <i className="fa fa-plus-circle" aria-hidden="true"></i>
                            </button>
                          </Grid>
                        )}                      

                      {getIn(values, name).length > 1 && (
                        <Grid item style={{ marginTop: "10px" }}>
                          <button
                            type="button"
                            className="add-icon delete-icon"
                            style={{ marginLeft: '140px', marginTop:"-45px" }}
                            onClick={() => {
                              getIn(values, name).length > 0 &&
                                remove(childIndex);
                            }}
                          >
                            <i className="fa fa-minus-circle" aria-hidden="true"></i>
                          </button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            {urlError && (
              <div style={{ color: "#f44336", textAlign: "center" }}>{urlError}</div>
            )}
          </>
        );
      }}
    </FieldArray>
  );
}