/* *****************************************************************************
 * *****************************************************************************
 * *******This is used as mobile version SiteWrapper**********************
 * *****************************************************************************
 * *****************************************************************************/

import React,{useState} from "react";
import { withTranslation } from "react-i18next";

import MobileSidebar from "./MobileSidebar";
import Appbar from "./Appbar";

import "../responsive.scss";

const changeLanguage = lang => {
  this.props.i18n.changeLanguage(lang);
};

const handleChange = e => {
  this.props.i18n.changeLanguage(e.target.value);
  this.setState({ langSelected: e.target.value });
};

function SiteWrapper(props) {
  let drawerWidth = 240;
  const [openDrawer, setopenDrawer] = useState(false);
  let handleClose = () => {
    setopenDrawer(!openDrawer);
  };
  return (
    <div>
      <Appbar
        isMobile={true}
        handleClose={handleClose}
        drawerWidth={drawerWidth}
        position="fixed"
      />
      <MobileSidebar open={openDrawer} handleClose={handleClose} />
      {props.children}
    </div>
  );
}

export default withTranslation()(SiteWrapper);
