import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  // root: {
  //   width: "100%"
  // },
  input: {
    width: 40
  },
  textInputStyle: {
    "& input": {
      padding: "1em"
    }
  },
  sliderColor: {
    color: "#166aab"
  }
});

export default function InputSlider(props) {
  const { onSliderChange, onLimitChange, sliderValue, defaultValue } = props;
  const classes = useStyles();

  const { t } = useTranslation();

  function valuetext(value) {
    return `${value}hours`;
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid
          item
          container
          justify="flex-end"
          alignItems="center"
          xs={1}
          style={{ marginBottom: "1.5em" }}
        >
          <span style={{ fontSize: "1em" }}>0</span>
        </Grid>
        <Grid item xs={9}>
          <Slider
            max={sliderValue}
            onChangeCommitted={onSliderChange}
            defaultValue={defaultValue}
            getAriaValueText={valuetext}
            aria-labelledby="input-slider"
            valueLabelDisplay="on"
            classes={{ colorPrimary: classes.sliderColor }}
          />
        </Grid>
        <Grid item xs={2} style={{ padding: ".5em 1em 0em 0em" }}>
          <TextField
            classes={{ root: classes.textInputStyle }}
            variant="outlined"
            notched={false}
            id=""
            value={sliderValue}
            onChange={onLimitChange}
          />
        </Grid>
      </Grid>
    </div>
  );
}
