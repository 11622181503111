import React, { useState, useContext, useMemo } from "react";
import { useParams, Link } from "react-router-dom";

import ToolTipCell from "components/common/table/ToolTipCell";
import format from "date-fns/format";
import { chillingUnitService } from "services/chillingUnitService.js";
import PageHeader from "components/layout/PageHeader";
import Table from "components/common/table/DynamicPaginationTable2";

import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

import { dashboardContext } from "context/dashboardContext";
import { errorContext } from "context/errorContext";

import "../../components/Table.scss";
import AlertsCard from "page-containers/Dashboard/components/AlertsCard";
import { useTranslation } from "react-i18next";
const AllAlerts = props => {
  let { state } = useParams();
  const [alertData, setData] = useState([]);
  const [alertsData, setAlertsData] = useState(null);
  const [status, setStatus] = React.useState("loading");

  const [pagingInfo, setPaging] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);
  const dashboardData = useContext(dashboardContext);
  const { t } = useTranslation();
  // const { hours, currentDate } = dashboardData.state;
  const { dispatch } = dashboardData;
  const { dispatch: errorDispatch } = useContext(errorContext);

  let query = useMemo(() => new URLSearchParams(props.location.search), [
    props.location
  ]);
  React.useEffect(() => {
    if (!alertsData) {
      chillingUnitService
        .getAlertStatusData(Number(query.get("hours")), query.get("date"))
        .then(data => {
          setAlertsData(data.alertsAggregate);
        })
        .catch(error => {
          errorDispatch({
            type: "ERROR",
            error
          });
        });
    }
  }, []);
  React.useEffect(() => {
    setHasNextPage(true); // if we reach last page and come to previous page
    setPageIndex(Number(query.get("page")));
    let otherValues = "";
    if (!!alertsData) {
      if (state === "Other Critical Alerts") {
        otherValues = Object.keys(alertsData.alertCountMap)
          .slice(0, 3)
          .join();
        otherValues = otherValues + ",Other Critical Alerts";
      }
      if (state === "All Alerts") {
        otherValues = "All Alerts";
      }
      setStatus("loading");
      chillingUnitService
        .getAlertsState(
          state,
          Number(query.get("hours")),
          query.get("date"),
          query.get("paging"),
          otherValues
        )
        .then(result => {
          if (result.content) {
            setData(result.content.RESULT);

            if (!!result.content.PAGING) {
              setPaging(result.content.PAGING);
            } else {
              setHasNextPage(false);
            }
          } else {
            setHasNextPage(false);
          }
          setStatus("success");
        })
        .catch(error =>
          errorDispatch({
            type: "ERROR",
            error
          })
        );
    }
  }, [query, alertsData, state]);

  const nextPage = () => {
    if (hasNextPage) {
      query.set("paging", pagingInfo);
      query.set("page", pageIndex + 1);
      props.history.push({
        path: props.location.pathname,
        search: query.toString()
      });
    }
  };

  const previousPage = () => {
    let index = pageIndex;
    if (index > 0) {
      props.history.goBack();
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("info_Alerts_Site_Name"),
        accessor: "siteName",
        Cell: ({ row }) => {
          return (
            <Link to={`/totalsites/site-details/${row.original.siteId}`}>
              <ToolTipCell>
                <span>{row.original.siteName}</span>
              </ToolTipCell>
            </Link>
          );
        }
      },
      {
        Header: t("info_Alerts_Bmc_Id"),
        accessor: "chillingUnitId",
        Cell: ({ row }) => (
          <ToolTipCell>{row.original.chillingUnitId}</ToolTipCell>
        )
      },
      {
        Header: t("info_Alerts_Type_Of_Alert"),
        accessor: "alertLevel",
        Cell: ({ row }) => <ToolTipCell>{row.original.alertLevel}</ToolTipCell>
      },
      {
        Header: t("info_Alerts_Message"),
        accessor: "alertMessage",
        Cell: ({ row }) => {
          return (
            <div
              style={{
                whiteSpace: "pre",
                textAlign: "left",
                paddingLeft: "1em"
              }}
            >
              {row.original.alertMessage}
            </div>
          );
        }
      }
    ],
    []
  );

  const data = React.useMemo(() => [...alertData]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={state === "All Alerts" ? "All Alerts" : t("total_Sites_Critical_Alerts")}
          breadcrumbs={[
            { label: t("info_Alerts_Breadcrum_Dashboard_Label"), link: "/dashboard" },
            { label: state === "All Alerts" ? "All Alerts" : t("total_Sites_Critical_Alerts") }
          ]}
        />
      </Grid>
      {status === "loading" ? (
        <Grid
          container
          xs={12}
          justify="center"
          alignItems="center"
          style={{ height: "50vh" }}
        >
          <CircularProgress />
        </Grid>
      ) : (
          <Table
            columns={columns}
            data={data}
            needDropDown={false}
            fileName={"alertsList.xls"}
          />
        )}
    </Grid>
  );
};

export default AllAlerts;
