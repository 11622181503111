function getToken() {
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const token = user && user.jwtAccessToken;
  return token;
}

async function getOrgTypes() {
  const url = `${process.env.REACT_APP_METADATA_ORG_TYPES}`;
  try {
    return getCall(url);
  } catch (error) {
    throw handleError(error);
  }
}

async function getSubOrgTypes() {
  const url = `${process.env.REACT_APP_METADATA_SUB_ORG_TYPES}`;
  try {
    return getCall(url);
  } catch (error) {
    throw handleError(error);
  }
}

async function getCountryCodes() {
  const url = `${process.env.REACT_APP_METADATA_COUNTRY_CODES}`;
  try {
    return getCall(url);
  } catch (error) {
    throw handleError(error);
  }
}

async function getGenderTypes() {
  const url = `${process.env.REACT_APP_METADATA_GENDER_TYPES}`;
  try {
    return getCall(url);
  } catch (error) {
    throw handleError(error);
  }
}

async function getProductTypes() {
  const url = `${process.env.REACT_APP_METADATA_PRODUCT_TYPES}`;
  try {
    return getCall(url);
  } catch (error) {
    throw handleError(error);
  }
}

async function getCountryNames() {
  const url = `${process.env.REACT_APP_METADATA_COUNTRIES}`;
  try {
    return getCall(url);
  } catch (error) {
    throw handleError(error);
  }
}

async function getStateNames(country) {
  const url = `${process.env.REACT_APP_METADATA_STATES}/${country}`;
  try {
    return getCall(url);
  } catch (error) {
    throw handleError(error);
  }
}

async function getDistrictNames(state) {
  const url = `${process.env.REACT_APP_METADATA_DISTRICTS}/${state}`;
  try {
    return getCall(url);
  } catch (error) {
    throw handleError(error);
  }
}

async function getMonitoringDeviceTypes() {
  const url = `${process.env.REACT_APP_METADATA_MONITORING_DEVICE_TYPES}`;
  try {
    return getCall(url);
  } catch (error) {
    throw handleError(error);
  }
}

async function getDeviceAttributes(deviceType) {
  const url = `${process.env.REACT_APP_METADATA_MONITORING_DEVICE_ATTRIBUTES}/${deviceType}`;
  try {
    return getCall(url);
  } catch (error) {
    throw handleError(error);
  }
}
async function getApplicationAttributes(deviceType) {
  const url = `${process.env.REACT_APP_METADATA_MONITORING_APPLICATION_ATTRIBUTES}/${deviceType}`;
  try {
    return getCall(url);
  } catch (error) {
    throw handleError(error);
  }
}
async function getUnitValueTypes(deviceType) {
  const url = `${process.env.REACT_APP_METADATA_MONITORING_UNITSVALUE_TYPES}/${deviceType}`;
  try {
    return getCall(url);
  } catch (error) {
    throw handleError(error);
  }
}

async function getPowerSenseTypes() {
  const url = `${process.env.REACT_APP_METADATA_POWER_SENSE_TYPES}`;
  try {
    return getCall(url);
  } catch (error) {
    throw handleError(error);
  }
}

async function getAlertTypes() {
  const url = `${process.env.REACT_APP_METADATA_ALERT_TYPES}`;
  try {
    return getCall(url);
  } catch (error) {
    throw handleError(error);
  }
}

async function getSalutationTypes() {
  const url = `/authgateway/provisioningService/metadata/salutationTypes`;

  try {
    return getCall(url);
  } catch (error) {
    throw handleError(error);
  }
}

async function getVendorTypes() {
  const url = `/authgateway/provisioningService/metadata/chillingunit/vendors`;
  try {
    return getCall(url);
  } catch (error) {
    throw handleError(error);
  }
}
async function getAlertLanguages() {
  const url = `/authgateway/provisioningService/metadata/user/alertLanguages`;
  try {
    return getCall(url);
  } catch (error) {
    throw handleError(error);
  }
}

async function getMethodOfCooling() {
	  const url = `/authgateway/provisioningService/metadata/methodOfCooling`;
	  try {
	    return getCall(url);
	  } catch (error) {
	    throw handleError(error);
	  }
	}

async function getCheckListValuesTypes() {
  const url = `/authgateway/provisioningService/metadata/checklist/checklistValueTypes`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken : localStorage.getItem("idToken")

    },
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true,
    };
    throw err;
  }
}

export const metadataService = {
  getOrgTypes,
  getCountryCodes,
  getProductTypes,
  getCountryNames,
  getStateNames,
  getDistrictNames,
  getMonitoringDeviceTypes,
  getPowerSenseTypes,
  getAlertTypes,
  getGenderTypes,
  getSubOrgTypes,
  getSalutationTypes,
  getVendorTypes,
  getAlertLanguages,
  getCheckListValuesTypes,
  getDeviceAttributes,
  getApplicationAttributes,
  getUnitValueTypes,
};

async function getCall(url) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      authToken : localStorage.getItem("idToken")
    },
  };
  const response = await fetch(url, requestOptions);
  if (response.ok) {
    return await response.json();
  } else {
    throw response;
  }
}

async function handleError(error) {
  let err = {
    status: error.status,
    error: !!error.body ? await error.json() : true,
  };
  throw err;
}

function getUrl(type, params) {
  switch (type) {
    case "orgTypes":
      return `${process.env.REACT_APP_METADATA_ORG_TYPES}`;
    case "countryCodes":
      return `${process.env.REACT_APP_METADATA_COUNTRY_CODES}`;
    case "productTypes":
      return `${process.env.REACT_APP_METADATA_PRODUCT_TYPES}`;
    case "countryNames":
      return `${process.env.REACT_APP_METADATA_COUNTRIES}`;
    case "stateNames":
      return `${process.env.REACT_APP_METADATA_STATES}/${params.country}`;
    case "districtNames":
      return `${process.env.REACT_APP_METADATA_DISTRICTS}/${params.state}`;
    case "monitoringDeviceTypes":
      return `${process.env.REACT_APP_METADATA_MONITORING_DEVICE_TYPES}`;
    case "powerSenseTypes":
      return `${process.env.REACT_APP_METADATA_POWER_SENSE_TYPES}`;
    case "alertTypes":
      return `${process.env.REACT_APP_METADATA_ALERT_TYPES}`;
    case "genderTypes":
      return `${process.env.REACT_APP_METADATA_GENDER_TYPES}`;
    case "subOrgTypes":
      return `${process.env.REACT_APP_METADATA_SUB_ORG_TYPES}`;
    case "SalutationTypes":
      return `/authgateway/provisioningService/metadata/salutationTypes`;
    case "vendorTypes":
      return `/authgateway/provisioningService/metadata/chillingunit/vendors`;
    case "alertLanguage":
      return `/authgateway/provisioningService/metadata/user/alertLanguages`;
    default:
      return "/";
  }
}

export default function metadataServices(type, params, options) {
  let url = getUrl(type, params);
  try {
    return getCall(url, options);
  } catch (error) {
    throw handleError(error);
  }
}
