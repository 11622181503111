import React from "react";
import CategoryTree from "components/common/elements/CategoryTree";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import PageHeader from "../../../components/layout/PageHeader.js";

export default function CategoryTreeDisplay() {
  
  const { t } = useTranslation();

  function handleClose(){

  }
  
  function handleCategoryClick(){

  }

  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={12}>
        <PageHeader
          title="Category"
          breadcrumbs={[
            { label: "Dashboard", link: "/dashboard" },
            { label: "Configuration" },
            { label: "Category" }
          ]}
        />
      </Grid>      
      <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%",
          height:"80vh"
        }}
      >
        <CategoryTree 
           handleCategoryClick={handleCategoryClick}
           handleClose={handleClose}
        />  
      </Paper>
    </Grid>
    //     <Grid item container direction="column" xs={12} md={6}>
    //         <Grid item>
    //             <h2>{t("org_Profile_Tree_View_Label")}</h2>
    //         </Grid>
    //         <Grid item style={{ height: "81%", width: "100%" }}>
    //           <CategoryTree 
    //             handleCategoryClick={handleCategoryClick}
    //             handleClose={handleClose}
    //         />  
    //         </Grid>
    //         </Grid>
    //         </Paper>
    //     </Grid>
       
    // </>
 )

}