import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { getIn } from "formik";
import PageHeader from "components/layout/PageHeader";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
import CommonForm from "components/common/form/common-form";
import FailureFeedback from "components/common/elements/FailureFeedback";
import NotificationExtraData from "components/common/form/NotificationExtraData";
import { notificationService } from "services/notificationServices";
import NotificationScheduler from "components/common/form/NotificationScheduler";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import "components/responsive.scss";
import "../configuration.scss";
// import "./Notification.scss";
import "../../../components/responsive.scss";
import CountryStateDistrictSelector from "components/common/form/CountryStateDistrictSelector";

import { useTranslation } from "react-i18next";
import { string } from "prop-types";
import addDays from "date-fns/addDays";

export default function CriteriaDairyFarmerForm({
  values,
  handleSubmit,
  editStatus,
  createError,
  edit,
  pbpData,
  pbpStatus
}) {
  
  const { t } = useTranslation();

  let breadcrumbs = [
    // {
    //   label: t("notification_Form_Breadcrum_Dashboard_Label"),
    //   link: "/dashboard"
    // },
    // { label: t("notification_Form_Breadcrum_Configuration_Label") },
    // { label: t("notification_Form_Breadcrum_Notification_Label") }
  ]; 

  console.log(pbpData);

  const [territoryData,setTerritoryData] = React.useState([]);
  const [isDisplaySubmitButton,setIsDisplaySubmitButton] = React.useState(false);

  useEffect(() => {
    const territory = pbpData.find(pbpData =>pbpData.id === values.pbpId);   
    if(territory != undefined){
      setTerritoryData(territory.territories)
    }
  }, [pbpData]);

  const formikForm = React.useRef(null);

  const fields = [
    {
      type: "text",
      name: "userType",
      label: t("Criteria_Form_User_Type_Label"),
      placeholder: t("Criteria_Form_User_Type_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    }, 
    {
      type: "select",
      name: "entityId",
      label: t("Criteria_Form_Territory_Name_Label"),
      placeholder: t("Criteria_Form_Territory_Name_Label"),
      // disabled:true,
      option:
        territoryData.length > 0
          ? territoryData.map(territoryData => ({
              value: territoryData.id,
              label: territoryData.name
           }))
        : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    }, 
    {
      type: "text",
      name: "customerGroup",
      label: t("Criteria_Form_Customer_Group_Label"),
      placeholder: t("Criteria_Form_Customer_Group_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },   
    {
      type: "textarea",
      name: "criteriaTags",
      label: t("Criteria_Form_Criteria_Tags_Label"),
      placeholder: t("Criteria_Form_Criteria_Tags_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "customHeader",
      name: "Organisation"
    },
    {
      type: "text",
      name: "organizationName",
      label: t("Criteria_Form_Organization_Name_Label"),
      placeholder: t("Criteria_Form_Organization_Name_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "chillingCenterName",
      label: t("Criteria_Form_Chilling_Center_Name_Label"),
      placeholder: t("Criteria_Form_Chilling_Center_Name_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    }
  ];

  let validations = {
    
  };

  const dispatch = useDispatch();
  const history = useHistory();

  function invokeOpenPopUp(){
    setIsDisplaySubmitButton(true);
    
  }
  
  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("Criteria_Form_Label")}
          breadcrumbs={breadcrumbs}
        />
      </Grid>
      <Grid container>
        <Paper elevation={2} style={{ padding: "2em" }}>
          {/* <Grid item>
            <h2>{t("Criteria_Form_Label")}</h2>
          </Grid> */}
          {/* <Grid container className="d-block d-md-flex ">
            <Grid item width={12} className="site-id-label">
              <label>
                {t("notification_Form_Title_Org_Label")} : <b>{org}</b>
              </label>
            </Grid>
          </Grid> */}
          <Grid item>
            <CommonForm
              fields={fields}
              submitLabel={t("site_Form_Submit_Label")}
              submittingLabel={t("site_Form_Submitting_Label")}
              initialValues={values}
              validationSchema={Yup.object().shape({ ...validations })}
              validateOnBlur={true}
              edit={edit}
              enableReinitialize
              validateOnChange={true}
              onSubmit={(
                values,
                { setSubmitting, setFieldError, resetForm, errors }
              ) => {
                if (!!errors) {
                  setSubmitting(false);
                }
                handleSubmit(values, resetForm, setSubmitting);
              }}
              formikRef={formikForm}
              buttonSize="3"
              buttonPosition="right"
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
