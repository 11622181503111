import React, { useState, useContext, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { ErrorMessage, getIn } from "formik";
import PageHeader from "components/layout/PageHeader";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
import CommonForm from "components/common/form/common-form";
import FailureFeedback from "components/common/elements/FailureFeedback";
import LoanProductName from "components/common/form/LoanProductName";
import { notificationService } from "services/notificationServices";
import GeographySelection from "components/common/form/GeographySelection";
import PincodeSelection from "components/common/form/PincodeSelection";
import OrganizationSelection from "components/common/form/OrganizationSelection";
import { provisioningService } from "services/provisioningServices";
import { farmerAssociationService } from "services/farmerAssociationServices";
import { customeGroupService } from "services/customerGroupServices";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import "components/responsive.scss";
import "../configuration.scss";
import "../../../components/responsive.scss";

import { useTranslation } from "react-i18next";
import { string } from "prop-types";
import addDays from "date-fns/addDays"
import { errorContext } from "context/errorContext";

import {
  FormControl,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Switch,
  Box,
  withStyles,
} from "@material-ui/core";

export default function FarmerAssociationForm({values,orgData,stateData,pbpData,status}){
  const { t } = useTranslation();
  const formikForm = React.useRef(null);
  const { dispatch: errorDispatch } = useContext(errorContext);

  //const createError = useSelector(state => state.site.toJS().createError);
  
  const [postOfficeData, setPostOfficeData] = React.useState([]);
  const [districtData , setDistrictData] = React.useState([]);
  const [talukData ,setTalukData] = React.useState([]);
  const [villageData, setVillageData] = React.useState([]);
  const [fetchTagOnPbp, setFetchTagOnPbp] = React.useState([]);
  const [vlccData, setVlccData] = React.useState([]);
  const [chillingCenterData,setChillingCenterData] = React.useState([]);
  const [selectionError,setSelectionError] = useState("");
  const [customerGroupData, setCustomerGroupData] = React.useState([]);

  const userType = [
    {name:"Dairy Farmer",value:"DAIRY_FARMER"},
    {name:"On Boarded Farmer",value:"SELF_REGISTERED"}];
    // ,
    // {name:"Both",value:"BOTH"}];
  const [farmerAssociateStatus, setFarmerAssociateStatus] = useState("loading");

  const [isGeogSelection,setIsGeogSelection] = React.useState(false);
  const [isPincodeSelection,setIsPinCodeSelection] = React.useState(false);
  const [isOrgSelection,setIsOrgSelection] = React.useState(false);
  const [isToggleSelection,setIsToggleSelection] = React.useState(false);
  const [toggle,setToggle] = React.useState(false);


  const loadFetchTagData = value => {
    setSelectionError("");
    const territory = pbpData.find(pbpData =>pbpData.id === value);
    if(territory != undefined){
      setFetchTagOnPbp(territory.territories)
    }
  }

  function loadFetchTag(){  
    const territory = pbpData.find(pbpData =>pbpData.id === 1);
    if(territory != undefined){
      setFetchTagOnPbp(territory.territories)
    }
  }

  function getCustomerGroupData(){
    customeGroupService.getAllCustomerGroup().then(data => {
      setCustomerGroupData(data.filter(data =>{
        return data.sourceType === "smartfarms_app";
      }));
    }).catch(error => {
       errorDispatch({
         type: "ERROR",
         error
       });
    });
  }

  useEffect(() => {
    loadFetchTag();
    getCustomerGroupData();
  }, [pbpData]);
 
  const [villageByPinCode,setVillageByPinCode] = React.useState([]);
  const [stateByPinCode,setStateByPinCode] = React.useState([]);
  const [districtByPinCode,setDistrictByPinCode] = React.useState([]);
  const [talukByPinCode,setTalukByPinCode] = React.useState([]);
  const [statusMessage,setStatusMessage] =  React.useState("");
  const [failedMessage,setFailedMessage] = React.useState("");

  function getGeogByPinCode(value){
    if(value.length === 6){
      provisioningService.getGeographyByPincode(value).then(data => {
        setPostOfficeData(data.postOfficeDetails);
        setStateByPinCode(data.postOfficeDetails[0].name);
        values.villageByPinCode = data.postOfficeDetails[0].name;
      }).catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
    }
  }

  function getCCByOrganization(value){
    provisioningService.getCCByOrg(value).then(data => {
      setChillingCenterData(data);
    }).catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
    });
  }

  function getVLCCByCC(value){
    provisioningService.getVlccByOrg(value).then(data => {
      setVlccData(data);
    }).catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
    });
  }

  function toggleSelection(value){
    values = {
      pincodeList:[],
      organizationBased:[]   
    }; 
    setSelectionError("");
    console.log("toggle selection",value);
    if(value === "DAIRY_FARMER"){
      setIsOrgSelection(true);
      setIsGeogSelection(false);
      setIsPinCodeSelection(false);
      setIsToggleSelection(false);
    }else if(value === "SELF_REGISTERED"){
      setIsPinCodeSelection(true);
      setIsOrgSelection(false);
      setIsToggleSelection(true);
      setToggle(false);
    }else if(value === "BOTH"){
      setIsGeogSelection(true);
      setIsOrgSelection(true);
      setIsPinCodeSelection(true);
      setIsToggleSelection(false);
    }
  }

  const handleToggle = (event) => {
    if(toggle == true){
      setIsGeogSelection(false);
      setIsPinCodeSelection(true);
    }else{
      setIsGeogSelection(true);
      setIsPinCodeSelection(false);
    }
    setToggle(!toggle);
    
    values[event.target.name] = !values[event.target.name]
    console.log("Toggle: ", values[event.target.name]);
    console.log("Toggle: ", event.target.name);
  }
  
  function handleSubmit(values, resetForm, setSubmitting){  
    
    if(typeof(values.customerGroup) === "string"){
      let tmpCustomerGroup = [];
      tmpCustomerGroup.push(values.customerGroup);
      values.customerGroup = tmpCustomerGroup;
    }

    values.entityType = "TERRITORY";
    values.criteria = {};
   
    values.criteria.pincodeBased = values.pincodeList;
    values.criteria.geoLocationBased = values.geoLocationBased;
    setSelectionError("");

    let orgManipulatedData = [];
    if(values.organizationBased.length > 0){
      for(var i=0;i<values.organizationBased.length;i++){
        for(var j=0;j<values.organizationBased[i].ccName.length;j++){
          let tempData = {
            organizationName:"",
            ccName:""
          };
          tempData.organizationName = values.organizationBased[i].organizationName;
          tempData.ccName = values.organizationBased[i].ccName[j];

          orgManipulatedData.push(tempData);

          console.log("orgManipulatedData",orgManipulatedData);
        }
      }      
    }

    values.criteria.organizationBased = orgManipulatedData;

    if(values.userType === "DAIRY_FARMER"){
      values.criteria.pincodeBased = [];
      if(values.criteria.organizationBased.length === 0){
        setSelectionError("Please select the Organization and Chilling Center by Clicking on Plus Symbol.");
        setSubmitting(false);
        return;
      }
    }else if(values.userType === "SELF_REGISTERED"){
      values.criteria.organizationBased = [];
      if(values.criteria.pincodeBased.length === 0){
        setSelectionError("Please select the Pincode by Clicking on Plus Symbol.");
        setSubmitting(false);
        return;
      }
    }
    // else if(values.userType === "BOTH"){
    //   if(values.criteria.organizationBased.length === 0 || values.criteria.pincodeBased.length === 0){
    //     setSelectionError("Please select the Organization and Pincode");
    //     setSubmitting(false);
    //     return;
    //   }
    // }

    delete values["geoLocationBased"];
    delete values["organizationBased"];
    delete values["pincodeList"];
    delete values["typeOffarmer"];

    farmerAssociationService.validationFarmerAssociateSelection(values).then(responseData => {
      console.log("responseData from API",responseData);
      if(responseData.status === "Success"){
        farmerAssociationService.associateFarmer(values).then(data => {
          setFarmerAssociateStatus("success");
          setStatusMessage(data.message);
          setSubmitting(false);
          resetForm();     
          console.log(values); 
        }).catch(error => {
          setFarmerAssociateStatus("failed");
          setFailedMessage(error);
          //  errorDispatch({
          //   type: "ERROR",
          //   error
          //  });
         
          setSubmitting(false);
          resetForm();
        });
      }else if(responseData.status === "Failure"){
        setFarmerAssociateStatus("failed");
        setFailedMessage(responseData.message);
        console.log(responseData);
        setSubmitting(false);
        resetForm();
      }        
    }).catch(error => {
      setFarmerAssociateStatus("failed");
      setFailedMessage(error);
      // errorDispatch({
      //   type: "ERROR",
      //   error
      // });
      setSubmitting(false);
      resetForm();
    });
  }

  const dispatch = useDispatch();
  const history = useHistory();
  const handleDialogClose = () => {
    setFarmerAssociateStatus("Loading"); 
  };

  let validations = {
    userType : Yup.string().required(t("Farmer_Association_Form_Validation_userType")),
    entityId : Yup.string().required(t("Farmer_Association_Form_Validation_territory")),
    customerGroup: Yup.string().required(t("Farmer_Association_Form_Validation_Customer_Group"))
  };

  const fields = [
    {
      type: "select",
      name: "pbpId",
      label: t("Farmer_Association_Form_pbp_Label"),
      placeholder: t("Farmer_Association_Form_pbp_Label"),
      option:pbpData.length > 0
        ? pbpData.map(pbpData => ({
            value: pbpData.id,
            label: pbpData.name
          }))
        : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      },
      customOnChange:loadFetchTagData
    },
    {
      type: "select",
      name: "userType",
      label: t("Farmer_Association_Form_Farmer_Type_Label"),
      placeholder: t("Farmer_Association_Form_Farmer_Type_Label"),
      option:userType.length > 0
        ? userType.map(userType => ({
            value: userType.value,
            label: userType.name
          }))
        : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      },
      customOnChange:toggleSelection
    },
    {
      type: "singleSelectSearch",
      name: "entityId",
      label: t("Farmer_Association_Form_Territory_Label"),
      placeholder: t("Farmer_Association_Form_Territory_Label"),
      option:fetchTagOnPbp.length > 0
        ? fetchTagOnPbp.map(fetchTagOnPbp => ({
            value: fetchTagOnPbp.id,
            label: fetchTagOnPbp.name
          }))
        : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "select",
      name: "customerGroup",
      label: t("Farmer_Association_Form_Customer_Group_Label"),
      placeholder: t("Farmer_Association_Form_Customer_Group_Label"),
      option:customerGroupData.length > 0
        ? customerGroupData.map(customerGroupData => ({
            value: customerGroupData.name,
            label: customerGroupData.name
          }))
        : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      },
    },
    // isToggleSelection?
    // {
    //   type: "custom",
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   },
    //   component: ({}) => (
    //     <FormControlLabel 
    //       control={
    //           <Switch checked={values.toggleSelection} onChange={handleToggle} name="toggleSelection" value={values.toggleSelection} />
    //       }
    //       label={t("Farmer_Association_Form_Toggle_Selection_Label")}
    //       labelPlacement="top"
    //     />
    //   )
    // }:null,   
    // isGeogSelection  ?
    // {
    //   type: "custom",
    //   component: ({ setFieldValue, values }, renderFields) => (
    //     <>
    //       <span>
    //         <h3>{t("Farmer_Association_Form_Geography_Selection_Label")}</h3>
    //       </span>
    //       <GeographySelection
    //         name={"geography"}
    //         stateData={stateData}
    //         setFieldValue={setFieldValue}
    //         values={values["geoLocationBased"]}
    //         data ={values}
    //       />
    //     </>
    //   ),
    //   grid: {
    //     xs: 12,
    //     className: "title-on-border-box bg-blue-l"
    //   }
    // }
    // :null,
    isPincodeSelection ?
    {
      type: "custom",
      component: ({ setFieldValue, values }, renderFields) => (
        <>
          <span>
            <h3>{t("Farmer_Association_Form_Pincode_Selection_Label")}</h3>
          </span>
          <PincodeSelection
            name={"pincode"}
            setFieldValue={setFieldValue}
            values={values["pincodeList"]}
          />
        </>
      ),
      grid: {
        xs: 12,
        className: "title-on-border-box bg-blue-l",
        
      }
    }:null,
    isOrgSelection ?
    {
      type: "custom",
      component: ({ setFieldValue, values }, renderFields) => (
        <>
          <span>
            <h3>{t("Farmer_Association_Form_Organization_Selection_Label")}</h3>
          </span>
          <OrganizationSelection
            name={"organization"}
            organizationData={orgData}
            setFieldValue={setFieldValue}
            values={values["organizationBased"]}
          />
        </>
      ),
      grid: {
        xs: 12,
        className: "title-on-border-box bg-blue-l"
      }
    }  :null  
  ];

  return(
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title="Farmer Association"
          breadcrumbs={[
            { label: "Dashboard", link: "/dashboard" },
            { label: "Configuration" },
            { label: "Farmer Association" }
          ]}
        />
      </Grid>

      <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%"
        }}
      >
        {status === "success" ? (
      <CommonForm
        fields={fields}
          submitLabel={t("site_Form_Submit_Label")}
          submittingLabel={t("site_Form_Submitting_Label")}
          initialValues={values}
          validationSchema={Yup.object().shape({ ...validations })}
          validateOnBlur={true}
          //  edit={edit}
          enableReinitialize
          validateOnChange={true}
          onSubmit={(
            values,
              { setSubmitting, setFieldError, resetForm, errors }
            ) => {
            if (!!errors) {
              setSubmitting(false);
            }
            handleSubmit(values, resetForm, setSubmitting);
          }}
          formikRef={formikForm}
          buttonSize="3"
          buttonPosition="right"
        />
        ):null}
        {selectionError && (
          <div style={{ color: "#f44336" }}>{selectionError}</div>
    )}
    </Paper>
   
    <SuccessFeedback
      open={farmerAssociateStatus === "success"}
      onClose={handleDialogClose}
      successMessage={statusMessage}
      createAnotherMesssage={statusMessage}
    />
    <FailureFeedback
      open={farmerAssociateStatus === "failed"}
      onClose={handleDialogClose}
      status={"Failed"}
      message={failedMessage}
      showButtons={true}
    />
  </Grid>   
  );
}
