import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { getIn } from "formik";
import PageHeader from "components/layout/PageHeader";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
import CommonForm from "components/common/form/common-form";
import FailureFeedback from "components/common/elements/FailureFeedback";
import { metadataService } from "services/metadataServices";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import "components/responsive.scss";
import "../configuration.scss";
import "./Product.scss";
import "../../../components/responsive.scss";
import ChillingUnitFields from "components/common/form/ChillingUnitFields";
import SerialNumberField from "components/common/form/SerialNumberField";
import CountryStateDistrictSelector from "components/common/form/CountryStateDistrictSelector";
import ViewProductLanguageList from "components/common/form/ViewProductLanguageList";
import CategoryTreePopUp from "components/common/elements/CategoryTreePopUp";

import { provisioningService } from "services/provisioningServices";
import { useTranslation } from "react-i18next";
import { string } from "prop-types";
import addDays from "date-fns/addDays";

import {
  FormControl,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Switch,
  Box,
  withStyles,
} from "@material-ui/core";

export default function ViewProductForm({
    values,
    siteUUID = false,
    language,
    handleSubmit,
    handleCategoryClick,
    handleCloseDialogue,
    status,
    editStatus,
    createError,
    edit,
    showSubmitButton=true
  }) {
    const [serialNumbers, setSerialNumbers] = useState([]);
    const [locale, setLocale] = useState([]);
    const [timeZone, setTimeZone] = useState([]);
    const [createdDate] = React.useState(addDays(new Date(), -2));
    const [expiryDate] = React.useState(new Date());
    const [publishChannelStatus,setPublishChannelStatus] = React.useState(true);
    const [toggle,setToggle] = React.useState(false);
    // const [deviceAttributeValues, setDeviceAttributeValues] = useState([]);

    const booleanOptions = [
      { value: true , label : "Yes" },
      { value: false , label : "No" }
    ]
  
    const { t } = useTranslation();
  
    let breadcrumbs = [
      {
        label: t("notification_Form_Breadcrum_Dashboard_Label"),
        link: "/dashboard"
      },
      { label: t("notification_Form_Breadcrum_Configuration_Label") },
      { label: t("notification_Form_Breadcrum_Notification_Label") }
    ];

    const handleToggle = (event) => {
      setToggle(!toggle)
      values[event.target.name] = !values[event.target.name]
      console.log("Toggle: ", values[event.target.name]);
      console.log("Toggle: ", event.target.name);
    }
   
    const handlePublishChannelStatus = value => {
      if(value.includes("TOPIC_NOTIFICATION")) {
        setPublishChannelStatus(false)
      }else{
        setPublishChannelStatus(true)
      }
    }
  
    const org = JSON.parse(localStorage.getItem("currentUser")).organizationName;
  
    const formikForm = React.useRef(null);

    const pbpList = ["a","b","c"];

    const fetchTagList = ["d","e"];

    function loadFetchTagData(){

    }
  
    const fields = [
      // {  
      //   type: "select",
      //   name: "pbpId",
      //   label: t("product_Form_pbpId_Label"),
      //   placeholder: t("product_Form_pbpId_Label"),
      //   option:
      //   pbpList.length > 0
      //     ? pbpList.map(pbpList => ({
      //         value: pbpList,
      //         label: pbpList
      //       }))
      //     : [],
      //   disabled:true,
      //   grid: {
      //     xs: 12,
      //     sm: 3,
      //     className: "mb-2"
      //   },
      //   customOnChange:loadFetchTagData
      // },
      // {  
      //   type: "multiSelectAll",
      //   name: "fetchTag",
      //   label: t("product_Form_fetchTag_Label"),
      //   placeholder: t("product_Form_fetchTag_Label"),
      //   option:
      //     fetchTagList.length > 0
      //       ? fetchTagList.map(fetchTagList => ({
      //           value: fetchTagList,
      //           label: fetchTagList
      //         }))
      //     : [],
      //   disabled:true,
      //   grid: {
      //     xs: 12,
      //     sm: 3,
      //     className: "mb-2"
      //   }
      // },
      {
        type: "header",
        name: "Product"
      },
      {  
        type: "text",
        name: "productCode",
        label: t("product_Form_productCode_Label"),
        placeholder: t("product_Form_productCode_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      // {  
      //   type: "text",
      //   name: "productGroup",
      //   label: t("product_Form_productGroup_Label"),
      //   placeholder: t("product_Form_productGroup_Label"),
      //   //disabled:true,
      //   grid: {
      //     xs: 12,
      //     sm: 3,
      //     className: "mb-2"
      //   }
      // },
      {  
        type: "text",
        name: "productName",
        label: t("product_Form_productName_Label"),
        placeholder: t("product_Form_productName_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {  
        type: "text",
        name: "productType",
        label: t("product_Form_productType_Label"),
        placeholder: t("product_Form_productType_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "header",
        name: "Product Pricing"
      },
      {
        type: "number",
        name: "amount",
        label: t("product_Form_amount_Label"),
        placeholder: t("product_Form_amount_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      // {
      //   type: "number",
      //   name: "buyingPrice",
      //   label: t("product_Form_buyingPrice_Label"),
      //   placeholder: t("product_Form_buyingPrice_Label"),
      //   //disabled:true,
      //   grid: {
      //     xs: 12,
      //     sm: 3,
      //     className: "mb-2"
      //   }
      // },
      {  
        type: "number",
        name: "sellingPrice",
        label: t("product_Form_sellingPrice_Label"),
        placeholder: t("product_Form_sellingPrice_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {  
        type: "number",
        name: "gstPercentage",
        label: t("product_Form_gstPercentage_Label"),
        placeholder: t("product_Form_gstPercentage_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "header",
        name: "Date"
      },
      {
        type: "datePicker",
        name: "createdDate",
        label: t("product_Form_createdDate_Label"),
        placeholder: t("product_Form_createdDate_Label"),
        value: { createdDate },
        maxDate:  expiryDate ,
        disabled: true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "datePicker",
        name: "expiryDate",
        label: t("product_Form_expiryDate_Label"),
        placeholder: t("product_Form_expiryDate_Label"),
        disabled: true,
        value: { expiryDate },
        maxDate:  expiryDate ,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "datePicker",
        name: "manufacturingDate",
        label: t("product_Form_manufacturingDate_Label"),
        placeholder: t("product_Form_manufacturingDate_Label"),
        disabled: true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "header",
        name: "Product Data"
      },
      {
        type: "number",
        name: "bar_code",
        label: t("product_Form_barCode_Label"),
        placeholder: t("product_Form_barCode_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "number",
        name: "batch",
        label: t("product_Form_batch_Label"),
        placeholder: t("product_Form_batch_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "text",
        name: "brand",
        label: t("product_Form_brand_Label"),
        placeholder: t("product_Form_brand_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {  
        type: "number",
        name: "hsn",
        label: t("product_Form_hsn_Label"),
        placeholder: t("product_Form_hsn_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "url",
        name: "imageUrl",
        label: t("product_Form_imageUrl_Label"),
        placeholder: t("product_Form_imageUrl_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {  
        type: "number",
        name: "initialSize",
        label: t("product_Form_initialSize_Label"),
        placeholder: t("product_Form_initialSize_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "custom",
        grid: {
          xs: 12,
          sm: 3,
          className: ""
        },
        component: ({}) => (
          <FormControlLabel 
            control={
                <Switch checked={values.bulkBuyAllowed} onChange={handleToggle} name="bulkBuyAllowed" value={values.bulkBuyAllowed} />
            }
            label={t("product_Form_bulkBuyAllowed_Label")}
            labelPlacement="top"
          />
        )
      },   
      {
        type: "custom",
        grid: {
          xs: 12,
          sm: 3,
          className: ""
        },
        component: ({}) => (
          <FormControlLabel 
            control={
                <Switch checked={values.isActive} onChange={handleToggle} name="isActive" value={values.isActive} />
            }
            label={t("product_Form_isActive_Label")}
            labelPlacement="top"
          />
        )
      }, 
      {
        type: "custom",
        grid: {
          xs: 12,
          sm: 3,
          className: ""
        },
        component: ({}) => (
          <FormControlLabel 
            control={
                <Switch checked={values.isActiveEligible} onChange={handleToggle} name="isActiveEligible" value={values.isActiveEligible} />
            }
            label={t("product_Form_isActiveEligible_Label")}
            labelPlacement="top"
          />
        )
      },  
      {  
        type: "text",
        name: "licenses",
        label: t("product_Form_licenses_Label"),
        placeholder: t("product_Form_licenses_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {  
        type: "number",
        name: "minBulkQty",
        label: t("product_Form_minBulkQty_Label"),
        placeholder: t("product_Form_minBulkQty_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {  
        type: "number",
        name: "maxBulkQty",
        label: t("product_Form_maxBulkQty_Label"),
        placeholder: t("product_Form_maxBulkQty_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {  
        type: "number",
        name: "minQtyAllowed",
        label: t("product_Form_minQtyAllowed_Label"),
        placeholder: t("product_Form_minQtyAllowed_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {  
        type: "number",
        name: "maxQtyAllowed",
        label: t("product_Form_maxQtyAllowed_Label"),
        placeholder: t("product_Form_maxQtyAllowed_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {  
        type: "text",
        name: "method",
        label: t("product_Form_method_Label"),
        placeholder: t("product_Form_method_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {  
        type: "number",
        name: "priority",
        label: t("product_Form_priority_Label"),
        placeholder: t("product_Form_priority_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {  
        type: "number",
        name: "quantity",
        label: t("product_Form_quantity_Label"),
        placeholder: t("product_Form_quantity_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {  
        type: "number",
        name: "rating",
        label: t("product_Form_rating_Label"),
        placeholder: t("product_Form_rating_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {  
        type: "text",
        name: "searchTag",
        label: t("product_Form_searchTag_Label"),
        placeholder: t("product_Form_searchTag_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      
      {  
        type: "text",
        name: "sku",
        label: t("product_Form_sku_Label"),
        placeholder: t("product_Form_sku_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {  
        type: "text",
        name: "status",
        label: t("product_Form_status_Label"),
        placeholder: t("product_Form_status_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {  
        type: "number",
        name: "stepCount",
        label: t("product_Form_stepCount_Label"),
        placeholder: t("product_Form_stepCount_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {  
        type: "text",
        name: "uom",
        label: t("product_Form_uom_Label"),
        placeholder: t("product_Form_uom_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {  
        type: "text",
        name: "variant",
        label: t("product_Form_variant_Label"),
        placeholder: t("product_Form_variant_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      
      {  
        type: "number",
        name: "provider",
        label: t("product_Form_provider_Label"),
        placeholder: t("product_Form_provider_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },     
      // {  
      //   type: "text",
      //   name: "orgId",
      //   label: t("product_Form_orgId_Label"),
      //   placeholder: t("product_Form_orgId_Label"),
      //   //disabled:true,
      //   grid: {
      //     xs: 12,
      //     sm: 3,
      //     className: "mb-2"
      //   }
      // },
      // {  
      //   type: "text",
      //   name: "orgName",
      //   label: t("product_Form_orgName_Label"),
      //   placeholder: t("product_Form_orgName_Label"),
      //   //disabled:true,
      //   grid: {
      //     xs: 12,
      //     sm: 3,
      //     className: "mb-2"
      //   }
      // },
      // {  
      //   type: "text",
      //   name: "groupName",
      //   label: t("product_Form_groupName_Label"),
      //   placeholder: t("product_Form_groupName_Label"),
      //   //disabled:true,
      //   grid: {
      //     xs: 12,
      //     sm: 3,
      //     className: "mb-2"
      //   }
      // },
      // {  
      //   type: "text",
      //   name: "omsReference",
      //   label: t("product_Form_omsReference_Label"),
      //   placeholder: t("product_Form_omsReference_Label"),
      //   //disabled:true,
      //   grid: {
      //     xs: 12,
      //     sm: 3,
      //     className: "mb-2"
      //   }
      // },
      {
        type: "header",
        name: "Category"
      },
      {
        type: "custom",
        component: ({ values, errors }, renderFields) => (
          <CategoryTreePopUp
            name="CategoryTree"
            values={values}
            handleCategoryClick={handleCategoryClick}
          />
        )
      },
      {  
        type: "text",
        name: "categoryName",
        label: t("product_Form_categoryName_Label"),
        placeholder: t("product_Form_categoryName_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {  
        type: "text",
        name: "categoryTree",
        label: t("product_Form_categoryTree_Label"),
        placeholder: t("product_Form_categoryTree_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {  
        type: "text",
        name: "categoryId",
        label: t("product_Form_categoryId_Label"),
        placeholder: t("product_Form_categoryId_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "custom",
        component: ({ values, errors }, renderFields) => (
          <ViewProductLanguageList
            name="languageList"
            languageValues={language}
            values={values}
            errors={errors}
            renderFields={renderFields}
          />
        )
      }
   ]
   
    // let validations = {
    //   id: Yup.number().required(t("product_Form_Validation_Id")),
    //   benefits: Yup.string().required(t("product_Form_Validation_Benefits")),
    //   categoryTree:Yup.string().required(t("product_Form_Validation_categoryTree")),
    //   description:Yup.string().required(t("product_Form_Validation_description")),
    //   howToUse:Yup.string().required(t("product_Form_Validation_howToUse")),
    //   imageUrl:Yup.string().required(t("product_Form_Mandatory_imageUrl")).matches(
    //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%_]+&?)?$/,
    //     t("product_Form_Validation_imageUrl")),
    //   language:Yup.string().required(t("product_Form_Validation_Language")),
    //   maxQtyAllowed: Yup.string().required(t("product_Form_Validation_maxQtyAllowed")),
    //   minQtyAllowed: Yup.string().required(t("product_Form_Validation_minQtyAllowed")),
    //   productCode: Yup.string().required(t("product_Form_Validation_productCode")),
    //   productName: Yup.string().required(t("product_Form_Validation_productName")),
    //   rating: Yup.string().required(t("product_Form_Validation_rating")),
    //   searchTag: Yup.string().required(t("product_Form_Validation_searchTag")),
    //   sellingPrice: Yup.string().required(t("product_Form_Validation_sellingPrice")),
    //   stepCount: Yup.number().required(t("product_Form_Validation_stepCount")),
    //   categoryId: Yup.string().required(t("product_Form_Validation_categoryId")),
    //   fetchTag:Yup.string().required(t("product_Form_Validation_fetchTag"))
    // };
  
    const dispatch = useDispatch();
    const history = useHistory();
    const handleDialogClose = () => {
      console.log("edit"+edit);
      if (editStatus === "success" || status === "success") {
        history.push("/configuration/product");       
      }    
    };
  
    return (
      <Grid container>
        <Grid item xs={12}>
          <PageHeader
            title={t("product_Form_Header_Label")}
            breadcrumbs={breadcrumbs}
          />
        </Grid>
        <Grid container>
          <Paper elevation={2} style={{ padding: "2em" }}>
            {/* <Grid item>
              <h2>{t("product_Form_Label")}</h2>
            </Grid> */}
            {/* <Grid container className="d-block d-md-flex ">
              <Grid item width={12} className="site-id-label">
                <label>
                  {t("notification_Form_Title_Org_Label")} : <b>{org}</b>
                </label>
              </Grid>
            </Grid> */}
            <Grid item>
              <CommonForm
                fields={fields}
                showSubmitButton={showSubmitButton}
                submitLabel={t("site_Form_Submit_Label")}
                submittingLabel={t("site_Form_Submitting_Label")}
                initialValues={values}
                // validationSchema={Yup.object().shape({ ...validations })}
                validateOnBlur={true}
                edit={edit}
                enableReinitialize
                validateOnChange={true}
                onSubmit={(
                  values,
                  { setSubmitting, setFieldError, resetForm, errors }
                ) => {
                  if (!!errors) {
                    setSubmitting(false);
                  }
                  handleSubmit(values, resetForm, setSubmitting);
                }}
                formikRef={formikForm}
                buttonSize="3"
                buttonPosition="right"
              />
            </Grid>
          </Paper>
        </Grid>
        <SuccessFeedback
          open={status === "success"}
          onClose={handleDialogClose}
          successMessage={t("notification_Form_Success_Message")}
          createAnotherMesssage={t("site_Form_Create_Another_Site")}
        />
         <SuccessFeedback
          open={editStatus === "success"}
          onClose={handleDialogClose}
          successMessage={t("notification_Form_Edit_Success_Message")}
          createAnotherMesssage={t("site_Form_Create_Another_Site")}
        />
        <FailureFeedback
          open={status === "failed"}
          onClose={handleDialogClose}
          status={!!createError ? createError.status : ""}
          message={
            !!createError
              ? !!createError.error.message
                ? createError.error.message
                : ""
              : ""
          }
        />
      </Grid>
    );
  }
