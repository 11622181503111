import React from "react";

import ExcelIcon from "../../../components/common/elements/ExcelIcon";
import PdfIcon from "../../../components/common/elements/PdfIcon";
import {FileUploadButton, CustomizedPrimaryButton } from "../../../components/common/elements/CustomizedPrimaryButton";
import PageHeader from "components/layout/PageHeader";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

export default function ImportSite() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title="Import Alert"
          breadcrumbs={[
            { label: "Dashboard", link: "/dashboard" },
            { label: "Configuration" },
            { label: "Import Alerts" }
          ]}
        />
      </Grid>
      <Paper elevation={2}>
        <Grid item container md={12} style={{ padding: "2em" }}>
          <Grid
            item
            container
            md={12}
            justify="space-between"
            alignItems="center"
            style={{ padding: "1em" }}
          >
            <Grid
              item
              container
              md={6}
              alignItems="center"
            >
		    <span>Sample Configuration
		    </span>
			    <PdfIcon/>
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomizedPrimaryButton
                variant="contained"
                color="primary"
                size="medium"
                className="align-self-end w-100 text-capitalize"
                block
                square
                label="Download"
              ></CustomizedPrimaryButton>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            style={{ padding: "1em" }}
          >
            <Grid item container md={6} alignItems="center"  >
              <span> Choose File ( .CSV Format )
              </span>
              <ExcelIcon />
            </Grid>
            <Grid item md={6} xs={12}>
              <FileUploadButton
                variant="contained"
                color="primary"
                size="medium"
                className="align-self-end w-100 text-capitalize"
                block
                square
                label="Browse/Upload"
              ></FileUploadButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
