import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import PreventiveTemplateForm from "./PreventiveTemplateForm";
import { provisioningService } from "services/provisioningServices";

export default function CreatePreventiveTemplate() {
  const org = JSON.parse(localStorage.getItem("currentUser")).organizationName;
  const history = useHistory();
  // to initialize the values when create template form loads first time
  let values = {
    orgName: org,
    checklistName: "",
    checklistDescription: "",
    taskList: [
      {
        taskName: "",
        subTasks: [
          {
            subTaskName: "",
            subTaskDescription: "",
            taskType: "",
            subTaskValue: {}
          }
        ]
      }
    ]
  };

  async function handleSubmit(values, resetForm, setSubmitting) {

    try {
      const repeat = null;
      const result = await provisioningService.createChecklistTemplate(values);

      resetForm();
      history.push("/configuration/checklist-templates-list");
    } catch (e) {
      console.error(e);
      setSubmitting(false);
    }

    //Make back end API call to save valuesresetDeviceFields
    //setSubmit to false
    //call resetForm
  }

  function resetCheckListFields(parentIndex, childIndex) {
    // setFieldValue(
    //   `chillingUnitMonitoringDetailsList.${parentIndex}.l1DeviceInfo.${childIndex}.deviceType`,
    //   "",
    //   false
    // );
    // setFieldValue(
    //   `chillingUnitMonitoringDetailsList.${parentIndex}.l1DeviceInfo.${childIndex}.deviceUsedFor`,
    //   "",
    //   false
    // );
  }

  // Calling the create template form with values(to initialize),
  return <PreventiveTemplateForm values={values} handleSubmit={handleSubmit} />;
}
