import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import UserForm from "./UserForm";

export default function CreateUser() {
  const org = JSON.parse(localStorage.getItem("currentUser")).organizationName;
  const status = useSelector(state => state.userConfiguration.toJS().status);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "USER_CONFIGURATION_RESET_SITES_CONFIGURATION"
    });
    handleOrganizationSelect(org);
  }, []);

  useEffect(() => {
    if (status === "success") {
      history.push(`/profile/success`);
      dispatch({
        type: "USER_CONFIGURATION_RESET_STATUS"
      });
    }
  }, [status]);

  const subOrganizations = useSelector(
    state => state.userConfiguration.toJS().subOrganizations
  );

  const subOrganizationsLoading = useSelector(
    state => state.userConfiguration.toJS().subOrganizationsLoading
  );

  const handleSubOrganizationSelect = (subOrganization, setFieldValue) => {
    dispatch({
      type: "USER_CONFIGURATION_SUB_ORGANIZATIONS_UPDATED",
      subOrganizationsSelected: subOrganization,
      setFieldValue,
      siteField: "sitesForAlertsConfiguration"
    });
  };

  const handleSubOrganizationForSiteAssociationSelect = (
    subOrganization,
    setFieldValue
  ) => {
    dispatch({
      type: "USER_CONFIGURATION_SUB_ORGANIZATIONS_FOR_SITE_ASSOCIATION_UPDATED",
      subOrganizationsSelected: subOrganization,
      setFieldValue,
      field: "siteAssociationConfiguration"
    });
  };

  const sitesList = useSelector(
    state => state.userConfiguration.toJS().sitesList
  );

  const sitesListLoading = useSelector(
    state => state.userConfiguration.toJS().alertsConfigurationListLoading
  );
  const sitesAssociationList = useSelector(
    state => state.userConfiguration.toJS().sitesAssociationList
  );
  const sitesAssociationListLoading = useSelector(
    state => state.userConfiguration.toJS().sitesAssociationListLoading
  );
  const handleSiteSelectForAlertsConfiguration = sites => {
    dispatch({
      type: "USER_CONFIGURATION_ALERTS_CONFIG_SITES_SELECTED_UPDATE",
      sitesSelected: sites
    });
  };

  const handleSiteAssociationSelect = sites => {
    dispatch({
      type: "USER_CONFIGURATION_SITE_ASSIGN",
      sitesAssigned: sites
    });
  };

  const sitesAlertsConfiguration = useSelector(
    state => state.userConfiguration.toJS().sitesAlertsConfiguration
  );

  let values = {
    userName: "",
    emailId: "",
    password: "",
    firstName: "",
    lastName: "",
    active: 1,
    roles: [],
    gender: "",
    salutation: "",
    mobileNumber: [
      {
        mobileNumber: "",
        countryCode: ""
      }
    ],
    organizationName: org,
    subOrganizationName: "",
    isOauthAuthenticated: null,
    siteAssociationConfiguration: [],
    userAlertConfiguration: [],

    enabled: true,
    locked: false,
    expired: false
  };

  const handleOrganizationSelect = org => {
    dispatch({
      type: "USER_CONFIGURATION_ORGANIZATION_SELECT_UPDATED",
      org
    });
  };

  const formik = useRef(null);

  function handleSubmit(values, resetForm, setSubmitting) {
    dispatch({
      type: "USER_CONFIGURATION_CREATE_USER",
      values,
      resetForm,
      setSubmitting
    });
  }

  const createError = useSelector(
    state => state.userConfiguration.toJS().createError
  );

  return (
    <UserForm
      values={values}
      handleSubmit={handleSubmit}
      createError={createError}
      status={status}
      subOrganizations={subOrganizations}
      subOrganizationsLoading={subOrganizationsLoading}
      handleSubOrganizationSelect={handleSubOrganizationSelect}
      handleSubOrganizationForSiteAssociationSelect={
        handleSubOrganizationForSiteAssociationSelect
      }
      sitesList={sitesList}
      sitesListLoading={sitesListLoading}
      sitesAssociationList={sitesAssociationList}
      sitesAssociationListLoading={sitesAssociationListLoading}
      handleSiteSelectForAlertsConfiguration={
        handleSiteSelectForAlertsConfiguration
      }
      handleSiteAssociationSelect={handleSiteAssociationSelect}
      sitesAlertsConfiguration={sitesAlertsConfiguration}
    />
  );
}
