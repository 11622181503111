import { func } from "prop-types";
import { utilityServices } from "./utilityServices";

function getToken() {
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const token = user && user.jwtAccessToken;
  return token;
}

async function  getRetailerAgentListData(data){
  const url = `${process.env.REACT_APP_GET_RETAILER_AGENT_LIST}?pbpName=${data}&size=5000`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfR",
      Authorization:`Bearer ${localStorage.getItem("bearerTokenRetailer")}`
    }
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function associateRetailerAgent(values){
  const url = `${process.env.REACT_APP_ASSOCIATE_RETAILER_AGENT}`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "gh7MvvJXnRQO44PatMnoAuBcgLNTfk",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    },
    body:JSON.stringify(values)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function validationRetailerAgentAssociateSelection(values){
  const url = `${process.env.REACT_APP_VALIDATE_RETAILER_AGENT_ASSOCIATE_SELECTION}`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "gh7MvvJXnRQO44PatMnoAuBcgLNTfk",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`
    },
    body:JSON.stringify(values)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getRetailerAgentCriteriaList(pageSize,pageIndex) {
  const url = `${process.env.REACT_APP_GET_RETAILER_AGENT_CRITERIA_LIST}?page=${pageIndex}&size=${pageSize}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "gh7MvvJXnRQO44PatMnoAuBcgLNTfk",
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`
    },
    body: JSON.stringify()
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function editRetailerAgentCriteria(id,values) {
  const url = `${process.env.REACT_APP_EDIT_RETAILER_AGENT_CRITERIA}/${id}`;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "gh7MvvJXnRQO44PatMnoAuBcgLNTfk",
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`
    },
    body: JSON.stringify(values)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

export const retailerAgentAssociationService = {  
  validationRetailerAgentAssociateSelection,
  associateRetailerAgent,
  getRetailerAgentListData,
  getRetailerAgentCriteriaList,
  editRetailerAgentCriteria
};
