import React from "react";
import orgTreeIcon from "assets/org-tree.png";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";

export default function OrgTreeIcon({ organization, button }) {
  return (
    // <Link to={`/organization-tree/${organization}`}>
      <Link to={`/organization-tree`}>
      {!!button && (
        <IconButton style={{ padding: "5px" }} edge="end">
          <img
            src={orgTreeIcon}
            alt="organization tree"
            style={{ width: "1.5em" }}
          />
        </IconButton>
      )}
      {!button && (
        <img
          src={orgTreeIcon}
          alt="organization tree"
          style={{ width: "1.5em" }}
        />
      )}
    </Link>
  );
}
