import React, { useEffect, useContext } from "react";

import { Link, Route, useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

import clsx from "clsx";

import logo from "../../assets/Stellapps_logo_Horizontal.png";
import AccountDropdown from "./AccountDropdown";

import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import MailIcon from "@material-ui/icons/Mail";
import HomeIcon from "@material-ui/icons/Home";
import Badge from "@material-ui/core/Badge";
import SearchIcon from "@material-ui/icons/Search";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles, makeStyles, fade } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { authContext } from "context/authContext";
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5"
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles(theme => ({
  root: {
    fontSize: "14px",
    minHeight: "0px",
    color: "black",
    "&:hover": {
      backgroundColor: "rgba(63,81,181,0.08)"
    }
  }
}))(MenuItem);

function Appbar({ handleClose, drawerWidth }) {
  const useStyles = makeStyles(theme => ({
    appBar: {
      backgroundColor: "#34409A",
      height: "5em",
      //paddingRight: "0em !important",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100vw - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    /* App Bar */
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25)
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto"
      },
      [theme.breakpoints.down("md")]: {
        display: "none"
      }
    },
    searchIcon: {
      width: theme.spacing(7),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    inputRoot: {
      color: "inherit"
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: 120,
        "&:focus": {
          width: 200
        }
      }
    },
    logoContainer: {
      [theme.breakpoints.down("md")]: {
        display: "none" //dont display logo on mobiles and tablets
      }
    },
    grow: {
      flexGrow: 1
    },
    menuButton: {
      "&:hover": {
        backgroundColor: "rgba(255,255,255,.08) !important",
        [theme.breakpoints.up("sm")]: {
          margin: 2
        }
      }
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block"
      }
    },
    sectionDesktop: {
      display: "flex",
      alignItems: "center"
      // [theme.breakpoints.down("md")]: {
      //   display: "none"
      // }
    },
    div: {
      display: "flex",
      alignItems: "center"
    },
    list: {
      width: 240
    },
    fullList: {
      width: "auto"
    }
  }));

  const classes = useStyles();

  const { t, i18n } = useTranslation();

  /* Language Change */
  const history = useHistory();
  const [orgName, setOrgName] = React.useState("");
  const [lang, setLang] = React.useState("En");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { authState, authDispatch } = useContext(authContext);
  useEffect(() => {
    let lang = localStorage.getItem("i18nextLng");
    let user = JSON.parse(localStorage.getItem("currentUser"));
    if (!!user) {
      setOrgName(user.organizationName);
    }
    if (!!lang) {
      lang = lang.charAt(0).toUpperCase() + lang.slice(1);
      authDispatch({
        type: "CHANGE_LANGUAGE",
        language: lang.toLowerCase()
      });
      setLang(lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  // const p=new Promise((resolve,reject)=>{
  //   setAnchorEl(null);
  //   if(anchorEl===null){
  //     resolve(true)
  //   }
  // })
  const closeMenu = l => {
    new Promise(resolve => {
      setAnchorEl(null);
      resolve(l);
    }).then(done => {
      handleLangChange(done);
    });
  };
  const handleLangChange = lang => {
    i18n.changeLanguage(lang.toLowerCase());
    authDispatch({
      type: "CHANGE_LANGUAGE",
      language: lang.toLowerCase()
    });
    setLang(lang);
    langRefresh();
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const refreshDashboard = () => {
    const path = window.location.hash;
    if (path === "#/dashboard") {
      window.location.reload();
    }
  };
  const langRefresh = () => {
    window.location.reload();
  };
  return (
    <AppBar position="fixed" elevation={1} className={clsx(classes.appBar)}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleClose}
          edge="start"
          classes={{ root: classes.menuButton }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.logoContainer}>
          <div className={classes.toolbar} style={{ width: "250px" }}>
            {" "}
            <Link id="navbar-logo" to="/dashboard">
              {" "}
              <img
                src={logo}
                alt="Navbar Logo"
                style={{
                  width: "70%",
                  display: "inline-block",
                  margin: "0.5rem 0px"
                }}
                onClick={refreshDashboard}
              />
            </Link>
          </div>
        </Typography>
        <div className={classes.grow} />

        {/* <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            inputProps={{ "aria-label": "search" }}
          />
        </div> */}
        <div className={classes.sectionDesktop}>
          <Badge badgeContent={0} color="secondary">
            {orgName}
          </Badge>
          {/* <IconButton
            aria-label="show// PASS THE VALUE HERE/ new mails"
            color="inherit"
            classes={{ root: classes.menuButton }}
          >
            <Badge badgeContent={0} color="secondary">
              <MailIcon />
            </Badge>
          </IconButton>
          <IconButton
            aria-label="show// PASS THE VALUE HERE// new notifications"
            color="inherit"
            classes={{ root: classes.menuButton }}
          >
            <Badge badgeContent={0} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <div style={{ borderRadius: "0", display: "flex" }}>
            <FormControl>
              <Button
                aria-controls="Language Select"
                aria-haspopup="true"
                onClick={handleClick}
                startIcon={
                  <i className="fa fa-globe" style={{ color: "white" }}></i>
                }
                endIcon={<ArrowDropDownIcon />}
                style={{ color: "white" }}
                classes={{ root: classes.menuButton }}
              >
                {lang}
              </Button>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                // keepMounted
                open={anchorEl !== null}
                onClose={() => {
                  setAnchorEl(null);
                }}
              >
                <StyledMenuItem onClick={e => closeMenu(e.target.textContent)}>
                  Fr
                </StyledMenuItem>
                <StyledMenuItem onClick={e => closeMenu(e.target.textContent)}>
                  En
                </StyledMenuItem>
              </StyledMenu>
              {/* <Select */}
              {/*   value={lang} */}
              {/*  //> onChange={handleLangChange} */}
              {/*   name="name" */}
              {/*   renderValue={value => ( */}
              {/*     <> */}
              {/*       <i className="fa fa-globe"></i> &nbsp; */}
              {/*       {value} */}
              {/*     </> */}
              {/*   )} */}
              {/*   inputProps={{ */}
              {/*     id: "language-simple" */}
              {/*   }} */}
              {/* > */}
              {/*   <MenuItem value="En" onClick={(e)=> console.log(e.target)}>En</MenuItem> */}
              {/*   <MenuItem value="Fr" onClick={(e)=> console.log(e.target)}>Fr</MenuItem> */}
              {/* </Select> */}
            </FormControl>
          </div>
        </div>
        <AccountDropdown />
      </Toolbar>
    </AppBar>
  );
}

export default Appbar;
