import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import image from "../../assets/ErrorImages/error.svg";
import { makeStyles } from "@material-ui/core/styles";
import { fontSize } from "@material-ui/system";
import { useDispatch } from "react-redux";
import { CustomizedPrimaryButton } from "../../components/common/elements/CustomizedPrimaryButton";
function Error() {
  const [refresh, setRefresh] = useState(false);
  const history = useHistory();
  // console.log("history ", useHistory());
  // console.log("location ", useLocation());
  const useStyles = makeStyles(theme => ({
    error404PageConatiner: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "100px",
      minHeight: "100%"
    },
    error: {
      margin: "10px"
    },
    errorContainer: {
      minWidth: "240px",
      maxWidth: "500px",
      maxHeight: "500px",
      display: "block",
      flexDirection: "column",
      alignSelf: "center"
    },
    errorMsg: {
      paddingLeft: theme.spacing(15),
      paddingRight: theme.spacing(15),
      [theme.breakpoints.down("md")]: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0)
      },
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    errorCode: {
      display: "block",
      marginBottom: theme.spacing(0)
    },
    errorSubMsg: {
      display: "flex",
      color: "#b0b4b9",
      fontSize: "2em",
      "& h2": {
        marginBottom: "0px"
      }
    }
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "RESET_ERROR"
    });
  }, []);
  return (
    <div className={classes.error404PageConatiner}>
      <div className={classes.errorContainer}>
        <div className={classes.errorMsg}>
          <h1 className={classes.errorCode}>Oops!</h1>
          <div className={classes.errorSubMsg}>
            <h2>Something went wrong </h2>
          </div>
        </div>
        <object type={"image/svg+xml"} data={image}>
          Your Browser doesn't support SVG
        </object>
        <div className={classes.errorMsg}>
          <div className={classes.errorSubMsg}>
            <h2>Please refresh the page</h2>
          </div>
        </div>
        <div className={classes.error}>
          <CustomizedPrimaryButton
            onClick={() => history.goBack()}
            variant="contained"
            color="primary"
            label="Try Again"
            width="8"
            position="center"
          />
        </div>
        <div className={classes.error}>
          <CustomizedPrimaryButton
            onClick={() => history.push("/dashboard")}
            variant="contained"
            color="primary"
            label="Go To Dashboard"
            width="8"
            position="center"
          />
        </div>
      </div>
    </div>
  );
}
export default Error;
