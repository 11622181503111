import React, { useEffect, useContext, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import "../../../components/Table.scss";
import { provisioningService } from "../../../services/provisioningServices.js";
import Table from "components/common/table/DynamicPaginationTable2";
import PageHeader from "components/layout/PageHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import { errorContext } from "context/errorContext";

import { UseTranslation, useTranslation } from "react-i18next";
import * as Yup from "yup";
import CommonForm from "components/common/form/common-form";
import Paper from "@material-ui/core/Paper";
import { pbpService } from "services/pbpServices";

const pbpList = props => {

  const { t } = useTranslation();

  const [status, setStatus] = React.useState("loading");
  const [pageInfo, setPageInfo] = React.useState({});
  //const [agentList, setAgentList] = React.useState([]);
  const [pbpList, setpbpList] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(10);
  const [pageIndex, setPageIndex] = React.useState(0);

  const { dispatch: errorDispatch } = useContext(errorContext);

  const columns = React.useMemo(
    () => [
      {
        Header: t("Id"),
        accessor: "id",
      },
      {
        Header: t("Name"),
        accessor: "name",
      },
      {
        Header: t("Addres"),
        accessor: "address"
      },
      {
        Header: t("GST"),
        accessor: "gst"
      },    
       
    ],
    []
  );


 /* const dropitems = [
    {
      item: (
        <Link to="/configuration/pbp-list">
         {t("agent_Add_Label")}
        </Link>
      )
    }
  ]; */

  useEffect(() => {
    provisioningService.verifyOtpNew('1313131313').then((res) => {
      const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
      const organizationId = JSON.parse(localStorage.getItem("orgId"));
      
    })
    .catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });
  }, []);


  { useEffect(() => {
    
    
    setStatus("loading");

          pbpService
          .getPbpList()
          .then(data => {
            setpbpList(data || []);
            console.log("Agent List :-========== ", data);
            setPageInfo(data.pageInfo);
            setStatus("success");
          })
          .catch(error => {
            errorDispatch({
              type: "ERROR",
              error
            });
          });
    
  }, [ ]); }  

  const data = React.useMemo(() => [...pbpList]);

  const nextPage = () => {
    if (
      status !== "loading" &&
      pageInfo.hasNextPage &&
      pageIndex < pageInfo.totalPages
    ) {
      let index = pageIndex;
      setPageIndex(++index);
    }
  };
  const previousPage = () => {
    let index = pageIndex;
    if (index > 0) {
      setPageIndex(--index);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("pbp_HeaderList_Label")}
         /* breadcrumbs={[
            { label: t("retailer_Breadcrumb_Dashboard_Label"), link: "/dashboard" },
            { label: t("retailer_Breadcrumb_Config_Label") },
            { label: t("retailer_Breadcrumb_Retailer_Label") }
          ]} */
        />
      </Grid>

      <Paper
         elevation={2}
         style={{
         padding: "2em",
         width: "100%"
        }}
      >
    
      { status === "loading" ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: "50vh" }}
        >
         <CircularProgress />
        </Grid>
      ) : (
          <Table
            columns={columns}
             data={data}
            //needDropdown={true}
            //dropItems={dropitems}
            //fileName={"agentsList.xls"}
          />
      )  } 

      </Paper>
    </Grid>
  );
};

export default pbpList;
