import handleResponse from "../helpers/handle-response";

function getToken() {
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const token = user && user.jwtAccessToken;
  return token;
}

async function login(token) {
  const url = process.env.REACT_APP_OAUTH_LOGIN;
  //const url = process.env.REACT_APP_AUTH_LOGIN;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "authToken": token
    }
    // ,
    // body: JSON.stringify({ userName, password })
  };

  try {
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      throw await response.json();
    } else {
      const data = await response.json();
      return data;
    }

    // const user = await handleResponse(response);
    // await localStorage.setItem("currentUser", JSON.stringify(user));
    // return user;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

async function logout(userName, token) {
  const user = JSON.parse(localStorage.getItem("currentUser"));
  // const token = user && user.jwtAccessToken;
  // const userName = user && user.userName;
  const url = process.env.REACT_APP_AUTH_LOGOUT;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({ jwtAccessToken: token, userName })
  };

  return (
    fetch(url, requestOptions)
      // .then(() => {
      //   localStorage.removeItem("currentUser");
      // })
      .catch(e => console.error("error"))
  );
}

async function loginKeycloak(userName) {
  const url = process.env.REACT_APP_KEYCLOAK_LOGIN;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ username: userName })
  };

  try {
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      throw await response.json();
    } else {
      const data = await response.json();
      console.log(data);
      return data;
    }

    // const user = await handleResponse(response);
    // await localStorage.setItem("currentUser", JSON.stringify(user));
    // return user;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export const authenticationService = {
  login,
  logout,
  loginKeycloak
};
