import React, { useMemo } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter
} from "react-table";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import "../../Table.scss";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory, useLocation } from "react-router-dom";
import {useTranslation} from "react-i18next";

function Table({ columns, data, hidePagination= false }) {
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    gotoPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const history = useHistory();
  const location = useLocation();
  let query = useMemo(() => new URLSearchParams(location.search), [location]);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (Number(query.get("page")) > pageOptions.length) {
      history.push("/404");
    } else if (query.get("page")) {
      setPageSize(Number(query.get("size")));
      gotoPage(Number(query.get("page")));
    }
  }, [query]);

  function handlePageSize(e) {
    if (!!query.get("size")) {
      query.set("size", e.target.value);
      query.set("page", 0);
      history.push({
        pathname: location.pathname,
        search: query.toString()
      });
    } else {
      setPageSize(e.target.value);
    }
  }
  function handleNextPage() {
    if (query.get("page") && Number(query.get("page")) >= 0) {
      query.set("page", Number(query.get("page")) + 1);
      history.push({
        pathname: location.pathname,
        search: query.toString()
      });
      nextPage();
    } else {
      nextPage();
    }
  }
  function handlePreviousPage() {
    if (query.get("page") && Number(query.get("page")) >= 0) {
      query.set("page", Number(query.get("page")) - 1);
      history.push({
        pathname: location.pathname,
        search: query.toString()
      });
      previousPage();
    } else {
      previousPage();
    }
  }

  return (
    <>
      <div style={{ overflow: "auto" }}>
        <table {...getTableProps()} className="data-table">
          <thead>
            {(headerGroups || []).map((headerGroup, hGroupIndex) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={hGroupIndex}>
                {headerGroup.headers.map((column, i) => {
                  if (column.Header === "Actions") {
                    return (
                      <th
                        {...column.getHeaderProps()}
                        key={column.header + i.toString()}
                        colSpan={2}
                        width={120}
                      >
                        {column.render("Header")}
                      </th>
                    );
                  }
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="alert-header"
                      key={column.header + i.toString()}
                      width={column.width !== 150 ? column.width : 120}
                    >
                      {column.render("Header")}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {page.map(
              (row, i) =>
                prepareRow(row) || (
                  <tr {...row.getRowProps()} key={i}>
                    {row.cells.map((cell, j) => {
                      if (cell.column.Header === "Actions") {
                        return cell.render("Cell");
                      }
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={i + j + 1}
                          width={
                            cell.column.width !== 150 ? cell.column.width : 120
                          }
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>
      {!hidePagination ? (
        <Grid
          container
          justify="space-between"
          className="pagination data-table-pagination"
          style={{ padding: "1em 0em 1em 0em" }}
        >
          <Grid
            item
            container
            xs={12}
            md={6}
            justify="flex-start"
            alignItems="center"
            className="page-btm-data"
          >
            <span>
              {t("pagination_Page")} &nbsp;
              <strong>{pageIndex + 1} &nbsp;</strong>
              {t("pagination_Of")} &nbsp;{pageOptions.length}
            </span>
            &nbsp;
            <Select
              variant="outlined"
              margin="dense"
              value={query.get("size") ? Number(query.get("size")) : pageSize}
              onChange={handlePageSize}
            >
              {[10, 20, 30, 40, 50, 100].map(pageSize => (
                <MenuItem key={pageSize} value={pageSize}>
                  {t("pagination_Show")} {pageSize}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item>
            <Button
              onClick={handlePreviousPage}
              disabled={!canPreviousPage}
              color="primary"
            >
              {"Previous"}
            </Button>
            <Button
              onClick={handleNextPage}
              disabled={!canNextPage}
              color="primary"
            >
              {"Next"}
            </Button>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}

export default Table;
