import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";

import CommonForm from "../../../components/common/form/common-form";
import { metadataService } from "../../../services/metadataServices";
import PageHeader from "../../../components/layout/PageHeader";
import LoanProductName from "components/common/form/LoanProductName";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { errorContext } from "context/errorContext";
import { useTranslation } from "react-i18next";

//Import added for Date field
import addDays from "date-fns/addDays";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Switch,
  Box,
  withStyles,
} from "@material-ui/core";

// This constructor is called from createChecklistTemplate.js and
// editChecklistTemplate.js to send the values to be displayed on UI
export default function LoanInsuranceForm({ values, handleSubmit, page, organization, type, category }) {
  
  const loadedPage = page;
  const org = JSON.parse(localStorage.getItem("currentUser")).organizationName;

  //Loan Insurance States
  const [validFromDate] = React.useState(addDays(new Date(), -7));
  const [validToDate] = React.useState(new Date());
  // const [toggle,setToggle] = React.useState(false);

  // const handleToggle = (event) => {
  //   console.log("event ================= ", event);
  //   setToggle(!toggle)
  //   values[event.target.name] = !values[event.target.name]
  //   console.log("toggle ", toggle , "in values",  values[event.target.name] , "event ", event.target.name)
  // }

  const formikForm = React.useRef(null);

  const { t } = useTranslation();

  const { dispatch: errorDispatch } = useContext(errorContext);

  //To diplay the common exceptions at app level
  const fields = [
    {
      type: "completedPercentage",
      formikRef: formikForm,
      title: loadedPage === "add" ?          
         t("loanInsurance_Form_Percentage_Add_Label") : t("loanInsurance_Form_Percentage_Edit_Label") 
    },
    {
      type: "select",
      name: "organizationName",
      label: t("loanInsurance_Form_Org_Name"),
      placeholder: t("loanInsurance_Form_Org_Name"),
      disabled: loadedPage === "edit" ? true : false,
      option:
      organization.length > 0
          ? organization.map(organization => ({
              value: organization.organizationName,
              label: organization.organizationName
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "productName",
      label: t("loanInsurance_Form_Product_Name"),
      placeholder: t("loanInsurance_Form_Product_Name"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "productCode",
      label: t("loanInsurance_Form_Product_Code"),
      placeholder: t("loanInsurance_Form_Product_Code"),
      disabled: loadedPage === "edit" ? true : false,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "select",
      name: "productCategory",
      label: t("loanInsurance_Form_Product_Category"),
      placeholder: t("loanInsurance_Form_Product_Category"),
      option:
      category.length > 0
          ? category.map(category => ({
              value: category.name,
              label: category.name
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "description",
      label: t("loanInsurance_Form_Description"),
      placeholder: t("loanInsurance_Form_Description"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "amount",
      label: t("loanInsurance_Form_Amount"),
      placeholder: t("loanInsurance_Form_Amount"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "select",
      name: "productType",
      label: t("loanInsurance_Form_Product_Type"),
      placeholder: t("loanInsurance_Form_Product_Type"),
      option:
      type.length > 0
          ? type.map(type => ({
              value: type.name,
              label: type.name
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "productTenure",
      label: t("loanInsurance_Form_Product_Tenure"),
      placeholder: t("loanInsurance_Form_Product_Tenure"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "datePicker",
      name: "validFromDate",
      value: { validFromDate },
      label: t("loanInsurance_Form_Valid_From_Date"),
      disabled: loadedPage === "edit" ? true : false,
      readOnly: loadedPage === "edit" ? true : false,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "datePicker",
      name: "validToDate",
      value: { validToDate },
      label: t("loanInsurance_Form_Valid_To_Date"),
      disabled: loadedPage === "edit" ? true : false,
      //minDate: validFromDate,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "url",
      name: "consentURL",
      label: t("loanInsurance_Form_Consent_Url"),
      placeholder: t("loanInsurance_Form_Consent_Url"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "url",
      name: "imageURL",
      label: t("loanInsurance_Form_Image_Url"),
      placeholder: t("loanInsurance_Form_Image_Url"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "premium",
      label: t("loanInsurance_Form_Premium"),
      placeholder: t("loanInsurance_Form_Premium"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    // {
    //   type: "text",
    //   name: "status",
    //   label: t("loanInsurance_Form_Status"),
    //   placeholder: t("loanInsurance_Form_Status"),
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "dispDescription",
    //   label: t("loanInsurance_Form_Disp_Description"),
    //   placeholder: t("loanInsurance_Form_Disp_Description"),
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "dispPremium",
    //   label: t("loanInsurance_Form_Disp_Premium"),
    //   placeholder: t("loanInsurance_Form_Disp_Premium"),
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "dispAmount",
    //   label: t("loanInsurance_Form_Disp_Amount"),
    //   placeholder: t("loanInsurance_Form_Disp_Amount"),
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    {
      type: "custom",
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      },
      component: ({ values, setFieldValue }, renderFields) => (
        <FormControlLabel 
          control={
              <Switch checked={values.active} onChange={(e) => setFieldValue("active",!values.active)} name = "active" value={values.active}/>
          }
          label={t("loanInsurance_Form_Active")}
          labelPlacement="top"
        />
      )
    },   
    // {
    //   type: "custom",
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   },
    //   component: ({}) => (
    //     <FormControlLabel 
    //       control={
    //           <Switch checked={values.productEligible} onChange={handleToggle} name="productEligible" value={values.productEligible} />
    //       }
    //       label={t("loanInsurance_Form_Product_Eligible")}
    //       labelPlacement="top"
    //     />
    //   )
    // },   
    {
      type: "custom",
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      },
      component: ({ values, setFieldValue }, renderFields) => (
        <FormControlLabel 
          control={
              <Switch checked={values.largeTicket} onChange={(e) => setFieldValue("largeTicket",!values.largeTicket)} name="largeTicket" value={values.largeTicket} />
          }
          label={t("loanInsurance_Form_Large_Ticket")}
          labelPlacement="top"
        />
      )
    },  
    {
      type: "custom",
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      },
      component: ({ values, setFieldValue }, renderFields) => (
        <FormControlLabel 
          control={
              <Switch checked={values.consentRequired} onChange={(e) => setFieldValue("consentRequired",!values.consentRequired)} name="consentRequired" value={values.consentRequired} />
          }
          label={t("loanInsurance_Form_Consent_Required")}
          labelPlacement="top"
        />
      )
    },  
    {
      type: "custom",
      component: ({ values, errors }, renderFields) => (
        <LoanProductName
          name="productDispNameReplicaList"
          values={values}
          errors={errors}
          renderFields={renderFields}
          elemProp="Display Name"
        />
      )
    },
    {
      type: "custom",
      component: ({ values, errors }, renderFields) => (
        <LoanProductName
          name="productDispCategoryReplicaList"
          values={values}
          errors={errors}
          renderFields={renderFields}
          elemProp="Display Category"
        />
      )
    },
    {
      type: "custom",
      component: ({ values, errors }, renderFields) => (
        <LoanProductName
          name="productDispTenureReplicaList"
          values={values}
          errors={errors}
          renderFields={renderFields}
          elemProp="Display Tenure"
        />
      )
    },
    {
      type: "custom",
      component: ({ values, errors }, renderFields) => (
        <LoanProductName
          name="productDispPremiumReplicaList"
          values={values}
          errors={errors}
          renderFields={renderFields}
          elemProp="Display Premium"
        />
      )
    },
    {
      type: "custom",
      component: ({ values, errors }, renderFields) => (
        <LoanProductName
          name="productDispAmountReplicaList"
          values={values}
          errors={errors}
          renderFields={renderFields}
          elemProp="Display Amount"
        />
      )
    },
    {
      type: "custom",
      component: ({ values, errors }, renderFields) => (
        <LoanProductName
          name="productDispDescriptionReplicaList"
          values={values}
          errors={errors}
          renderFields={renderFields}
          elemProp="Display Description"
        />
      )
    }
  ]

  let validations = {
    organizationName: Yup.string(),
    productName: Yup.string().required(t("loanInsurance_Form_Product_Name")),
    productCode: Yup.string().required(t("loanInsurance_Form_Product_Code")),
    productCategory: Yup.string().required(t("loanInsurance_Form_Product_Category")),
    description: Yup.string(),
    amount: Yup.string().required(t("loanInsurance_Form_Amount")),
    productType: Yup.string().required(t("loanInsurance_Form_Product_Type")),
    productTenure: Yup.string().nullable(true),
    validFromDate: Yup.string().required(t("loanInsurance_Form_Valid_From_Date")),
    validToDate: Yup.string().required(t("loanInsurance_Form_Valid_To_Date")),
    // consentURL: Yup.string().matches(
    //   /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //   t("loanInsurance_Form_Consent_Url")
    // ),
    // imageURL: Yup.string().matches(
    //   /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //   t("loanInsurance_Form_Image_Url")
    // ),
    consentURL: Yup.string().nullable(true),
    imageURL: Yup.string().nullable(true),
    premium: Yup.string().nullable(true),
    // status: Yup.string().nullable(true),
    //dispDescription: Yup.string().nullable(true),
    //dispPremium: Yup.string().nullable(true),
    //dispAmount: Yup.string().nullable(true),
    productEligible: Yup.string(),
    active: Yup.string().required(t("loanInsurance_Form_Active")),
    largeTicket: Yup.string(),
    consentRequired: Yup.string(),
    productDispName: Yup.object(),
    productDispCategory: Yup.object(),
    dispProductTenure: Yup.object(),
    productDispNameReplicaList: Yup.array().of(
      Yup.object()
        .shape({
          subKey: Yup.string().required(t("loanInsurance_Form_Key_Label")),
          subValue: Yup.string().required(t("loanInsurance_Form_Value_Label"))             
        })
    )
    // productDispCategoryReplicaList: Yup.array().of(
    //   Yup.object()
    //     .shape({
    //       subKey: Yup.string().required(t("loanInsurance_Form_Key_Label")),
    //       subValue: Yup.string().required(t("loanInsurance_Form_Value_Label"))             
    //     })
    // ),
    // productDispTenureReplicaList: Yup.array().of(
    //   Yup.object()
    //     .shape({
    //       subKey: Yup.string().required(t("loanInsurance_Form_Key_Label")),
    //       subValue: Yup.string().required(t("loanInsurance_Form_Value_Label"))             
    //     })
    // )

  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("loanInsurance_Form_Header_Label")}
          breadcrumbs={[
            { label: t("loanInsurance_Form_Breadcrumb_Dashboard_Label"), link: "/dashboard" },
            {
              label: t("loanInsurance_Form_Breadcrumb_Loan_Insurance_Label"),
              link: "/configuration/loan-insurance-list"
            },
            { label: t("loanInsurance_Form_Breadcrumb_Loan_Insurance_Label") }
          ]}
        />
      </Grid>

      <Grid container>
        <Paper elevation={2} style={{ padding: "2em", width: "100%" }}>
          {" "}
          <Grid item xs={12}>
            {loadedPage == "add" &&
              <h2>{t("loanInsurance_Form_Title_Add_Label")}</h2>
            }
            {loadedPage == "edit" &&
              <h2>{t("loanInsurance_Form_Title_Edit_Label")}</h2>
            }
          </Grid>
          <Grid item>
            <CommonForm
              fields={fields}
              submitLabel={t("checkList_Form_Submit_Label")}
              submittingLabel={t("checkList_Form_Submitting_Label")}
              initialValues={values}
              validationSchema={Yup.object().shape(validations)}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={(values, { resetForm, setSubmitting }) => {
                //console.log("Actual Values Passed:- ", values);
                handleSubmit(values, resetForm, setSubmitting);
              }}
              formikRef={formikForm}
              buttonSize="3"
              buttonPosition="right"
              enableReinitialize
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
