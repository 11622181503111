import React, { Fragment, useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import { provisioningService } from "services/provisioningServices";
import { authenticationService } from "../../services/authServices";
import Organization from "../ConfigurationPages/Organization/Organization";
import SiteFooter from "../../components/layout/SiteFooter";

import Container from "@material-ui/core/Container";
import Icon from "@material-ui/core/Icon";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 330
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  Input: {
    minWidth: 330,
    minHeight: 50
  }
}));

const OrgSelectpage = props => {
  const classes = useStyles();
  const [org, setOrg] = useState([]);
  const [orgName, setOrgName] = useState("");
  const [alert, setAlert] = useState("");
  const [createOrg, setNewOrg] = useState(false);
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
    provisioningService.getOrganizations().then(data => {
      setOrg(data);
    });
  }, []);

  const selectOrg = e => {
    setOrgName(e.target.value);
  };
  const onSubmit = e => {
    e.preventDefault();
    if (orgName) {
      //call redux here
    } else {
      setAlert("Please Select an Organization");
      removeAlert();
    }
  };
  const removeAlert = () => {
    setTimeout(() => {
      setAlert("");
    }, 2000);
  };
  const fetchNewOrg = () => {
    setNewOrg(true);
  };

  const location = useLocation();

  const history = useHistory();

  const logOut = () => {
    authenticationService.logout().then(() => {
      history.push("/login");
    });
  };

  return (
    <Fragment>
      <>
        <Grid className="text-blue logout-btn">
          <i className="fa fa-sign-out" color="primary" onClick={logOut}>
            Logout
          </i>
        </Grid>
      </>
      {createOrg ? (
        <Organization location={location} showDashboard={false} />
      ) : (
        <>
          <div className="org-bg">
            <Container fixed className="orgSelect-wrap">
              <Grid item xs={12} sm={12} lg={6} className="mx-auto my-auto">
                <div className="org-inner">
                  <div className="text-center stella-blue mb-5 org-text">
                    <h2>Choose Organization</h2>
                  </div>
                  {alert && (
                    <>
                      <br />
                      <span className="text-center text-danger mb-5">
                        {alert}
                      </span>
                    </>
                  )}
                  <div className="d-flex justify-content-center mb-5">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel
                        ref={inputLabel}
                        id="demo-simple-select-outlined-label"
                      >
                        Select Organization
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={orgName}
                        onClick={e => selectOrg(e)}
                        labelWidth={labelWidth}
                      >
                        <MenuItem defaultValue disabled={true}>
                          Select Organization
                        </MenuItem>
                        {org.length
                          ? org.map((org, index) => (
                              <MenuItem key={index} value={org.orgName}>
                                {org.orgName}
                              </MenuItem>
                            ))
                          : ""}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="mt-5 text-center orgButton">
                    <Button
                      className={classes.Input}
                      variant="contained"
                      color="primary"
                      onClick={e => onSubmit(e)}
                    >
                      Continue
                    </Button>
                  </div>
                  <div className="mt-5 text-center">
                    <Button
                      className={classes.Input}
                      variant="outlined"
                      onClick={fetchNewOrg}
                    >
                      Create New Organization
                    </Button>
                  </div>
                  <div className="mt-5 text-center orgButton">
                    <Link to="/dashboard">
                      <Button
                        className={classes.Input}
                        variant="contained"
                        color="primary"
                      >
                        dashboard
                      </Button>
                    </Link>
                  </div>
                </div>
              </Grid>
            </Container>
          </div>
        </>
      )}
      <SiteFooter />
    </Fragment>
  );
};
export default OrgSelectpage;
