import React from "react";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(theme => ({
  inputInput: {
    padding: theme.spacing(1, 2, 1, 2),
    transition: theme.transitions.create("width"),
    width: "100%"
  },
  inputFocused: {
    border: "2px solid #34409a !important"
  },
  inputRoot: {
    width: "100%",
    borderRadius: "2em",
    border: "1px solid #F4F5F7",
    "&:hover:not($focused)": {
      border: "1px solid #166aab"
    },
    backgroundColor: "#F8F9FB"
  }
}));
function Searchbar(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <InputBase
      placeholder={t("search_Bar_Search")}
      value={props.value}
      classes={{
        root: classes.inputRoot,
        input: classes.inputInput,
        focused: classes.inputFocused
      }}
      inputProps={{ "aria-label": "search" }}
      onChange={props.onChange}
    />
  );
}
export default Searchbar;
