import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
//import { useTable, useSortBy } from "react-table";

import AlertConfiguration from "components/common/elements/AlertConfiguration";
import Table from "components/common/table/ControlledPaginationTable";
import TemplateForTables from "components/layout/innerPagesTemplate";
import { metadataService } from "services/metadataServices";

import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const CustomSwitch = withStyles({
  switchBase: {
    color: "white",
    "&$checked + $track": {
      backgroundColor: "#067533"
    },
    "&$checked": {
      color: "#067533"
    }
  },
  checked: {},
  track: {},
  thumb: {
    backgroundColor: "white"
  }
})(Switch);

export default function AlertsConfigurationTable({
  handleChange,
  alertConfig = [],
  field,
  sitesSelected,
  edit,
  configValues
}) {
  const dispatch = useDispatch();

  const [alertTypes, setAlertTypes] = React.useState(null);
  const [configType, setConfigType] = React.useState(null);
  const { t } = useTranslation();

  // const [sitesAssigned, setSitesAssigned] = React.useState({});
  console.log(alertConfig)
  React.useEffect(() => {
    metadataService.getAlertTypes().then(data => {
      setAlertTypes(data);
    });
  }, []);

  // handle the state change here and then pass the state to the formik through handleChange prop
  async function handleSwitchChange(name, index) {
    let sitesAlertConfig = [...alertConfig];
    sitesAlertConfig[index][name] = !sitesAlertConfig[index][name];
    dispatch({
      type: "USER_CONFIGURATION_ALERTS_CONFIGURATION_UPDATE_SUCCEED",
      alertConfiguration: sitesAlertConfig
    });

    handleChange(field, sitesAlertConfig);
  }

  useEffect(() => {
    if (edit) {
      dispatch({
        type: "USER_CONFIGURATION_ALERTS_CONFIGURATION_UPDATE_SUCCEED",
        alertConfiguration: configValues
      });
    }
  }, []);

  function handleEmailAlertConfig(index, values) {
    const list = [...alertConfig];
    list[index]["criticalAlerts"] = values.criticalAlerts;
    list[index]["infoAlerts"] = values.infoAlerts;
    list[index].emailAlerts = { infoAlerts: [], criticalAlerts: [] };
    list[index].emailAlerts["criticalAlerts"] = values.criticalAlerts;
    list[index].emailAlerts["infoAlerts"] = values.infoAlerts;

    setConfigType("email");

    dispatch({
      type: "USER_CONFIGURATION_ALERTS_CONFIGURATION_UPDATE_SUCCEED",
      alertConfiguration: list
    });
    handleChange(field, list);
  }
  function handleSmsAlertConfig(index, values) {
    const list = [...alertConfig];
    list[index]["criticalAlerts"] = values.criticalAlerts;
    list[index]["infoAlerts"] = values.infoAlerts;

    list[index].smsAlerts = { infoAlerts: [], criticalAlerts: [] };
    list[index].smsAlerts["criticalAlerts"] = values.criticalAlerts;
    list[index].smsAlerts["infoAlerts"] = values.infoAlerts;

    // list[index].smsAlerts["criticalAlerts"] = values.criticalAlerts;
    // list[index].smsAlerts["infoAlerts"] = values.infoAlerts;
    setConfigType("sms");

    dispatch({
      type: "USER_CONFIGURATION_ALERTS_CONFIGURATION_UPDATE_SUCCEED",
      alertConfiguration: list
    });
    handleChange(field, list);
  }

  // fetch the sites info from the sites passed in the props
  const data = React.useMemo(() => alertConfig);

  const columns = React.useMemo(() => [
    {
      Header: t("user_Form_Alert_Config_Table_Site_Name"),
      accessor: "siteName"
    },
    {
      Header: t("user_Form_Alert_Config_Table_Chilling_Unit_Id"),
      accessor: "chillingUnitId"
    },
    {
      Header: t("user_Form_Alert_Config_Table_Enable_Email"),
      accessor: "enableEmailAlerts",
      Cell: ({ row }) => {
        return (
          <>
            {/* <CustomSwitch
              checked={row.original.enableEmailAlerts}
              onChange={() =>
                handleSwitchChange("enableEmailAlerts", row.index)
              }
              value="enableEmailAlerts"
              inputProps={{ "aria-label": "switch" }}
            /> */}
            <AlertConfiguration
              changeColor={
                !!row.original.emailAlerts
                  ? row.original.emailAlerts.criticalAlerts.length ||
                  row.original.emailAlerts.infoAlerts.length
                  : false
              }
              index={row.index}
              handleSave={handleEmailAlertConfig}
              alertTypes={alertTypes}
              configType={"email"}
            />
          </>
        );
      }
    },
    {
      Header: t("user_Form_Alert_Config_Table_Enabel_Sms"),
      accessor: "enableSMSAlerts",
      Cell: ({ row }) => {
        return (
          <>
            {/* <CustomSwitch
              checked={row.original.enableSMSAlerts}
              onChange={() => handleSwitchChange("enableSMSAlerts", row.index)}
              value="enableSMSAlerts"
              inputProps={{ "aria-label": "switch" }}
            /> */}
            <AlertConfiguration
              changeColor={
                !!row.original.smsAlerts
                  ? row.original.smsAlerts.criticalAlerts.length ||
                  row.original.smsAlerts.infoAlerts.length
                  : false
              }
              index={row.index}
              handleSave={handleSmsAlertConfig}
              alertTypes={alertTypes}
              configType={"sms"}
            />
          </>
        );
      }
    }
    //    {
    //      Header: "Enable Web Alerts",
    //      accessor: "enableWebAlerts",
    //      Cell: ({ cell }) => {
    //        return (
    //          <>
    //            <CustomSwitch
    //              checked={cell.row.original.enableWebAlerts}
    //              onChange={() =>
    //                handleSwitchChange("enableWebAlerts", cell.row.index)
    //              }
    //              value="enableWebAlerts"
    //              inputProps={{ "aria-label": "switch" }}
    //            />
    //          </>
    //        );
    //      }
    //    },
    // {
    //   Header: "Configuration",
    //   Cell: ({ row }) => {
    //     return (
    //       <>
    //         <AlertConfiguration
    //           index={row.index}
    //           handleSave={handleAlertConfig}
    //           alertTypes={alertTypes}
    //         />
    //       </>
    //     );
    //   },
    // },
  ]);

  if (alertConfig.length > 0)
    return (
      <TemplateForTables needDropdown={false}>
        <div style={{ padding: "10px" }}>
          <Table data={data} columns={columns} />
        </div>
      </TemplateForTables>
    );
  return null;
}
