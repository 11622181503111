import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import AgentForm from "./AgentForm";
import { provisioningService } from "services/provisioningServices";

//Import added for Date field
import { format } from "date-fns";
import addDays from "date-fns/addDays";
import { array, element } from "prop-types";
import SweetAlert from 'react-bootstrap-sweetalert';


export default function CreateAgent() {
  const organizationName = JSON.parse(localStorage.getItem("currentUser")).organizationName;
  const organizationId = JSON.parse(localStorage.getItem("orgId"));
  const history = useHistory();
  //const [organization, setOrganization] = React.useState([]);
  const [createSuccess, setCreateSuccess] = React.useState(false);
  const [createFailure, setCreateFailure] = React.useState(false);
  const [failureMsg, setFailureMsg] = React.useState("");

  const [type, setType] = React.useState([]);
  const [category, setCategory] = React.useState([]);

  // const [states, setStates] = React.useState([]);
  // const [districts, setDistricts] = React.useState([]);
  // const [taluks, setTaluks] = React.useState([]);
  // const [villages, setVillages] = React.useState([]);
  // const [selectedState, setSelectedState] = React.useState("");

  const hideAlert = (event) => {
    setCreateSuccess(false);
    setCreateFailure(false);
  }

  // useEffect(() => {
  //   provisioningService.getOrgsAgent().then(data => {
  //     if (data) {
  //       console.log("Org for Agents:-==========", data);
  //       setOrganization(data);
  //     }
  //   });
    
  //   provisioningService.getStatesAgent().then(data => {
  //     if (data.states) {
  //       console.log("States:-==========", data.states);
  //       setStates(data.states);
  //     }
  //   });

  //   provisioningService.getDistrictsAgent("Karnataka").then(data => {
  //     if (data.districts) {
  //       console.log("Districts:-========== ", data.districts);
  //       setDistricts(data.districts);
  //     }
  //   });

  //   provisioningService.getTaluksAgent("Udupi").then(data => {
  //     if (data.taluks) {
  //       console.log("Taluks:-========== ", data.taluks);
  //       setTaluks(data.taluks);
  //     }
  //   });

  //   provisioningService.getVillagesAgent("Mangalore").then(data => {
  //     if (data.villages) {
  //       console.log("Villages:-========== ", data.villages);
  //       setVillages(data.villages);
  //     }
  //   });


  // }, []);


//   const handleChangeState = (e) => {
//     console.log("handleChangeState", e);
//     //console.log("handleChangeState", e.target.value);
//     provisioningService.getDistrictsAgent(e).then(data => {
//       if (data.districts) {
//         console.log("Districts:-========== ", data.districts);
//         setDistricts(data.districts);
//       }
//     });
//   }

// function handleStateSelect(e){
//   console.log("Selected state", e.target.value);
// }

  // to initialize the values when create template form loads first time
  let values = {
    //organization: organization,
    name: "",
    gender: "",
    //dateOfBirth: new Date(),
    dateOfBirth: null,
    addressLine1: "",
    //addressLine2: "",
    mobileNumber: null,
    emailID: "",
    username: "",
    password: ""
    // organizationid: null,
    // organizationName: "",

    // agentPincode: null,
    // agentVillage: "",
    // agentTaluk: "",
    // agentDistrict: "",
    // agentState: "",
    // agentCountry: "",
    // agreement: "",
    // status: "",
    // creditLimit: null,
    // creditDays: null,
    // socialSecurityNumber: null,
    // phoneNumber: null,
    // location: "",
    // license: "",
    // gst: "",
    // latLang: "",
    // createdDate: new Date(),
    // updatedDate: new Date(),
    // createdBy: "",
    // updatedBy: "",
    // accountName: "",
    // accountNumber: null,
    // bankName: "",
    // branch: "",
    // ifsc: "",
    // storeName: "",
    // storeAddress1: "",
    // storeAddress2: "",
    // storePincode: null,
    // storeVillage: "",
    // storeTaluk: "",
    // storeDistrict: "",
    // storeState: "",
    // storeCountry: "",
    // pnumber: null

  };

  async function handleSubmit(values, resetForm, setSubmitting) {

    //console.log("Post Obj Single Org :--------- ", values.organization);

    var datetime = new Date(values.dateOfBirth);
    values.dateOfBirth = datetime
       .toISOString().substring(0,10);
      // .replace("Z", " ")
      // .replace("T", " ");
      if(values.dateOfBirth === "1970-01-01" || values.dateOfBirth === "1969-12-31"){
        values.dateOfBirth = "";
      }

    // var datetime = new Date(values.createdDate);
    // values.createdDate = datetime
    //   .toISOString()
    //   .replace("Z", " ")
    //   .replace("T", " ");

    // var datetime = new Date(values.updatedDate);
    // values.updatedDate = datetime
    //   .toISOString()
    //   .replace("Z", " ")
    //   .replace("T", " ");

    // var organizationObj = values.organization.filter(function (item) {
    //   return item.organizationName === values.organizationName;
    // })[0];

    // values.organization = organizationObj;


    // values.organizationid = values.organization.orgId;
    // values.organizationName = values.organization.orgName;

    // delete values["organization"];
    const syncAgentObj = {username:"", email:""};
    syncAgentObj.username = values.username;
    syncAgentObj.email = values.emailID;
    try {
      const repeat = null;
      const result = await provisioningService.validateAgent(syncAgentObj);
      //console.log("Result:============= ", result);
      if(result.data === null && result.message && result.status === "failure"){
        //console.log("If Condition:==========")
        setCreateFailure(true);
        setFailureMsg(result.message);
        setSubmitting(false);
      }else{

        try {
          const repeat = null;
          const result = await provisioningService.createAgent(values);
          resetForm();
          setCreateSuccess(true);
          //resetForm();      
          //redirect to list page
          setTimeout(() => {
            history.push("/configuration/agent-list");
          }, 3000); 
          //history.push("/configuration/agent-list");
        } catch (e) {
          //console.error(e);
          setCreateFailure(true);
          setFailureMsg(e.error.message);
          setSubmitting(false);
        }

      }
    } catch (e) {
      console.error(e);
    }

    //console.log("Create Post Obj:--------- ", values);
    // try {
    //   const repeat = null;
    //   const result = await provisioningService.createAgent(values);
    //   resetForm();
    //   setCreateSuccess(true);
    //   //resetForm();      
    //   //redirect to list page
    //   setTimeout(() => {
    //     history.push("/configuration/agent-list");
    //   }, 3000); 
    //   //history.push("/configuration/agent-list");
    // } catch (e) {
    //   //console.error(e);
    //   setCreateFailure(true);
    //   setFailureMsg(e.error.message);
    //   setSubmitting(false);
    // }

    //setSubmit to false
    //call resetForm
  }

  // Calling the create template form with values(to initialize),
  return (
    <>
      <AgentForm 
        values={values} 
        handleSubmit={handleSubmit} 
        page="add" 
        // organization={organization} 
        // states={states}
        // districts={districts}
        // taluks={taluks}
        // villages={villages}
        // //handleChangeState={handleChangeState}
      />

      {createSuccess ? 
        <SweetAlert success
          title="Agent Created Successfully"
          onConfirm={hideAlert}
          //disabled={ disableOkBtn ? true : false }
          onCancel={hideAlert}
          timeout={2000}
        ></SweetAlert> 
      : null}

      {createFailure ? 
        <SweetAlert danger
          title={failureMsg}
          onConfirm={hideAlert}
          //disabled={ disableOkBtn ? true : false }
          onCancel={hideAlert}
          //timeout={3000}
        ></SweetAlert> 
      : null}

    </>

  );
}
