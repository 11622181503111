
function getToken() {
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const token = user && user.jwtAccessToken;
  return token;
}

async function getPictureBrowseBtn(picturePath){ 

const url = `${process.env.REACT_APP_POST_BANNER_BROWSE_BTN}`; 
const formData = new FormData();
formData.append('path', 'test_sample');
formData.append('image', picturePath); 

console.log("formData:", formData);
const requestOptions = {
  method: "POST", 
  body: formData
};

try {
  const response = await fetch(url, requestOptions);
  if (response.ok) {
    return await response.json();
  } else {
    throw response;
  }
} catch (error) {
  let err = {
    status: error.status,
    error: !!error.body ? await error.json() : true
  };
  throw err;
}
}

async function getDocumentBrowseBtn(picturePath){   

const url = `${process.env.REACT_APP_POST_BANNER_BROWSE_BTN}`; 
const formData = new FormData();

formData.append('path', 'test');
formData.append('image', picturePath); 
console.log("formData:", formData);
const requestOptions = {
  method: "POST", 
  body: formData
};

try {
  const response = await fetch(url, requestOptions);
  if (response.ok) {
    return await response.json();
  } else {
    throw response;
  }
} catch (error) {
  let err = {
    status: error.status,
    error: !!error.body ? await error.json() : true
  };
  throw err;
}
}

export const browseBtnService = { 
  getPictureBrowseBtn,
  getDocumentBrowseBtn
};