import React, { useState ,useContext } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { FieldArray,Field , getIn} from "formik";
import { provisioningService } from "services/provisioningServices";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";
import { errorContext } from "context/errorContext";
import OrganizationChipRetailerCriteria from "./OrganizationChipRetailerCriteria";
import MultiSelectAll from "../elements/MultiSelectAll";
import CommonForm from "components/common/form/common-form";
import * as Yup from "yup";

export default function OrganizationSelectionRetailerCriteria({
  name,
  metaProps = { margin: "dense", variant: "outlined", fullWidth: true },
  organizationData,
  setFieldValue,
  values,
  roleName

}) {
  
  //console.log("Values in Org Selction--------", values);
  const [orgValue, setOrgValue] = useState("");
  const [chillingCenterData,setChillingCenterData] = React.useState("");
  const [vlccData,setVlccData] = React.useState([]);
  const [vlccValue,setVlccValue] = React.useState("");

  const [chillingCenterValue,setChillingCenterValue] = useState("");

  const { dispatch: errorDispatch } = useContext(errorContext);

  const [orgError,setOrgError] = useState("");
  const [ccError,setCcError] = useState("");
  const [vlccError,setVlccError] = useState("");

  const [orgSelectionLimitMessage,setOrgSelectionLimitMessage] = useState("");

  const { t } = useTranslation();

  function handleOrgChange(value){
      setChillingCenterData("");
      setChillingCenterValue("");
      setVlccData([]);
      setVlccValue("");   
    provisioningService.getCCByOrg(value.orgId).then(data => {
      setChillingCenterData(data);    
    }).catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });  
  }

  function handleChillingCenterChange(e,setFieldValue,value){
    provisioningService.getVlccByOrg(e.chillingCenterId).then(data => {
      setVlccData(data);
    }).catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
    });
  }

  async function handleOrganizationData() {
    //console.log("Its me!!!")
    setOrgError("");
    setCcError("");
    setVlccError("");
    if(orgValue === ""){
      setOrgError("Please select Organization");
      return;
    }

    if(chillingCenterValue === ""){
      setCcError("Please select Chilling Center");
      return;
    }

    if(vlccValue === ""){
      setVlccError("Please select Vlcc");
    }

    let selectedData = {};

    selectedData.organizationName = orgValue;
    selectedData.ccName = chillingCenterValue;
    selectedData.vlccName = vlccValue;

    let isDuplicate =false;
    for(var i=0;i<values.length;i++){
      if(values[i].organizationName === orgValue && values[i].ccName === chillingCenterValue &&
        values[i].vlccName === vlccValue){
        isDuplicate = true;
        break;
      }
    }
    if(!isDuplicate){
      values.push(selectedData);
    }

    //console.log(values);

    setFieldValue(values);
    setChillingCenterData("");
    setOrgValue("");
    setChillingCenterValue("");
    setVlccData([]);
    setVlccValue("");
    
  }

  const formikForm = React.useRef(null);

  const fields = [
    {
      type: "singleSelectSearch",
      name: "organizationName",
      label: t("agent_Association_Form_Organization_Selection_Label"),
      placeholder: t("agent_Association_Form_Organization_Selection_Label"),
      option:
        organizationData.length > 0
          ? organizationData.map(orgData => ({
              value: orgData,
              label: orgData.orgName
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      },
      customOnChange:handleOrgChange
    },
    {
      type: "singleSelectSearch",
      name: "ccName",
      label: t("agent_Association_Form_ChillingCenter_Label"),
      placeholder: t("agent_Association_Form_ChillingCenter_Label"),
      option:
       chillingCenterData.length > 0
          ? chillingCenterData.map(ccData => ({
              value: ccData,
              label: ccData.chillingCenterName
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      },
      customOnChange:handleChillingCenterChange
    }
    ,
    {
      type: "singleSelectSearch",
      name: "vlccName",
      label: t("agent_Association_Form_Vlcc_Label"),
      placeholder: t("agent_Association_Form_Vlcc_Label"),
      option:
      vlccData.length > 0
          ? vlccData.map(vlccData => ({
              value: vlccData,
              label: vlccData.vlccName
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    }
  ];

  let validations = {
    organizationName : Yup.string().required(
      //t("agent_Association_Form_Organization_Selection_Label")
      "Is required"
    ).nullable(),
    // ccName : Yup.string().required(
    //   //t("agent_Association_Form_ChillingCenter_Label")
      // "Is required"
      // ).nullable(),
    // vlccName : Yup.string().required(
    //   //t("agent_Association_Form_Vlcc_Label")
      // "Is required"
      // ).nullable()
  };

  function handleSubmit(data, resetForm, setSubmitting) {
    //console.log("its hittin");
    if(roleName === "MQP_retailer" || roleName === "MEP_retailer"){
      if(values.length >= 1){
        setOrgSelectionLimitMessage("One Criteria is allowed for MEP & MQP Role.");
        return;
      }
    }
    let isDuplicate =false;
    for(var i=0;i<values.length;i++){
      if(values[i].organizationName === data.organizationName && JSON.stringify(values[i].ccName) === JSON.stringify(data.ccName) && 
      JSON.stringify(values[i].vlccName) === JSON.stringify(data.vlccName)){
        isDuplicate = true;
        break;
      }
    }
    if(!isDuplicate){
      values.push(data);
    }

    setFieldValue(values);
    setChillingCenterData("");
    setVlccValue([]);
    resetForm();

    // setOrgValue("");
    // setChillingCenterValue("");
    // setVlccValue("");
  }

  let initialValues = {
    organizationName:"",
    ccName:"",
    vlccName:""
  } 

  return (   
  //   <Grid container item xs={12} className="mb-0 d-flex flex-direction-column device-wrapper">
  //     <Grid item container xs={12}  spacing={2} alignContent="flex-start" className="site-inner">
    <Grid container>
      <Grid item xs={12}>
        <CommonForm
          fields={fields}
          submitLabel={t("agent_Association_Form_Submit_Label")}
          submittingLabel={t("site_Form_Submitting_Label")}
          initialValues={initialValues}
          validationSchema={Yup.object().shape({ ...validations })}
          validateOnBlur={true}
          enableReinitialize
          validateOnChange={true}
          showSubmitButton={false}
          showOrgSubmitButton={true}
          onSubmit={(
            values,
              { setSubmitting, setFieldError, resetForm, errors }
            ) => {
              if (!!errors) {
                setSubmitting(false);
              }
              handleSubmit(values, resetForm, setSubmitting);
            }}
          formikRef={formikForm}
          buttonSize="1"
          buttonPosition="left"
        />

        {orgSelectionLimitMessage && (
            <div style={{ color: "#f44336" }}>{orgSelectionLimitMessage}</div>
         )}
       </Grid>
       <Grid item xs={11} md={3}>
        <Grid item style={{ marginTop: "8px", marginLeft: "-10px" }}>
          <OrganizationChipRetailerCriteria
            values={values}
            setFieldValue={setFieldValue}
          />
        </Grid>
        </Grid>
      </Grid>
    );
}
