import React, { useEffect, useContext, useState, useMemo } from "react";

import { Link } from "react-router-dom";

import Table from "components/common/table/DynamicPaginationTable2";
import PageHeader from "../../../components/layout/PageHeader.js";
import { provisioningServices } from "../../../services/provisioningServices.js";

import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import { errorContext } from "context/errorContext";

import "../../../components/Table.scss";

import { useTranslation } from "react-i18next";

const TotalSite = props => {
    const { dispatch: errorDispatch } = useContext(errorContext);

    const [sites, setSites] = useState([]);
    const [pageInfo, setPageInfo] = useState({});
    const [status, setStatus] = useState("loading");
    const { t } = useTranslation();

    let query = useMemo(() => new URLSearchParams(props.location.search), [
        props.location
    ]);

    const dropitems = [
        {
        item: <Link to="/configuration/site">{t("site_List_Drop_Down_Add_Site")}</Link>
        },
        { item: <Link to="/configuration/import-site">{t("site_List_Drop_Down_Import_Site")}</Link> }
      ];
    useEffect(() => {
        if (!!query.get("page") && !!query.get("size")) {
            setStatus("loading");
            provisioningServices("get", "sites", {
                pageSize: query.get("size"),
                pageIndex: query.get("page")
            })
                .then(data => {
                    setSites(data.content);
                    setPageInfo(data.pageInfo);
                    setStatus("success");
                })
                .catch(error => {
                    console.error(error);
                    errorDispatch({
                        type: "ERROR",
                        error
                    });
                    setStatus("failed");
                });
        } else {
            props.history.push({
                pathname: props.location.pathname,
                search: `?page=0&size=1000`
            });
        }
    }, [query]);

    const columns = React.useMemo(
        () => [
            {
                Header: t("site_List_Site_Id_Label"),
                accessor: "siteId",
                Cell: ({ row }) => {
                    return (
                        <Link
                            to={`/totalsites/site-details/${row.original.siteId}`}
                        >
                            {row.original.deviceType === "BMC" && (
                                <img
                                    className="bmc-icon"
                                    src="/assets/graph-icons/bmc.png"
                                    alt=""
                                />
                            )}
                            {row.original.deviceType === "ABC" && (
                                <img
                                    className="bmc-icon"
                                    src="/assets/graph-icons/abc.png"
                                    alt=""
                                />
                            )}
                            <span>{row.original.siteId}</span>
                        </Link>
                    );
                }
            },
            {
                Header: t("site_List_Site_Name_Label"),
                accessor: "siteName"
            },
            {
                Header: t("site_List_Org_Name_Label"),
                accessor: "organizationName"
            },
            {
                Header: t("site_List_Sub_Org_Name"),
                accessor: "subOrganizationName"
            },
            {
                Header: t("site_List_Bmcs"),
                accessor: "numberOfChillingUnit"
            },
            {
                Header: t("site_List_Devices"),
                accessor: "numberOfL1Devices"
            },
            {
                Header: t("site_List_Actions"),

                Cell: ({ row }) => {
                    let link = `/configuration/site/${row.original.siteId}`;
                    return (
                        <>
                            <td>
                                <Link to={link}>
                                    <IconButton>
                                        <i className="fa fa-edit" aria-hidden="true"></i>
                                    </IconButton>
                                </Link>
                            </td>
                            {/* <td>
                                <IconButton>
                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                </IconButton>
                            </td> */}
                        </>
                    );
                }
            }
        ],
        []
    );

    const data = React.useMemo(() => [...sites]);
    return (
        <Grid container>
            <Grid item xs={12}>
                <PageHeader
                    title={t("site_List_Header_Label")}
                    breadcrumbs={[
                        { label: t("site_List_Breadcrum_Dashboard_Label"), link: "/dashboard" },
                        { label: t("site_List_Breadcrum_Configuration_Label") },
                        { label: t("site_List_Total_Sites_Label") }
                    ]}
                />
            </Grid>
            {status === "loading" ? (
                <Grid
                    container
                    xs={12}
                    justify="center"
                    alignItems="center"
                    style={{ height: "50vh" }}
                >
                    <CircularProgress />
                </Grid>
            ) : (
                    <Table
                        columns={columns}
                        data={data}
                        needDropdown={true}
                        dropItems={dropitems}
                        fileName={"siteList.xls"}
                    />
                )}
        </Grid>
    );
};

export default TotalSite;
