import React, { useEffect, useContext, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import "../../../components/Table.scss";
import { provisioningService } from "../../../services/provisioningServices.js";
import Table from "components/common/table/DynamicPaginationTable2";
import PageHeader from "components/layout/PageHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { itemLevelConfigurationService } from "services/itemLevelConfigurationServices";
import ItemLevelConfigurationTabs from "./ItemLevelConfigurationTabs";

import { errorContext } from "context/errorContext";

import { UseTranslation, useTranslation } from "react-i18next";
import * as Yup from "yup";
import CommonForm from "components/common/form/common-form";
import Paper from "@material-ui/core/Paper";


const ItemLevelConfiguration = props => {

    const { t } = useTranslation();
    const { id } = useParams();
    const history = useHistory();

    const [status, setStatus] = React.useState("loading");
    const [pageInfo, setPageInfo] = React.useState({});
    const [itemConfigList, setItemConfigList] = React.useState([]);
    const [pageSize, setPageSize] = React.useState(10);
    const [pageIndex, setPageIndex] = React.useState(0);

    const { dispatch: errorDispatch } = useContext(errorContext);


    const [productNameStatus, setProductNameStatus] = useState("loading");
    const [productName, setproductName] = React.useState([]);
    const [configItem, setConfigItem] = React.useState(['cod']);
    const [showTabs, setShowTabs] = React.useState(false);
    const [productPriceMappingByProdId, setProductPriceMappingByProdId] = React.useState([]);
    const [productDetailsByProdId, setProductDetailsByProdId] = React.useState([]);
    const [codEnabledData, setCodEnabledData] = React.useState(['true','false']);

    const [selectProductName, setSelectProductName] = useState(false);


    useEffect(() => {
    
        setStatus("loading");
        provisioningService
          .verifyOtpNew('1313131313')
          .then((res) => {
            const bearerToken = localStorage.getItem("bearerToken");
    
            itemLevelConfigurationService
              .getItemLevelConfigurationAllProducts(1000,0)
            //provisioningService
            //  .getItemLevelConfigList()
              .then(data => {
                //console.log("All products:====== ", data);
                //setItemConfigList(data.content || []);
                setproductName(data.content || []);
                setPageInfo(data.pageInfo);
                setStatus("success");
                setProductNameStatus("success");
                //setItemConfigListByIdStatus("success");
              })
              .catch(error => {
                //console.log("Inner Catch:======", error)
                errorDispatch({
                  type: "ERROR",
                  error
                });
              });
    
    
          })
          .catch(error => {
            //console.log("Outer Catch:======", error)
            errorDispatch({
              type: "ERROR",
              error
            });
          });
        
    }, [ ]);

    let values = {
        productName: productName,
        configItem: 'cod',
        //productDetails: productDetailsByProdId[0],
        //productPriceMappingDetails: productPriceMappingByProdId[0]
    };

    const formikForm = React.useRef(null);

    const fields = [
        {
            type: "singleSelectSearch",
            name: "productName",
            label: t("itemLevelConfig_ProductName_Label"),
            placeholder: t("itemLevelConfig_ProductName_Label"),
            option:
            productName.length > 0
                ? productName.map(productName => ({
                    value: productName,
                    label: productName.productName
                    }))
                : [],
            grid: {
                xs: 12,
                sm: 2,
                md: 3,
                className: ""
            },
            customOnChange: handleSelectChange
        },
        {
            type: "select",
            name: "configItem",
            label: t("itemLevelConfig_ConfigItem_Label"),
            placeholder: t("itemLevelConfig_ConfigItem_Label"),
            option:
            configItem.length > 0
                ? configItem.map(configItem => ({
                    value: configItem,
                    label: configItem
                    }))
                : [],
            grid: {
                xs: 12,
                sm: 2,
                md: 3,
                className: ""
            }
        }
    ]; 

    // let validations = {
    //     productName: Yup.string().required(
    //         //t("itemLevelConfig_ProductName_Label")
    //         "Is required"
    //     ).nullable()
    // };

    const nextPage = () => {
        if (
          status !== "loading" &&
          pageInfo.hasNextPage &&
          pageIndex < pageInfo.totalPages
        ) {
          let index = pageIndex;
          setPageIndex(++index);
        }
    };

    const previousPage = () => {
        let index = pageIndex;
        if (index > 0) {
          setPageIndex(--index);
        }
    };

    function getPriceMappingValues(prodId){
        //provisioningService
        itemLevelConfigurationService
            .getItemLevelConfigPriceMappingList(prodId)
            .then(data1 => {      
            setProductPriceMappingByProdId(data1.content);
            setSelectProductName(false);
            //console.log("Price Mapping Details:===== ", data1);
            // setPageInfo(data1.pageInfo);
            //setItemConfigPriceMappingListStatus("success");
            //setShowTabs(true);
            })
            .catch(error => {
            errorDispatch({
                type: "ERROR",
                error
            });
            });
    }


    function handleSubmit(values, resetForm, setSubmitting) {
        //console.log("Submit values:======= ", values)
        if(values.productName == null || values.productName.id == undefined || values.productName.id == null || values.productName.id == '' ){
            setSelectProductName(true);
            setSubmitting(false);
            setShowTabs(false);
            return;
        }
        //provisioningService
        itemLevelConfigurationService
                  .getItemLevelConfigListById(values.productName.id)
                  .then(data2 => {                  
                    setProductDetailsByProdId(data2 || []);
                    //console.log("Prod Details:===== ", data2);
                    // setPageInfo(data2.pageInfo);
                    //setItemConfigListByIdStatus("success");
                    setShowTabs(true);
                    setSelectProductName(false);
    
                        getPriceMappingValues(values.productName.id);
                        // provisioningService
                        // itemLevelConfigurationService
                        //   .getItemLevelConfigPriceMappingList(values.productName.id)
                        //   .then(data11 => {
                        //     console.log("Price Mapping Details:===== ", data11);
                        //     setProductPriceMappingByProdId(data11|| []);
                        //     // setPageInfo(data.pageInfo);
                        //     //setItemConfigPriceMappingListStatus("success");
                        //     //setShowTabs(true);
                        //   })
                        //   .catch(error => {
                        //     errorDispatch({
                        //       type: "ERROR",
                        //       error
                        //     });
                        //   });
    
    
                  })
                  .catch(error => {
                    errorDispatch({
                      type: "ERROR",
                      error
                    });
                  });
    
        setSubmitting(false);
    }

    function handleSelectChange(value){
        if(value == null){
            setShowTabs(false);
            return;
        }
    }


    return(
        
        <Grid container>
            <Grid item xs={12}>
                <PageHeader
                title={t("itemLevelConfig_Header_Label")}
                breadcrumbs={[
                    { label: t("retailer_Breadcrumb_Dashboard_Label"), link: "/dashboard" },
                    { label: t("retailer_Breadcrumb_Config_Label") },
                    { label: t("retailer_Breadcrumb_Retailer_Label") }
                ]}
                />
            </Grid>

            <Paper
                elevation={2}
                style={{
                padding: "2em",
                width: "100%"
                }}
            >

             {fields.productName}   

            {productNameStatus === "loading" ? (
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    style={{ height: "50vh" }}
                >
                    <CircularProgress />
                </Grid>
                ):(
            <CommonForm
                fields={fields}
                submitLabel={t("itemLevelConfig_Submit_Btn")}
                submittingLabel={t("itemLevelConfig_Submitting_Btn")}
                initialValues={values}
                // validationSchema={Yup.object().shape({ ...validations })}
                // validateOnBlur={true}
                // validateOnChange={true}
                onSubmit={(
                values,
                { setSubmitting, setFieldError, resetForm, errors }
                ) => {
                if (!!errors) {
                    setSubmitting(false);
                }
                handleSubmit(values, resetForm, setSubmitting);
                }}
                formikRef={formikForm}
                buttonSize="2"
                buttonPosition="right"
                inlineButton={true}
            />
            )}

            {selectProductName == true ? <h6 style={{color: "red"}}>Please select atleast one product</h6> : null}

            {showTabs === true ? (  
                <ItemLevelConfigurationTabs
                    //valuesBasic={values.productName}
                    valuesBasic={productDetailsByProdId}
                    //valuesBasic={values.productDetails}
                    valuesAdvanced={productPriceMappingByProdId}
                    //valuesAdvanced={values.productPriceMappingDetails}
                    codEnabledData={codEnabledData}
                    handleSubmit={handleSubmit}
                    showSubmitButton={true}
                    //handleSubmitBasic={handleSubmit}
                    // status={status}
                    // customerGroupData={customerGroupData}
                    // pbpData={pbpData}
                    // roleList={roleList}
                    setProductDetailsByProdId={setProductDetailsByProdId}
                    setProductPriceMappingByProdId={setProductPriceMappingByProdId}
                /> )
                : null
                }



            </Paper>
        </Grid>

    );
};

export default ItemLevelConfiguration;
