import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authContext } from "context/authContext";
import { customeGroupService } from "services/customerGroupServices";
import addDays from "date-fns/addDays";
import { errorContext } from "context/errorContext";
import { useTranslation } from "react-i18next";
import FailureFeedback from "components/common/elements/FailureFeedback";
import { provisioningService } from "../../../services/provisioningServices.js";
import CustomerGroupForm from "./CustomerGroupForm";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
import { Link, useHistory } from "react-router-dom";

export default function CreateCustomerGroup() {
  const { dispatch: errorDispatch } = useContext(errorContext);
  const org = JSON.parse(localStorage.getItem("currentUser")).organizationName;
  const [sourceTypeData, setSourceTypeData] = React.useState([]);
  const [customerGroupStatus, setCustomerGroupStatus] = useState("loading");
  const [duplicateErrorStatus,setDuplicateErrorStatus] = React.useState(false);
  const [createSuccessStatus,setCreateSuccessStatus] = React.useState(false);
  const [errorStatus,setErrorStatus] = React.useState(false);
  const history = useHistory();

  const { t } = useTranslation();

  const [status, setStatus] = useState("loading");
  
  function getSourceType(){
    customeGroupService.getAllSourceType().then(data => {
      if(data){
        setSourceTypeData(data);
        console.log(data);
        setCustomerGroupStatus("success");
      }
    }).catch(error => {
      if(error.status === 409){
        setDuplicateErrorStatus(true);
      }       
    });
  }
  
  useEffect(() => {
    provisioningService.verifyOtpNew('1313131313').then((res) => {
      const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
      const organizationId = JSON.parse(localStorage.getItem("orgId"));
      getSourceType();
    })
    .catch(error => {
       errorDispatch({
         type: "ERROR",
         error
       });
    });
  }, []);
 
  function handleSubmit(values, resetForm, setSubmitting) {
    values.createdBy = JSON.parse(localStorage.getItem("currentUser")).userName;
    customeGroupService.createCustomerGroup(values).then(data => {
      console.log(data);
      setSubmitting(false);
      resetForm();
      setCreateSuccessStatus(true);
    }).catch(error => {
      setSubmitting(false);
      if(error.status === 409){
        setDuplicateErrorStatus(true);
      }else{
        setErrorStatus(true);
      }
    });
  }

  const handleAlertClose = () => {
    setDuplicateErrorStatus(false);
    setErrorStatus(false);
  }

  const handleSuccessDialogClose = () =>{
    history.push("/configuration/customergroup");
    setCreateSuccessStatus(false);
  }

  let values = {
    sourceType:"",
    name:"",
    description:""
  };

  return (
    <>
      <CustomerGroupForm
        values={values}
        handleSubmit={handleSubmit}
        sourceTypeData={sourceTypeData}
        status={status} />

      <SuccessFeedback
        open={createSuccessStatus === true}
        onClose={handleSuccessDialogClose}
        successMessage={t("CustomerGroup_Form_Success_Message")}
      />

      <FailureFeedback
        open={duplicateErrorStatus === true}
        onClose={handleAlertClose}
        status={"Failed"}
        message={t("CustomerGroup_Form_Duplicate_Failure_Message")}
        showButtons={true} />

      <FailureFeedback
        open={errorStatus === true}
        onClose={handleAlertClose}
        status={"Failed"}
        message={t("CustomerGroup_Form_Error_Message")}
        showButtons={true} />
    </>  
  );
}
