import React from "react";

function SiteFooter({l}) {
  return (
    <footer
      style={{
        padding: "2em 0em 2em 0em",
        color: l===true?"white":"gray",//to check if called from Login page
        fontSize: "12px",
        textAlign: "center",
        width: "100%"
      }}
    >
      Copyright © 2024 Stellapps
    </footer>
  );
}

export default SiteFooter;
