import React, { useState, useEffect } from "react";

import { FieldArray, Field, getIn } from "formik";

import { CustomizedPrimaryButton } from "../elements/CustomizedPrimaryButton";
import { metadataService } from "services/metadataServices";
import DeviceProfile from "./DeviceProfile";

import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { TextField } from "formik-material-ui";
import { provisioningService } from "services/provisioningServices";
import { useTranslation } from "react-i18next";

export default function ChillingUnitFields({
  name,
  edit,
  renderFields,
  formProps,
  otherVendors,
  handleVendorSelect
}) {
  const {
    setFieldValue,
    handleChange,
    handleBlur,
    setFieldError,
    setFieldTouched,
    errors,
    values
  } = formProps;
  const [productTypes, setProductTypes] = useState([]);
  const [productTypesSelected, setProductTypesSelected] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [vendorTypes, setVendorTypes] = useState([]);
  const [coolingMethod, setCoolingMethod] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    metadataService.getProductTypes().then(data => setProductTypes(data));
    metadataService.getCountryCodes().then(data => {
      let types = data.map(type => type.typeName);
      setCountryCodes(types);
    });
    metadataService.getVendorTypes().then(data => {
      let types = data.map(type => type.typeName);
      setVendorTypes(types);
    });
    provisioningService.methodOfCooling().then(d => setCoolingMethod(d));
  }, []);

  useEffect(() => {
    if (getIn(values, `${name}`).length > 0) {
      let productTypesSelected = getIn(values, `${name}`).map(unit => {
        if (!!unit) {
          let productType = !!unit.productType ? unit.productType.name : "ABC";
          return productType;
        }
      });
      setProductTypesSelected(productTypesSelected);
    }
  }, [values]);

  useEffect(() => {
    if (vendorTypes.length > 0 && getIn(values, `${name}`).length > 0) {
      for (let i = 0; i < getIn(values, `${name}`).length; ++i) {
        // if the value is not in vendor types, set vendor to others and add other vendor field. [during edit]
        if (
          edit &&
          !!getIn(values, `${name}`)[i].chillingUnit &&
          !!getIn(values, `${name}`)[i].chillingUnit.vendor &&
          !vendorTypes.includes(getIn(values, `${name}`)[i].chillingUnit.vendor)
        ) {
          const vendor = getIn(values, `${name}`)[i].chillingUnit.vendor;
          setFieldValue(
            `chillingUnitMonitoringDetailsList.${i}.chillingUnit.vendor`,
            "Others",
            false
          );
          setFieldValue(
            `chillingUnitMonitoringDetailsList.${i}.chillingUnit.otherVendor`,
            vendor,
            false
          );
          handleVendorSelect("Others");
        }
      }
    }
  }, [vendorTypes]);

  const chillingUnitFields = index => {
    return {
      ABC: [
        {
          name: `${name}.${index}.chillingUnit.chillingCenterName`,
          fieldName: "chillingCenterName",
          label: t("chilling_Unit_Form_Chilling_Center_Name"),
          type: "text",
          grid: {
            xs: 12,
            sm: 3
          }
        },
        {
          name: `${name}.${index}.chillingUnit.chillingCenterId`,
          fieldName: "chillingCenterId",
          label: t("chilling_Unit_Form_Chilling_Center_Id"),
          type: "text",
          grid: {
            xs: 12,
            sm: 3
          }
        },
        {
          name: `${name}.${index}.chillingUnit.collectionCenterType`,
          fieldName: "collectionCenterType",
          label: t("chilling_Unit_Form_Collection_Center_Type"),
          type: "text",
          grid: {
            xs: 12,
            sm: 3
          }
        },
        {
          name: `${name}.${index}.chillingUnit.societyId`,
          fieldName: "societyId",
          label: t("chilling_Unit_Form_Society_Id"),
          type: "text",
          grid: {
            xs: 12,
            sm: 3
          }
        },
        {
          name: `${name}.${index}.chillingUnit.societyLongId`,
          fieldName: "societyLongId",
          label: t("chilling_Unit_Form_Society_Long_Id"),
          type: "text",
          grid: {
            xs: 12,
            sm: 3
          }
        },
        {
          name: `${name}.${index}.chillingUnit.capacity`,
          fieldName: "capacity",
          label: t("chilling_Unit_Form_Capacity"),
          type: "text",
          grid: {
            xs: 12,
            sm: 3
          }
        },
        {
          name: `${name}.${index}.chillingUnit.chillingCapacity`,
          fieldName: "chillingCapacity",
          label: "Chilling Capacity",
          type: "text",
          grid: {
            xs: 12,
            sm: 3
          }
        },
        {
          name: `${name}.${index}.chillingUnit.cutOffTemperature`,
          fieldName: "cutOffTemperature",
          label: "Cut Off Temperature",
          type: "text",
          grid: {
            xs: 12,
            sm: 3
          }
        },
        {
          name: `${name}.${index}.chillingUnit.cutInTemperature`,
          fieldName: "cutInTemperature",
          label: "Cut In Temperature",
          type: "text",
          grid: {
            xs: 12,
            sm: 3
          }
        },
        {
          name: `${name}.${index}.chillingUnit.ownerName`,
          fieldName: "ownerName",
          label: t("chilling_Unit_Form_Owner_Name"),
          type: "text",
          grid: {
            xs: 12,
            sm: 3
          }
        },
        {
          name: `${name}.${index}.chillingUnit.countryCode`,
          fieldName: "countryCode",
          label: t("chilling_Unit_Form_Country_Code"),
          type: "select",
          option:
            !!countryCodes && countryCodes.length > 0
              ? countryCodes.map(type => ({
                  value: type,
                  label: type
                }))
              : [],
          grid: {
            xs: 12,
            sm: 3
          }
        },
        {
          name: `${name}.${index}.chillingUnit.ownerContactNum`,
          fieldName: "ownerContactNum",
          label: t("chilling_Unit_Form_Owner_Contact_Number"),
          type: "text",
          grid: {
            xs: 12,
            sm: 3
          }
        },
        {
          name: `${name}.${index}.chillingUnit.vendor`,
          fieldName: "vendor",
          label: t("chilling_Unit_Form_Vendor"),
          type: "select",
          option:
            !!vendorTypes && vendorTypes.length > 0
              ? vendorTypes.map(type => ({
                  value: type,
                  label: type
                }))
              : [],
          customOnChange: handleVendorSelect,
          grid: {
            xs: 12,
            sm: 3
          }
        },
        !!otherVendors
          ? {
              name: `${name}.${index}.chillingUnit.otherVendor`,
              fieldName: "otherVendor",
              label: "Other Vendor",
              type: "text",
              grid: {
                xs: 12,
                sm: 3
              }
            }
          : null,
        {
          name: `${name}.${index}.chillingUnit.manufacturedYear`,
          fieldName: "manufacturedYear",
          label: t("chilling_Unit_Form_Manufactured_Year"),
          type: "text",
          grid: {
            xs: 12,
            sm: 3
          }
        },
        {
          name: `${name}.${index}.chillingUnit.methodOfCooling`,
          fieldName: "methodOfCooling",
          label: t("chilling_Unit_Form_Method_Of_Cooling"),
          type: "select",
          option:
            !!coolingMethod && coolingMethod.length > 0
              ? coolingMethod.map(type => ({
                  value: type.typeName,
                  label: type.typeDisplayName
                }))
              : [],
          grid: {
            xs: 12,
            sm: 3
          }
        }
      ],
      BMC: [
        {
          name: `${name}.${index}.chillingUnit.customerBmcId`,
          fieldName: "customerBmcId",
          label: t("chilling_Unit_Form_Bmc_Id"),
          type: "text",
          grid: {
            xs: 12,
            sm: 3
          }
        },
        {
          name: `${name}.${index}.chillingUnit.bmcName`,
          fieldName: "bmcName",
          label: t("chilling_Unit_Form_Bmc_Name"),
          type: "text",
          grid: {
            xs: 12,
            sm: 3
          }
        },
        {
          name: `${name}.${index}.chillingUnit.capacity`,
          fieldName: "capacity",
          label: t("chilling_Unit_Form_Capacity"),
          type: "text",
          grid: {
            xs: 12,
            sm: 3
          }
        },
        {
          name: `${name}.${index}.chillingUnit.chillingCapacity`,
          fieldName: "chillingCapacity",
          label: "Chilling Capacity",
          type: "text",
          grid: {
            xs: 12,
            sm: 3
          }
        },
        {
          name: `${name}.${index}.chillingUnit.cutOffTemperature`,
          fieldName: "cutOffTemperature",
          label: "Cut Off Temperature",
          type: "text",
          grid: {
            xs: 12,
            sm: 3
          }
        },
        {
          name: `${name}.${index}.chillingUnit.cutInTemperature`,
          fieldName: "cutInTemperature",
          label: "Cut In Temperature",
          type: "text",
          grid: {
            xs: 12,
            sm: 3
          }
        },
        {
          name: `${name}.${index}.chillingUnit.ownerName`,
          fieldName: "ownerName",
          label: t("chilling_Unit_Form_Owner_Name"),
          type: "text",
          grid: {
            xs: 12,
            sm: 3
          }
        },
        {
          name: `${name}.${index}.chillingUnit.countryCode`,
          fieldName: "countryCode",
          label: t("chilling_Unit_Form_Country_Code"),
          type: "select",
          option:
            !!countryCodes && countryCodes.length > 0
              ? countryCodes.map(type => ({
                  value: type,
                  label: type
                }))
              : [],
          grid: {
            xs: 12,
            sm: 3
          }
        },
        {
          name: `${name}.${index}.chillingUnit.ownerContactNum`,
          fieldName: "ownerContactNum",
          label: t("chilling_Unit_Form_Owner_Contact_Number"),
          type: "text",
          grid: {
            xs: 12,
            sm: 3
          }
        },
        {
          name: `${name}.${index}.chillingUnit.vendor`,
          fieldName: "vendor",
          label: t("chilling_Unit_Form_Vendor"),
          type: "select",
          option:
            !!vendorTypes && vendorTypes.length > 0
              ? vendorTypes.map(type => ({
                  value: type,
                  label: type
                }))
              : [],
          customOnChange: handleVendorSelect,
          grid: {
            xs: 12,
            sm: 3
          }
        },
        !!otherVendors
          ? {
              name: `${name}.${index}.chillingUnit.otherVendor`,
              fieldName: "otherVendor",
              label: "Other Vendor",
              type: "text",
              grid: {
                xs: 12,
                sm: 3
              }
            }
          : null,
        {
          name: `${name}.${index}.chillingUnit.manufacturedYear`,
          fieldName: "manufacturedYear",
          label: t("chilling_Unit_Form_Manufactured_Year"),
          type: "text",
          grid: {
            xs: 12,
            sm: 3
          }
        },
        {
          name: `${name}.${index}.chillingUnit.methodOfCooling`,
          fieldName: "methodOfCooling",
          label: t("chilling_Unit_Form_Method_Of_Cooling"),
          type: "select",
          option:
            !!coolingMethod && coolingMethod.length > 0
              ? coolingMethod.map(type => ({
                  value: type.typeName,
                  label: type.typeDisplayName
                }))
              : [],
          grid: {
            xs: 12,
            sm: 3
          }
        }
      ]
    };
  };

  // update the selected list
  // set the chillingUnit fields in the formik values as the new selected Type and
  function handleProductTypeChange(type, index) {
    handleVendorSelect("");
    let selected = [...productTypesSelected];
    if (selected[index] === type) return; // if the type is not changed, don't run the rest
    selected[index] = type;
    let chillingUnit = {};
    chillingUnitFields(index)[selected[index]].forEach(
      field => !!field && (chillingUnit[field.fieldName] = "")
    );
    const missingProps = {
      l1DeviceInfo: [
        {
          serialNumber: "",
          deviceType: "",
          gatewaySerialNumber: "",
          l0Config: [
            {
              deviceAttribute: "",
              applicationAttribute: "",
              unit: ""
            }
          ]
        }
      ],
      productType: {
        name: type
      },
      chillingUnit: {
        chillingCenterName: "",
        chillingCenterId: "",
        collectionCenterType: "",
        societyId: "",
        capacity: "",
        cutOffTemperature: "",
        cutInTemperature: "",
        chillingCapacity: "",
        ownerName: "",
        countryCode: "",
        ownerContactNum: "",
        vendor: "",
        otherVendor: "",
        manufacturedYear: "",
        methodOfCooling: ""
      },
      chillingUnitId: null,
      chillingUnitUuid: null
    };
    if (!!edit && type === "ABC") {
      setFieldValue(
        `chillingUnitMonitoringDetailsList.${index}`,
        missingProps,
        false
      );
      setFieldTouched(
        `chillingUnitMonitoringDetailsList.${index}`,
        false,
        false
      );
    } else {
      setFieldValue(
        `chillingUnitMonitoringDetailsList.${index}.chillingUnit`,
        chillingUnit,
        false
      );
      setFieldTouched(
        `chillingUnitMonitoringDetailsList.${index}.chillingUnit`,
        false,
        false
      );
    }
    setProductTypesSelected(selected);
  }

  return (
    <>
      <span>
        <h3>{t("site_Form_Chilling_Unit_Header_Label")}</h3>
      </span>
      <FieldArray
        name={name}
        render={({ insert, remove, push }) => (
          <div className="mt-2 ">
            {getIn(values, `${name}`).length > 0 &&
              getIn(values, `${name}`).map(
                (chillingUnit, parentIndex) =>
                  getIn(values, `${name}.${parentIndex}.productType.name`) && (
                    <Grid
                      className="site-add"
                      key={`chillingUnit${parentIndex}`}
                    >
                      <Grid item container md={12} spacing={2}>
                        <Grid item md={3} xs={10}>
                          <Field
                            select
                            name={`${name}.${parentIndex}.productType.name`}
                            label={t("chilling_Unit_Form_Select_Product_Type")}
                            component={TextField}
                            // value={getIn(values, `${parentIndex}.productType.name`)}
                            // error={getIn(errors, `${parentIndex}.productType.name`)}
                            inputProps={{
                              onChange: async e => {
                                await handleChange(e);
                                handleProductTypeChange(
                                  e.target.value,
                                  parentIndex
                                );
                              }
                            }}
                            margin="dense"
                            fullWidth={true}
                            variant="outlined"
                          >
                            <MenuItem disabled value="none">
                              {t("chilling_Unit_Form_Select_Product_Type")}
                            </MenuItem>
                            {productTypes.length > 0 &&
                              productTypes.map(type => (
                                <MenuItem
                                  key={type.productTypeId}
                                  value={type.name}
                                >
                                  {type.name}
                                </MenuItem>
                              ))}
                          </Field>
                        </Grid>
                        <Grid item container xs={2} alignItems="center">
                          <button
                            type="button"
                            className="add-icon delete-icon"
                            onClick={e => {
                              if (getIn(values, `${name}`).length > 0) {
                                remove(parentIndex);
                                let selected = [...productTypesSelected];
                                setProductTypesSelected(
                                  selected.splice(parentIndex, 1)
                                );
                              }
                            }}
                          >
                            <i
                              className="fa fa-minus-circle"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </Grid>
                      </Grid>
                      <Grid container xs={12} spacing={2} className="mb-6">
                        {productTypesSelected.length ===
                          getIn(values, `${name}`).length &&
                          chillingUnitFields(parentIndex)[
                            productTypesSelected[parentIndex]
                          ].map(renderFields(formProps))}

                        <DeviceProfile
                          name={`${name}.${parentIndex}.l1DeviceInfo`}
                          values={
                            getIn(
                              values,
                              `${name}.${parentIndex}.l1DeviceInfo`
                            ) || []
                          }
                          errors={
                            getIn(
                              errors,
                              `${name}.${parentIndex}.l1DeviceInfo`
                            ) || []
                          }
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          setFieldError={setFieldError}
                          setFieldTouched={setFieldTouched}
                          // metaProps={metaProps}
                          devices={getIn(values, `devices`)}
                          edit={edit}
                          parentIndex={parentIndex}
                        />
                      </Grid>
                    </Grid>
                  )
              )}
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <CustomizedPrimaryButton
                  variant="contained"
                  size="medium"
                  type="button"
                  color="primary"
                  className="w-100 text-capitalize"
                  onClick={() => {
                    push({
                      l1DeviceInfo: [
                        {
                          serialNumber: "",
                          deviceType: "",
                          gatewaySerialNumber: "",
                          l0Config: [
                            {
                              deviceAttribute: "",
                              applicationAttribute: "",
                              unit: ""
                            }
                          ]
                        }
                      ],
                      productType: {
                        name: "BMC"
                      },
                      chillingUnit: {
                        customerBmcId: "",
                        bmcName: "",
                        capacity: "",
                        ownerName: "",
                        countryCode: "",
                        ownerContactNum: "",
                        vendor: "",
                        otherVendor: "",
                        manufacturedYear: "",
                        methodOfCooling: ""
                      },
                      chillingUnitId: null,
                      chillingUnitUuid: null
                    });
                    setProductTypesSelected([...productTypesSelected, "BMC"]);
                  }}
                  label={
                    getIn(values, `${name}`).length > 0
                      ? t("site_Form_Add_Another_Chilling_Unit_Label")
                      : t("site_Form_Add_Chilling_Unit_Label")
                  }
                ></CustomizedPrimaryButton>
              </Grid>
            </Grid>
          </div>
        )}
      />
    </>
  );
}
