import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";

import CommonForm from "../../../components/common/form/common-form";
import { metadataService } from "../../../services/metadataServices";
import PageHeader from "../../../components/layout/PageHeader";
// import LoanProductName from "components/common/form/LoanProductName";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { errorContext } from "context/errorContext";
import { useTranslation } from "react-i18next";

//Import added for Date field
import addDays from "date-fns/addDays";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Switch,
  Box,
  withStyles,
} from "@material-ui/core";


export default function AgentForm({ 
  values, 
  handleSubmit, 
  page, 
  status, 
  category, 
  showSubmitButton=true, 
  // states,
  // organization,
  // districts,
  // taluks,
  // villages,
  // handleChangeState
}) {
  
  const loadedPage = page; 
  // console.log("States in Form Page:::::::::::: ", states);
  // console.log("Orgs in Form Page:::::::::::: ", organization);

  const [dateOfBirth] = React.useState(new Date());
  const [createdDate] = React.useState(new Date());
  const [updatedDate] = React.useState(new Date());
  const gender = ['Male', 'Female', 'Others'];

  const formikForm = React.useRef(null);

  const { t } = useTranslation();

  const { dispatch: errorDispatch } = useContext(errorContext);

  const autoCompleteString = '' + Math.random();

  // useEffect(() => {
  //   metadataService
  //     .getGenderTypes()
  //     .then(data => {
  //       let types = data.map(type => type.typeName);
  //       setGender(types);
  //     })
  //     .catch(error => {
  //       errorDispatch({
  //         type: "ERROR",
  //         error
  //       });
  //     });
  // }, []);

  //To diplay the common exceptions at app level
  const fields = [
    // {
    //   type: "completedPercentage",
    //   formikRef: formikForm,
    //   title: t("retailer_Form_Percentage_Edit_Label") 
    // },
    
    // {
    //   type: "header",
    //   name: "Agent"
    // },
    {
      type: "textMandatory",
      name: "name",
      label: t("agent_Name"),
      placeholder: t("agent_Name"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "selectMandatory",
      name: "gender",
      label: t("agent_Gender"),
      placeholder: t("agent_Gender"),
      option:
      gender.length > 0
          ? gender.map(gender => ({
              value: gender,
              label: gender
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "datePicker",
      name: "dateOfBirth",
      value: { dateOfBirth },
      label: t("agent_DOB"),
      maxDate: new Date(),
      //disabled: loadedPage === "edit" ? true : false,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "addressLine1",
      label: t("agent_AddressLine1"),
      placeholder: t("agent_AddressLine1"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    // {
    //   type: "text",
    //   name: "addressLine2",
    //   label: t("agent_AddressLine2"),
    //   placeholder: t("agent_AddressLine2"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    {
      type: "textMandatory",
      name: "mobileNumber",
      label: t("agent_MobileNo"),
      placeholder: t("agent_MobileNo"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "textMandatory",
      name: "emailID",
      label: t("agent_EmailId"),
      placeholder: t("agent_EmailId"),
      disabled: loadedPage === "edit" ? true : false,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "textMandatory",
      name: "username",
      label: t("agent_Username"),
      placeholder: t("agent_Username"),
      disabled: loadedPage === "edit" ? true : false,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      },
      autoComplete: autoCompleteString
    },
    {
      type: "passwordMandatory",
      name: "password",
      label: t("agent_Password"),
      placeholder: t("agent_Password"),
      disabled: loadedPage === "edit" ? true : false,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      },
      autoComplete: autoCompleteString
    }
    // {
    //   type: "select",
    //   name: "organizationid",
    //   label: t("agent_OrganizationId"),
    //   placeholder: t("agent_OrganizationId"),
    //   option:
    //   status.length > 0
    //       ? status.map(status => ({
    //           value: status,
    //           label: status
    //         }))
    //       : [],
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },


    // {
    //   type: "select",
    //   name: "organizationName",
    //   label: t("agent_OrganizationName"),
    //   placeholder: t("agent_OrganizationName"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   option:
    //   organization.length > 0
    //       ? organization.map(organization => ({
    //           value: organization,
    //           label: organization.orgName
    //         }))
    //       : [],
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },
    // {
    //   type: "text",
    //   name: "agentCountry",
    //   label: t("agent_Country"),
    //   placeholder: t("agent_Country"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // //State
    // {
    //   type: "select",
    //   name: "agentState",
    //   label: t("agent_State"),
    //   placeholder: t("agent_State"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   option:
    //   states.length > 0
    //       ? states.map(states => ({
    //           value: states,
    //           label: states
    //         }))
    //       : [],
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   },
    //   //customOnChange: handleChangeState,
    // },
    // // {
    // //   type: "text",
    // //   name: "agentState",
    // //   label: t("agent_State"),
    // //   placeholder: t("agent_State"),
    // //   grid: {
    // //     xs: 12,
    // //     sm: 3,
    // //     className: ""
    // //   }
    // // },
    // // {
    // //   type: "text",
    // //   name: "agentDistrict",
    // //   label: t("agent_District"),
    // //   placeholder: t("agent_District"),
    // //   grid: {
    // //     xs: 12,
    // //     sm: 3,
    // //     className: ""
    // //   }
    // // },
    // {
    //   type: "select",
    //   name: "agentDistrict",
    //   label: t("agent_District"),
    //   placeholder: t("agent_District"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   option:
    //   districts.length > 0
    //       ? districts.map(districts => ({
    //           value: districts,
    //           label: districts
    //         }))
    //       : [],
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },
    // // {
    // //   type: "text",
    // //   name: "agentTaluk",
    // //   label: t("agent_Taluk"),
    // //   placeholder: t("agent_Taluk"),
    // //   grid: {
    // //     xs: 12,
    // //     sm: 3,
    // //     className: ""
    // //   }
    // // },
    // {
    //   type: "select",
    //   name: "agentTaluk",
    //   label: t("agent_Taluk"),
    //   placeholder: t("agent_Taluk"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   option:
    //   taluks.length > 0
    //       ? taluks.map(taluks => ({
    //           value: taluks,
    //           label: taluks
    //         }))
    //       : [],
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },
    // // {
    // //   type: "text",
    // //   name: "agentVillage",
    // //   label: t("agent_Village"),
    // //   placeholder: t("agent_Village"),
    // //   grid: {
    // //     xs: 12,
    // //     sm: 3,
    // //     className: ""
    // //   }
    // // },
    // {
    //   type: "select",
    //   name: "agentVillage",
    //   label: t("agent_Village"),
    //   placeholder: t("agent_Village"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   option:
    //   villages.length > 0
    //       ? villages.map(villages => ({
    //           value: villages,
    //           label: villages
    //         }))
    //       : [],
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },
    // {
    //   type: "text",
    //   name: "agentPincode",
    //   label: t("agent_Pincode"),
    //   placeholder: t("agent_Pincode"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "agreement",
    //   label: t("agent_Agreement"),
    //   placeholder: t("agent_Agreement"),
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "status",
    //   label: t("agent_Status"),
    //   placeholder: t("agent_Status"),
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // // {
    // //   type: "select",
    // //   name: "status",
    // //   label: t("agent_Status"),
    // //   placeholder: t("agent_Status"),
    // //   option:
    // //   status.length > 0
    // //       ? status.map(status => ({
    // //           value: status,
    // //           label: status
    // //         }))
    // //       : [],
    // //   grid: {
    // //     xs: 12,
    // //     sm: 3,
    // //     className: "mb-2"
    // //   }
    // // },

    // {
    //   type: "text",
    //   name: "creditLimit",
    //   label: t("agent_CreditLimit"),
    //   placeholder: t("agent_CreditLimit"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "creditDays",
    //   label: t("agent_CreditDays"),
    //   placeholder: t("agent_CreditDays"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "socialSecurityNumber",
    //   label: t("agent_SocialSecurityNumber"),
    //   placeholder: t("agent_SocialSecurityNumber"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "phoneNumber",
    //   label: t("agent_PhoneNumber"),
    //   placeholder: t("agent_PhoneNumber"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "location",
    //   label: t("agent_Location"),
    //   placeholder: t("agent_Location"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "license",
    //   label: t("agent_License"),
    //   placeholder: t("agent_License"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "gst",
    //   label: t("agent_Gst"),
    //   placeholder: t("agent_Gst"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "latLang",
    //   label: t("agent_LatLong"),
    //   placeholder: t("agent_LatLong"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "datePicker",
    //   name: "createdDate",
    //   value: { createdDate },
    //   label: t("agent_CreatedDate"),
    //   placeholder: t("agent_CreatedDate"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "datePicker",
    //   name: "updatedDate",
    //   value: { updatedDate },
    //   label: t("agent_UpdatedDate"),
    //   placeholder: t("agent_UpdatedDate"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "createdBy",
    //   label: t("agent_CreatedBy"),
    //   placeholder: t("agent_CreatedBy"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "updatedBy",
    //   label: t("agent_UpdatedBy"),
    //   placeholder: t("agent_UpdatedBy"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },


    // {
    //   type: "header",
    //   name: "Bank Details"
    // },
    // {
    //   type: "text",
    //   name: "accountName",
    //   label: t("agent_AccountName"),
    //   placeholder: t("agent_AccountName"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "accountNumber",
    //   label: t("agent_AccountNumber"),
    //   placeholder: t("agent_AccountNumber"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "bankName",
    //   label: t("agent_BankName"),
    //   placeholder: t("agent_BankName"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "branch",
    //   label: t("agent_Branch"),
    //   placeholder: t("agent_Branch"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "ifsc",
    //   label: t("agent_Ifsc"),
    //   placeholder: t("agent_Ifsc"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },


    // {
    //   type: "header",
    //   name: "Store"
    // },
    // {
    //   type: "text",
    //   name: "storeName",
    //   label: t("agent_StoreName"),
    //   placeholder: t("agent_StoreName"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "storeAddress1",
    //   label: t("agent_StoreAddress1"),
    //   placeholder: t("agent_StoreAddress1"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "storeAddress2",
    //   label: t("agent_StoreAddress2"),
    //   placeholder: t("agent_StoreAddress2"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "storePincode",
    //   label: t("agent_StorePincode"),
    //   placeholder: t("agent_StorePincode"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "storeVillage",
    //   label: t("agent_StoreVillage"),
    //   placeholder: t("agent_StoreVillage"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "storeTaluk",
    //   label: t("agent_StoreTaluk"),
    //   placeholder: t("agent_StoreTaluk"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "storeDistrict",
    //   label: t("agent_StoreDistrict"),
    //   placeholder: t("agent_StoreDistrict"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "storeState",
    //   label: t("agent_StoreState"),
    //   placeholder: t("agent_StoreState"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "storeCountry",
    //   label: t("agent_StoreCountry"),
    //   placeholder: t("agent_StoreCountry"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // },
    // {
    //   type: "text",
    //   name: "pnumber",
    //   label: t("agent_PNumber"),
    //   placeholder: t("agent_PNumber"),
    //   disabled: loadedPage === "edit" ? true : false,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: ""
    //   }
    // }

  ]

  // if(loadedPage === "add"){
    let validationsAdd = {
      name: Yup.string().required(t("agent_Name"))
      .max(255, 'Max 255 characters allowed'),
      gender: Yup.string().required(t("agent_Gender")),
      mobileNumber: Yup.string().required(t("agent_MobileNo"))
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, 'Must be exactly 10 digits')
      .max(10, 'Must be exactly 10 digits'),
      dateOfBirth: Yup.string().nullable(true),
      addressLine1: Yup.string().nullable(true)
      .max(1000, 'Max 1000 characters allowed'),
      emailID: Yup.string().required(t("agent_EmailId"))
      .max(255, 'Max 255 characters allowed')
      .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "Invalid Email"),
      username:
        Yup.string()
          .matches(/^[aA-zZ0-9!@#$%^&*<>()-_.]+$/, "Whitespace or empty space not allowed")
          .min(3, t("agent_Form_Validation_User_Name_Min_Char"))
          .max(50, 'Max 50 characters allowed')
          .required(t("agent_Form_Validation_User_Name"))
          .typeError(t("agent_Form_Validation_User_Name")),
      password:
        Yup.string()
          .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/, 
          "Min 8 and Max 15 characters, at least one uppercase letter, one lowercase letter, one number and one special character")
          .required(t("agent_Form_Validation_Password"))
          .min(8, t("agent_Form_Validation_Password_Min_Char"))
          .max(15, t("agent_Form_Validation_Password__Max_Char"))
          .typeError(t("agent_Form_Validation_Password"))
          .when(["username","emailID"], (username, emailID, schema) => {
            //console.log(schema);
            return schema.notOneOf([username, emailID], "should not contain username or email");
          })
          // .when(["emailID"], (emailID, schema) => {
          //   console.log(schema);
          //   return schema.notOneOf([emailID], "should not contain email Id");
          // })
          //.nullable(true)
      // addressLine2: Yup.string().required(t("agent_AddressLine2")),
      // organizationid: Yup.string().required(t("agent_OrganizationId")),
      // organizationName: Yup.string().required(t("agent_OrganizationName")),
      // agentPincode: Yup.string().required(t("agent_Pincode"))
      //   .matches(/^[0-9]+$/, "Must be only digits")
      //   .min(6, 'Must be exactly 6 digits')
      //   .max(6, 'Must be exactly 6 digits'),
      // agentVillage: Yup.string().required(t("agent_Village")),
      // agentTaluk: Yup.string().required(t("agent_Taluk")),
      // agentDistrict: Yup.string().required(t("agent_District")),
      // agentState: Yup.string().required(t("agent_State")),
      // agentCountry: Yup.string().required(t("agent_Country")),
      // agreement: Yup.string().required(t("agent_Agreement")),
      // status: Yup.string().required(t("agent_Status")),
      // // creditLimit: Yup.string().required(t("agent_CreditLimit")),
      // // creditDays: Yup.string().required(t("agent_CreditDays")),
      // // socialSecurityNumber: Yup.string().required(t("agent_SocialSecurityNumber")),
      // // phoneNumber: Yup.string().required(t("agent_PhoneNumber")),
      // // location: Yup.string().required(t("agent_Location")),
      // // license: Yup.string().required(t("agent_License")),
      // // gst: Yup.string().required(t("agent_Gst")),
      // // latLang: Yup.string().required(t("agent_LatLong")),
      // // createdDate: Yup.string().required(t("agent_CreatedDate")),
      // // updatedDate: Yup.string().required(t("agent_UpdatedDate")),
      // // createdBy: Yup.string().required(t("agent_CreatedBy")),
      // // updatedBy: Yup.string().required(t("agent_UpdatedBy")),
  
      // // accountName: Yup.string().required(t("agent_AccountName")),
      // // accountNumber: Yup.string().required(t("agent_AccountNumber")),
      // // bankName: Yup.string().required(t("agent_BankName")),
      // // branch: Yup.string().required(t("agent_Branch")),
      // // ifsc: Yup.string().required(t("agent_Ifsc")),
  
      // storeName: Yup.string().required(t("agent_StoreName")),
      // storeAddress1: Yup.string().required(t("agent_StoreAddress1")),
      // storeAddress2: Yup.string().required(t("agent_StoreAddress2")),
      // storePincode: Yup.string().required(t("agent_StorePincode"))
      //   .matches(/^[0-9]+$/, "Must be only digits")
      //   .min(6, 'Must be exactly 6 digits')
      //   .max(6, 'Must be exactly 6 digits'),
      // storeVillage: Yup.string().required(t("agent_StoreVillage")),
      // storeTaluk: Yup.string().required(t("agent_StoreTaluk")),
      // storeDistrict: Yup.string().required(t("agent_StoreDistrict")),
      // storeState: Yup.string().required(t("agent_StoreState")),
      // storeCountry: Yup.string().required(t("agent_StoreCountry")),
      // pnumber: Yup.string().required(t("agent_PNumber"))
      // .matches(/^[0-9]+$/, "Must be only digits")
      // .min(10, 'Must be exactly 10 digits')
      // .max(10, 'Must be exactly 10 digits')
    }
  // }else{
      let validationsEdit = {
        name: Yup.string().required(t("agent_Name"))
        .max(255, 'Max 255 characters allowed'),
        gender: Yup.string().required(t("agent_Gender")),
        mobileNumber: Yup.string().required(t("agent_MobileNo"))
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(10, 'Must be exactly 10 digits')
        .max(10, 'Must be exactly 10 digits'),
        dateOfBirth: Yup.string().nullable(true),
        addressLine1: Yup.string().nullable(true)
        .max(1000, 'Max 1000 characters allowed'),
        emailID: Yup.string().required(t("agent_EmailId"))
        .max(255, 'Max 255 characters allowed'),
        username:
          Yup.string()
            .matches(/^[aA-zZ0-9!@#$%^&*_.]+$/, "Whitespace or empty space not allowed")
            .min(3, t("agent_Form_Validation_User_Name_Min_Char"))
            .max(50, 'Max 50 characters allowed')
            .required(t("agent_Form_Validation_User_Name"))
            .typeError(t("agent_Form_Validation_User_Name")),
        password:
          Yup.string()
            //.required(t("agent_Form_Validation_Password"))
            .min(8, t("agent_Form_Validation_Password_Min_Char"))
            .max(15, t("agent_Form_Validation_Password__Max_Char"))
            .typeError(t("agent_Form_Validation_Password"))
            .nullable(true)
    }
  // }

  
  return (

    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("agent_Form_Header_Label")}
          breadcrumbs={[
            { label: t("agent_Form_Breadcrumb_Dashboard_Label"), link: "/dashboard" },
            {
              label: t("agent_Form_Breadcrumb_Agent_Label"),
              link: "/configuration/agent-list"
            },
            { label: t("agent_Form_Breadcrumb_Agent_Label") }
          ]}
        />
      </Grid>

      <Grid container>
        <Paper elevation={2} style={{ padding: "2em", width: "100%" }}>
          {" "}
          <Grid item xs={12}>
            {loadedPage == "add" &&
              <h2>{t("agent_Form_Title_Add_Label")}</h2>
            }
            {loadedPage == "edit" &&
              <h2>{t("agent_Form_Title_Edit_Label")}</h2>
            }
          </Grid>
          <Grid item>
            {loadedPage == "add" &&
              <CommonForm
                fields={fields}
                submitLabel={t("retailer_Form_Submit_Label")}
                submittingLabel={t("retailer_Form_Submitting_Label")}
                initialValues={values}
                validationSchema={Yup.object().shape(validationsAdd)}
                validateOnBlur={true}
                validateOnChange={true}
                showSubmitButton={showSubmitButton}
                onSubmit={(values, { resetForm, setSubmitting }) => {
                  //console.log("Actual Values Passed:- ", values);
                  handleSubmit(values, resetForm, setSubmitting);
                }}
                formikRef={formikForm}
                buttonSize="3"
                buttonPosition="right"
                enableReinitialize
              />
            }
            {loadedPage == "edit" &&
              <CommonForm
                fields={fields}
                submitLabel={t("retailer_Form_Submit_Label")}
                submittingLabel={t("retailer_Form_Submitting_Label")}
                initialValues={values}
                validationSchema={Yup.object().shape(validationsEdit)}
                validateOnBlur={true}
                validateOnChange={true}
                showSubmitButton={showSubmitButton}
                onSubmit={(values, { resetForm, setSubmitting }) => {
                  //console.log("Actual Values Passed:- ", values);
                  handleSubmit(values, resetForm, setSubmitting);
                }}
                formikRef={formikForm}
                buttonSize="3"
                buttonPosition="right"
                enableReinitialize
              />
            }
          </Grid>
        </Paper>
      </Grid>
    </Grid>
    
  );
}
