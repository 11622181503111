import React from "react";
// import ReactEcharts from "echarts-for-react";
import Tree from "react-d3-tree";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import { provisioningService } from "../../../services/provisioningServices";

import { makeStyles } from "@material-ui/core/styles";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import Backdrop from "@material-ui/core/Backdrop";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 1302,
    color: "#fff"
  }
}));

export default function OrgChart({ setOrgName, organization, zoom = 0.5 }) {
  const [heirarchy, setHeirarchy] = React.useState({});
  const [graphLoading, setGraphLoading] = React.useState(false);
  const [openBottomMenu, setOpenBottomMenu] = React.useState(true);
  const [zoomValue, setZoom] = React.useState(1);
  const [fullScreen, setFullScreen] = React.useState(false);
  const [reset, setReset] = React.useState(Date.now());
  const classes = useStyles();
  React.useEffect(() => {
    setZoom(zoom);
  }, [zoom]);

  React.useEffect(() => {
    handleHeirarchyFetch(organization);
  }, [organization]);

  async function handleHeirarchyFetch(orgId) {
    setGraphLoading(true);
    const data = await provisioningService.getOrgHeirarchy(orgId);
    if (!!setOrgName) {
      setOrgName(data.orgName);
    }
    let orgHeirarchy = {};
    orgHeirarchy.name = data.orgName;
    if (data.subOrganization.length > 0) {
      orgHeirarchy.children = handleSubOrgs(data.subOrganization);

      setHeirarchy([orgHeirarchy]);
    }
    setGraphLoading(false);
  }

  function handleSubOrgs(subOrganizations) {
    let subOrgs = [];
    subOrgs = subOrganizations.map(org => {
      let orgData = {};
      orgData.name = org.orgName;
      if (org.subOrganization.length > 0) {
        orgData.children = handleSubOrgs(org.subOrganization);
      }
      return orgData;
    });
    return [...subOrgs];
  }

  const styles = {
    links: { stroke: "#C4CFDD" },
    nodes: {
      node: {
        circle: {
          fill: "#9B5FFB",
          stroke: "#E5E5FF",
          strokeWidth: 3
        },
        name: {
          stroke: "#000"
        }
      },
      leafNode: {
        circle: {
          fill: "#478FCC",
          stroke: "#E5E5FF",
          strokeWidth: 3
        },
        name: {
          stroke: "#97A4BA"
        }
      }
    }
  };

  const treeContainer = React.useRef(null);
  const fullScreenContainer = React.useRef(null);

  const [translate, setTranslate] = React.useState({
    x: 0,
    y: 0
  });

  React.useEffect(() => {
    if (fullScreen) {
      const dimensions = fullScreenContainer.current.getBoundingClientRect();
      setTranslate({
        x: dimensions.width / 2,
        y: 100
      });
    } else {
      const dimensions = treeContainer.current.getBoundingClientRect();
      setTranslate({
        x: dimensions.width / 2,
        y: 100
      });
    }
  }, [heirarchy, fullScreen]);

  return (
    <>
      {fullScreen ? (
        <Backdrop
          classes={{ root: classes.backdrop }}
          open={true}
          onClick={e => {
            if (e.target.nodeName === "DIV") {
              setFullScreen(false);
            }
          }}
        >
          <Grid
            container
            ref={fullScreenContainer}
            style={{
              position: "relative",
              height: "100%",
              width: "100%",
              backgroundColor: "white",
              opacity: "1"
            }}
            justify="flex-end"
          >
            <IconButton
              style={{ position: "absolute", top: "10px" }}
              onClick={() => setFullScreen(false)}
            >
              <FullscreenExitIcon />
            </IconButton>
            <Tree
              data={heirarchy}
              orientation="vertical"
              pathFunc="step"
              styles={styles}
              allowForeignObjects
              translate={translate}
              zoom={zoomValue}
              separation={{ siblings: 3, nonSiblings: 2 }}
              nodeLabelComponent={{
                render: <NodeLabel />,
                foreignObjectWrapper: {
                  width: 320,
                  x: 15,
                  y: -10
                }
              }}
            />
          </Grid>
        </Backdrop>
      ) : (
        <Grid
          container
          ref={treeContainer}
          alignItems="flex-end"
          style={{
            height: "100%",
            position: "relative",
            border: "1px solid #F1EAF0",
            borderRadius: "4px"
          }}
        >
          <Tree
            key={reset}
            data={heirarchy}
            orientation="vertical"
            pathFunc="step"
            styles={styles}
            allowForeignObjects
            translate={translate}
            zoom={zoomValue}
            separation={{ siblings: 3, nonSiblings: 2 }}
            nodeLabelComponent={{
              render: <NodeLabel />,
              foreignObjectWrapper: {
                width: 320,
                x: 15,
                y: -10
              }
            }}
          />
          <Grid
            container
            justify="center"
            style={{
              position: "absolute",
              backgroundColor: "rgba(140, 143, 146,.9)",
              borderRadius: "0px 4px "
            }}
          >
            <IconButton
              onClick={() => {
                if (zoomValue > 0.1) {
                  setZoom(zoomValue - 0.1);
                }
              }}
            >
              <ZoomOutIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                if (zoomValue < 0.9) {
                  setZoom(zoomValue + 0.1);
                }
              }}
            >
              <ZoomInIcon />
            </IconButton>
            <IconButton onClick={() => setFullScreen(true)}>
              <FullscreenIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setZoom(0.5);
                setReset(Date.now());
              }}
            >
              <FullscreenExitIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </>
  );
}

const NodeLabel = ({ nodeData }) => {
  return (
    <span style={{ fontSize: "30px", wordWrap: "break-word" }}>
      <Link to={`/organization/profile/${nodeData.name}`}>{nodeData.name}</Link>
    </span>
  );
};
