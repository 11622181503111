import React, { useEffect, useContext,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { authContext } from "context/authContext";
import ApproveForm from "./ApproveForm";
import { notificationService } from "services/notificationServices";
import { errorContext } from "context/errorContext";
import addDays from "date-fns/addDays";
import { Grid, Paper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation } from 'react-router-dom'; 
import { isAfter } from "date-fns";
import { approveServices } from "services/approveServices";
import CommonForm from "components/common/form/common-form";
import { useTranslation } from "react-i18next";
import { provisioningService } from "../../../services/provisioningServices.js";
import FailureFeedback from "components/common/elements/FailureFeedback";

export default function ViewCategory() {

  const { t } = useTranslation();
  const formikForm = React.useRef(null);
    
  const { dispatch: errorDispatch } = useContext(errorContext);
  const [edit,setEdit] = ("");

  const [status, setStatus] = useState("loading");
  const [language, setLanguage] = React.useState([]);
  const [statusMessage,setStatusMessage] =  React.useState("");
  const [validStatusSelection, setValidStatusSelection] = useState(false);

  let showSubmitButton = false;

  function getLanguageData(){
    notificationService.getNotificationUIOptions('LANGUAGE').then(optionData => {
      if (optionData) {
        setLanguage(optionData);
      }
    });
  }

  useEffect(() => {
    provisioningService.verifyOtpNew('1313131313').then((res) => {
      const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
      const organizationId = JSON.parse(localStorage.getItem("orgId"));
      getLanguageData();
    })
    .catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });
  }, []);

 
  const location = useLocation();
  const categoryViewData = location.state.from;
  
  let  languageList = [];
  if(categoryViewData!=undefined && categoryViewData.after!=undefined && categoryViewData.after.languages!=undefined){
    let languageObj = categoryViewData.after.languages;
    for(let key in languageObj){
      if(languageObj.hasOwnProperty(key)){
        let languageReplicaObj = {};
        let lngObj = {};
        let value = languageObj[key];
        console.log(key,value);
        if(key == "en"){
          languageReplicaObj.language = "ENGLISH";
        } else if (key == "kn"){
          languageReplicaObj.language = "KANNADA";
        } else if( key == "hi"){
          languageReplicaObj.language = "HINDI";
        }
        Object.assign(lngObj , languageReplicaObj, value);
                  
        console.log("languageObj" , lngObj);
        languageList.push(lngObj);
      }
    }
  }
  
  if(categoryViewData.status === "APPROVED" || categoryViewData.status === "REJECTED"){
    showSubmitButton = false ;
  }else{
    showSubmitButton = true ;
  }

  function handleSubmit(values, resetForm, setSubmitting) {
    console.log("View of values",values);

    categoryViewData.remarks = values.remarks;
    categoryViewData.status = values.status; 
    
    if(values.status === "PENDING"){
      setValidStatusSelection(true);
      setSubmitting(false);
      return;
    }
     
    approveServices.adminUpdateStatus(values.id,values.status,categoryViewData).then(data => {
      console.log(values);
      setSubmitting(false);
      if(values.status === "APPROVED"){
        setStatus("approvedSuccessfully");
      }else if(values.status === "REJECTED"){
        setStatus("rejectedSuccessfully");
      }
    })
    .catch(error => {
      console.error(error);
      if(error.error.message !=undefined){
        setStatusMessage(error.error.message);
      }
      setStatus("failed");
      setSubmitting(false);
    });
  }
  
  let values =  {
    categoryDescriptionDisplayName:!!categoryViewData && !!categoryViewData.after? categoryViewData.after.description :"",
    categoryName: !!categoryViewData && categoryViewData.after? categoryViewData.after.categoryName : "",
    categoryUUID:!!categoryViewData && !!categoryViewData.after? categoryViewData.after.categoryUUID :"",
    createdBy: !!categoryViewData && !!categoryViewData.after? categoryViewData.after.createdBy : "",
    createdDate:!!categoryViewData && !!categoryViewData.after? categoryViewData.after.createdDate : "",
    fetchTag:!!categoryViewData && !!categoryViewData.after? categoryViewData.after.fetchTag : "",
    hierarchyTree:!!categoryViewData && !!categoryViewData.after? categoryViewData.after.hierarchyTree : "",
    id:!!categoryViewData ? categoryViewData.id : "",
    image:!!categoryViewData && !!categoryViewData.after? categoryViewData.after.image : "",
    imageUrl:!!categoryViewData && !!categoryViewData.after? categoryViewData.after.imageUrl : "",
    itemGroupCode:!!categoryViewData && !!categoryViewData.after? categoryViewData.after.itemGroupCode : "",
    omsReferenceId:!!categoryViewData && !!categoryViewData.after? categoryViewData.after.omsReferenceId :"",
    parentId:!!categoryViewData && !!categoryViewData.after? categoryViewData.after.parentId :"",
    priority:!!categoryViewData && !!categoryViewData.after? categoryViewData.after.priority :"",
    searchTag:!!categoryViewData && !!categoryViewData.after? categoryViewData.after.searchTag :"",
    subCategories:!!categoryViewData && !!categoryViewData.after? categoryViewData.after.subCategories :"",
    updatedBy:!!categoryViewData && !!categoryViewData.after? categoryViewData.after.updatedBy :"",
    updatedDate:!!categoryViewData && !!categoryViewData.after? categoryViewData.after.updatedDate :"",
    createdAt: !!categoryViewData ? categoryViewData.createdAt :"",
    entityId:!!categoryViewData ? categoryViewData.entityId :"",
    entityType:!!categoryViewData ? categoryViewData.entityType :"",
    origin:!!categoryViewData ? categoryViewData.origin :"",
    remarks:!!categoryViewData ? categoryViewData.remarks :"",
    updatedAt:!!categoryViewData ? categoryViewData.updatedAt:"",
    status:!!categoryViewData ? categoryViewData.status:"",
    languageList:!!languageList ? languageList:""
  }

  const handleClose = () => {
    setValidStatusSelection(false);
  };
   
  return(
    <>
      <Grid container>
        <ApproveForm
          values={values}
          handleSubmit={handleSubmit}
          language={language}
          status={status} 
          showSubmitButton={showSubmitButton}
          statusMessage={statusMessage}/>
      </Grid>

      <FailureFeedback
        open={validStatusSelection}
        onClose={handleClose}
        status={"Failed"}
        message={t("approve_Form_select_approve_reject_status")}
        showButtons={true} />
    </>
  );
}
