import React from "react";
import Chip from "@material-ui/core/Chip";

export default function PincodeChip({
  values,
  setFieldValue
}) {
  
  function deletePincodeData(data) {
    let pincodeData = [...values];
    
    let indexValue = 0;
    for(var i=0;i<values.length;i++){
      if(values[i].pincode === data.pincode){
        indexValue = i;
        console.log("indexValue",indexValue);
        values.splice(indexValue,1);
        break;
      }
    }
    console.log(values);
    setFieldValue(values);
  }

  //let pincodeData = [...values];
  let pincodeData = [];
  try{
    pincodeData = [...values];
    console.log("pincodeData",pincodeData);
  }catch{

  }

  if (pincodeData !== []) {
    return (
      <>   
      { pincodeData.map((data,index) => (
        <Chip
            style={{ marginLeft: "10px",
           marginTop:"5px" }}
            key={data.pincode}
            label={"PinCode:" +data.pincode +", State: " +data.state +", District: " +data.district +", Taluk: "+data.taluk}
            className="gateway-label"
            onDelete={() => {
              deletePincodeData(data);
            } } />     
        ))}
      </>
    );
  }

  return null;
}
