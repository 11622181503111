import React, { useEffect, useContext, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import "../../../components/Table.scss";
import { provisioningService } from "../../../services/provisioningServices.js";
import Table from "components/common/table/DynamicPaginationTable2";
import PageHeader from "components/layout/PageHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import { errorContext } from "context/errorContext";

import { UseTranslation, useTranslation } from "react-i18next";
import * as Yup from "yup";
import CommonForm from "components/common/form/common-form";
import Paper from "@material-ui/core/Paper";

const LoanInsuranceList = props => {

  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("currentUser"));

  const [status, setStatus] = React.useState("loading");
  const [pageInfo, setPageInfo] = React.useState({});
  const [loanInsuranceListData, setLoanInsuranceListData] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(10);
  const [pageIndex, setPageIndex] = React.useState(0);

  const { dispatch: errorDispatch } = useContext(errorContext);

  const [orgListStatus, setOrgListStatus] = useState("loading");
  const [organizationName, setOrganizationName] = React.useState([]);

  let values = {
    organizationName: organizationName[0]
  };

  const formikForm = React.useRef(null);

  const fields = [
    {
      type: "select",
      name: "organizationName",
      label: t("loanInsurance_Organization_Label"),
      placeholder: t("loanInsurance_Organization_Label"),
      option:
      organizationName.length > 0
          ? organizationName.map(organizationName => ({
              value: organizationName,
              label: organizationName.organizationName
            }))
          : [],
      grid: {
        xs: 12,
        sm: 2,
        md: 3,
        className: ""
      }
    }
  ]; 

  let validations = {
    organizationName: Yup.string().required(
        t("loanInsurance_Organization_Label")
    )
  };

  useEffect(() => {
    if(organizationName.length > 0){    
      let pCode = organizationName[0];
    }
  }, [organizationName]);

  function getAllOrgList(){
    provisioningService.getAllOrgList().then(data => {
      if (data) {
        setOrganizationName(data);
        setOrgListStatus("success");
        setStatus("success");
      }
    });
  }

  function handleSubmit(values, resetForm, setSubmitting) {

    provisioningService
              .getLoanInsurance(values.organizationName.organizationId)
              .then(data => {
                setLoanInsuranceListData(data || []);
                setPageInfo(data.pageInfo);
                setStatus("success");
              })
              .catch(error => {
                errorDispatch({
                  type: "ERROR",
                  error
                });
              });

    setSubmitting(false);
  }


  const columns = React.useMemo(
    () => [
      {
        Header: t("loanInsurance_Org_Name_Label"),
        accessor: "organizationName",
      },
      {
        Header: t("loanInsurance_Product_Name_Label"),
        accessor: "productName"
      },
      {
        Header: t("loanInsurance_Product_Code_Label"),
        accessor: "productCode"
      },
      {
        Header: t("loanInsurance_Product_Category_Label"),
        accessor: "productCategory"
      },
      {
        Header: t("loanInsurance_Product_Type_Label"),
        accessor: "productType"
      },
      {
        Header: t("loanInsurance_Product_Valid_From_Label"),
        accessor: "validFromDate",
        width: 100
      },
      {
        Header: t("loanInsurance_Product_Valid_To_Label"),
        accessor: "validToDate",
        width: 100
      },
      {
        Header: t("loanInsurance_Active_Label"),
        accessor: "active",
        Cell: ({ cell }) => cell.value == true ? "Yes" : "No"
      },
      {
        Header: t("loanInsurance_Edit_Label"),
        Cell: ({ row }) => {
          let link = `/configuration/loan-insurance-edit/${row.original.productId}`;
          return (
            user.uiPrivileges.includes("EDIT_LOAN/INSURANCE") ?
            <Link to={link}>
              <IconButton>
                <i className="fa fa-edit" aria-hidden="true"></i>
              </IconButton>
            </Link>
            : null
          );
        }
      }
    ],
    []
  );

  const dropitems = [
    {
      item: (
        <Link to="/configuration/loan-insurance-create">
         {t("loanInsurance_Add_Label")}
        </Link>
      )
    }
  ];

  useEffect(() => {
    
    setStatus("loading");
    provisioningService
      .verifyOtpNew('1313131313')
      .then((res) => {
        const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
          getAllOrgList();
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
    
  }, [ ]);

  const data = React.useMemo(() => [...loanInsuranceListData]);

  const nextPage = () => {
    if (
      status !== "loading" &&
      pageInfo.hasNextPage &&
      pageIndex < pageInfo.totalPages
    ) {
      let index = pageIndex;
      setPageIndex(++index);
    }
  };
  const previousPage = () => {
    let index = pageIndex;
    if (index > 0) {
      setPageIndex(--index);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("loanInsurance_Header_Label")}
          breadcrumbs={[
            { label: t("loanInsurance_Breadcrumb_Dashboard_Label"), link: "/dashboard" },
            { label: t("loanInsurance_Breadcrumb_Config_Label") },
            { label: t("loanInsurance_Breadcrumb_Loan_Insurance_Label") }
          ]}
        />
      </Grid>

      <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%"
        }}
      >
      {orgListStatus === "loading" ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ):(
      <CommonForm
        fields={fields}
        submitLabel={t("loanInsurance_Form_Submit_Label")}
        submittingLabel={t("loanInsurance_Form_Submitting_Label")}
        initialValues={values}
        validationSchema={Yup.object().shape({ ...validations })}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={(
          values,
          { setSubmitting, setFieldError, resetForm, errors }
        ) => {
          if (!!errors) {
            setSubmitting(false);
          }
          handleSubmit(values, resetForm, setSubmitting);
        }}
        formikRef={formikForm}
        buttonSize="2"
        buttonPosition="right"
        inlineButton={true}
      />
      )}

      {status === "loading" ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: "50vh" }}
        >
          <CircularProgress />
        </Grid>
      ) : (
          <Table
            columns={columns}
            data={data}
            needDropdown={true}
            dropItems={dropitems}
            fileName={"loanInsuranceList.xls"}
          />
        )}

      </Paper>
    </Grid>
  );
};

export default LoanInsuranceList;
