import React, { useEffect, useContext, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import "../../../components/Table.scss";
import { provisioningService } from "../../../services/provisioningServices.js";
import Table from "components/common/table/DynamicPaginationTable2";
import PageHeader from "components/layout/PageHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import { errorContext } from "context/errorContext";

import { UseTranslation, useTranslation } from "react-i18next";
import * as Yup from "yup";
import CommonForm from "components/common/form/common-form";
import Paper from "@material-ui/core/Paper";

const AgentList = props => {

  const { t } = useTranslation();

  const [status, setStatus] = React.useState("loading");
  const [pageInfo, setPageInfo] = React.useState({});
  const [agentList, setAgentList] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(10);
  const [pageIndex, setPageIndex] = React.useState(0);

  const { dispatch: errorDispatch } = useContext(errorContext);

  const columns = React.useMemo(
    () => [
      {
        Header: t("retailer_Name_Label"),
        accessor: "name",
      },
      {
        Header: t("retailer_Gender_Label"),
        accessor: "gender"
      },
      {
        Header: t("retailer_DOB_Label"),
        accessor: "dateOfBirth"
      },
      {
        Header: t("retailer_Mobile_Label"),
        accessor: "mobileNumber"
      },
      {
        Header: t("agent_Username_Label"),
        accessor: "username"
      },
      {
        Header: t("agent_Edit_Label"),
        Cell: ({ row }) => {
          let link = `/configuration/agent-edit/${row.original.id}`;
          return (
            <Link to={link}>
              <IconButton>
                <i className="fa fa-edit" aria-hidden="true"></i>
              </IconButton>
            </Link>
          );
        }
      },

      {
        Header: t("agent_Association_Label"),
        Cell: ({ row }) => {
          let link = `/configuration/agent-associate/${row.original.id}/${row.original.name}`;
          return (
            <Link to={link}>
              <IconButton>
                <i className="fa fa-group" aria-hidden="true"></i>
              </IconButton>
            </Link>
          );
        }
      }
    ],
    []
  );


  const dropitems = [
    {
      item: (
        <Link to="/configuration/agent-create">
         {t("agent_Add_Label")}
        </Link>
      )
    }
  ];


  useEffect(() => {
    
    setStatus("loading");

          provisioningService
          .getAgentList()
          .then(data => {
            setAgentList(data || []);
            //console.log("Agent List :-========== ", data);
            setPageInfo(data.pageInfo);
            setStatus("success");
          })
          .catch(error => {
            errorDispatch({
              type: "ERROR",
              error
            });
          });
    
  }, [ ]);

  const data = React.useMemo(() => [...agentList]);

  const nextPage = () => {
    if (
      status !== "loading" &&
      pageInfo.hasNextPage &&
      pageIndex < pageInfo.totalPages
    ) {
      let index = pageIndex;
      setPageIndex(++index);
    }
  };
  const previousPage = () => {
    let index = pageIndex;
    if (index > 0) {
      setPageIndex(--index);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("agent_Header_Label")}
          breadcrumbs={[
            { label: t("retailer_Breadcrumb_Dashboard_Label"), link: "/dashboard" },
            { label: t("retailer_Breadcrumb_Config_Label") },
            { label: t("retailer_Breadcrumb_Retailer_Label") }
          ]}
        />
      </Grid>

      <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%"
        }}
      >
    
      {status === "loading" ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: "50vh" }}
        >
          <CircularProgress />
        </Grid>
      ) : (
          <Table
            columns={columns}
            data={data}
            needDropdown={true}
            dropItems={dropitems}
            fileName={"agentsList.xls"}
          />
        )}

      </Paper>
    </Grid>
  );
};

export default AgentList;
