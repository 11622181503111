import React, { useEffect, useContext, useState, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import PbpForm from "./pbpForm";
import SweetAlert from 'react-bootstrap-sweetalert';
//import { productService } from "services/productServices";
import { pbpService } from "services/pbpServices";

import { errorContext } from "context/errorContext";
import { provisioningService } from "../../../services/provisioningServices.js";


export default function CreatePBP() {
  const organizationName = JSON.parse(localStorage.getItem("currentUser")).organizationName;
  const organizationId = JSON.parse(localStorage.getItem("orgId"));
  const history = useHistory();
  //const [organization, setOrganization] = React.useState([]);
  const [createSuccess, setCreateSuccess] = React.useState(false);
  const [createFailure, setCreateFailure] = React.useState(false);
  const [failureMsg, setFailureMsg] = React.useState("");
  const { dispatch: errorDispatch } = useContext(errorContext);  

  const [type, setType] = React.useState([]);
  const [category, setCategory] = React.useState([]); 

  const hideAlert = (event) => {
    setCreateSuccess(false);
    setCreateFailure(false);
  } 

  // to initialize the values when create template form loads first time
  let values = {

    name: "",
    address: "",
    gst: ""

  };

  useEffect(() => {
    provisioningService.verifyOtpNew('1313131313').then((res) => {
      const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
      const organizationId = JSON.parse(localStorage.getItem("orgId"));
      
    })
    .catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });
  }, []);

  async function handleSubmit(values, resetForm, setSubmitting) {
    //console.log(values);
    try {
      const repeat = null;
      const result = await pbpService.PBPcreate(values);
      //console.log("Result:============= ", result);
      if(result.data === null && result.message && result.status === "failure"){
        //console.log("If Condition:==========")
        setCreateFailure(true);
        setFailureMsg(result.message);
        setSubmitting(false);
      }else{

        try {
          const repeat = null;
          //const result = await pbpService.PBPcreate(values);
          resetForm();
          setCreateSuccess(true);
          //resetForm();      
          //redirect to list page
          setTimeout(() => {
            history.push("/configuration/pbp_list");
          }, 3000); 
          
        } catch (e) {
          //console.error(e);
          setCreateFailure(true);
          setFailureMsg(e.error.message);
          setSubmitting(false);
        }

      }
    } catch (e) {
      console.error(e);
    }
    
  }

  // Calling the create template form with values(to initialize),
  return (
    <>
      <PbpForm 
        values={values}
        handleSubmit={handleSubmit} 
        page="add" 

      />

      {createSuccess ? 
        <SweetAlert success
          title="PBP Created Successfully"
          onConfirm={hideAlert}
          //disabled={ disableOkBtn ? true : false }
          onCancel={hideAlert}
          timeout={2000}
        ></SweetAlert> 
      : null}

      {createFailure ? 
        <SweetAlert danger
          title={failureMsg}
          onConfirm={hideAlert}
          //disabled={ disableOkBtn ? true : false }
          onCancel={hideAlert}
          //timeout={3000}
        ></SweetAlert> 
      : null}

    </>

  );
}
