import { Map } from "immutable";

let initialState = Map({
  error: false,
  status: null,
  message: ""
});

export default function error(state = initialState, { type, error }) {
  switch (type) {
    case "ERROR":
      if (error.status === 500) {
        return state.set("error", true).set("status", 500);
      } else if (error.status === 401) {
        return state.set("error", true).set("status", 401);
      } else if (error.status === 503) {
        return state.set("error", true).set("status", 503);
      }
      return state;
    case "RESET_ERROR":
      return initialState;
    default:
      return state;
  }
}
