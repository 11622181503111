import { put, call, all, fork, takeLatest } from "redux-saga/effects";
import { provisioningService } from "services/provisioningServices";

function* watchOrganizationConfiguration() {
  yield takeLatest(
    "ORGANIZATION_CONFIGURATION_CREATE_ORGANIZATION",
    createOrganization
  );
  yield takeLatest("GET_ORG_DETAILS_BY_ID", getOrgDetailsbyID);
  yield takeLatest(
    "ORGANIZATION_CONFIGURATION_EDIT_ORGANIZATION",
    editOrganization
  );
  yield takeLatest("GET_ORG_DETAILS_BY_NAME", getOrgDetailsByName);
}

function* editOrganization({ values, setSubmitting }) {
  try {
    const result = yield call(provisioningService.editOrganization, values);
    // console.log("result of creating org", result);

    yield put({
      type: "ORGANIZATION_CONFIGURATION_EDIT_ORGANIZATION_SUCCESS",
      result
    });
  } catch (e) {
    // console.log("result of creating org eee", e);
    setSubmitting(false);
    yield put({
      type: "ORGANIZATION_CONFIGURATION_EDIT_ORGANIZATION_FAIL",
      error: e.error.message
    });
  }
}
function* getOrgDetailsByName({ name }) {
  try {
    const result = yield call(provisioningService.getOrgByName, name);
    yield put({
      type: "GET_ORG_DETAILS_BY_NAME_SUCCESS",
      org: result
    });
  } catch (error) {
    yield put({
      type: "GET_ORG_DETAILS_BY_NAME_FAILED",
      error: error.error.message
    });
    yield put({
      type: "ERROR",
      error
    });
  }
}
function* getOrgDetailsbyID({ id }) {
  try {
    const result = yield call(provisioningService.getOrgDetailsbyID, id);
    yield put({
      type: "GET_ORG_DETAILS_BY_ID_SUCCESS",
      org: result
    });
  } catch (error) {
    yield put({
      type: "GET_ORG_DETAILS_BY_ID_FAIL",
      error: error.error.message
    });
    yield put({
      type: "ERROR",
      error
    });
  }
}

function* createOrganization({ values, setSubmitting }) {
  try {
    const result = yield call(provisioningService.createOrganization, values);
    yield put({
      type: "ORGANIZATION_CONFIGURATION_CREATE_ORGANIZATION_SUCCESS",
      result
    });
  } catch (e) {
    console.error(e);
    setSubmitting(false);
    yield put({
      type: "ORGANIZATION_CONFIGURATION_CREATE_ORGANIZATION_FAIL",
      error: e
    });
  }
}
export default function* rootSaga() {
  yield all([fork(watchOrganizationConfiguration)]);
}
