import service from './cod-service.js';

function getToken() {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    const token = user && user.jwtAccessToken;
    return token;
}

export class CODImportService {

    triggerCODImport(data) {
        const options = {
            headers: {
                authToken: localStorage.getItem("idToken"),
                Authorization: `Bearer ${localStorage.getItem("bearerTokenCOD")}`,     
                "Content-Type": "multipart/form-data",
                "Api-Key": 'ee25280aac4145afafa960a9975c00eb'
                //"Api-Key": 'e87MPVwlgORoG9J8Pod4j7L5p0eQfq'
            }
        };
        //returns Promise object
        return service.getCODServiceURL().post('/orderPaymentService/order/bulkimport', data, options);
    }

}