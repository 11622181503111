import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import PageHeader from "components/layout/PageHeader";
import { Typography } from "@material-ui/core";
import { chillingUnitService } from "services/chillingUnitService";
import { Link, useParams } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";

const viewRecordDetails = props => {
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const organizationName = user && user.organizationName;
  const { checklistName } = useParams();

  const [checklistDetails, setChecklistDetails] = useState("");
  const [taskDetails, setTaskDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");

  const { t } = useTranslation();
  
  useEffect(() => {
    setLoading(true);
    chillingUnitService
      .getChecklistByChecklistName(organizationName, checklistName)
      .then(data => {
        setChecklistDetails(data);
        setTaskDetails(data.taskList);
        setLoading(false);
      });
  }, []);

  function formatDate(value) {
    var formatedDate = "";
    if (value !== undefined) {
      value.map(dates => {
        if (dates < 10) {
          formatedDate = formatedDate + "0";
        }
        formatedDate = formatedDate + dates;
      });
    }
    let date = formatedDate.substring(0, 4) + "-";
    date = date + formatedDate.substring(4, 6) + "-";
    date = date + formatedDate.substring(6, 8);
    date = date + "  " + formatedDate.substring(8, 10) + ":";
    date = date + formatedDate.substring(10, 12);
    return date;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("view_Records_Details_Header_Label")}
          breadcrumbs={[
            { label: t("view_Records_Details_Dashboard_Label"), link: "/dashboard" },
            { label: t("view_Records_Details_Preventive_Maintenance_Label") },
            {
              label: t("view_Records_Details_View_Records_Label"),
              link: "/preventivemaintenance/view-records"
            },
            { label: t("view_Records_Details_CheckList_Details") }
          ]}
        />
      </Grid>
      {loading === true ? (
        <Grid
          container
          xs={12}
          justify="center"
          alignItems="center"
          style={{ height: "50vh", backgroundColor: "#dee8f8" }}
        >
          <CircularProgress />
        </Grid>
      ) : (
          <Grid
            container
            style={{
              backgroundColor: "#dee8f8",
              marginLeft: "10px",
              marginRight: "20px"
            }}
          >
            <Grid container xs={12}>
              <Grid item xs={3} style={{ marginTop: 20, marginLeft: 20 }}>
                <Typography display="inline" variant="subtitle2">
                  <strong>{t("view_Records_Details_CheckList_Name")}: </strong>
                </Typography>
                <Typography
                  display="inline"
                  color="primary"
                  component="span"
                  variant="subtitle2"
                >
                  {checklistName}
                </Typography>
              </Grid>
              <Grid item xs={4} style={{ marginTop: 20, marginLeft: 20 }}>
                <Typography display="inline" align="justify" variant="subtitle2">
                  <strong>{t("view_Records_Details_Serviced_Date")}: </strong>
                </Typography>
                <Typography
                  display="inline"
                  color="primary"
                  component="span"
                  variant="subtitle2"
                >
                  {formatDate(checklistDetails.actionDate)}
                </Typography>
              </Grid>

              <Grid item xs={4} style={{ marginTop: 20, marginLeft: 20 }}>
                <Typography display="inline" variant="subtitle2">
                  <strong>{t("view_Records_Details_Next_Service_Date")}: </strong>
                </Typography>
                <Typography
                  display="inline"
                  color="primary"
                  component="span"
                  variant="subtitle2"
                >
                  {formatDate(checklistDetails.dueDate)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20, marginLeft: 20 }}>
              <Typography display="inline" variant="subtitle2">
                <strong>{t("view_Records_Details_Technician")}: </strong>
              </Typography>
              <Typography
                display="inline"
                color="primary"
                component="span"
                variant="subtitle2"
              >
                {checklistDetails.performedBy}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ marginTop: 20, marginLeft: 20, marginBottom: 20 }}
            >
              <Typography display="inline" variant="subtitle2">
                <strong>{t("view_Records_Details_CheckList_Description")}: </strong>
              </Typography>
              <Typography
                display="inline"
                color="primary"
                component="span"
                variant="subtitle2"
              >
                {checklistDetails.checklistDesc}
              </Typography>
            </Grid>
            <Grid
              container
              xs={10}
              style={{
                backgroundColor: "#ffffff",
                marginLeft: "30px",
                marginBottom: "40px"
              }}
            >
              <Grid
                item
                xs={12}
                style={{ marginLeft: "20px", marginBottom: "40px" }}
              >
                {taskDetails.map(task => {
                  return (
                    <div>
                      <Grid
                        style={{
                          backgroundColor: "#dee8f8",
                          marginRight: "20px",
                          marginTop: "40px"
                        }}
                      >
                        <Typography
                          align="center"
                          variant="subtitle2"
                          style={{ marginTop: "20px" }}
                        >
                          <strong>{t("view_Records_Details_Element_Name")}: {task.taskName}</strong>
                        </Typography>
                      </Grid>

                      <table>
                        <thead align="center">
                          <tr>
                            <th style={{ width: "150px" }}>Task Name</th>
                            <th style={{ width: "150px" }}> Reading</th>
                            <th style={{ width: "150px" }}>Max</th>
                            <th style={{ width: "150px" }}>Min</th>
                            <th>{t("view_Records_Details_Comment")}</th>
                          </tr>
                        </thead>
                        {task.subTaskList.map(subTask => {
                          return (
                            <tbody>
                              <tr>
                                <td>{subTask.subTaskName}</td>
                                <td>{subTask.reading}</td>
                                {subTask.taskType === "RANGE" ? (
                                  <>
                                    <td>{subTask.taskValue.value.maxValue}</td>
                                    <td>{subTask.taskValue.value.minValue}</td>
                                  </>
                                ) : (
                                    <>
                                      <td>{t("view_Records_Details_Not_Applicable")}</td>
                                      <td>{t("view_Records_Details_Not_Applicable")}</td>
                                    </>
                                  )}
                                <td>{subTask.comment}</td>
                              </tr>
                            </tbody>
                          );
                        })}
                      </table>
                    </div>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        )}
    </Grid>
  );
};

export default viewRecordDetails;
