import service from './retailer-service.js';

export class RetailerImportService {

    triggerRetailerImport(data) {
        const options = {
            headers: {
                authToken: localStorage.getItem("idToken"),
                Authorization: `Bearer ${localStorage.getItem("bearerTokenRetailer")}`,
                "Content-Type": "multipart/form-data",
                "Api-Key": 'e87MPVwlgORoG9J8Pod4j7L5p0eQfR'
            }
        };
        //returns Promise object
        return service.getRetailerServiceURL().post('/userSignupService/nexgen/retailer/bulkimport', data, options);
    }

}