import { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { provisioningService } from "services/provisioningServices";

export default function SiteDetailsByUuid() {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!!uuid) {
      provisioningService
        .getSiteByUUID(uuid)
        .then(site => {
          dispatch({
            type: "GET_SITE_DETAILS_SUCCESS",
            site
          });
          dispatch({
            type: "CHILLING_LIST_FETCH",
            siteId: site.siteId
          });
          return site;
        })
        .then(site =>
          history.replace(`/totalsites/site-details/${site.siteId}`)
        );
    }
  }, [uuid]);

  return null;
}
