import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { getIn } from "formik";
import PageHeader from "components/layout/PageHeader";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
import CommonForm from "components/common/form/common-form";
import FailureFeedback from "components/common/elements/FailureFeedback";
import { metadataService } from "services/metadataServices";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import "components/responsive.scss";
import "../configuration.scss";
import "./Site.scss";
import "../../../components/responsive.scss";
import ChillingUnitFields from "components/common/form/ChillingUnitFields";
import SerialNumberField from "components/common/form/SerialNumberField";
import CountryStateDistrictSelector from "components/common/form/CountryStateDistrictSelector";

import { provisioningService } from "services/provisioningServices";
import { useTranslation } from "react-i18next";
import { string } from "prop-types";

export default function SiteForm({
  values,
  siteUUID = false,
  subOrganizations,
  handleSubmit,
  status,
  createError,
  edit,
  otherVendors,
  handleVendorSelect
}) {
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [locale, setLocale] = useState([]);
  const [timeZone, setTimeZone] = useState([]);
  // const [deviceAttributeValues, setDeviceAttributeValues] = useState([]);

  const { t } = useTranslation();

  let breadcrumbs = [
    { label: t("site_Form_Breadcrum_Dashboard_Label"), link: "/dashboard" },
    { label: t("site_Form_Breadcrum_Configuration_Label") },
    { label: t("site_Form_Breadcrum_Site_Label") }
  ];

  useEffect(() => {
    provisioningService
      .getLocaleList()
      .then(result => {
        setLocale(result);
      })
      .catch(e => console.error(e));
    provisioningService
      .getTimeZoneList()
      .then(result => {
        setTimeZone(result);
      })
      .catch(e => console.error(e));
    if (!!edit) {
      const devices = values.chillingUnitMonitoringDetailsList.reduce(
        (acc, unit) => {
          let serials = unit.l1DeviceInfo.map(device => device.serialNumber);
          return [...acc, ...serials];
        },
        []
      );
      setSerialNumbers(devices);
    }
  }, [edit]);

  const org = JSON.parse(localStorage.getItem("currentUser")).organizationName;

  const formikForm = React.useRef(null);

  const fields = [
    {
      type: "completedPercentage",
      formikRef: formikForm,
      title: t("site_Form_Title_Label")
    },
    {
      type: "text",
      name: "organizationName",
      label: t("site_Form_Org_Label"),
      placeholder: t("site_Form_Org_Label"),
      disabled: true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "select",
      name: "subOrganizationName",
      label: t("site_Form_Sub_Org_Label"),
      placeholder: t("site_Form_Sub_Org_Label"),
      option:
        subOrganizations.length > 0
          ? subOrganizations.map(org => ({
              value: org.orgName,
              label: org.orgName
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "siteId",
      label: t("site_Form_Site_Id_Label"),
      disabled: !!edit,
      placeholder: t("site_Form_Site_Id_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "siteName",
      label: t("site_Form_Site_Name_Label"),
      placeholder: t("site_Form_Site_Name_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    // {
    //   type: "hr"
    // },
    {
      type: "header",
      name: t("site_Form_Address_Header_Label")
    },

    {
      type: "text",
      name: "addressLine1",
      label: t("site_Form_Address_Line_Label"),
      placeholder: t("site_Form_Address_Line_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "pinCode",
      label: t("site_Form_Pin_Code_Label"),
      placeholder: t("site_Form_Pin_Code_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "villageTownCity",
      label: t("site_Form_Village_Town_City_Label"),
      placeholder: t("site_Form_Village_Town_City_Label"),
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "custom",
      component: ({ values, setFieldValue }, renderFields) => (
        <CountryStateDistrictSelector
          edit={edit}
          countryField={"country"}
          stateField={"state"}
          districtField={"district"}
          handleChange={setFieldValue}
          values={values}
        />
      )
    },
    {
      type: "text",
      name: "latitude",
      label: "Latitude,Longitude",
      placeholder: "Latitude,Longitude",
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "altitude",
      label: "Altitude",
      placeholder: "Altitude",
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "header",
      name: "Locale and TimeZone"
    },
    {
      type: "select",
      name: "locale",
      label: "Locale",
      placeholder: "Locale",
      option:
        locale.length > 0
          ? locale.map(d => ({
              value: d.typeName,
              label: d.typeDisplayName
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "select",
      name: "zoneId",
      label: "TimeZone",
      placeholder: "TimeZone",
      option:
        timeZone.length > 0
          ? timeZone.map(d => ({
              value: d.typeName,
              label: d.typeDisplayName
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },

    // {
    //   type: "header",
    //   name: "Common Devices"
    // },
    {
      type: "custom",
      component: ({ setFieldValue, values }, renderFields) => (
        <>
          <span>
            <h3>{t("site_Form_Common_Device_Label")}</h3>
          </span>
          <SerialNumberField
            name={"devices"}
            gatewayLabel={"Gateway Serial Number"}
            sensorLabel={"Sensor Serial Number"}
            setFieldValue={setFieldValue}
            values={values["devices"]}
          />
        </>
      ),
      grid: {
        xs: 12,
        className: "title-on-border-box bg-blue-l"
      }
    },
    {
      type: "custom",
      component: (formProps, renderFields) => (
        <ChillingUnitFields
          name={"chillingUnitMonitoringDetailsList"}
          // devices={values["devices"]}
          // values={getIn(values, field.name)}
          renderFields={renderFields}
          formProps={formProps}
          {...formProps}
          edit={edit}
          otherVendors={otherVendors}
          handleVendorSelect={handleVendorSelect}
        />
      ),
      grid: {
        xs: 12,
        className: "title-on-border-box bg-blue-l"
      }
    },
    {
      type: "hr"
    }
  ];

  // check if duplicate at site level
  // check if duplicate in the chilling unit
  // check if duplicate at different chilling unit

  Yup.addMethod(Yup.object, "unique", function(propertyName, message) {
    return this.test("unique", message, function(value) {
      let { values } = formikForm.current.state;

      if (!value || !value[propertyName]) {
        return true;
      } else if (
        this.parent
          .filter(v => v !== value)
          .some(v => v[propertyName] === value[propertyName])
      ) {
        throw this.createError({
          path: `${this.path}.${propertyName}`
        });
      } else if (
        values.devices &&
        values.devices.filter(
          device => device.serialNumber === value.serialNumber
        ).length > 0
      ) {
        throw this.createError({
          path: `${this.path}.${propertyName}`,
          message: t("site_Form_Validation_Device_Added_Site_Level")
        });
      } else if (
        values.chillingUnitMonitoringDetailsList &&
        values.chillingUnitMonitoringDetailsList.reduce((acc, current) => {
          return current.l1DeviceInfo.filter(device => {
            return device.serialNumber === value.serialNumber;
          }).length > 0
            ? acc + 1
            : acc;
        }, 0) > 1
      ) {
        throw this.createError({
          path: `${this.path}.${propertyName}`,
          message: t("site_Form_Validation_Serial_Number_Already_Added")
        });
      }
      formikForm.current.setFieldError(`${this.path}.serialNumber`, "");
      return true;
    });
  });

  Yup.addMethod(Yup.object, "serialValid", function() {
    return this.test(
      "isValidSerial",
      "Valid serial number is required",
      async function(value) {
        if (
          !formikForm.current.state.isSubmitting &&
          !serialNumbers.includes(value.serialNumber)
        ) {
          return new Promise((resolve, reject) => {
            if (
              !getIn(
                formikForm.current.state.errors,
                `${this.path}.serialNumber`
              ) &&
              getIn(
                formikForm.current.state.touched,
                `${this.path}.serialNumber`
              ) &&
              !formikForm.current.state.isSubmitting
            ) {
              formikForm.current.setFieldValue(
                `${this.path}.deviceType`,
                "",
                false
              );
              provisioningService
                .validateDevice("L1", value.serialNumber, siteUUID)
                .then(result => {
                  let deviceType = result.deviceType.replace(
                    "indsensABC",
                    "indsens"
                  );
                  let deviceTypeWithABC = result.deviceType;
                  let { values } = formikForm.current.state;
                  if (Array.isArray(values.chillingUnitMonitoringDetailsList)) {
                    values.chillingUnitMonitoringDetailsList.map(
                      (current, index) => {
                        const serialDevices = current.l1DeviceInfo.map(
                          device => device.serialNumber
                        );
                        if (current.chillingUnit.methodOfCooling === "TSS") {
                          if (
                            deviceTypeWithABC.toLowerCase() === "feedsens" ||
                            deviceTypeWithABC.toLowerCase() === "volsens"
                          ) {
                            if (
                              serialDevices.indexOf(value.serialNumber) > -1
                            ) {
                              formikForm.current.setFieldError(
                                `${this.path}.serialNumber`,
                                t(
                                  "Feedsens and volsens not allowed with TSS method of cooling"
                                )
                              );
                            }
                          }
                        }
                        if (current.chillingUnit.methodOfCooling === "DX") {
                          if (
                            deviceTypeWithABC.toLowerCase() === "indsensabc"
                          ) {
                            if (
                              serialDevices.indexOf(value.serialNumber) > -1
                            ) {
                              formikForm.current.setFieldError(
                                `${this.path}.serialNumber`,
                                t(
                                  "indsens not allowed with DX method of cooling"
                                )
                              );
                            }
                          }
                        }
                      }
                    );
                  }
                  if (deviceType === "L3")
                    formikForm.current.setFieldError(
                      `${this.path}.serialNumber`,
                      t("site_Form_Validation_Add_Sensor_Serial_Number")
                    );
                  if (
                    getIn(formikForm.current.state.values, `${this.path}`) &&
                    !getIn(
                      formikForm.current.state.errors,
                      `${this.path}.serialNumber`
                    )
                  ) {
                    //             values.chillingUnitMonitoringDetailsList &&
                    // values.chillingUnitMonitoringDetailsList.reduce((acc, current)=>{
                    //   console.log(acc,current)
                    // }
                    Promise.all([
                      metadataService.getDeviceAttributes(deviceTypeWithABC),
                      metadataService.getApplicationAttributes(
                        deviceTypeWithABC
                      ),
                      metadataService.getUnitValueTypes(deviceTypeWithABC)
                    ]).then(data => {
                      formikForm.current.setFieldValue(
                        `${this.path}.deviceAttributes`,
                        data[0],
                        false
                      );
                      formikForm.current.setFieldValue(
                        `${this.path}.applicationAttributes`,
                        data[1],
                        false
                      );
                      formikForm.current.setFieldValue(
                        `${this.path}.unitValueTypes`,
                        data[2],
                        false
                      );
                      formikForm.current.setFieldValue(
                        `${this.path}.deviceType`,
                        deviceType,
                        false
                      );
                      formikForm.current.setFieldTouched(
                        `${this.path}.serialNumber`,
                        false
                      );
                    });
                  }
                })
                .catch(e => {
                  if (getIn(formikForm.current.state.values, `${this.path}`)) {
                    formikForm.current.setFieldValue(
                      `${this.path}.deviceType`,
                      "",
                      false
                    );
                    formikForm.current.setFieldValue(
                      `${this.path}.deviceAttributes`,
                      [],
                      false
                    );
                    formikForm.current.setFieldValue(
                      `${this.path}.applicationAttributes`,
                      [],
                      false
                    );
                    formikForm.current.setFieldValue(
                      `${this.path}.unitValueTypes`,
                      [],
                      false
                    );
                  }
                  if (value.serialNumber === "") {
                    resolve(true);
                  } else {
                    resolve(
                      this.createError({
                        path: `${this.path}.serialNumber`,
                        message: e.message
                      })
                    );
                  }
                });
            } else {
              resolve(true);
            }
          });
        } else {
          return Promise.resolve(true);
        }
      }
    );
  });
  let validations = {
    organizationName: Yup.string().required(t("site_Form_Validation_Org_Name")),
    subOrganizationName: Yup.string()
      .required(t("site_Form_Validation_Sub_Org_Name"))
      .typeError(t("site_Form_Validation_Sub_Org_Name_Type_Error")),
    siteId: Yup.string().required(t("site_Form_Validation_Site_Id")),
    siteName: Yup.string().required(t("site_Form_Validation_Site_Name")),
    gatewaySerialNumber: Yup.string(),
    sensorSerialNumber: Yup.string(),
    chillingUnitMonitoringDetailsList: Yup.array()
      .of(
        Yup.object().shape({
          l1DeviceInfo: Yup.array().of(
            Yup.object()
              .shape({
                serialNumber: Yup.string().required(
                  t("site_Form_Validation_L1_Serial_Number")
                ),
                gatewaySerialNumber: Yup.string().required(
                  t("site_Form_Validation_Gateway_Serial_Number")
                ),
                deviceType: Yup.string()
              })
              .serialValid()
              .unique(
                "serialNumber",
                t("site_Form_Validation_Serial_Number_Already_Added")
              )
          ),
          productType: Yup.object().shape({
            name: Yup.string().required(t("site_Form_Validation_Product_Type"))
          }),
          chillingUnit: Yup.object().when("productType.name", {
            is: value => value === "ABC",
            then: Yup.object().shape({
              societyLongId: Yup.string(),
              societyId: Yup.string(),
              collectionCenterType: Yup.string(),
              chillingCenterName: Yup.string(),
              chillingCenterId: Yup.string(),
              capacity: Yup.number().typeError(
                t("site_Form_Validation_Capacity")
              ),
              cutOffTemperature: Yup.number().typeError("Must be a number"),
              cutInTemperature: Yup.number().typeError("Must be a number"),
              chillingCapacity: Yup.number().typeError("Must be a number"),
              ownerContactNum: Yup.number()
                .test(
                  "len",
                  t("site_Form_Validation_Contact_Number"),
                  val => val.toString().length === 10
                )
                .typeError(t("site_Form_Validation_Contact_Number_Type_Error")),
              vendor: Yup.string(),
              manufacturedYear: Yup.number().typeError(
                t("site_Form_Validation_Manufactured_Year")
              ),
              methodOfCooling: Yup.string()
            }),
            otherwise: Yup.object().shape({
              customerBmcId: Yup.string(),
              bmcName: Yup.string(),
              capacity: Yup.number().typeError(
                t("site_Form_Validation_Capacity")
              ),
              ownerName: Yup.string(),
              ownerContactNum: Yup.number()
                .test(
                  "len",
                  t("site_Form_Validation_Contact_Number"),
                  val => val.toString().length === 10
                )
                .typeError(t("site_Form_Validation_Contact_Number_Type_Error")),
              vendor: Yup.string(),
              manufacturedYear: Yup.number()
                // .test("len", "Must be 10 Digits", val => val.toString().length === 10)
                .typeError(t("site_Form_Validation_Manufactured_Year")),
              methodOfCooling: Yup.string()
            })
          })
        })
      )
      .nullable(),
    country: Yup.string().required(t("selector_Form_Select_Country_Label")),
    state: Yup.string().required(t("selector_Form_Select_State_Label")),
    district: Yup.string().required(t("selector_Form_Select_District_Label")),
    altitude: Yup.number(),
    latitude: Yup.string().matches(
      /^[-+]?([1-8]?\d(?:\.\d+)?|90(?:\.0+)?), *[-+]?(180(?:\.0+)?|(?:1[0-7]\d|[1-9]?\d)(?:\.\d+)?)$/,
      "latitude, longitude is the format"
    ),
    pinCode: Yup.number()
      .required(t("site_Form_Validation_Pin_Code"))
      .typeError(t("site_Form_Validation_Pin_Code_Type_Error")),
    villageTownCity: Yup.string().required(
      t("site_Form_Validation_Village_Town_City")
    ),
    addressLine1: Yup.string().required(t("site_Form_Validation_Address_Line"))
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const handleDialogClose = () => {
    if (status === "success") {
      history.push("/configuration/site");
      dispatch({
        type: "SITE_CONFIGURATION_RESET_STATUS"
      });
    }
    dispatch({
      type: "SITE_CONFIGURATION_STATUS_UPDATE",
      status: "idle"
    });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("site_Form_Header_Label")}
          breadcrumbs={breadcrumbs}
        />
      </Grid>
      <Grid container>
        <Paper elevation={2} style={{ padding: "2em" }}>
          <Grid item>
            <h2>{t("site_Form_Title_Label")}</h2>
          </Grid>
          <Grid container className="d-block d-md-flex ">
            <Grid item width={12} className="site-id-label">
              <label>
                {t("site_Form_Title_Org_Label")} : <b>{org}</b>
              </label>
            </Grid>
          </Grid>
          <Grid item>
            <CommonForm
              fields={fields}
              submitLabel={t("site_Form_Submit_Label")}
              submittingLabel={t("site_Form_Submitting_Label")}
              initialValues={values}
              validationSchema={Yup.object().shape({ ...validations })}
              validateOnBlur={true}
              edit={edit}
              enableReinitialize
              validateOnChange={true}
              onSubmit={(
                values,
                { setSubmitting, setFieldError, resetForm, errors }
              ) => {
                if (!!errors) {
                  setSubmitting(false);
                }
                handleSubmit(values, resetForm, setSubmitting);
              }}
              formikRef={formikForm}
              buttonSize="3"
              buttonPosition="right"
            />
          </Grid>
        </Paper>
      </Grid>
      <SuccessFeedback
        open={status === "success"}
        onClose={handleDialogClose}
        successMessage={t("site_Form_Success_Message")}
        createAnotherMesssage={t("site_Form_Create_Another_Site")}
      />
      <FailureFeedback
        open={status === "failed"}
        onClose={handleDialogClose}
        status={!!createError ? createError.status : ""}
        message={
          !!createError
            ? !!createError.error.message
              ? createError.error.message
              : ""
            : ""
        }
      />
    </Grid>
  );
}
