import React, { useState, useEffect,useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import ItemLevelConfigurationBasic from "./ItemLevelConfigurationBasic";
import ItemLevelConfigurationAdvanced from "./ItemLevelConfigurationAdvanced";
import { provisioningService } from "services/provisioningServices";
import { itemLevelConfigurationService } from "services/itemLevelConfigurationServices";
import format from "date-fns/format";
//import { number, string } from "prop-types";

//For Tab view - Starts
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import PageHeader from "../../../components/layout/PageHeader";
import { useTranslation } from "react-i18next";
import { customeGroupService } from "services/customerGroupServices";
import { errorContext } from "context/errorContext";
import { farmerAssociationService } from "services/farmerAssociationServices";
import SweetAlert from 'react-bootstrap-sweetalert';
import ConfirmPopUp from "components/common/elements/ConfirmPopUp";

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  tabIndicator: {
    backgroundColor: "#1089ff"
  }
}));
//For Tab view - Ends


export default function ItemLevelConfigurationTabs({ 
  valuesBasic, 
  valuesAdvanced,
  codEnabledData,
  //handleSubmitBasic
  setProductDetailsByProdId,
  setProductPriceMappingByProdId
}) {
  // console.log("Basic Values flowing to Tabs Component:===== ", valuesBasic);
  // console.log("Advanced Values flowing to Tabs Component:===== ", valuesAdvanced);

  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const { dispatch: errorDispatch } = useContext(errorContext);

  const [basicTabSuccess, setBasicTabSuccess] = React.useState(false);
  const [basicTabConfirmSuccess, setBasicTabConfirmSuccess] = React.useState(false);

  const [isConfirmStatus,setIsConfirmStatus] = useState(false);
  const [basicObjCopy, setBasicObjCopy] = useState({});
  // const [valuesBasic1, setValuesBasic1] = useState({});
  // const [showBasicContent1, setShowBasicContent1] = useState(false);
   const [fieldValue1, setFieldValue1] = useState(false);

  //For Tab view - Starts
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {

    setValue(newValue);
    //console.log("Tab HandleChange :======= ",  newValue, valuesBasic);
    if(newValue === 0){
      itemLevelConfigurationService
      .getItemLevelConfigListById(valuesBasic.id)
      .then(data => {
        //valuesBasic.isCodEnabled = data.isCodEnabled;
        setProductDetailsByProdId(data);
        //console.log("Tab HandleChange API Call for Basic:======= ", valuesBasic.isCodEnabled);
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
    }

    if(newValue === 1){
      itemLevelConfigurationService
      .getItemLevelConfigPriceMappingList(valuesBasic.id)
      .then(data1 => {
        //valuesBasic.isCodEnabled = data.isCodEnabled;
        setProductPriceMappingByProdId(data1.content);
       //console.log("Tab HandleChange API Call for Advanced:======= ", data1.content); 
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
    }

  };
  //For Tab view - Ends

  const hideAlert = () => {
      setBasicTabSuccess(false);
      //setBasicTabConfirmSuccess(false);
  }

  const handleConfirmDialogClose = () => {
    //console.log("hitting cancel")
    setProductDetailsByProdId((prevState) => ({
      ...prevState,
      prevState
    }))
    setIsConfirmStatus(false);
    setFieldValue1(true);
  }

  const invokeBasicUpdateAPI = () => {
    setIsConfirmStatus(false);

    let objkeys = Object.keys(basicObjCopy);
    let myKey = objkeys[56];
    let objValues = Object.values(basicObjCopy);
    let myValue = objValues[56];
    // console.log("my Key === ", myKey);
    // console.log("my Value === ", myValue);
    
    let constructedConfigBasicArr = [];
    let constructedConfigBasicObj = {};
    constructedConfigBasicObj.entityId = basicObjCopy.id;
    constructedConfigBasicObj.entityName = "product";
    if(myKey === "isCodEnabled"){
      constructedConfigBasicObj.field = "is_cod_enabled";
    }
    if(myValue === true){
      constructedConfigBasicObj.value = true;
    }
    if(myValue === false){
      constructedConfigBasicObj.value = false;
    }

    constructedConfigBasicArr.push(constructedConfigBasicObj);
    //console.log("Basic Tabs values to be passed to Backend :========= ", constructedConfigBasicArr);

      try {
        const repeat = null;
        const result =  itemLevelConfigurationService.saveItemLevelConfigurations(constructedConfigBasicArr);
        setBasicTabSuccess(true);
        //setSubmitting(false);
        // itemLevelConfigurationService
        //           .getItemLevelConfigListById(basicObjCopy.id)
        //           .then(data2 => {                  
        //             setValuesBasic1(data2 || []);
        //             setShowBasicContent1(true);
        //           })
        //           .catch(error => {
        //             errorDispatch({
        //               type: "ERROR",
        //               error
        //             });
        //           });
        //setSubmitting(false);
      } catch (e) {
        console.log(e);
        setBasicTabSuccess(false);
        //setSubmitting(false);
      }

  }

  function openApproveConfirmPopUp(values){
    setIsConfirmStatus(true);
    setBasicObjCopy(values);
    //setFieldValue1(!values.isCodEnabled);
  }

  async function handleSubmit(values, resetForm, setSubmitting) {
    //console.log("Tabs Basic submit values :-------- ", values);
    openApproveConfirmPopUp(values);

    // setBasicTabSuccess(false);
    // setBasicTabConfirmSuccess(true);

    // itemLevelConfigurationService
    //   .getItemLevelConfigListById(values.id)
    //   .then(data => {
    //     codEnabledData = values.isCodEnabled;
    //   })
    //   .catch(error => {
    //     errorDispatch({
    //       type: "ERROR",
    //       error
    //     });
    //   });

      // let objkeys = Object.keys(values);
      // let myKey = objkeys[56];
      // let objValues = Object.values(values);
      // let myValue = objValues[56];
      // // console.log("my Key === ", myKey);
      // // console.log("my Value === ", myValue);
      
      // let constructedConfigBasicArr = [];
      // let constructedConfigBasicObj = {};
      // constructedConfigBasicObj.entityId = values.id;
      // constructedConfigBasicObj.entityName = "product";
      // if(myKey === "isCodEnabled"){
      //   constructedConfigBasicObj.field = "is_cod_enabled";
      // }
      // if(myValue === true){
      //   constructedConfigBasicObj.value = true;
      // }
      // if(myValue === false){
      //   constructedConfigBasicObj.value = false;
      // }

      // constructedConfigBasicArr.push(constructedConfigBasicObj);
      // // console.log("Basic Tabs values to be passed to Backend :========= ", constructedConfigBasicArr);

      // // setTimeout(function(){
      // //   try {
      // //     const repeat = null;
      // //     const result =  itemLevelConfigurationService.saveItemLevelConfigurations(constructedConfigBasicArr);
      // //     setBasicTabSuccess(true);
      // //     setSubmitting(false);
      // //   } catch (e) {
      // //     console.log(e);
      // //     setBasicTabSuccess(false);
      // //     setSubmitting(false);
      // //   }
      // // }, 3000)
      setSubmitting(false);
  }

  // Calling the create template form with values(to initialize),
  return (
    <>
     <Grid container>

      <Grid container>
        <Paper elevation={2} style={{ padding: "2em", width: "100%" }}>
          {" "}
          <Grid item xs={12}> 
          </Grid>
          <Grid item>

          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="Retailer"              
          >
          <Tab label="Basic" {...a11yProps(0)} />
          <Tab label="Advanced" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={value} index={0} >          
            <ItemLevelConfigurationBasic
              values={valuesBasic}
              handleSubmit={handleSubmit}
              showSubmitButton={true}
              codEnabledData={codEnabledData}
              // fieldValue1={fieldValue1}
              setProductDetailsByProdId={setProductDetailsByProdId}
            />

          {/* {showBasicContent1 ? 
            <ItemLevelConfigurationBasic
                        values={valuesBasic1}
                        handleSubmit={handleSubmit}
                        showSubmitButton={true}
                        codEnabledData={codEnabledData}
                      /> : null} */}

            {/* {basicTabConfirmSuccess  ? 
              <SweetAlert info
                title="All the corresponding item level price mapping COD will be updated !!!"
                onConfirm={hideAlert}
                //onCancel={hideAlert}
                timeout={3000}
              ></SweetAlert> 
            : null} */}

            <ConfirmPopUp
                    open ={isConfirmStatus}
                    onSubmit={invokeBasicUpdateAPI}
                    confirmMessage={"Do you really want to Update?"}
                    onClose={handleConfirmDialogClose}
                    showButtons={true}
                  />

            {basicTabSuccess  ? 
              <SweetAlert success
                title="Configuration updated successfully !!!"
                onConfirm={hideAlert}
                onCancel={hideAlert}
                //timeout={2000}
              ></SweetAlert> 
            : null}

          </TabPanel>
          <TabPanel value={value} index={1} >
            <ItemLevelConfigurationAdvanced
              values={valuesAdvanced}
              handleSubmit={handleSubmit}
              showSubmitButton={true}
              //setProductPriceMappingByProdId={setProductPriceMappingByProdId}
            />
          </TabPanel>
          </Grid>
        </Paper>
      </Grid>
    </Grid>

    </>
  );
}
