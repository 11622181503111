import { all, call, put, takeLatest, select, fork } from "redux-saga/effects";
import { provisioningService } from "services/provisioningServices";
import { metadataService } from "services/metadataServices";

function* editUser() {
  yield takeLatest("USER_CONFIGURATION_EDIT_USER", function* edit({
    values,
    resetForm,
    userId
  }) {
    try {
      const valuesToSend = yield call(formatUserData, values);

      if (typeof values.roles === "string") {
        const roles = [];
        roles.push(values.roles);
        valuesToSend.roles = [...roles];
      }
      const result = yield call(
        provisioningService.editUser,
        valuesToSend,
        userId
      );
      yield put({
        type: "USER_CONFIGURATION_USER_EDIT_SUCCESS",
        result: result.response
      });
      yield put({
        type: "USER_CONFIGURATION_RESET_SITES_CONFIGURATION"
      });
    } catch (e) {
      console.error("error in editing user......", e.message);
      yield call(resetForm);
      yield put({
        type: "USER_CONFIGURATION_USER_CREATION_FAIL",
        error: e
      });
    }
  });
}

function* createUser() {
  yield takeLatest("USER_CONFIGURATION_CREATE_USER", function* create({
    values,
    resetForm,
    setSubmitting
  }) {
    try {
      const valuesToSend = yield call(formatUserData, values);

      const roles = [];
      roles.push(values.roles);
      valuesToSend.roles = roles;

      const result = yield call(provisioningService.createUser, valuesToSend);
      yield put({
        type: "USER_CONFIGURATION_USER_CREATION_SUCCESS",
        result: result.response
      });
      yield call(resetForm);
      yield put({
        type: "USER_CONFIGURATION_RESET_SITES_CONFIGURATION"
      });
    } catch (e) {
      // yield call(resetForm);
      setSubmitting(false);
      console.error("error in creating user......", e);
      yield put({
        type: "USER_CONFIGURATION_USER_CREATION_FAIL",
        error: e
      });
    }
  });
}

// format data to send to create/edit APIs
function* formatUserData(values) {
  let valuesToSend = { ...values };
  if (values.subOrganizationName.length === 0) {
    delete valuesToSend.subOrganizationName;
  }
  const alertConfiguration = yield select(
    state => state.userConfiguration.toJS().sitesAlertsConfiguration
  );
  // console.log("alertConfiguration ", alertConfiguration);
  // if alertconfig is present, format to send. else delete field
  if (!!alertConfiguration && alertConfiguration.length > 0) {
    // console.log("alertConfiguration ", alertConfiguration);
    let userAlertConfiguration = [];
    alertConfiguration.forEach(item => {
      // console.log("item ", item);
      if (!!item.emailAlerts) {
        let alerts = { infoAlerts: [], criticalAlerts: [] };
        alerts.infoAlerts = item.emailAlerts.infoAlerts;
        alerts.criticalAlerts = item.emailAlerts.criticalAlerts;

        item.emailAlerts = alerts;

        delete item.infoAlerts;
        delete item.criticalAlerts;
        // delete item.emailAlerts.criticalAlerts;
        // delete item.emailAlerts.infoAlerts;
      }
      if (!!item.smsAlerts) {
        let alerts = { infoAlerts: [], criticalAlerts: [] };
        alerts.infoAlerts = item.smsAlerts.infoAlerts;
        alerts.criticalAlerts = item.smsAlerts.criticalAlerts;

        item.smsAlerts = alerts;
        delete item.infoAlerts;
        delete item.criticalAlerts;
        // delete item.smsAlerts.criticalAlerts;
        // delete item.smsAlerts.infoAlerts;
      }
      userAlertConfiguration.push(item);

    });
    valuesToSend.userAlertConfiguration = userAlertConfiguration;
  } else {
    delete valuesToSend.userAlertConfiguration;
  }

  // delete confirmPassword from values sending
  delete valuesToSend.confirmpassword;

  const siteAssociationConfiguration = yield select(
    state => state.userConfiguration.toJS().sitesAssociationConfiguration
  );
  valuesToSend.siteAssociationConfiguration = siteAssociationConfiguration;
  // remove the fields that are not being sent to API
  delete valuesToSend.siteAssociation;
  delete valuesToSend.subOrganizationForSiteAssociation;
  delete valuesToSend.sitesForAlertsConfiguration;

  return valuesToSend;
}

function* fetchSitesBySubOrganizations() {
  yield takeLatest(
    "USER_CONFIGURATION_SUB_ORGANIZATIONS_UPDATED",
    function* fetchData({
      subOrganizationsSelected,
      setFieldValue,
      siteField
    }) {
      if (subOrganizationsSelected.length === 0) {
        yield put({
          type: "USER_CONFIGURATION_RESET_SITES_CONFIGURATION"
        });
        setFieldValue(siteField, []);
      } else {
        try {
          const sites = yield call(
            provisioningService.getSitesBySubOrgs,
            subOrganizationsSelected.join(","),
            0,
            1000
          );

          // if sitesSeelcted not in sites list
          // empty sitesselected
          //
          yield put({
            type: "USER_CONFIGURATION_SITES_OF_SUB_ORGANIZATION_FETCH_SUCCEED",
            sitesList: sites.content
          });

          const sitesSelected = yield select(
            state => state.userConfiguration.toJS().sitesSelected
          );

          let updatedSelection = [];
          sitesSelected.forEach(site => {
            sites.content.filter(item => site === item.siteName).length > 0 &&
              updatedSelection.push(site);
          });
          setFieldValue(siteField, updatedSelection, false);
          yield put({
            type: "USER_CONFIGURATION_ALERTS_CONFIG_SITES_SELECTED_UPDATE",
            sitesSelected: updatedSelection
          });
        } catch (error) {
          yield put({
            type: "USER_CONFIGURATION_SITES_OF_SUB_ORGANIZATION_FETCH_FAILED"
          });
          yield put({
            type: "ERROR",
            error
          });
        }
      }
    }
  );
}
function* fetchSitesBySubOrganizationsForSiteAssociation() {
  yield takeLatest(
    "USER_CONFIGURATION_SUB_ORGANIZATIONS_FOR_SITE_ASSOCIATION_UPDATED",
    function* fetchData({ subOrganizationsSelected, setFieldValue, field }) {
      if (subOrganizationsSelected.length === 0) {
        yield put({
          type: "USER_CONFIGURATION_RESET_SITES_ASSOCIATION"
        });
        setFieldValue(field, [], false);
      } else {
        try {
          const sites = yield call(
            provisioningService.getSitesBySubOrgs,
            subOrganizationsSelected.join(","),
            0,
            5000
          );

          // if sitesSeelcted not in sites list
          // empty sitesselected
          //
          yield put({
            type:
              "USER_CONFIGURATION_SITES_OF_SUB_ORGANIZATION_FOR_SITE_ASSOCIATION_FETCH_SUCCEED",
            sitesList: sites.content
          });
        } catch (error) {
          yield put({
            type: "USER_CONFIGURATION_SITES_OF_SUB_ORGANIZATION_FETCH_FAILED"
          });
          yield put({
            type: "ERROR",
            error
          });
        }
      }
    }
  );
}

function* fetchSitesConfiguration() {
  yield takeLatest(
    "USER_CONFIGURATION_ALERTS_CONFIG_SITES_SELECTED_UPDATE",
    function* getSitesConfig({ sitesSelected }) {
      // sites list stored in the redux
      const sitesList = yield select(
        state => state.userConfiguration.toJS().sitesList
      );

      let alertConfig = yield select(
        state => state.userConfiguration.toJS().sitesAlertsConfiguration
      );

      // filters the data of selected sites
      const sitesData = sitesList.filter(site =>
        sitesSelected.includes(site.siteName)
      );
      try {
        const alertConfiguration = [];
        for (let i = 0; i < sitesData.length; ++i) {
          // if not already added to the alertConfig list enter this block
          if (
            !alertConfig
              .map(config => config.siteName)
              .includes(sitesData[i].siteName)
          ) {
            for (
              let j = 0;
              j < sitesData[i].chillingUnitCompresedDTOS.length;
              ++j
            ) {
              // for each chilling unit in the site, set the default data.
              const formatedData = {
                siteName: sitesData[i].siteName,
                siteId: sitesData[i].siteUUID,
                chillingUnitId:
                  sitesData[i].chillingUnitCompresedDTOS[j].chillingUnitUuid,
                enableEmailAlerts: true,
                enableSMSAlerts: true,
                enableWebAlerts: false,
                infoAlerts: [],
                criticalAlerts: [],
                organization: sitesData[i].organizationName,
                subOrganization: sitesData[i].subOrganizationName
              };

              alertConfiguration.push(formatedData);
            }
          } else {
            // if already added, then just add the existing config.
            alertConfig.forEach(alert => {
              if (alert.siteName === sitesData[i].siteName) {
                alertConfiguration.push(alert);
              }
            });
          }
        }
        yield put({
          type: "USER_CONFIGURATION_ALERTS_CONFIGURATION_UPDATE_SUCCEED",
          alertConfiguration
        });
      } catch (error) {
        yield put({
          type: "USER_CONFIGURATION_SITES_FETCH_FAILED",
          message: error
        });
        yield put({
          type: "ERROR",
          error
        });
      }
    }
  );
}

function* siteAssociationUpdate() {
  yield takeLatest("USER_CONFIGURATION_SITE_ASSIGN", function* assignSite({
    sitesAssigned
  }) {
    const sitesAssociationList = yield select(
      state => state.userConfiguration.toJS().sitesAssociationList
    );

    // filter the data of the selected sites
    const sitesData = sitesAssociationList.filter(site =>
      sitesAssigned.includes(site.siteName)
    );

    const config = sitesData.map(site => ({
      siteName: site.siteName,
      siteUUID: site.siteUUID,
      isBookmarked: false,
      organization: site.organizationName,
      subOrganization: site.subOrganizationName
    }));

    yield put({
      type: "USER_CONFIGURATION_SITE_ASSOCIATION_CONFIGURATION",
      config
    });
  });
}

function* fetchRoles() {
  yield takeLatest("USER_CONFIGURATION_FETCH_ROLES", function* getRoles({
    orgName
  }) {
    try {
      const roles = yield call(provisioningService.getRoles, orgName);
      yield put({
        type: "USER_CONFIGURATION_ROLES_FETCH_SUCCEED",
        roles
      });
    } catch (error) {
      yield put({
        type: "USER_CONFIGURATION_ROLES_FETCH_FAILED"
      });
      yield put({
        type: "ERROR",
        error
      });
    }
  });
}

function* fetchSubOrgs() {
  yield takeLatest(
    "USER_CONFIGURATION_ORGANIZATION_SELECT_UPDATED",
    function* getSubOrgs({ org }) {
      try {
        yield put({
          type: "USER_CONFIGURATION_SUB_ORGANIZATION_LOADING"
        });
        const subOrganizations = yield call(
          provisioningService.getSubOrganizationsByOrgName,
          org,
          0,
          1000
        );
        yield put({
          type: "USER_CONFIGURATION_SUB_ORGANIZATION_FETCH_SUCCEED",
          subOrganizations
        });
      } catch (error) {
        yield put({
          type: "USER_CONFIGURATION_SUB_ORGANIZATION_FETCH_FAILED"
        });
        yield put({
          type: "ERROR",
          error
        });
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(fetchSitesBySubOrganizations),
    fork(fetchRoles),
    fork(fetchSubOrgs),
    fork(fetchSitesConfiguration),
    fork(createUser),
    fork(editUser),
    fork(siteAssociationUpdate),
    fork(fetchSitesBySubOrganizationsForSiteAssociation)
  ]);
}
