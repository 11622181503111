import { Map } from "immutable";
const initialState = new Map({
  siteValue: [],
  site: null,
  noSite: false,
  editSiteValues: null,
  loadingSiteDetails: false,
  organizations: [],
  subOrganizations: [],
  chillingUnitList: [],
  chillingUnitsFetchStatus: "loading",
  devices: [],
  deviceTypes: [],
  error: "",
  createError: null,
  status: "idle",
  validationStatus: "idle",
  result: null
});
export default function site(state = initialState, action) {
  switch (action.type) {
    case "SITE_CONFIG_SUB_ORG_FETCH_SUCCEED":
      return state.set("subOrganizations", action.subOrganizations);
    case "SITE_CONFIGURATION_DEVICE_VALIDATION_SUCCESS":
      return state
        .set("validationStatus", "success")
        .set("devices", action.devices);
    case "SITE_CONFIGURATION_DEVICE_RESET":
      return state.set("devices", []);
    case "SITE_CONFIGURATION_DEVICE_VALIDATION_FAILED":
      return state
        .set("validationStatus", "failure")
        .set("error", action.validationError);
    case "SITE_CONFIGURATION_DELETE_DEVICE":
      return state.set(
        "devices",
        state
          .toJS()
          .devices.filter(device => device.serialNumber !== action.device)
      );

    case "SITE_CONFIGURATION_STATUS_UPDATE":
      return state.set("status", action.status);

    case "SITE_CONFIGURATION_DEVICE_TYPE_SUCCESS":
      let deviceTypes = [...state.get("deviceTypes")];
      deviceTypes[action.index] = action.deviceType;
      return state.set("deviceTypes", deviceTypes);
    case "SITE_CONFIGURATION_DEVICE_TYPE_FAILED":
      let deviceTypesfailed = [...state.get("deviceTypes")];
      deviceTypesfailed[action.index] = "";
      return state.set("deviceTypes", deviceTypesfailed);
    case "SITE_CONFIGURATION_CREATE_SITE_SUCCESS":
      return state.set("result", action.result).set("status", "success");
    case "SITE_CONFIGURATION_CREATE_SITE_FAILED":
      return state.set("status", "failed").set("createError", action.error);
    case "SITE_CONFIGURATION_EDIT_SITE_SUCCESS":
      return state.set("result", action.result).set("status", "success");
    case "SITE_LOADING":
      return state.set("loadingSiteDetails", true);
    case "GET_SITE_DETAILS_SUCCESS":
      return state.set("site", action.site).set("loadingSiteDetails", false);
    case "CHILLING_LIST_FETCH":
      return state.set("chillingUnitsFetchStatus", "loading");
    case "CHILLING_UNIT_LIST_UPDATE":
      return state
        .set("chillingUnitList", action.chillingUnitData)
        .set("chillingUnitsFetchStatus", "success");
    case "GET_EDIT_SITE_DETAILS_BY_ID":
      return state
        .set("editSiteValues", null)
        .set("noSite", false)
        .set("loadingSiteDetails", true);
    case "GET_EDIT_SITE_DETAILS_BY_ID_SUCCESS":
      return state
        .set("editSiteValues", action.site)
        .set("noSite", false)
        .set("loadingSiteDetails", false);
    case "GET_EDIT_SITE_DETAILS_BY_ID_FAIL":
      return state
        .set("editSiteValues", null)
        .set("noSite", true)
        .set("loadingSiteDetails", false);
    case "SITE_CONFIGURATION_RESET_STATUS":
      return state.set("status", "idle");
    case "ERROR":
      console.log("action ", action.error);
      return state
        .set("chillingUnitsFetchStatus", "failed")
        .set("error", action.error)
        .set("status", "failed");
    default:
      return state;
  }
}
