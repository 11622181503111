import React, { useContext } from "react";

import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import Breadcrumbs from "./Breadcrumbs";
import DisplayDate from "../common/elements/DisplayDate";

import Divider from "@material-ui/core/Divider";
import OrgTreeIcon from "components/common/elements/OrgTreeIcon";
import { provisioningService } from "services/provisioningServices";
//import Grid from "@material-ui/core/Grid";
//import { chillingUnitService } from "../../services/chillingUnitService";
import { makeStyles } from "@material-ui/core/styles";
import { dashboardContext } from "context/dashboardContext";
import RenderIf from "components/Auth/RenderIf";
import { useTranslation } from "react-i18next";

export default function PageHeader({
  title,
  breadcrumbs,
  dashboard,
  dashboardData
}) {
  const useStyles = makeStyles(theme => ({
    pageHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        marginTop: "5em"
      },
      borderBottom: "1px solid lightgray",
      padding: "1em 0em 0em 0em",
      marginBottom: "1em"
    },
    dateAndOrg: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      [theme.breakpoints.down("md")]: {
        alignItems: "center"
      }
    },
    showDate: {
      paddingBottom: ".75em"
    },
    showOrgName: {
      display: "flex",
      alignItems: "center",
      "& h1": {
        fontSize: "14px",
        marginBottom: "0em",
        lineHeight: "1.4em",
        fontWeight: "600"
      },
      "& a": {
        color: "black",
        "& :hover ": {
          color: "#166aab"
        }
      },
      [theme.breakpoints.down("md")]: {
        paddingBottom: ".75em"
      }
    },
    siteAndBmcContainer: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
      }
    },
    siteAndBmc: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      "& hr": {
        height: "1em",
        backgroundColor: "black",
        width: "1px",
        margin: "0em .5em 0em .5em"
      },
      "& a": {
        color: "black",
        "& :hover ": {
          color: "#166aab"
        }
      }
    },
    totalSitesLink: {
      "& span>b": {
        fontSize: "14px",
        color: "#2E384D"
      }
    },
    totalBmcLink: {
      "& span>b": {
        fontSize: "14px",
        color: "#2E384D"
      }
    },
    hDivider: {
      marginBottom: theme.spacing(3)
    }
  }));
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const [orgId, setOrgId] = React.useState(null);
  const organizationName = user && user.organizationName;
  const classes = useStyles();
  const { t } = useTranslation();

  const { state: dashboardState } = useContext(dashboardContext);

  React.useEffect(() => {
    if (dashboard) {
      provisioningService
        .getOrgByName(organizationName)
        .then(data => {
          setOrgId(data.orgId);
          //console.log("From Org Service", data);
          localStorage.setItem("orgId", data.orgId);
        })
        .catch(error => console.error(error));
        
    }
    const path = window.location.hash;
    if (!path.includes("device-data")) {
      localStorage.removeItem("rawData");
    }
  }, []);

  return (
    <React.Fragment>
      <div className={classes.pageHeader}>
        <div className={classes.dateAndOrg}>
          <span className={classes.showDate}>
            <DisplayDate />
          </span>
          <div className={classes.showOrgName}>
            {!!dashboard ? (
              <>
                <Link to={`/organization/profile/${organizationName}`}>
                  <h1>{`${organizationName} ${title}`}</h1>
                </Link>
                {/* <OrgTreeIcon organization={orgId} button /> */}
              </>
            ) : (
              <h1>{title}</h1>
            )}
          </div>
        </div>

        {/* {dashboard && (
          <div className={classes.siteAndBmcContainer}>
            <div className={classes.siteAndBmc}>
              <div className={classes.totalSitesLink}>
                <Link
                  to={
                    !!dashboardData &&
                    !!dashboardData.siteAggregate &&
                    dashboardData.siteAggregate.assignedSiteCount > 0
                      ? `/totalsites?date=${dashboardState.currentDate}&paging=&page=0&size=1000`
                      : `#`
                  }
                  className=""
                >
                  <span className="">
                    {t("page_Header_Site_Label")} :
                    <b>
                      &nbsp;
                      {!!dashboardData && !!dashboardData.siteAggregate
                        ? dashboardData.siteAggregate.assignedSiteCount
                        : 0}
                    </b>
                  </span>
                </Link>
              </div> */}

              {/* <RenderIf privilege={["BMC_LIST"]}> */}

              {/* <Divider orientation="vertical" />
              <div className={classes.totalBmcLink}>
                <Link
                  to={
                    !!dashboardData &&
                    !!dashboardData.siteAggregate &&
                    dashboardData.siteAggregate.assignedChillingUnitCount > 0
                      ? {
                          pathname: "/all-bmc/ALL",
                          search: `?page=0&paging=&date=${dashboardState.currentDate}`
                        }
                      : `#`
                  }
                  className=""
                >
                  <span>
                    {t("page_Header_BMC_Label")} :
                    <b>
                      &nbsp;
                      {!!dashboardData && !!dashboardData.siteAggregate
                        ? dashboardData.siteAggregate.assignedChillingUnitCount
                        : 0}
                    </b>
                  </span>
                </Link>
              </div> */}

              {/* </RenderIf> */}

            {/* </div>
          </div>
        )}
        {!!breadcrumbs && (
          <div className={classes.siteAndBmcContainer}>
            <Breadcrumbs data={[...breadcrumbs]} />
          </div>
        )} */}
      </div>
      {/* <Divider className={classes.hDivider} /> */}
    </React.Fragment>
  );
}
