import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Table from "components/common/table/DynamicPaginationTable2";
import { provisioningService } from "../../../services/provisioningServices";
import PageHeader from "components/layout/PageHeader";

//import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { errorContext } from "context/errorContext";
import { useTranslation } from "react-i18next";
import { ToolTip } from "../../../components/common/table/ToolTipCell";
import "../../../components/Table.scss";

const SubOrganizationList = props => {
  const [subOrganizations, setSubOrganizations] = useState([]);

  const { dispatch: errorDispatch } = useContext(errorContext);

  const { t } = useTranslation();

  const user = JSON.parse(localStorage.getItem("currentUser"));

  const dropitems = [
    // {
    //   item: <Link to="/configuration/organization">Add New Org</Link>
    // },
    {
      item: (
        <Link to="/configuration/suborganizationprofile">
          {t("sub_Org_List_Drop_Down_Add_Sub_Org_Label")}
        </Link>
      )
    }
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: t("sub_Org_List_Sub_Org_Name_Label"),
        accessor: "orgName",
        Cell: ({ cell }) => {
          return (
            <Link to={`/organization/profile/${cell.value}`}>{cell.value}</Link>
          );
        }
      },
      {
        Header: t("sub_Org_List_Sub_Org_Display_Name"),
        accessor: "orgDisplayName",
        width: 300
      },
      {
        Header: t("sub_Org_List_Sub_Org_Type_Label"),
        accessor: "orgType"
      },
      {
        Header: t("sub_Org_List_Parent_Name_Label"),
        accessor: "parentOrgName"
      },
      {
        Header: t("sub_Org_List_Address_Label"),
        accessor: "addressLine1"
      },
      {
        Header: t("sub_Org_List_Contact_Person_Label"),
        accessor: "contactPersonName"
      },
      {
        Header: t("sub_Org_List_Contact_Number_Label"),
        accessor: "contactNumber"
      },
      {
        Header: t("sub_Org_List_Email_Label"),
        accessor: "emailId",
        Cell: ({ row }) => {
          return (
            <>
              <ToolTip name={row.original.emailId}>
                <span>{row.original.emailId}</span>
              </ToolTip>
            </>
          );
        }
      },
      {
        Header: t("sub_Org_List_Actions_Label"),
        Cell: ({ row }) => {
          let link = `/configuration/suborganizationprofile/${row.original.orgId}`;
          return (
            <>
              {(!!user.uiPrivileges &&
                user.uiPrivileges.includes("ORGANIZATION_CONFIGURATION")) ||
              user.uiPrivileges.includes("ALL_PRIVILEGE") ? (
                <td>
                  <Link to={link}>
                    <IconButton>
                      <i className="fa fa-edit" aria-hidden="true"></i>
                    </IconButton>
                  </Link>
                </td>
              ) : null}
              {/* <td>
                <IconButton>
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </IconButton>
              </td> */}
            </>
          );
        }
      }
    ],
    []
  );

  const [status, setStatus] = React.useState("loading");
  //const [orgData, setOrgData] = React.useState([]);

  const [pageSize, setPageSize] = React.useState(1000);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageInfo, setPageInfo] = React.useState({});


  const { orgName } = useParams();

  useEffect(() => {
    setStatus("loading");
    provisioningService
      .getSubOrganizationsByOrgNameWithoutNestedObject(orgName)
      .then(res => {
        setSubOrganizations(res.content);
        setPageInfo(res.pageInfo);
        setStatus("success");
      })
      .catch(error => {
        console.error(error);
        errorDispatch({
          type: "ERROR",
          error
        });
        setStatus("failed");
      });
  }, [pageIndex, pageSize]);

  const data = React.useMemo(() => subOrganizations);

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={`${orgName} ${t("sub_Org_List_Header_Label")}`}
          breadcrumbs={[
            {
              label: t("sub_Org_List_Breadcrum_Dashboard_Label"),
              link: "/dashboard"
            },
            { label: t("sub_Org_List_Breadcrum_Configuration_Label") },
            { label: t("sub_Org_List_Breadcrum_List_Label") }
          ]}
        />
      </Grid>
      {status === "loading" ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: "50vh" }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Table
          columns={columns}
          data={data}
          needDropdown={true}
          dropItems={dropitems}
          fileName={"subOrganizationList.xls"}
        />
      )}
    </Grid>
  );
};

export default SubOrganizationList;
