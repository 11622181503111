import { func } from "prop-types";
import { utilityServices } from "./utilityServices";

function getToken() {
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const token = user && user.jwtAccessToken;
  return token;
}

async function getAllSourceType() {
  const url = `${process.env.REACT_APP_SOURCE_TYPE_LIST}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
       authToken: localStorage.getItem("idToken"),
       "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
       Authorization: `Bearer ${localStorage.getItem("bearerToken")}`
    },
    body: JSON.stringify()
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function getAllCustomerGroup() {
  const url = `${process.env.REACT_APP_CUSTOMER_GROUP_LIST}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
       authToken: localStorage.getItem("idToken"),
       "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC",
       Authorization: `Bearer ${localStorage.getItem("bearerToken")}`
    },
    body: JSON.stringify()
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function createCustomerGroup(data) {
  const url = `${process.env.REACT_APP_CUSTOMER_GROUP_CREATE}`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
      authToken: localStorage.getItem("idToken"),
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQfC"
    },
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

export const customeGroupService = {
    getAllSourceType,
    getAllCustomerGroup,
    createCustomerGroup
};