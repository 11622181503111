import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductForm from "./ProductForm";
import { authContext } from "context/authContext";
import { productService } from "services/productServices";
import { notificationService } from "services/notificationServices";
import { categoryService } from "services/categoryServices";
import addDays from "date-fns/addDays";
import { errorContext } from "context/errorContext";
import {
  CustomizedPrimaryButton,
  WhiteButton
} from "components/common/elements/CustomizedPrimaryButton";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import orgTreeIcon from "assets/org-tree.png";
import IconButton from "@material-ui/core/IconButton";
import FailureFeedback from "components/common/elements/FailureFeedback";
import { func } from "prop-types";
import { provisioningService } from "../../../services/provisioningServices.js";

export default function CreateProduct() {
  const { dispatch: errorDispatch } = useContext(errorContext);
  const org = JSON.parse(localStorage.getItem("currentUser")).organizationName;
  const { authState } = useContext(authContext);
 
  const dispatch = useDispatch();
 
  const createError = useSelector(state => state.site.toJS().createError);
 
  const [language, setLanguage] = React.useState([]);
  const [status, setStatus] = useState("loading");

  const [categoryName,setCategoryName] = React.useState([]);
  const [categoryId,setCategoryId] = React.useState([]);
  const [categoryTree,setCategoryTree] = React.useState([]);
  const [pbpData,setPbpData] = React.useState([]);
  const [validDateRangeAlertMessage, setValidDateRangeAlertMessage] = useState(false);

  const { t } = useTranslation();

  function getPbpList(){
    productService.getPbpList().then(data =>{
      if(data){
        setPbpData(data);
      }
    });

    notificationService.getNotificationUIOptions('LANGUAGE').then(data => {
      if (data) {
        setLanguage(data);
      }
    });
  }

  useEffect(() => {
    provisioningService.verifyOtpNew('1313131313').then((res) => {
      const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
      const organizationId = JSON.parse(localStorage.getItem("orgId"));
      getPbpList();
    })
    .catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });      
  }, []);

  function handleSubmit(values, resetForm, setSubmitting) {
    var createdDate = new Date(values.createdDate);  
    var expiryDate = new Date(values.expiryDate);

    let diffInMs = Math.floor(expiryDate - createdDate);
    let days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    if(days < 0){
      setSubmitting(false);
      setValidDateRangeAlertMessage(true);
      return;
    }

    if(values.pbpId){
      let pbp = {};
      pbp.id = values.pbpId;
      values.pbp = pbp;
    }

    productService.createProduct(values).then(data => {
      if (data) {
        console.log(data);
        resetForm();
        setStatus("success");
      }
    })
    .catch(error => {
      console.error(error);
        errorDispatch({
        type: "ERROR",
        error
      });
      setStatus("failed");
    });
  }

  const handleValidDateAlertClose = () => {
    setValidDateRangeAlertMessage(false);
  }

  function handleCloseDialogue(){

  }

  let values = {
    id: "",
    amount: "",
    bar_code: "",
    batch: "",
    benefits:"",
    brand:"",
    bulkBuyAllowed:"",
    buyingPrice:"",
    categoryTree:"",
    categoryName:"",
    createdDate:addDays(new Date(), -2),   
    expiryDate:new Date(),
    gstPercentage:"",    
    hsn:"",
    imageUrl:"",
    initialSize:"",
    isActive:"",
    isActiveEligible:"",
    licenses:"",
    manufacturingDate:new Date(),
    maxBulkQty:"",
    maxQtyAllowed:"",
    method:"",
    minBulkQty:"",
    minQtyAllowed:"",
    priority:"",
    productCode:"",
    productGroup:"",
    productName:"",
    productType:"",
    productUuid:"",
    quantity:"",
    rating:"",
    searchTag:"",
    sellingPrice:"",
    sku:"",
    status:"",
    stepCount:"",
    uom:"",
    variant:"",
    categoryId:"",
    pbpId:"",
    provider:"",
    fetchTag:"",
    orgId:"",
    orgName:"",
    groupName:"",
    omsReference:"",
    languageList:[
      {
        language:"",
        product: "",
        benefits:"",       
        Category:"",
        howToUse:"",
        description:""        
      }
    ]
  };
  return (
    <>      
      <ProductForm
        values={values}
        pbpData = {pbpData}
        language={language}
        categoryName = {categoryName}
        categoryId = {categoryId}
        categoryTree = {categoryTree}
        handleSubmit={handleSubmit}
        status={status}
        createError={createError} />

      <FailureFeedback
        open={validDateRangeAlertMessage}
        onClose={handleValidDateAlertClose}
        status={"Failed"}
        message={t("product_list_Valid_Date_Range_Message")}
        showButtons={true} />      
    </>
  );
}
