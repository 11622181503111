import React from "react";
import BmcRating from "components/common/elements/BmcRating";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useParams } from "react-router-dom";

import PageHeader from "components/layout/PageHeader";

export default function RatingGraph() {
  const { bmcId } = useParams();

  let startDate = new Date(new Date().getFullYear(), new Date().getMonth()-1, 1);
  let endDate = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
  return (
    <Grid container xs={12}>
      <Grid item xs={12}>
        <PageHeader
          title={`Rating`}
          breadcrumbs={[
            { label: "Dashboard", link: "/dashboard" },
            { label: "Site Details" },
            { label: "Rating" }
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <Paper
          elevation={2}
          style={{
            padding: "2em",
            width: "100%"
          }}
        >
          <BmcRating
            bmcId={bmcId}
            translate={{
              x: 200,
              y: 350
            }}
            startDate={startDate}
            endDate={endDate}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
