import React, { useEffect, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { provisioningServices } from "services/provisioningServices";
import PageHeader from "components/layout/PageHeader";
import OrgTreeIcon from "components/common/elements/OrgTreeIcon";
import CircularProgress from "@material-ui/core/CircularProgress";
import TemplateForTables from "components/layout/innerPagesTemplate";
import Grid from "@material-ui/core/Grid";
import Table from "components/common/table/DynamicPaginationTable";
import IconButton from "@material-ui/core/IconButton";
import { errorContext } from "context/errorContext";

import "components/Table.scss";

const OrganizationList = props => {
  const { t } = useTranslation();

  const { dispatch: errorDispatch } = useContext(errorContext);

  const [status, setStatus] = React.useState("loading");
  const [orgData, setOrgData] = React.useState([]);
  const [pageInfo, setPageInfo] = React.useState(null);

  // const location = useLocation();
  let query = useMemo(() => new URLSearchParams(props.location.search), [
    props.location
  ]);

  useEffect(() => {
    if (!!query.get("page") && !!query.get("size")) {
      setStatus("loading");
      provisioningServices("get", "organizations", {
        pageSize: query.get("size"),
        pageIndex: query.get("page")
      })
        .then(data => {
          setOrgData(data.content);
          setPageInfo(data.pageInfo);
          setStatus("success");
        })
        .catch(error => {
          console.error(error);
          errorDispatch({
            type: "ERROR",
            error
          });
          setStatus("failed");
        });
    } else {
      props.history.push({
        pathname: props.location.pathname,
        search: `?page=0&size=10`
      });
    }
  }, [query]);

  const handlePageSize = size => {
    query.set("size", size);
    query.set("page", 0);
    props.history.push({
      pathname: props.location.pathname,
      search: query.toString()
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("organization_configuration_list_header_org_name"),
        accessor: "orgName",
        Cell: ({ row, cell }) => {
          return (
            <>
              <Link
                to={`/configuration/SuborganizationList/${row.original.orgName}`}
              >
                <span>{cell.value}</span>
              </Link>
              <OrgTreeIcon organization={row.original.orgId} />
            </>
          );
        }
      },
      {
        Header: t("organization_configuration_list_header_org_display_name"),
        accessor: "orgDisplayName"
      },
      {
        Header: t("organization_configuration_list_header_website"),
        accessor: "orgUrl"
      },
      {
        Header: t("organization_configuration_list_header_address"),
        accessor: "address.addressLine1"
      },
      {
        Header: t("organization_configuration_list_header_contact_person"),
        accessor: "contactDetails.contactPersonName"
      },
      {
        Header: t("organization_configuration_list_header_contact_number"),
        accessor: "contactDetails.contactNumber"
      },
      {
        Header: t("organization_configuration_list_header_district"),
        accessor: "address.district"
      },
      {
        Header: t("organization_configuration_list_header_email"),
        accessor: "contactDetails.emailId"
      },
      {
        Header: t("organization_configuration_list_header_actions"),
        Cell: ({ row }) => {
          let link = "/configuration/organization/" + row.original.orgId;
          return (
            <>
              <td>
                <Link to={link}>
                  <IconButton>
                    <i className="fa fa-edit" aria-hidden="true"></i>
                  </IconButton>
                </Link>
              </td>
              {/* <td>
                <IconButton>
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </IconButton>
              </td> */}
            </>
          );
        }
      }
    ],
    []
  );

  const nextPage = () => {
    if (
      status !== "loading" &&
      pageInfo.hasNextPage &&
      Number(query.get("page")) < pageInfo.totalPages
    ) {
      query.set("page", Number(query.get("page")) + 1);
      props.history.push({
        pathname: props.location.pathname,
        search: query.toString()
      });
    }
  };
  const previousPage = () => {
    props.history.goBack();
  };
  const data = React.useMemo(() => [...orgData]);

  const dropitems = [
    {
      item: (
        <Link to="/configuration/organization">
          {t("organization_configuration_list_dropdown_add_org")}
        </Link>
      )
    },
    {
      item: (
        <Link to="/configuration/suborganizationprofile">
          {t("organization_configuration_list_dropdown_add_sub_org")}
        </Link>
      )
    }
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("organization_configuration_list_header")}
          breadcrumbs={[
            { label: t("organization_Configuration_Breadcrum_Dashboard_Label"), link: "/dashboard" },
            { label: t("organization_Configuration_Breadcrum_Confiuration_Label") },
            { label: t("organization_Configuration_Breadcrum_Sub_Org_List_Label") }
          ]}
        />
      </Grid>
      <TemplateForTables
        dropdownitems={dropitems}
        needDropdown={true}
        importIcons
      >
        {status === "loading" ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
            <Table
              columns={columns}
              data={data}
              paginationInfo={{
                pageIndex: Number(query.get("page")),
                nextPage,
                previousPage,
                pageSize: Number(query.get("size")),
                setPageSize: handlePageSize,
                pageInfo
              }}
            />
          )}
      </TemplateForTables>
    </Grid>
  );
};

export default OrganizationList;
