import React from "react";
import Chip from "@material-ui/core/Chip";
import "./form.scss";

export default function OrganizationChipAgentCriteria({
  values,
  setFieldValue
}) {

  function getVlccName(data){
    //console.log("data in getVlccName func in Chip:------", data);
    if(data.organizationName && data.ccName && data.vlccName && !data.vlccId){
      return "Organization:" +data.organizationName.orgName +", Chilling Center: " +data.ccName.chillingCenterName +", Vlcc: " +data.vlccName.map((obj) => obj.vlccName).join(',');
    }else if(data.organizationName && data.ccName && data.vlccName && data.vlccId){
      return "Organization:" +data.organizationName +", Chilling Center: " +data.ccName +", Vlcc: " +data.vlccName;
    }else if(data.organizationName && data.ccName && !data.chillingCenterId){
      return "Organization:" +data.organizationName.orgName +", Chilling Center: " +data.ccName.chillingCenterName;
    }else if(data.organizationName && data.ccName && data.chillingCenterId){
      return "Organization:" +data.organizationName +", Chilling Center: " +data.ccName;
    }else if(data.organizationName && data.ccName === "" && data.vlccName === ""){
      //console.log("hitting else if 3 statement condition in getVlccName func")
      return "Organization:" +data.organizationName.orgName;
    }else if(data.organizationName && data.orgId){
      //console.log("hitting else condition in getVlccName func")
      return "Organization:" +data.organizationName;
    }else{
      return null
    }
  }
  
  function deleteOrganizationData(data) {
    let orgData = [...values];
    
    let indexValue = 0;
    for(var i=0;i<values.length;i++){
      if(JSON.stringify(values[i]) === JSON.stringify(data)){
        indexValue = i;
        //console.log("indexValue",indexValue);
        values.splice(indexValue,1);
        break;
      }
    }

    setFieldValue(values);
  }

  let orgData = [];
  try{
    orgData = [...values];
    //console.log("orgData in Chip:-----", orgData)
  }catch{
    console.log("error");
  }

  if (orgData !== []) {
    return (
      <>   
      { orgData.map((data,index) => (
        <Chip
            style={{ marginLeft: "10px",
           marginTop:"5px", paddingTop: "5px" }}
            key={data.orgId}
            //label={"Organization:" +data.organizationName.orgName +", Chilling Center: " +data.ccName.chillingCenterName +", Vlcc: " +data.vlccName.map((obj) => obj.vlccName).join(',')}
            label={getVlccName(data)}
            className="gateway-label"
            onDelete={() => {
                deleteOrganizationData(data);
            } } />     
        ))}
      </>
    );
  }

  return null;
}
