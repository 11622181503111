import React, { useEffect, useContext, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authContext } from "context/authContext";
import addDays from "date-fns/addDays";
import { errorContext } from "context/errorContext";
import { useTranslation } from "react-i18next";
import FailureFeedback from "components/common/elements/FailureFeedback";
import { provisioningService } from "../../../services/provisioningServices.js";
import { warehouseService } from "../../../services/warehouseServices";
import { Grid, Paper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import PageHeader from "../../../components/layout/PageHeader.js";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";

import Table from "components/common/table/DynamicPaginationTable2";

const WarehouseAssociateList = props => {

    const [status, setStatus] = React.useState("loading");
    const [warehouseListData,setWarehouseListData] = React.useState([]);
    const [warehouseStatus, setWarehouseStatus] = useState("loading");
    
    const { dispatch: errorDispatch } = useContext(errorContext);
    let viewDropDown = false;

    const [pageInfo, setPageInfo] = React.useState(null);

    let query = useMemo(() => new URLSearchParams(props.location.search), [
      props.location
    ]);

    const nextPage = () => {
      if (
        status !== "loading" &&
        Number(query.get("page")) < pageInfo
      ) {
        query.set("page", Number(query.get("page")) + 1);
        props.history.push({
          pathname: props.location.pathname,
          search: query.toString()
        });
      }
    };

    const previousPage = () => {
      props.history.goBack();
    };

    function getWarehouseAssociateList(){
      let size = 5000;
      let pageIndex = 0;
      warehouseService.getWarehouseAssociateListData(size,pageIndex).then(data => {
        setWarehouseListData(data.content);
        console.log(data);
        setWarehouseStatus("success");
      }).catch(error => {
        setWarehouseStatus("failed");
         errorDispatch({
           type: "ERROR",
           error
        });
      });
    }

    useEffect(() => {
      provisioningService.verifyOtpNew('1313131313').then((res) => {
        const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
        const organizationId = JSON.parse(localStorage.getItem("orgId"));
        getWarehouseAssociateList();
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
    }, [query]);

    const columns = React.useMemo(
      () => [
        {
          Header:"wharehouse Id",
          accessor:"warehouseId"
        },
        {
          Header: "Warehouse Code",
          accessor: "warehouseCode"
        },  
        {
          Header: "Edit",
          Cell: ({ row }) => {
            let link = `/configuration/warehouse-edit/${row.original.id}`;            
            return (
                <Link to={{
                    pathname: link,
                    state: {
                        from: row.original
                    }
                  }}>   
                <IconButton>
                  <i className="fa fa-edit" aria-hidden="true"></i>
                </IconButton>
              </Link>
            );
          }
        }      
        ],
      []
    );

    const handlePageSize = size => {
      query.set("size", size);
      query.set("page", 0);
      props.history.push({
        pathname: props.location.pathname,
        search: query.toString()
      });
    };

    const data = React.useMemo(() => [...warehouseListData]);
    return(
      <Grid container>
        <Grid item xs={12}>
          <PageHeader
            title="Warehouse List"
            breadcrumbs={[
              { label: "Dashboard", link: "/dashboard" },
              { label: "Configuration" },
              { label: "WareHouse List" }
            ]}
          />
        </Grid>
        <Paper
          elevation={2}
          style={{
            padding: "2em",
            width: "100%"
          }}
        >
       
        {warehouseStatus === "loading" ? (
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ height: "50vh" }}
            >
             <CircularProgress />
            </Grid>
          ) : (
            <Table  
              columns={columns}
              data={data}
              needDropdown={viewDropDown}
              //dropItems={dropitems}
              fileName={"agentCriteriaList.xls"}
            />
          )}
       
        </Paper>        
      </Grid>  
    );
};

export default WarehouseAssociateList;
