import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { ErrorMessage, getIn } from "formik";
import PageHeader from "components/layout/PageHeader";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
import CommonForm from "components/common/form/common-form";
import FailureFeedback from "components/common/elements/FailureFeedback";
import LoanProductName from "components/common/form/LoanProductName";
import { notificationService } from "services/notificationServices";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import "components/responsive.scss";
import "../configuration.scss";
import "../../../components/responsive.scss";
import CountryStateDistrictSelector from "components/common/form/CountryStateDistrictSelector";
import ProductLanguageList from "components/common/form/ProductLanguageList";
import ApproveCategoryLangList from "components/common/form/ApproveCategoryLangList";

import { useTranslation } from "react-i18next";
import { string } from "prop-types";
import addDays from "date-fns/addDays";

export default function ApproveForm({values,status, handleSubmit,language,showSubmitButton,statusMessage}){
  const { t } = useTranslation();
  const formikForm = React.useRef(null);

  const statusSelect = ["PENDING","APPROVED","REJECTED"];

  let breadcrumbs = [
    {
      label: t("notification_Form_Breadcrum_Dashboard_Label"),
      link: "/dashboard"
    },
    { label: t("notification_Form_Breadcrum_Configuration_Label") },
    { label: t("notification_Form_Breadcrum_Notification_Label") }
  ];

  const history = useHistory();
  const handleDialogClose = () => {
    // history.push("/configuration/approve");
    history.push({pathname:"/configuration/approve" ,
      state: { 
        from: "0"
      }
    });
  };

  const fields = [
    // {
    //   type: "text",
    //   name: "categoryDescriptionDisplayName",
    //   label: t("approve_Form_Category_Description_Label"),
    //   placeholder: t("approve_Form_Category_Description_Label"),
    //   disabled:true,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },
    {
      type: "text",
      name: "categoryName",
      label: t("approve_Form_Category_Name_Label"),
      placeholder: t("approve_Form_Category_Name_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "categoryUUID",
      label: t("approve_Form_categoryUUID_Label"),
      placeholder: t("approve_Form_categoryUUID_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    // {
    //   type: "text",
    //   name: "createdBy",
    //   label: t("approve_Form_createdBy_Label"),
    //   placeholder: t("approve_Form_createdBy_Label"),
    //   disabled:true,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },
    // {
    //   type: "text",
      //   name: "createdDate",
      //   label: t("approve_Form_createdDate_Label"),
      //   placeholder: t("approve_Form_createdDate_Label"),
      //   disabled:true,
      //   grid: {
      //     xs: 12,
      //     sm: 3,
      //     className: "mb-2"
      //   }
      // },
      {
        type: "text",
        name: "fetchTag",
        label: t("approve_Form_fetchTag_Label"),
        placeholder: t("approve_Form_fetchTag_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "text",
        name: "hierarchyTree",
        label: t("approve_Form_Hierarchy_Tree_Label"),
        placeholder: t("approve_Form_Hierarchy_Tree_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "text",
        name: "id",
        label: t("approve_Form_id_Label"),
        placeholder: t("approve_Form_id_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      // {
      //   type: "text",
      //   name: "image",
      //   label: t("approve_Form_image_Label"),
      //   placeholder: t("approve_Form_image_Label"),
      //   disabled:true,
      //   grid: {
      //     xs: 12,
      //     sm: 3,
      //     className: "mb-2"
      //   }
      // },
    //   {
    //     type: "text",
    //     name: "imageUrl",
    //     label: t("approve_Form_imageUrl_Label"),
    //     placeholder: t("approve_Form_imageUrl_Label"),
    //     disabled:true,
    //     grid: {
    //       xs: 12,
    //       sm: 3,
    //       className: "mb-2"
    //     }
    //  },
     {
        type: "text",
        name: "itemGroupCode",
        label: t("approve_Form_itemGroupCode_Label"),
        placeholder: t("approve_Form_itemGroupCode_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
    },
    {
      type: "text",
      name: "omsReferenceId",
      label: t("approve_Form_omsReferenceId_Label"),
      placeholder: t("approve_Form_omsReferenceId_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "parentId",
      label: t("approve_Form_parentId_Label"),
      placeholder: t("approve_Form_parentId_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "priority",
      label: t("approve_Form_priority_Label"),
      placeholder: t("approve_Form_priority_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "searchTag",
      label: t("approve_Form_searchTag_Label"),
      placeholder: t("approve_Form_searchTag_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    // {
    //   type: "text",
    //   name: "subCategories",
    //   label: t("approve_Form_subCategories_Label"),
    //   placeholder: t("approve_Form_subCategories_Label"),
    //   disabled:true,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },
    // {
    //   type: "text",
    //   name: "updatedBy",
    //   label: t("approve_Form_updatedBy_Label"),
    //   placeholder: t("approve_Form_updatedBy_Label"),
    //   disabled:true,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },
    // {
    //   type: "text",
    //   name: "updatedDate",
    //   label: t("approve_Form_updatedDate_Label"),
    //   placeholder: t("approve_Form_updatedDate_Label"),
    //   disabled:true,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },
    // {
    //   type: "text",
    //   name: "createdAt",
    //   label: t("approve_Form_createdAt_Label"),
    //   placeholder: t("approve_Form_createdAt_Label"),
    //   disabled:true,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },
    // {
    //   type: "text",
    //   name: "entityId",
    //   label: t("approve_Form_entityId_Label"),
    //   placeholder: t("approve_Form_entityId_Label"),
    //   disabled:true,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },
    {
      type: "text",
      name: "entityType",
      label: t("approve_Form_entityType_Label"),
      placeholder: t("approve_Form_entityType_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "origin",
      label: t("approve_Form_origin_Label"),
      placeholder: t("approve_Form_origin_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "updatedAt",
      label: t("approve_Form_updatedAt_Label"),
      placeholder: t("approve_Form_updatedAt_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "custom",
      component: ({ values, errors }, renderFields) => (
        <ApproveCategoryLangList
          name="languageList"
          languageValues={language}
          values={values}
          errors={errors}
          renderFields={renderFields}
        />
      )
    },
    {
      type: "textarea",
      name: "remarks",
      label: t("approve_Form_remarks_Label"),
      placeholder: t("approve_Form_remarks_Label"),
      disabled: !showSubmitButton,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "select",
      name: "status",
      label: t("approve_Form_status_Label"),
      placeholder: t("approve_Form_status_Label"),
      option:
        statusSelect.length > 0
          ? statusSelect.map(statusSelect => ({
              value: statusSelect,
              label: statusSelect
            }))
          : [],
      disabled: !showSubmitButton,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    }                            
  ];

  let validations = {
    remarks: Yup.string().required(t("approve_form_remarks_mandatory_message")),
    status: Yup.string().required(t("approve_form_status_mandatory_message"))
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title="Category"
          breadcrumbs={breadcrumbs}
        />
      </Grid>
      <Grid container>
        <Paper elevation={2} style={{ padding: "2em" }}>
          <Grid item>
            <CommonForm
              fields={fields}
              submitLabel={t("site_Form_Submit_Label")}
              submittingLabel={t("site_Form_Submitting_Label")}
              initialValues={values}
              validationSchema={Yup.object().shape({ ...validations })}
              validateOnBlur={true}
              enableReinitialize
              validateOnChange={true}
              onSubmit={(
                values,
                { setSubmitting, setFieldError, resetForm, errors }
              ) => {
                if (!!errors) {
                  setSubmitting(false);
                }
                handleSubmit(values, resetForm, setSubmitting);
              }}
              formikRef={formikForm}
              buttonSize="3"
              buttonPosition="right"
              showSubmitButton={showSubmitButton}
            />
          </Grid>
        </Paper>
      </Grid>
      <SuccessFeedback
        open={status === "approvedSuccessfully"}
        onClose={handleDialogClose}
        successMessage={t("approve_Form_Success_Message")}
        createAnotherMesssage={t("approve_Form_Create_Another_Site")}
      />

      <SuccessFeedback
        open={status === "rejectedSuccessfully"}
        onClose={handleDialogClose}
        successMessage={t("approve_Form_Rejected_Message")}
        createAnotherMesssage={t("approve_Form_Create_Another_Site")}
      />

      <FailureFeedback
        open={status === "failed"}
        onClose={handleDialogClose}
        status={"Failed"}
        message={statusMessage}
        showButtons={true}
      /> 
    </Grid>
  )
}
