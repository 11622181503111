import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { getIn } from "formik";
import PageHeader from "components/layout/PageHeader";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
import CommonForm from "components/common/form/common-form";
import FailureFeedback from "components/common/elements/FailureFeedback";
import LoanProductName from "components/common/form/LoanProductName";
import { notificationService } from "services/notificationServices";
import { useLocation } from 'react-router-dom'; 

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import "components/responsive.scss";
import "../configuration.scss";
import "../../../components/responsive.scss";
import CountryStateDistrictSelector from "components/common/form/CountryStateDistrictSelector";

import { useTranslation } from "react-i18next";
import { string } from "prop-types";
import addDays from "date-fns/addDays";

export default function ApprovePriceForm({values,edit,status,handleSubmit,showSubmitButton,statusMessage}){

    const { t } = useTranslation();
    const formikForm = React.useRef(null);

    let breadcrumbs = [
        {
          label: t("notification_Form_Breadcrum_Dashboard_Label"),
          link: "/dashboard"
        },
        { label: t("notification_Form_Breadcrum_Configuration_Label") },
        { label: t("notification_Form_Breadcrum_Notification_Label") }
    ];

    let validations = {
      remarks: Yup.string().required(t("approve_form_remarks_mandatory_message")),
      status: Yup.string().required(t("approve_form_status_mandatory_message")),
    };

    const statusSelect = ["PENDING","APPROVED","REJECTED"];

    const fields = [
      {
        type: "customHeader",
        name: "Selling Price"
      },
      {
        type: "text",
        name: "beforeSellingPrice",
        label: t("approve_Price_Form_Before_Label"),
        placeholder: t("approve_Price_Form_Before_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "text",
        name: "sellingPrice",
        label: t("approve_Price_Form_After_Label"),
        placeholder: t("approve_Price_Form_After_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "customHeader",
        name: "MRP"
      },
      {
        type: "text",
        name: "beforeMrp",
        label: t("approve_Price_Form_Before_Label"),
        placeholder: t("approve_Price_Form_Before_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "text",
        name: "mrp",
        label: t("approve_Price_Form_After_Label"),
        placeholder: t("approve_Price_Form_After_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "customHeader",
        name: "Tax Percentage"
      },
      {
        type: "text",
        name: "beforeTaxPercentage",
        label: t("approve_Price_Form_Before_Label"),
        placeholder: t("approve_Price_Form_Before_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
    },
    {
        type: "text",
        name: "taxPercentage",
        label: t("approve_Price_Form_After_Label"),
        placeholder: t("approve_Price_Form_After_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
    },
    {
      type: "customHeader",
      name: "Discount In Percentage"
    },
    {
      type: "text",
      name: "beforeDiscountInPercentage",
      label: t("approve_Price_Form_Before_Label"),
      placeholder: t("approve_Price_Form_Before_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "discountInPercentage",
      label: t("approve_Price_Form_After_Label"),
      placeholder: t("approve_Price_Form_After_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "customHeader",
      name: "Is Active"
    },
    {
      type: "text",
      name: "isActive",
      label: t("approve_Price_Form_Before_Label"),
      placeholder: t("approve_Price_Form_Before_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "beforeIsActive",
      label: t("approve_Price_Form_After_Label"),
      placeholder: t("approve_Price_Form_After_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "customHeader",
      name: ""
    },
    {
      type: "text",
      name: "customerGroup",
      label: t("approve_Price_Form_Customer_Group_Label"),
      placeholder: t("approve_Price_Form_Customer_Group_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "appType",
      label: t("approve_Price_Form_AppType_Label"),
      placeholder: t("approve_Price_Form_AppType_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "cgstPercentage",
      label: t("approve_Price_Form_cgstPercentage_Label"),
      placeholder: t("approve_Price_Form_cgstPercentage_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    // {
    //   type: "text",
    //   name: "id",
    //   label: t("approve_Price_Form_id_Label"),
    //   placeholder: t("approve_Price_Form_id_Label"),
    //   disabled:true,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },
    {
      type: "text",
      name: "igstPercentage",
      label: t("approve_Price_Form_igstPercentage_Label"),
      placeholder: t("approve_Price_Form_igstPercentage_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "mappingTag",
      label: t("approve_Price_Form_MappingTag_Label"),
      placeholder: t("approve_Price_Form_MappingTag_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    // {
    //   type: "text",
    //   name: "productId",
    //   label: t("approve_Price_Form_productId_Label"),
    //   placeholder: t("approve_Price_Form_productId_Label"),
    //   disabled:true,
    //   grid: {
    //     xs: 12,
    //     sm: 3,
    //     className: "mb-2"
    //   }
    // },
    {
      type: "text",
      name: "productOmsReferenceId",
      label: t("approve_Price_Form_productOmsReferenceId_Label"),
      placeholder: t("approve_Price_Form_productOmsReferenceId_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "sgstPercentage",
      label: t("approve_Price_Form_sgstPercentage_Label"),
      placeholder: t("approve_Price_Form_sgstPercentage_Label"),
      disabled:true,
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    },
    {
      type: "text",
      name: "entityId",
      label: t("approve_Price_Form_entityId_Label"),
      placeholder: t("approve_Price_Form_entityId_Label"),
      disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "text",
        name: "id",
        label: t("approve_Price_Form_id_Label"),
        placeholder: t("approve_Price_Form_id_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "text",
        name: "omsReferenceId",
        label: t("approve_Price_Form_omsReferenceId_Label"),
        placeholder: t("approve_Price_Form_omsReferenceId_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "text",
        name: "origin",
        label: t("approve_Price_Form_origin_Label"),
        placeholder: t("approve_Price_Form_origin_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "text",
        name: "requestId",
        label: t("approve_Price_Form_requestId_Label"),
        placeholder: t("approve_Price_Form_requestId_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "text",
        name: "approvalUpdatedAt",
        label: t("approve_Price_Form_approvalUpdatedAt_Label"),
        placeholder: t("approve_Price_Form_approvalUpdatedAt_Label"),
        disabled:true,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "textarea",
        name: "remarks",
        label: t("approve_Price_Form_remarks_Label"),
        placeholder: t("approve_Price_Form_remarks_Label"),
        disabled:!showSubmitButton,
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      },
      {
        type: "select",
        name: "status",
        label: t("approve_Form_status_Label"),
        placeholder: t("approve_Form_status_Label"),
        disabled: !showSubmitButton,
        option:
        statusSelect.length > 0
            ? statusSelect.map(statusSelect => ({
                value: statusSelect,
                label: statusSelect
              }))
            : [],
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }
      }      
    ];
    
    const history = useHistory();
    const location = useLocation();
    const handleDialogClose = () => {
      history.push({pathname:"/configuration/approve" ,
        state: { 
          from: "2"
        }
      });
    };

    return (
      <Grid container>
        <Grid item xs={12}>
          <PageHeader
            title="Price"
            breadcrumbs={breadcrumbs}
          />
        </Grid>
        <Grid container>
          <Paper elevation={2} style={{ padding: "2em" }}>
            <Grid item>
              <CommonForm
                fields={fields}
                submitLabel={t("site_Form_Submit_Label")}
                submittingLabel={t("site_Form_Submitting_Label")}
                initialValues={values}
                validationSchema={Yup.object().shape({ ...validations })}
                validateOnBlur={true}
                edit={edit}
                enableReinitialize
                validateOnChange={true}
                onSubmit={(
                  values,
                  { setSubmitting, setFieldError, resetForm, errors }
                ) => {
                  if (!!errors) {
                    setSubmitting(false);
                  }
                  handleSubmit(values, resetForm, setSubmitting);
                }}
                showSubmitButton={showSubmitButton}
                formikRef={formikForm}
                buttonSize="3"
                buttonPosition="right"
              />
            </Grid>
          </Paper>
        </Grid>
        <SuccessFeedback
          open={status === "approvedSuccessfully"}
          onClose={handleDialogClose}
          successMessage={t("approve_Form_Success_Message")}
          createAnotherMesssage={t("approve_Form_Create_Another_Site")}
        />

        <SuccessFeedback
          open={status === "rejectedSuccessfully"}
          onClose={handleDialogClose}
          successMessage={t("approve_Form_Rejected_Message")}
          createAnotherMesssage={t("approve_Form_Create_Another_Site")}
        />

        <FailureFeedback
          open={status === "failed"}
          onClose={handleDialogClose}
          status={"Failed"}
          message={statusMessage}
          showButtons={true}
        />       
      </Grid>
    )
}

