import React, { useState ,useContext } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { FieldArray,Field , getIn} from "formik";
import { provisioningService } from "services/provisioningServices";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";
import { errorContext } from "context/errorContext";
import OrganizationChip from "./OrganizationChip";
import MultiSelectAll from "../elements/MultiSelectAll";
import CommonForm from "components/common/form/common-form";
import * as Yup from "yup";

export default function OrganizationSelection({
  name,
  metaProps = { margin: "dense", variant: "outlined", fullWidth: true },
  organizationData,
  setFieldValue,
  values
}) {
  
  const [orgValue, setOrgValue] = useState("");
  const [chillingCenterData,setChillingCenterData] = React.useState([]);
  const [vlccData,setVlccData] = React.useState([]);
  const [vlccValue,setVlccValue] = React.useState([]);

  const [chillingCenterValue,setChillingCenterValue] = useState("");

  const { dispatch: errorDispatch } = useContext(errorContext);

  const [orgError,setOrgError] = useState("");
  const [ccError,setCcError] = useState("");

  const { t } = useTranslation();

  function handleOrgChange(value){
    console.log(values);
    setChillingCenterData([]);
    let orgData = organizationData.find(organizationData => organizationData.orgName === value)
    console.log(orgData);
    provisioningService.getCCByOrg(orgData.orgId).then(data => {
      console.log("data",data);
      setChillingCenterData(data);    
    }).catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });  
  }

  async function handleOrganizationData() {
    setOrgError("");
    setCcError("");
    if(orgValue === ""){
      setOrgError("Please select Organization");
      return;
    }

    if(chillingCenterValue === ""){
      setCcError("Please select Chilling Center");
      return;
    }

    let selectedData = {};

    selectedData.organizationName = orgValue;
    selectedData.ccName = chillingCenterValue;

    let isDuplicate =false;
    for(var i=0;i<values.length;i++){
      if(values[i].organizationName === orgValue && values[i].ccName === chillingCenterValue){
        isDuplicate = true;
        break;
      }
    }
    if(!isDuplicate){
      values.push(selectedData);
    }

    console.log(values);

    setFieldValue(values);
    setChillingCenterData([]);
    setOrgValue("");
    setChillingCenterValue("");
    setVlccValue("");
    
  }

  const formikForm = React.useRef(null);

  const fields = [
    {
      type: "singleSelectSearch",
      name: "organizationName",
      label: t("Farmer_Association_Form_Organization_Selection_Label"),
      placeholder: t("Farmer_Association_Form_Organization_Selection_Label"),
      option:
        organizationData.length > 0
          ? organizationData.map(orgData => ({
              value: orgData.orgName,
              label: orgData.orgName
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      },
      customOnChange:handleOrgChange
    },
    {
      type: "multiSelectAll",
      name: "ccName",
      label: t("Farmer_Association_Form_chillingCenter_Label"),
      placeholder: t("Farmer_Association_Form_chillingCenter_Label"),
      option:
       chillingCenterData.length > 0
          ? chillingCenterData.map(ccData => ({
              value: ccData.chillingCenterName,
              label: ccData.chillingCenterName
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    }];

  let validations = {
    organizationName : Yup.string().required(
      t("Farmer_Association_Form_Validation_organization")
    ),
    ccName : Yup.string().required(
      t("Farmer_Association_Form_Validation_Chilling_Center")
    )
  };

  function handleSubmit(data, resetForm, setSubmitting) {
    let isDuplicate =false;
    for(var i=0;i<values.length;i++){
      if(values[i].organizationName === data.organizationName && JSON.stringify(values[i].ccName) === JSON.stringify(data.ccName)){
        isDuplicate = true;
        break;
      }
    }
    if(!isDuplicate){
      values.push(data);
    }

    setFieldValue(values);
    setChillingCenterData([]);
    resetForm();

    // setOrgValue("");
    // setChillingCenterValue("");
    // setVlccValue("");
  }

  let initialValues = {
    organizationName:"",
    ccName:[]
  } 

  return (   
  //   <Grid container item xs={12} className="mb-0 d-flex flex-direction-column device-wrapper">
  //     <Grid item container xs={12}  spacing={2} alignContent="flex-start" className="site-inner">
    <Grid container>
      <Grid item xs={12}>
        <CommonForm
          fields={fields}
          submitLabel={t("Farmer_Association_Form_Submit_Label")}
          submittingLabel={t("site_Form_Submitting_Label")}
          initialValues={initialValues}
          validationSchema={Yup.object().shape({ ...validations })}
          validateOnBlur={true}
          enableReinitialize
          validateOnChange={true}
          showSubmitButton={false}
          showOrgSubmitButton={true}
          onSubmit={(
            values,
              { setSubmitting, setFieldError, resetForm, errors }
            ) => {
              if (!!errors) {
                setSubmitting(false);
              }
              handleSubmit(values, resetForm, setSubmitting);
            }}
          formikRef={formikForm}
          buttonSize="1"
          buttonPosition="left"
        />
        
        {/* <Grid item xs={11} md={3}>
          <Field 
            select
            name={name}
            label={t("Farmer_Association_Form_Organization_Selection_Label")}
            component={TextField}
            margin="dense"
            fullWidth={true}
            variant="outlined"
            value={orgValue}
            inputProps={{
            onChange: e => {
              setOrgValue(e.target.value);
              handleOrgChange(
                e.target.value,
                setFieldValue,
                values
              );
             }
            }}
          >
          <MenuItem disabled value="none">
            {"Choose"}
          </MenuItem>
          {organizationData.map(value => (
            <MenuItem key={value.orgName} value={value.orgName}>
              {value.orgName}
            </MenuItem>
          ))}
        </Field>
        {orgError && (
          <div style={{ color: "#f44336" }}>{orgError}</div>
        )}
        </Grid> */}

        {/* <Grid item xs={11} md={3}>
          <MultiSelectAll
            value={chillingCenterValue}
            name={chillingCenterValue}
            field={chillingCenterValue}
           // options = {chillingCenterData}
            // options= {ccData}
            options ={ chillingCenterData.length > 0
              ? chillingCenterData.map(chillingCenterData => ({
                value: chillingCenterData.organizationName,
                label: chillingCenterData.organizationName
              }))
            : []}
            label={t("Farmer_Association_Form_chillingCenter_Label")}
            handleChange={setFieldValue}
            // onChange={customOnChange}
            {...metaProps}

          />



        <Field 
          select
          name={name}
         
          label={t("Farmer_Association_Form_chillingCenter_Label")}
          component={TextField}
          margin="dense"
          fullWidth={true}
          variant="outlined"
          value={chillingCenterValue}
          inputProps={{
            onChange: e => {
              setChillingCenterValue(e.target.value);
              handleChillingCenterChange(
                e.target.value,
                values
              );
            }
          }}
        >
        <MenuItem disabled value="none">
          {"Choose"}
        </MenuItem>
        {chillingCenterData.map(value => (
          <MenuItem key={value.chillingCenterName} value={value.chillingCenterName}>
            {value.chillingCenterName} 
          </MenuItem>
        ))}
        </Field>
        {ccError && (
          <div style={{ color: "#f44336" }}>{ccError}</div>
        )}
        </Grid> */}

        {/* <Grid item xs={11} md={3}>
        <Field 
           select
           name={name}
           label={t("Farmer_Association_Form_Vlcc_Label")}
           component={TextField}
           margin="dense"
           fullWidth={true}
           value={vlccValue}
           variant="outlined"
           inputProps={{
             onChange: e => {
                setVlccValue(e.target.value);
             }
           }}
        >
        <MenuItem disabled value="none">
            {"Choose"}
        </MenuItem>
        {vlccData.map(value => (
            <MenuItem key={value.vlccId} value={value.vlccId}>
                {value.vlccName +" "+ value.vlccId}
            </MenuItem>
        ))}
      </Field>
        </Grid> */}

         
       </Grid>
       <Grid item xs={11} md={3}>
        {/* <Grid item container alignItems="center" xs={2} md={1}>
          <button
            type="button"
            onClick={() => handleOrganizationData()}
            className="add-icon"
          >
            <i className="fa fa-plus-circle" aria-hidden="true"></i>
          </button>
        </Grid> */}
        <Grid item style={{ marginTop: "8px", marginLeft: "-10px" }}>
          <OrganizationChip
            values={values}
            setFieldValue={setFieldValue}
          />
        </Grid>
        </Grid>
      </Grid>
    );
}
