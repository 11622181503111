import { Map } from "immutable";
const initialState = new Map({
  status: "idle",
  subOrganizations: [],
  orgDetails: null,
  error: null,
  editOrgValues: {},
  noorg: false,
  loadingOrgDetails: false
});

export default function organization(state = initialState, action) {
  switch (action.type) {
    case "RESET_SUB_ORG_HEIRARCHY":
      return state.set("subOrganizations", []);
    case "SET_SUB_ORG_HEIRARCHY":
      return state.set("subOrganizations", action.payload);
    case "ORGANIZATION_CONFIGURATION_CREATE_ORGANIZATION_SUCCESS":
      return state.set("orgDetails", action.result).set("status", "success");
    case "ORGANIZATION_CONFIGURATION_EDIT_ORGANIZATION_SUCCESS":
      return state.set("orgDetails", action.result).set("status", "success");
    case "ORGANIZATION_CONFIGURATION_CREATE_ORGANIZATION_FAIL":
      return state.set("error", action.error).set("status", "failed");
    case "ORGANIZATION_CONFIGURATION_RESET_STATUS":
      return state.set("status", "idle");
    case "GET_ORG_DETAILS_BY_ID":
      return state
        .set("editOrgValues", {})
        .set("noorg", false)
        .set("loadingOrgDetails", true);
    case "GET_ORG_DETAILS_BY_ID_SUCCESS":
      return state
        .set("editOrgValues", action.org)
        .set("noorg", false)
        .set("loadingOrgDetails", false);
    case "GET_ORG_DETAILS_BY_ID_FAIL":
      return state
        .set("editOrgValues", {})
        .set("noorg", true)
        .set("loadingOrgDetails", false);
    case "GET_ORG_DETAILS_BY_NAME":
      return state
        .set("loadingOrgDetails", true)
        .set("orgDetails", null)
        .set("noorg", false);
    case "GET_ORG_DETAILS_BY_NAME_SUCCESS":
      return state
        .set("orgDetails", action.org)
        .set("loadingOrgDetails", false)
        .set("noorg", false);
    case "GET_ORG_DETAILS_BY_NAME_FAILED":
      return state.set("loadingOrgDetails", false).set("noorg", true);
    case "ORG_DETAILS_RESET":
      return state
        .set("orgDetails", null)
        .set("loadingOrgDetails", false)
        .set("noorg", false);
    default:
      return state;
  }
}
