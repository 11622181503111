import React from "react";

import ExcelIcon from "components/common/elements/ExcelIcon";
import PdfIcon from "components/common/elements/PdfIcon";
import Searchbar from "components/common/elements/searchBar";
import PopupMenu from "./menuPopup";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { CSVLink } from "react-csv";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles(theme => ({
    iconButtonRoot: {
        padding: "0em"
    }
}));
export default function TemplateForTables({
    importIcons,
    needDropdown,
    dropdownitems,
    children,
    globalFilter,
    setGlobalFilter,
    data,
    fileName,
    searchBar = true,
    ...gridProps
}) {
    const classes = useStyles();
    return (
        <Grid container item style={{ width: "100%" }} {...gridProps}>
            <Paper elevation={2} style={{ width: "100%", padding: "1.5em" }}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={6} md={4}>
                    {searchBar ?
                        <Searchbar onChange={setGlobalFilter} value={globalFilter} />
                        : null }
                    </Grid> 
                    <Grid
                        container
                        item
                        xs={6}
                        md={6}
                        justify="flex-end"
                        style={{ flexWrap: "nowrap" }}
                    >
                        {!!importIcons && (
                            <>
                                {data ?
                                <IconButton classes={{ root: classes.iconButtonRoot }}>
                                    {/* csv download */}
                                    {data.length > 0 && (
                                        <CSVLink data={data} filename={fileName}>
                                            <ExcelIcon />
                                        </CSVLink>
                                    )}
                                </IconButton> : <CircularProgress/> }
                                {/* <IconButton classes={{ root: classes.iconButtonRoot }}>
                                    <PdfIcon />
                                </IconButton> */}
                            </>
                        )}
                        {!!needDropdown && (
                            <PopupMenu droplist={dropdownitems} onclick={null} />
                        )}
                    </Grid>
                </Grid>
                <Grid>{children}</Grid>
            </Paper>
        </Grid>
    );
}
