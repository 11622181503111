import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import SpinningLoader from "../../../components/common/elements/SpinningLoader";
const useStyles = makeStyles(theme => ({
    appBar: {
        position: "relative"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    }
}));

const Transition = props => {
    const ref = React.createRef();
    return <Slide direction="down" ref={ref} {...props} />;
};

export default function ReportPopup({ src, hideIt, showPopup, name }) {
    //console.log("From Reports Pop Up:- ", src);
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const handleClose = () => {
        hideIt();
    };
    const showLoader = () => {
        setLoading(false);
    };
    // console.log(loading);
    return (
        <div>
            <Dialog
                fullScreen
                open={showPopup}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Reports - {name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                {loading && <SpinningLoader />}
                <iframe
                    width="100%"
                    height="600"
                    scrolling="yes"
                    onLoad={showLoader}
                    frameBorder="no"
                    src={src}
                    title="reports"
                ></iframe>
            </Dialog>
        </div>
    );
}
