import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { useSelector } from "react-redux";
import { CustomizedPrimaryButton } from "../../common/elements/CustomizedPrimaryButton";
import { metadataService } from "services/metadataServices";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { categoryService } from "services/categoryServices";
import orgIcon from "../../../assets/icon_org.svg";
import * as Yup from "yup";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from "@material-ui/core/styles";
import CommonForm from "components/common/form/common-form";
import { TextField } from "formik-material-ui";
import { Formik, Field, getIn } from "formik";
import MenuItem from "@material-ui/core/MenuItem";
import {Radio,RadioGroup} from "@material-ui/core";
import CustomDatePicker from "../elements/DatePicker";
import "./CustomCalender.scss";

export default function CategoryTreePopUp({name,setFieldValue,values,isOpen,    
    handleChange
  }) {

  console.log("isOpen",isOpen);

  console.log("values",values);

  const minDate =  new Date(); 
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [displayEndOnDate, setDisplayEndOnDate] = React.useState(false);
  const [displayAfterOccurence, setDisplayAfterOccurence] = React.useState(false);
  const [displayRepeatOn,setDisplayRepeatOn] = React.useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const formikForm = React.useRef(null);
  const repeatEveryUnit = [
    { value:"DAY", label:"Day" },
    { value:"WEEK", label:"Week" }];
    // { value:"MONTH", label:"Month" },
    // { value:"YEAR", label:"Year" }

  const repeatOnDay = ["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"];
  // const endsOption = ["Never","On","After"];

  const endsOption = [
    { value:"Never", label:"Never"},
    { value:"On",label:"On"},
    { value:"After",label:"After"}];

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen === true]);

  let validations = {
  };

  function handleSubmit(values, resetForm, setSubmitting){   

  }

  function handleClose(){
    setOpen(false);
    isOpen = false;
  }

  function handleSaveClick(){
    console.log("save click");
    setFieldValue(values);
  }

  const changeEndOn = (e) =>{
    values.params.end.never = false;
    values.params.end.afterOccurrences = "";
    values.params.end.on = new Date(); 
    setFieldValue(values);
    if(e.target.value === "Never"){
      values.params.end.never = true;
      values.isEndOnDate = false;
      setDisplayEndOnDate(false);
      setDisplayAfterOccurence(false);
    }else if(e.target.value === "On"){          
      values.isEndOnDate = true;
      setDisplayEndOnDate(true);
      setDisplayAfterOccurence(false);
    }else if(e.target.value === "After"){
      values.isEndOnDate = false;
      setDisplayEndOnDate(false);
      setDisplayAfterOccurence(true);
    }
    setFieldValue(values);
  }

  const styles = {
    dialogPaper: {
      minHeight: '40vh',
      maxHeight: '40vh',
    },
  };

  function handleConfigOpen() {
    setOpen(true);
  }

  function handleSaveClick() {
    handleClose();
   // handleSave(index, values);
  }

  function handleClose() {
    setOpen(false);
  }

  const [styleSUN, setStyleSUN] =  React.useState("radio");
  const [styleMON, setStyleMON] =  React.useState("radio");
  const [styleTUE, setStyleTUE] =  React.useState("radio");
  const [styleWED, setStyleWED] =  React.useState("radio");
  const [styleTHU, setStyleTHU] =  React.useState("radio");
  const [styleFRI, setStyleFRI] =  React.useState("radio");
  const [styleSAT, setStyleSAT] =  React.useState("radio");

  function customOnChange(value){
    values.params.repeatsEvery = value;
    setFieldValue(values);
  }

  function afterOccurenceChange(value){
    values.afterOccurrences = value;
    setFieldValue(values);
  }

  function handleRepeatUnitChange(value){
    values.params.repeatsEveryUnit = value;
    setDisplayRepeatOn(false);
    if(value === "WEEK"){
      setDisplayRepeatOn(true);
    }
    setFieldValue(values);
  }

  function selectDay(selectedDay){
    switch(selectedDay){
      case "SUN":
        if(styleSUN === "radio"){
          setStyleSUN("radio1");
        }else if(styleSUN === "radio1"){
          setStyleSUN("radio");
        }
        values.params.repeatOn.SUN = !values.params.repeatOn.SUN;
        break;
      case "MON":
        if(styleMON === "radio"){
          setStyleMON("radio1");
        }else if(styleMON === "radio1"){
          setStyleMON("radio");
        }
        values.params.repeatOn.MON = !values.params.repeatOn.MON;
      break;
      case "TUE":
        if(styleTUE === "radio"){
          setStyleTUE("radio1");
        }else if(styleTUE === "radio1"){
          setStyleTUE("radio");
        }
        values.params.repeatOn.TUE = !values.params.repeatOn.TUE;
      break;
      case "WED":
        if(styleWED === "radio"){
          setStyleWED("radio1");
        }else if(styleWED === "radio1"){
          setStyleWED("radio");
        }
        values.params.repeatOn.WED = !values.params.repeatOn.WED;
      break;
      case "THU":
        if(styleTHU === "radio"){
          setStyleTHU("radio1");
        }else if(styleTHU === "radio1"){
          setStyleTHU("radio");
        }
        values.params.repeatOn.THU = !values.params.repeatOn.THU;
      break;
      case "FRI":
        if(styleFRI === "radio"){
          setStyleFRI("radio1");
        }else if(styleFRI === "radio1"){
          setStyleFRI("radio");
        }
        values.params.repeatOn.FRI = !values.params.repeatOn.FRI;
      break;
      case "SAT":
        if(styleSAT === "radio"){
          setStyleSAT("radio1");
        }else if(styleSAT === "radio1"){
          setStyleSAT("radio");
        }
        values.params.repeatOn.SAT = !values.params.repeatOn.SAT;
      break;
    }
  }

  function setFieldTouched(){
    console.log("setFieldTouched");
  }

  function handleDateChange(e){
    // schedulerSelectedData.scheduleDate = 
     console.log(e);
   }
  
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        className={"user-checklist"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <h2>{t("custom_calender_pop_up")}</h2>
      </DialogTitle>
      <DialogContent dividers
        style={{height:'80vh'}}>
          <Grid item container spacing={3}>
            <Grid item xs={12} md={4}>
              <header style={{ color:"black"}}>Repeat Every</header>
            </Grid>
            <Grid  item xs={12} md={2}>
              <Field
                type={"number"}
                name={"repeatsEvery"}
                autoComplete={false}
                component={TextField}
                placeholder={""}
                InputLabelProps={{ shrink: true }}
                inputProps={
                  !!customOnChange
                    ? {
                      onChange: async e => {
                        //setFieldTouched(name, true, false);
                        // setFieldValue(name, e.target.value);
                        await customOnChange(e.target.value);
                      },
                      value: getIn(values, "params.repeatsEvery"),
                      shrink: true
                    }
                    : {}
                }
              />
            </Grid>
            <Grid  item xs={12} md={3}>
            <Field
              name={"repeatsEveryUnit"}
              component={TextField}
              select
              placeholder={""}
              inputProps={
                {
                  onChange: async e => {
                    // setFieldTouched(field.name, true, false);
                    // setFieldValue(field.name, e.target.value);
                    handleRepeatUnitChange(e.target.value);
                  },
                  value: getIn(values, "params.repeatsEveryUnit"),
                  shrink: true
                }
              }
            >
              <MenuItem disabled value="none">
                {"repeatUnit"}
              </MenuItem>
              {repeatEveryUnit.map(unit => (
                <MenuItem key={unit.value} value={unit.value}>
                  {unit.label}
                </MenuItem>
              ))}
            </Field>
          </Grid>
        </Grid>
        {displayRepeatOn ? (
          <><Grid item container spacing={3}>
              <Grid item xs={12} md={4}>
                <header style={{ color: "black" }}>Repeat On</header>
              </Grid>
            </Grid><Grid item container spacing={3}>
                <Grid item xs={12} md={12}>
                  <div class="radio-group">
                    <span class={styleSUN} data-value="1" onClick={() => selectDay("SUN")}>S</span>
                    <span class={styleMON} data-value="2" onClick={() => selectDay("MON")}>M</span>
                    <span class={styleTUE} data-value="3" onClick={() => selectDay("TUE")}>T</span>
                    <span class={styleWED} data-value="4" onClick={() => selectDay("WED")}>W</span>
                    <span class={styleTHU} data-value="5" onClick={() => selectDay("THU")}>T</span>
                    <span class={styleFRI} data-value="6" onClick={() => selectDay("FRI")}>F</span>
                    <span class={styleSAT} data-value="7" onClick={() => selectDay("SAT")}>S</span>
                    <br />
                  </div>
                </Grid>
              </Grid></>
        ):null} 
        
        <Grid item container spacing={3}>
          <Grid item xs={12} md={3}>
            <header style={{ color:"black"}}>Ends</header>
          </Grid>
        </Grid>

        <Grid item container spacing={4} >            
          <Grid item xs={12} md={3}>
            <RadioGroup onChange={changeEndOn}  defaultValue="Never">
              {endsOption.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label} />
              ))}
            </RadioGroup>
          </Grid>

          <Grid item xs={12} md={5} class='spaceAtTopForDate'>
            <CustomDatePicker
              formikRef={formikForm}
              name={"endOnDate"}
              label={"On Date"}
              value={getIn(values, "params.end.on")}
              handleDateChange={date => setFieldValue("scheduler.params.end.on", date)}
              setFieldTouched={setFieldTouched}
              minDate={minDate}
              readOnly={true}
              editable={false}
              disabled={!displayEndOnDate}
            />
          </Grid>
            
          <Grid  item xs={12} md={4} >
          </Grid>

          <Grid  item xs={12} md={3} class='spaceAtTopForOccurence'>
            <Field
              type={"number"}
              name={"afterOccurence"}
              disabled={!displayAfterOccurence}
              // label={"repeatCount"}
              autoComplete={false}
              component={TextField}
              placeholder={""}
              InputLabelProps={{ shrink: true }}
              inputProps={
                !!afterOccurenceChange
                  ? {
                      onChange: async e => {
                      //setFieldTouched(name, true, false);
                      // setFieldValue(name, e.target.value);
                      await afterOccurenceChange(e.target.value);
                    },
                    // value: getIn(values, field.name),
                    shrink: true
                  }
                  : {}
              }
            />
          </Grid>
        </Grid>       
         
      </DialogContent>
      <DialogActions>
        {/* <CustomizedPrimaryButton
          onClick={handleClose}
          variant="contained"
          color="primary"
          label={t("Custom_Calender_PopUp_Close_Label")}
          width="3"
          position="middle"
        ></CustomizedPrimaryButton> */}
         
        <CustomizedPrimaryButton
          onClick={handleSaveClick}
          variant="contained"
          color="primary"
          label={t("Custom_Calender_PopUp_Save_Label")}
          width="3"
          position="right"
        ></CustomizedPrimaryButton>
      </DialogActions>
    </Dialog>

    </>
  );
}
