import authentication from "./authReducer";
import { combineReducers } from "redux";
import site from "./siteConfigurationReducer";
import organization from "./organizationReducer";
import userConfiguration from "./userConfigurationReducer";
import chillingGraph from "./chillingGraphReducer";
import error from "./errorReducer";

const appReducer = combineReducers({
  authentication,
  chillingGraph,
  site,
  organization,
  userConfiguration,
  error
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_APP") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
