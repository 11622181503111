import React from "react";
import {
    useTable,
    useSortBy,
    useGlobalFilter,
    usePagination
} from "react-table";
import "../../Table.scss";
import ToolTipCell from "./ToolTipCell";
import TemplateForTables from "../../layout/innerPagesTemplate2";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Select, MenuItem, Button } from "@material-ui/core";
import { utilityServices } from "../../../services/utilityServices";
function Table({
    columns,
    data,
    needDropdown,
    dropItems,
    extraHeaders = false,
    fileName = "file.xls"
}) {
    const {
        getTableProps,
        headerGroups,
        prepareRow,
        page,
        setGlobalFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter }
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 }
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    const { t } = useTranslation();
    let csvData = 0;
    if (data.length) {
        csvData = utilityServices.getHeaders(columns, data, extraHeaders);
    }
    return (
        <>
            <TemplateForTables
                dropdownitems={dropItems}
                needDropdown={needDropdown}
                importIcons
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                data={csvData}
                fileName={fileName}
            >
                <div style={{ overflow: "auto" }}>
                    <table {...getTableProps()} className="data-table">
                        <thead>
                            {(headerGroups || []).map((headerGroup, hGroupIndex, idx) => (
                                <tr
                                    {...headerGroup.getHeaderGroupProps()}
                                    key={hGroupIndex + idx.toString()}
                                >
                                    {headerGroup.headers.map((column, idx) => {
                                        if (column.Header === "Actions") {
                                            return (
                                                <th
                                                    {...column.getHeaderProps()}
                                                    key={column.header + idx.toString()}
                                                    colSpan={2}
                                                >
                                                    {column.render("Header")}
                                                </th>
                                            );
                                        }
                                        return (
                                            <th
                                                {...column.getHeaderProps(
                                                    column.getSortByToggleProps()
                                                )}
                                                className="alert-header"
                                                key={column.header + idx.toString()}
                                            >
                                                {column.render("Header")}
                                            </th>
                                        );
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {page.map(
                                (row, i) =>
                                    prepareRow(row) || (
                                        <tr {...row.getRowProps()} key={i}>
                                            {row.cells.map((cell, j) => {
                                                if (cell.column.Header === "Actions") {
                                                    return cell.render("Cell");
                                                }
                                                return (
                                                    <td {...cell.getCellProps()} key={i + j + 1}>
                                                        {cell.render("Cell")}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    )
                            )}
                        </tbody>
                    </table>
                </div>
                {data.length > 0 && (
                    <Grid
                        container
                        justify="space-between"
                        className="pagination data-table-pagination"
                        style={{ padding: "1em 0em 1em 0em" }}
                    >
                        <Grid
                            item
                            container
                            xs={12}
                            md={6}
                            justify="flex-start"
                            alignItems="center"
                            className="page-btm-data"
                        >
                            <span>
                                {t("pagination_Page")} &nbsp;
                <strong>{pageIndex + 1} &nbsp;</strong>
                {t("pagination_Of")} &nbsp;{pageOptions.length}
                            </span>
              &nbsp;
              <Select
                                variant="outlined"
                                margin="dense"
                                value={pageSize}
                                onChange={e => {
                                    setPageSize(Number(e.target.value));
                                }}
                            >
                                {[10, 20, 30, 40, 50, 100].map(pageSize => (
                                    <MenuItem key={pageSize} value={pageSize}>
                                        {t("pagination_Show")} {pageSize}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                                color="primary"
                            >
                                {t("pagination_Previous")}
                            </Button>
                            <Button
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                                color="primary"
                            >
                                {t("pagination_Next")}
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </TemplateForTables>
        </>
    );
}

export default Table;
