import React, { useEffect, useContext, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import "../../../components/Table.scss";
import { provisioningService } from "../../../services/provisioningServices.js";
import Table from "components/common/table/DynamicPaginationTable2";
import PageHeader from "components/layout/PageHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import { errorContext } from "context/errorContext";

import { UseTranslation, useTranslation } from "react-i18next";
import * as Yup from "yup";
import CommonForm from "components/common/form/common-form";
import Paper from "@material-ui/core/Paper";

const CODList = props => {

  const { t } = useTranslation();
  const formikForm = React.useRef(null);

  const [status, setStatus] = React.useState("loading");
  const [pageInfo, setPageInfo] = React.useState({});
  const [CODList, setCODList] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(10);
  const [pageIndex, setPageIndex] = React.useState(0);

  const { dispatch: errorDispatch } = useContext(errorContext);

  const CODStatusList = ["success","failed"];

  // let values = {
  //   CODStatusList: ''
  // };

  let values = {
    CODStatusList: CODStatusList[0]
  };

  let validations = {
    CODStatusList: Yup.string().required(
        t("COD_List_Status_Label")
    )
  };

  const fields = [
    {
      type: "select",
      name: "CODStatusList",
      label: t("COD_List_Status_Label"),
      placeholder: t("COD_List_Status_Label"),
      option:
      CODStatusList.length > 0
          ? CODStatusList.map(CODStatusList => ({
              value: CODStatusList,
              label: CODStatusList
            }))
          : [],
      grid: {
        xs: 12,
        sm: 2,
        md: 3,
        className: ""
      }
    }
  ]; 


  function handleSubmit(values, resetForm, setSubmitting) {
    // if(values.CODStatusList === "all"){
    //   values.CODStatusList = '';
    // }
    localStorage.setItem("CODStatus", values.CODStatusList);
    //console.log(values.CODStatusList);
    provisioningService
              .fetchCODListBasedOnStatus()
              .then(data => {
                setCODList(data || []);
                setPageInfo(data.pageInfo);
                setStatus("success");
              })
              .catch(error => {
                errorDispatch({
                  type: "ERROR",
                  error
                });
              });

    setSubmitting(false);
  }

  const columns = React.useMemo(
    () => [
      // {
      //   Header: t("COD_List_Sr_No"),
      //   accessor: "srNo",
      // },
      {
        Header: t("COD_List_SalesOrder"),
        accessor: "salesOrder",
      },
      {
        Header: t("COD_List_SalesInvoiceId"),
        accessor: "salesInvoiceId"
      },
      {
        Header: t("COD_List_TransactionsId"),
        accessor: "transactionsId"
      },
      {
        Header: t("COD_List_ModeOfPayment"),
        accessor: "modeOfPayment"
      },
      {
        Header: t("COD_List_CollectionDate"),
        accessor: "collectionDate"
      },
      {
        Header: t("COD_List_DepositedDate"),
        accessor: "depositedDate"
      },
      {
        Header: t("COD_List_DepositedBy"),
        accessor: "depositedBy"
      },
      {
        Header: t("COD_List_AccountNumber"),
        accessor: "accountNumber"
      },
      {
        Header: t("COD_List_EmpId"),
        accessor: "empId"
      },
      {
        Header: t("COD_List_AmountPaid"),
        accessor: "amountPaid"
      },
      {
        Header: t("COD_List_Remarks"),
        accessor: "remarks"
      },
      {
        Header: t("COD_List_Error"),
        accessor: "error"
      },
      {
        Header: t("COD_List_Status"),
        accessor: "status"
      },
      {
        Header: t("COD_List_FileName"),
        accessor: "fileName"
      }
    ],
    []
  );


  useEffect(() => {
    
    setStatus("loading");
    
    provisioningService
        .fetchCODToken('1313131313')
        .then((res) => {
            const bearerTokenFromAppUserService = localStorage.getItem("bearerTokenCOD");
        })
        .catch(error => {
            errorDispatch({
            type: "ERROR",
            error
            });
        });

    //localStorage.setItem("CODStatus", '');
    localStorage.setItem("CODStatus", CODStatusList[0]);
    provisioningService
        .fetchCODListBasedOnStatus()
        .then(data => {
          setCODList(data || []);
          setPageInfo(data.pageInfo);
          setStatus("success");
        })
        .catch(error => {
          errorDispatch({
            type: "ERROR",
            error
          });
        });
    
  }, [ ]);

  const data = React.useMemo(() => [...CODList]);

  const nextPage = () => {
    if (
      status !== "loading" &&
      pageInfo.hasNextPage &&
      pageIndex < pageInfo.totalPages
    ) {
      let index = pageIndex;
      setPageIndex(++index);
    }
  };
  const previousPage = () => {
    let index = pageIndex;
    if (index > 0) {
      setPageIndex(--index);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("COD_List_Header_Label")}
          breadcrumbs={[
            { label: t("COD_List_Breadcrumb_Dashboard_Label"), link: "/dashboard" },
            { label: t("COD_List_Breadcrumb_Config_Label") },
            { label: t("COD_List_Breadcrumb_Retailer_Label") }
          ]}
        />
      </Grid>

      <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%"
        }}
      >

      {
      // orgListStatus === "loading" ? (
      //     <Grid
      //       container
      //       justify="center"
      //       alignItems="center"
      //       style={{ height: "50vh" }}
      //     >
      //       <CircularProgress />
      //     </Grid>
      //   ):(
      <CommonForm
        fields={fields}
        submitLabel={t("loanInsurance_Form_Submit_Label")}
        submittingLabel={t("loanInsurance_Form_Submitting_Label")}
        initialValues={values}
        validationSchema={Yup.object().shape({ ...validations })}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={(
          values,
          { setSubmitting, setFieldError, resetForm, errors }
        ) => {
          if (!!errors) {
            setSubmitting(false);
          }
          handleSubmit(values, resetForm, setSubmitting);
        }}
        formikRef={formikForm}
        buttonSize="2"
        buttonPosition="right"
        inlineButton={true}
      />
      // )
      }


      {status === "loading" ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: "50vh" }}
        >
          <CircularProgress />
        </Grid>
      ) : (
          <Table
            columns={columns}
            data={data}
            //needDropdown={true}
            // dropItems={dropitems}
            fileName={"CODList.xls"}
          />
        )}

      </Paper>
    </Grid>
  );
};

export default CODList;
