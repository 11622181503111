import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";

import CommonForm from "../../../components/common/form/common-form";
import { metadataService } from "../../../services/metadataServices";
import PageHeader from "../../../components/layout/PageHeader";
import TaskFields from "components/common/form/TaskFields";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { errorContext } from "context/errorContext";
import { useTranslation } from "react-i18next";

// This constructor is called from createChecklistTemplate.js and
// editChecklistTemplate.js to send the values to be displayed on UI
export default function PreventiveTemplateForm({ values, handleSubmit }) {
  const org = JSON.parse(localStorage.getItem("currentUser")).organizationName;

  const [TaskValuesTypes, setTaskValueTypes] = useState([]);

  const formikForm = React.useRef(null);

  const { t } = useTranslation();

  const { dispatch: errorDispatch } = useContext(errorContext);

  // DidMount - onloading of form to initialize the values required to render the form
  useEffect(() => {
    metadataService
      .getCheckListValuesTypes()
      .then(data => {
        let types = data.map(type => type.typeName);
        setTaskValueTypes(types);
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
  }, []);

  //To diplay the common exceptions at app level
  const fields = [
    {
      type: "completedPercentage",
      formikRef: formikForm,
      title: t("checkList_Form_Percetage_Label")
    },
    {
      type: "text",
      name: "orgName",
      label: t("checkList_Form_Org_Name"),
      placeholder: t("checkList_Form_Org_Name"),
      disabled: "true",
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "checklistName",
      label: t("checkList_Form_Checklist_Name"),
      placeholder: t("checkList_Form_Checklist_Name"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "checklistDescription",
      label: t("checkList_Form_Checklist_Description"),
      placeholder: t("checkList_Form_Checklist_Description"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "custom",
      component: ({ values, errors }, renderFields) => (
        <TaskFields
          name="taskList"
          TaskValuesTypes={TaskValuesTypes}
          values={values}
          errors={errors}
          renderFields={renderFields}
        />
      )
    }
  ];

  Yup.addMethod(Yup.string, "uniqueCost", function(message) {
    return this.test("uniqueCost", message, function(value) {
      const repeat = this.parent.filter(item => item === value);
      if (!value) {
        return true;
      } else if (repeat.length > 1) {
        throw this.createError({
          path: `${this.path}`
        });
      }
      return true;
    });
  });

  Yup.addMethod(Yup.object, "uniqueSubTask", function(message) {
    return this.test("uniqueSubTask", message, function(value) {
      const subTaskNames = this.parent.filter(item => {
        return item.subTaskName === value.subTaskName;
      });
      if (subTaskNames.length > 1) {
        throw this.createError({
          path: `${this.path}.subTaskName`,
          message: t("checkList_Form_Validation_Message_Sub_Task_Name")
        });
      }
      return true;
    });
  });

  Yup.addMethod(Yup.object, "uniqueSelectName", function(message) {
    return this.test("uniqueSelectName", message, function(value) {
      if (
        this.parent.filter(item => {
          return item.name === value.name;
        }).length > 1
      ) {
        throw this.createError({
          path: `${this.path}.name`,
          message: t("checkList_Form_Validation_Message_Name")
        });
      }
      return true;
    });
  });

  Yup.addMethod(Yup.object, "uniqueSelectValue", function(message) {
    return this.test("uniqueSelectValue", message, function(value) {
      if (
        this.parent.filter(item => {
          return item.value === value.value;
        }).length > 1
      ) {
        throw this.createError({
          path: `${this.path}.value`,
          message: t("checkList_Form_Validation_Message_Value")
        });
      }
      return true;
    });
  });

  let validations = {
    orgName: Yup.string(),
    checklistName: Yup.string().required(t("checkList_Form_Validation_Name")),
    checklistDescription: Yup.string().required(t("checkList_Form_Validation_Description")),
    taskList: Yup.array().of(
      Yup.object().shape({
        taskName: Yup.string().required(t("checkList_Form_Validation_Task_Name")),
        subTasks: Yup.array().of(
          Yup.object()
            .shape({
              taskType: Yup.string().required(t("checkList_Form_Validation_Sub_Task_Type")),
              subTaskName: Yup.string().required(t("checkList_Form_Validation_Sub_Task_Name")),
              subTaskDescription: Yup.string().required(
                t("checkList_Form_Validation_Sub_Task_Description")
              ),
              subTaskValue: Yup.object().when("taskType", {
                is: value => value === "SELECT",
                then: Yup.object()
                  .shape({
                    list: Yup.array().of(
                      Yup.object()
                        .shape({
                          name: Yup.string().required(t("checkList_Form_Validation_Select_Name")),
                          value: Yup.string().required(t("checkList_Form_Validation_Select_Value"))
                        })
                        .uniqueSelectName(t("checkList_Form_Validation_Select_Unique_Name"))
                        .uniqueSelectValue(t("checkList_Form_Validation_Select_Unique_Value"))
                    )
                  })
                  .required(), // select validation,
                otherwise: Yup.object().when("taskType", {
                  is: value => value === "COST",
                  then: Yup.object().shape({
                    currency: Yup.array().of(
                      Yup.string()
                        .required("Required")
                        .uniqueCost(t("checkList_Form_Validation_Unique_Cost"))
                    )
                  }),
                  otherwise: Yup.object().when("taskType", {
                    is: value => value === "RANGE",
                    then: Yup.object()
                      .shape({
                        maxValue: Yup.number()
                          .required(t("checkList_Form_Validation_Range_Max_Value"))
                          .typeError(t("checkList_Form_Validation_Range_Max_Value_Type_Error")),
                        minValue: Yup.number(t("checkList_Form_Validation__Range_Min_Value"))
                          .required(t("checkList_Form_Validation__Range_Min_Value_Required"))
                          .typeError(t("checkList_Form_Validation__Range_Min_Value_Type_Error")),
                        type: Yup.string().required("Type is required")
                      })
                      .test("rangeTest", "the range is incorrect", function(
                        value
                      ) {
                        // checks if min value is >= max value
                        if (!value) {
                          return true;
                        } else if (value.minValue >= value.maxValue) {
                          throw this.createError({
                            path: `${this.path}.minValue`,
                            message: "Min value must be less than max value"
                          });
                        }
                        return true;
                      }),
                    otherwise: Yup.object()
                  })
                }) // other validation
              })
            })
            .uniqueSubTask("The sub tasks can't have same values")
        )
      })
    )
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("checkList_Form_Header_Label")}
          breadcrumbs={[
            { label: t("checkList_Form_Breadcrum_Dashboard_Label"), link: "/dashboard" },
            {
              label: t("checkList_Form_Breadcrum_Checklist_Template_Label"),
              link: "/configuration/checklist-templates-list"
            },
            { label: t("checkList_Form_Breadcrum_Create_Checklist_Label") }
          ]}
        />
      </Grid>

      <Grid container>
        <Paper elevation={2} style={{ padding: "2em", width: "100%" }}>
          {" "}
          <Grid item xs={12}>
            <h2>{t("checkList_Form_Title_Label")}</h2>
          </Grid>
          <Grid item>
            <CommonForm
              fields={fields}
              submitLabel={t("checkList_Form_Submit_Label")}
              submittingLabel={t("checkList_Form_Submitting_Label")}
              initialValues={values}
              validationSchema={Yup.object().shape(validations)}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={(values, { resetForm, setSubmitting }) => {
                handleSubmit(values, resetForm, setSubmitting);
              }}
              formikRef={formikForm}
              buttonSize="3"
              buttonPosition="right"
              enableReinitialize
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
