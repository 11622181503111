import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

export function ColorChangingProgressBar({ value, color }) {
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: "1",
      padding: "1em .5em 2em .5em",
      height: "1em",
      borderRadius: "1em"
    },
    barRoot: {
      borderRadius: "2em",
      height: ".5rem",
      backgroundColor: "#F0F0F0"
    },
    bar: {
      borderRadius: "2em",
      background: "linear-gradient(90deg, #D8FFD6 0%, #45D336 100%)"
      //will cause Dynamic sheed update error in material ui 4.8.1 ...if fixed in material ui use this
      //method ..temporary fix..return seperate colored bars based on value.
      // backgroundColor: value => {
      //   return value < 20
      //     ? "#CC0000"
      //     : value > 60
      //     ? "linear-gradient(90deg, #9bed99 0%, #24d024 100%)"
      //     : "#A86A2D";
      // }
    },
    //temp fix
    bar20: {
      borderRadius: "2em",
      background: "#CC0000"
    },
    bar60: {
      borderRadius: "2em",
      background: "#A86A2D"
    },
    barBlue: {
      borderRadius: "2em",
      backgroundColor: "#5799d1"
    }
  }));
  const classes = useStyles();
  value = parseInt(value);
  return (
    <div className={classes.root}>
      <LinearProgress
        classes={{
          root: classes.barRoot,
          bar:
            color === false
              ? classes.barBlue
              : value < 20
              ? classes.bar20
              : value < 60
              ? classes.bar60
              : classes.bar
        }}
        variant="determinate"
        value={value}
      />
    </div>
  );
}

export function PageLoadingProgressBar() {
  const useStyles = makeStyles(() => ({
    bar: {
      background: "linear-gradient(90deg, #14AAE4 0%, #148AE4 100%)",
      borderRadius:"2px"
    },
    root: {
      backgroundColor: "#F0F0F0",
      borderRadius:"2px"
    }
  }));

  const [completed, setCompleted] = React.useState(0);
  const classes = useStyles();
  React.useEffect(() => {
    function progress() {
      setCompleted(oldCompleted => {
        if (oldCompleted === 100) {
          return 100;
        }
        const diff = Math.floor(Math.random() * (100 - oldCompleted)/10 );
        return Math.min(oldCompleted + diff, 100);
      });
    }

    const timer = setInterval(progress, 300);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div>
      <LinearProgress
        classes={{
          root: classes.root,
          bar: classes.bar
        }}
        //variant="determinate"
        //value={completed}
      />
    </div>
  );
}
