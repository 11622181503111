import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import LoanInsuranceForm from "./LoanInsuranceForm";
import { provisioningService } from "services/provisioningServices";

//Import added for Date field
import { format } from "date-fns";
import addDays from "date-fns/addDays";
import { array, element } from "prop-types";


export default function CreateLoanInsurance() {
  const organizationName = JSON.parse(localStorage.getItem("currentUser")).organizationName;
  const organizationId = JSON.parse(localStorage.getItem("orgId"));
  const history = useHistory();
  const [organization, setOrganization] = React.useState([]);

  const [type, setType] = React.useState([]);
  const [category, setCategory] = React.useState([]);

  useEffect(() => {
    provisioningService.getAllOrgList().then(data => {
      if (data) {
        setOrganization(data);
      }
    });
    provisioningService.getTypeAndCategoryInLoan().then(data => {
      if (data) {
        //console.log("Type n Category:- ", data);
        setType(data.type);
        setCategory(data.category);
      }
    });
  }, []);

  // to initialize the values when create template form loads first time
  let values = {
    organization: organization,
    organizationName: "",
    organizationId: null,
    productName: "",
    productCode: "",
    productCategory: category,
    description: "",
    amount: "",
    productType: type,
    productTenure: "",
    validFromDate: addDays(new Date(), -7),
    validToDate: new Date(),
    consentURL: null,
    imageURL: null,
    premium: "",
    status: "",
    dispDescription: {},
    dispPremium: {},
    dispAmount: {},
    productEligible: false,
    active: false,
    largeTicket: false,
    consentRequired: false,
    productDispName: {},
    productDispCategory: {},
    dispProductTenure: {},
    productDispNameReplicaList: [
      {
      subKey: "",
      subValue: ""
      }
    ],
    productDispCategoryReplicaList: [
      {
      subKey: "",
      subValue: ""
      }
    ],
    productDispTenureReplicaList: [
      {
      subKey: "",
      subValue: ""
      }
    ],
    productDispPremiumReplicaList: [
      {
      subKey: "",
      subValue: ""
      }
    ],
    productDispAmountReplicaList: [
      {
      subKey: "",
      subValue: ""
      }
    ],
    productDispDescriptionReplicaList: [
      {
      subKey: "",
      subValue: ""
      }
    ]
  };

  async function handleSubmit(values, resetForm, setSubmitting) {

    var organizationObj = values.organization.filter(function (item) {
      return item.organizationName === values.organizationName;
    })[0];

    values.organization = organizationObj;
    values.organizationId = organizationObj.organizationId;

    var datetime = new Date(values.validFromDate.setUTCHours(0, 1, 0, 0));
    values.validFromDate = datetime
      .toISOString()
      .replace("Z", " ")
      .replace("T", " ");

    var datetime = new Date(values.validToDate.setUTCHours(23, 59, 59, 999));
    values.validToDate = datetime
      .toISOString()
      .replace("Z", " ")
      .replace("T", " ");

    for(var i=0;i<values.productDispNameReplicaList.length;i++){
      values.productDispName[values.productDispNameReplicaList[i].subKey] = values.productDispNameReplicaList[i].subValue;
    }

    for(var j=0;j<values.productDispCategoryReplicaList.length;j++){
      values.productDispCategory[values.productDispCategoryReplicaList[j].subKey] = values.productDispCategoryReplicaList[j].subValue;
    }

    for(var k=0;k<values.productDispTenureReplicaList.length;k++){
      values.dispProductTenure[values.productDispTenureReplicaList[k].subKey] = values.productDispTenureReplicaList[k].subValue;
    }

    for(var l=0;l<values.productDispPremiumReplicaList.length;l++){
      values.dispPremium[values.productDispPremiumReplicaList[l].subKey] = values.productDispPremiumReplicaList[l].subValue;
    }

    for(var m=0;m<values.productDispAmountReplicaList.length;m++){
      values.dispAmount[values.productDispAmountReplicaList[m].subKey] = values.productDispAmountReplicaList[m].subValue;
    }

    for(var n=0;n<values.productDispDescriptionReplicaList.length;n++){
      values.dispDescription[values.productDispDescriptionReplicaList[n].subKey] = values.productDispDescriptionReplicaList[n].subValue;
    }

    delete values["productDispNameReplicaList"];
    delete values["productDispCategoryReplicaList"];
    delete values["productDispTenureReplicaList"];
    delete values["productDispPremiumReplicaList"];
    delete values["productDispAmountReplicaList"];
    delete values["productDispDescriptionReplicaList"];
    delete values["organization"];

    //console.log("Create Post Obj:--------- ", values);
    try {
      const repeat = null;
      const result = await provisioningService.createLoanInsurance(values);
      resetForm();
      history.push("/configuration/loan-insurance-list");
    } catch (e) {
      console.error(e);
      setSubmitting(false);
    }
    //setSubmit to false
    //call resetForm
  }

  // Calling the create template form with values(to initialize),
  return (
    <LoanInsuranceForm 
      values={values} 
      handleSubmit={handleSubmit} 
      page="add" 
      organization={organization} 
      type={type}
      category={category}
    />
  );
}
