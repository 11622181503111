import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";

import CommonForm from "../../../components/common/form/common-form";
import { errorContext } from "context/errorContext";
import { useTranslation } from "react-i18next";
import { provisioningService } from "../../../services/provisioningServices.js";
import Table from "components/common/table/DynamicPaginationTable2Checkbox";
import { itemLevelConfigurationService } from "services/itemLevelConfigurationServices";
import SweetAlert from 'react-bootstrap-sweetalert';


//Import added for Date field
import addDays from "date-fns/addDays";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Switch,
  Box,
  withStyles,
} from "@material-ui/core";
import color from "@material-ui/core/colors/amber";
import { red } from "@material-ui/core/colors";


export default function ItemLevelConfigurationAdvanced({ values, showSubmitButton, handleSubmit }) {
  
  //console.log("Advance ====", values);
  const [advancedAlterCodFlag, setAdvancedAlterCodFlag] = React.useState(false);
  const [advancedTabSuccess, setAdvancedTabSuccess] = React.useState(false);

  const hideAlert = () =>{
    setAdvancedTabSuccess(false);
    setNoDataPriceMapping(false);
  }

  const [pbp,setPbp] = React.useState([]);
  const [territory,setTerritory] = React.useState([]);
  const [appType,setAppType] = React.useState([]);
  const [customerGroup,setCustomerGroup] = React.useState([]);
  const [itemConfigPriceMappingList, setitemConfigPriceMappingList] = React.useState([]);
  const [itemConfigPriceMappingListStatus, setItemConfigPriceMappingListStatus] = React.useState("loading");
  const [checkboxSelectedData,setCheckboxSelectedData] = useState([]);
  const [codSetToFalseValues, setCodSetToFalseValues] = useState([]);

  const [valuesAdvancedSearchTable, setValuesAdvancedSearchTable] = useState(values);
  const [advancedSearchTableFlag, setAdvancedSearchTableFlag] = React.useState(false);

  const [noDataPriceMapping, setNoDataPriceMapping] = useState(false);

  const formikForm = React.useRef(null);

  const { t } = useTranslation();

  const { dispatch: errorDispatch } = useContext(errorContext);


  useEffect(() => {
    
    //provisioningService
    itemLevelConfigurationService
      .getItemLevelConfigMasterData()
      .then(data => {
        //console.log("masterData:========== ", data)
        setPbp(data.pbps);
        setTerritory(data.territories);
        setAppType(data.appTypes);
        setCustomerGroup(data.customerGroups);
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });

      // itemLevelConfigurationService
      // .getItemLevelConfigListById(values[0].productId)
      // .then(data => {
      //   values.isCodEnabled = data.isCodEnabled;
      //   console.log("Advanced Tab useEffect ========= ", values.isCodEnabled);
      // })
      // .catch(error => {
      //   errorDispatch({
      //     type: "ERROR",
      //     error
      //   });
      // });

      // itemLevelConfigurationService
      //   .getItemLevelConfigPriceMappingList(values[0].productId)
      //   .then(data2 => {      
      //     values = data2.content;
      //   })
      //   .catch(error => {
      //   errorDispatch({
      //       type: "ERROR",
      //       error
      //   });
      // });

  }, []);

  
  let initialValuesAdvancedSearch = {
    appType: "",
    customerGroup: "",
    territory: "",
    productId: values[0].productId
  };


  function handleSubmit2(initialValuesAdvancedSearch, setSubmitting){
    //console.log("Advanced Search Submit :==== ", initialValuesAdvancedSearch);
    if(initialValuesAdvancedSearch.appType == null){
      initialValuesAdvancedSearch.appType = "";
    }
    if(initialValuesAdvancedSearch.customerGroup == null){
      initialValuesAdvancedSearch.customerGroup = "";
    }
    if(initialValuesAdvancedSearch.territory == null){
      initialValuesAdvancedSearch.territory = "";
    }
    setValuesAdvancedSearchTable([]);
    itemLevelConfigurationService
      .getItemLevelConfigPriceMappingListBasedParams(initialValuesAdvancedSearch.productId,1000,0,initialValuesAdvancedSearch.appType,initialValuesAdvancedSearch.customerGroup,initialValuesAdvancedSearch.territory)
      .then(data => {
        //console.log("handleSubmit2 :========== ", data.content)
        setValuesAdvancedSearchTable(data.content);
        setSubmitting(false);
        setAdvancedSearchTableFlag(true);
        setNoDataPriceMapping(false);
        //console.log("handleSubmit2 :========== ", valuesAdvancedSearchTable);
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
  }
  

  function checkboxSelected(e,data){

    //console.log("checkbox handle===================", e.target.checked);
    //console.log("Data from select================",data);
    setNoDataPriceMapping(false);

    if(e.target.checked === true){
      checkboxSelectedData.push(data.id);
      //console.log("if checkboxSelectedData", checkboxSelectedData)
    }else {
      codSetToFalseValues.push(data.id);
      //console.log("codSetToFalseValues", codSetToFalseValues)
      let index = checkboxSelectedData.findIndex(tmpData => tmpData === data.id);
      //console.log("index, tmpData ", index);
      checkboxSelectedData.splice(index, 1);
      //console.log("else checkboxSelectedData", checkboxSelectedData)

    }
    
  }

async function handleSubmit1(values, resetForm, setSubmitting) {
    //console.log("Submit btn ", values);

    let constructedConfigAdvancedArr = [];
    for(var k=0;k<checkboxSelectedData.length;k++){     
      let constructedConfigAdvancedObj = {};
      constructedConfigAdvancedObj.entityId = checkboxSelectedData[k];
      //console.log("for loop id", constructedConfigAdvancedObj.entityId)
      constructedConfigAdvancedObj.entityName = "product_price_mappings";
      constructedConfigAdvancedObj.value = true;
      constructedConfigAdvancedObj.field = "is_cod_enabled";
      constructedConfigAdvancedArr.push(constructedConfigAdvancedObj);
    }

    if(codSetToFalseValues.length>0){
      for(var m=0;m<codSetToFalseValues.length;m++){
        //console.log("hitting False for loop when submitted ", codSetToFalseValues.length);
        let constructedConfigAdvancedObj1 = {};
        constructedConfigAdvancedObj1.entityId = codSetToFalseValues[m];
        constructedConfigAdvancedObj1.entityName = "product_price_mappings";
        constructedConfigAdvancedObj1.value = false;
        constructedConfigAdvancedObj1.field = "is_cod_enabled";
        constructedConfigAdvancedArr.push(constructedConfigAdvancedObj1);
      }
    }

    //constructedConfigAdvancedArr.push(constructedConfigAdvancedObj);
    //console.log("Advanced Tabs values to be passed to Backend :========= ", constructedConfigAdvancedArr);
    if(constructedConfigAdvancedArr.length == 0 || valuesAdvancedSearchTable.length == 0){
      setNoDataPriceMapping(true);
      setSubmitting(false);
      return;
    }
    //setBasicTabSuccess(true);

    try {
      const repeat = null;
      const result = await itemLevelConfigurationService.saveItemLevelConfigurations(constructedConfigAdvancedArr);
      //resetForm();
      setAdvancedTabSuccess(true);
      setSubmitting(false);
    } catch (e) {
      console.log(e);
      setAdvancedTabSuccess(false);
      setSubmitting(false);
    }

  }

  
  const columns = React.useMemo(
    () => [
      {
        Header: t("itemLevelConfig_Id_Label"),
        accessor: "id",
      },
      {
        Header: t("itemLevelConfig_ProductId_Label"),
        accessor: "productId",
      },
      {
        Header: t("itemLevelConfig_AppType_Label"),
        accessor: "appType",
      },
      {
        Header: t("itemLevelConfig_CustomerGroup_Label"),
        accessor: "customerGroup",
      },
      {
        Header: t("itemLevelConfig_Territory_Label"),
        accessor: "omsTeritoryName"
      },
      // { 
      //   Header: t("itemLevelConfig_CODEnabled_Label"),
      //   accessor: "isCodEnabled",
      //   Cell: ({ row }) => {
      //     return (
      //       <input type="checkbox" key={row.original.id} checked={checkboxSelectedData.includes(row.original.id) ? true : false} onChange={ (e) => checkboxSelected(e,row.original)} ></input>            
      //     )
      //   }
      // }
      { 
        Header: t("itemLevelConfig_CODEnabled_Label"),
        accessor: "isCodEnabled",
        Cell: ({ row }) => {
          return (
            <input type="checkbox" key={row.original.id} defaultChecked={row.original.isCodEnabled == true ? true : false} checked={row.checked} onChange={ (e) => checkboxSelected(e,row.original)} ></input>            
          )
        }
      }
    ],
    [checkboxSelectedData,values]
  );

  const fields = [
    //singleSelectSearch
    // {
    //   type: "select",
    //   name: "pbp",
    //   label: t("itemLevelConfig_PBP_Label"),
    //   placeholder: t("itemLevelConfig_PBP_Label"),
    //   option:
    //   pbp.length > 0
    //       ? pbp.map(pbp => ({
    //          value: pbp,
    //          label: pbp
    //         }))
    //     : [],
    //   grid: {
    //     xs: 12,
    //     sm: 2,
    //     md: 3,
    //     className: ""
    //   }
    // },
    {
      type: "select",
      name: "appType",
      label: t("itemLevelConfig_AppType_Label"),
      placeholder: t("itemLevelConfig_AppType_Label"),
      option:
      appType.length > 0
          ? appType.map(appType => ({
             value: appType,
             label: appType
            }))
        : [],
      grid: {
        xs: 12,
        sm: 2,
        md: 3,
        className: ""
      }
    },
    {
      type: "select",
      name: "customerGroup",
      label: t("itemLevelConfig_CustomerGroup_Label"),
      placeholder: t("itemLevelConfig_CustomerGroup_Label"),
      option:
      customerGroup.length > 0
          ? customerGroup.map(customerGroup => ({
             value: customerGroup,
             label: customerGroup
            }))
        : [],
      grid: {
        xs: 12,
        sm: 2,
        md: 3,
        className: ""
      }
    },
    {
      type: "select",
      name: "territory",
      label: t("itemLevelConfig_Territory_Label"),
      placeholder: t("itemLevelConfig_Territory_Label"),
      option:
      territory.length > 0
          ? territory.map(territory => ({
             value: territory,
             label: territory
            }))
        : [],
      grid: {
        xs: 12,
        sm: 2,
        md: 3,
        className: ""
      }
    },
  ]

  let validations = {
  
  };

  //if(advancedSearchTableFlag){
    const data1 = React.useMemo(() => [...valuesAdvancedSearchTable]);
  //}else{
    const data = React.useMemo(() => [...values]);
  //}

  return (
    <>
      {/* {noDataPriceMapping ? <h6 style={{ color: 'red'}}>Alter atleast one COD (or) No records available</h6> : null} */}

      <CommonForm
        //fields={fields}
        submitLabel={t("itemLevelConfig_Update_Btn")}
        submittingLabel={t("itemLevelConfig_Updating_Btn")}
        initialValues={values}
        // validationSchema={Yup.object().shape(validations)}
        // validateOnBlur={true}
        // validateOnChange={true}
        showSubmitButton={showSubmitButton}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          //console.log("Actual Values Passed:- ", values);
          handleSubmit1(values, resetForm, setSubmitting);
        }}
        formikRef={formikForm}
        buttonSize="3"
        buttonPosition="right"
        enableReinitialize
      />

      <CommonForm
        fields={fields}
        submitLabel={t("itemLevelConfig_Advanced_Btn_Label")}
        submittingLabel={t("itemLevelConfig_Advanced_Btn_Searching_Label")}
        initialValues={initialValuesAdvancedSearch}
        // validationSchema={Yup.object().shape(validations)}
        // validateOnBlur={true}
        // validateOnChange={true}
        showSubmitButton={showSubmitButton}
        onSubmit={(initialValuesAdvancedSearch, { resetForm, setSubmitting }) => {
          //console.log("Actual Values Passed:- ", values);
          handleSubmit2(initialValuesAdvancedSearch, resetForm, setSubmitting);
        }}
        formikRef={formikForm}
        buttonSize="3"
        buttonPosition="right"
        enableReinitialize
      />

      <br></br>
        {(advancedSearchTableFlag && <Table
          columns={columns}
          data={data1}
          //needDropdown={true}
          // dropItems={dropitems}
          fileName={"itemLevelConfigListById.xls"}
        /> )}

        {(!advancedSearchTableFlag && <Table
            columns={columns}
            data={data}
            //needDropdown={true}
            // dropItems={dropitems}
            fileName={"itemLevelConfigListById.xls"}
          /> )}  
      <br></br>

      {advancedTabSuccess  ? 
              <SweetAlert success
                title="Configuration updated successfully !!!"
                onConfirm={hideAlert}
                onCancel={hideAlert}
                //timeout={2000}
              ></SweetAlert> 
            : null}

      {noDataPriceMapping  ? 
                    <SweetAlert danger
                      title="Alter atleast one COD or No records available !!!"
                      onConfirm={hideAlert}
                      onCancel={hideAlert}
                      //timeout={2000}
                    ></SweetAlert> 
                  : null}
      
    </>

  )

 }
