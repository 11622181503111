import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotificationForm from "./NotificationForm";
import { authContext } from "context/authContext";
import { notificationService } from "services/notificationServices";
import addDays from "date-fns/addDays";
import { errorContext } from "context/errorContext";
import { useTranslation } from "react-i18next";
import FailureFeedback from "components/common/elements/FailureFeedback";

export default function CreateNotification() {
  const { dispatch: errorDispatch } = useContext(errorContext);
  const org = JSON.parse(localStorage.getItem("currentUser")).organizationName;
  const { authState } = useContext(authContext);
  // fetch the initial subOrg list
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: ""
    });
    dispatch({
      type: "SITE_CONFIGURATION_DEVICE_RESET"
    });
    dispatch({
      type: "NOTIFICATION_CONFIG_UI_OPTIONS"
    });
  }, []);

  // const subOrganizations = useSelector(
  //   state => state.site.toJS().subOrganizations
  // );
  //const status = useSelector(state => state.site.toJS().status);
  const createError = useSelector(state => state.site.toJS().createError);
 
  const [language, setLanguage] = React.useState([]);
  const [appAction, setAppAction] = React.useState([]);
  const [mimeType, setMimeType] = React.useState([]);
  const [productCode, setProductCode] = React.useState([]);
  const [publishChannel, setPublishChannel] = React.useState([]);
  const [actionUrl, setActionUrl] = React.useState([]);
  const [previewUrl, setPreviewUrl] = React.useState([]);
  const [topicName,setTopicName] = React.useState([]);
  const [allDropDownData, setAllDropDownData] = React.useState([]);
  const [edit,setEdit] = React.useState(false);
  const [endDateValidation,setEndDateValidation] = React.useState(false);

  const [validDateRangeMessage, setValidDateRangeMessage] = useState(false);

  const { t } = useTranslation();

  const [status, setStatus] = useState("loading");
  useEffect(() => {
    notificationService.getNotificationUIOptions('*').then(data => {
      if (data) {
        setAllDropDownData(data);
        setLanguage(data.LANGUAGE);
        setAppAction(data.APP_ACTION_SMART_FARMS);
        setMimeType(data.MIME_TYPE);
        setProductCode(data.PRODUCTS);
        setPublishChannel(data.PUBLISH_CHANNEL);
        setTopicName(data.TOPIC_NAME);
      }
    });
  }, []);

  let days = 0;

  function convert(str){
    let date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
  }

  function convertTime(str){
    let date = new Date(str),
      mnth = ("0" + (date.getMonth()+1)).slice(-2),
      day  = ("0" + date.getDate()).slice(-2),
      hours  = ("0" + date.getHours()).slice(-2),
      minutes = ("0" + date.getMinutes()).slice(-2),
      seconds = ("0"+ date.getSeconds()).slice(-2);
      return [ hours, minutes,seconds ].join(":");
  } 

  function convertEndTime(str){
    let date = new Date(str);
    date.setTime(date.getTime() + (60*60*1000))
    
    let hours  = ("0" + date.getHours()).slice(-2),
    minutes = ("0" + date.getMinutes()).slice(-2),
    seconds = ("0"+ date.getSeconds()).slice(-2);

    return [ hours, minutes,seconds ].join(":");
  } 

  function invokeSchedulerService(data,values){
    let schedulerData ={};
    schedulerData.uuid = data[0].configurationId;

    if(values.type === "NO_REPEAT"){
      let tmpStartDate = convert(values.startTime);   
      let tmpStartTime = convertTime(values.scheduleTime);
      values.startTime = tmpStartDate +" "+tmpStartTime; 
    }else if(values.type === "CUSTOM"){
      let tmpPeriodicDate = convert(values.periodicDate);
      let tmpPeriodicTime = convertTime(values.periodicTime);
      values.startTime = tmpPeriodicDate + " "+ tmpPeriodicTime;
    }

    let endOn = convert(values.params.end.on);
    let endOnTime = convertEndTime(values.periodicTime);

    values.params.end.on = endOn +" "+endOnTime;
    values.approvalRequired = true;

    if(values.params.repeatsEveryUnit ==="WEEK" && values.params.end.never === true){
      values.params.end.on = null;
    }

    delete values["scheduleTime"];
    delete values["deliveryOption"];
    delete values["periodicDate"];
    delete values["periodicTime"];
    delete values["isEndOnDate"]

    schedulerData.scheduler = values;   

    notificationService.notificationScheduler(schedulerData).then(data => {
      if (data) {
        console.log(data);
        // resetForm();
        setStatus("success");
      }
    })
    .catch(error => {
      console.error(error);
        errorDispatch({
        type: "ERROR",
        error
      });
      setStatus("failed");
    });
  }

  function handleSubmit(values, resetForm, setSubmitting) {

    for(var i=0;i<values.extraDataList.length;i++){
      values.extraData[values.extraDataList[i].subKey] = values.extraDataList[i].subValue;
    }

    delete values["extraDataList"];
    
    if (values.targetUsers) {
      values.targetUsers = values.targetUsers.split(',');
    }
    console.log("value" + values.publishChannel);
    if(typeof(values.validFromDate) === "string" || typeof(values.validToDate) === "string"){
     
      var fromDatetime = new Date(values.validFrom);
      values.validFrom = fromDatetime
        .toISOString()
        .replace("00:00:00.000", "00:01:00.000")
        .replace("Z", " ")
        .replace("T", " ");
    
      var toDatetime = new Date(values.validUpTo);
      values.validUpTo = toDatetime
        .toISOString()
        .replace("00:00:00.000","23:59:59.999")
        .replace("Z", " ")
        .replace("T", " ");
      
      let diffInMs = Math.floor(toDatetime - fromDatetime);
      days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    }else{
      var fromDatetime = new Date(values.validFrom.setUTCHours(0, 1, 0, 0));
      values.validFrom = fromDatetime
        .toISOString()
        .replace("Z", " ")
        .replace("T", " ");
      var toDatetime = new Date(values.validUpTo.setUTCHours(23, 59, 59, 999));
      values.validUpTo = toDatetime
        .toISOString()
        .replace("Z", " ")
        .replace("T", " ");

      let diffInMs = Math.floor(toDatetime - fromDatetime);
      days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    }

    console.log(days);

    if(days < 0){
      setSubmitting(false);
      setValidDateRangeMessage(true);
      return;
    }

    if(values.topicName.length === 0){
      values.topicName ="";
    }       

    values.sourceAppName = "UI-PORTAL";

    values.isScheduledNotification = false;
    if(values.scheduler.deliveryOption != "Manual"){
      values.isScheduledNotification = true;
    }

    let value = [];
    value.push(values); 
    delete value["scheduler"];

    // console.log("value of create notification",value);

    // let diffDays = 0;
    // if(values.scheduler.isEndOnDate === true){
    //   let diffInMs = Math.floor(values.scheduler.params.end.on - values.scheduler.periodicDate);
    //   console.log(diffInMs);
    //   diffDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    // }

    // if(diffDays < 0){
    //   console.log(diffDays);
    //   setSubmitting(false);
    //   //setEndDateValidation(true);
    //   return;
    // }

    notificationService.createNotifications(value).then(data => {
      if (data) {
        resetForm();
        if(values.scheduler.deliveryOption != "Manual"){
          invokeSchedulerService(data,values.scheduler);
        }else{
          setStatus("success");
        }
      }
    })
    .catch(error => {
      console.error(error);
        errorDispatch({
        type: "ERROR",
        error
      });
      setStatus("failed");
    });
  }

  const [otherVendors, setOtherVendors] = React.useState(false);

  const handleValidDateAlertClose = () => {
    setValidDateRangeMessage(false);
  }

  const handleVendorSelect = value => {
    if (value === "Others") {
      setOtherVendors(true);
    } else {
      setOtherVendors(false);
    }
  };

  function handleEndDateAlertClose(){
    setEndDateValidation(false);
  }

  let values = {
    title: "",
    content: "",
    language: [],
    mimeType: [],
    previewUrl: "",
    actionUrl: "",
    appAction: "",
    publishChannel: [],
    topicName: [],
    productCode: [],
    targetUsers: [],
    validFrom: addDays(new Date(), -2),
    validUpTo: new Date(),
    extraData:{},
    extraDataList: [
      {
        subKey: "",
        subValue: ""
      }
    ],
    scheduler:
      {
        type:"",
        deliveryOption:"",
        startTime:new Date(),
        periodicDate:new Date(),
        periodicTime:new Date(),
        scheduleTime:new Date(),
        isEndOnDate:false,
        params:{
          repeatsEvery: 1,
          repeatsEveryUnit: "DAY",
          repeatOn: {
            MON: "",
            TUE: "",
            WED: "",
            THU: "",
            FRI: "",
            SAT: "",
            SUN: "",
          },
          end: {
            never: true,
            on:new Date(),
            afterOccurrences: 0
          }
        }
      }
  };
  return (
    <>
    <NotificationForm
      values={values}
      language={language}
      mimeType={mimeType}
      actionUrl={actionUrl}
      previewUrl={previewUrl}
      publishChannel={publishChannel}
      topicName={topicName}
      productCode={productCode}
      appAction={appAction}
      allDropDownData = {allDropDownData}
      handleSubmit={handleSubmit}
      status={status}
      edit={edit}
      createError={createError}
      otherVendors={otherVendors}
      handleVendorSelect={handleVendorSelect} />
      
      <FailureFeedback
        open={validDateRangeMessage}
        onClose={handleValidDateAlertClose}
        status={"Failed"}
        message={t("notification_list_Valid_Date_Range_Message")}
        showButtons={true} />

      <FailureFeedback
        open={endDateValidation}
        onClose={handleEndDateAlertClose}
        status={"Failed"}
        message={t("notification_list_End_Date_Validation_Message")}
        showButtons={true} />
    </>   
  );
}
