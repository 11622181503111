import { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { errorContext } from "context/errorContext";

export default function ErrorWrapper({ children }) {
  const history = useHistory();

  const { state, dispatch } = useContext(errorContext);
  useEffect(() => {
    if (!!state && state.error) {
      if (state.status === 500) {
        history.push("/500");
      } else if (state.status === 401) {
        history.push("/401");
      } else if (state.status === 403) {
        history.push("/403");
      } else if (state.status === 503) {
        history.push("/503");
      } else if (state.status === 404) {
        history.push("/404");
      } else if (state.status === "no_data") {
        history.push("/nodata");
      } else {
        history.push("/error");
      }
    }

    return function resetError() {
      dispatch({
        type: "RESET_ERROR"
      });
    };
  }, [state]);

  return children;
}
