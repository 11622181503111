import React, { useEffect, useContext, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PageHeader from "../../../components/layout/PageHeader.js";
import { approveServices } from "../../../services/approveServices.js";

import CircularProgress from "@material-ui/core/CircularProgress";
import TemplateForTables from "../../../components/layout/innerPagesTemplate";
import { Grid, Paper } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

import { errorContext } from "context/errorContext";
import addDays from "date-fns/addDays";

import "../../../components/Table.scss";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import CommonForm from "components/common/form/common-form";
import { func } from "prop-types";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
import FailureFeedback from "components/common/elements/FailureFeedback";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Table from "components/common/table/DynamicPaginationTable2";
import { farmerAssociationService } from "services/farmerAssociationServices";
import { provisioningService } from "services/provisioningServices";

import FarmerAssociationForm from "./FarmerAssociationForm";

export default function FarmerAssociationList() {
  const { t } = useTranslation();
  const { dispatch: errorDispatch } = useContext(errorContext);
  const [value, setValue] = React.useState(0);
  const [postOfficeData, setPostOfficeData] = React.useState([]);

  const [stateData,setStateData] = React.useState([]);
  const [status, setStatus] = useState("loading");
  const [pbpData, setPbpData] = React.useState([]);
  const [fetchTagOnPbp, setFetchTagOnPbp] = React.useState([]);
  const [orgData,setOrgData ] = React.useState([]);

  useEffect(() => {
    provisioningService.verifyOtpNew('1313131313').then((res) => {
      const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
      const organizationId = JSON.parse(localStorage.getItem("orgId"));
      getListData();
    })
    .catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });
  }, []);
 
  function getListData(value){
    farmerAssociationService.getPbpList().then(data => {    
      setPbpData(data);
    }).catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });

    provisioningService.getAllOrgs().then(data => {
      setOrgData(data);
    }).catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });

    provisioningService.getAllStates().then(data =>{
      setStateData(data.states);
      setStatus("success");
    }).catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });
  } 

  const formikForm = React.useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let values = {
    pbpId: 1,
    entityId:"",
    userType:"",
    selectionType:"",
    customerGroup:"",
    geoLocationBased:[],
    pincodeList:[],
    organizationBased:[]   
  };  

  function handleSubmit(){
    console.log(values);
    console.log("HandleSubmit");
  }

  return (  
  //  {status === "success" ?(
    <FarmerAssociationForm
      values = {values}
      orgData = {orgData}
      stateData = {stateData}
      pbpData = {pbpData}
      status = {status}
    //  handleSubmit = {handleSubmit}
    />   
  //   ):null
  //  }
  );
}
