import React, { useContext } from "react";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { errorContext } from "context/errorContext";
import bmcIcon from "../../assets/bmc-icon.png";
import abcIcon from "../../assets/abc-icon.png";

import PageHeader from "../../components/layout/PageHeader.js";
import TemplateForTables from "../../components/layout/innerPagesTemplate.js";
import ToolTipCell from "components/common/table/ToolTipCell";
import Table from "components/common/table/ControlledPaginationTable";
import Map from "components/Map2";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import "../../components/Table.scss";
import { CircularProgress } from "@material-ui/core";
import { provisioningService } from "../../services/provisioningServices";
import { useTranslation } from "react-i18next";

const SiteDetails = props => {
  const site = useSelector(state => state.site.toJS().site);
  const chillingUnitList = useSelector(
    state => state.site.toJS().chillingUnitList
  );
  const [abcCount, setAbcCount] = React.useState(0);
  const [bmcCount, setBmcCount] = React.useState(0);

  const chillingUnitsFetchStatus = useSelector(
    state => state.site.toJS().chillingUnitsFetchStatus
  );
  const loadingSiteDetails = useSelector(
    state => state.site.toJS().loadingSiteDetails
  );
  const error = useSelector(state => state.site.toJS().error);
  const { dispatch: errorDispatch } = useContext(errorContext);
  let { siteId } = useParams();
  let { siteUUID } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [chillingUnitDetails, setChillingUnitDetails] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [rating, setRating] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (
      site &&
      !!site.chillingUnitMonitoringDetailsList[0] &&
      site.siteId === siteId &&
      chillingUnitList.length > 0
    ) {
      if (
        JSON.stringify(chillingUnitList).includes(
          site.chillingUnitMonitoringDetailsList[0].chillingUnitUUID
        )
      )
        setRating(true);
    } else {
      setRating(false);
    }
  });
  React.useEffect(() => {
    if (rating) {
      addRating();
    }
  }, [rating]);

  React.useEffect(() => {
    if (error !== "") {
      const {
        status,
        error: { message = "" }
      } = error;
      setErrorMessage(message);
    } else {
      setErrorMessage("");
    }
  }, [error]);
  function addRating() {
    var bmc = { name: [] };
    chillingUnitList.map(chillingUnit => {
      bmc.name.push(chillingUnit.chillingUnitID);
    });
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
    var startDate =
      firstDay.getFullYear() +
      "-" +
      (firstDay.getMonth() + 1) +
      "-" +
      firstDay.getDate();
    var endDate =
      lastDay.getFullYear() +
      "-" +
      (lastDay.getMonth() + 1) +
      "-" +
      lastDay.getDate();
    provisioningService
      .getOverAllBmcRating(bmc, startDate, endDate)
      .then(data => {
        data.map(ratingDetails => {
          chillingUnitList.map(chillingUnit => {
            if (chillingUnit.chillingUnitID == ratingDetails.deviceName) {
              chillingUnit.rating = ratingDetails.rating;
              return;
            }
          });
        });
        if (siteId === site.siteId) {
          setChillingUnitDetails(chillingUnitList);
        }
      });
  }

  React.useEffect(() => {
    if (!site || siteId !== site.siteId) {
      dispatch({
        type: "SITE_LOADING"
      });
      dispatch({
        type: "SITE_DETAILS_FETCH",
        siteId
      });
      dispatch({
        type: "CHILLING_LIST_FETCH",
        siteId
      });
    }
  }, [siteId]);

  React.useEffect(() => {
    if (!!site) {
      const bmcCount = site.chillingUnitMonitoringDetailsList
        ? site.chillingUnitMonitoringDetailsList.reduce((count, current) => {
            if (current.productType.name === "BMC") {
              return ++count;
            }
            return count;
          }, 0)
        : 0;
      const abcCount = site.chillingUnitMonitoringDetailsList
        ? site.chillingUnitMonitoringDetailsList.reduce((count, current) => {
            if (current.productType.name === "ABC") {
              return ++count;
            }
            return count;
          }, 0)
        : 0;
      setAbcCount(abcCount);
      setBmcCount(bmcCount);
    }
  }, [site]);

  function truncate(n) {
    return n > 0 ? Math.floor(n) : Math.ceil(n);
  }

  let getDMS = function(dd, longOrLat) {
    let hemisphere = /^[WE]|(?:lon)/i.test(longOrLat)
      ? dd < 0
        ? "W"
        : "E"
      : dd < 0
      ? "S"
      : "N";

    const absDD = Math.abs(dd);
    const degrees = truncate(absDD);
    const minutes = truncate((absDD - degrees) * 60);
    const seconds = (
      (absDD - degrees - minutes / 60) *
      Math.pow(60, 2)
    ).toFixed(2);

    let dmsArray = [degrees, minutes, seconds, hemisphere];
    return `${dmsArray[0]}°${dmsArray[1]}'${dmsArray[2]}" ${dmsArray[3]}`;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("site_Details_Product_Type"),
        accessor: "prductType",
        Cell: ({ row }) => <ToolTipCell>{row.original.prductType}</ToolTipCell>
      },
      {
        Header: t("site_Details_Chilling_Unit_Id"),
        accessor: "chillingUnitID",
        Cell: ({ row }) => {
          return (
            <span
              style={{ color: "#296AAB", cursor: "pointer" }}
              onClick={e =>
                handleChillingUnitClick(row.original.chillingUnitUUID)
              }
            >
              <ToolTipCell>
                {row.original.chillingUnitID}
                <i className="fa fa-line-chart" aria-hidden="true"></i>
              </ToolTipCell>
            </span>
          );
        }
      },
      {
        Header: t("site_Details_Chilling_Unit_Name"),
        accessor: "chillingUnitName",
        Cell: ({ row }) => (
          <ToolTipCell>{row.original.chillingUnitName}</ToolTipCell>
        )
      },
      {
        Header: t("site_Details_Associated_Sensor_Device"),
        accessor: "totalSensors",
        Cell: ({ row }) => (
          <ToolTipCell>{row.original.totalSensors}</ToolTipCell>
        )
      },
      {
        Header: t("site_Details_Gateway_Info"),
        accessor: "gatewaySerialNumber",
        Cell: ({ row }) => row.original.gatewaySerialNumber.slice(1, -1)
      },
      {
        Header: t("site_Details_Device_Type"),

        Cell: ({ row }) => {
          return (
            <span>
              <Link
                to={`/totalsites/site-details/${siteId}/device-data/${row.original.chillingUnitUUID}`}
              >
                {t("site_Details_Device_Data")}
              </Link>
            </span>
          );
        }
      },
      {
        Header: t("site_Details_Device_Profile"),
        Cell: ({ row }) => {
          let a = row.original.deviceProfile.slice(1, -1).split(",");
          let sr = [];
          a.map((v, i, a) => {
            sr.push(<li key={v + i.toString()}>{v}</li>);
          });
          return <ul style={{ textAlign: "left" }}>{sr}</ul>;
        }
      },
      {
        Header: t("site_Details_Rating"),
        accessor: "rating",
        Cell: ({ row }) => (
          <Link to={`/bmc-rating/${row.original.chillingUnitID}`}>
            {row.original.rating}
          </Link>
        )
      }
    ],
    []
  );

  const data = React.useMemo(() => [...chillingUnitDetails]);

  function handleChillingUnitClick(id) {
    history.push(`/bmcgraph/${siteId}`);
    dispatch({
      type: "CHILLING_UNIT_ID_SELECTED",
      id: id
    });
  }
  const dropitems = [
    {
      item: <Link to="/configuration/site">{t("site_Details_Add_Site")}</Link>
    },
    {
      item: (
        <Link to="/configuration/import-site">
          {t("site_Details_Import_Site")}
        </Link>
      )
    }
  ];
  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("site_Details_Header_Label")}
          breadcrumbs={[
            {
              label: t("site_Details_Breadcrum_Dashboard_Label"),
              link: "/dashboard"
            },
            { label: t("site_Details_Breadcrum_Configuration") },
            { label: t("site_Details_Breadcrum_Site_Details") }
          ]}
        />
      </Grid>
      <Grid container style={{ marginBottom: "2em" }}>
        <Paper elevation={2} style={{ width: "100%", padding: "2em" }}>
          {loadingSiteDetails ? (
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              style={{ height: "5rem" }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <h2>
                  {loadingSiteDetails ? (
                    <CircularProgress />
                  ) : (
                    <>
                      {!!site && site.siteId} {!!site && site.siteName}
                    </>
                  )}
                </h2>
              </Grid>
              <Grid item container alignItems="center" xs={12} md={4}>
                {bmcCount > 0 && (
                  <Grid container direction="column">
                    <img src={bmcIcon} alt="bmc" style={{ width: "3rem" }} />
                    <span>
                      {t("site_Details_Total_Bmc")} :
                      <span className={"blue-num"}>{bmcCount}</span>
                    </span>
                  </Grid>
                )}
                {abcCount > 0 && (
                  <Grid container direction="column">
                    <img src={abcIcon} alt="bmc" style={{ width: "3rem" }} />
                    <span>
                      {t("site_Details_Total_Abc")} :
                      <span className={"blue-num"}>{abcCount}</span>
                    </span>
                  </Grid>
                )}
              </Grid>
              <Grid item container md={3}></Grid>
              <Grid item container alignItems="center" xs={12} md={4}>
                <Grid item container justify="space-around" xs={12}>
                  <span>{t("site_Details_Latitude")}</span>
                  <span className={"blue-num"}>
                    {(!!site &&
                      site.coordinates &&
                      getDMS(
                        !!site.coordinates.latitude &&
                          site.coordinates.latitude,
                        "lat"
                      )) ||
                      0.0}{" "}
                    <sup>°</sup>
                  </span>
                </Grid>
                <Grid item container justify="space-around" xs={12}>
                  <span>{t("site_Details_Longitude")}</span>
                  <span className={"blue-num"}>
                    {(!!site &&
                      site.coordinates &&
                      getDMS(
                        !!site.coordinates.longitude &&
                          site.coordinates.longitude,
                        "long"
                      )) ||
                      0.0}{" "}
                    <sup>°</sup>
                  </span>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} md={4}>
                  <div style={{ height: "8em", overflow: "hidden" }}>
                    <Map
                      lat={!!site && site.coordinates.latitude}
                      lng={!!site && site.coordinates.longitude}
                    />
                  </div>
                </Grid> */}
            </Grid>
          )}
        </Paper>
      </Grid>
      {chillingUnitsFetchStatus === "loading" ? (
        <Paper elevation={2} style={{ width: "100%", padding: "2em" }}>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "5rem" }}
          >
            <CircularProgress />
          </Grid>
        </Paper>
      ) : chillingUnitsFetchStatus === "failed" ? (
        <Paper elevation={2} style={{ width: "100%", padding: "2em" }}>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "5rem" }}
          >
            <Alert severity="error">{errorMessage}</Alert>
          </Grid>
        </Paper>
      ) : (
        <TemplateForTables
          dropdownitems={dropitems}
          needDropdown={true}
          importIcons
        >
          {data && data.length > 0 ? (
            <Table columns={columns} data={data} />
          ) : (
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              style={{ height: "5rem" }}
            >
              <CircularProgress />
            </Grid>
          )}
        </TemplateForTables>
      )}
    </Grid>
  );
};

export default SiteDetails;
