import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { getIn } from "formik";

export default function CustomDatePicker({
  label,
  name,
  value = new Date(),
  handleDateChange,
  setFieldTouched,
  maxDate,
  minDate,
  error,
  touched,
  openTo,
  views,
  format="dd MMM  yyyy",
  disabled
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        error={error && !!getIn(touched, name)}
        helperText={error && !!getIn(touched, name) ? error : ""}
        autoOk
        allowKeyboardControl
        onOpen={e => setFieldTouched(name, true)}
        variant="inline"
        margin="dense"
        clearable
        inputVariant="outlined"
        label={label}
        value={value}
        format={format}
        fullWidth={true}
        openTo = {openTo}
        views = {views}
        InputAdornmentProps={{ position: "end", style: { padding: "0px" } }}
        onChange={date => {
          setFieldTouched(name, true);
          setIsOpen(false);
          handleDateChange(date);
        }}
        maxDate={maxDate}
        minDate={minDate}
        disabled={disabled}
        keyboardIcon
        KeyboardButtonProps={{
          style: { marginRight: "-0.6em" },
          onFocus: e => {
            setIsOpen(true);
          }
        }}
        PopoverProps={{
          disableRestoreFocus: true,
          onClose: () => {
            setIsOpen(false);
          }
        }}
        InputProps={{
          onFocus: () => {
            setIsOpen(true);
          }
        }}
        open={isOpen}
      />
    </MuiPickersUtilsProvider>
  );
}
