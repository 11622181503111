import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import PageHeader from "components/layout/PageHeader";
import { Grid, Paper } from "@material-ui/core";
import Table from "components/common/table/DynamicPaginationTable2";
import CircularProgress from "@material-ui/core/CircularProgress";
import addDays from "date-fns/addDays";
import CommonForm from "../../components/common/form/common-form";
import * as Yup from "yup";
import { provisioningService } from "../../services/provisioningServices";
import { chillingUnitService } from "../../services/chillingUnitService";
import { endOfDecade } from "date-fns";
import { useTranslation } from "react-i18next";

const PreventiveMaintenanceList = props => {
  const [startDate, setStartDate] = React.useState(addDays(new Date(), -1));
  const [endDate, setEndDate] = React.useState(new Date());
  const [record, setRecord] = React.useState([]);
  const [status, setStatus] = React.useState("yetToLoad");
  const [loading, setLoading] = useState(false);
  const [bmcList, setBmcList] = useState([]);
  const { t } = useTranslation();

  const user = JSON.parse(localStorage.getItem("currentUser"));
  const organizationName = user && user.organizationName;

  const initialValues = {
    chillingUnitUuid: "",
    startDate: startDate,
    endDate: endDate
  };

  const dropitems = [
    {
      item: <Link to="/preventivemaintenance/add-record">{t("pm_View_Record_Add_Record_Label")}</Link>
    }
  ];

  const columns = React.useMemo(() => [
    {
      Header: t("pm_View_Record_Check_List_Name"),
      accessor: "checklistName",
      Cell: ({ row }) => {
        return (
          <Link
            to={`/preventiveMaintenance/view-records/record-details/${row.original.checklistName}`}
          >
            <span>{row.original.checklistName}</span>
          </Link>
        );
      }
    },
    {
      Header: t("pm_View_Record_Description"),
      accessor: "checklistDesc",
      width: 300
    },
    {
      Header: t("pm_View_Record_Org_Name"),
      accessor: "organizationName"
    },
    {
      Header: t("pm_View_Record_Performed_By"),
      accessor: "performedBy"
    }
  ]);

  const fields = [
    {
      type: "select",
      name: "chillingUnitUuid",
      label: t("pm_View_Record_Select_Chilling_Unit"),
      placeholder: "Select Chilling Unit",
      option:
        bmcList.length !== 0
          ? bmcList.map(bmc => ({
            value: bmc.chillingUnitUuid,
            label: bmc.chillingUnitName + " - " + bmc.chillingUnitId
          }))
          : [],
      grid: {
        xs: 12,
        sm: 3
      }
    },
    {
      type: "datePicker",
      name: "startDate",
      value: { startDate },
      label: t("pm_View_Record_Start_Date"),
      grid: {
        xs: 12,
        sm: 3
      }
    },
    {
      type: "datePicker",
      name: "endDate",
      value: { endDate },
      label: t("pm_View_Record_End_Date"),
      grid: {
        xs: 12,
        sm: 3
      }
    }
  ];

  useEffect(() => {
    setLoading(true);
    provisioningService.getAllSitesByOrg(organizationName).then(data => {
      let chillingUnitNames = data.content
        .map(site => site.chillingUnitCompresedDTOS)
        .flat();
      setBmcList(chillingUnitNames);
      setLoading(false);
    });
  }, []);

  const validations = {
    chillingUnitUuid: Yup.string().required(t("pm_View_Record_Validation_Chilling_Unit"))
  };
  const handleSubmit = (values, setSubmitting) => {
    setStatus("loading");
    chillingUnitService
      .getChecklistByUuid(
        values.chillingUnitUuid,
        values.startDate.toISOString().substring(0, 10),
        values.endDate.toISOString().substring(0, 10)
      )
      .then(data => {
        setRecord(data);
        setStatus("success");
        setSubmitting(false);
      });
  };

  // const nextPage = () => {
  //   if (
  //     status !== "loading" &&
  //     pageInfo.hasNextPage &&
  //     pageIndex < pageInfo.totalPages
  //   ) {
  //     let index = pageIndex;
  //     setPageIndex(++index);
  //   }
  // };
  // const previousPage = () => {
  //   let index = pageIndex;
  //   if (index > 0) {
  //     setPageIndex(--index);
  //   }
  // };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title={t("pm_View_Record_Header_Label")}
          breadcrumbs={[
            { label: t("pm_View_Record_Dashboard_Label"), link: "/dashboard" },
            { label: t("pm_View_Record_Preventive_Maintenance_Label") },
            { label: t("pm_View_Record_View_Record_Label") }
          ]}
        />
      </Grid>
      <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%"
        }}
      >
        <Grid item>
          <CommonForm
            fields={fields}
            submitLabel={t("pm_View_Record_Submit_Label")}
            submittingLabel={t("pm_View_Record_Submitting_Label")}
            validationSchema={Yup.object().shape({ ...validations })}
            validateOnBlur={true}
            initialValues={initialValues}
            validateOnChange={true}
            onSubmit={(initialValues, { setSubmitting }) => {
              handleSubmit(initialValues, setSubmitting);
            }}
            buttonPosition="right"
            buttonSize="2"
            inlineButton={true}
            style={{ overflow: "auto" }}
          />
        </Grid>
        {status === "loading" ? (
          <Grid
            container
            xs={12}
            justify="center"
            alignItems="center"
            style={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : status === "success" ? (
          <Grid item style={{ marginTop: 20 }}>
            <Table
              columns={columns}
              data={record}
              needDropdown={true}
              dropItems={dropitems}
              fileName={"preventiveRecordList.xls"}
            />
          </Grid>
        ) : null}
      </Paper>
    </Grid>
  );
};
export default PreventiveMaintenanceList;
