import React, { useEffect, useContext,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { authContext } from "context/authContext";
import { notificationService } from "services/notificationServices";
import { errorContext } from "context/errorContext";
import addDays from "date-fns/addDays";
import { Grid, Paper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation } from 'react-router-dom'; 
import { isAfter } from "date-fns";
import { approveServices } from "services/approveServices";
import FailureFeedback from "components/common/elements/FailureFeedback";
import { useTranslation } from "react-i18next";
import RetailerAgentCriteriaForm from "./RetailerAgentCriteriaForm";
import { agentAssociationService } from "services/agentAssociationServices";
import { retailerAgentAssociationService } from "../../../services/retailerAgentAssociationServices";
import SuccessFeedback from "components/common/elements/SuccessFeedback";
import { provisioningService } from "services/provisioningServices";

export default function EditRetailerAgentCriteria() {

    const { dispatch: errorDispatch } = useContext(errorContext);

    const createError = useSelector(state => state.site.toJS().createError);

    const location = useLocation();
    //console.log("Location:========= ", location);
    const history = useHistory();
    const { t } = useTranslation();
    const { agentName } = useParams();
    const editRetailerAgentCriteriaData = location.state.from;
    //const agentName1 = location.state.from.agentName;
    const editStatus = "";
    const edit = "";
    const [pbpData, setPbpData] = React.useState([]);
    const [pbpStatus,setPbpStatus] = React.useState("loading");
    const [servicesOffering, setServicesOffering] = React.useState([]);
    const [status,setStatus] = React.useState(false);
    const [failedStatus,setFailedStatus] = React.useState(false);
    const [openConfirmDialog,setOpenConfirmDialog] = React.useState(false);
    const [orgData,setOrgData ] = React.useState([]);
    const [selectionError,setSelectionError] = useState("");

    useEffect(() => {
      getMasterData();
      provisioningService.getAllOrgs().then(data => {
        setOrgData(data);
      }).catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
    }, []);

    function getMasterData(){
      agentAssociationService.getAgentOriginAndServicesOffering().then(data => {
        // const filteredData = data.filter(data =>data.name === "moogrow"); 
        for(var i=0;i<data.pbp.length;i++){
          delete data.pbp[i]["gst"];
          delete data.pbp[i]["address"];
          delete data.pbp[i]["territories"];
        }
        //console.log("PBP re:------", data.pbp);
        //console.log("Agent Origin data:==========> ", data);
        setPbpData(data.pbp);
        setServicesOffering(data.servicesOffering);
   
       }).catch(error => {
         errorDispatch({
           type: "ERROR",
           error
         });
       });
    }

    const values = {
      criteriaId: !!editRetailerAgentCriteriaData ? editRetailerAgentCriteriaData.id : "",
      criteriaTags: !!editRetailerAgentCriteriaData ? editRetailerAgentCriteriaData.criteriaTags : "",
      criteriaTagNames: !!editRetailerAgentCriteriaData ? editRetailerAgentCriteriaData.criteriaTagNames : "",
      servicesOffering: !!editRetailerAgentCriteriaData ? editRetailerAgentCriteriaData.servicesOffering : "",
      pbp: !!editRetailerAgentCriteriaData && editRetailerAgentCriteriaData.pbp  ? editRetailerAgentCriteriaData.pbp[0].id : [{}],
      criteria: !!editRetailerAgentCriteriaData && !!editRetailerAgentCriteriaData.criteria && !!editRetailerAgentCriteriaData.criteria  ? editRetailerAgentCriteriaData.criteria : {},
      organizationBased: !!editRetailerAgentCriteriaData && !!editRetailerAgentCriteriaData.criteria && !!editRetailerAgentCriteriaData.criteria.organizationBased  ? editRetailerAgentCriteriaData.criteria.organizationBased : [],
      pincodeBased: !!editRetailerAgentCriteriaData && !!editRetailerAgentCriteriaData.criteria && !!editRetailerAgentCriteriaData.criteria.pincodeBased  ? editRetailerAgentCriteriaData.criteria.pincodeBased : []
    }

    function handleSubmit(values, resetForm, setSubmitting) {
      setSelectionError("");
      if(values.criteria.organizationBased.length === 0 && values.criteria.pincodeBased.length ===0){
        setSelectionError("Please select the Either of the Criteria(Organizatin/Pincode)by Clicking on Plus Symbol");
        setSubmitting(false);
        return;
      }     

      if(values.servicesOffering.length === 0){
        setSelectionError("Please select Service Offering");
        setSubmitting(false);
        return;
      }

      if(values.pbp){
        let selectedPbp = pbpData.filter(data => {
          return (data.id === values.pbp) ;
        });
        values.pbp = selectedPbp;     
      }

      editRetailerAgentCriteriaData.pbp = values.pbp;
      editRetailerAgentCriteriaData.servicesOffering = values.servicesOffering;
      editRetailerAgentCriteriaData.criteriaTags = values.criteriaTags;
      editRetailerAgentCriteriaData.criteriaTagNames = values.criteriaTagNames;

      delete editRetailerAgentCriteriaData["agentOrigin"];

      for(var k=0;k<editRetailerAgentCriteriaData.pbp.length;k++){
        delete editRetailerAgentCriteriaData.pbp[k].address;
        delete editRetailerAgentCriteriaData.pbp[k].gst;
        delete editRetailerAgentCriteriaData.pbp[k].territories;
      }

      let orgArr = []; 
      
      if(editRetailerAgentCriteriaData.roleName === "FIS_support" || editRetailerAgentCriteriaData.roleName === "MR_retailer"){
        for(var i=0;i<values.criteria.organizationBased.length;i++){

          if(values.criteria.organizationBased[i].orgId && 
            values.criteria.organizationBased[i].organizationName  &&
            !values.criteria.organizationBased[i].chillingCenterId){
            let orgObjOnlyOrg = {organizationName:"",orgId:null};
            orgObjOnlyOrg.organizationName = values.criteria.organizationBased[i].organizationName;
            orgObjOnlyOrg.orgId = values.criteria.organizationBased[i].orgId;
            orgArr.push(orgObjOnlyOrg);
          }
    
          if(values.criteria.organizationBased[i].orgId && 
            values.criteria.organizationBased[i].organizationName &&
            values.criteria.organizationBased[i].chillingCenterId &&
            values.criteria.organizationBased[i].ccName &&
            !values.criteria.organizationBased[i].vlccId){
            let orgObjOnlyOrgAndCc = {organizationName:"",orgId:null,ccName:"",chillingCenterId:null};
            orgObjOnlyOrgAndCc.organizationName = values.criteria.organizationBased[i].organizationName;
            orgObjOnlyOrgAndCc.orgId = values.criteria.organizationBased[i].orgId;
            orgObjOnlyOrgAndCc.ccName = values.criteria.organizationBased[i].ccName;
            orgObjOnlyOrgAndCc.chillingCenterId = values.criteria.organizationBased[i].chillingCenterId;
            orgArr.push(orgObjOnlyOrgAndCc);
          }
    
          if(values.criteria.organizationBased[i].orgId && 
            values.criteria.organizationBased[i].organizationName &&
            values.criteria.organizationBased[i].chillingCenterId &&
            values.criteria.organizationBased[i].ccName &&
            values.criteria.organizationBased[i].vlccId &&
            values.criteria.organizationBased[i].vlccName){
            let orgObjOnlyOrgAndCcAndVlcc = {organizationName:"",orgId:null,ccName:"",chillingCenterId:null,vlccName:"",vlccId:null,societyUnqId:null};
            orgObjOnlyOrgAndCcAndVlcc.organizationName = values.criteria.organizationBased[i].organizationName;
            orgObjOnlyOrgAndCcAndVlcc.orgId = values.criteria.organizationBased[i].orgId;
            orgObjOnlyOrgAndCcAndVlcc.ccName = values.criteria.organizationBased[i].ccName;
            orgObjOnlyOrgAndCcAndVlcc.chillingCenterId = values.criteria.organizationBased[i].chillingCenterId;
            orgObjOnlyOrgAndCcAndVlcc.vlccName = values.criteria.organizationBased[i].vlccName;
            orgObjOnlyOrgAndCcAndVlcc.vlccId = values.criteria.organizationBased[i].vlccId;
            orgObjOnlyOrgAndCcAndVlcc.societyUnqId = values.criteria.organizationBased[i].societyUnqId;
            orgArr.push(orgObjOnlyOrgAndCcAndVlcc);
          }
    
          if(values.criteria.organizationBased[i].organizationName && (values.criteria.organizationBased[i].ccName == '' || values.criteria.organizationBased[i].ccName == null) && values.criteria.organizationBased[i].vlccName == ''){
              let orgObjOrg = {organizationName:"",orgId:null};
                orgObjOrg.organizationName = values.criteria.organizationBased[i].organizationName.orgName;
                orgObjOrg.orgId = values.criteria.organizationBased[i].organizationName.orgId;
                orgArr.push(orgObjOrg);
          }
    
          if(values.criteria.organizationBased[i].organizationName && values.criteria.organizationBased[i].ccName && values.criteria.organizationBased[i].vlccName == ''){
            let orgObjOrgCc = {organizationName:"",orgId:null,ccName:"",chillingCenterId:null};
              orgObjOrgCc.organizationName = values.criteria.organizationBased[i].organizationName.orgName;
              orgObjOrgCc.orgId = values.criteria.organizationBased[i].organizationName.orgId;
              orgObjOrgCc.ccName = values.criteria.organizationBased[i].ccName.chillingCenterName;
              orgObjOrgCc.chillingCenterId = values.criteria.organizationBased[i].ccName.chillingCenterId;
              orgArr.push(orgObjOrgCc);
          }
    
          if(values.criteria.organizationBased[i].organizationName && values.criteria.organizationBased[i].ccName && values.criteria.organizationBased[i].vlccName && values.criteria.organizationBased[i].vlccName[0].vlccName){
            for(var j=0;j<values.criteria.organizationBased[i].vlccName.length;j++){
              let orgObj = {organizationName:"",orgId:null,ccName:"",chillingCenterId:null,vlccName:"",vlccId:null,societyUnqId:null};
                orgObj.organizationName = values.criteria.organizationBased[i].organizationName.orgName;
                orgObj.orgId = values.criteria.organizationBased[i].organizationName.orgId;
                orgObj.ccName = values.criteria.organizationBased[i].ccName.chillingCenterName;
                orgObj.chillingCenterId = values.criteria.organizationBased[i].ccName.chillingCenterId;
                orgObj.vlccName = values.criteria.organizationBased[i].vlccName[j].vlccName;
                orgObj.vlccId = values.criteria.organizationBased[i].vlccName[j].vlccId;
                orgObj.societyUnqId = values.criteria.organizationBased[i].vlccName[j].societyUnqId;
                orgArr.push(orgObj);
            }
          }
        }

      }else{    
        for(var i=0;i<values.criteria.organizationBased.length;i++){
          if(Object.keys(values.criteria.organizationBased[i]).length>0){
            if(values.criteria.organizationBased[i].orgId && 
              values.criteria.organizationBased[i].organizationName  &&
              !values.criteria.organizationBased[i].chillingCenterId){
              let orgObjOnlyOrg = {organizationName:"",orgId:null};
              orgObjOnlyOrg.organizationName = values.criteria.organizationBased[i].organizationName;
              orgObjOnlyOrg.orgId = values.criteria.organizationBased[i].orgId;
              orgArr.push(orgObjOnlyOrg);
            }

            if(values.criteria.organizationBased[i].orgId && 
              values.criteria.organizationBased[i].organizationName &&
              values.criteria.organizationBased[i].chillingCenterId &&
              values.criteria.organizationBased[i].ccName &&
              !values.criteria.organizationBased[i].vlccId){
              let orgObjOnlyOrgAndCc = {organizationName:"",orgId:null,ccName:"",chillingCenterId:null};
              orgObjOnlyOrgAndCc.organizationName = values.criteria.organizationBased[i].organizationName;
              orgObjOnlyOrgAndCc.orgId = values.criteria.organizationBased[i].orgId;
              orgObjOnlyOrgAndCc.ccName = values.criteria.organizationBased[i].ccName;
              orgObjOnlyOrgAndCc.chillingCenterId = values.criteria.organizationBased[i].chillingCenterId;
              orgArr.push(orgObjOnlyOrgAndCc);
            }

            if(values.criteria.organizationBased[i].orgId && 
              values.criteria.organizationBased[i].organizationName &&
              values.criteria.organizationBased[i].chillingCenterId &&
              values.criteria.organizationBased[i].ccName &&
              values.criteria.organizationBased[i].vlccId &&
              values.criteria.organizationBased[i].vlccName){
              let orgObjOnlyOrgAndCcAndVlcc = {organizationName:"",orgId:null,ccName:"",chillingCenterId:null,vlccName:"",vlccId:null,societyUnqId:null};
              orgObjOnlyOrgAndCcAndVlcc.organizationName = values.criteria.organizationBased[i].organizationName;
              orgObjOnlyOrgAndCcAndVlcc.orgId = values.criteria.organizationBased[i].orgId;
              orgObjOnlyOrgAndCcAndVlcc.ccName = values.criteria.organizationBased[i].ccName;
              orgObjOnlyOrgAndCcAndVlcc.chillingCenterId = values.criteria.organizationBased[i].chillingCenterId;
              orgObjOnlyOrgAndCcAndVlcc.vlccName = values.criteria.organizationBased[i].vlccName;
              orgObjOnlyOrgAndCcAndVlcc.vlccId = values.criteria.organizationBased[i].vlccId;
              orgObjOnlyOrgAndCcAndVlcc.societyUnqId = values.criteria.organizationBased[i].societyUnqId;
              orgArr.push(orgObjOnlyOrgAndCcAndVlcc);
            }

            if(values.criteria.organizationBased[i].organizationName && (values.criteria.organizationBased[i].ccName == '' || values.criteria.organizationBased[i].ccName == null) && values.criteria.organizationBased[i].vlccName == ''){
              let orgObjOrg = {organizationName:"",orgId:null};
                orgObjOrg.organizationName = values.criteria.organizationBased[i].organizationName.orgName;
                orgObjOrg.orgId = values.criteria.organizationBased[i].organizationName.orgId;
                orgArr.push(orgObjOrg);
            }

            if(values.criteria.organizationBased[i].organizationName &&
               values.criteria.organizationBased[i].ccName && 
               values.criteria.organizationBased[i].vlccName &&
               values.criteria.organizationBased[i].organizationName.orgName != undefined){
              let orgObjOrgCc = {organizationName:"",orgId:null,ccName:"",chillingCenterId:null};
                orgObjOrgCc.organizationName = values.criteria.organizationBased[i].organizationName.orgName;
                orgObjOrgCc.orgId = values.criteria.organizationBased[i].organizationName.orgId;
                orgObjOrgCc.ccName = values.criteria.organizationBased[i].ccName.chillingCenterName;
                orgObjOrgCc.chillingCenterId = values.criteria.organizationBased[i].ccName.chillingCenterId;
                orgObjOrgCc.vlccName = values.criteria.organizationBased[i].vlccName.vlccName;
                orgObjOrgCc.vlccId = values.criteria.organizationBased[i].vlccName.vlccId;
                orgObjOrgCc.societyUnqId = values.criteria.organizationBased[i].vlccName.societyUnqId;
                orgArr.push(orgObjOrgCc);
            }
          }
        }
      }

      values.criteria.organizationBased = orgArr;
      
      retailerAgentAssociationService.editRetailerAgentCriteria(values.criteriaId,editRetailerAgentCriteriaData).then(data => {
        setStatus(true);
        setSubmitting(false);
      }).catch(error => {
        setStatus(false);
        setSubmitting(false);
        setFailedStatus(true);
      }); 
    }

    const closeConfirmDialog = () => {
      setOpenConfirmDialog(false);
    }

    const handleDialogClose = () => {
      history.push("/configuration/retailer-associate-criteria");
    };

    return (
      <>
      <Grid container>
        <RetailerAgentCriteriaForm
          values={values}
          handleSubmit={handleSubmit}
          editStatus={editStatus}
          edit={edit}
          createError={createError}
          pbpData={pbpData}
          pbpStatus={pbpStatus}
          servicesOffering={servicesOffering}
          agentName={agentName}
          orgData = {orgData}
          roleName = {editRetailerAgentCriteriaData.roleName}
        />
        {selectionError && (
          <div style={{ color: "#f44336" }}>{selectionError}</div>
        )}
      </Grid>

      <SuccessFeedback
        open={status === true}
        onClose={handleDialogClose}
        successMessage={t("Retailer_agent_Criteria_Form_Edit_Success_Message")}
      />

      <FailureFeedback
        open={failedStatus === true}
        onClose={handleDialogClose}
        status={"Failed"}
        message={t("Retailer_agent_Criteria_Form_Edit_Failed_Message")}
        showButtons={true} />
      </>
    );
};
