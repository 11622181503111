import React from 'react';
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import 'bootstrap/dist/css/bootstrap.css';

const ColorButton = withStyles(theme => ({
    root: {
        backgroundImage: "linear-gradient(to bottom, #34409A, #14AAE4)",
        width: "100%",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "linear-gradient(to bottom, #34409A, #14AAE4)"
        }
    }
}))(Button);

const DocDownloadRetailer = ({values, extraDocData}) => {
    return (
        <>
            {extraDocData &&
                <Grid item xs={9} md={8}>
                    {
                        extraDocData.map((docDetails) => {
                            return <Grid item container md={6} alignItems="center">
                                <span style={{ fontSize: "14px" }}> {docDetails.documentName} </span>
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                <a href={docDetails.document} >Download
                                <i className="fa fa-download" id="iconId"></i>
                                </a>
                            </Grid>
                        }) 
                    }    
                </Grid>
            }
        </>
    )
}

export default DocDownloadRetailer;
