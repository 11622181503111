import React, { useEffect, useContext, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authContext } from "context/authContext";
import addDays from "date-fns/addDays";
import { errorContext } from "context/errorContext";
import { useTranslation } from "react-i18next";
import FailureFeedback from "components/common/elements/FailureFeedback";
import { provisioningService } from "../../../services/provisioningServices.js";
import { retailerAgentAssociationService } from "../../../services/retailerAgentAssociationServices";
import { Grid, Paper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import PageHeader from "../../../components/layout/PageHeader.js";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";

import Table from "components/common/table/DynamicPaginationTable2";

const RetailerAgentCriteriaList = props => {

    const [status, setStatus] = React.useState("loading");
    const [retailerAgentCriteriaStatus, setRetailerAgentCriteriaStatus] = useState("loading");
    const [retailerAgentCriteriaListData,setRetailerAgentCriteriaListData] = React.useState([]);
    
    const { dispatch: errorDispatch } = useContext(errorContext);
    let viewDropDown = false;

    const [pageInfo, setPageInfo] = React.useState(null);
    const [tableDispPbp, setTableDispPbp] = React.useState(null);

    let query = useMemo(() => new URLSearchParams(props.location.search), [
      props.location
    ]);

    const nextPage = () => {
      if (
        status !== "loading" &&
        Number(query.get("page")) < pageInfo
      ) {
        query.set("page", Number(query.get("page")) + 1);
        props.history.push({
          pathname: props.location.pathname,
          search: query.toString()
        });
      }
    };

    const previousPage = () => {
      props.history.goBack();
    };

    function getRetailerAgentCriteriaList(){
      if (!!query.get("page") && !!query.get("size")) {
        let pageSize = query.get("size");
        let pageIndex = query.get("page");
        retailerAgentAssociationService.getRetailerAgentCriteriaList(pageSize,pageIndex).then(data => {
          setRetailerAgentCriteriaListData(data.content);
          setPageInfo(data.totalPages)
          setRetailerAgentCriteriaStatus("success");
        }).catch(error => {
          console.error(error); 
            errorDispatch({
            type: "ERROR",
            error
          });
          setRetailerAgentCriteriaStatus("failed");
        });
      }else {
        props.history.push({
          pathname: props.location.pathname,
          search: `?page=0&size=5000`
        });
      }
    }

    useEffect(() => {
      provisioningService.verifyOtpNew('1313131313').then((res) => {
        const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
        const organizationId = JSON.parse(localStorage.getItem("orgId"));
        getRetailerAgentCriteriaList();
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
    }, [query]);

    const columns = React.useMemo(
      () => [
        {
          Header: "Criteria Tags",
          accessor: "criteriaTagNames"
        },
        {
          Header: "Retailer Name",
          accessor: "retailerName"
        },
        {
          Header: "Retailer Id",
          accessor: "retailerId"
        },
        {
          Header: "PBP",
          Cell: ({row}) => {
            return (
              row.original.pbp.map((obj) => obj.name).join(',')
            )
          }
        },
        {
          Header: "Services Offering",
          Cell: ({row}) => {
            return (
              row.original.servicesOffering.map((obj) => obj).join(',')
            )
          }
        },
        {
          Header: "Role Name",
          accessor: "roleName"
        },
        {
          Header: "Edit",
          Cell: ({ row }) => {
            let link = `/configuration/associate-criteria-edit/${row.original.id}/${row.original.retailerName}`;
            return (
              <Link to={{
                pathname: link,
                state: {
                  from: row.original
                }
              }}>
                <IconButton>
                  <i className="fa fa-edit" aria-hidden="true"></i>
                </IconButton>
              </Link>
            );
          }
        }
        ],
      []
    );

    const handlePageSize = size => {
      query.set("size", size);
      query.set("page", 0);
      props.history.push({
        pathname: props.location.pathname,
        search: query.toString()
      });
    };

    const data = React.useMemo(() => [...retailerAgentCriteriaListData]);
    return(
      <Grid container>
        <Grid item xs={12}>
          <PageHeader
            title="Retailer Agent Criteria List"
            breadcrumbs={[
              { label: "Dashboard", link: "/dashboard" },
              { label: "Configuration" },
              { label: "Retailer Agent Criteria" }
            ]}
          />
        </Grid>
        <Paper
          elevation={2}
          style={{
            padding: "2em",
            width: "100%"
          }}
        >
       
        {retailerAgentCriteriaStatus === "loading" ? (
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ height: "50vh" }}
            >
             <CircularProgress />
            </Grid>
          ) : (
            <Table  
              columns={columns}
              data={data}
              needDropdown={viewDropDown}
              //dropItems={dropitems}
              fileName={"agentCriteriaList.xls"}
            />
          )}
       
        </Paper>        
      </Grid>  
    );
};

export default RetailerAgentCriteriaList;
