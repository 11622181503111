import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import SiteForm from "./SiteForm";
import { authContext } from "context/authContext";
export default function CreateSite() {
  const org = JSON.parse(localStorage.getItem("currentUser")).organizationName;
  const { authState } = useContext(authContext);
  // fetch the initial subOrg list
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "SITE_CONFIGURATION_DEVICE_RESET"
    });
    dispatch({
      type: "SITE_CONFIG_SUB_ORG_FETCH",
      org
    });
  }, []);
  const subOrganizations = useSelector(
    state => state.site.toJS().subOrganizations
  );
  const status = useSelector(state => state.site.toJS().status);
  const createError = useSelector(state => state.site.toJS().createError);

  function handleSubmit(values, resetForm, setSubmitting) {
    if (values.latitude !== "") {
      const data = values.latitude.split(",");
      values.longitude = data[1].trim();
      values.latitude = data[0];
    }
    dispatch({
      type: "SITE_CONFIGURATION_CREATE_SITE",
      payload: { values, resetForm, setSubmitting }
    });
  }

  const [otherVendors, setOtherVendors] = React.useState(false);

  const handleVendorSelect = value => {
    if (value === "Others") {
      setOtherVendors(true);
    } else {
      setOtherVendors(false);
    }
  };

  let values = {
    organizationName: org,
    subOrganizationName: "",
    siteId: "",
    siteName: "",
    devices: [],
    chillingUnitMonitoringDetailsList: [],
    latitude: "",
    altitude: "",
    country: "",
    state: "",
    district: "",
    pinCode: "",
    villageTownCity: "",
    addressLine1: "",
    locale: "",
    timeZone: ""
  };
  return (
    <SiteForm
      values={values}
      subOrganizations={subOrganizations}
      handleSubmit={handleSubmit}
      status={status}
      createError={createError}
      otherVendors={otherVendors}
      handleVendorSelect={handleVendorSelect}
    />
  );
}
