import { Map } from "immutable";
const initialState = new Map({
  roles: [],
  subOrganizations: [],
  subOrganizationsLoading: true,
  //   sites list and sites Association, alert configuration
  sitesList: [],
  alertsConfigurationListLoading: false,
  sitesAssociationList: [],
  sitesAssociationListLoading: false,
  sitesSelected: [],
  sitesAlertsConfiguration: [],
  sitesAssociationConfiguration: [],
  // user details to display user profile
  userDetails: null,
  createError: null,
  error: ""
});
export default function userConfiguration(state = initialState, action) {
  switch (action.type) {
    // Roles list fetch
    case "USER_CONFIGURATION_ROLES_FETCH_SUCCEED":
      return state.set("roles", action.roles);
    case "USER_CONFIGURATION_ROLES_FETCH_FAILED":
      return state.set("error", action.error);
    // Sub organizations fetch
    case "USER_CONFIGURATION_SUB_ORGANIZATION_LOADING":
      return state.set("subOrganizationsLoading", true);
    case "USER_CONFIGURATION_SUB_ORGANIZATION_FETCH_SUCCEED":
      return state
        .set("subOrganizations", action.subOrganizations.content)
        .set("subOrganizationsLoading", false);
    case "USER_CONFIGURATION_SUB_ORGANIZATION_FETCH_FAILED":
      return state.set("error", action.error);
    // Sites list fetching
    case "USER_CONFIGURATION_SUB_ORGANIZATIONS_UPDATED":
      return state.set("alertsConfigurationListLoading", true);
    case "USER_CONFIGURATION_RESET_SITES_CONFIGURATION": //when suborg selection changed.
      return state
        .set("sitesAlertsConfiguration", [])
        .set("sitesList", [])
        .set("alertsConfigurationListLoading", false);
    case "USER_CONFIGURATION_SITES_OF_SUB_ORGANIZATION_FETCH_SUCCEED":
      return state
        .set("sitesList", action.sitesList)
        .set("alertsConfigurationListLoading", false);
    case "USER_CONFIGURATION_SITES_OF_SUB_ORGANIZATION_FETCH_FAILED":
      return state
        .set("error", "sites fetching failed")
        .set("alertsConfigurationListLoading", false);
    // site selection
    case "USER_CONFIGURATION_ALERTS_CONFIG_SITES_SELECTED_UPDATE":
      return state.set("sitesSelected", action.sitesSelected);
    // sites association configuration
    case "USER_CONFIGURATION_SUB_ORGANIZATIONS_FOR_SITE_ASSOCIATION_UPDATED":
      return state.set("sitesAssociationListLoading", true);
    case "USER_CONFIGURATION_SITES_OF_SUB_ORGANIZATION_FOR_SITE_ASSOCIATION_FETCH_SUCCEED":
      return state
        .set("sitesAssociationList", action.sitesList)
        .set("sitesAssociationListLoading", false);
    case "USER_CONFIGURATION_SITE_ASSOCIATION_CONFIGURATION":
      return state.set("sitesAssociationConfiguration", action.config);
    case "USER_CONFIGURATION_RESET_SITES_ASSOCIATION":
      return state
        .set("sitesAssociationConfiguration", [])
        .set("sitesAssociationList", [])
        .set("sitesAssociationListLoading", false);
    // alerts configuration
    case "USER_CONFIGURATION_ALERTS_CONFIGURATION_UPDATE_SUCCEED":
      return state.set("sitesAlertsConfiguration", action.alertConfiguration);
    // User Creation
    case "USER_CONFIGURATION_USER_CREATION_SUCCESS":
      return state.set("status", "success").set("userDetails", action.result);
    case "USER_CONFIGURATION_USER_CREATION_FAIL":
      return state
        .set("status", "failed")
        .set("userDetails", null)
        .set("createError", action.error);
    // User Edit
    case "USER_CONFIGURATION_USER_EDIT_SUCCESS":
      return state.set("status", "success").set("userDetails", action.result);
    // status update
    case "USER_CONFIGURATION_STATUS_UPDATE":
      return state.set("status", action.status);
    default:
      return state;
  }
}
