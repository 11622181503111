import React, { useState } from "react";
import {
  Tooltip,
  Zoom,
  Typography,
  IconButton,
  Box,
  Paper
} from "@material-ui/core";
import InfoSharpIcon from "@material-ui/icons/InfoSharp";
import MultiInline from "./MultiInline";

const ImageComponent = ({values}) => {    
// if (values !== '')  
// {
  return (
  <>    
  { values && 
     <img
        src={values}
        width="100"
        height="100"         
        //style={{marginLeft:'270px', marginTop:'-63px'}}
        style={{marginLeft:'10px', marginTop:'15px', marginBottom:'10px'}}      
      />
  }    
  </>
  );
//}
};
export default ImageComponent;