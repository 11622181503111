function getToken() {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    const token = user && user.jwtAccessToken;
    return token;
  }
  
  function getUserName() {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    const userName = user && user.userName;
    return userName;
  }
  
  function getOrgName() {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    const organizationName = user && user.organizationName;
    return organizationName;
  }
async function getAttributes(orgName) {
    const url = `/authgateway/analyticService/rating/attributes?organization=${orgName}`;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
        authToken : localStorage.getItem("idToken")

      }
    };
    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        return await response.json();
      } else {
        throw response;
      }
    } catch (error) {
      let err = {
        status: error.status,
        error: !!error.body ? await error.json() : true
      };
      throw err;
    }
  }

  async function updateAttributes(attributes) {
    const url = `/authgateway/analyticService/rating/attributes/update`
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
        authToken : localStorage.getItem("idToken")

      },
      body: JSON.stringify(attributes)
    };
    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        return await response.json();
      } else {
        throw response;
      }
    } catch (error) {
      let err = {
        status: error.status,
        error: !!error.body ? await error.json() : true
      };
      throw err;
    }
  }

  export const analyticServices = {
      getAttributes,
      updateAttributes
  }