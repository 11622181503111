import React, { useState ,useContext, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { FieldArray,Field , getIn} from "formik";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";
import { errorContext } from "context/errorContext";
import * as Yup from "yup";
import CommonForm from "components/common/form/common-form";
import CustomCalenderPopUp from "components/common/form/CustomCalenderPopUp";
import {Radio,RadioGroup} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";


export default function ArticleHirarchy({
    setFieldValue,
    values={},
    setHierarchyType,
    resetForm    
  }) {
   
    const formikForm = React.useRef(null);
    const { t } = useTranslation();
  
    const hierarchyTypeData = [
      { value:"MCategory", label:"Main Category"},
      { value:"SCategory",label:"Sub Category"},
      { value:"MArticle",label:"Main Article"}];   
   
    const [customOccurance,setCustomOccurance] = useState(false);
    const [displayCustomPopUp,setDisplayCustomPopUp] = useState(false);
    const [radiocheck, setRadioCheck] = useState(true);    
   
   
    const changeHierarchyType = (e) => {
      
      setHierarchyType(e.target.value)
      
      if(e.target.value === "MCategory"){    
        resetForm();           
        values.type = hierarchyTypeData[0].value;       
      }else if(e.target.value === "SCategory"){ 
        resetForm();       
        values.type = hierarchyTypeData[1].value;
      }else if(e.target.value === "MArticle"){ 
        resetForm();       
        values.type = hierarchyTypeData[2].value;        
      }
      setFieldValue(values);
      console.log("values 2nd time",values);
    }    

    return (
      <>
      <Grid item container spacing={3}>
        <Grid item xs={12} md={5}> 
          <header style={{ color:"black"}}>Choose the Hierarchy Type</header>
          <RadioGroup onChange={changeHierarchyType} row defaultValue="MArticle"> 
            {hierarchyTypeData.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label} />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>

      </>
    );

}
