import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";

import CommonForm from "../../../components/common/form/common-form";
import { metadataService } from "../../../services/metadataServices";
import PageHeader from "../../../components/layout/PageHeader";
import LoanProductName from "components/common/form/LoanProductName";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { errorContext } from "context/errorContext";
import { useTranslation } from "react-i18next";
import { itemLevelConfigurationService } from "services/itemLevelConfigurationServices";

//Import added for Date field
import addDays from "date-fns/addDays";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Switch,
  Box,
  withStyles,
} from "@material-ui/core";


export default function ItemLevelConfigurationBasic({ values, handleSubmit, codEnabledData, showSubmitButton=true, fieldValue1, handleSwitch }) {

  //console.log("fieldValue1 Basic ======== ", fieldValue1);

  const formikForm = React.useRef(null);

  const { t } = useTranslation();

  const { dispatch: errorDispatch } = useContext(errorContext);

  useEffect(() => {
    //console.log("Basic Tab useEffect ========= ",values);
    //values.isCodEnabled = !values.isCodEnabled;
    //provisioningService
    itemLevelConfigurationService
      .getItemLevelConfigListById(values.id)
      .then(data => {
        values.isCodEnabled = data.isCodEnabled;
        //console.log("Basic Tab useEffect ========= ", values.isCodEnabled);
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });

  }, [values.isCodEnabled, !values.isCodEnabled]);

  const fields = [
    {
      type: "text",
      name: "id",
      label: t("itemLevelConfig_Id_Label"),
      placeholder: t("itemLevelConfig_Id_Label"),
      disabled: true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "productName",
      label: t("itemLevelConfig_ProductName_Label"),
      placeholder: t("itemLevelConfig_ProductName_Label"),
      disabled: true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "productCode",
      label: t("itemLevelConfig_ProductCode_Label"),
      placeholder: t("itemLevelConfig_ProductCode_Label"),
      disabled: true,
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    // {
    //   type: "select",
    //   name: "isCodEnabled",
    //   label: t("itemLevelConfig_CODEnabled_Label"),
    //   placeholder: t("itemLevelConfig_CODEnabled_Label"),
    //   option:
    //   codEnabledData.length > 0
    //       ? codEnabledData.map(codEnabledData => ({
    //          value: codEnabledData,
    //          label: codEnabledData
    //         }))
    //     : [],
    //   grid: {
    //     xs: 12,
    //     sm: 2,
    //     md: 3,
    //     className: ""
    //   }
    // }
    {
      type: "custom",
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      },
      component: ({ values, setFieldValue }, renderFields) => (
        // (fieldValue1 ? <FormControlLabel 
        //   control={
        //       <Switch checked={!values.isCodEnabled} onChange={(e) => setFieldValue("isCodEnabled",!values.isCodEnabled)} name="isCodEnabled" value={values.isCodEnabled} />
        //   }
        //   label={t("itemLevelConfig_CODEnabled_Label")}
        //   labelPlacement="top"
        // /> :
        <FormControlLabel 
          control={
              <Switch checked={values.isCodEnabled} onChange={(e) => setFieldValue("isCodEnabled",!values.isCodEnabled)} name="isCodEnabled" value={values.isCodEnabled} />
          }
          label={t("itemLevelConfig_CODEnabled_Label")}
          labelPlacement="top"
        /> 
        //)
      )
    }

  ]

  // let validations = {
  //   name: Yup.string().required(t("itemLevelConfig_CODEnabled_Label")),
  // };

  return (
    <CommonForm
      fields={fields}
      submitLabel={t("itemLevelConfig_Update_Btn")}
      submittingLabel={t("itemLevelConfig_Updating_Btn")}
      initialValues={values}
      // validationSchema={Yup.object().shape(validations)}
      // validateOnBlur={true}
      // validateOnChange={true}
      showSubmitButton={showSubmitButton}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        //console.log("Actual Values Passed Basic submit:- ", values);
        handleSubmit(values, resetForm, setSubmitting);
        //handleSwitch(values.isCodEnabled);
      }}
      formikRef={formikForm}
      buttonSize="3"
      buttonPosition="right"
      enableReinitialize
    />
  );
}
