import React, { useEffect, useContext, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authContext } from "context/authContext";
import addDays from "date-fns/addDays";
import { errorContext } from "context/errorContext";
import { useTranslation } from "react-i18next";
import FailureFeedback from "components/common/elements/FailureFeedback";
import { provisioningService } from "../../../services/provisioningServices.js";
import { warehouseService } from "../../../services/warehouseServices";
import { Grid, Paper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import PageHeader from "../../../components/layout/PageHeader.js";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import ConfirmPopUp from "components/common/elements/ConfirmPopUp";
import SuccessFeedback from "components/common/elements/SuccessFeedback";

import Table from "components/common/table/DynamicPaginationTable2";

const WarehouseList = props => {

  const user = JSON.parse(localStorage.getItem("currentUser"));

    const [status, setStatus] = React.useState("loading");
    const [warehouseListData,setWarehouseListData] = React.useState([]);
    const [warehouseStatus, setWarehouseStatus] = useState("loading");
    const [confirmDeleteWarehouseAssociate, setConfirmDeleteWarehouseAssociate] =  useState(false);
    const [deleteWarehouseId, setDeleteWarehouseId] = React.useState([]);
    const [displayDeleteConfirmMsg,setDisplayDeleteConfirmMsg] = useState(false);
    const [successMessageForDelete,setSuccessMessageForDelete] = useState("");
    const [isDeleteSuccessMessage,setIsDeleteSuccessMessage] = useState(false);

    const { dispatch: errorDispatch } = useContext(errorContext);
    let viewDropDown = false;

    const [pageInfo, setPageInfo] = React.useState(null);

    let query = useMemo(() => new URLSearchParams(props.location.search), [
      props.location
    ]);

    const nextPage = () => {
      if (
        status !== "loading" &&
        Number(query.get("page")) < pageInfo
      ) {
        query.set("page", Number(query.get("page")) + 1);
        props.history.push({
          pathname: props.location.pathname,
          search: query.toString()
        });
      }
    };

    const previousPage = () => {
      props.history.goBack();
    };

    function getWarehouseList(){
      warehouseService.getWarehouseListData().then(data => {
        for(let i=0;i< data.length;i++){
          if(data[i].pincode){
            data[i].pincodeString = (data[i].pincode).toString();            
          }
        }
        setWarehouseListData(data);
        console.log(data);
        setWarehouseStatus("success");
      }).catch(error => {
        setWarehouseStatus("failed");
         errorDispatch({
           type: "ERROR",
           error
        });
      });
    }

    useEffect(() => {
      provisioningService.verifyOtpNew('1313131313').then((res) => {
        const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
        const organizationId = JSON.parse(localStorage.getItem("orgId"));
        getWarehouseList();
      })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
    }, [query]);

    function invokeDeleteWarehouseAssociate(){
      warehouseService.deleteWarehouseAssociation(deleteWarehouseId).then((res) =>{
        console.log(res);
        setSuccessMessageForDelete(res.message);
        setIsDeleteSuccessMessage(true);
        setDeleteWarehouseId([]);
        setConfirmDeleteWarehouseAssociate(false);
        setDisplayDeleteConfirmMsg(true);
      })
      .catch(error => {
        setDeleteWarehouseId([]);
        setConfirmDeleteWarehouseAssociate(false);
        errorDispatch({
          type: "ERROR",
          error
        });
      });
    }
    let deleteWarehouseAssociationId = "";

    function deleteWarehouseCriteria(e,value){
      setDeleteWarehouseId(value.warehouseAssociationId);
      deleteWarehouseAssociationId = value.warehouseAssociationId;
      setConfirmDeleteWarehouseAssociate(true);
    }

    const columns = React.useMemo(
      () => [
        {
          Header: "Warehouse Id",
          accessor: "warehouseId"
        },
        {
          Header: "Warehouse Code",
          accessor: "warehouseCode"
        },
        {
          Header: "Company",
          accessor: "company"
        },
        {
          Header: "Pincode",
          accessor: "pincodeString"
        },     
        {
          Header: "Associate/Edit",
          Cell: ({ row }) => {
            let link =``;
            if(row.original.warehouseAssociationId === null){
              link = `/configuration/warehouse-associate/${row.original.warehouseId}/${row.original.warehouseName}`;
            }else{
              link = `/configuration/warehouse-edit/${row.original.warehouseId}/${row.original.warehouseAssociationId}/${row.original.warehouseName}`;
            }
            return (
              <>
              {row.original.warehouseAssociationId === null ? (
                <Link to={{
                  pathname: link,
                  state: {
                    from: row.original
                  }
                }}>
                <IconButton>
                    <i className="fa fa-group" aria-hidden="true"></i>
                  </IconButton>
                </Link>
                ):(
                  <Link to={{
                    pathname: link,
                    state: {
                        from: row.original
                    }
                  }}>   
                <IconButton>
                  <i className="fa fa-edit" aria-hidden="true"></i>
                </IconButton>
              </Link>
                )}
              </>
            );
          }
        },
        {
          Header: "Delete",
          Cell: ({ row }) => {
           // let link = `/configuration/editnotification/${row.original.configurationId}`;
            return (
              user.uiPrivileges.includes("DELETE_WAREHOUSE_CRITERIA") ?
              <>
              {row.original.warehouseAssociationId != null ? (
                <IconButton onClick={ (e) => deleteWarehouseCriteria(e,row.original)} >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </IconButton>
              ):null}
              </>
               : null
            )
          }
        }
        ],
      []
    );

    const handleConfirmDialogClose = () => {
      setConfirmDeleteWarehouseAssociate(false);
    }

    const handleDialogClose = () => {    
      setIsDeleteSuccessMessage(false);
      getWarehouseList();
    };

    const handlePageSize = size => {
      query.set("size", size);
      query.set("page", 0);
      props.history.push({
        pathname: props.location.pathname,
        search: query.toString()
      });
    };

    const data = React.useMemo(() => [...warehouseListData]);
    return(
      <>
      <Grid container>
        <Grid item xs={12}>
          <PageHeader
            title="Warehouse List"
            breadcrumbs={[
              { label: "Dashboard", link: "/dashboard" },
              { label: "Configuration" },
              { label: "WareHouse List" }
            ]}
          />
        </Grid>
        <Paper
          elevation={2}
          style={{
            padding: "2em",
            width: "100%"
          }}
        >
       
        {warehouseStatus === "loading" ? (
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ height: "50vh" }}
            >
             <CircularProgress />
            </Grid>
          ) : (
            <Table  
              columns={columns}
              data={data}
              needDropdown={viewDropDown}
              //dropItems={dropitems}
              fileName={"agentCriteriaList.xls"}
            />
          )}
       
        </Paper>        
      </Grid>  

      <ConfirmPopUp
        open ={confirmDeleteWarehouseAssociate}
        onSubmit={invokeDeleteWarehouseAssociate}
        confirmMessage={"Do you want Delete Warehouse Association?"}
        onClose={handleConfirmDialogClose}
        showButtons={true}
      />

      <SuccessFeedback
        open={isDeleteSuccessMessage}
        onClose={handleDialogClose}
        successMessage={successMessageForDelete}       
        showButtons={true}
      />
      </>
    );
};

export default WarehouseList;
